/*eslint-disable*/
import React, { useState,useEffect } from "react";
import {
  Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
    Table,
    CardFooter,
    Form,
    Button,
	Col,
	Container,
    Modal, ModalHeader, ModalBody
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
// import { InputBase } from "@material-ui/core";

export default function CreatePromo() {
  const token = localStorage.token;
  const username = localStorage.username;
  const warehouseId = localStorage.warehouse;
  let history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [warehouse,setWarehouse] = useState("");
  const [warehouses,setWarehouses] = useState([]);
  const [usernameuserss, setUsernameUserss] = useState("");
  const [codepromo, setCodePromo] = useState("");
  const [namapromo, setNamaPromo] = useState("");
  const [minimalbeli, setMinimalBeli] = useState("");
  const [maksimalpotongan, setMaksimalPotongan] = useState("");
  const [promopersen, setPromoPersen] = useState("");
  const [promonominal, setPromoNominal] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [type, setType] = useState("");
  const [globaltype, setGlobalType] = useState("");
  const [uniontype, setUnionType] = useState("");
  const [senin, setSenin] = useState("");
  const [selasa, setSelasa] = useState(2);
  const [rabu, setRabu] = useState(2);
  const [kamis, setKamis] = useState(2);
  const [jumat, setJumat] = useState(2);
  const [sabtu, setSabtu] = useState(2);
  const [minggu, setMinggu] = useState(2);
  const [januari,setjanuari] = useState(2);
  const [februari,setFebruari] = useState(2);
  const [maret,setMaret] = useState(2);
  const [april,setApril] = useState(2);
  const [mei,setMei] = useState(2);
  const [juni,setJuni] = useState(2);
  const [juli,setJuli] = useState(2);
  const [agustus,setAgustus] = useState(2);
  const [september,setSeptember] = useState(2);
  const [oktober,setOktober] = useState(2);
  const [november,setNovember] = useState(2);
  const [desember, setDesember] = useState(2);
  const [pic,setPic]=useState("")
  const [bulan,setBulan] = useState("");
  const [tahun,setTahun] = useState("");
  const [start,setStart] = useState("");
  const [end,setEnd] = useState("");
  const [status,setStatus] = useState("");
  const [savedItems,setSavedItems] = useState([]);
  const [savedItems1,setSavedItems1] = useState([]);
  const [editingItem,setEditingitem] = useState([]);
  const [qty,setQty] = useState([]);
  const [active,setActive] = useState(0);
  const [filtered,setFiltered] = useState([]);
  const [isShow,setIsShow] = useState(false);
  const [input,setInput] = useState("");
  const [input1,setInput1] = useState("");
  const [active1,setActive1] = useState(0);
  const [filtered1,setFiltered1] = useState([]);
  const [isShow1,setIsShow1] = useState(false);
  const [diskonnominal,setDiskonNominal] = useState(0);
  const [diskonpersen,setDiskonPersen] = useState(0);
  const [modal,setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [editable, setEditable] = useState(false);
  const [isConfEditableOpen, setIsConfEditableOpen] = useState(false);
  const [itemIdd, setItemIdd] = useState("");
  const [confPassEditable, setConfPassEditable] = useState('');
  const [isSearchShoww, setIsSearchShoww] = useState(false);
  const [allItemm, setAllItemm] = useState([]);
  const [queryy, setQueryy] = useState("");
  const [isSearchShowww, setIsSearchShowww] = useState(false);
  const [allItemmm, setAllItemmm] = useState([]);
  const [queryyy, setQueryyy] = useState("");
  const [qtyTotal, setTotalQty] = useState(0);
  const [qtyTotal1, setTotalQty1] = useState(0);
  const [budgetawal, setBudgetAwal] = useState("");
  const [promoglobalpersen, setPromoGlobalPersen]=useState("")
  const [promoglobalnominal, setPromoGlobalNominal]=useState("")
  const [potonganqty, setPotonganQty]=useState("")
  const [potongantransaksi, setPotonganTransaksi]=useState("")
  const [category, setCategory] = useState("")
    const [categories, setCategories] = useState([]);
    const [subCategory, setSubCategory] = useState("");
    const [subCategories, setSubCategories] = useState([]);
    const [categoryId, setCategoryId] = useState("");
    const [functionId, setFunctionId] = useState("");
    const [subfunctionId, setSubFunctionId] = useState("");
    const [subCategoryId, setSubCategoryId] = useState("");
    const [nameFunction, setNameFunction] = useState("")
    const [nameFunctions, setNameFunctions] = useState([]);
    const [nameSubFunction, setNameSubFunction] = useState("");
    const [nameSubFunctions, setNameSubFunctions] = useState([]);
    const [merek, setMerek] = useState("");
    const [allMerek, setAllMerek] = useState([]);
    const [submerek, setSubMerek] = useState("");
    const [allSubMerek, setAllSubMerek] = useState([]);
    const [hari,setHari] = useState({
        senin: false,
        selasa: false,
        rabu: false,
        kamis: false,
        jumat: false,
        sabtu: false,
        minggu: false,
    })

    useEffect(() => {
        setSelasa();
        getCategory();
        getSubCategory();
        getFunction();
        getSubFunction();
        getMerek();
        getSubMerek();
      }, []);

      const getCategory = () => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/item-kategori/list`, {
				headers,
			})
			.then((data) => {
				setCategories(data.data.response);
				// getSubCategory(id);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getSubCategory = (id) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/sub-kategori/list?kategori_id=${id}`, { headers
		})
		.then(data => {
			setSubCategories(data.data.response);
		})
		  .catch(function (error) {
			console.log(error)
		  })
	  }
	
	const getFunction = (id) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/item-function/list?kategori_id=${categoryId}&sub_kategori_id=${id}`, { headers
		})
		.then(data => {
		  setNameFunctions(data.data.response);
		})
		  .catch(function (error) {
			console.log(error)
		  })
	  }

	const getSubFunction = (id) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/sub-function/list?kategori_id=${categoryId}&sub_kategori_id=${subCategoryId}&function_id=${id}`, { headers
		})
		.then(data => {
			setNameSubFunctions(data.data.response);
		})
		  .catch(function (error) {
			console.log(error)
		  })
	  }

	const getMerek = () => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/merek/list`, { headers })
			.then((data) => {
				setAllMerek(data.data.response);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getSubMerek = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/sub-merek/list?merek_id=${id}`, { headers })
			.then((data) => {
				setAllSubMerek(data.data.response);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

  function CreateData() {
    setLoading(true);
    let dataCabangs = [];
        savedItems1.map((dataCabang) => dataCabangs = [...dataCabangs, 
            { 
                warehouse_id: dataCabang.id,
                warehouse_code: dataCabang.code

            }]);

    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id,
                harga_awal : dataItem.harga_awal, 
                qty: dataItem.qty,
                promo_persen: dataItem.promo_persen,
                promo_nominal: dataItem.promo_nominal,
                harga_akhir: dataItem.harga_akhir
            }]);
    let data = {
        username: username,
        promo_name: namapromo,
        budged_awal : budgetawal,
        promo_global_persen: parseFloat(promoglobalpersen),
        promo_global_nominal: parseFloat(promoglobalnominal),
        potongan_per_qty: parseFloat(potonganqty),
        potongan_per_transaksi: parseFloat(potongantransaksi),
        pic : pic,
        kategori_id: parseInt(),
        subkategori_id:parseInt(),
        fungsi_id:parseInt(),
        subfungsi_id:parseInt(),
        merek_id:parseInt(),
        submerek_id:parseInt(),
        // minimal_pembelian: parseFloat(minimalbeli),
        // maksimal_potongan: parseFloat(maksimalpotongan),
        // promo_persen: parseInt(promopersen),
        // promo_nominal: parseInt(promonominal),
        keterangan: keterangan,
        type: parseInt(type),
        global_type: parseInt(globaltype),
        union_type: parseInt(uniontype),
        senin: parseInt(senin),
        selasa:parseInt(selasa),
        rabu:parseInt(rabu),
        kamis:parseInt(kamis),
        jumat:parseInt(jumat),
        sabtu:parseInt(sabtu),
        minggu:parseInt(minggu),
        januari: parseInt(januari),
        februari: parseInt(februari),
        maret: parseInt(maret),
        april: parseInt(april),
        mei:parseInt(mei),
        juni:parseInt(juni),
        juli:parseInt(juli),
        agustus:parseInt(agustus),
        september:parseInt(september),
        oktober:parseInt(oktober),
        november:parseInt(november),
        desember:parseInt(desember),
        start_date:parseInt(start),
        end_date:parseInt(end),
        active_flag:parseInt(status),
        items : dataItems,
        warehouse : dataCabangs,
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/promo/single-promo-per-item/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          history.push("/admin/promo");
        })
        .then((json) => {
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
  }

  useEffect(() => {
    const getData = setTimeout(async() => {
        if(queryy != ''){
            axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/items-so-proyek`, { item_info: queryy , per_page: 10, warehouse_id: parseInt(warehouse)},{ headers: {
                Authorization: `Bearer ${token}`,
              },})
            .then((res) => {
                setAllItemm(res.data);
                setIsSearchShoww(true);
                setLoading(false);
            })
            .catch(function (error) {
                // alert("Ups Terjadi Eror : "+ error.response.data.status + " -> "+ error.response.data.message)
                alert("Internet Sedang Tidak Stabil")
            });
            setLoading(true);
        }else{
            // getItems();
      setAllItemm(null);
        }
      }, 500)
      return () => clearTimeout(getData)
  }, [queryy]);
  
  
//   const handleConfEditable = () => {
//     let pass = '123';
//     if(confPassEditable===pass){
//       setEditable(true);setIsConfEditableOpen(false);setConfPassEditable('');
//     }else{
//       setEditable(false);setIsConfEditableOpen(false);setConfPassEditable('');
//     }
//   }
  
const handleEditQty = (index, value) => {
    let updateList = savedItems;
    let aqtyTotal = parseInt(updateList[index].harga) + value;
    
    if(!isNaN(value) && value.length > -1){
      updateList[index] = {...updateList[index], qty: value};
      setSavedItems(updateList);
      setTotalQty(qtyTotal + aqtyTotal);
    }else{return false}
  }
  
  // save
  const saveItemOrUpdate = (item) => {
    let oldobj = savedItems.find(o => o.item_id === item.id);
    if(oldobj===undefined){
      setSavedItems([...savedItems,{
        item_id: item.id,
        item_name: item.item_name,
        item_code : item.item_code,
        satuan : item.satuan,
        qty: 1,
      }]);
    }else{
      let index = savedItems.findIndex(e=>e.item_id===item.id);
      let updateList = savedItems;
      let qtyupdate = parseInt(updateList[index].qty)+parseInt(1);
      updateList[index] = {...updateList[index], qty: qtyupdate};
      setSavedItems(updateList);
    };
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    {
      setQueryy("");
      setIsSearchShoww("");
      return true
    }
  };
  
    const deleteItem = (id) => {
      let array = [...savedItems];
  
      let index = array.findIndex(object => {
        return object.item_id === id;
      });
  
      if (index !== -1) {
        // setTotalPrice(totalPrice - array[index].harga * array[index].qty - array[index].diskon_nominal);
        setTotalQty(qtyTotal - array[index].harga * array[index].qty);
        // setDiskonTotal(diskontotal - array[index].diskon_nominal * array[index].qty);
        array.splice(index, 1);
        setSavedItems(array);
      }
    }

    useEffect(() => {
        const getData = setTimeout(async() => {
            if(queryyy != ''){
                axios
                .post(`${process.env.REACT_APP_API_BASE_URL}/warehouse/all`, { warehouse_name: queryyy , per_page: 10,page: 1,},{ headers: {
                    Authorization: `Bearer ${token}`,
                  },})
                .then((res) => {
                    setAllItemmm(res.data);
                    setIsSearchShowww(true);
                    setLoading(false);
                })
                .catch(function (error) {
                    // alert("Ups Terjadi Eror : "+ error.response.data.status + " -> "+ error.response.data.message)
                    alert("Internet Sedang Tidak Stabil")
                });
                setLoading(true);
            }else{
                // getItems();
          setAllItemmm(null);
            }
          }, 500)
          return () => clearTimeout(getData)
      }, [queryyy]);
      
      
    //   const handleConfEditable = () => {
    //     let pass = '123';
    //     if(confPassEditable===pass){
    //       setEditable(true);setIsConfEditableOpen(false);setConfPassEditable('');
    //     }else{
    //       setEditable(false);setIsConfEditableOpen(false);setConfPassEditable('');
    //     }
    //   }
      
    // const handleEditQty1 = (index, value) => {
    //     let updateList = savedItems;
    //     let aqtyTotal = parseInt(updateList[index].harga) + value;
        
    //     if(!isNaN(value) && value.length > -1){
    //       updateList[index] = {...updateList[index], qty: value};
    //       setSavedItems1(updateList);
    //       setTotalQty1(qtyTotal + aqtyTotal);
    //     }else{return false}
    //   }
      
      // save
      const saveItemOrUpdate1 = (item) => {
        let oldobj = savedItems1.find(o => o.item_id === item.id);
        if(oldobj===undefined){
          setSavedItems1([...savedItems1,{
            item_id: item.id,
            warehouse_code: item.warehouse_code,
            warehouse_name : item.warehouse_name,
          }]);
        }else{
          let index = savedItems1.findIndex(e=>e.item_id===item.id);
          let updateList = savedItems1;
          let qtyupdate = parseInt(updateList[index].qty)+parseInt(1);
          updateList[index] = {...updateList[index], qty: qtyupdate};
          setSavedItems1(updateList);
        };
      }

      const handleSubmit1 = async (e) => {
        e.preventDefault();
        {
          setQueryyy("");
          setIsSearchShowww("");
          return true
        }
      };
      
      const allCentangCuaks = async (e) => {
        e.preventDefault();
        {
          setSenin("1");
          setSelasa("1");
          setRabu("1");
          setKamis("1");
          setJumat("1");
          setSabtu("1");
          setMinggu("1");
          return true
        }
      };
      
        const deleteItem1 = (id) => {
          let array = [...savedItems1];
      
          let index = array.findIndex(object => {
            return object.item_id === id;
          });
      
          if (index !== -1) {
            setTotalQty1(qtyTotal1 - array[index].qty);
            array.splice(index, 1);
            setSavedItems1(array);
          }
        }


// const formatRupiah = (money) => {
//   return new Intl.NumberFormat('id-ID',
//       { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
//   ).format(money);
// }

  return (
    <>
      <SimpleHeader name="Daftar Promo Per Item" parentName="Master" />
      <Container className="mt--6" fluid>
        <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <h3>Promo</h3>
            </CardHeader>
            <Row md="12">
                <Col md="6">
                    <CardBody>
                        <FormGroup row>
                            <Label for="exampleEmail" sm={4}>
                                Nama Promo
                            </Label>
                            <Col sm={7}>
                                <Input
                                    autoComplete="off"    
                                    className="form-control-alternative"
                                    type="text"
                                    name="barname"
                                    placeholder="Masukan Nama Promo"
                                    value={namapromo}
                                    onChange={(e) => {
                                        setNamaPromo(e.target.value);
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label for="exampleEmail" sm={4}>
                                Keterangan
                            </Label>
                            <Col sm={7}>
                                <Input
                                    autoComplete="off"
                                    className="form-control-alternative"
                                    type="textarea"
                                    rows ="14"
                                    placeholder="Masukan Keterangan"
                                    value={keterangan}
                                    onChange={(e) => {
                                        setKeterangan(e.target.value);
                                    }}
                                />
                            </Col>
                        </FormGroup>
                    </CardBody>
                </Col>
                <Col md="6">
                    <CardBody>
                          <FormGroup row>
                              <Label for="exampleEmail" sm={4}>
                                  Minimal Pembelian
                              </Label>
                              <Col sm={7}>
                                  <Input
                                    autoComplete="off"
                                      className="form-control-alternative"
                                      type="text"
                                      placeholder="Masukan Minimal Pembelian"
                                      value={minimalbeli}
                                      onChange={(e) => {
                                          setMinimalBeli(e.target.value);
                                      }}
                                  />
                              </Col>
                          </FormGroup>
                          <FormGroup row>
                              <Label for="exampleEmail" sm={4}>
                                  Maksimal Potongan
                              </Label>
                              <Col sm={7}>
                                  <Input
                                    autoComplete="off"
                                      className="form-control-alternative"
                                      type="text"
                                      placeholder="Masukan Maksimal Potongan"
                                      value={maksimalpotongan}
                                      onChange={(e) => {
                                          setMaksimalPotongan(e.target.value);
                                      }}
                                  />
                              </Col>
                          </FormGroup>
                          <FormGroup row>
                              <Label for="exampleEmail" sm={4}>
                                  Promo Persen
                              </Label>
                              <Col sm={7}>
                                  <Input
                                    autoComplete="off"
                                      className="form-control-alternative"
                                      type="text"
                                      placeholder="Masukan Promo Persen"
                                      value={promopersen}
                                      onChange={(e) => {
                                          setPromoPersen(e.target.value);
                                      }}
                                  />
                              </Col>
                          </FormGroup>
                          <FormGroup row>
                              <Label for="exampleEmail" sm={4}>
                                  Promo Nominal
                              </Label>
                              <Col sm={7}>
                                  <Input
                                    autoComplete="off"
                                      className="form-control-alternative"
                                      type="text"
                                      placeholder="Masukan Promo Nominal"
                                      value={promonominal}
                                      onChange={(e) => {
                                          setPromoNominal(e.target.value);
                                      }}
                                  />
                              </Col>
                          </FormGroup>
                          <FormGroup row>
                              <Label for="exampleEmail" sm={4}>
                                  Tipe Promo
                              </Label>
                              <Col sm={7}>
                                  <Input
                                    autoComplete="off"
                                      className="form-control-alternative"
                                      name="Supplier"
                                      type="select"
                                      placeholder="Masukan Type"
                                      value={type}
                                      onChange={(e) => {
                                          setType(e.target.value);
                                      }} >
                                    <option value="" disabled selected hidden> Tipe Promo</option>
                                    <option value="1">Promo Transaksi</option>
                                    <option value="2">Promo Per Item</option>
                                    <option value="3">Promo Kategori</option>
                                  </Input>
                              </Col>
                          </FormGroup>
                          <FormGroup row>
                              <Label for="exampleEmail" sm={4}>
                                  Global Promo
                              </Label>
                              <Col sm={7}>
                                  <Input
                                    autoComplete="off"
                                      className="form-control-alternative"
                                      name="Supplier"
                                      type="select"
                                      placeholder="Masukan Global Type"
                                      value={globaltype}
                                      onChange={(e) => {
                                          setGlobalType(e.target.value);
                                      }}>
                                        <option value="" disabled selected hidden> Tipe Global Promo</option>
                                        <option value="1">Semua Cabang</option>
                                        <option value="2">Cabang Tertenu</option>
                                  </Input>
                              </Col>
                          </FormGroup>
                          <FormGroup row>
                              <Label for="exampleEmail" sm={4}>
                                  Gabungan Promo
                              </Label>
                              <Col sm={7}>
                                  <Input
                                    autoComplete="off"
                                      className="form-control-alternative"
                                      name="Supplier"
                                      type="select"
                                      placeholder="Masukan Union Type"
                                      value={uniontype}
                                      onChange={(e) => {
                                          setUnionType(e.target.value);
                                      }}>
                                        <option value="" disabled selected hidden> Tipe Gabungan Promo</option>
                                        <option value="1">Terpisah</option>
                                        <option value="2">Tergabung</option>
                                  </Input>
                              </Col>
                          </FormGroup>
                    </CardBody>
                </Col>
            </Row>
        </Card>
        {/* kategori */}
        <Card className="bg-secondary shadow">
									<CardHeader className="bg-white border-0">
										<h3>KATEGORI</h3>
									</CardHeader>
									<CardBody>
										<Row md="12">
											<Col md="6">
												<FormGroup row>
													<Label for="exampleEmail" sm={4}>
														Kategori
													</Label>
													<Col sm={5}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="kategory"
															type="select"
															value={category}
															onChange={(e) => {
																setCategory(e.target.value);
																setCategoryId(e.target.value);
																getSubCategory(e.target.value);
															}}>
															<option value="">Pilih Kategori</option>
															{categories.map((category, key) => {
																return (
																	<option key={key} value={category.id}>
																		{category.name}
																	</option>
																);
															})}
														</Input>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label for="exampleEmail" sm={4}>
														Sub Kategori
													</Label>
													<Col sm={5}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="subKategory"
															type="select"
															value={subCategory}
															onChange={(e) => {
																setSubCategory(e.target.value);
																setSubCategoryId(e.target.value);
																getFunction(e.target.value)
															}}>
															<option value="">Pilih Sub Kategori</option>
															{subCategories.map((category, key) => {
																return (
																	<option key={key} value={category.id}>
																		{category.name}
																	</option>
																);
															})}
														</Input>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label for="exampleEmail" sm={4}>
														Function
													</Label>
													<Col sm={5}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="function"
															type="select"
															value={nameFunction}
															onChange={(e) => {
																setNameFunction(e.target.value);
																setFunctionId(e.target.value);
																getSubFunction(e.target.value)
															}}>
															<option value="">Pilih Function</option>
															{nameFunctions.map((nameFunction, key) => {
																return (
																	<option key={key} value={nameFunction.id}>
																		{nameFunction.name}
																	</option>
																);
															})}
														</Input>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label for="exampleEmail" sm={4}>
														Sub Function
													</Label>
													<Col sm={5}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="subFunction"
															type="select"
															value={nameSubFunction}
															onChange={(e) => {
																setNameSubFunction(e.target.value);
																setSubFunctionId(e.target.value);
															}}>
															<option value="">Pilih Sub Function</option>
															{nameSubFunctions.map((nameFunction, key) => {
																return (
																	<option key={key} value={nameFunction.id}>
																		{nameFunction.name}
																	</option>
																);
															})}
														</Input>
													</Col>
												</FormGroup>
											</Col>
											<Col md="6">
												<FormGroup row>
													<Label for="exampleEmail" sm={4}>
														Merek
													</Label>
													<Col sm={5}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="merek"
															type="select"
															value={merek}
															onChange={(e) => {
																setMerek(e.target.value);
																getSubMerek(e.target.value);
															}}>
															<option value="">Pilih Merek</option>
															{allMerek.map((m, key) => {
																return (
																	<option key={key} value={m.id}>
																		{m.name}
																	</option>
																);
															})}
														</Input>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label for="exampleEmail" sm={4}>
														Sub Merek
													</Label>
													<Col sm={5}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="merek"
															type="select"
															value={submerek}
															onChange={(e) => {
																setSubMerek(e.target.value);

															}}>
															<option value="">Pilih Sub Merek</option>
															{allSubMerek.map((submerek, key) => {
																return (
																	<option key={key} value={submerek.id}>
																		{submerek.name}
																	</option>
																);
															})}
														</Input>
													</Col>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
									{/* <CardFooter>
										<Button color="danger" onClick={setQuestionAlert}>
											Simpan
										</Button>
										<Link className="btn btn-info" to="/admin/item">
											Kembali
										</Link>
									</CardFooter> */}
								</Card>
                                {/* Hari */}
        <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3>Durasi Hari</h3>
                    <Form onSubmit={allCentangCuaks}>
                        <div style={{ textAlign: "right" }}>
                            <Button color="primary" className="mb-2" type="submit">Pilih Semua</Button> 
                        </div>
                    </Form>
                </div>
            </CardHeader>
            {/* <Row md="12">
                <Col md="6">
                    <CardBody>
                    <FormGroup row>
                    <Label for="exampleEmail" sm={4}>
                    Senin
                    </Label>
                    <Col sm={7}>
                        <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="select"
                            
                            placeholder="Masukan Senin"
                            value={senin}
                            onChange={(e) => {
                                setSenin(e.target.value);
                            }}>
                            <option value="" disabled selected hidden> Pilih Status</option>
                            <option value="1">Aktif</option>
                            <option value="2">Tidak Aktif</option>
                            </Input>
                    </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Label for="exampleEmail" sm={4}>
                    Selasa
                    </Label>
                    <Col sm={7}>
                        <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="select"
                            
                            placeholder="Masukan Selasa"
                            value={selasa}
                            onChange={(e) => {
                                setSelasa(e.target.value);
                            }}>
                            <option value="" disabled selected hidden> Pilih Status </option>
                            <option value="1">Aktif</option>
                            <option value="2">Tidak Aktif</option>
                            </Input>
                    </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Label for="exampleEmail" sm={4}>
                    Rabu
                    </Label>
                    <Col sm={7}>
                        <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="select"
                            
                            placeholder="Masukan Rabu"
                            value={rabu}
                            onChange={(e) => {
                                setRabu(e.target.value);
                            }}>
                            <option value="" disabled selected hidden> Pilih Status </option>
                            <option value="1">Aktif</option>
                            <option value="2">Tidak Aktif</option>
                            </Input>
                    </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Label for="exampleEmail" sm={4}>
                    Kamis
                    </Label>
                    <Col sm={7}>
                        <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="select"
                            
                            placeholder="Masukan Kamis"
                            value={kamis}
                            onChange={(e) => {
                                setKamis(e.target.value);
                            }}>
                            <option value="" disabled selected hidden> Pilih Status </option>
                            <option value="1">Aktif</option>
                            <option value="2">Tidak Aktif</option>
                            </Input>
                    </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Label for="exampleEmail" sm={4}>
                    Jumat
                    </Label>
                    <Col sm={7}>
                        <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="select"
                            
                            placeholder="Masukan Jumat"
                            value={jumat}
                            onChange={(e) => {
                                setJumat(e.target.value);
                            }}>
                            <option value="" disabled selected hidden> Pilih Status </option>
                            <option value="1">Aktif</option>
                            <option value="2">Tidak Aktif</option>
                            </Input>
                    </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Label for="exampleEmail" sm={4}>
                    Sabtu
                    </Label>
                    <Col sm={7}>
                        <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="select"
                            
                            placeholder="Masukan Sabtu"
                            value={sabtu}
                            onChange={(e) => {
                                setSabtu(e.target.value);
                            }}>
                                <option value="" disabled selected hidden> Pilih Status </option>
                            <option value="1">Aktif</option>
                            <option value="2">Tidak Aktif</option>
                            </Input>
                    </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Label for="exampleEmail" sm={4}>
                    Minggu
                    </Label>
                    <Col sm={7}>
                        <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="select"
                            
                            placeholder="Masukan Minggu"
                            value={minggu}
                            onChange={(e) => {
                                setMinggu(e.target.value);
                            }}>
                            <option value="" disabled selected hidden> Pilih Status </option>
                            <option value="1">Aktif</option>
                            <option value="2">Tidak Aktif</option>
                            </Input>
                    </Col>
                    </FormGroup>
                    </CardBody>
                </Col>
                <Col md="6">
                    <CardBody>
                    
                    </CardBody>
                </Col>
            </Row> */}
            <CardBody>
            <Table size="sm" responsive>
                    <thead>
                      <tr>
                        <th><center>Senin</center></th>
                        <th><center>Selasa</center></th>
                        <th><center>Rabu</center></th>
                        <th><center>Kamis</center></th>
                        <th><center>Jumat</center></th>
                        <th><center>Sabtu</center></th>
                        <th><center>Minggu</center></th>
                      </tr>
                    </thead>
                    <tbody>
                          <tr>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked={
                                            senin == 1 ? true : false
                                        }
                                        value={senin == 1 ? "2" : "1"}
                                        onChange={(e) => {
                                                setSenin(e.target.value);
                                            }}
                                    />
                                    <Label check>
                                    </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {selasa == 1 ? true : false}
                                        value={selasa == 1 ? "2" : "1"}
                                        onChange={(e) => setSelasa(e.target.value)}
                                    />
                                        <Label check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {rabu == 1 ? true : false}
                                        value={rabu == 1 ? "2" : "1"}
                                        onChange={(e) => setRabu(e.target.value)}
                                    />
                                        <Label check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {kamis == 1 ? true : false}
                                        value={kamis == 1 ? "2" : "1"}
                                        onChange={(e) => setKamis(e.target.value)}
                                    />
                                        <Label check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {jumat == 1 ? true : false}
                                        value={jumat == 1 ? "2" : "1"}
                                        onChange={(e) => setJumat(e.target.value)}
                                    />
                                        <Label check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {sabtu == 1 ? true : false}
                                        value={sabtu == 1 ? "2" : "1"}
                                        onChange={(e) => setSabtu(e.target.value)}
                                    />
                                        <Label check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {minggu == 1 ? true : false}
                                        value={minggu == 1 ? "2" : "1"}
                                        onChange={(e) => setMinggu(e.target.value)}
                                    />
                                        <Label check>
                                        </Label>
                                </FormGroup>
                            </td>
                          </tr>
                    </tbody>
                  </Table>
            </CardBody>
        </Card>
        {/* Hari */}
        {/* <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3>Durasi Promo</h3>
                    <Form onSubmit={allCentangCuaks}>
                        <div style={{ textAlign: "right" }}>
                            <Button color="primary" className="mb-2" type="submit">Pilih Semua</Button> 
                        </div>
                    </Form>
                </div>
            </CardHeader>
            <Row md="12">
                <Col md="6">
                    <CardBody>
                    <FormGroup row>
                    <Label for="exampleEmail" sm={4}>
                    Senin
                    </Label>
                    <Col sm={7}>
                        <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="select"
                            
                            placeholder="Masukan Senin"
                            value={senin}
                            onChange={(e) => {
                                setSenin(e.target.value);
                            }}>
                            <option value="" disabled selected hidden> Pilih Status</option>
                            <option value="1">Aktif</option>
                            <option value="2">Tidak Aktif</option>
                            </Input>
                    </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Label for="exampleEmail" sm={4}>
                    Selasa
                    </Label>
                    <Col sm={7}>
                        <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="select"
                            
                            placeholder="Masukan Selasa"
                            value={selasa}
                            onChange={(e) => {
                                setSelasa(e.target.value);
                            }}>
                            <option value="" disabled selected hidden> Pilih Status </option>
                            <option value="1">Aktif</option>
                            <option value="2">Tidak Aktif</option>
                            </Input>
                    </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Label for="exampleEmail" sm={4}>
                    Rabu
                    </Label>
                    <Col sm={7}>
                        <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="select"
                            
                            placeholder="Masukan Rabu"
                            value={rabu}
                            onChange={(e) => {
                                setRabu(e.target.value);
                            }}>
                            <option value="" disabled selected hidden> Pilih Status </option>
                            <option value="1">Aktif</option>
                            <option value="2">Tidak Aktif</option>
                            </Input>
                    </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Label for="exampleEmail" sm={4}>
                    Kamis
                    </Label>
                    <Col sm={7}>
                        <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="select"
                            
                            placeholder="Masukan Kamis"
                            value={kamis}
                            onChange={(e) => {
                                setKamis(e.target.value);
                            }}>
                            <option value="" disabled selected hidden> Pilih Status </option>
                            <option value="1">Aktif</option>
                            <option value="2">Tidak Aktif</option>
                            </Input>
                    </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Label for="exampleEmail" sm={4}>
                    Jumat
                    </Label>
                    <Col sm={7}>
                        <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="select"
                            
                            placeholder="Masukan Jumat"
                            value={jumat}
                            onChange={(e) => {
                                setJumat(e.target.value);
                            }}>
                            <option value="" disabled selected hidden> Pilih Status </option>
                            <option value="1">Aktif</option>
                            <option value="2">Tidak Aktif</option>
                            </Input>
                    </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Label for="exampleEmail" sm={4}>
                    Sabtu
                    </Label>
                    <Col sm={7}>
                        <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="select"
                            
                            placeholder="Masukan Sabtu"
                            value={sabtu}
                            onChange={(e) => {
                                setSabtu(e.target.value);
                            }}>
                                <option value="" disabled selected hidden> Pilih Status </option>
                            <option value="1">Aktif</option>
                            <option value="2">Tidak Aktif</option>
                            </Input>
                    </Col>
                    </FormGroup>
                    <FormGroup row>
                    <Label for="exampleEmail" sm={4}>
                    Minggu
                    </Label>
                    <Col sm={7}>
                        <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="select"
                            
                            placeholder="Masukan Minggu"
                            value={minggu}
                            onChange={(e) => {
                                setMinggu(e.target.value);
                            }}>
                            <option value="" disabled selected hidden> Pilih Status </option>
                            <option value="1">Aktif</option>
                            <option value="2">Tidak Aktif</option>
                            </Input>
                    </Col>
                    </FormGroup>
                    </CardBody>
                </Col>
                <Col md="6">
                    <CardBody>
                    <FormGroup row>
                        <Label for="exampleEmail" sm={4}>
                        Bulan
                        </Label>
                        <Col sm={7}>
                            <Input
                                autoComplete="off"
                                className="form-control-alternative"
                                type="select"
                                
                                placeholder="Masukan Bulan"
                                value={bulan}
                                onChange={(e) => {
                                    setBulan(e.target.value);
                                }} >
                            <option value="" disabled selected hidden> Pilih Bulan</option>
                            <option value="1">Januari</option>
                            <option value="2">Februari</option>
                            <option value="3">Maret</option>
                            <option value="4">April</option>
                            <option value="5">Mei</option>
                            <option value="6">Juni</option>
                            <option value="7">Juli</option>
                            <option value="8">Agustus</option>
                            <option value="9">September</option>
                            <option value="10">Oktober</option>
                            <option value="11">November</option>
                            <option value="12">Desember</option>
                            </Input>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="exampleEmail" sm={4}>
                        Tahun
                        </Label>
                        <Col sm={7}>
                            <Input
                                autoComplete="off"
                                className="form-control-alternative"
                                type="select"
                                
                                placeholder="Masukan Tahun"
                                value={tahun}
                                onChange={(e) => {
                                    setTahun(e.target.value);
                                }}>
                                <option value="" disabled selected hidden> Pilih Tahun</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                                <option value="2031">2031</option>
                                <option value="2032">2032</option>
                            </Input>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="exampleEmail" sm={4}>
                        Pembuatan
                        </Label>
                        <Col sm={7}>
                            <Input
                                autoComplete="off"
                                className="form-control-alternative"
                                type="date"
                                
                                placeholder="Masukan Pembuatan"
                                value={start}
                                onChange={(e) => {
                                    setStart(e.target.value);
                                }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="exampleEmail" sm={4}>
                        Selesai
                        </Label>
                        <Col sm={7}>
                            <Input
                                autoComplete="off"
                                className="form-control-alternative"
                                type="date"
                                
                                placeholder="Masukan Selesai"
                                value={end}
                                onChange={(e) => {
                                    setEnd(e.target.value);
                                }}
                            />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label for="exampleEmail" sm={4}>
                        Status Promo
                        </Label>
                        <Col sm={7}>
                            <Input
                                autoComplete="off"
                                className="form-control-alternative"
                                type="select"
                                
                                placeholder="Masukan Status"
                                value={status}
                                onChange={(e) => {
                                    setStatus(e.target.value);
                                }}>
                                <option value="" disabled selected hidden> Pilih Status Promo </option>
                                <option value="1">Aktif</option>
                                <option value="2">Tidak Aktif</option>
                                </Input>
                        </Col>
                    </FormGroup>
                    </CardBody>
                </Col>
            </Row>
        </Card> */}
                {/* Cabang */}
        {/* <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <h3>Cabang</h3>
            </CardHeader>
            <Form onSubmit={handleSubmit1}>
                <Row md="12">
                    <Col md="6">
                        <CardBody>
                            <FormGroup row>
                                <Label
                                    for="exampleEmail"
                                    sm={4}
                                >
                                    Cabang
                                </Label>
                                <Col sm={7}>
                                <Input
                                    autoComplete="off"
                                    placeholder="Cabang"
                                    type="text"
                                    onChange={onChange1}
                                    onKeyDown={onKeyDown1}
                                    value={input1}
                                />
                                </Col>
                                <AutoCompleTes1 />
                            </FormGroup>
                        </CardBody>
                    </Col>
                    <Col md="6">
                        <CardBody>
                            <FormGroup row>
                            <Label
                            sm={4}
                            >
                            </Label>
                            <Col sm={7}>
                                <Button color="primary" className="mb-3" type="submit">
                                Tambah
                                </Button>
                            </Col>
                            </FormGroup>
                        </CardBody>
                    </Col>
                </Row>
            </Form>
            <CardBody>
                <Table>
                    <tr>
                        <th>
                        Nama Cabang
                        </th>
                        <th>
                        
                        </th>
                    </tr>
                    <tbody>
                    {
                        savedItems1.map((savedItem1, key) => {
                            return (
                                <tr key={key}>
                                    <td>{savedItem1.data.warehouse_name}</td>
                                    <td> <Button color="danger" onClick={() => deleteItem(savedItem1.id)}>Delete</Button></td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>
            </CardBody>
        </Card>  */}
        <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <h3>Cabang</h3>
            </CardHeader>
                <CardBody>
                    <Table size="sm" responsive>
                    <thead>
                        <tr>
                        <th><b>Kode Cabang</b></th>
                        <th><b>Cabang</b></th>
                        </tr>
                    </thead>
                        <tbody>
                        {
                        savedItems1.map((savedItem1, key) => {
                            return (
                            <tr key={key}>
                                <td>{savedItem1.warehouse_code}</td>
                                <td>{savedItem1.warehouse_name}</td>
                                <td>
                                <Button color="danger" onClick={() => deleteItem1(savedItem1.item_id)}><i className="fas fa-trash" /></Button>
                                </td>
                            </tr>
                            )
                        })
                        }
                        <tr>
                        <td>
                            <><Form onSubmit={handleSubmit1}>
                                    <Input
                                    autoComplete="off"
                                    placeholder="Masukan Cabang"
                                    type="search"
                                    // onKeyDown={searchh}
                                    value={queryyy}
                                    onChange={(e) => setQueryyy(e.target.value)}
                                    />
                                    {/* <Button type="submit"><i className="fa fa-search" /></Button> */}
                                
                                {isSearchShowww && queryyy && (
                                <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                    <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                    <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShowww(false)}></i>
                                    </div>
                                    {allItemmm?.response ? (
                                    allItemmm.response.map((item) => (
                                        <CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {saveItemOrUpdate1(item);setQueryyy('');setIsSearchShowww(false);}}>
                                        <div>
                                            <b>Cabang:</b> {item.warehouse_name}
                                        </div>
                                        </CardBody>
                                    ))
                                    ) : (
                                    <div className="text-center mb-3 text-danger">Cabang "{queryyy}" tidak ada bosku!</div>
                                    )}
                                </Card>
                                )}
                            </Form></>
                            </td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
        </Card>
        {/* item */}
        <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <h3>Item</h3>
            </CardHeader>
                <CardBody>
                    <Table size="sm" responsive>
                    <thead>
                        <tr>
                        <th><b>Nama Item</b></th>
                        <th><b>Kode Item</b></th>
                        <th><b>Qty</b></th>
                        <th><b>Satuan</b></th>
                        {/* <th>
                            {editable?(<Button color="danger" onClick={() => setEditable(false)} size="sm">Simpan</Button>
                            ):(<Button onClick={() => setIsConfEditableOpen(true)} size="sm">Izinkan Edit</Button>)}
                        </th> */}
                        </tr>
                    </thead>
                        <tbody>
                        {
                        savedItems.map((savedItem, key) => {
                            return (
                            <tr key={key}>
                                <td>{savedItem.item_name}</td>
                                <td>{savedItem.item_code}</td>
                                <td>
                                <Input
                                    className="form-control-alternative"
                                    placeholder="qty"
                                    type="number"
                                    value={savedItem.qty}
                                    onChange={(e) => {
                                        handleEditQty(key, e.target.value);
                                    }}/>
                                </td>
                                <td>{savedItem.satuan}</td>
                                <td>
                                <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                </td>
                            </tr>
                            )
                        })
                        }
                        <tr>
                        <td>
                            <><Form onSubmit={handleSubmit}>
                                    <Input
                                    autoComplete="off"
                                    placeholder="Masukan Item Manual"
                                    type="search"
                                    // onKeyDown={searchh}
                                    value={queryy}
                                    onChange={(e) => setQueryy(e.target.value)}
                                    />
                                    {/* <Button type="submit"><i className="fa fa-search" /></Button> */}
                                
                                {isSearchShoww && queryy && (
                                <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                    <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                    <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
                                    </div>
                                    {allItemm?.response ? (
                                    allItemm.response.map((item) => (
                                        <CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {saveItemOrUpdate(item);setQueryy('');setIsSearchShoww(false);}}>
                                        <div>
                                            <b>Nama item:</b> {item.item_name}
                                        </div>
                                        </CardBody>
                                    ))
                                    ) : (
                                    <div className="text-center mb-3 text-danger">Item "{queryy}" tidak ada bosku!</div>
                                    )}
                                </Card>
                                )}
                            </Form></>
                            </td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
                {/* <CardFooter>
                    <Row md="12">
                    <Col md="6">
                    <FormGroup row>
                        <Col sm={7}>
                        <Input
                            type="textarea"
                            name="keterangan"
                            rows="10"
                            placeholder="Masukan Keterangan"
                            value={keterangan}
                            onChange={(e) => {
                            setKeterangan(e.target.value);
                            }}
                        />
                        </Col>
                    </FormGroup>
                    </Col>
                    <Col md="2">
                    </Col>
                    <Col md="4">
                        <FormGroup row>
                        <Label
                        for="exampleEmail"
                        sm={4}
                        size="small"
                        >
                        <b>Total</b>
                        </Label>
                        <Col sm={6}>
                        <Input
                        className="form-control-alternative"
                        disabled
                        style={{fontWeight: 'bold'}}
                        type="text"
                        name="barcode"
                        placeholder="Harga Total"
                        // value={totalPrice1}
                        value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                        />
                        </Col>
                        </FormGroup>
                        <FormGroup row>
                        <Label
                        for="exampleEmail"
                        sm={4}
                        >
                        <b>Diskon</b>
                        </Label>
                        <Col sm={6}>
                        <Input
                        className="form-control-alternative"
                        style={{fontWeight: 'bold'}}
                        disabled
                        type="text"
                        name="barcode"
                        placeholder="Diskon"
                        value={"Rp." + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                        />
                        </Col>
                        </FormGroup>
                        <FormGroup row>
                        <Label
                        for="exampleEmail"
                        sm={4}
                        >
                        <b>PPN</b>
                        </Label>
                        <Col sm={6}>
                        <Input
                        className="form-control-alternative"
                        disabled
                        style={{fontWeight: 'bold'}}
                        type="text"
                        name="barcode"
                        placeholder="PPN"
                        value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                        />
                        </Col>
                        </FormGroup>
                        <FormGroup row>
                        <Label
                        for="exampleEmail"
                        sm={4}
                        >
                        <b>Ongkir</b>
                        </Label>
                        <Col sm={6}>
                        <Input
                        className="form-control-alternative"
                        disabled
                        style={{fontWeight: 'bold'}}
                        type="text"
                        name="barcode"
                        placeholder="PPN"
                        value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                        />
                        </Col>
                        </FormGroup>
                        <FormGroup row>
                        <Label
                        for="exampleEmail"
                        sm={4}
                        >
                        <b>Grand Total</b>
                        </Label>
                        <Col sm={6}>
                        <Input
                        className="form-control-alternative"
                        disabled
                        type="text"
                        name="barcode"
                        style={{fontWeight: 'bold'}}
                        placeholder="Grand Total"
                        value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                        />
                        </Col>
                        </FormGroup>

                    </Col>
                    </Row>
                </CardFooter> */}
        </Card>
      </Container>
    </>
  );
}
