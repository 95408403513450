/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    Badge,
    CardBody, 
    CardHeader, 
    Container,
    ButtonGroup, 
    Form, 
    FormGroup, 
    Label, 
    Input ,
} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import Select2 from "react-select2-wrapper";
import paginationFactory from "react-bootstrap-table2-paginator";
import SimpleHeader from "components/Headers/SimpleHeader.js"
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from 'views/404';

const ValidasiKepalaPurchasingPoPusat = () => {
  const token = localStorage.token;
  const [warehouse, setWarehouse] = useState("")
  const [alert, setAlert] = React.useState(null);
  const [warehouses, setWarehouses] = useState([])
  const redirectPrefix = `/admin/pusat/purchase-order/validasi-admin-approve-po/edit/`;
  const [rowIndex, setRowIndex] = useState(0);
  const [allPenawaranPo, setAllPenawaranPo] = useState([]);
  const [tanggal, setTanggal] = useState("");
  const [codepo, setCodePo] = useState("")
  const [person, setPerson] = useState("")
  const [description, setDescription] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [persons, setPersons] = useState([]);
  const allInfo = JSON.parse(localStorage.allinfo);
  const penawaranPOs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub PO Pusat").map((p) => p.read_access));
  
  useEffect(() => {
    getPerson();
    getWarehouse();
  }, []);

  const getPerson = () => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/person/list`, { headers
      })
      .then(data => {
        setPersons(data.data.response);
      })
        .catch(function (error) {
          console.log(error)
        })
    }


  const getWarehouse = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`,
        { headers }
      )
      .then((data) => {
        setWarehouses(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort,tanggal, codepo, person, description, warehouse);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, tanggal, codepo, person, description, warehouse) => {
    getPenawaranPo(page, perPage, sort, tanggal, codepo, person, description, warehouse);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setTanggal(tanggal);
    setCodePo(codepo);
    setPerson(person)
    setDescription(description);
    setWarehouse(warehouse)
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, tanggal, codepo, person, description, warehouse)
    }
  }

  
  useEffect(() => {
    getPenawaranPo(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getPenawaranPo = (page, perPage, currentSort, transaction_date="", po_code = "",  person_name = "",keterangan="", warehouse_id="") => {
    
    let filter = { 
      
      page: page, 
      per_page: perPage, 
      approve:5, 
      status_ap:3,
      status_d: 3,
      
    };
    if (transaction_date !== "") {
      filter = Object.assign(filter, { transaction_date: transaction_date });
    }
    if (po_code !== "") {
      filter = Object.assign(filter, { po_code: po_code });
    }
    if (person_name !== "") {
      filter = Object.assign(filter, { person_name: person_name });
    }
    if (keterangan !== "") {
      filter = Object.assign(filter, { keterangan: keterangan });
    }
    if (warehouse_id !== "") {
      filter = Object.assign(filter, { warehouse_id: warehouse_id });
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/page`, data, {
        headers,
      })
      .then((data) => {
        setAllPenawaranPo(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        setAllPenawaranPo(error.response.data.response);
  });
}

const setGagalAlert = (id) => {
setAlert(
<SweetAlert
danger
showConfirm
confirmBtnText="Ok"
title={id}
onCancel={hideAlert}
onConfirm={hideAlert}
/>
);
};

const hideAlert = () => {
setAlert(null);
};

  const reset = () => {
    setTanggal(null);
    setCodePo("")
    setPerson("")
    setDescription("");
    setWarehouse("")
    updateDataTable(1, perPage, currentSort, "", "","","");
  }

  return (
    <div>
      {alert}
      {penawaranPOs && penawaranPOs === "1" ? (
			<div>
      <SimpleHeader name="Validasi Kepala Purchasing" parentName="" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                      <Link className="btn btn-link" to="/admin/pusat/purchase-order">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                      </Link>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                <Form>
                    <Row md="12">
                      <Col md="3">
                        <FormGroup>
                          <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >
                            Tanggal Transaksi
                          </Label>
                          <Input
                          className="form-control-alternative"
                            type="date"
                            placeholder="Masukan Tanggal"
                            value={tanggal}
                            onChange={(e) =>
                              updateDataTable(1,perPage,currentSort, e.target.value, codepo , person, description,warehouse )
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >
                            Kode PO
                          </Label>
                          <Input
                          className="form-control-alternative"
                            type="text"
                            placeholder="Masukan Kode PO"
                            value={codepo}
                            onChange={e => updateDataTable(1, perPage, currentSort, tanggal, e.target.value, person, description,warehouse)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                      <FormGroup>
                              <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >
                                  Supplier
                              </Label>
                                      <Select2
                                        className="form-control-alternative"
                                        defaultValue="0"
                                        value={person}
                                        onChange={(e) =>
                                          updateDataTable(1, perPage, currentSort, tanggal, codepo, e.target.value, description,warehouse)
                                          }
                                        options={{
                                          placeholder: "Pilih Supplier"
                                        }}
                                        data={persons.map((person, key) => {
                                          return (
                                          { id: person.person_name, text: person.person_name, key:key}
                                          );
                                        })}
                                      />
                              </FormGroup>
                      </Col>
                      <Col md="3" hidden>
                        <FormGroup>
                          <Label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect3"
                              >
                            Keterangan
                          </Label>
                          <Input
                          className="form-control-alternative"
                            type="text"
                            placeholder="Masukan Keterangan"
                            value={description}
                            onChange={e => updateDataTable(1, perPage, currentSort, tanggal, codepo, person, e.target.value,warehouse )}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            Cabang
                        </Label>
                        <Input
                            name="account"
                            type="select"
                            value={warehouse}
                            onChange={(e) =>
                            updateDataTable(1,  perPage, currentSort, tanggal, codepo, person, description, e.target.value)
                            }
                        >
                            <option value="">Pilih Cabang</option>
                            {warehouses.map((dep, key) => {
                            return (
                                <option key={key} value={dep.id}>
                                {dep.name}
                                </option>
                            );
                            })}
                        </Input>
                        </FormGroup>
                    </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label>&nbsp;</Label>
                        <Button type="button" onClick={reset} color="secondary">
                          Reset
                        </Button>
                        </FormGroup>
                    </Col>
                    </Row>
                  </Form>
                  {allPenawaranPo === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                  dataField: "",
                                  text: "Tanggal",
                                  sort: true,
                              },
                              {
                                  dataField: "",
                                  text: "Kode PO",
                                  sort: true,
                              },
                              {
                                  dataField: "",
                                  text: "Supplier",
                                  sort: true,
                              },
                              {
                                dataField: "",
                                text: "Status",
                                sort: true,
                            },
                           
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <h3>DATA NOT FOUND</h3>
                          </CardHeader>
                        </div>
                        ):(
                      <ToolkitProvider
                              rowNumber={rowIndex}
                              data={allPenawaranPo}
                              keyField="id"
                              columns={[
                              {
                                  dataField: "no",
                                  text: "#",
                                  sort: true,
                                  page: 1,
                                  formatter: (cell, row, index) => {
                                  let currentRow = ++index;
                                  return currentRow + rowIndex;
                                  },
                              },
                              {
                                  dataField: "transaction_date",
                                  text: "Tanggal",
                                  sort: true,
                              },
                              {
                                  dataField: "po_code",
                                  text: "Kode PO",
                                  sort: true,
                                  formatter: (cell, row) => {
                                    return row.po_code === ""
                                      ? <h2>
                                        <Badge color="warning" >
                                          {row.po_code}
                                        </Badge>
                                        </h2>
                                      : 
                                      <h2>
                                      <Badge color="warning" >
                                          {row.po_code}
                                        </Badge>
                                        </h2>
                                  },
                              },
                              {
                                  dataField: "person_name",
                                  text: "Supplier",
                                  sort: true,
                              },
                              {
                                dataField: "status_ap",
                                text: "Status",
                                sort: true,
                                formatter: (cell, row) => {
                                  return row.status_ap === 3
                                    ? 
                                      <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-danger" />
                                        Proses
                                      </Badge>
                                    : row.status_ap === 4
                                    ? <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-warning" />
                                        Tolak
                                      </Badge>
                                    : <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-success" />
                                        Setuju
                                      </Badge>;
                                },
                            },
                              {
                                  dataField: "", text: "", formatter: (cell, row, index) => {
                                  return (
                                    <ButtonGroup>
                                      <Button>
                                          <Link
                                          to={redirectPrefix + row.id}
                                          id={"tooltip_" + row.id}
                                          >
                                           <i className="fa fa-check-circle"  aria-hidden="true"/> Validasi
                                          
                                          </Link>
                                      </Button>
                                      </ButtonGroup>
                                  )
                                  }
                              },
                              ]}
                          >
                              {(props) => (
                              <div className="py-4 table-responsive">
                                  <BootstrapTable
                                  remote
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  bordered={false}
                                  hover={true}
                                  pagination={paginationFactory({ ...paginationOption })}
                                  onTableChange={handleTableChange}
                                  />
                              </div>
                              )}
                      </ToolkitProvider>
                        )}
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        </div>
      ):(
        <Halaman404 />
      )}
    </div>
  );
}

export default ValidasiKepalaPurchasingPoPusat;