import React from "react";
import './file-styles.css'
import { Col, Row } from "reactstrap";
// import CameraAltIcon from '@mui/icons-material/CameraAlt';
// import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
// import ImageIcon from '@mui/icons-material/Image';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const FotoArea = ({
  onclick,
  imageSrc1,
  htmlFor1
}) => {
  return (
    <Row>
      <Col>
        <div class="dashed" onClick={() => onclick()} htmlFor={htmlFor1}>
            {
                imageSrc1 != null ?
                <img
                src={imageSrc1}
                alt=""
                />
                :
                <div>
                  <i class="fa fa-camera-retro fa-3x" color="success"></i><p>Foto Area</p>
                  {/* <AddPhotoAlternateIcon color="success" fontSize="large" />
                  <p>Foto Area</p> */}
                </div>
            }
        </div>
      </Col>
    </Row>

  )
}

export default FotoArea;