/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    ButtonGroup,
    Container,
    Badge,
    Form, 
    FormGroup, 
    Label, 
    Input 
} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SimpleHeader from "components/Headers/SimpleHeader.js"
import SweetAlert from "react-bootstrap-sweetalert";
import Select2 from "react-select2-wrapper";
import Halaman404 from 'views/404';

const ValidasiDirekturInvoicePO = () => {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const warehouse = parseInt(localStorage.warehouse);
  const redirectPrefix = `/admin/invoice-po/validasi-direktur/validasi/`;
  const [tanggal, setTanggal] = useState("");
  const [rowIndex, setRowIndex] = useState(0);
  const [allInvoicePo, setAllInvoicePo] = useState([]);
  const [uomCode, setUomCode] = useState("");
  const [description, setDescription] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [persons, setPersons] = useState([]);
  const allInfo = JSON.parse(localStorage.allinfo);
  const invoicePOs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Invoice PO").map((p) => p.read_access));
 
  useEffect(() => {
    getPerson();
  }, []);

  const getPerson = () => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/person/list`, { headers
      })
      .then(data => {
        setPersons(data.data.response);
      })
        .catch(function (error) {
          console.log(error)
        })
    }
  
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort,tanggal, uomCode, description);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, tanggal,uomCode, description) => {
    getInvoicePo(page, perPage, sort,tanggal, uomCode, description);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setTanggal(tanggal)
    setUomCode(uomCode);
    setDescription(description);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort,tanggal,  uomCode, description)
    }
  }

  
  useEffect(() => {
    getInvoicePo(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getInvoicePo = (page, perPage, currentSort, transaction_date = null, invoice_code = null, person_id = null) => {
    
    let filter = { 
      page: page, 
      per_page: perPage, 
      status_af:5,
      status_d:3,
      warehouse_id: parseInt(warehouse),
      
    };
    if (transaction_date !== null) {
      filter = Object.assign(filter, {transaction_date : transaction_date });
    }
    if (invoice_code !== null) {
      filter = Object.assign(filter, { invoice_code: invoice_code });
    }
    if (person_id !== null) {
      filter = Object.assign(filter, { person_id: person_id });
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/invoice-po/page`, data, {
        headers,
      })
      .then((data) => {
        setAllInvoicePo(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
            setAllInvoicePo(error.response.data.response);
      });
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };

  const reset = () => {
    setTanggal("")
    setUomCode("");
    setDescription("");
    updateDataTable(1, perPage, currentSort, "", "");
  }

  return (
    <div>
      {alert}
      {invoicePOs && invoicePOs === "1" ? (
			<div>
      <SimpleHeader name="Validasi Direktur" parentName="" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
            <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                      <Link className="btn btn-link" to="/admin/invoice-po">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                      </Link>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                        <Form>
                          <Row md="12">
                          <Col md="3">
                              <FormGroup>
                                <Label  className="form-control-label" htmlFor="exampleFormControlSelect3">Tanggal</Label>
                                <Input
                                className="form-control-alternative"
                                  type="date"
                                  placeholder="Masukan Kode Faktur"
                                  value={tanggal}
                                  onChange={e => updateDataTable(1, perPage, currentSort, e.target.value, uomCode, description)}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="3">
                              <FormGroup>
                                <Label  className="form-control-label" htmlFor="exampleFormControlSelect3">Kode Faktur</Label>
                                <Input
                                className="form-control-alternative"
                                  type="text"
                                  placeholder="Masukan Kode Faktur"
                                  value={uomCode}
                                  onChange={e => updateDataTable(1, perPage, currentSort,tanggal, e.target.value, description)}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="3">
                            <FormGroup>
                              <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >
                                  Supplier
                              </Label>
                              <Select2
                                        className="form-control-alternative"
                                        defaultValue="0"
                                        value={description}
                                        onChange={(e) =>
                                          updateDataTable(1, perPage, currentSort,tanggal, uomCode, e.target.value,)
                                          }
                                        options={{
                                          placeholder: "Pilih Supplier"
                                        }}
                                        data={persons.map((person, key) => {
                                          return (
                                          { id: person.id, text: person.person_name, key:key}
                                          );
                                        })}
                                      />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Button type="button" onClick={reset} color="secondary">Reset</Button>
                            </Col>
                          </Row>
                        </Form>
                        {allInvoicePo === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                      dataField: "no",
                                      text: "#",
                                      sort: true,
                                      page: 1,
                                      formatter: (cell, row, index) => {
                                      let currentRow = ++index;
                                      return currentRow + rowIndex;
                                      },
                                  },
                                  {
                                    dataField: "",
                                    text: "Tanggal",
                                    sort: true,
                                },
                                {
                                    dataField: "",
                                    text: "Kode Faktur",
                                    sort: true,
                                },
                                {
                                    dataField: "",
                                    text: "Supplier",
                                    sort: true,
                                },
                                {
                                  dataField: "",
                                  text: "Status",
                                  sort: true,
                              },
                           
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <h3>DATA NOT FOUND</h3>
                          </CardHeader>
                        </div>
                        ):(
                      <ToolkitProvider
                              rowNumber={rowIndex}
                              data={allInvoicePo}
                              keyField="id"
                              columns={[
                              {
                                  dataField: "no",
                                  text: "#",
                                  sort: true,
                                  page: 1,
                                  formatter: (cell, row, index) => {
                                  let currentRow = ++index;
                                  return currentRow + rowIndex;
                                  },
                              },
                              {
                                  dataField: "created_at",
                                  text: "Tanggal",
                                  sort: true,
                              },
                              {
                                  dataField: "invoice_code",
                                  text: "Kode Faktur",
                                  sort: true,
                                  formatter: (cell, row) => {
                                    return row.invoice_code === ""
                                      ? <h2>
                                        <Badge color="warning" >
                                          {row.invoice_code}
                                        </Badge>
                                        </h2>
                                      : 
                                      <h2>
                                      <Badge color="warning" >
                                          {row.invoice_code}
                                        </Badge>
                                        </h2>
                                  },
                              },
                              {
                                  dataField: "person_name",
                                  text: "Supplier",
                                  sort: true,
                              },
                             
                              {
                                dataField: "status_d",
                                text: "Status",
                                sort: true,
                                formatter: (cell, row) => {
                                  return row.status_d === 3
                                    ? 
                                      <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-danger" />
                                        Proses
                                      </Badge>
                                    : row.status_d === 4
                                    ? <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-warning" />
                                        Tidak Setuju
                                      </Badge>
                                    : <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-success" />
                                        Setuju
                                      </Badge>;
                                },
                            },
                                {
                                    dataField: "", text: "", formatter: (cell, row, index) => {
                                    return (
                                        <ButtonGroup>
                                        <Button>
                                            <Link
                                            to={redirectPrefix + row.id}
                                            id={"tooltip_" + row.id}
                                            >
                                            <i className="fa fa-check-circle"  aria-hidden="true"/> Validasi
                                            </Link>
                                        </Button>
                                        </ButtonGroup>
                                    )
                                    }
                                },
                              ]}
                          >
                              {(props) => (
                              <div className="py-4 table-responsive">
                                  <BootstrapTable
                                  remote
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  bordered={false}
                                  hover={true}
                                  pagination={paginationFactory({ ...paginationOption })}
                                  onTableChange={handleTableChange}
                                  />
                              </div>
                              )}
                      </ToolkitProvider>
                        )}
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        </div>
      ):(
        <Halaman404 />
      )}
    </div>
  );
}

export default ValidasiDirekturInvoicePO;
