/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Card,CardBody,Row, Container,CardImg,} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";
import HeaderTotal from "components/Headers/HeaderTotal";

export default function SuratJalanKasir() {
    const warehouse = localStorage.warehouse;
    const username = localStorage.username;
    const token = localStorage.token;
    const [totalItem, setTotalItem] = useState(0);
    const [proses, setProses] = useState(0);
    const [tolak, setTolak] = useState(0);
    const [setuju, setSetuju] = useState(0);
    const page = 1;
    const perPage = 10;
    const allInfo = JSON.parse(localStorage.allinfo);
    const permintaanBarangs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub SO").map((p) => p.read_access));
    const permintaanBarang = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab SO").map((p) => p.read_access));
	const validasiPermintaanBarang = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi SO Kepala").map((p) => p.read_access));
	const cetak = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Cetak SO").map((p) => p.read_access));
    
    useEffect(() => {
        gettotal();
        getProses();
        getTolak();
        getSetuju()
      }, []);
    
      // fungsi dari ambil data
      const gettotal = () => {
        let filter = { 
          page: page, 
          per_page: perPage,
          username : username,
          warehouse_id: parseInt(warehouse),
        };
        
        const data = filter;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/sales-order/page`, data, {
            headers,
          })
          .then((data) => {
            setTotalItem(data.data.total_item);
          })
          .catch(function (error) {
          });
    }

    const getProses = () => {
        let filter = { 
          page: page, 
          per_page: perPage,
          status_ph : 3,
          username : username,
          warehouse_id: parseInt(warehouse),
        };
        
        const data = filter;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/sales-order/page`, data, {
            headers,
          })
          .then((data) => {
            setProses(data.data.total_item);
          })
          .catch(function (error) {
          });
    }

    const getTolak = () => {
        let filter = { 
          page: page, 
          per_page: perPage,
          status_ph : 4,
          username : username,
          warehouse_id: parseInt(warehouse),
        };
        
        const data = filter;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/sales-order/page`, data, {
            headers,
          })
          .then((data) => {
            setTolak(data.data.total_item);
          })
          .catch(function (error) {
          });
    }

    const getSetuju = () => {
        let filter = { 
          page: page, 
          per_page: perPage,
          status_ph : 5,
          username : username,
          warehouse_id: parseInt(warehouse),
        };
        
        const data = filter;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/sales-order/page`, data, {
            headers,
          })
          .then((data) => {
            setSetuju(data.data.total_item);
          })
          .catch(function (error) {
          });
    }


    return (
    <>
      {permintaanBarangs && permintaanBarangs === "1" ? (
			<div>
      <SimpleHeader name="Penjualan Barang" parentName="Report" />
      <HeaderTotal
       totalItem = {totalItem}
       proses ={proses}
       tolak={tolak}
       setuju={setuju}

      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <CardBody>
                    <Row>
                        <div className="card-columns">
                            {permintaanBarang && permintaanBarang === "1" && (
                            <Card>
                                <Link to="/admin/sales-order/page">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/penjualan-barang.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {validasiPermintaanBarang && validasiPermintaanBarang === "1" && (
                            <Card>
                                <Link to="/admin/sales-order/validasi-kepala-purchasing">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-kepala-purchasing.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {cetak && cetak === "1" && (
                            <Card>
                                <Link to="/admin/sales-order/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        </div>
					</Row>
                </CardBody>
          </div>
        </Row>
      </Container>
      </div>
      ):(
        <Halaman404 />
      )}
    </>
  );
}