/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Card, Button, Badge,Row, Col, CardBody, CardHeader, Container,Form, FormGroup, Label, Input ,DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from 'views/404';

const PermintaanBarangPage = () => {
  const [alert, setAlert] = React.useState(null);
  const token = localStorage.token;
  const warehouse = localStorage.getItem("warehouse");
  const redirectPrefix = `/admin/permintaan-barang/edit/`;
  const [rowIndex, setRowIndex] = useState(0);
  const [allPermintaanBarang, setAllPermintaanBarang] = useState([]);
  const [uomCode, setUomCode] = useState("");
  const [description, setDescription] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const allInfo = JSON.parse(localStorage.allinfo);
	const createButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Rfq PO").map((p) => p.create_access));
	const createButtonKasir = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Rfq PO Kasir").map((p) => p.read_access));
  const updateButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Rfq PO").map((p) => p.update_access));
  const permintaanBarangs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Rfq PO").map((p) => p.read_access));
	
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort,tanggal, uomCode, description);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort,tanggal, uomCode, description) => {
    getPermintaanBarang(page, perPage, sort, tanggal, uomCode, description);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setTanggal(tanggal)
    setUomCode(uomCode);
    setDescription(description);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort,tanggal,  uomCode, description)
    }
  }

  
  useEffect(() => {
    getPermintaanBarang(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getPermintaanBarang = (page, perPage, currentSort,transaction_date=null, rfq_code = null, item_code = null) => {
    
    let filter = { 
      
      page: page, 
      per_page: perPage,
      status_rfq: 3,
      warehouse_id: parseInt(warehouse),
    };
    if (transaction_date !== null) {
      filter = Object.assign(filter, { transaction_date: transaction_date });
    }
    if (rfq_code !== null) {
      filter = Object.assign(filter, { rfq_code: rfq_code });
    }
    if (item_code !== null) {
      filter = Object.assign(filter, { item_code: item_code });
    }
    
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/rfq-po/page`, data, {
        headers,
      })
      .then((data) => {
        setAllPermintaanBarang(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        // if(error.response.data.status == 404){
          setAllPermintaanBarang(error.response.data.response);
            // }
      });
}

const setGagalAlert = (id) => {
setAlert(
<SweetAlert
danger
showConfirm
confirmBtnText="Ok"
title={id}
onCancel={hideAlert}
onConfirm={hideAlert}
/>
);
};

const hideAlert = () => {
  setAlert(null);
};

  const reset = () => {
    setTanggal("");
    setUomCode("");
    setDescription("");
    updateDataTable(1, perPage, currentSort, "", "");
  }

  return (
    <div>
      {alert}
      {permintaanBarangs && permintaanBarangs === "1" ? (
			<div>
      <SimpleHeader name="Permintaan Barang" parentName="" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
            <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {/* <h3>Permintaan Barang</h3> */}
                    <div style={{ textAlign: 'left' }}>
                      <Link className="btn btn-link" to="/admin/permintaan-barang">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                      </Link>
                    </div>
                    
                    <div style={{ textAlign: 'right' }}>
                      {/* <Link className="btn btn-danger" to="/admin/permintaan-barang/create">
                        <i className="fas fa-plus" /> Tambah
                      </Link> */}
                      <UncontrolledDropdown nav>
													<DropdownToggle color="danger" >
															<i className="fas fa-plus" /> Tambah 
													</DropdownToggle>
													<DropdownMenu right>
                          {createButton && createButton === "1" && (
														<Link to="/admin/permintaan-barang/create">
														<DropdownItem>
                              <Label
                               className="form-control-label">
                                RFQ CABANG
                              </Label>
															{/* <span>RFQ CABANG</span> */}
														</DropdownItem>
														</Link>
                            )}
                          {createButtonKasir && createButtonKasir === "1" && (
														<Link to="/admin/permintaan-barang/create-kasir">
														<DropdownItem>
                              <Label
                               className="form-control-label">
                                RFQ KASIR
                              </Label>
															{/* <span>RFQ KASIR</span> */}
														</DropdownItem>
														</Link>
                            )}
													</DropdownMenu>
												</UncontrolledDropdown>
                    </div>
                    
                  </div>
                </CardHeader>
                <CardBody>
                  <Form>
                    <Row md="12">
                      <Col md="3">
                        <FormGroup>
                          <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >
                            Tanggal
                          </Label>
                          <Input
                          className="form-control-alternative"
                            type="date"
                            placeholder="Masukan Tanggal"
                            value={tanggal}
                            onChange={(e) =>
                              updateDataTable(1,perPage,currentSort,e.target.value,uomCode,description)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >
                            Kode RFQ
                          </Label>
                          <Input
                          className="form-control-alternative"
                            type="text"
                            placeholder="Masukan Kode RFQ"
                            value={uomCode}
                            onChange={(e) =>
                              updateDataTable(1,perPage,currentSort,tanggal,e.target.value,description)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup>
                          <Input
                          className="form-control-alternative"
                            type="hidden"
                            placeholder="Masukan Kode Item"
                            value={uomCode}
                            onChange={(e) =>
                              updateDataTable(
                                1,
                                perPage,
                                currentSort,
                                uomCode,
                                tanggal,
                                e.target.value
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button type="button" onClick={reset} color="secondary">
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  {allPermintaanBarang === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={[]}
                                  keyField="id"
                                  columns={[
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                  dataField: "",
                                  text: "Tanggal",
                                  sort: true,
                                },
                                {
                                    dataField: "",
                                    text: "Kode RFQ",
                                    sort: true,
                                },
                                // {
                                //     dataField: "",
                                //     text: "Supplier",
                                //     sort: true,
                                // },
                                {
                                  dataField: "",
                                  text: "Status",
                                  sort: true,
                                 
                              },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <h3>DATA NOT FOUND</h3>
                          </CardHeader>
                        </div>
                        ):(
                      <ToolkitProvider
                              rowNumber={rowIndex}
                              data={allPermintaanBarang}
                              keyField="id"
                              columns={[
                              {
                                  dataField: "no",
                                  text: "#",
                                  sort: true,
                                  page: 1,
                                  formatter: (cell, row, index) => {
                                  let currentRow = ++index;
                                  return currentRow + rowIndex;
                                  },
                              },
                              {
                                dataField: "transaction_date",
                                text: "Tanggal",
                                sort: true,
                              },
                              {
                                  dataField: "rfq_code",
                                  text: "Kode RFQ",
                                  sort: true,
                                  formatter: (cell, row) => {
                                    return row.rfq_code === ""
                                      ? <h2>
                                        <Badge color="warning" >
                                          {row.rfq_code}
                                        </Badge>
                                        </h2>
                                      : 
                                      <h2>
                                      <Badge color="warning" >
                                          {row.rfq_code}
                                        </Badge>
                                        </h2>
                                  },
                              },
                              // {

                              //   dataField : "person_name",
                              //   text : "Supplier",
                              //   sort : true,
                              // },
                              {
                                  dataField: "status_rfq",
                                  text: "Status",
                                  sort: true,
                                  formatter: (cell, row) => {
                                    return row.status_rfq === 3
                                      ? 
                                        <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-danger" />
                                          Proses
                                        </Badge>
                                      : row.status_rfq === 4
                                      ? <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-warning" />
                                          Tidak Setuju
                                        </Badge>
                                      : <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-success" />
                                          Setuju
                                        </Badge>;
                                  },
                              },
                              {
                                  dataField: "", text: "", formatter: (cell, row, index) => {
                                  return (
                                      <UncontrolledDropdown nav>
                                        {/* <DropdownToggle className="nav-link pr-0" color="" tag="a">
                                            <Link className="btn btn-danger">
                                                Tindakan
                                            </Link>
                                        </DropdownToggle> */}
                                        <DropdownToggle color="danger">
                                                Tindakan
                                        </DropdownToggle>
                                        <DropdownMenu>
                                              {updateButton && updateButton === "1" && (
                                              <Link  to={redirectPrefix + row.id}
                                                id={"tooltip_" + row.id}>
                                              <DropdownItem>
                                                <i className="fas fa-user-edit" /><span>Edit</span>
                                              </DropdownItem>
                                              </Link>
                                              )}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                  )
                                  }
                              },
                              ]}
                          >
                              {(props) => (
                              <div className="py-4 table-responsive">
                                  <BootstrapTable
                                  remote
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  bordered={false}
                                  hover={true}
                                  pagination={paginationFactory({ ...paginationOption })}
                                  onTableChange={handleTableChange}
                                  />
                              </div>
                              )}
                      </ToolkitProvider>
                      )}
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        </div>
      ):(
        <Halaman404 />
      )}
    </div>
  );
}

export default PermintaanBarangPage;
