import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  CardHeader,
  Col,
  Badge,
  Button,
  Table,
  Container,
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";

export default function Userss(props) {
  // Mengambil data user dari localStorage
  const allInfo = JSON.parse(localStorage.allinfo);
  const users = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Users").map((p) => p.read_access));
  const token = localStorage.token;

  // State untuk menyimpan data user dan warehouse
  const [usernameusers, setUsernameUsers] = useState("");
  const [nama, setNama] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [warehouses, setWarehouses] = useState([]);
  const [editingItem, setEditingItem] = useState([]);
  const [savedItems, setSavedItems] = useState([]);
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  // UseEffect untuk memuat data awal
  useEffect(() => {
    getById();
  }, []);

  // Fungsi untuk mendapatkan data user berdasarkan ID
  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/users/get/${props.match.params.id}`, { headers })
      .then((data) => {
        const response = data.data.response;
        setUsernameUsers(response.username);
        setNama(response.name);
        setEmail(response.email);
        setStatus(response.active_flag);
        setWarehouse(response.warehouse_id);
        getWarehouse(response.office);
        getItemDataSaved();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Fungsi untuk mendapatkan data privileges yang tersimpan
  const getItemDataSaved = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/users/list-privileges`, {
        user_id: props.match.params.id,
      }, { headers })
      .then(async (response) => {
        const stateItem = response.data.response.map((data) => ({
          id: data.id,
          user_id: data.user_id,
          privilege_id: data.privilege_id,
          privilege_name: data.privilege_name,
          create_access: data.create_access,
          read_access: data.read_access,
          update_access: data.update_access,
          delete_access: data.delete_access,
          data: { ...data },
        }));

        const stateEditing = response.data.response.map(() => ({ editing: false }));

        setEditingItem(stateEditing);
        setSavedItems(stateItem);
      })
      .catch((error) => console.log(error));
  };

  // Fungsi untuk mendapatkan daftar warehouse
  const getWarehouse = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`, { headers })
      .then((data) => {
        setWarehouses(data.data.response);
      })
      .catch((error) => console.log(error));
  };

  // // Fungsi untuk mengubah akses privilege
  // const handleEditQty = (index, value, type) => {
  //   const newValue = value === "1" ? "2" : "1";
  //   const updatedItems = [...savedItems];
  //   updatedItems[index] = {
  //     ...updatedItems[index],
  //     [`${type}_access`]: newValue,
  //   };
  //   setSavedItems(updatedItems);
  //   changePriceStatus(index, true);
  // };

  const handleEditQty = (index, value, type) => {
    const newValue = value === "1" ? "2" : "1"; // Berubah dari 1 ke 2 atau sebaliknya
    const updatedItems = [...savedItems]; // Membuat salinan dari array savedItems
    updatedItems[index] = {
      ...updatedItems[index],
      [`${type}_access`]: newValue, // Mengubah akses yang sesuai (read, create, update, delete)
    };
    setSavedItems(updatedItems); // Memperbarui state
    changePriceStatus(index, true); // Memperbarui status editing
  };

  // Fungsi untuk mengubah status edit privilege
  const changePriceStatus = (index, status) => {
    const updatedEditingItem = [...editingItem];
    updatedEditingItem[index] = { editing: status };
    setEditingItem(updatedEditingItem);
  };

  // Fungsi untuk menyimpan perubahan privilege
  const savePrivilege = (item, key) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    const data = {
      privileges: [
        {
          privilege_id: item.privilege_id,
          create_access: item.create_access,
          read_access: item.read_access,
          update_access: item.update_access,
          delete_access: item.delete_access,
        },
      ],
    };

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/users/makro-privileges/update/${item.user_id}`, data, { headers })
      .then((response) => {
        if (response.status === 200) {
          changePriceStatus(key, false);
        } else {
          alert("Maaf, simpan data gagal");
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      {users === "1" ? (
        <div>
          <SimpleHeader name="Edit Akses User" parentName="Master" />
          <Container className="mt--6" fluid>
            <Row>
              <Col>
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <Link className="btn btn-link" to="/admin/user">
                        <i className="fa fa-arrow-circle-left fa-2x" />
                      </Link>
                      <h1>
                        <Badge color={status === 1 ? "success" : "danger"}>
                          {status === 1 ? "AKTIF" : "TIDAK AKTIF"}
                        </Badge>
                      </h1>
                    </div>
                  </CardHeader>
                  <Row md="12">
                    <Col md="6">
                      <CardBody>
                        <FormGroup row>
                          <Label className="form-control-label" sm={4}>
                            Username
                          </Label>
                          <Col sm={7}>
                            <Input
                              disabled
                              className="form-control-alternative"
                              type="text"
                              value={usernameusers}
                              onChange={(e) => setUsernameUsers(e.target.value)}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label className="form-control-label" sm={4}>
                            Cabang
                          </Label>
                          <Col sm={7}>
                            <Input
                              disabled
                              className="form-control-alternative"
                              type="select"
                              value={warehouse}
                              onChange={(e) => setWarehouse(e.target.value)}
                            >
                              <option value="">Pilih Cabang</option>
                              {warehouses.map((warehouse2) => (
                                <option key={warehouse2.id} value={warehouse2.id}>
                                  {warehouse2.name}
                                </option>
                              ))}
                            </Input>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label className="form-control-label" sm={4}>
                            Nama
                          </Label>
                          <Col sm={7}>
                            <Input
                              disabled
                              className="form-control-alternative"
                              type="text"
                              value={nama}
                              onChange={(e) => setNama(e.target.value)}
                            />
                          </Col>
                        </FormGroup>
                      </CardBody>
                    </Col>
                    <Col md="6">
                      <CardBody>
                        <FormGroup row>
                          <Label className="form-control-label" sm={4}>
                            Email
                          </Label>
                          <Col sm={7}>
                            <Input
                              disabled
                              className="form-control-alternative"
                              type="text"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Col>
                        </FormGroup>
                      </CardBody>
                    </Col>
                  </Row>
                  <CardHeader className="bg-white border-0">
                    <h3>PRIVILEGE</h3>
                  </CardHeader>
                </Card>

                <Card className="bg-secondary shadow">
                  <CardBody>
                    <Table size="sm" responsive>
                      <thead>
                        <tr>
                          <th>Menu</th>
                          <th>View</th>
                          <th>Create</th>
                          <th>Update</th>
                          <th>Delete</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {savedItems.map((savedItem, key) => (
                          <tr key={savedItem.id}>
                            <td>{savedItem.data.privilege_name}</td>
                            <td>
                              <label className="custom-toggle custom-toggle-primary">
                                <Input
                                  type="checkbox"
                                  checked={savedItem.read_access === 1}
                                  value={savedItem.read_access}
                                  onChange={(e) => handleEditQty(key, e.target.value, "read")}
                                />
                                <span className="custom-toggle-slider rounded-circle"></span>
                              </label>
                            </td>
                            <td>
                              <label className="custom-toggle custom-toggle-primary">
                                <Input
                                  type="checkbox"
                                  checked={savedItem.create_access === "1"}
                                  value={savedItem.create_access}
                                  onChange={(e) => handleEditQty(key, e.target.value, "create")}
                                />
                                <span className="custom-toggle-slider rounded-circle"></span>
                              </label>
                            </td>
                            <td>
                              <label className="custom-toggle custom-toggle-primary">
                                <Input
                                  type="checkbox"
                                  checked={savedItem.update_access === "1"}
                                  value={savedItem.update_access}
                                  onChange={(e) => handleEditQty(key, e.target.value, "update")}
                                />
                                <span className="custom-toggle-slider rounded-circle"></span>
                              </label>
                            </td>
                            <td>
                              <label className="custom-toggle custom-toggle-primary">
                                <Input
                                  type="checkbox"
                                  checked={savedItem.delete_access === "1"}
                                  value={savedItem.delete_access}
                                  onChange={(e) => handleEditQty(key, e.target.value, "delete")}
                                />
                                <span className="custom-toggle-slider rounded-circle"></span>
                              </label>
                            </td>
                            <td>
                              {editingItem[key]?.editing ? (
                                <>
                                  <Button color="warning" size="sm" onClick={() => savePrivilege(savedItem, key)}>
                                    Update
                                  </Button>
                                  <Button
                                    color="danger"
                                    size="sm"
                                    onClick={() => {
                                      const updatedItems = [...savedItems];
                                      updatedItems[key] = { ...savedItem.data };
                                      setSavedItems(updatedItems);
                                      changePriceStatus(key, false);
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </>
                              ) : null}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      ) : (
        <Halaman404 />
      )}
    </>
  );
}
