/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  FormGroup,
  Label,
  Input,
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

const ValidasiBbmFinance = (props) => {
    const [alert, setAlert] = React.useState(null);
    const token = localStorage.token;
    const username = localStorage.username;
    const warehouse = localStorage.warehouse;
    let history = useHistory();
    const [codebbm, setCodeBbm] = useState("")
    const [persons, setPersons] = useState([]);
    const [person, setPerson] = useState("");
    const [keterangan, setKeterangan] = useState("");
    const [statusaf, setStatusAf] = useState("");
    const [savedItems, setSavedItems] = useState([]);
    const [bank, setBank] = useState("");
    const [banks, setBanks] = useState([]);
    const allInfo = JSON.parse(localStorage.allinfo);
    const BuktiBankMasuk = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Bukti Bank Masuk").map((p) => p.read_access));
   

    useEffect(() => {
        getById();
      }, []);
    
      const getById = () => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/bbm/get/${props.match.params.id}`,
            { headers }
          )
          .then((data) => {
            setCodeBbm(data.data.response.bbm_code)
            getPerson(data.data.response.customer_id);
            getbank(data.data.response.payment_method);
            setKeterangan(data.data.response.keterangan1);
            getItemDataSaved();
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      
  const getItemDataSaved = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/bbm/item`, {

        bbm_id: props.match.params.id

    }).then(async response => {
        let stateItem = [];

        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
                warehouse_id: data.warehouse_id,
                code_invoice : data.code_invoice,
                code_sj: data.code_sj,
                code_so: data.code_so,
                tgl_tempo : data.tgl_tempo,
                payment_total : data.payment_total,
                keterangan2 : data.keterangan2,
                potongan: data.potongan,
                bayar : data.bayar,
                data: {
                    warehouse_id: data.warehouse_id,
                    tgl_tempo : data.tgl_tempo,
                    code_invoice : data.code_invoice,
                    payment_total : data.payment_total,
                    keterangan2 : data.keterangan2,
                    potongan: data.potongan,
                    bayar : data.bayar,
                },
            }];
        }));
        setSavedItems(stateItem);
    })
}
  
  useEffect(() => {
    getPerson();
    getbank();
  }, []);

  const getPerson = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/customer/list`,
        { headers }
      )
      .then((data) => {
        setPersons(data.data.response);
        setPerson(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getbank = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/bank/get-by-wh/${warehouse}`,
        { headers }
      )
      .then((data) => {
        setBanks(data.data.response);
        setBank(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                warehouse_id: dataItem.warehouse_id,
                code_invoice : dataItem.code_invoice,
                // tgl_tempo : dataItem.tgl_tempo,
                payment_total : dataItem.payment_total,
                keterangan2 : dataItem.keterangan,
                potongan: dataItem.potongan,
                bayar : dataItem.bayar,
            }]);
    let data = {
      username : username,
      warehouse_id : parseInt(warehouse),
      account_id : parseInt(bank),
      customer_id : parseInt(person),
      payment_method : parseInt(bank),
      keterangan1 : keterangan,
      status_af : parseInt(statusaf),
      admin_finance: username,
      status_d :3,
      list : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/bbm/update/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/bbm-finance/validasi-kepala-finance")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={CreateData}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };


const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}
  
  return (
    <>
    {alert}
    {BuktiBankMasuk && BuktiBankMasuk === "1" ? (
      <div>
    <SimpleHeader name="Validasi Kepala Finance" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col"> 
                <CardBody>
                <Card className="bg-secondary shadow">
                          <CardHeader className="bg-white border-0">
                              <h3>Validasi Kepala Finance</h3>
                          </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6"> 
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Kode
                              </Label>
                              <Col sm={7}>
                                <Input
                                disabled
                                  name="person"
                                  type="text"
                                  value={codebbm}
                                  onChange={(e) => {
                                    setCodeBbm(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                             <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Customer
                              </Label>
                              <Col sm={7}>
                                <Input
                                disabled
                                  name="person"
                                  type="select"
                                  value={person}
                                  onChange={(e) => {
                                    setPerson(e.target.value);
                                  }}
                                >
                                  <option value=''>Pilih Customer</option>
                                  {persons.map((person, key) => {
                                      return (
                                        <option key={key} value={person.id}>
                                          {person.name}
                                        </option>
                                      );
                                    })}
                                </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                    sm={4}
                                >
                                    Bank
                                </Label>
                                <Col sm={7}>
                                <Input
                                disabled
                                autoComplete="off"
                                    name="coa"
                                    type="select"
                                    value={bank}
                                    onChange={(e) => {
                                    setBank(e.target.value);
                                    }}
                                >
                                    <option value="" disabled selected hidden>Pilih Bank</option>
                                    {banks.map((coa, key) => {
                                    return (
                                        <option key={key} value={coa.id}>
                                        {coa.bank_name} {coa.account_number} {coa.account_name}
                                        </option>
                                    );
                                    })}
                                </Input>
                                </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                             <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Keterangan
                              </Label>
                              <Col sm={7}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                  type="textarea"
                                  name="desc"
                                  rows = "4"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                            <Col
                              for="exampleEmail"
                              sm={4}
                            >
                              <Label className="form-control-label">Validasi</Label>
                            </Col>
                              <Col sm={7}>
                                <div style={{ display: "flex" }}>
                                  <div className="custom-control custom-radio mb-3">
                                    <Input
                                      className="custom-control-input"
                                      id="customRadio8"
                                      name="custom-radio-3"
                                      type="radio"
                                      value={5}
                                      checked={statusaf === 5}
                                      onChange={() => setStatusAf(5)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio8"
                                    >
                                      
                                    </Label>
                                    <b>SETUJU</b>
                                  </div>
                                  <div
                                    className="custom-control custom-radio mb-3"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <Input
                                      className="custom-control-input"
                                      id="customRadio9"
                                      name="custom-radio-3"
                                      type="radio"
                                      value={4}
                                      checked={statusaf === 4}
                                      onChange={() => setStatusAf(4)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio9"
                                    >
                                    </Label>
                                    <b>TOLAK</b>
                                  </div>
                                </div>
                              </Col>
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                </Card>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Invoice</h3>
                    </CardHeader>
                        <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>SO</b></th>
                                <th><b>SJ</b></th>
                                <th><b>Faktur</b></th>
                                <th><b>Tempo</b></th>
                                <th><b>Tagihan</b></th>
                                <th><b>Keterangan</b></th>
                                <th><b>Potongan</b></th>
                                <th><b>Bayar</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.code_so}</td>
                                      <td>{savedItem.code_sj}</td>
                                      <td>{savedItem.code_invoice}</td>
                                      <td>{savedItem.tgl_tempo}</td>
                                      <td><>{formatRupiah(savedItem.payment_total)}</></td>
                                      <td>{savedItem.keterangan2}</td>
                                      <td><>{formatRupiah(savedItem.potongan)}</></td>
                                      <td><>{formatRupiah(savedItem.bayar)}</></td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/bbm-finance/validasi-kepala-finance">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={()=>setQuestionAlert()}>
                          Simpan
                      </Button>
                </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}

export default ValidasiBbmFinance;