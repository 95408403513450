/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Link} from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function DetailAdjusmentHistori(props)  {
const token = localStorage.token;
  const [keterangan,setKeterangan] = useState("");
  const [savedItems, setSavedItems] = useState([]);

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/adjustment/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setKeterangan(data.data.response.keterangan);
        getItemDataSaved();
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const getItemDataSaved = () => {
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/adjustment/item`, {

        adjustment_id: props.match.params.id

      }).then(async response => {
          let stateItem = [];

          await Promise.all(response.data.response.map(async (data) => {
              stateItem = [...stateItem, {
                  item_id: data.item_id,
                  code_rak: data.code_rak,
                  item_name:data.item_name,
                  qty: data.qty,
                  reason:data.reason,
                  ket:data.ket,
                  data: {
                    item_name: data.item_name,
                    qty: data.qty,
                    reason:data.reason,
                    ket:data.ket,
                  },
              }];
          }));

          setSavedItems(stateItem);
      })
  }

  return (
    <>
    <SimpleHeader name="Detail Adjusment" parentName="" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Detail Adjusment</h3>
                    </CardHeader>
                <CardBody>
                    <CardBody>
                      <Row md="12">
                          <Col md="9">
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={2}
                              >
                                Keterangan
                              </Label>
                              <Col sm={10}>
                                <Input
                                disabled
                                autoComplete="off"
                                  type="textarea"
                                  name="keterangan"
                                  placeholder="Masukan Keterangan"
                                  style={{
                                    fontSize: 14,
                                    paddingTop: 20,
                                    top: "50%",
                                    height: 117,
                                    resize: "none",
                                  }}
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                    <CardBody>
                      <Table>
                              <thead>
                                <tr>
                                <th>
                                    Kode Rak
                                  </th>
                                  <th>
                                    Nama Item
                                  </th>
                                  <th>
                                  Qty
                                  </th>
                                  <th>
                                    Alasan
                                  </th>
                                  <th>
                                    Keterangan
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                    savedItems.map((savedItem, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{savedItem.code_rak}</td>
                                                <td>{savedItem.data.item_name}</td>
                                                <td>{savedItem.qty}</td>
                                                <td>{savedItem.reason}</td>
                                                <td>{savedItem.ket}</td>
                                            </tr>
                                        )
                                    })
                                }
                              </tbody>
                      </Table>
                    </CardBody>
                </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/stock-adjustment/histori">
                        Kembali
                      </Link>
                </CardFooter>
              </Card>
          </div>
        </Row>
    </Container>  
    </>
  );
}