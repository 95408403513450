/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Badge,
	Input,
	CardHeader,
	Col,
	Button,
	Table,
	Container,
	CardGroup,
} from "reactstrap";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Barcodee from "react-barcode";
import QRCode from "qrcode.react";
import CurrencyInput from 'react-currency-input-field';
import Halaman404 from "views/404";

export default function ItemCabang() {
	const token = localStorage.token;
	const warehouseId = localStorage.warehouse;
	const [rowIndex, setRowIndex] = useState(0);
	const [page, setPage] = useState(1);
	const [perPage, setPerpage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [currentSort, setCurrentSort] = useState("");
	const [itemCode, setItemCode] = useState("");
	const [nameItem, setNameItem] = useState("");
	const [description, setDescription] = useState("");
	const [satuan, setSatuan] = useState("");
	const [grade, setGrade] = useState("");
	const [category, setCategory] = useState("")
	const [subCategory, setSubCategory] = useState("");
	const [nameFunction, setNameFunction] = useState("")
	const [nameSubFunction, setNameSubFunction] = useState("");
	const [merek, setMerek] = useState("");
	const [submerek, setSubMerek] = useState("");
	const [panjang, setPanjang] = useState("");
	const [lebar, setLebar] = useState("");
	const [tinggi, setTinggi] = useState("");
	const [nameType, setNameType] = useState(1);
	const [hargaBeli, setHargaBeli] = useState(0);
	const [allpajak, setAllPajak] = useState([]);
	const [allpajaks, setAllPajaks] = useState([]);
	const [pajakBeli, setPajakBeli] = useState(11);
	const [pajakBeliNominal, setPajakBeliNominal] = useState(0);
	const [pajakjual, setPajakJual] = useState(11);
	const [pajakjualnominal, setPajakJualNominal] = useState(0);
	const [pajakjualnominal2, setPajakJualNominal2] = useState(0);
	const [pajakjualnominal3, setPajakJualNominal3] = useState(0);
	const [pajakjualnominal4, setPajakJualNominal4] = useState(0);
	const [pajakjualnominal5, setPajakJualNominal5] = useState(0);
	const [diskonsuplierpersen, setDiskonSuplierPersen] = useState(0);
	const [diskonnominal, setDiskonNominal] = useState(0);
	const [diskonpersen, setDiskonPersen] = useState(0);
	const [diskonsupliernominal, setDiskonSuplierNominal] = useState(0);
	const [hargajualminimal, setHargaJualMinimal] = useState(0);
	const [hargajual, setHargaJual] = useState(0);
	const [hargabelitotal, setHargaBeliTotal] = useState(0);
	const [marginglobalpersen, setMarginGlobalPersen] = useState(0);
	const [marginglobalnominal, setMarginGlobalNominal] = useState(0);
	const [barcode, setBarcode] = useState("");
	const [status, setStatus] = useState("");
	const [minimumstock, setMinimumStock] = useState("");
	const [hmd, setHMD] = useState(0);
	const [level1, setLevel1] = useState("");
	const [level2, setLevel2] = useState("");
	const [level3, setLevel3] = useState("");
	const [level4, setLevel4] = useState("");
	const [level5, setLevel5] = useState("");
	const [level6, setLevel6] = useState("");
	const [margin1, setMargin1] = useState(0);
	const [margin2, setMargin2] = useState(0);
	const [margin3, setMargin3] = useState(0);
	const [margin4, setMargin4] = useState(0);
	const [margin5, setMargin5] = useState(0);
	const [margin6, setMargin6] = useState(0);
	const [marginke1, setMarginKe1] = useState(0);
	const [marginke2, setMarginKe2] = useState(0);
	const [marginke3, setMarginKe3] = useState(0);
	const [marginke4, setMarginKe4] = useState(0);
	const [marginke5, setMarginKe5] = useState(0);
	const [persentase1, setPersentase1] = useState(0);
	const [persentase2, setPersentase2] = useState(0);
	const [persentase3, setPersentase3] = useState(0);
	const [persentase4, setPersentase4] = useState(0);
	const [persentase5, setPersentase5] = useState(0);
	const [persentase6, setPersentase6] = useState(0);
	const [qty1, setQty1] = useState(0);
	const [qty2, setQty2] = useState(0);
	const [qty3, setQty3] = useState(0);
	const [qty4, setQty4] = useState(0);
	const [qty5, setQty5] = useState(0);
	const [qty6, setQty6] = useState(0);
	const [price1, setPrice1] = useState(0);
	const [price2, setPrice2] = useState(0);
	const [price3, setPrice3] = useState(0);
	const [price4, setPrice4] = useState(0);
	const [price5, setPrice5] = useState(0);
	const [price6, setPrice6] = useState(0);
	const [itemgrup, setItemGrup] = useState("");
	const [berat, setBerat] = useState("");
	const [image1, setImage1] = useState("");
	const [image2, setImage2] = useState("");
	const [image3, setImage3] = useState("");
	const [logPrice, setLogPrice] = useState([]);
	const [allItem, setAllItem] = useState([]);
	const [query, setQuery] = useState('');
	const [isSearchShow, setIsSearchShow] = useState(false);
	const headers = { Authorization: `Bearer ${token}` };
	const [diskon, setDiskon] = useState("");
	const [pajak, setPajak] = useState("");
	const [margin, setMargin] = useState("");
	const [diskonJual, setDiskonJual] = useState("");
	const allInfo = JSON.parse(localStorage.allinfo);
	const itemToko = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Item Toko").map((p) => p.read_access));
	const LogHarga = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Log Harga").map((p) => p.read_access));
	const [itemproduksi,setItemProduksi ] = useState([])
	

	useEffect(() => {
		console.log('page changed');
		getItems();
	}, [page]);

	//get list item berdasarkan warehouse
	const getItems = async () => {
		let filter = {
			page: page,
			per_page: 1,
			warehouse_id: parseInt(warehouseId),
		};
		const data = filter;
		const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/items-po`, data, { headers });
		if(res.data.response.id !== null){
			getById(res.data.response[0].id);
			getPriceDetail(res.data.response[0].id);
			setTotalPages(res.data.total_page);
		}
	};

	//cari item
	useEffect(() => {
		const getData = setTimeout(async() => {
			if(query != ''){
				axios
				.post(`${process.env.REACT_APP_API_BASE_URL}/items-po`, { item_info: query , per_page: 10, warehouse_id: parseInt(warehouseId),  },{ headers })
				.then((res) => {
					setAllItem(res.data);
					setIsSearchShow(true);
				})
				.catch(function (error) {
					setAllItem(error.response.data.response);
					setIsSearchShow(true);
					// alert("Internet Sedang Tidak Stabil")
				});
			}else{
			}
		  }, 1000)
		  return () => clearTimeout(getData)
	}, [query]);

	 // diskon supplier 
	 useEffect(() => {
		diskonsupliernominal && diskon === "diskonNominal" && setDiskonSuplierPersen(diskonsuplierpersen);
		diskonsuplierpersen && diskon === "diskonPersen" && setDiskonSuplierNominal(hargaBeli * (diskonsuplierpersen / 100));
	}, [hargaBeli, diskonsuplierpersen, diskonsupliernominal]);

	// Harga - PPN supplier
	useEffect(() => {
		pajakBeliNominal && pajak === "pajakNominal" && setPajakBeli((pajakBeliNominal / hargaBeli) * 100);
		pajakBeli && pajak === "pajakPersen" && setPajakBeliNominal(hargaBeli * (pajakBeli / 100));
	}, [hargaBeli,pajak, pajakBeli, pajakBeliNominal]);

	// Harga - Harga Beli Total
	useEffect(() => {
		hargaBeli && diskonsupliernominal && pajakBeliNominal && setHargaBeliTotal(Number(hargaBeli) - Number(diskonsupliernominal) + Number(pajakBeliNominal));
	}, [hargaBeli, diskonsuplierpersen, diskonsupliernominal, pajakBeli, pajakBeliNominal]);

	// Harga - HPP
	useEffect(() => {
		setHargaJualMinimal(hargabelitotal);
	}, [hargabelitotal]);

	// Harga - Margin
	useEffect(() => {
		marginglobalnominal && margin === "marginNominal" && setMarginGlobalPersen((marginglobalnominal / hargajualminimal) * 100);
		marginglobalpersen && margin === "marginPersen" && setMarginGlobalNominal(hargajualminimal * (marginglobalpersen / 100));
	}, [hargajualminimal, marginglobalpersen, marginglobalnominal]);

	// Harga - Diskon
	useEffect(() => {
		diskonnominal && diskonJual === "diskonNominal" && setDiskonPersen((diskonnominal / hargajualminimal) * 100);
		diskonpersen && diskonJual === "diskonPersen" && setDiskonNominal(hargajualminimal * (diskonpersen / 100));
	}, [hargajualminimal, diskonpersen, diskonnominal]);

	// Harga HPP + MarginNominal - DiskonNominal
	useEffect(() => {
		setHMD(Number(hargajualminimal) + Number(marginglobalnominal) - Number(diskonnominal) );
	}, [hargajualminimal,marginglobalnominal,diskonnominal]);

	// Harga - PPN
	useEffect(() => {
		setPajakJualNominal(Number(hmd) * (pajakjual / 100));
	}, [hargajualminimal, pajakjual, pajakjualnominal,hmd]);

	// PPN Price 2
	useEffect(() => {
		setPajakJualNominal2((Number(hargajualminimal) + Number(margin2) - Number(diskonnominal)) * (pajakjual / 100));
	}, [hargajualminimal, pajakjual, diskonnominal,margin2]);

	// PPN Price 3
	useEffect(() => {
		setPajakJualNominal3((Number(hargajualminimal) + Number(margin3) - Number(diskonnominal)) * (pajakjual / 100));
	}, [hargajualminimal, pajakjual, diskonnominal,margin3]);

	// PPN Price 4
	useEffect(() => {
		setPajakJualNominal4((Number(hargajualminimal) + Number(margin4) - Number(diskonnominal)) * (pajakjual / 100));
	}, [hargajualminimal, pajakjual, diskonnominal,margin4]);

	// PPN Price 5
	useEffect(() => {
		setPajakJualNominal5((Number(hargajualminimal) + Number(margin5) - Number(diskonnominal)) * (pajakjual / 100));
	}, [hargajualminimal, pajakjual, diskonnominal,margin5]);

	// Harga - Harga Jual
	useEffect(() => {
			setHargaJual(Number(hargajualminimal)+ Number(marginglobalnominal) - Number(diskonnominal) + Number(pajakjualnominal));
	}, [hargajualminimal, marginglobalnominal, diskonnominal, pajakjualnominal]);

	// Margin 1

	useEffect(() => {
		margin1 && marginke1 === "marginnominal1" && setPersentase1((margin1 / hargajualminimal) * 100);
		persentase1 && marginke1 === "marginpersentase1" && setMargin1(hargajualminimal * (persentase1 / 100));
	}, [margin1, hargajualminimal, persentase1]);

	useEffect(() => {
		setPersentase1(marginglobalpersen);
		setMargin1(marginglobalnominal);
	}, [margin1, hargajual, persentase1]);

	// Margin 2
	useEffect(() => {
		margin2 && marginke2 === "marginnominal2" && setPersentase2((margin2 / hargajualminimal) * 100);
		persentase2 && marginke2 === "marginpersentase2" && setMargin2(hargajualminimal * (persentase2 / 100));
	}, [margin2, hargajualminimal, persentase2]);

	// Margin 3
	useEffect(() => {
		margin3 && marginke3 === "marginnominal3" && setPersentase3((margin3 / hargajualminimal) * 100);
		persentase3 && marginke3 === "marginpersentase3" && setMargin3(hargajualminimal * (persentase3 / 100));
	}, [margin3, hargajualminimal, persentase3]);

	// Margin 4
	useEffect(() => {
		margin4 && marginke4 === "marginnominal4" && setPersentase4((margin4 / hargajualminimal) * 100);
		persentase4 && marginke4 === "marginpersentase4" && setMargin4(hargajualminimal * (persentase4 / 100));
	}, [margin4, hargajualminimal, persentase4]);

	// Margin 5
	useEffect(() => {
		margin5 && marginke5 === "marginnominal5" && setPersentase5((margin5 / hargajualminimal) * 100);
		persentase5 && marginke5 === "marginpersentase5" && setMargin5(hargajualminimal * (persentase5 / 100));
	}, [margin5, hargajualminimal, persentase5]);

	//price 1

	useEffect(() => {
		setPrice1(hargajual);
	}, [hargajual]);
	
	//price 2

	useEffect(() => {
		margin2 && hargajualminimal && setPrice2(Number(hargajualminimal) +  Number(margin2) - Number(diskonnominal) + Number(pajakjualnominal2));
	}, [margin2, hargajualminimal,pajakjualnominal2,diskonnominal]);

	//price 3

	useEffect(() => {
		margin3 && hargajualminimal && setPrice3(Number(hargajualminimal) +  Number(margin3) - Number(diskonnominal) + Number(pajakjualnominal3));
	}, [margin3, hargajualminimal,pajakjualnominal3,diskonnominal]);

	//price 4

	useEffect(() => {
		margin4 && hargajualminimal && setPrice4(Number(hargajualminimal) +  Number(margin4) - Number(diskonnominal) + Number(pajakjualnominal4));
	}, [margin4, hargajualminimal,pajakjualnominal4,diskonnominal]);

	//price 5

	useEffect(() => {
		margin5 && hargajualminimal && setPrice5(Number(hargajualminimal) +  Number(margin5) - Number(diskonnominal) + Number(pajakjualnominal5));
	}, [margin5, hargajualminimal,pajakjualnominal5,diskonnominal]);

	const getById = (id) => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/get-items-detail?id=${id}&wid=${warehouseId}`,
	        { headers }
	      )
	      .then((data) => {
			setSatuan(data.data.response.satuan);
			getPajakBeli(data.data.response.pajak_beli);
			setGrade(data.data.response.grade);
			setCategory(data.data.response.kategori);
			setSubCategory(data.data.response.sub_kategori);
			setNameFunction(data.data.response.function);
			setNameSubFunction(data.data.response.sub_function);
			setMerek(data.data.response.merek);
			setSubMerek(data.data.response.sub_merek);
			setItemGrup(data.data.response.group)
			setBarcode(data.data.response.barcode);
	        setBerat(data.data.response.berat);
	        setStatus(data.data.response.active_flag);
	        setSatuan(data.data.response.uom_code);
	        setMerek(data.data.response.merek_name);
	        setNameItem(data.data.response.item_name);
	        setDescription(data.data.response.item_description);
	        setPanjang(data.data.response.panjang);
	        setLebar(data.data.response.lebar);
			setHargaBeli(data.data.response.harga_beli);
	        setHargaBeliTotal(data.data.response.hpp_beli);
	        setHargaJualMinimal(data.data.response.hpp_jual);
	        setDiskonNominal(data.data.response.diskon_nominal);
			setMarginGlobalNominal(data.data.response.margin_global_nominal);
			setMarginGlobalPersen(data.data.response.margin_global_persen);
	        setDiskonPersen(data.data.response.diskon_persen);
	        setDiskonSuplierNominal(data.data.response.diskon_nominal_suplier);
	        setDiskonSuplierPersen(data.data.response.diskon_persen_suplier);
			setHargaJual(data.data.response.harga_jual);
	        setImage1(data.data.response.image_1);
	        setImage2(data.data.response.image_2);
	        setImage3(data.data.response.image_3);
	        setTinggi(data.data.response.tinggi);
	        setMinimumStock(data.data.response.minimum_stok);
	        setItemCode(data.data.response.item_code);
			setNameType(data.data.response.type);
	        setLevel1(data.data.response.level_1);
	        setLevel2(data.data.response.level_2);
	        setLevel3(data.data.response.level_3);
	        setLevel4(data.data.response.level_4);
	        setLevel5(data.data.response.level_5);
	        setLevel6(data.data.response.level_6);
	        setPersentase1(data.data.response.persentase_1);
	        setPersentase2(data.data.response.persentase_2);
	        setPersentase3(data.data.response.persentase_3);
	        setPersentase4(data.data.response.persentase_4);
	        setPersentase5(data.data.response.persentase_5);
	        setPersentase6(data.data.response.persentase_6);
	        setMargin1(data.data.response.nominal_1);
	        setMargin2(data.data.response.nominal_2);
	        setMargin3(data.data.response.nominal_3);
	        setMargin4(data.data.response.nominal_4);
	        setMargin5(data.data.response.nominal_5);
	        setMargin6(data.data.response.nominal_6);
	        setQty1(data.data.response.min_qty_1);
	        setQty2(data.data.response.min_qty_2);
	        setQty3(data.data.response.min_qty_3);
	        setQty4(data.data.response.min_qty_4);
	        setQty5(data.data.response.min_qty_5);
	        setPrice1(data.data.response.price_1);
	        setPrice2(data.data.response.price_2);
	        setPrice3(data.data.response.price_3);
	        setPrice4(data.data.response.price_4);
	        setPrice5(data.data.response.price_5);
	        setPrice6(data.data.response.price_6);
			setItemProduksi(data.data.response.part);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

	  const getPriceDetail = (id) => {
		let filter = { 
		  item_id : id,
		  warehouse_id :warehouseId
		};
		const data = filter;
		const headers = {
		  "Content-Type": "application/json",
		  Authorization: `Bearer ${token}`,
		};
		axios
		  .post(`${process.env.REACT_APP_API_BASE_URL}/log-pd/page`, data, {
			headers,
		  })
		  .then((data) => {
			setLogPrice(data.data.response);
			setPerpage(data.data.per_page);
		  })
		  .catch(function (error) {
			console.log(error);
		  });
	  };

	  useEffect(() => {
		getPajakJual();
		getPajakBeli();
	}, []);

	const getPajakJual = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
			.then((data) => {
				setAllPajak(data.data.response);
				setPajakJual(id)
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	
	const getPajakBeli = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
			.then((data) => {
				setAllPajaks(data.data.response);
				setPajakBeli(id);
			})
			.catch(function (error) {
				console.log(error);
			});
	};


	let paginationOption = {
		page: page,
		alwaysShowAllBtns: true,
		override: true,
		showTotal: true,
		withFirstAndLast: false,
		sizePerPage: perPage,
		onPageChange: (page) => {
			updateDataTable(page, perPage, currentSort);
		},
		sizePerPageRenderer: () => (
			<div className="dataTables_length" id="datatable-basic_length">
				<label
				className="form-control-label"
				>
					Show{" "}
					{
						<select
							name="datatable-basic_length"
							aria-controls="datatable-basic"
							className="form-control form-control-sm"
							onChange={(e) => {
								updateDataTable(page, e.target.value, currentSort);
							}}>
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="25">25</option>
							<option value="50">50</option>
						</select>
					}{" "}
					entries.
				</label>
			</div>
		),
	};

	const updateDataTable = (page, perPage, sort) => {
		setPerpage(perPage);
		setRowIndex((page - 1) * perPage);
		setCurrentSort(sort);
	};

	const handleTableChange = (type, { sortField, sortOrder }) => {
		if (type === "sort") {
			let sort = `${sortField} ${sortOrder}`;
			updateDataTable(page, perPage, sort);
		}
	};


	const searchShow = (item) => {
		getById(item.id);
		getPriceDetail(item.id);
		setIsSearchShow(false);
	};

	const formatRupiah = (money) => {
		return new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR", minimumFractionDigits: 0 }).format(money);
	};

	const pricehargabeli = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setHargaBeli(value || ' ');
																
	  };

	  const diskonpersensupplier = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setDiskonSuplierPersen(value || ' ');
		setDiskon("diskonPersen")
																
	  };

	  const diskonnominalsupplier = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setDiskonSuplierNominal(value || ' ');
		setDiskon("diskonNominal")
																
	  };

	const pricehargabelitotal = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setHargaBeliTotal(value || ' ');
																
	  };

	const pricehpp = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setHargaJualMinimal(value || ' ');
																
	  };

	const marginpersenhj = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setMarginGlobalPersen(value || ' ');
		setMargin("marginPersen")
																
	  };

	  const marginnominalhj = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setMarginGlobalNominal(value || ' ');
		setMargin("marginNominal")
																
	  };

	const diskonpersenhj = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setDiskonPersen(value || ' ');
		setDiskonJual("diskonPersen");
	  };

	  const diskonnominalhj = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setDiskonNominal(value || ' ');
		setDiskonJual("diskonNominal");
	  };

	const hargapricejual = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setHargaJual(value || ' ')
	
	  };

	const hargaprice1 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPrice1(value || ' ')
	
	  };

	  const hargaprice2 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPrice2(value || ' ')
	
	  };

	  const hargaprice3 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPrice3(value || ' ')
	
	  };

	  const hargaprice4 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPrice4(value || ' ')
	
	  };

	  const hargaprice5 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPrice5(value || ' ')
	
	  };

	  const hargaprice6 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPrice6(value || ' ')
	
	  };

	  const nominalmargin1 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setMargin1(value || ' ');
		setMarginKe1("marginnominal1")
	
	  };

	  const nominalmargin2 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setMargin2(value || ' ');
		setMarginKe2("marginnominal2")
	
	  };

	  const nominalmargin3 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setMargin3(value || ' ');
		setMarginKe3("marginnominal3")
	
	  };

	  const nominalmargin4 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setMargin4(value || ' ');
		setMarginKe4("marginnominal4")
	
	  };

	  const nominalmargin5 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setMargin5(value || ' ');
		setMarginKe5("marginnominal5")
	
	  };

	  const nominalmargin6 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setMargin6(value || ' ');
	
	  };

	  const persenmargin1 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPersentase1(value || ' ');
		setMarginKe1("marginpersentase1")
	  };

	  const persenmargin2 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPersentase2(value || ' ');
		setMarginKe2("marginpersentase2")
	  };

	  const persenmargin3 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPersentase3(value || ' ');
		setMarginKe3("marginpersentase3")
	  };

	  const persenmargin4 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPersentase4(value || ' ');
		setMarginKe4("marginpersentase4")
	  };

	  const persenmargin5 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPersentase5(value || ' ');
		setMarginKe5("marginpersentase5")
	  };

	  const persenmargin6 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPersentase6(value || ' ');
	  };


	return (
		<>
			{itemToko && itemToko === "1" ? (
			<div>
				<SimpleHeader name="Item Toko" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
								<Card className="position-sticky boxShadow" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
											<Col md="5 d-lg-block d-none">
												<Button onClick={() => setPage(1)} disabled={page === 1} color="danger" type="button">
													First
												</Button>
												<Button onClick={() => setPage((page) => page - 1)} disabled={page === 1} color="success" type="button">
												<i className="fa fa-angle-left" /> Prev
												</Button>
												<Button onClick={() => setPage((page) => page + 1)} disabled={page >= totalPages} color="success" type="button">
													Next <i className="fa fa-angle-right" />
												</Button>
												<Button onClick={() => setPage(totalPages)} disabled={page == totalPages} color="warning" type="button">
													End
												</Button>
											</Col>
											<Col md="3">
												<FormGroup row>
													<Col sm={10}>
														<Input
															placeholder="Search Item"
															type="search"
															value={query}
															onChange={(e) => setQuery(e.target.value)}
														/>
													</Col>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
								</Card>

								{isSearchShow && query && (
									<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
										<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
											<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
										</div>
										<span className="text-center mb-3">
											<b>Pencarian berdasarkan:</b> {query}
										</span>
										{allItem?.response ? (
											allItem.response.map((item) => (
												<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" onClick={() => searchShow(item)}style={{minHeight: 'auto'}}>
													<div className=" d-flex align-items-center">
														<div className="ml-3">
															<img src={item.image_url} height="50px" width="50px" className="img-thumbnail" />
														</div>
														<div className="ml-3">
															<div>
																<b>Nama item:</b> {item.item_name}
															</div>
															<div>
																<b>Kode item:</b> {item.item_code ? item.item_code : "(Not available)"}
															</div>
														</div>
														<div className="ml-3">
															<div>
																<b>Barcode:</b> {item.barcode ? item.barcode : "(Not available)"}
															</div>
															<div>
																<b>Price:</b>Rp. {new Intl.NumberFormat().format(item.price)}
															</div>
														</div>
													</div>
												</CardBody>
											))
										) : (
											<div className="text-center mb-3 text-danger">Item "{query}" tidak ada bosku!</div>
										)}
									</Card>
								)}
								<CardBody>
									<Row md="12">
										<Col md="7">
											<Card className="bg-secondary shadow">
												<CardHeader className="bg-white border-0">
												<div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <h3>ITEM</h3>
                                                        <div style={{ textAlign: 'right' }}>
                                                            <h1>
                                                                {status === 1 ?  
                                                                <Badge color="success">
                                                                    AKTIF
                                                                </Badge> :  
                                                                <Badge color="danger">
                                                                    TIDAK AKTIF
                                                                </Badge>}
                                                           
                                                            </h1>
                                                        </div>
                                                    </div>
												</CardHeader>
												<CardBody response>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Kode Item
														</Label>
														<Col sm={7}>
															<Input
																disabled
																className="form-control-alternative"
																type="text"
																name="itemCode"
																placeholder="Masukan Kode Item"
																value={itemCode}
																onChange={(e) => {
																	setItemCode(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Barcode
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="barcode"
																placeholder="Masukan Barcode"
																value={barcode}
																onChange={(e) => {
																	setBarcode(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Nama Item
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="nameItem"
																placeholder="Masukan Nama Item"
																value={nameItem}
																onChange={(e) => {
																	setNameItem(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Jenis Barang
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																name="kategory"
																type="text"
																value={itemgrup}
																onChange={(e) => {
																	setItemGrup(e.target.value);
																}}>
																
															</Input>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Satuan
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																name="satuan"
																type="text"
																value={satuan}
																onChange={(e) => {
																	setSatuan(e.target.value);
																}}>
																
															</Input>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Dimensi <b>(PxLxT)</b>
														</Label>
														<Col sm={2}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="panjang"
																placeholder="Panjang"
																value={panjang}
																onChange={(e) => {
																	setPanjang(e.target.value);
																}}
															/>
														</Col>
														<Col sm={3}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="lebar"
																placeholder="Lebar"
																value={lebar}
																onChange={(e) => {
																	setLebar(e.target.value);
																}}
															/>
														</Col>
														<Col sm={2}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="tinggi"
																placeholder="Tinggi"
																value={tinggi}
																onChange={(e) => {
																	setTinggi(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Berat
														</Label>
														<Col sm={4}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="Berat"
																placeholder="Masukan Berat"
																value={berat}
																onChange={(e) => {
																	setBerat(e.target.value);
																}}
															/>
														</Col>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															<b>Gram</b>
														</Label>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Minimum Stock
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																type="number"
																name="stock"
																placeholder="Masukan Stock"
																value={minimumstock}
																onChange={(e) => {
																	setMinimumStock(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
												</CardBody>
											</Card>
										</Col>
										<Col md="5">
											<Card className="bg-secondary shadow">
												<CardHeader className="bg-white border-0">
													<h3>Deskripsi</h3>
												</CardHeader>
												<CardBody>
													<FormGroup row>
														<Input
															disabled
															className="form-control-alternative"
															rows="13"
															type="textarea"
															name="deskripsi Item"
															placeholder="Masukan Deskripsi Item"
															value={description}
															onChange={(e) => {
																setDescription(e.target.value);
															}}
														/>
													</FormGroup>
													<FormGroup row>
														<Card>
															<CardBody>
																<CardGroup>
																	<Row md="10" center>
																		<Col md="5">
																			<QRCode className="qrcode"  value={barcode} />
																		</Col>
																		<Col md="5">
																			<Barcodee className="qrcode" value={barcode} />
																		</Col>
																	</Row>
																</CardGroup>
															</CardBody>
														</Card>
													</FormGroup>
												</CardBody>
											</Card>
										</Col>
									</Row>
									<Card className="bg-secondary shadow">
									<CardHeader className="bg-white border-0">
										<h3>Gambar</h3>
									</CardHeader>
									<CardBody>
										<Row md="12">
											<Col md="10">
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={3}>
														<b>Foto Produk</b><br></br>
														Format gambar .jpg .jpeg .png dan ukuran minimum <b>300px X 300px</b>.
													</Label>
													<Col sm={2}>
													{image1 === "" ?(
														<img
														alt="..."
														src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
														width="100%"
														/>
													):(
													<img
														alt=""
														src={image1}
														width="100%"
													/>
													)}
													</Col>
													<Col sm={2}>
													{image2 === "" ?(
														<img
														alt="..."
														src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
														width="100%"
														/>
													):(
													<img
														alt=""
														src={image2}
														width="100%"
													/>
													)}
													</Col>
													<Col sm={2}>
													{image3 === "" ?(
														<img
														alt="..."
														src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
														width="100%"
														/>
													):(
													<img
														alt=""
														src={image3}
														width="100%"
													/>
													)}
													</Col>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
									</Card>
									<Card className="bg-secondary shadow">
									<CardHeader className="bg-white border-0">
										<h3>KATEGORI</h3>
									</CardHeader>
									<CardBody>
										<Row md="12">
											<Col md="6">
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Tipe Item
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															type="text"
															name="nameItem"
															placeholder="Masukan Tipe Item"
															value={nameType}
															onChange={(e) => {
																setNameType(e.target.value);
															}}>
														</Input>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Kategori
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="kategory"
															type="text"
															value={category}
															onChange={(e) => {
																setCategory(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Sub Kategori
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="subKategory"
															type="text"
															value={subCategory}
															onChange={(e) => {
																setSubCategory(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Function
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="function"
															type="text"
															value={nameFunction}
															onChange={(e) => {
																setNameFunction(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Sub Function
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="subFunction"
															type="text"
															value={nameSubFunction}
															onChange={(e) => {
																setNameSubFunction(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
											</Col>
											<Col md="6">
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Merek
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="merek"
															type="text"
															value={merek}
															onChange={(e) => {
																setMerek(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Sub Merek
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="merek"
															type="text"
															value={submerek}
															onChange={(e) => {
																setSubMerek(e.target.value);

															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Grade
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="grade"
															type="text"
															value={grade}
															onChange={(e) => {
																setGrade(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												{/* <FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Status
													</Label>
													<Col sm={7}>
														<div style={{ display: "flex" }}>
															<div className="custom-control custom-radio mb-3">
																<Input
																disabled
																autoComplete="off"
																	className="custom-control-input"
																	id="customRadio11"
																	name="custom-radio-4"
																	type="checkbox"
																	value={1}
																	checked={status === 1}
																	onChange={() => setStatus(1)}
																/>
																<Label
																className="custom-control-label" htmlFor="customRadio11">
																	Aktif
																</Label>
															</div>
															<div className="custom-control custom-radio mb-3" style={{ marginLeft: "20px" }}>
																<Input
																disabled
																autoComplete="off"
																	className="custom-control-input"
																	id="customRadio12"
																	name="custom-radio-4"
																	type="radio"
																	value={2}
																	checked={status === 2}
																	onChange={() => setStatus(2)}
																/>
																<Label
																className="custom-control-label" htmlFor="customRadio12">
																	Tidak Aktif
																</Label>
															</div>
														</div>
													</Col>
												</FormGroup> */}
											</Col>
										</Row>
									</CardBody>
									</Card>
									<Card>
										<CardHeader>
											<div style={{ display: "flex", justifyContent: "space-between" }}>
												<h3>Item Produksi</h3>
											</div>
										</CardHeader>
										<CardBody>
										{itemproduksi.length > 0 ? (
											<ToolkitProvider
												rowNumber={rowIndex}
												data={itemproduksi}
												keyField="id"
												columns={[
													{
														dataField: "no",
														text: "#",
														sort: true,
														page: 1,
														formatter: (cell, row, index) => {
															let currentRow = ++index;
															return currentRow + rowIndex;
														},
													},
													{
														dataField: "item_name",
														text: "Nama Item",
														sort: true,
													},
													{
														dataField: "item_code",
														text: "Kode Item",
														sort: true,
													},
													{
														dataField: "qty",
														text: "Qty",
														sort: true,
													},
													{
														dataField: "satuan",
														text: "Satuan",
														sort: true,
													},
												]}>
												{(props) => (
													<div className="py-4 table-responsive">
														<BootstrapTable
															remote
															{...props.baseProps}
															bootstrap4={true}
															bordered={false}
															hover={true}
															pagination={paginationFactory({ ...paginationOption })}
															onTableChange={handleTableChange}
														/>
													</div>
												)}
											</ToolkitProvider>
											):(
												<CardHeader>
													<center><h3>DATA NOT FOUND</h3></center>
												</CardHeader>
												)}
										</CardBody>
									</Card>
									<Card className="bg-secondary shadow">
										<CardHeader className="bg-white border-0">
											<h3>HARGA</h3>
										</CardHeader>
										<CardBody>
											<Row md="12">
												<Col md="6">
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Harga Beli
														</Label>
														<Col sm={7}>
															<CurrencyInput
																disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={hargaBeli}
																onValueChange={pricehargabeli}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
														</Col>
													</FormGroup>
													<FormGroup row floating>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Diskon
														</Label>
														<Col sm={3}>
															<CurrencyInput
																disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={diskonsuplierpersen}
																onValueChange={diskonpersensupplier}
																className={`form-control`}
																suffix={'%'}
																step={10}
															/>
														</Col>
														<Col sm={4}>
															<CurrencyInput
																disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={diskonsupliernominal}
																onValueChange={diskonnominalsupplier}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															PPN
														</Label>
														<Col sm={7}>
															<Input
															disabled
																autoComplete="off"
																type="select"
																value={pajakBeli}
																onChange={(e) => {
																	setPajakBeli(e.target.value);
																}}
																>
																<option value="">Pilih PPN</option>
																{allpajaks.map((customer, key) => {
																	return (
																		<option key={key} value={customer.persentase}>
																			{customer.keterangan}
																		</option>
																	);
																})}
															</Input>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Harga Beli Total
														</Label>
														<Col sm={7}>
															<CurrencyInput
																disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={hargabelitotal}
																onValueChange={pricehargabelitotal}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
														</Col>
													</FormGroup>
												</Col>
												<Col md="6">
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															HPP
														</Label>
														<Col sm={7}>
															<CurrencyInput
																disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={hargajualminimal}
																onValueChange={pricehpp}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="marginpersen" sm={4}>
															Margin
														</Label>
														<Col sm={3}>
															<CurrencyInput
															disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={marginglobalpersen}
																onValueChange={marginpersenhj}
																className={`form-control`}
																suffix={'%'}
																step={10}
															/>
														</Col>
														<Col sm={4}>
															<CurrencyInput
															disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={marginglobalnominal}
																onValueChange={marginnominalhj}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Diskon
														</Label>
														<Col sm={3}>
															<CurrencyInput
															disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={diskonpersen}
																onValueChange={diskonpersenhj}
																className={`form-control`}
																suffix={'%'}
																step={10}
															/>
														</Col>
														<Col sm={4}>
															<CurrencyInput
															disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={diskonnominal}
																onValueChange={diskonnominalhj}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															PPN
														</Label>
														<Col sm={7}>
															<Input
															disabled
																autoComplete="off"
																type="select"
																value={pajakjual}
																onChange={(e) => {
																	setPajakJual(e.target.value);
																}}
																>
																<option value="" disabled selected hidden>Pilih PPN</option>
																{allpajak.map((customer, key) => {
																	return (
																		<option key={key} value={customer.persentase}>
																			{customer.keterangan}
																		</option>
																	);
																})}
															</Input>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Harga Jual
														</Label>
														<Col sm={7}>
															<CurrencyInput
																disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={hargajual}
																onValueChange={hargapricejual}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
														</Col>
													</FormGroup>
												</Col>
											</Row>
											<Table>
												<tbody>
													<tr>
														<td>
															<Label
															className="form-control-label"
															for="exampleEmail" sm={4}>
																Level
															</Label>
														</td>
														<td>
															<Input
															autoComplete="off"
																className="form-control-alternative"
																type="text"
																name="Level1"
																value={level1}
																onChange={(e) => {
																	setLevel1(e.target.value);
																}}
																disabled
															/>
														</td>
														<td>
															<Input
															autoComplete="off"
																className="form-control-alternative"
																type="text"
																name="Level2"
																value={level2}
																onChange={(e) => {
																	setLevel2(e.target.value);
																}}
																disabled
															/>
														</td>
														<td>
															<Input
															autoComplete="off"
																className="form-control-alternative"
																type="text"
																name="Level3"
																value={level3}
																onChange={(e) => {
																	setLevel3(e.target.value);
																}}
																disabled
															/>
														</td>
														<td>
															<Input
															autoComplete="off"
																className="form-control-alternative"
																type="text"
																name="Level4"
																value={level4}
																onChange={(e) => {
																	setLevel4(e.target.value);
																}}
																disabled
															/>
														</td>
														<td>
															<Input
															autoComplete="off"
																className="form-control-alternative"
																type="text"
																name="Level5"
																
																value={level5}
																onChange={(e) => {
																	setLevel5(e.target.value);
																}}
																disabled
															/>
														</td>
														<td>
															<Input
															autoComplete="off"
																className="form-control-alternative"
																type="text"
																name="Level5"
																placeholder="Level 6"
																value={level6}
																onChange={(e) => {
																	setLevel6(e.target.value);
																}}
																disabled
															/>
														</td>
													</tr>
													<tr>
														<td>
															<Label
															className="form-control-label"
															for="exampleEmail" sm={4}>
																Min Qty
															</Label>
														</td>
														<td>
															<Input
															disabled
															autoComplete="off"
																className="form-control-alternative"
																type="number"
																name="qty1"
																placeholder="Qty 1"
																value={qty1}
																onChange={(e) => {
																	setQty1(e.target.value);
																}}
															/>
														</td>
														<td>
															<Input
															disabled
															autoComplete="off"
																className="form-control-alternative"
																type="number"
																name="qty2"
																placeholder="Qty 2"
																value={qty2}
																onChange={(e) => {
																	setQty2(e.target.value);
																}}
															/>
														</td>
														<td>
															<Input
															disabled
															autoComplete="off"
																className="form-control-alternative"
																name="qty3"
																type="number"
																placeholder="Qty 3"
																value={qty3}
																onChange={(e) => {
																	setQty3(e.target.value);
																}}
															/>
														</td>
														<td>
															<Input
															disabled
															autoComplete="off"
																className="form-control-alternative"
																name="qty4"
																type="number"
																placeholder="Qty 4"
																value={qty4}
																onChange={(e) => {
																	setQty4(e.target.value);
																}}
															/>
														</td>
														<td>
															<Input
															disabled
															autoComplete="off"
																className="form-control-alternative"
																name="qty5"
																type="number"
																placeholder="Qty 5"
																value={qty5}
																onChange={(e) => {
																	setQty5(e.target.value);
																}}
															/>
														</td>
														<td>
															<Input
															disabled
															autoComplete="off"
																className="form-control-alternative"
																name="qty5"
																type="number"
																placeholder="Qty 6"
																value={qty6}
																onChange={(e) => {
																	setQty6(e.target.value);
																}}
															/>
														</td>
													</tr>
													<tr>
														<td>
															<Label
															className="form-control-label"
															for="exampleEmail" sm={4}>
																Price
															</Label>
														</td>
														<td>
															<CurrencyInput
															disabled
																	id="validation-example-2-field"
																	placeholder="Rp0"
																	allowDecimals={false}
																	value={price1}
																	onValueChange={hargaprice1}
																	className={`form-control`}
																	prefix={'Rp'}
																	step={10}
																/>
														</td>
														<td>
														<CurrencyInput
														disabled
																	id="validation-example-2-field"
																	placeholder="Rp0"
																	allowDecimals={false}
																	value={price2}
																	onValueChange={hargaprice2}
																	className={`form-control`}
																	prefix={'Rp'}
																	step={10}
																/>
														</td>
														<td>
														<CurrencyInput
														disabled
																	id="validation-example-2-field"
																	placeholder="Rp0"
																	allowDecimals={false}
																	value={price3}
																	onValueChange={hargaprice3}
																	className={`form-control`}
																	prefix={'Rp'}
																	step={10}
																/>
														</td>
														<td>
														<CurrencyInput
														disabled
																	id="validation-example-2-field"
																	placeholder="Rp0"
																	allowDecimals={false}
																	value={price4}
																	onValueChange={hargaprice4}
																	className={`form-control`}
																	prefix={'Rp'}
																	step={10}
																/>
														</td>
														<td>
														<CurrencyInput
														disabled
																	id="validation-example-2-field"
																	placeholder="Rp0"
																	allowDecimals={false}
																	value={price5}
																	onValueChange={hargaprice5}
																	className={`form-control`}
																	prefix={'Rp'}
																	step={10}
																/>
														</td>
														<td>
														<CurrencyInput
														disabled
																	id="validation-example-2-field"
																	placeholder="Rp0"
																	allowDecimals={false}
																	value={price6}
																	onValueChange={hargaprice6}
																	className={`form-control`}
																	prefix={'Rp'}
																	step={10}
																/>
														</td>
													</tr>
													<tr>
														<td>
															<Label
															className="form-control-label"
															for="exampleEmail" sm={4}>
																Margin (N)
															</Label>
														</td>
														<td>
															<CurrencyInput
															disabled
																	id="validation-example-2-field"
																	placeholder="Rp0"
																	allowDecimals={false}
																	value={margin1}
																	onValueChange={nominalmargin1}
																	className={`form-control`}
																	prefix={'Rp'}
																	step={10}
																/>
														</td>
														<td>
																<CurrencyInput
																disabled
																	id="validation-example-2-field"
																	placeholder="Rp0"
																	allowDecimals={false}
																	value={margin2}
																	onValueChange={nominalmargin2}
																	className={`form-control`}
																	prefix={'Rp'}
																	step={10}
																/>
														</td>
														<td>
														<CurrencyInput
														disabled
																	id="validation-example-2-field"
																	placeholder="Rp0"
																	allowDecimals={false}
																	value={margin3}
																	onValueChange={nominalmargin3}
																	className={`form-control`}
																	prefix={'Rp'}
																	step={10}
																/>
														</td>
														<td>
														<CurrencyInput
														disabled
																	id="validation-example-2-field"
																	placeholder="Rp0"
																	allowDecimals={false}
																	value={margin4}
																	onValueChange={nominalmargin4}
																	className={`form-control`}
																	prefix={'Rp'}
																	step={10}
																/>
														</td>
														<td>
														<CurrencyInput
														disabled
																	id="validation-example-2-field"
																	placeholder="Rp0"
																	allowDecimals={false}
																	value={margin5}
																	onValueChange={nominalmargin5}
																	className={`form-control`}
																	prefix={'Rp'}
																	step={10}
																/>
														</td>
														<td>
														<CurrencyInput
																	disabled
																	id="validation-example-2-field"
																	placeholder="Rp0"
																	allowDecimals={false}
																	value={margin6}
																	onValueChange={nominalmargin6}
																	className={`form-control`}
																	prefix={'Rp'}
																	step={10}
																/>
														</td>
													</tr>
													<tr>
														<td>
															<Label
															className="form-control-label"
															for="exampleEmail" sm={4}>
																Margin %
															</Label>
														</td>
														<td>
															<CurrencyInput
																disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={persentase1}
																onValueChange={persenmargin1}
																className={`form-control`}
																suffix={'%'}
																step={10}
															/>
														</td>
														<td>
														<CurrencyInput
														disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={persentase2}
																onValueChange={persenmargin2}
																className={`form-control`}
																suffix={'%'}
																step={10}
															/>
														</td>
														<td>
														<CurrencyInput
														disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={persentase3}
																onValueChange={persenmargin3}
																className={`form-control`}
																suffix={'%'}
																step={10}
															/>
														</td>
														<td>
														<CurrencyInput
														disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={persentase4}
																onValueChange={persenmargin4}
																className={`form-control`}
																suffix={'%'}
																step={10}
															/>
														</td>
														<td>
														<CurrencyInput
														disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={persentase5}
																onValueChange={persenmargin5}
																className={`form-control`}
																suffix={'%'}
																step={10}
															/>
														</td>
														<td>
														<CurrencyInput
																disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={persentase6}
																onValueChange={persenmargin6}
																className={`form-control`}
																suffix={'%'}
																step={10}
															/>
														</td>
													</tr>
												</tbody>
											</Table>
										</CardBody>
									</Card>
								{LogHarga && LogHarga === "1" && (
									<Card>
										<CardHeader>
											<div style={{ display: "flex", justifyContent: "space-between" }}>
												<h3>Log Harga</h3>
											</div>
										</CardHeader>
										<CardBody>
										{logPrice.length > 0 ? (
											<ToolkitProvider
												rowNumber={rowIndex}
												data={logPrice}
												keyField="id"
												columns={[
													{
														dataField: "no",
														text: "#",
														sort: true,
														page: 1,
														formatter: (cell, row, index) => {
															let currentRow = ++index;
															return currentRow + rowIndex;
														},
													},
													{
														dataField: "username",
														text: "Username",
														sort: true,
													},
													{
														dataField: "harga_beli",
														text: "Harga Beli",
														sort: true,
														formatter: (cell, row) => {
															return formatRupiah(row.harga_beli);
														},
													},
													{
														dataField: "price_1",
														text: "Harga 1",
														sort: true,
														formatter: (cell, row) => {
															return formatRupiah(row.price_1);
														},
													},
													{
														dataField: "price_2",
														text: "Harga 2",
														sort: true,
														formatter: (cell, row) => {
															return formatRupiah(row.price_2);
														},
													},
													{
														dataField: "price_3",
														text: "Harga 3",
														sort: true,
														formatter: (cell, row) => {
															return formatRupiah(row.price_3);
														},
													},
													{
														dataField: "price_4",
														text: "Harga 4",
														sort: true,
														formatter: (cell, row) => {
															return formatRupiah(row.price_4);
														},
													},
													{
														dataField: "price_5",
														text: "Harga 5",
														sort: true,
														formatter: (cell, row) => {
															return formatRupiah(row.price_5);
														},
													},
													{
														dataField: "price_6",
														text: "Harga 6",
														sort: true,
														formatter: (cell, row) => {
															return formatRupiah(row.price_6);
														},
													},
												]}>
												{(props) => (
													<div className="py-4 table-responsive">
														<BootstrapTable
															remote
															{...props.baseProps}
															bootstrap4={true}
															bordered={false}
															hover={true}
															pagination={paginationFactory({ ...paginationOption })}
															onTableChange={handleTableChange}
														/>
													</div>
												)}
											</ToolkitProvider>
											):(
											<CardHeader>
												<center><h3>DATA NOT FOUND</h3></center>
											</CardHeader>
											)}
										</CardBody>
									</Card>
								)}
								</CardBody>
						</div>
					</Row>
				</Container>
			</div>
			):(
				<Halaman404 />
			)}
		</>
	);
}
