/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col, CardBody, CardHeader,Form, Badge,Container,FormGroup, Label, Input ,DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import ModalCetakSJCCeklist from './ModalCetakBeforeSjKasir';
import Halaman404 from 'views/404';
import Select2 from "react-select2-wrapper";

const SuratJalanSalesOrderPage = () => {
  const [alert, setAlert] = React.useState(null);
  const token = localStorage.token;
  const [persons, setPersons] = useState([]);
  const [tanggal,setTanggal] = useState("")
  const warehouse = parseInt(localStorage.warehouse);
  const [rowIndex, setRowIndex] = useState(0);
  const redirectPrefix = `/admin/surat-jalan-kasir/edit/`;
  const [allSuratJalanSo, setAllSuratJalanSo] = useState([]);
  const [uomCode, setUomCode] = useState("");
  const [description, setDescription] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [openModalCetak1, setOpenModalCetak1] = useState(false);
  const [dataModalCetak1, setDataModalCetak1] = useState({ id: 0,});
  const toggle1 = () => setOpenModalCetak1(!openModalCetak1);
  const allInfo = JSON.parse(localStorage.allinfo);
  const suratjalankasir = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Surat Jalan Cashier").map((p) => p.read_access));
  const createButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Surat Jalan Cashier").map((p) => p.create_access));
  const updateButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Surat Jalan Cashier").map((p) => p.update_access));
  
  useEffect(() => {
    getPerson();
  }, []);

  const getPerson = () => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/customer/list`, { headers
      })
      .then(data => {
        setPersons(data.data.response);
      })
        .catch(function (error) {
          console.log(error)
        })
    }

  const displayModalCetak1 = (id) => {
    setDataModalCetak1({
      id: id,
    });
    setOpenModalCetak1(true);
  };
  
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, tanggal, uomCode, description);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort, tanggal, uomCode, description)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, tanggal, uomCode, description) => {
    getSuratJalan(page, perPage, sort, tanggal, uomCode, description);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setTanggal(tanggal)
    setUomCode(uomCode);
    setDescription(description);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, tanggal, uomCode, description)
    }
  }

  
  useEffect(() => {
    getSuratJalan(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getSuratJalan = (page, perPage, currentSort,transaction_date=null, sjc_code = null, customer_name = null) => {
    
    let filter = { 
      
      page: page, 
      per_page: perPage, 
      status_sj : 3,
      warehouse_id : parseInt(warehouse),
      
    };
    if (transaction_date !== null) {
      filter= Object.assign(filter, {transaction_date:transaction_date})
    }
    if (sjc_code !== null) {
      filter = Object.assign(filter, { sjc_code: sjc_code })
    }
    if (customer_name !== null) {
        filter = Object.assign(filter, { customer_name: customer_name })
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/surat-jalan-cashier/page`, data, {
        headers,
      })
      .then((data) => {
        setAllSuratJalanSo(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        setAllSuratJalanSo(error.response.data.response);
      });
  }

  const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const reset = () => {
    setTanggal("")
    setUomCode("");
    setDescription("");
    updateDataTable(1, perPage, currentSort, "", "");
  }

  return (
    <div>
      {alert}
      {suratjalankasir && suratjalankasir === "1" ? (
      <div>
      <SimpleHeader name="Surat Jalan Kasir" parentName="PO" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
            <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                        <Link className="btn btn-link" to="/admin/surat-jalan-kasir">
                          <i className="fa fa-arrow-circle-left fa-2x" /> 
                        </Link>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                    {createButton && createButton === "1" && (
                      <Link className="btn btn-danger" to="/admin/surat-jalan-kasir/create">
                        <i className="fas fa-plus" /> Tambah
                      </Link>
                    )}
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                         <Form>
                          <Row md="12">
                          <Col md="3">
                            <FormGroup>
                              <Label
                                      className="form-control-label"
                                      htmlFor="exampleFormControlSelect3"
                                  >
                                Tanggal 
                              </Label>
                              <Input
                              className="form-control-alternative"
                                type="date"
                                placeholder="Masukan Tanggal"
                                value={tanggal}
                                onChange={(e) =>
                                  updateDataTable(1,perPage,currentSort, e.target.value, uomCode , description )
                                }
                              />
                            </FormGroup>
                          </Col>
                              <Col md="3">
                              <FormGroup>
                                <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >Kode SJ</Label>
                                <Input
                                className="form-control-alternative"
                                  type="text"
                                  placeholder="Masukan Kode SJ"
                                  value={uomCode}
                                  onChange={e => updateDataTable(1, perPage, currentSort,tanggal, e.target.value, description)}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="3">
                              <FormGroup>
                                <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >Customer</Label>
                                <Select2
                                        className="form-control-alternative"
                                        defaultValue="0"
                                        value={description}
                                          onChange={e => updateDataTable(1, perPage, currentSort,tanggal, uomCode, e.target.value)}
                                        options={{
                                          placeholder: "Pilih Customer"
                                        }}
                                        data={persons.map((person, key) => {
                                          return (
                                          {  id: person.name, text: person.name, key:key}
                                          );
                                        })}
                                      />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Button type="button" onClick={reset} color="secondary">Reset</Button>
                            </Col>
                          </Row>
                        </Form>
                        {allSuratJalanSo === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  keyField="id"
                                  data={""}
                                  columns={[
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                  dataField: "",
                                  text: "Tanggal",
                                  sort: true,
                                  },
                                  {
                                    dataField: "",
                                    text: "Kode SJ",
                                    sort: true,
                                },
                                {
                                  dataField: "",
                                  text: "Customer",
                                  sort: true,
                              },
                                  {
                                    dataField: "",
                                    text: "Status",
                                    sort: true,
                                },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                        </div>
                        ):(
                      <ToolkitProvider
                              rowNumber={rowIndex}
                              data={allSuratJalanSo}
                              keyField="id"
                              columns={[
                              {
                                  dataField: "no",
                                  text: "#",
                                  sort: true,
                                  page: 1,
                                  formatter: (cell, row, index) => {
                                  let currentRow = ++index;
                                  return currentRow + rowIndex;
                                  },
                              },
                              {
                                dataField: "transaction_date",
                                text: "Tanggal",
                                sort: true,
                              },
                              {
                                  dataField: "sjc_code",
                                  text: "Kode SJ",
                                  sort: true,
                                  formatter: (cell, row) => {
                                    return row.sjc_code === ""
                                      ? <h2>
                                        <Badge color="warning" >
                                          {row.sjc_code}
                                        </Badge>
                                        </h2>
                                      : 
                                      <h2>
                                      <Badge color="warning" >
                                          {row.sjc_code}
                                        </Badge>
                                        </h2>
                                  },
                              },
                              {
                                dataField: "customer_name",
                                text: "Customer",
                                sort: true,
                            },
                              {
                                dataField: "status_sj",
                                text: "Status",
                                sort: true,
                                formatter: (cell, row) => {
                                  return row.status_sj === 3
                                    ? 
                                      <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-danger" />
                                        Proses
                                      </Badge>
                                    : row.status_sj === 4
                                    ? <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-warning" />
                                        Tolak
                                      </Badge>
                                    : <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-success" />
                                        Setuju
                                      </Badge>;
                                },
                            },
                              {
                                  dataField: "", text: "", formatter: (cell, row, index) => {
                                  return (
                                    
                                      <UncontrolledDropdown nav>
                                      <DropdownToggle color="danger">
                                              Tindakan
                                      </DropdownToggle>
                                      <DropdownMenu>
                                      {updateButton && updateButton === "1" && (
                                            <Link  to={redirectPrefix + row.id}
                                              id={"tooltip_" + row.id}>
                                            <DropdownItem>
                                              <i className="fas fa-user-edit" /><span>Edit</span>
                                            </DropdownItem>
                                            </Link>
                                      )}
                                      {createButton && createButton === "1" && (
                                            <DropdownItem
                                                onClick={() => displayModalCetak1(row.id)}
                                              >
                                                <i className="fas fa-print" />
                                                <span>Ceklist</span>
                                            </DropdownItem>
                                      )}
                                      
                                      </DropdownMenu>
                                  </UncontrolledDropdown>
                                  )
                                  }
                              },
                              ]}
                          >
                              {(props) => (
                              <div className="py-4 table-responsive">
                                  <BootstrapTable
                                  remote
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  bordered={false}
                                  hover={true}
                                  pagination={paginationFactory({ ...paginationOption })}
                                  onTableChange={handleTableChange}
                                  />
                              </div>
                              )}
                      </ToolkitProvider>
                        )}
                </CardBody>
              </Card>
            </div>
          </Row>
           {openModalCetak1 && (
            <ModalCetakSJCCeklist
              open={openModalCetak1}
              data={dataModalCetak1}
              toggle={toggle1}
            />
          )}
        </Container>
        </div>
      ):(
        <Halaman404 />
      )}
    </div>
  );
}

export default SuratJalanSalesOrderPage;