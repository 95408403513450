/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  FormGroup,
  Label,
  Input,
  InputGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem 
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";
import CurrencyInput from 'react-currency-input-field';

const CreateInvoiceSo = () => {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState("");
  const [pengiriman, setPengiriman] = useState([]);
  const [keterangan, setKeterangan] = useState("");
  const [savedItems, setSavedItems] = useState([]);
  const [codeso, setCodeSo] = useState("");
  const [codesj, setCodeSj] = useState("");
  const [payment_method, setPaymentMethod] = useState("");
  const [allpajak, setAllPajak] = useState([]);
  const [ppn, setPPN] = useState(0);
  const [totaldiskon, setTotalDiskon] = useState(0);
  const [grandtotal, setGrandTotal] = useState(0);
  const [diskonglobalnominal, setDiskonGlobalNominal] = useState(0);
  const [diskonglobalpersen,setDiskonGlobalPersen] = useState(0);
  const [ongkir, setOngkir] = useState(0);
  const [pajak, setPajak] = useState("");
  const [lainnya, setLainnya] = useState(0);
  const [allJangkaWaktu, setAllJangkaWaktu] = useState([]);
  const [jangkaWaktu,setJangkaWaktu] = useState(0);
  const [ongkir1, setOngkir1] = useState(0);
	const [ppnnew, setPPNNEW] = useState(0);
	const [diskonglobal, setDiskonGLobal] = useState(0);
  const [totalPrice1, setTotalPrice1] = useState(0);
  const [dp, setDp] = useState(0);
  const [dp1, setDp1] = useState(0);
  const a = 0;
	const b = 0;
  const c = 0;
  const [diskonglobalnominal1,setDiskonGlobalNominal1] = useState(0)
	const [totaldiskon2, setTotalDiskon2] = useState(0);
	const [totaldiskon3, setTotalDiskon3] = useState(0);
	const [qtyTotal, setTotalQty] = useState(0);
	const [diskontotal, setDiskonTotal] = useState(0);
  const [customername, setCustomerName] = useState("")
  const [bankcustomer, setBankCustomer] = useState("")
  const [bankcustomers, setBankCustomers] = useState([])
  const [isSearchShow, setIsSearchShow] = useState(false);
  const [isSearchShows, setIsSearchShows] = useState(false);
  const headers = { Authorization: `Bearer ${token}`};
  const [allItem, setAllItem] = useState([]);
  const [query, setQuery] = useState("");
  const [querys, setQuerys] = useState("");
  const allInfo = JSON.parse(localStorage.allinfo);
  const invoiceSO = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Invoice SO").map((p) => p.read_access));
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterType, setFilterType]=useState("supplier")

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const handleFilterTypeChange = (type) => {
    setFilterType(type);
  };

  const searchShow = (item) => {
    AmbilDataCok(item.sj_code);
    setIsSearchShow(false);
    setIsSearchShows(false);
  };

  useEffect(() => {
    const getData = setTimeout(async() => {
      if(query != ""){
        axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/page`, {  
          page: 1,
        per_page: 10,
        status_sj:5,
        active_flag :1,
        customer_name: query,
        warehouse_id : parseInt(warehouse),
        },{ headers })
        .then((res) => {
          setAllItem(res.data);
          setIsSearchShow(true);
          
        })
        .catch(function (error) {
          setGagalAlert(error.response.data.message)
        });
      }
      }, 100)
      return () => clearTimeout(getData)
  }, [query]);

  useEffect(() => {
    const getData = setTimeout(async() => {
      if(querys != ""){
        axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/sales-order/page`, {  
          page: 1,
          per_page: 10,
          active_flag:1,
          status_ph: 5,
          so_code: querys,
          warehouse_id : parseInt(warehouse),
        },{ headers })
        .then((res) => {
          setAllItem(res.data);
          setIsSearchShows(true);
          
        })
        .catch(function (error) {
          setAllItem(error.response.data.response);

          // setGagalAlert(error.response.data.message);
          setIsSearchShows(true);

        });
      }}, 1000)
      return () => clearTimeout(getData)
  }, [querys]);
  
const AmbilDataCok = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/get-by-code/${id}`,
        { headers }
      )
      .then((data) => {
        getCustomer(data.data.response.customer_id);
        setCustomer(data.data.response.customer_id);
        getbankCustomer(data.data.response.customer_id);
        setBankCustomer(data.data.response.account_number);
        setDp(data.data.response.um_nominal)
        setCustomerName(data.data.response.customer_name);
        getPajak(data.data.response.pajak_id);
        getById(data.data.response.pajak_id);
        setCodeSo(data.data.response.code_so);
        setCodeSj(data.data.response.sj_code);
        setOngkir(data.data.response.ongkir);
        setLainnya(data.data.response.lainnya);
        setPengiriman(data.data.response.pengiriman);
        setPaymentMethod(data.data.response.payment_method1);
        setKeterangan(data.data.response.keterangan);
        setTotalQty(data.data.response.price_real);
        setDiskonTotal(data.data.response.diskon_total);
        getJangkaWaktu(data.data.response.jangka_waktu);
        setPaymentMethod(data.data.response.payment_method);
        setDiskonGlobalNominal(data.data.response.diskon_global_nominal);
        setDiskonGlobalPersen(data.data.response.diskon_global_persen);
        getItemDataSaved(data.data.response.sj_code);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = (id) => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/item-by-code`, {

        code_sj: id

    }, {headers}).then(async response => {
        let stateItem = [];

        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                satuan : data.satuan,
                qty: data.qty,
                harga: data.harga,
                pembayaran : data.pembayaran,
                diskon_persen: data.diskon_persen,
                diskon_nominal: data.diskon_nominal,
                data: {
                  item_id: data.item_id,
                  item_name:data.item_name,
                  item_code:data.item_code,
                  satuan : data.satuan,
                  qty: data.qty,
                  harga: data.harga,
                  pembayaran : data.pembayaran,
                  diskon_persen: data.diskon_persen,
                  diskon_nominal: data.diskon_nominal,
                },
            }];
        }));
        setSavedItems(stateItem);
    })
}

// useEffect(() => {
//   setDiskonGlobalNominal1(diskonglobalnominal);
// }, [diskonglobalnominal]);

//   // diskon bayangan
//   useEffect(() => {
//   setTotalDiskon(diskonglobalnominal1);
// }, [diskonglobalnominal1]);

// useEffect(() => {
//   setTotalDiskon3(diskontotal-a);
// }, [diskontotal, a]);

//   //diskon tampil
//   useEffect(() => {
//   setTotalDiskon2(totaldiskon - a);
// }, [totaldiskon3, totaldiskon, a]);

// //ongkir tampil
// useEffect(() => {
//   setOngkir1(ongkir - b);
// }, [ongkir, b]);


//   useEffect(() => {
//   setTotalPrice1(qtyTotal);
// }, [qtyTotal]);


//   // diskonglobalnominal dan persen
//   useEffect(() => {
//   diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((qtyTotal - diskontotal) * diskonglobalpersen);
//   diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal - diskontotal) * (diskonglobalpersen/100));
// }, [diskonglobalnominal, qtyTotal, diskonglobalpersen,diskontotal]);

//   // hasil nominal dari PPN
//   useEffect(() => {
//       setPPNNEW( (qtyTotal - totaldiskon) * (ppn / 100));
//  }, [qtyTotal,totaldiskon,ppn]);

//   // hasil grandtotal
// useEffect(() => {
//   setGrandTotal(( qtyTotal - totaldiskon2) + ppnnew + ongkir1- a );
// }, [qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);

// useEffect(() => {
//   setDiskonGlobalNominal1(diskonglobalnominal);
// }, [diskonglobalnominal]);

//   // diskon bayangan
//   useEffect(() => {
//   setTotalDiskon(diskonglobalnominal1);
// }, [diskonglobalnominal1]);

// useEffect(() => {
//   setTotalDiskon3(diskontotal-a);
// }, [diskontotal, a]);

//   //diskon tampil
//   useEffect(() => {
//   setTotalDiskon2(totaldiskon - a);
// }, [totaldiskon3, totaldiskon, a]);

// //ongkir tampil
// useEffect(() => {
//   setOngkir1(ongkir - b);
// }, [ongkir, b]);


//   useEffect(() => {
//   setTotalPrice1(qtyTotal - ppnnew);
// }, [qtyTotal, ppnnew]);


//   // diskonglobalnominal dan persen
//   useEffect(() => {
//     diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
//     diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
//   }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);

//   // hasil nominal dari PPN
//   useEffect(() => {
//     setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
//   }, [qtyTotal,totaldiskon,ppn]);

//   // hasil grandtotal
// useEffect(() => {
//   setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew + ongkir1- a );
// }, [totalPrice1,qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);

useEffect(() => {
  setDiskonGlobalNominal1(diskonglobalnominal);
}, [diskonglobalnominal]);

  // diskon bayangan
  useEffect(() => {
  setTotalDiskon(diskonglobalnominal1);
}, [diskonglobalnominal1]);

useEffect(() => {
  setTotalDiskon3(diskontotal-a);
}, [diskontotal, a]);

  //diskon tampil
  useEffect(() => {
  setTotalDiskon2((totaldiskon)/((ppn/100)+1));
}, [totaldiskon3, totaldiskon, ppn , a]);

//ongkir tampil
useEffect(() => {
  setOngkir1(ongkir - b);
}, [ongkir, b]);

  useEffect(() => {
  setTotalPrice1((qtyTotal)/((ppn/100)+1));
}, [qtyTotal, ppnnew,ppn]);


  // diskonglobalnominal dan persen
  useEffect(() => {
    diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
    diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
  }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);

  // hasil nominal dari PPN
  useEffect(() => {
    setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
  }, [qtyTotal,totaldiskon,ppn]);

  //uang muka tampil
  useEffect(() => {
    setDp1(dp - c);
  }, [dp, c]);

    // hasil grandtotal
  useEffect(() => {
    setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew - dp1  + ongkir1- a );
  }, [totalPrice1,qtyTotal, totaldiskon2,ppnnew,dp1, ongkir1, a]);



	// useEffect(() => {
  //       getById();
	// }, [pajak]);

	const getById = (id) => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/pajak/${id}`,
	        { headers }
	      )
	      .then((data) => {;
	        setPPN(data.data.response.persentase);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

      const getPajak = (id) => {
      	const headers = {
      		"Content-Type": "application/json",
      		Authorization: `bearer ${token}`,
      	};
      	axios
      		.get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
      		.then((data) => {
      			setAllPajak(data.data.response);
      			setPajak(id);
      		})
      		.catch(function (error) {
      			console.log(error);
      		});
      };

  const getCustomer = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/customer/list`,
        { headers }
      )
      .then((data) => {
        setCustomers(data.data.response);
        setCustomer(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getbankCustomer = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-customer/${id}`, { headers })
      .then((data) => {
        setBankCustomers(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit2 = () => {
    hideAlert();
    setTimeout(() => {CreateData()}, 1000);
  };

  function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga: dataItem.harga,
                pembayaran : dataItem.pembayaran,
                diskon_nominal: dataItem.diskon_nominal,
                diskon_persen : dataItem.diskon_persen,
            }]);
    let data = {
      warehouse_id : parseInt(warehouse),
      pajak_id : parseInt(pajak),
      username : username,
      active_flag : 1,
      code_sj: codesj,
      code_so : codeso,
      jangka_waktu : parseInt(jangkaWaktu),
      customer_id: parseInt(customer),
      um_nominal: parseInt(dp),
      pengiriman: parseInt(pengiriman),
      keterangan: keterangan ,
      payment_method : parseInt(payment_method),
      diskon_global_nominal : parseFloat(diskonglobalnominal),
      diskon_global_persen : parseInt(diskonglobalpersen),
      ongkir: parseFloat(ongkir),
      lainnya:parseFloat(lainnya),
      items : dataItems,
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/invoice-so/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/invoice-so/page")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
    }
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }

  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };

  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={handleSubmit2}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };
  
    const hideAlert = () => {
      setAlert(null);
    };


const getJangkaWaktu = (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/jwkredit/list`, { headers })
    .then((data) => {
      setAllJangkaWaktu(data.data.response);
      setJangkaWaktu(id);
    })
    .catch(function (error) {
      console.log(error);
    });
};


  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }

  // const HargaDiskonPersen = (e) => {
  //   const value = e === undefined ? 'undefined' : e;
  //   setDiskonGlobalPersen(value || ' ');
  //   setDiskonGLobal("diskonglobalpersen")
  //   };
  
  //   const HargaDiskonNominal = (e) => {
  //     const value = e === undefined ? 'undefined' : e;
  //     setDiskonGlobalNominal(value || ' ');
  //     setDiskonGLobal("diskonglobalnominal")
  //     };
    
  //     const HargaOngkir = (e) => {
  //       const value = e === undefined ? 'undefined' : e;
  //       setOngkir(value || ' ');
  //       };

  return (
    <>
    {alert}
    {invoiceSO && invoiceSO === "1" ? (
			<div>
    <SimpleHeader name="Buat Faktur Penjualan" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
                <Card className="position-sticky bg-secondary BoxShadow" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
											<Col md="4">
											</Col>
											<Col md="4">
                      <FormGroup>
                      <InputGroup>
                        <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                          <DropdownToggle caret color="primary">
                            {filterType === 'supplier' ? 'Nama Customer' : 'Kode SO'}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => {handleFilterTypeChange('supplier');setIsSearchShows(false)}}>
                              Nama Customer
                            </DropdownItem>
                            <DropdownItem onClick={() => {handleFilterTypeChange('po');setIsSearchShow(false)}}>
                              Kode SO
                            </DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      {filterType === "supplier" ? 
                        <Input
                            placeholder="Search Customer"
                            type="search"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          : 
                          <Input
                            className="form-control"
                            placeholder={filterType === "supplier" ? "Search Customer" : "Search Kode SO"}
                            type="search"
                            value={querys}
                            onChange={(e) => setQuerys(e.target.value)}
                          />
                          }
                        </InputGroup>
                      </FormGroup>
												{/* <FormGroup row>
													<Col sm={10}>
														<Input
															className="form-control-alternative"
															placeholder="Search Customer"
															type="search"
															value={query}
															onChange={(e) => setQuery(e.target.value)}
														/>
													</Col>
												</FormGroup> */}
											</Col>
                      <Col md="4">
                      </Col>
										</Row>
									</CardBody>
								</Card>
									{isSearchShow && query && (
										<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
											<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
												<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
											</div>
											<span className="text-center mb-3">
												<b>Pencarian berdasarkan:</b> {query}
											</span>
											{allItem?.response ? (
												allItem.response.map((item) => (
													<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" style={{minHeight: 'auto'}} onClick={() => {searchShow(item); setQuery(''); setIsSearchShow(false);}}>
														<div className=" d-flex align-items-center">
															<div className="ml-3">
																<div>
																	<b>Customer</b> {item.customer_name}
																</div>
															</div>
                              <div className="ml-3">
																<div>
																	<b>Kode :</b> {item.sj_code}
																</div>
															</div>
														</div>
													</CardBody>
												))
											) : (
												<div className="text-center mb-3 text-danger">Customer "{query}" tidak ada bosku!</div>
											)}
                      {isSearchShows && querys && (
										<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
											<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
												<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShows(false)}></i>
											</div>
											<span className="text-center mb-3">
												<b>Pencarian berdasarkan:</b> {querys}
											</span>
											{allItem?.response ? (
												allItem.response.map((item) => (
													<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" style={{minHeight: 'auto'}} onClick={() => {searchShow(item); setQuerys(''); setIsSearchShows(false);}}>
														<div className=" d-flex align-items-center">
														<div className="ml-3">
																<div>
																	<b>Customer :</b> {item.customer_name}
																</div>
															</div>
                              <div className="ml-3">
																<div>
																	<b>Kode :</b> {item.so_code}
																</div>
															</div>
														</div>
													</CardBody>
												))
											) : (
												<div className="text-center mb-3 text-danger">SO "{querys}" tidak ada bosku!</div>
											)}
										</Card>
									)} 
										</Card>
                  )}
              <Card>
                <CardBody>
                  <Row md="12">
                      <Col md="6">
                      <FormGroup row>
														<Label 
                            className="form-control-label"
                            for="exampleEmail" sm={3}>
															Kode SO
														</Label>
														<Col sm={6}>
															<Input
                                disabled
                                placeholder="Masukan Kode SO"
                                className="form-control-alternative"
																type="text"
																value={codeso}
                                />
														</Col>
													  </FormGroup>
                      <FormGroup row>
														<Label 
                            className="form-control-label"
                            for="exampleEmail" sm={3}>
															Kode SJ
														</Label>
														<Col sm={6}>
															<Input
                                disabled
                                placeholder="Masukan Kode SJ"
                                className="form-control-alternative"
																type="text"
																value={codesj}
                                />
														</Col>
													  </FormGroup>
                        <FormGroup row>
														<Label 
                            className="form-control-label"
                            for="exampleEmail" sm={3}>
															Customer
														</Label>
														<Col sm={6}>
															<Input
                                disabled
                                placeholder="Masukan Customer"
                                className="form-control-alternative"
																type="text"
																value={customername}
                                />
														</Col>
													  </FormGroup>
                        <FormGroup row>
														<Label 
                            className="form-control-label"
                            for="exampleEmail" sm={3}>
															Bank
														</Label>
														<Col sm={6}>
															<Input
                              disabled
                                className="form-control-alternative"
																type="select"
																value={bankcustomer}
                                >
                                <option value="" disabled selected hidden>Pilih Bank</option>
                                  {bankcustomers.map((supplier, key) => {
                                    return (
                                        <option key={key} value={supplier.account_number}>
                                          {supplier.bank_name} {supplier.account_number} a/n {supplier.account_name} 
                                        </option>
                                    );
                                  })}
                                </Input>
														</Col>
													  </FormGroup>
                        
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                Diskon
                              </Label>
                              <Col sm={3}>
                                <CurrencyInput
                                  disabled
                                  id="validation-example-2-field"
                                  placeholder="0"
                                  allowDecimals={false}
                                  value={diskonglobalpersen}
                                  className={`form-control`}
                                  suffix={'%'}
                                  step={10}
                                />
                              </Col>
                              <Col sm={3}>
                              <CurrencyInput
                                  disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={diskonglobalnominal}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                PPN
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                  className="form-control-alternative"
                                    type="select"
                                    value={pajak}
                                    >
                                      <option>Pilih PPN</option>
                                      {allpajak.map((warehouse2, key) => {
                                        return (
                                            <option key={key} value={warehouse2.id}>
                                              {warehouse2.keterangan}
                                            </option>
                                        );
                                      })}
                                </Input>
                              </Col>
                            </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup row>
                        <Label

className="form-control-label"
for="exampleEmail"
                          sm={4}
                        >
                          Metode Pembayaran
                        </Label>
                        <Col sm={6}>
                        <Input
                        disabled
                        className="form-control-alternative"
                            name="Tipe Po"
                            type="select"
                            value={payment_method}
                          >
                            <option value="" disabled hidden selected>Pilih Metode Pembayaran</option>
                            <option value={1}>Lunas</option>
                            <option value={2}>Tempo</option>
                            <option value={5}>Dp/Kredit</option>
                          </Input>
                        </Col>
                        </FormGroup>
                        {payment_method >  1  ? (
                              <FormGroup row>
                                <Label

className="form-control-label"
for="exampleEmail"
                                  sm={4}
                                >
                                  Jangka Waktu
                                </Label>
                                <Col sm={4}>
                                  <Input
                        disabled

                                    className="form-control-alternative"
                                    type="select"
                                    name="desc"
                                    value={jangkaWaktu}
                                  >
                                  <option value="">Pilih</option>
                                  {allJangkaWaktu.map((waktu, key) => {
                                    return (
                                      <option key={key} value={waktu.durasi}>
                                        {waktu.durasi}
                                      </option>
                                    );
                                  })}
                                </Input>
                                </Col> 
                                <Label 
                                className="form-control-label"
                                for="exampleEmail" sm={4}>
                                  <b>Hari</b>
                                </Label>
                              </FormGroup>
                            ):(
                              ""
                            )}
                            {payment_method === 5 ? 
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Uang Muka
                              </Label>
                              <Col sm={6}>
                              <CurrencyInput
                              disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={dp}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>                             
                            </FormGroup>
                            : 
                            ""
                            }
                        <FormGroup row>
                          <Label

className="form-control-label"
for="exampleEmail"
                            sm={4}
                          >
                            Ongkir
                          </Label>
                          <Col sm={6}>
                          <CurrencyInput
                              disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={ongkir}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                          </Col>                             
                        </FormGroup>
                        <FormGroup row>
                          <Label

className="form-control-label"
for="exampleEmail"
                            sm={4}
                          >
                            Keterangan
                          </Label>
                          <Col sm={6}>
                            <Input
                            className="form-control-alternative"
                            disabled
                              type="textarea"
                              name="desc"
                              rows = "4"
                              placeholder="Masukan Keterangan"
                              value={keterangan}
                            />
                          </Col>                             
                        </FormGroup>
                      </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                    <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Harga</b></th>
                                <th><b>Qty</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Diskon %</b></th>
                                <th><b>Diskon (N)</b></th>
                                <th><b>Sub Total</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td><>{formatRupiah(savedItem.harga)}</></td>
                                      <td><>{savedItem.qty}</></td>
                                      <td>{savedItem.satuan}</td>
                                      <td><>{savedItem.diskon_persen}</></td>
                                      <td><>{formatRupiah(savedItem.diskon_nominal)}</></td>
                                      <td>
                                      {/* {formatRupiah((((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) - ((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) * (ppn/100)))} */}
                                      {formatRupiah(((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) / (ppn/100 + 100/100))}

                                        {/* {formatRupiah((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty)} */}
                                        </td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter >
                          <Row md="12">
                            <Col md="6">
                            </Col>
                            <Col md="2">
                            </Col>
                            <Col md="4">
                              <FormGroup row>
                              <Label

className="form-control-label"
for="exampleEmail"
                                sm={4}
                                size="small"
                              >
                                <b>Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Harga Total"
                                // value={totalPrice1}
                                value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label

className="form-control-label"
for="exampleEmail"
                                sm={4}
                              >
                                <b>Diskon</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                style={{fontWeight: 'bold'}}
                                disabled
                                type="text"
                                name="barcode"
                                placeholder="Diskon"
                                value={"Rp." + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label

className="form-control-label"
for="exampleEmail"
                                sm={4}
                              >
                                <b>PPN</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label

className="form-control-label"
for="exampleEmail"
                                sm={4}
                              >
                                <b>Ongkir</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Uang Muka</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Uang Muka"
                                value={"Rp." + dp1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label

className="form-control-label"
for="exampleEmail"
                                sm={4}
                              >
                                <b>Grand Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                type="text"
                                name="barcode"
                                style={{fontWeight: 'bold'}}
                                placeholder="Grand Total"
                                value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>

                            </Col>
                          </Row>
                        </CardFooter>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/invoice-so/page">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={()=>setQuestionAlert()}>
                          Simpan
                      </Button>
                </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container> 
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}

export default CreateInvoiceSo;