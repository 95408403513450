import React, { useEffect, useRef } from 'react';
import { Input } from 'reactstrap';

const SearchLocation = ({
  address,
  setLong,
  setLat
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    const initAutocomplete = () => {
      if (!window.google || !window.google.maps) {
        console.error("Google Maps API belum dimuat.");
        return;
      }

      const input = inputRef.current;
      const searchBox = new window.google.maps.places.SearchBox(input);

      let markers = [];

      searchBox.addListener('places_changed', function () {
        const places = searchBox.getPlaces();

        if (places.length === 0) {
          return;
        }

        markers.forEach(marker => marker.setMap(null));
        markers = [];

        const bounds = new window.google.maps.LatLngBounds();
        places.forEach(place => {
          if (!place.geometry) {
            console.log("Returned place contains no geometry");
            return;
          }

          const icon = {
            url: place.icon,
            size: new window.google.maps.Size(71, 71),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(17, 34),
            scaledSize: new window.google.maps.Size(25, 25)
          };

          markers.push(new window.google.maps.Marker({
            icon: icon,
            title: place.name,
            position: place.geometry.location
          }));

          // Update latitude and longitude
          setLat(place.geometry.location.lat());
          setLong(place.geometry.location.lng());

          if (place.geometry.viewport) {
            bounds.union(place.geometry.viewport);
          } else {
            bounds.extend(place.geometry.location);
          }
        });
      });
    };

    // Initialize autocomplete after component mounts
    initAutocomplete();

  }, [setLat, setLong]); // Dependency array ensures the effect runs on mount

  return (
    <Input
      defaultValue={address}
      id="pac-input"
      className="controls"
      type="text"
      placeholder="Search Address"
      innerRef={inputRef}
    />
  );
}

export default SearchLocation;
