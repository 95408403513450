import { Table } from "reactstrap";
const IdBank = () => {
  return (
    <Table borderless cellPadding={1} cellSpacing="1" width={100}>
      <tbody>
        <tr>
          <td>
            <img
              style={{ width: "30%" }}
              alt=""
              src={"https://i.imgur.com/KSXz0Ji.jpeg"}
            />
          </td>
          {/* <td style={{ fontSize: ".8em" , color:"black" }}>
            Head Office : {warehouseName}
            <br></br>
            Jl Menganti Karangan No.562 <br></br>
            Surabaya - Jawa Timur<br></br>
            Phone : 081 217 85 3300<br></br>
            Email : hokkybangunan.sby@gmail.com
          </td> */}
        </tr>
      </tbody>
    </Table>
  );
};
export default IdBank;
