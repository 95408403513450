/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

export default function EditJadwalOpname(props) {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  let history = useHistory();
  const [tanggal, setTanggal] = useState("")
  const allInfo = JSON.parse(localStorage.allinfo);
  const JadwalStokOpnames = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Stok Opname Scheduled").map((p) => p.read_access));
  
  useEffect(() => {
        getById();
    }, []);

    const getById = () => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/opname-scheduled/get/${props.match.params.id}`,
	        { headers }
	      )
	      .then((data) => {
            // setTanggal(moment(data.data.response.opname_date, "DD/MM/YYYY").format("YYYY-MM-DD"));
            setTanggal(data.data.response.opname_date);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };
  

  function EditJadwalOpname() {
    let data = {
        opname_date : moment(tanggal).format("YYYY-MM-DD 00:00:00"),
        active_flag: 1,
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/opname-scheduled/update/${props.match.params.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then(function (response) {
      setSuccessAlert(response.data.message);
      setTimeout(() => ( history.push("/admin/jadwal-stock-opname/page")), 1000);
    })
    .catch(function (error) {
          setGagalAlert(error.response.data.message);
    });
}

const setSuccessAlert = (id) => {
setAlert(
  <SweetAlert
    success
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
  />
)
}

const setGagalAlert = (id) => {
setAlert(
<SweetAlert
danger
showConfirm
confirmBtnText="Ok"
title={id}
onCancel={hideAlert}
onConfirm={hideAlert}
/>
);
};

const setQuestionAlert = () => {
setAlert(
<SweetAlert
warning
showCancel
confirmBtnText="Lanjutkan"
confirmBtnBsStyle="danger"
title="Apakah Kamu Yakin?"
onConfirm={EditJadwalOpname}
onCancel={hideAlert}
focusCancelBtn
/>
);
};

const hideAlert = () => {
setAlert(null);
};


  return (
    <>
    {alert}
    {JadwalStokOpnames && JadwalStokOpnames === "1" ? (
      <div>
      <SimpleHeader name="Edit Jadwal Opname" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <CardBody>
            <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <h3>Edit Jadwal Opname</h3>
                  </CardHeader>
                  <CardBody>
                    <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Tanggal Opname
                        </Label>
                        <Input
                          autoComplete="off"
                          type="date"
                          name="nama"
                          placeholder="Masukan Nama Tanggal"
                          value={tanggal}
                          onChange={(e) => {
                            setTanggal(e.target.value);
                          }}
                        />
                      </FormGroup>
                  </CardBody>
                  <CardFooter>
                    <Link className="btn btn-info" to="/admin/jadwal-stock-opname/page">
                      Kembali
                    </Link>
                    <Button color="danger" onClick={()=>setQuestionAlert()}>
                          Simpan
                      </Button>
                  </CardFooter>
            </Card>
          </CardBody>
          </div>
        </Row>
      </Container>
      </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}
