/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";

const DetailTransferInternal = (props) => {
const token = localStorage.token;
  const [keterangan,setKeterangan] = useState("");
  const [rak1, setRak1] = useState("");
  const [rak2, setRak2] = useState("");
  const [savedItems, setSavedItems] = useState([]);
  const [code, setCode] = useState(false);
  const allInfo = JSON.parse(localStorage.allinfo);
  const transferRak = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Transfer Eksternal").map((p) => p.read_access));
  
  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/pindah-rak/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setRak1(data.data.response.code_rak1);
        setRak2(data.data.response.code_rak2);
        setCode(data.data.response.pr_code);
        setKeterangan(data.data.response.keterangan);
        getItemDataSaved();
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/pindah-rak/item`, {

        pr_id: props.match.params.id

    }).then(async response => {
        let stateItem = [];

        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                qty: data.qty,
                harga: data.harga,
                satuan: data.satuan,
                data: {
                    item_name: data.item_name,
                    harga: data.harga
                },
            }];
        }));

        setSavedItems(stateItem);
    })
}

  return (
    <>
     {transferRak && transferRak === "1" ? (
        <div>
    <SimpleHeader name="Detail Transfer Rak" parentName="Inventori" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
              <CardBody>
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <h3>Detail Transfer Rak</h3>
                  </CardHeader>
                  <CardBody>
                    <Row md="12">
                        <Col md="6">
                        <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Rak Asal
                            </Label>
                            <Col sm={6}>
                              <Input
                              disabled
                              className="form-control-alternative"
                                type="text"
                                name="keterangan"
                                placeholder="Masukan Rak Asal"
                                value={rak1}
                                onChange={(e) => {
                                  setRak1(e.target.value);
                                }}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Kode
                            </Label>
                            <Col sm={6}>
                              <Input
                              disabled
                              className="form-control-alternative"
                                type="text"
                                name="keterangan"
                                placeholder="kode"
                                value={code}
                                onChange={(e) => {
                                  setCode(e.target.value);
                                }}
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                        <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Rak Tujuan
                            </Label>
                            <Col sm={6}>
                              <Input
                              disabled
                              className="form-control-alternative"
                                type="text"
                                name="keterangan"
                                placeholder="Masukan Rak Tujuan"
                                value={rak2}
                                onChange={(e) => {
                                  setRak2(e.target.value);
                                }}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Keterangan
                            </Label>
                            <Col sm={6}>
                              <Input
                              disabled
                              className="form-control-alternative"
                                type="textarea"
                                name="keterangan"
                                rows = "4"
                                placeholder="Masukan Keterangan"
                                value={keterangan}
                                onChange={(e) => {
                                  setKeterangan(e.target.value);
                                }}
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                        <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Qty</b></th>
                                <th><b>Satuan</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.qty}</td>
                                      <td>{savedItem.satuan}</td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter>
                              <Link className="btn btn-info" to="/admin/transfer-rak">
                                  Kembali
                              </Link>
                        </CardFooter>
              </Card>
              </CardBody>
          </div>
        </Row>
    </Container>  
    </div>
     ):(
      <Halaman404 />
     )};
    </>
  );
}

export default DetailTransferInternal;