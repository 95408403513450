/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  FormGroup,
  Label,
  Input,
  
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import Halaman404 from "views/404";
import CurrencyInput from 'react-currency-input-field';

export default function EditSalesOrderPusat(props)  {
  const token = localStorage.token;
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState("");
  const [typereq, setTypeReq] = useState([]);
  const [pengiriman, setPengiriman] = useState([]);
  const [payment_method,setPaymentMethod] = useState(1);
  const [keterangan, setKeterangan] = useState("");
  const [allJangkaWaktu, setAllJangkaWaktu] = useState([]);
  const [jangkaWaktu,setJangkaWaktu] = useState(0);
  const [savedItems, setSavedItems] = useState([]);
  const [allpajak, setAllPajak] = useState([]);
  const [ppn, setPPN] = useState(0);
  const [totaldiskon, setTotalDiskon] = useState(0);
  const [grandtotal, setGrandTotal] = useState(0);
  const [diskonglobalnominal, setDiskonGlobalNominal] = useState();
  const [diskonglobalpersen,setDiskonGlobalPersen] = useState();
  const [ongkir, setOngkir] = useState(0);
  const [pajak, setPajak] = useState("");
  const [alamat, setAlamat] = useState("");
  const [ongkir1, setOngkir1] = useState(0);
	const [ppnnew, setPPNNEW] = useState(0);
	const [diskonglobal, setDiskonGLobal] = useState(0);
  const [totalPrice1, setTotalPrice1] = useState(0);
  const a = 0;
	const b = 0;
	const c = 0;
  const [dp, setDp] = useState(0);
  const [dp1, setDp1] = useState(0);
  const [diskonglobalnominal1,setDiskonGlobalNominal1] = useState(0)
	const [totaldiskon2, setTotalDiskon2] = useState(0);
	const [qtyTotal, setTotalQty] = useState(0);
  const [codeso, setCodeSo] = useState("")
  const [bankcustomer, setBankCustomer] = useState("")
  const [bankcustomers, setBankCustomers] = useState([])
  const [warehousepusat, setWarehousePusat] = useState("");
  const [warehousepusats, setWarehousePusats] = useState([]);
  const allInfo = JSON.parse(localStorage.allinfo);
	const salesOrderPusat = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub SO Pusat").map((p) => p.read_access));
  

  // useEffect(() => {
	// 	setDiskonGlobalNominal1(diskonglobalnominal);
	// }, [diskonglobalnominal]);

  //   // diskon bayangan
  //   useEffect(() => {
	// 	setTotalDiskon(diskonglobalnominal1);
	// }, [diskonglobalnominal1]);

  //   //diskon tampil
  //   useEffect(() => {
	// 	setTotalDiskon2(totaldiskon - a);
	// }, [totaldiskon, a]);

	// //ongkir tampil
	// useEffect(() => {
	// 	setOngkir1(ongkir - b);
	// }, [ongkir, b]);


  //   useEffect(() => {
	// 	setTotalPrice1(qtyTotal);
	// }, [qtyTotal]);


  //   // diskonglobalnominal dan persen
  //   useEffect(() => {
  //     diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
  //     diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
  //   }, [diskonglobalnominal, qtyTotal, diskonglobalpersen,diskonglobalpersen]);

  //   // hasil nominal dari PPN
  //   useEffect(() => {
  //       setPPNNEW( (qtyTotal - totaldiskon) * (ppn / 100));
  //  }, [qtyTotal,totaldiskon,ppn]);

  //   // hasil grandtotal
	// useEffect(() => {
	// 	setGrandTotal(( qtyTotal - totaldiskon2) + ppnnew + ongkir1- a );
	// }, [qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);

  // useEffect(() => {
  //   setDiskonGlobalNominal1(diskonglobalnominal);
  // }, [diskonglobalnominal]);

  //   // diskon bayangan
  //   useEffect(() => {
  //   setTotalDiskon(diskonglobalnominal1);
  // }, [diskonglobalnominal1]);

  // useEffect(() => {
  //   setTotalDiskon3(diskontotal-a);
  // }, [diskontotal, a]);

  //   //diskon tampil
  //   useEffect(() => {
  //   setTotalDiskon2(totaldiskon - a);
  // }, [totaldiskon3, totaldiskon, a]);

  // //ongkir tampil
  // useEffect(() => {
  //   setOngkir1(ongkir - b);
  // }, [ongkir, b]);


  //   useEffect(() => {
  //   setTotalPrice1(qtyTotal - ppnnew);
  // }, [qtyTotal, ppnnew]);


  //   // diskonglobalnominal dan persen
  //   useEffect(() => {
  //     diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
  //     diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
  //   }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);

  //   // hasil nominal dari PPN
  //   useEffect(() => {
  //     setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
  //   }, [qtyTotal,totaldiskon,ppn]);

  //   // hasil grandtotal
  // useEffect(() => {
  //   setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew + ongkir1- a );
  // }, [totalPrice1,qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);

  useEffect(() => {
    setDiskonGlobalNominal1(diskonglobalnominal);
  }, [diskonglobalnominal]);

    // diskon bayangan
    useEffect(() => {
    setTotalDiskon(diskonglobalnominal1);
  }, [diskonglobalnominal1]);

  // useEffect(() => {
  //   setTotalDiskon3(diskontotal-a);
  // }, [diskontotal, a]);

    //diskon tampil
    useEffect(() => {
    setTotalDiskon2((totaldiskon)/((ppn/100)+1));
  }, [totaldiskon, ppn , a]);

  //ongkir tampil
  useEffect(() => {
    setOngkir1(ongkir - b);
  }, [ongkir, b]);

    useEffect(() => {
    setTotalPrice1((qtyTotal)/((ppn/100)+1));
  }, [qtyTotal, ppnnew,ppn]);

  useEffect(() => {
    setDp1(dp - c);
  }, [dp, c]);

    // diskonglobalnominal dan persen
    useEffect(() => {
      diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
      diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
    }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskonglobalpersen]);

    // hasil nominal dari PPN
    useEffect(() => {
      setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
    }, [qtyTotal,totaldiskon,ppn]);

    // hasil grandtotal
  useEffect(() => {
    setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew - dp1 + ongkir1- a );
  }, [totalPrice1,qtyTotal, totaldiskon2, ppnnew,dp1, ongkir1, a]);

const getByPajak = (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(
      `${process.env.REACT_APP_API_BASE_URL}/pajak/${id}`,
      { headers }
    )
    .then((data) => {;
      setPPN(data.data.response.persentase);
    })
    .catch(function (error) {
      console.log(error);
    });
};

  useEffect(() => {
    getByIdss();
  }, []);

  const getByIdss = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/sales-order/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        getCustomer(data.data.response.customer_id);
        getbankCustomer(data.data.response.customer_id);
        setBankCustomer(data.data.response.account_number);
        getPajak(data.data.response.pajak_id);
        getByPajak(data.data.response.pajak_id);
        getItemDataSaved();
        setPaymentMethod(data.data.response.payment_method);
        getJangkaWaktu(data.data.response.jangka_waktu);
        setDp(data.data.response.um_nominal)
        setKeterangan(data.data.response.keterangan);
        setOngkir(data.data.response.ongkir);
        setAlamat(data.data.response.manual_address);
        setTotalQty(data.data.response.price_real);
        getPusat(data.data.response.warehouse_id);
        setCodeSo(data.data.response.so_code);
        setPengiriman(data.data.response.pengiriman);
        setTypeReq(data.data.response.type);
        setDiskonGlobalNominal(data.data.response.diskon_global_nominal);
        setDiskonGlobalPersen(data.data.response.diskon_global_persen);
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getPusat = (id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`, { headers
    })
    .then(data => {
      setWarehousePusats(data.data.response);
      setWarehousePusat(id)
    })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getJangkaWaktu = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/jwkredit/list`, { headers })
      .then((data) => {
        setAllJangkaWaktu(data.data.response);
        setJangkaWaktu(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getPajak = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
			.then((data) => {
				setAllPajak(data.data.response);
				setPajak(id);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

  const getItemDataSaved = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/sales-order/item`, {

        so_id: props.match.params.id

    }).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code : data.item_code,
                satuan : data.satuan,
                qty: data.qty,
                harga: data.harga,
                diskon_persen: data.diskon_persen,
                diskon_nominal: data.diskon_nominal,
                data: {
                    item_name: data.item_name,
                    harga: data.harga,
                    diskon_persen: data.diskon_persen,
                  diskon_nominal: data.diskon_nominal,
                  qty: data.qty,
                },
            }];
        }));
        setSavedItems(stateItem);
    })
}

const getCustomer = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/customer/list`,
        { headers }
      )
      .then((data) => {
        setCustomers(data.data.response);
        setCustomer(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getbankCustomer = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-customer/${id}`, { headers })
      .then((data) => {
        setBankCustomers(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getAlamat = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/customer/get/${id}`,
        { headers }
      )
      .then((data) => {
          setAlamat(data.data.response.address)
      })
      .catch(function (error) {
        console.log(error);
      });
  };


const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}  

const HargaDiskonPersen = (e) => {
  const value = e === undefined ? 'undefined' : e;
  setDiskonGlobalPersen(value || ' ');
  setDiskonGLobal("diskonglobalpersen")
  };

  const HargaDiskonNominal = (e) => {
    const value = e === undefined ? 'undefined' : e;
    setDiskonGlobalNominal(value || ' ');
    setDiskonGLobal("diskonglobalnominal")
    };
  
  return (
    <>
    {salesOrderPusat && salesOrderPusat === "1" ? (
			<div>
    <SimpleHeader name="Detail Penjualan Barang" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
        <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                      <h3>Detail Penjualan Barang</h3>
                    </CardHeader>
                    <CardBody>
                        <Row md="12">
                          <Col md="6">
                              <FormGroup row>
                                <Label
                                className="form-control-label"
                                  for="exampleEmail"
                                  sm={3}
                                >
                                  Kode
                                </Label>
                                <Col md="6">
                                      <Input
                                      disabled
                                        className="form-control-alternative"
                                        type="text"
                                        name="desc"
                                        placeholder="Masukan Keterangan"
                                        value={codeso}
                                        onChange={(e) => {
                                          setCodeSo(e.target.value);
                                        }}
                                      />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                    for="exampleEmail"
                                    sm={3}
                                >
                                    Cabang
                                    
                                </Label>
                                <Col sm={6}>
                                    <Input
                                    disabled
                                        autoComplete="off"
                                        className="form-control-alternative"
                                        type="select"
                                        value={warehousepusat}
                                        onChange={(e) => {
                                        setWarehousePusat(e.target.value);
                                        
                                        }}
                                    >
                                        <option value={""}  selected disabled hidden>Pilih Cabang</option>
                                        {
                                        warehousepusats.map((a, key) => {
                                            return <option key={key} value={a.id}>{a.name}</option>
                                        })
                                        }
                                    </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Customer
                              </Label>
                              <Col sm={6}>
                                <Select2
                                disabled
                                      className="form-control-alternative"
                                      defaultValue="1"
                                      value={customer}
                                      onChange={(e) => {
                                        setCustomer(e.target.value);
                                        getAlamat(e.target.value);
                                        getbankCustomer(e.target.value)
                                      }}
                                      options={{
                                        placeholder: "Pilih Customer"
                                      }}
                                      data={customers.map((customer) => {
                                        return (
                                        { id: customer.id, text: customer.name}
                                        );
                                      })}
                                    />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
														<Label
                            className="form-control-label" for="exampleEmail" sm={3}>
															Bank
														</Label>
														<Col sm={6}>
															<Input
                                                            disabled
                                className="form-control-alternative"
																type="select"
																value={bankcustomer}
																onChange={(e) => {
																	setBankCustomer(e.target.value);
																}}>
                                <option value="" disabled selected hidden>Pilih Bank</option>
                                  {bankcustomers.map((supplier, key) => {
                                    return (
                                        <option key={key} value={supplier.account_number}>
                                          {supplier.bank_name} {supplier.account_number} a/n {supplier.account_name} 
                                        </option>
                                    );
                                  })}
                                </Input>
														</Col>
													  </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Alamat
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                  type="textarea"
                                  name="keterangan"
                                  rows = "4"
                                  placeholder="Masukan Alamat"
                                  value={alamat}
                                  onChange={(e) => {
                                    setAlamat(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={4}
                            >
                              Tipe SO
                            </Label>
                            <Col sm={6}>
                            <Input
                            disabled
                            className="form-control-alternative"
                                name="Tipe Request"
                                type="select"
                                value={typereq}
                                onChange={(e) => {
                                  setTypeReq(e.target.value);
                                }}
                              >
                                <option value="" disabled hidden selected>Pilih Tipe SO</option>
                                <option value={1}>Ready</option>
                                <option value={2}>Indent</option>
                              </Input>
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={4}
                            >
                              Metode Pembayaran
                            </Label>
                            <Col sm={6}>
                            <Input
                            disabled
                            className="form-control-alternative"
                                name="Tipe Po"
                                type="select"
                                value={payment_method}
                                onChange={(e) => {
                                    setPaymentMethod(e.target.value);
                                }}
                              >
                                <option value="" disabled hidden selected>Pilih Metode Pembayaran</option>
                                <option value={1}>Lunas</option>
                                <option value={2}>Tempo</option>
                                <option value={5}>Dp/Kredit</option>
                              </Input>
                            </Col>
                            </FormGroup>
                            {payment_method >  1  ? (
                              <FormGroup row>
                                <Label
                                className="form-control-label"
                                  for="exampleEmail"
                                  sm={4}
                                >
                                  Jangka Waktu
                                </Label>
                                <Col sm={4}>
                                  <Input
                                  disabled
                                    className="form-control-alternative"
                                    type="select"
                                    name="desc"
                                    value={jangkaWaktu}
                                    onChange={(e) => {
                                      setJangkaWaktu(e.target.value);
                                    }}
                                  >
                                  <option value="">Pilih</option>
                                  {allJangkaWaktu.map((waktu, key) => {
                                    return (
                                      <option key={key} value={waktu.durasi}>
                                        {waktu.durasi}
                                      </option>
                                    );
                                  })}
                                </Input>
                                </Col> 
                                <Label
                                className="form-control-label" for="exampleEmail" sm={3}>
                                  <b>Hari</b>
                                </Label>
                              </FormGroup>
                            ):(
                              ""
                            )}
                            {payment_method === 5 ? 
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Uang Muka
                              </Label>
                              <Col sm={6}>
                              <CurrencyInput
                              disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={dp}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>                             
                            </FormGroup>
                            : 
                            ""
                            }
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={4}
                            >
                              Pengiriman
                            </Label>
                            <Col sm={6}>
                            <Input
                            disabled
                            className="form-control-alternative"
                                name="Tipe Request"
                                type="select"
                                value={pengiriman}
                                onChange={(e) => {
                                  setPengiriman(e.target.value);
                                }}
                              >
                                <option value="" disabled hidden selected>Pilih Pengiriman</option>
                                <option value={1}>Ambil Sendiri</option>
                                <option value={2}>Delivery</option>
                              </Input>
                            </Col>
                            </FormGroup>
                            {pengiriman >  1  ? (
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Ongkir
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                  type="number"
                                  name="desc"
                                  placeholder="Masukan Ongkir"
                                  value={ongkir}
                                  onChange={(e) => {
                                    setOngkir(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                            ):(
                              ""
                            )}
                             <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={4}>
                                Diskon
                              </Label>
                              <Col sm={3}>
                                <CurrencyInput
                                  disabled
                                  id="validation-example-2-field"
                                  placeholder="0"
                                  allowDecimals={false}
                                  value={diskonglobalpersen}
                                  onValueChange={HargaDiskonPersen}
                                  className={`form-control`}
                                  suffix={'%'}
                                  step={10}
                                />
                              </Col>
                              <Col sm={3}>
                              <CurrencyInput
                                  disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={diskonglobalnominal}
                                  onValueChange={HargaDiskonNominal}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={4}>
                                PPN
                              </Label>
                              <Col sm={6}>
                                <Input
                                    disabled
                                    className="form-control-alternative"
                                    type="select"
                                    value={pajak}
                                    onChange={(e) => {
                                      setPajak(e.target.value);
                                      getByPajak(e.target.value);
                                    }}>
                                      {allpajak.map((warehouse2, key) => {
                                        return (
                                            <option key={key} value={warehouse2.id}>
                                              {warehouse2.keterangan}
                                            </option>
                                        );
                                      })}
                                </Input>
                              </Col>
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                    <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Harga</b></th>
                                <th><b>Qty</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Diskon %</b></th>
                                <th><b>Diskon (N)</b></th>
                                <th><b>Sub Total</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td><>{formatRupiah(savedItem.harga)}</></td>
                                      <td>{savedItem.qty}</td>
                                      <td>{savedItem.satuan}</td>
                                      <td><>{savedItem.diskon_persen}</></td>
                                      <td><>{formatRupiah(savedItem.diskon_nominal)}</></td>
                                      <td>
																{formatRupiah(((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) / (ppn/100 + 100/100))}

                                      {/* {formatRupiah((((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) - ((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) * (ppn/100)))} */}

                                        {/* {formatRupiah((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty)}  */}
                                        </td>
                                     
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter>
                          <Row md="12">
                            <Col md="6">
                            <FormGroup row>
                              <Col sm={7}>
                                <Input
                                disabled
                                  type="textarea"
                                  name="keterangan"
                                  rows="10"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            </Col>
                            <Col md="2">
                            </Col>
                            <Col md="4">
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                                size="small"
                              >
                                <b>Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Harga Total"
                                // value={totalPrice1}
                                value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Diskon</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                style={{fontWeight: 'bold'}}
                                disabled
                                type="text"
                                name="barcode"
                                placeholder="Diskon"
                                value={"Rp." + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>PPN</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Ongkir</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                className="form-control-label"
                                  for="exampleEmail"
                                  sm={4}
                                >
                                  <b>Uang Muka</b>
                                </Label>
                                <Col sm={6}>
                                <Input
                                  className="form-control-alternative"
                                  disabled
                                  style={{fontWeight: 'bold'}}
                                  type="text"
                                  name="barcode"
                                  placeholder="Uang Muka"
                                  value={"Rp." + dp1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                  />
                                </Col>
                                </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Grand Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                type="text"
                                name="barcode"
                                style={{fontWeight: 'bold'}}
                                placeholder="Grand Total"
                                value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>

                            </Col>
                          </Row>
                        </CardFooter>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/pusat/sales-order/page">
                        Kembali
                      </Link>
                </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}