/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Row, 
    Col, 
    CardBody,
    Container, 
} from 'reactstrap';
import { Link,useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";

const SettlementChasier = () => {
  const token = localStorage.token;
  let history = useHistory();
  const username = localStorage.username;
	const [statusKasir, setStatusKasir] = useState(false);

	useEffect(() => {
		cekKasir();
	}, []);

	const cekKasir = async () => {
		const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/chasier-settlement/check-by-username/${username}`);
		if (res.data.status === 200) 
				  setStatusKasir(true);
		else 	
    setStatusKasir(false);
	};

  // const cekKasir = async () => {
	// 	// let data = {
	// 	// 	warehouse_id: parseInt(warehouse),
	// 	// 	password_otorisasi: confPassEditable,
	// 	// 	name: nameotorisasi,
	// 	// 	note : keterangan5
	// 	//   }
	// 	const res = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/chasier-settlement/check-by-username/${username}`)
	// 	  .then(function (res) {
  //       setStatusKasir(true);
  //       history.push("/admin/kasir-sales-order/so-kasir");
	// 	  })
	// 	  .catch(function (error) {
	// 		if(error.res.data.status == 400){
	// 			setStatusKasir(false);
  //       history.push("/admin/kasir-sales-order/modal");
	// 		}
	// 	  });
	// };

  return (
    <div>
    <SimpleHeader name="Opening Cashier " parentName="SO" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              {/* <CardBody>
                      <Link style={{
                        fontSize: 50,
                        paddingTop: 20,
                        top: "50%",
                        height: 117,
                        resize: "none",
                      }}  
                      className="btn btn-info" 
                      // onClick={() => cekKasir()}
                      to={statusKasir ? "/admin/kasir-sales-order/modal" : "/admin/kasir-sales-order/so-kasir"}
                      >
                    Open Cashier
                    </Link>
              </CardBody> */}
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <Row>
                <Col
                  md={{
                    offset: 3,
                    size: 6
                  }}
                  sm="12"
                >
                  <center>
                    <Link style={{
                          fontSize: 50,
                          paddingTop: 20,
                          top: "50%",
                          height: 117,
                          width: 250,
                          resize: "none",
                        }}  
                        className="btn btn-info" 
                        // onClick={() => cekKasir()}
                        to={statusKasir ? "/admin/kasir-sales-order/modal" : "/admin/kasir-sales-order/so-kasir"}
                        >
                      KASIR
                    </Link>
                  </center>
                </Col>
              </Row>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </Card>
          </div>
        </Row>
        </Container>
    </div>
  );
}

export default SettlementChasier;