/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Container,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

const CreateBank = () => {
  const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [accountname,setAccountName] = useState("");
  const [accountnumber,setAccountNumber] = useState("");
  const [bankname,setBankName] = useState("");
  const [alert, setAlert] = React.useState(null);
  const [banks, setBanks] = useState([]);
  const allInfo = JSON.parse(localStorage.allinfo);
	const bank = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Bank").map((p) => p.read_access));
	
  useEffect(() => {
    getBank();
  }, []);

  const getBank = () => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/daftar-bank/list`, { headers
    })
    .then(data => {
        setBanks(data.data.response);
        
    })
      .catch(function (error) {
        console.log(error)
      })
  }


  function CreateData() {
    let data = {
      warehouse_id : parseInt(warehouse),
      username : username,
      account_name: accountname,
      account_number: accountnumber,
      bank_name: bankname,
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/bank/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/bank")), 2000);
          })
          .catch(function (error) {
            setGagalAlert(error.response.data.message);
          });
    }
    
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }
    
    const setGagalAlert = (id) => {
    setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
    );
    };
    
    const setQuestionAlert = () => {
    setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={CreateData}
    onCancel={hideAlert}
    focusCancelBtn
    />
    );
    };
    
    const hideAlert = () => {
      setAlert(null);
    }

  
  const handleSubmit = (e) => {
    {
      CreateData();
    }
  };

 
  return (
    <>
    {alert}
    {bank && bank === "1" ? (
      <div>
    <SimpleHeader name="Buat Bank" parentName="Master" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <h3>Buat Bank</h3>
                  </CardHeader>
                  <CardBody>
                    <Row md="12">
                        <Col md="6">
                        <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={4}
                            >
                              Nama Pemilik
                            </Label>
                            <Col sm={7}>
                              <Input
                              autoComplete="off"
                                type="text"
                                name="desc"
                                placeholder="Masukan Nama Pemilik "
                                value={accountname}
                                onChange={(e) => {
                                  setAccountName(e.target.value);
                                }}
                              />
                              </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={4}
                            >
                              Nomor Rekening
                            </Label>
                            <Col sm={7}>
                              <Input
                              autoComplete="off"
                                type="text"
                                name="desc"
                                placeholder="Masukan Nomer Akun "
                                value={accountnumber}
                                onChange={(e) => {
                                  setAccountNumber(e.target.value);
                                }}
                              />
                            </Col>                             
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Bank 
                            </Label>
                            <Col sm={6}>
                              {/* <Input
                              autoComplete="off"
                              type="select"
                              name="bank"
                              placeholder="Masukan Bank"
                              value={bankname}
                              onChange={(e) => {
                                setBankName(e.target.value);
                              }}
                              >
                                <option value="">Pilih Bank</option>
                                <option value="MANDIRI">MANDIRI</option>
                                <option value="BCA">BCA</option>
                                <option value="BRI">BRI</option>
                                <option value="BNI">BNI</option>
                                <option value="JATIM">JATIM</option>
                                <option value="BTN">BTN</option>
                                <option value="DANAMON">DANAMON</option>
                                <option value="CIMB">CIMB</option>
                                <option value="SINARMAS">SINARMAS</option>
                                <option value="MEGA">MEGA</option>
                                <option value="BSI">BSI</option>
                                <option value="BTPN">BTPN</option>
                              </Input> */}
                              <Input
                                autoComplete="off"
                                name="Kota"
                                type="select"
                                value={bankname}
                                onChange={(e) => {
                                  setBankName(e.target.value);
                                }}
                              >
                                <option value="">Pilih Bank</option>
                                {
                                  banks.map((city, key) => {
                                    return <option key={key} value={city.bank_name}>{city.bank_name}</option>
                                  })
                                }
                              </Input>
                            </Col>
                          </FormGroup>
                        </Col>
                    </Row>   
                  </CardBody>
                  <CardFooter>
                        <Link className="btn btn-info" to="/admin/bank">
                          Kembali
                        </Link>
                        <Button color="danger" onClick={setQuestionAlert}>
                            Simpan
                        </Button>
                  </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}

export default CreateBank;