// import { textAlign } from "@mui/system";
import React from "react";

export const ViewCetakSoKasir = React.forwardRef((props, ref) => {
    const formatRupiah = (money) => {
		return new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR", minimumFractionDigits: 0 }).format(money);
	};
    const showdate= new Date();
        const displaytoday=showdate.getDate()+'/'+(showdate.getMonth()+1)+'/'+showdate.getFullYear();
        // const dt=showdate.toDateString();
        const displaytime=showdate.getHours()+':'+showdate.getMinutes()+':'+showdate.getSeconds();

    return (
        <div style={{width:'300px', height:'auto'}} ref={ref}>
            <table border="0" style={{width:'100%'}}>
                <tbody>
                        <tr>
                            <td 
                            style={{width:'300%' }} 
                                align="right"
                                >
                                <img alt="" src={process.env.PUBLIC_URL + '/Hokky1.png'} style={{height:'auto', width:'275px'}} />
                            </td>
                        </tr>
                </tbody>
            </table>
            <p style={{fontSize:'14px', fontFamily:'calibri', textAlign:'center'}}>Jl. Raya Menganti Karangan No.38, Babatan, Kec. Wiyung, Kota SBY, Jawa Timur 60227</p>
            <hr/>						
            <table border="0" style={{width:'100%'}}>
                <tbody>
                    <tr>
                        <td style={{fontSize:'13px', fontFamily:'calibri'}}><strong>Tanggal : {displaytoday} {displaytime} </strong></td>
                        <td style={{fontSize:'13px', fontFamily:'calibri'}}><strong>Kasir : {props.kasir}</strong></td>
                      
                    </tr>
                    <tr>
                        <td style={{fontSize:'13px', fontFamily:'calibri'}}><strong>Nota : {props.nonota}</strong></td>
                        <td style={{fontSize:'13px', fontFamily:'calibri'}}><strong>Bayar  : {props.namapaymentmethod}</strong></td>
                    </tr>
                </tbody>
            </table>
            <hr/>						
            <table border="0" style={{width:'100%'}}>
                {/* <thead>
                    <tr>
                        <th colSpan="3" scope="col" style={{textAlign:'left', fontStyle:'bold', fontSize:'14px', fontFamily:'calibri'}}>Detail Pembelian</th>
                    </tr>
                </thead> */}
                <tbody>
                    <tr>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}><strong>Barang</strong></td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}><strong>Qty</strong></td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}><strong><center>Harga</center></strong></td>
                    </tr>
                    {props.detailpembelian.map((itempembelian, key) => {return(
                        <tr key={key}>
                            <td style={{fontSize:'14px', fontFamily:'calibri'}}>{itempembelian.item_name}</td>
                            <td style={{fontSize:'14px', fontFamily:'calibri'}}>{itempembelian.qty}</td>
                            <td style={{fontSize:'14px', fontFamily:'calibri'}}>{formatRupiah(itempembelian.harga)}</td>
                        </tr>
                    )})}
                </tbody>
            </table>
            <hr/>
            <table border="0" style={{width:'100%'}}>
                <tbody>
                    <tr>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}><strong>Sub Total</strong></td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}>:</td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}} align="right"> {props.total}</td>
                    </tr>
                    <tr>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}><strong>Promo</strong></td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}>:</td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}} align="right"> {props.promo}</td>
                    </tr>
                    <tr>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}><strong>PPN</strong></td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}>:</td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}} align="right"> {props.ppn}</td>
                    </tr>
                    <tr>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}><strong>Ongkir</strong></td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}>:</td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}} align="right"> {props.ongkir}</td>
                    </tr>
                    {/* {props.jasa === "" ? 
                    ""
                    :
                    <tr>
                    <td style={{fontSize:'14px', fontFamily:'calibri'}}><strong>Jasa</strong></td>
                    <td style={{fontSize:'14px', fontFamily:'calibri'}}>:</td>
                    <td style={{fontSize:'14px', fontFamily:'calibri'}} align="right"> {props.jasa}</td>
                </tr>
                    } */}
                   
                    <tr>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}><strong>Grand Total</strong></td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}>:</td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}} align="right">{props.grandtotal}</td>
                    </tr>
                    {/* <tr>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}><strong>PEMBULATAN</strong></td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}>:</td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}} align="right"><strong> {props.pembulatan}</strong></td>
                    </tr>
                    <tr>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}><strong>GRAND TOTAL</strong></td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}>:</td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}} align="right"><strong> {props.totalasli}</strong></td>
                    </tr> */}
                    <tr>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}><strong>Dibayar</strong></td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}>:</td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}} align="right"> {props.dibayar}</td>
                    </tr>
                    <tr>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}><strong>Kembali</strong></td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}}>:</td>
                        <td style={{fontSize:'14px', fontFamily:'calibri'}} align="right"> {props.kembali}</td>
                    </tr>
                </tbody>
            </table>
            <hr/>					
            <p><text style={{fontSize:'12px', fontFamily:'calibri'}}><center>Terima Kasih atas Kunjungan Anda<br/>PT Hokky Sumber Makmur<br/>96.260.943.4-618.000<br/>MENYEDIAKAN JASA APLIKATOR<br/>LEBIH HEMAT BERGARANSI</center></text></p>
        </div>
    )
});

export const ViewCetakBuktiMasuk = React.forwardRef((props, ref) => {
    return (<></>)
});

export const ViewCetakDll = React.forwardRef((props, ref) => {
    return (<></>)
});