/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
	Badge,
	Col,
	Button,
	Container,
	CardGroup,
} from "reactstrap";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Barcodee from "react-barcode";
import QRCode from "qrcode.react";
import Halaman404 from "views/404";
// import { LabelOffRounded } from "@mui/icons-material";

export default function ItemRegion() {
	const token = localStorage.token;
	const warehouseId = localStorage.warehouse;
	const [rowIndex, setRowIndex] = useState(0);
	const [page, setPage] = useState(1);
	const [perPage, setPerpage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [totalItem, setTotalItem] = useState(0);
	const [currentSort, setCurrentSort] = useState("");
	const [itemCode, setItemCode] = useState("");
	const [nameItem, setNameItem] = useState("");
	const [description, setDescription] = useState("");
	const [satuan, setSatuan] = useState("");
	const [grade, setGrade] = useState("");
	const [category, setCategory] = useState("")
	const [subCategory, setSubCategory] = useState("");
	const [nameFunction, setNameFunction] = useState("")
	const [nameSubFunction, setNameSubFunction] = useState("");
	const [merek, setMerek] = useState("");
	const [submerek, setSubMerek] = useState("");
	const [panjang, setPanjang] = useState("");
	const [lebar, setLebar] = useState("");
	const [tinggi, setTinggi] = useState("");
	const [nameType, setNameType] = useState(1);
	const [barcode, setBarcode] = useState("")
	const [status, setStatus] = useState("");
	const [minimumstock, setMinimumStock] = useState("");
	const [itemgrup, setItemGrup] = useState("");
	const [berat, setBerat] = useState("");
	const [image1, setImage1] = useState("");
	const [image2, setImage2] = useState("");
	const [image3, setImage3] = useState("");
	const [logPrice, setLogPrice] = useState([]);
	const [allItem, setAllItem] = useState([]);
	const [query, setQuery] = useState('');
	const [itemproduksi,setItemProduksi] = useState([])
	const [isSearchShow, setIsSearchShow] = useState(false);
	const headers = { Authorization: `Bearer ${token}` };
	const allInfo = JSON.parse(localStorage.allinfo);
	const itemregion = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Item Region").map((p) => p.read_access));
	

	useEffect(() => {
		getItems();
	}, [page]);

	//get list item berdasarkan warehouse
	const getItems = async () => {
		let filter = {
			page: page,
			per_page: 1,
			warehouse_id: parseInt(warehouseId),
		};
		const data = filter;
		const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/items-po`, data, { headers });
		if(res.data.response.id !== null){
			getById(res.data.response[0].id);
			getItemRegion(res.data.response[0].id);
			setTotalPages(res.data.total_page);
		}
	};

	//cari item
	useEffect(() => {
		const getData = setTimeout(async() => {
			if(query != ''){
				axios
				.post(`${process.env.REACT_APP_API_BASE_URL}/items-po`, { item_info: query , per_page: 10, warehouse_id: parseInt(warehouseId),  },{ headers })
				.then((res) => {
					setAllItem(res.data);
					setIsSearchShow(true);
				})
				.catch(function (error) {
					setAllItem(error.response.data.response);
					setIsSearchShow(true);
					// alert("Internet Sedang Tidak Stabil")
				});
			}
		  }, 1000)
		  return () => clearTimeout(getData)
	}, [query]);


	const getItemRegion = (id) => {
		
		let filter = { 
		warehouse_id: parseInt(warehouseId),
		id : id
		  
		};
		const data = filter;
		const headers = {
		  "Content-Type": "application/json",
		  Authorization: `Bearer ${token}`,
		};
		axios
		  .post(`${process.env.REACT_APP_API_BASE_URL}/items-region`, data, {
			headers,
		  })
		  .then((data) => {
			setLogPrice(data.data.response);
			setPerpage(data.data.per_page);
			setTotalItem(data.data.total_item);
		  })
		  .catch(function (error) {
			console.log(error);
		  });
	  };

	  const getById = (id) => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/get-items-detail?id=${id}&wid=${warehouseId}`,
	        { headers }
	      )
	      .then((data) => {
			setSatuan(data.data.response.satuan);
			setGrade(data.data.response.grade);
			setCategory(data.data.response.kategori);
			setSubCategory(data.data.response.sub_kategori);
			setNameFunction(data.data.response.function);
			setNameSubFunction(data.data.response.sub_function);
			setMerek(data.data.response.merek);
			setSubMerek(data.data.response.sub_merek);
			setItemGrup(data.data.response.group)
			setBarcode(data.data.response.barcode);
	        setBerat(data.data.response.berat);
	        setStatus(data.data.response.active_flag);
	        setSatuan(data.data.response.uom_code);
	        setMerek(data.data.response.merek_name);
	        setNameItem(data.data.response.item_name);
	        setDescription(data.data.response.item_description);
	        setPanjang(data.data.response.panjang);
	        setLebar(data.data.response.lebar);
	        setImage1(data.data.response.image_1);
	        setImage2(data.data.response.image_2);
	        setImage3(data.data.response.image_3);
	        setTinggi(data.data.response.tinggi);
	        setMinimumStock(data.data.response.minimum_stok);
	        setItemCode(data.data.response.item_code);
			setNameType(data.data.response.type);
			setItemProduksi(data.data.response.part);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

	    let paginationOption = {
		page: page,
		alwaysShowAllBtns: true,
		override: true,
		showTotal: true,
		withFirstAndLast: false,
		sizePerPage: perPage,
		totalSize: totalItem,
		onPageChange: (page) => {
			updateDataTable(page, perPage, currentSort);
		},
		sizePerPageRenderer: () => (
			<div className="dataTables_length" id="datatable-basic_length">
				<label
				className="form-control-label"
				>
					Show{" "}
					{
						<select
							name="datatable-basic_length"
							aria-controls="datatable-basic"
							className="form-control form-control-sm"
							onChange={(e) => {
								updateDataTable(page, e.target.value, currentSort);
							}}>
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="25">25</option>
							<option value="50">50</option>
						</select>
					}{" "}
					entries.
				</label>
			</div>
		),
	};

	    const updateDataTable = (page, perPage, sort) => {
		setPerpage(perPage);
		setRowIndex((page - 1) * perPage);
		setCurrentSort(sort);
	};

	    const handleTableChange = (type, { sortField, sortOrder }) => {
		if (type === "sort") {
			let sort = `${sortField} ${sortOrder}`;
			updateDataTable(page, perPage, sort);
		}
	};

	const searchShow = (item) => {
		getById(item.id);
		setPage(item.id)
		setIsSearchShow(false);
	};

	const formatRupiah = (money) => {
		return new Intl.NumberFormat('id-ID',
			{ style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
		).format(money);
	  }

	return (
		<>
			{itemregion && itemregion === "1" ? (
			<div>
				<SimpleHeader name="Item Region" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
								<Card className="position-sticky boxShadow" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
											<Col md="5 d-lg-block d-none">
												<Button onClick={() => setPage(1)} disabled={page === 1} color="danger" type="button">
													First
												</Button>
												<Button onClick={() => setPage((page) => page - 1)} disabled={page === 1} color="success" type="button">
												<i className="fa fa-angle-left" /> Prev
												</Button>
												<Button onClick={() => setPage((page) => page + 1)} disabled={page >= totalPages} color="success" type="button">
													Next <i className="fa fa-angle-right" />
												</Button>
												<Button onClick={() => setPage(totalPages)} disabled={page == totalPages} color="warning" type="button">
													End
												</Button>
											</Col>
											<Col md="3">
												<FormGroup row>
													<Col sm={10}>
														<Input
															placeholder="Search Item"
															type="search"
															value={query}
															onChange={(e) => setQuery(e.target.value)}
														/>
													</Col>
												</FormGroup>
											</Col>
											<Col md="4">
												<div style={{ textAlign: "right" }}>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>

								{isSearchShow && query && (
									<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
										<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
											<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
										</div>
										<span className="text-center mb-3">
											<b>Pencarian berdasarkan:</b> {query}
										</span>
										{allItem?.response ? (
											allItem.response.map((item) => (
												<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" onClick={() => searchShow(item)}style={{minHeight: 'auto'}}>
													<div className=" d-flex align-items-center">
														<div className="ml-3">
															<img src={item.image_url} height="50px" width="50px" className="img-thumbnail" />
														</div>
														<div className="ml-3">
															<div>
																<b>Nama item:</b> {item.item_name}
															</div>
															<div>
																<b>Kode item:</b> {item.item_code ? item.item_code : "(Not available)"}
															</div>
														</div>
														<div className="ml-3">
															<div>
																<b>Barcode:</b> {item.barcode ? item.barcode : "(Not available)"}
															</div>
															<div>
																<b>Price:</b>Rp. {new Intl.NumberFormat().format(item.price)}
															</div>
														</div>
													</div>
												</CardBody>
											))
										) : (
											<div className="text-center mb-3 text-danger">Item "{query}" tidak ada bosku!</div>
										)}
									</Card>
								)}
								<CardBody>
									<Row md="12">
										<Col md="7">
											<Card className="bg-secondary shadow">
												<CardHeader className="bg-white border-0">
												<div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <h3>ITEM</h3>
                                                        <div style={{ textAlign: 'right' }}>
                                                            <h1>
                                                                {status === 1 ?  
                                                                <Badge color="success">
                                                                    AKTIF
                                                                </Badge> :  
                                                                <Badge color="danger">
                                                                    TIDAK AKTIF
                                                                </Badge>}
                                                           
                                                            </h1>
                                                        </div>
                                                    </div>
												</CardHeader>
												<CardBody response>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Kode Item
														</Label>
														<Col sm={7}>
															<Input
																disabled
																className="form-control-alternative"
																type="text"
																name="itemCode"
																placeholder="Masukan Kode Item"
																value={itemCode}
																onChange={(e) => {
																	setItemCode(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Barcode
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="barcode"
																placeholder="Masukan Barcode"
																value={barcode}
																onChange={(e) => {
																	setBarcode(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Nama Item
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="nameItem"
																placeholder="Masukan Nama Item"
																value={nameItem}
																onChange={(e) => {
																	setNameItem(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Jenis Barang
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																name="kategory"
																type="text"
																value={itemgrup}
																onChange={(e) => {
																	setItemGrup(e.target.value);
																}}>
															</Input>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Satuan
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																name="satuan"
																type="text"
																value={satuan}
																onChange={(e) => {
																	setSatuan(e.target.value);
																}}>
															</Input>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Dimensi <b>(PxLxT)</b>
														</Label>
														<Col sm={2}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="panjang"
																placeholder="Panjang"
																value={panjang}
																onChange={(e) => {
																	setPanjang(e.target.value);
																}}
															/>
														</Col>
														<Col sm={3}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="lebar"
																placeholder="Lebar"
																value={lebar}
																onChange={(e) => {
																	setLebar(e.target.value);
																}}
															/>
														</Col>
														<Col sm={2}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="tinggi"
																placeholder="Tinggi"
																value={tinggi}
																onChange={(e) => {
																	setTinggi(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Berat
														</Label>
														<Col sm={4}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="Berat"
																placeholder="Masukan Berat"
																value={berat}
																onChange={(e) => {
																	setBerat(e.target.value);
																}}
															/>
														</Col>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															<b>Gram</b>
														</Label>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Minimum Stock
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																type="number"
																name="stock"
																placeholder="Masukan Stock"
																value={minimumstock}
																onChange={(e) => {
																	setMinimumStock(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
												</CardBody>
											</Card>
										</Col>
										<Col md="5">
											<Card className="bg-secondary shadow">
												<CardHeader className="bg-white border-0">
													<h3>Deskripsi</h3>
												</CardHeader>
												<CardBody>
													<FormGroup row>
														<Input
															disabled
															className="form-control-alternative"
															rows="13"
															type="textarea"
															name="deskripsi Item"
															placeholder="Masukan Deskripsi Item"
															value={description}
															onChange={(e) => {
																setDescription(e.target.value);
															}}
														/>
													</FormGroup>
													<FormGroup row>
														<Card>
															<CardBody>
																<CardGroup>
																	<Row md="10" center>
																		<Col md="5">
																			<QRCode className="qrcode"  value={barcode} />
																		</Col>
																		<Col md="5">
																			<Barcodee className="qrcode" value={barcode} />
																		</Col>
																	</Row>
																</CardGroup>
															</CardBody>
														</Card>
													</FormGroup>
												</CardBody>
											</Card>
										</Col>
									</Row>
									<Card className="bg-secondary shadow">
									<CardHeader className="bg-white border-0">
										<h3>Gambar</h3>
									</CardHeader>
									<CardBody>
										<Row md="12">
											<Col md="10">
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={3}>
														<b>Foto Produk</b><br></br>
														Format gambar .jpg .jpeg .png dan ukuran minimum <b>300px X 300px</b>.
													</Label>
													<Col sm={2}>
													{image1 === "" ?(
														<img
														alt="..."
														src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
														width="100%"
														/>
													):(
													<img
													alt=""
														src={image1}
														width="100%"
													/>
													)}
													</Col>
													<Col sm={2}>
													{image2 === "" ?(
														<img
														alt="..."
														src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
														width="100%"
														/>
													):(
													<img
													alt=""
														src={image2}
														width="100%"
													/>
													)}
													</Col>
													<Col sm={2}>
													{image3 === "" ?(
														<img
														alt="..."
														src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
														width="100%"
														/>
													):(
													<img
														alt=""
														src={image3}
														width="100%"
													/>
													)}
													</Col>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
									</Card>
									<Card className="bg-secondary shadow">
									<CardHeader className="bg-white border-0">
										<h3>KATEGORI</h3>
									</CardHeader>
									<CardBody>
										<Row md="12">
											<Col md="6">
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Tipe Item
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															type="text"
															name="nameItem"
															placeholder="Masukan Tipe Item"
															value={nameType}
															onChange={(e) => {
																setNameType(e.target.value);
															}}>
														</Input>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Kategori
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="kategory"
															type="text"
															value={category}
															onChange={(e) => {
																setCategory(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Sub Kategori
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="subKategory"
															type="text"
															value={subCategory}
															onChange={(e) => {
																setSubCategory(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Function
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="function"
															type="text"
															value={nameFunction}
															onChange={(e) => {
																setNameFunction(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Sub Function
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="subFunction"
															type="text"
															value={nameSubFunction}
															onChange={(e) => {
																setNameSubFunction(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
											</Col>
											<Col md="6">
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Merek
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="merek"
															type="text"
															value={merek}
															onChange={(e) => {
																setMerek(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Sub Merek
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="merek"
															type="text"
															value={submerek}
															onChange={(e) => {
																setSubMerek(e.target.value);

															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Grade
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="grade"
															type="text"
															value={grade}
															onChange={(e) => {
																setGrade(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												{/* <FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Status
													</Label>
													<Col sm={7}>
														<div style={{ display: "flex" }}>
															<div className="custom-control custom-radio mb-3">
																<Input
																disabled
																autoComplete="off"
																	className="custom-control-input"
																	id="customRadio11"
																	name="custom-radio-4"
																	type="checkbox"
																	value={1}
																	checked={status === 1}
																	onChange={() => setStatus(1)}
																/>
																<Label
																className="custom-control-label" htmlFor="customRadio11">
																	Aktif
																</Label>
															</div>
															<div className="custom-control custom-radio mb-3" style={{ marginLeft: "20px" }}>
																<Input
																disabled
																autoComplete="off"
																	className="custom-control-input"
																	id="customRadio12"
																	name="custom-radio-4"
																	type="radio"
																	value={2}
																	checked={status === 2}
																	onChange={() => setStatus(2)}
																/>
																<LabelOffRounded
																className="custom-control-label" htmlFor="customRadio12">
																	Tidak Aktif
																</Label>
															</div>
														</div>
													</Col>
												</FormGroup> */}
											</Col>
										</Row>
									</CardBody>
									</Card>
									<Card>
										<CardHeader>
											<div style={{ display: "flex", justifyContent: "space-between" }}>
												<h3>Item Produksi</h3>
											</div>
										</CardHeader>
										<CardBody>
										{itemproduksi.length > 0 ? (
											<ToolkitProvider
												rowNumber={rowIndex}
												data={itemproduksi}
												keyField="id"
												columns={[
													{
														dataField: "no",
														text: "#",
														sort: true,
														page: 1,
														formatter: (cell, row, index) => {
															let currentRow = ++index;
															return currentRow + rowIndex;
														},
													},
													{
														dataField: "item_name",
														text: "Nama Item",
														sort: true,
													},
													{
														dataField: "item_code",
														text: "Kode Item",
														sort: true,
													},
													{
														dataField: "qty",
														text: "Qty",
														sort: true,
													},
													{
														dataField: "satuan",
														text: "Satuan",
														sort: true,
													},
												]}>
												{(props) => (
													<div className="py-4 table-responsive">
														<BootstrapTable
															remote
															{...props.baseProps}
															bootstrap4={true}
															bordered={false}
															hover={true}
															pagination={paginationFactory({ ...paginationOption })}
															onTableChange={handleTableChange}
														/>
													</div>
												)}
											</ToolkitProvider>
											):(
												<CardHeader>
													<center><h3>DATA NOT FOUND</h3></center>
												</CardHeader>
												)}
										</CardBody>
									</Card>
									<Card>
										<CardHeader>
											<div style={{ display: "flex", justifyContent: "space-between" }}>
												<h3>Info Item</h3>
											</div>
										</CardHeader>
										<CardBody>
										{logPrice.length > 0 ? (
											<ToolkitProvider
												rowNumber={rowIndex}
												data={logPrice}
												keyField="id"
												columns={[
													{
														dataField: "no",
														text: "#",
														sort: true,
														page: 1,
														formatter: (cell, row, index) => {
															let currentRow = ++index;
															return currentRow + rowIndex;
														},
													},
													{
														dataField: "warehouse",
														text: "Region",
														sort: true,
													},
													{
														dataField: "city",
														text: "Kota",
														sort: true,
													},
													{
														dataField: "address",
														text: "Alamat",
														sort: true,
													},
													{
														dataField: "phone",
														text: "Telephone",
														sort: true,
													},
													{
														dataField: "stok",
														text: "Stok",
														sort: true,
													},
													{
														dataField: "price_1",
														text: "Harga Offline",
														sort: true,
														formatter: (cell, row) => {
															return formatRupiah(row.price_1);
														},
													},
													{
														dataField: "price_6",
														text: "Harga Online",
														sort: true,
														formatter: (cell, row) => {
															return formatRupiah(row.price_6);
														},
													},
												]}>
												{(props) => (
													<div className="py-4 table-responsive">
														<BootstrapTable
															remote
															{...props.baseProps}
															bootstrap4={true}
															bordered={false}
															hover={true}
															pagination={paginationFactory({ ...paginationOption })}
															onTableChange={handleTableChange}
														/>
													</div>
												)}
											</ToolkitProvider>
											):(
												<CardHeader>
													<center><h3>DATA NOT FOUND</h3></center>
												</CardHeader>
												)}
										</CardBody>
									</Card>
								</CardBody>
						</div>
					</Row>
				</Container>
			</div>
			):(
				<Halaman404 />
			)}
		</>
	);
}
