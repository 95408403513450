/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";

const Edit1Adjustment = (props ) => {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [keterangan,setKeterangan] = useState("");
  const [reasons, setReasons] = useState([]);
  const [savedItems, setSavedItems] = useState([]);
  const [qtyTotal, setTotalQty] = useState(0);
  const [allItemm, setAllItemm] = useState([]);
  const [queryy, setQueryy] = useState("");
  const [isSearchShoww, setIsSearchShoww] = useState(false);
  const headers = { Authorization: `Bearer ${token}` };
  const [coderak, setCodeRak] = useState("")
  const [coderaks, setCodeRaks] = useState([])

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/adjustment/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setKeterangan(data.data.response.keterangan);
        getItemGrup(data.data.response.code_rak)
        getItemDataSaved();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemGrup = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/rak/list/${warehouse}`,
        { headers }
      )
      .then((data) => {
        setCodeRaks(data.data.response);
        setCodeRak(id)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = () => {
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/adjustment/item`, {

        adjustment_id: props.match.params.id

      }).then(async response => {
          let stateItem = [];
          await Promise.all(response.data.response.map(async (data) => {
              stateItem = [...stateItem, {
                  item_id: data.item_id,
                  code_rak: data.code_rak,
                  item_name:data.item_name,
                  qty: data.qty,
                  reason:data.reason,
                  ket:data.ket,
                  data: {
                    item_name: data.item_name,
                    item_id: data.item_id,
                    code_rak: data.code_rak,
                    qty: data.qty,
                    reason:data.reason,
                    ket:data.ket,
                  },
              }];
        }));
        setSavedItems(stateItem);
        
    })
  }


  useEffect(() => {
    const getData = setTimeout(async() => {
        if(queryy != ''){
            axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/item-stok/list-for-adjustment`, { item_name: queryy , code_rak:coderak, per_page: 10 ,warehouse_id: parseInt(warehouse)},{ headers })
            .then((res) => {
                setAllItemm(res.data);
                setIsSearchShoww(true);
            })
            .catch(function (error) {
                setAllItemm(null);
            });
        }else{
      setAllItemm(null);
        }
      }, 500)
      return () => clearTimeout(getData)
  }, [queryy]);
  


const handleEditQty = (index, value) => {
  let updateList = savedItems;
  let aqtyTotal = parseInt(updateList[index].harga) + value;
  
  if(!isNaN(value) && value.length > -1){
    updateList[index] = {...updateList[index], qty: value};
    setSavedItems(updateList);
    setTotalQty(qtyTotal + aqtyTotal);
  }else{return false}
}

const handleEditReason = (index, value) => {
  let updateList = savedItems;
  let aqtyTotal = value;
  
  if(value){
    updateList[index] = {...updateList[index], reason: value};
    setSavedItems(updateList);
    setTotalQty(qtyTotal + aqtyTotal);
  }else{return false}
}

const handleEditKeterangan = (index, value) => {
  let updateList = savedItems;
  let aqtyTotal =  value;
  
  if(value){
    updateList[index] = {...updateList[index], ket: value};
    setSavedItems(updateList);
    setTotalQty(qtyTotal + aqtyTotal);
  }else{return false}
}

const saveItemOrUpdate = (item) => {
  let oldobj = savedItems.find(o => o.item_id === item.item_id);
  if(oldobj===undefined){
    setSavedItems([...savedItems,{
      item_id: item.item_id,
      code_rak : item.code_rak,
      item_name: item.item_name,
      qty: 1,
      reason : item.reason,
      ket : item.ket, 
    }]);
    
  }else{
    let index = savedItems.findIndex(e=>e.item_id===item.item_id);
    let updateList = savedItems;
    let qtyupdate = parseInt(updateList[index].qty)+parseInt(1);
    updateList[index] = {...updateList[index], qty: qtyupdate};
    setSavedItems(updateList);
  };
}

  useEffect(() => {
    getReason();
  }, []);

  const getReason = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/reason/list?type=1`,
        { headers }
      )
      .then((data) => {
        setReasons(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
              item_id: dataItem.item_id, 
              code_rak : dataItem.code_rak,
              item_name:dataItem.item_name,
              qty: dataItem.qty, 
              reason:dataItem.reason,
              ket:dataItem.ket,
            }]);
    let data = {
      status_d : 3,
      warehouse_id : parseInt(warehouse),
      username : username,
      keterangan: keterangan ,
      items : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/adjustment/update/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/stock-adjustment/page")), 2000);
          })
          .catch(function (error) {
            setGagalAlert(error.response.data.message);
          });
    }
    
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }
    
    const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
      danger
      showConfirm
      confirmBtnText="Ok"
      title={id}
      onCancel={hideAlert}
      onConfirm={hideAlert}
      />
      );
    };
    
    const setQuestionAlert = () => {
    setAlert(
      <SweetAlert
      warning
      showCancel
      confirmBtnText="Lanjutkan"
      confirmBtnBsStyle="danger"
      title="Apakah Kamu Yakin?"
      onConfirm={CreateData}
      onCancel={hideAlert}
      focusCancelBtn
      />
      );
    };
    
    const hideAlert = () => {
      setAlert(null);
    }

 
const handleSubmit = async (e) => {
  e.preventDefault();
  {
    setQueryy("");
    setIsSearchShoww("");
    return true
  }
};

  const deleteItem = (id) => {
    let array = [...savedItems];

    let index = array.findIndex(object => {
        return object.item_id === id;
    });

    if (index !== -1) {
        array.splice(index, 1);
        setSavedItems(array);
    }
}

  return (
    <>
    {alert}
    <SimpleHeader name="Edit Adjustment" parentName="Inventori" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <CardBody>
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Edit Adjustment</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="9">
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={2}
                              >
                                Keterangan
                              </Label>
                              <Col sm={10}>
                                <Input
                                autoComplete="off"
                                  type="textarea"
                                  name="keterangan"
                                  placeholder="Masukan Keterangan"
                                  style={{
                                    fontSize: 14,
                                    paddingTop: 20,
                                    top: "50%",
                                    height: 117,
                                    resize: "none",
                                  }}
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card className="bg-secondary shadow">
                    <CardHeader>
                      <h3>Item </h3>
                    </CardHeader>
                    <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Kode Rak</b></th>
                                <th><b>Nama Item</b></th>
                                <th><b>Qty</b></th>
                                <th><b>Alasan</b></th>
                                <th><b>Keterangan</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.code_rak}</td>
                                      <td>{savedItem.item_name}</td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="qty"
                                          type="number"
                                          value={savedItem.qty}
                                          onChange={(e) => {
                                              handleEditQty(key, e.target.value);
                                          }}/>
                                      </td>
                                      <td>
                                          <Input
                                              className="form-control-alternative"
                                              autoComplete="off"
                                              type="select"
                                              value={savedItem.reason}
                                              onChange={(e) => {
                                                handleEditReason(key, e.target.value);
                                            }}>
                                           <option value="" disabled hidden selected>Pilih Alasan</option>
                                              {reasons.map((reason, key) => {
                                                  return (
                                                  <option key={key} value={reason.description}>
                                                      {reason.description}
                                                  </option>
                                                  );
                                              })}
                                        </Input>
                                      </td>
                                      <td>
                                           <Input
                                          className="form-control-alternative"
                                          placeholder="Keterangan"
                                          type="text"
                                          value={savedItem.ket}
                                          onChange={(e) => {
                                            handleEditKeterangan(key, e.target.value);
                                          }}/>
                                      </td>
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              <tr>
                              <td>
                                  <> <Input
                                        autoComplete="off"
                                          className="form-control-alternative"
                                          name="kategory"
                                          type="select"
                                          value={coderak}
                                          onChange={(e) => {
                                            setCodeRak(e.target.value);
                                          }}
                                        >
                                          <option value="">Kode Rak</option>
                                          {coderaks.map((categorysss, key) => {
                                            return (
                                              <option key={key} value={categorysss.rak_code}>
                                                {categorysss.rak_code}
                                              </option>
                                            );
                                          })}
                                          </Input></>
                                  </td>
                                <td>
                                  <><Form onSubmit={handleSubmit}>
                                          <Input
                                            autoComplete="off"
                                            placeholder="Masukan Item Manual"
                                            type="search"
                                            value={queryy}
                                            onChange={(e) => setQueryy(e.target.value)}
                                          />
                                        
                                        {isSearchShoww && queryy && (
                                        <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                          <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                            <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
                                          </div>
                                          {allItemm?.response ? (
                                            allItemm.response.map((item) => (
                                              <CardBody key={item.item_id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {saveItemOrUpdate(item);setQueryy('');setCodeRak("");setIsSearchShoww(false);}}>
                                                <div>
                                                  <b>Nama item:</b> {item.item_name}
                                                </div>
                                              </CardBody>
                                            ))
                                          ) : (
                                            <div className="text-center mb-3 text-danger">Item "{queryy}" tidak ada bosku!</div>
                                          )}
                                        </Card>
                                      )}
                                  </Form></>
                                  </td>
                                 </tr>
                              </tbody>
                          </Table>
                        </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/stock-adjustment/page">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={()=>setQuestionAlert()}>
                          Simpan
                      </Button>
                </CardFooter>
              </Card>
              </CardBody>
          </div>
        </Row>
    </Container>  
    </>
  );
}

export default Edit1Adjustment;