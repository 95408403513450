/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

export default function EditSuratJalanSo(props) {
  const token = localStorage.token;
  const [alert, setAlert] = React.useState(null);
  const [tanggal,setTanggal] = useState("")
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState("");
  const [pengiriman, setPengiriman] = useState([]);
  const [keterangan, setKeterangan] = useState("");
  const [editingItem, setEditingitem] = useState([]);
  const [savedItems, setSavedItems] = useState([]);
  const [codesj, setCodeSJ] = useState("");
  const [codeso, setCodeSo] = useState("");
  const [driver, setDriver] = useState("");
  const [helper, setHelper] = useState("");
  const [qtyTotal, setTotalQty] = useState(0);
  const [nopol, setNopol] = useState("");
  const [qtyError, setQtyError] = useState(null);
  const allInfo = JSON.parse(localStorage.allinfo);
  const suratjalanso = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Surat Jalan SO").map((p) => p.read_access));
 
  const validateForm = () => {
    let error = false;
    savedItems.map((savedItem, key) => { 
      if (savedItem.qty > savedItem.qtysisa) {
        setQtyError("invalid");
        error = true;
      }
    })
    return error;
};

const handleformvalidasi = (e) => {
  e.preventDefault();
  if (!validateForm()) {
      setQuestionAlert();
  }
}

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        // getCustomer(data.data.response.customer_id);
        setCustomer(data.data.response.customer_name)
        // setCustomer(data.data.response.customer_id)
        getItemDataSaved();
        setTanggal(data.data.response.created_at);
        setKeterangan(data.data.response.keterangan);
        setTotalQty(data.data.response.qty_total);
        setDriver(data.data.response.driver);
        setHelper(data.data.response.helper);
        setNopol(data.data.response.nopol);
        setCodeSJ(data.data.response.sj_code);
        setCodeSo(data.data.response.code_so);
        setPengiriman(data.data.response.pengiriman);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/item`, {

        sj_id: props.match.params.id

      }).then(async response => {
          let stateItem = [];
          let stateEditing = [];
          await Promise.all(response.data.response.map(async (data) => {
              stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code : data.item_code,
                harga : data.harga,
                qty: data.qty,
                qtysisa : data.max_qty,
                satuan : data.satuan,
                data: {
                    item_name: data.item_name,
                    qtysisa : data.max_qty,
                    harga : data.harga,
                    item_code : data.item_code,
                    qty: data.qty,
                    satuan: data.satuan,
                },
            }]; 
              stateEditing = [...stateEditing, {
              editing: false
          }];
      }));
      setEditingitem(stateEditing);
      setSavedItems(stateItem);
    })
}

// const getCustomer = (id) => {
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     };
//     axios
//       .get(
//         `${process.env.REACT_APP_API_BASE_URL}/customer/list`,
//         { headers }
//       )
//       .then((data) => {
//         setCustomers(data.data.response);
//         setCustomer(id);
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };

  const handleSubmit2 = () => {
    hideAlert();
    setTimeout(() => {EditData()}, 1000);
  };

   function EditData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga:dataItem.harga,
            }]);
    let data = {
        warehouse_id : parseInt(warehouse),
        username : username,
        code_sj: codeso,
        status_sj: 3,
        // customer_id: customer,
        pengiriman: parseInt(pengiriman),
        keterangan: keterangan ,
        driver : driver,
        helper : helper,
        nopol : nopol,
        items : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/update/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message)
				setTimeout(() => (history.push("/admin/surat-jalan-so/page")), 1000);

        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
    }
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }

  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };

  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={handleSubmit2}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };
  
    const hideAlert = () => {
      setAlert(null);
    };
  
    const handleEditQty = (index, value) => {
        let updateList = savedItems;
        // let aqtyTotala = value ;
        // let aqtyTotal = parseInt(updateList[index].qty) ;
        let aqtyTotala = parseInt(updateList[index].harga)* value ;
    let aqtyTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty) ;
    
        if(!isNaN(value)&&value.length>0){
          updateList[index] = {...updateList[index], qty: value};
          setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
          setSavedItems(updateList);
        }else{return false}
      }
    
      const deleteItem = (id) => {
        let array = [...savedItems];
    
        let index = array.findIndex(object => {
          return object.item_id === id;
        });
    
        if (index !== -1) {
          
          array.splice(index, 1);
          setSavedItems(array);
        }
      }
    

const handleSubmit = (e) => {
    {
      EditData();
    }
  };

  const changePriceStatus = (index, status) => {
    setEditingitem([
        ...editingItem.slice(0, index),
        Object.assign({}, editingItem[index], { editing: status }),
        ...editingItem.slice(index + 1)
    ]);
  }
  
  const changeItemDataTable = async (arg) => {
      setSavedItems([
          ...savedItems.slice(0, arg.index),
          Object.assign({}, savedItems[arg.index], {
              data: {
                  item_name: arg.itemName,
                  item_code : arg.item_code,
                  satuan : arg.satuan,
                  qty:savedItems[arg.index].qty,
              }
          }),
          ...savedItems.slice(arg.index + 1)
      ]);
  
      changePriceStatus(arg.index, false);
  }

  return (
    <>
    {alert}
    {suratjalanso && suratjalanso === "1" ? (
			<div>
    <SimpleHeader name="Edit Surat Jalan Penjualan" parentName="Penjualan" />
    <Container className="mt--6" fluid>
        <Row>
        <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Edit Surat Jalan Penjualan</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Tanggal
                              </Label> 
                              <Col sm={6}>
                              <Input
                                  className="form-control-alternative"
                                  disabled
                                  name="customer"
                                  type="text"
                                  value={tanggal}
                                />
                              </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode SJ
                              </Label>
                              <Col sm={6}>
                              <Input
                                  className="form-control-alternative"
                                  disabled
                                  name="customer"
                                  type="text"
                                  value={codesj}
                                  onChange={(e) => {
                                    setCodeSJ(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode SO
                              </Label>
                              <Col sm={6}>
                              <Input
                                  className="form-control-alternative"
                                  disabled
                                  name="customer"
                                  type="text"
                                  value={codeso}
                                  onChange={(e) => {
                                    setCodeSo(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Customer
                              </Label>
                              <Col sm={6}>
                              <Input
                                  className="form-control-alternative"
                                  disabled
                                  name="customer"
                                  type="text"
                                  placeholder="Masukan Customer"
                                  value={customer}
                                  onChange={(e) => {
                                    setCustomer(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          {/* <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Customer
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                              disabled
                                  name="customer"
                                  type="select"
                                  value={customer}
                                  onChange={(e) => {
                                    setCustomer(e.target.value);
                                  }}
                                >
                                  <option value=''>Pilih Customer</option>
                                  {customers.map((customer, key) => {
                                      return (
                                        <option key={key} value={customer.id}>
                                          {customer.name}
                                        </option>
                                      );
                                    })}
                                  </Input>
                              </Col>
                            </FormGroup> */}
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Driver
                                <span className="text-danger">*</span>
                              </Label>
                              <Col sm={6}>
                                <Input
                                    autoComplete="off"
                                    className="form-control-alternative"
                                    placeholder="Masukan Driver"
                                    name="customer"
                                    type="text"
                                    value={driver}
                                    onChange={(e) => {
                                      setDriver(e.target.value);
                                    }}
                                  >
                                </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Helper
                                <span className="text-danger">*</span>
                              </Label>
                              <Col sm={6}>
                                <Input
                                    autoComplete="off"
                                    className="form-control-alternative"
                                    placeholder="Masukan Helper"
                                    name="customer"
                                    type="text"
                                    value={helper}
                                    onChange={(e) => {
                                      setHelper(e.target.value);
                                    }}
                                  >
                                </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Nopol
                                <span className="text-danger">*</span>
                              </Label>
                              <Col sm={6}>
                                <Input
                                    autoComplete="off"
                                    className="form-control-alternative"
                                    placeholder="Masukan Nopol"
                                    name="customer"
                                    type="text"
                                    value={nopol}
                                    onChange={(e) => {
                                      setNopol(e.target.value);
                                    }}
                                  >
                                </Input>
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Pengiriman
                            </Label>
                            <Col sm={6}>
                            <Input
                                className="form-control-alternative"
                                disabled
                                name="Tipe Request"
                                type="select"
                                value={pengiriman}
                                onChange={(e) => {
                                  setPengiriman(e.target.value);
                                }}
                              >
                                <option value="">Pilih Request</option>
                                <option value={1}>Ambil Sendiri</option>
                                <option value={2}>Delivery</option>
                                <option value={3}>Kurir</option>
                              </Input>
                            </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Alamat Kirim
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="textarea"
                                  name="Alamat Kirim"
                                  rows = "6"
                                  placeholder="Masukan Alamat Kirim"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                <Form onSubmit={handleformvalidasi}>
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                    <CardBody>
                      <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th><b>Nama Item</b></th>
                            <th><b>Kode Item</b></th>
                            <th><b>Qty</b></th>
                            <th><b>Satuan</b></th>
                        </tr>
                        </thead>
                        <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="qty"
                                          type="number"
                                          invalid={qtyError === "invalid"}
                                          value={savedItem.qty}
                                          onChange={(e) => {
                                              handleEditQty(key, e.target.value);
                                              if (savedItem.qty > savedItem.qtysisa) {
                                                setQtyError("");
                                              }
                                          }}/>
                                           <FormFeedback>
                                                        Max Barang {savedItem.qtysisa} 
                                                    </FormFeedback>
                                      </td>
                                      <td>{savedItem.satuan}</td>
                                      
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                      </Table>
                    </CardBody>
                    <CardFooter>
                          <Link className="btn btn-info" to="/admin/surat-jalan-so/page">
                            Kembali
                          </Link>
                          <Button color="danger" type="submit">
                              Simpan
                          </Button>
                    </CardFooter>
                </Form>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}