/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
  Card, 
  Row, 
  Col,
  Input,
  Label,
  CardBody, 
  CardHeader, 
  Form,
  Button,
  ButtonGroup ,
  FormGroup,
  Container,
  DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle,
  Modal, ModalHeader, ModalBody

} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from 'views/404';

const HistoriOpname = () => {
  const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle1 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
const token = localStorage.token;
  const warehouse = parseInt(localStorage.warehouse);
  const redirectPrefix = `/admin/stock-opname/detail/`;
  const [allOpname, setAllOpname] = useState([]);
  const [uomCode, setUomCode] = useState("");
  const [description, setDescription] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const allInfo = JSON.parse(localStorage.allinfo);
  const StokOpnames = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Stok Opname").map((p) => p.read_access));
 
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, uomCode, description);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, uomCode, description) => {
    getOpname(page, perPage, sort, uomCode, description);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setUomCode(uomCode);
    setDescription(description);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort,  uomCode, description)
    }
  }

  
  useEffect(() => {
    getOpname(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getOpname = (page, perPage, currentSort,opname_code='',code_rak='') => {
    
    let filter = { 
      
      page: page, 
      per_page: perPage,
      status_d:5,
      warehouse_id : parseInt(warehouse),
      
    };
    if (opname_code !== null) {
      filter = Object.assign(filter, { opname_code: opname_code });
    }
    if (code_rak !== null) {
      filter = Object.assign(filter, { code_rak: code_rak });
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/opname/page`, data, {
        headers,
      })
      .then((data) => {
        setAllOpname(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        setAllOpname(error.response.data.response);
      });
  };

  const reset = () => {
    setUomCode("");
    setDescription("");
    updateDataTable(1, perPage, currentSort, "", "");
  }

  const qtysistem = (id) => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/opname/input-fisik/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        getOpname();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const qtyfisik = (id) => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/opname/input-fisik/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        getOpname();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const setQtySistem = () => {
    setAlert(
      <SweetAlert
      warning
      showCancel
      confirmBtnText="Lanjutkan"
      confirmBtnBsStyle="danger"
      title="Qty akan mengikuti Sistem. Apakah Kamu Yakin ?"
      onConfirm={qtysistem}
      onCancel={hideAlert}
      focusCancelBtn
      />
    );
    };

    const setQtyFisik = () => {
      setAlert(
        <SweetAlert
        warning
        showCancel
        confirmBtnText="Lanjutkan"
        confirmBtnBsStyle="danger"
        title="Qty akan mengikuti Fisik. Apakah Kamu Yakin ?"
        onConfirm={qtyfisik}
        onCancel={hideAlert}
        focusCancelBtn
        />
      );
      };
    

      const getDataLog = (id) => {
        let filter = { 
          transaction : id,
          features : "AKUNTANSI2",
          warehouse_id : warehouse
        }
        const data = filter;
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
          }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
        })
        .then(data => {
          setDataLog(data.data.response);
                setModal1(!modal1);
    
        })
          .catch(function (error) {
          setGagalAlert(error.response.data.message);
          })
        }
    
        const setGagalAlert = (id) => {
        setAlert(
        <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
        />
        );
        };
        
        const hideAlert = () => {
          setAlert(null);
        };

  return (
    <div>
      {alert}
      {StokOpnames && StokOpnames === "1" ? (
      <div>
      <SimpleHeader name="Histori" parentName="PO" />
        <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ textAlign: 'left' }}>
                      <Link className="btn btn-link" to="/admin/stock-opname">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                      </Link>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
              <Form>
                        <Row md="12">
                          <Col md="3">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Kode Opname</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Kode Opname"
                                value={uomCode}
                                onChange={e => updateDataTable(1, perPage, currentSort, e.target.value, description)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Kode Rak</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Kode Rak"
                                value={description}
                                onChange={e => updateDataTable(1, perPage, currentSort, uomCode, e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button type="button" onClick={reset} color="secondary">Reset</Button>
                          </Col>
                        </Row>
                        </Form>
                        {allOpname === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                    dataField: "",
                                    text: "Tanggal",
                                    sort: true,
                                },
                                {
                                    dataField: "",
                                    text: "Kode",
                                    sort: true,
                                },
                                {
                                    dataField: "",
                                    text: "Rak",
                                    sort: true,
                                },
                                {
                                  dataField: "",
                                  text: "Keterangan",
                                  sort: true,
                              },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                        </div>
                        ):(
                    <ToolkitProvider
                            rowNumber={rowIndex}
                            data={allOpname}
                            keyField="id"
                            columns={[
                            {
                                dataField: "no",
                                text: "#",
                                sort: true,
                                page: 1,
                                formatter: (cell, row, index) => {
                                let currentRow = ++index;
                                return currentRow + rowIndex;
                                },
                            },
                            {
                              dataField: "created_at",
                              text: "Tanggal",
                              sort: true,
                            },
                            {
                                dataField: "opname_code",
                                text: "Kode Opname",
                                sort: true,
                            },
                            {
                              dataField: "code_rak",
                              text: "Kode Rak",
                              sort: true,
                          },
                            {
                                dataField: "keterangan",
                                text: "Keterangan",
                                sort: true,
                            },
                            {
                              dataField: "",
                              text: "",
                              formatter: (cell, row, index) => {
                                return row.active_flag === 1
                                  ? <UncontrolledDropdown nav>
                                      <DropdownToggle color="danger">
                                          Tindakan
                                        </DropdownToggle>
                                        <DropdownMenu center>
                                        <DropdownItem
                                            onClick={() => qtysistem(row.id)}
                                          >
                                            <i className="fas fa-print" />
                                            <span>QTY SISTEM</span>
                                          </DropdownItem>
                                        <DropdownItem
                                            onClick={() => qtyfisik(row.id)}
                                          >
                                            <i className="fas fa-print" />
                                            <span>QTY FISIK</span>
                                          </DropdownItem>
                                          </DropdownMenu>
                                    </UncontrolledDropdown>
                                  : <UncontrolledDropdown nav>
                                  <DropdownToggle color="danger">
                                      Tindakan
                                    </DropdownToggle>
                                    <DropdownMenu center>
                                    <Link  onClick={() => getDataLog(row.id)}>
                                                    <DropdownItem>
                                                    <i className="fa fa-info-circle" aria-hidden="true"></i> <span>Info</span>
                                                    </DropdownItem>
                                                </Link>
                                          <Link to={redirectPrefix + row.id}
                                              id={"tooltip_" + row.id}>
                                            <DropdownItem>
                                              <i className="fas fa-book" /><span>Detail</span>
                                            </DropdownItem>
                                            </Link>
                                      </DropdownMenu>
                                </UncontrolledDropdown>
                              },
                            },
                            ]}
                        >
                            {(props) => (
                            <div className="py-4 table-responsive">
                                <BootstrapTable
                                remote
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                                hover={true}
                                pagination={paginationFactory({ ...paginationOption })}
                                onTableChange={handleTableChange}
                                />
                            </div>
                            )}
                    </ToolkitProvider>
                        )}
              </CardBody>
            </Card>
          </div>
        </Row>
        </Container>
        <Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>
        </div>
      ):(
        <Halaman404 />
      )}
    </div>
  );
}

export default HistoriOpname;