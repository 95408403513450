/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  FormGroup,
  Label,
  Input,
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import SweetAlert from "react-bootstrap-sweetalert";

const CreateFinanceBkmRetur = () => {
    const [alert, setAlert] = React.useState(null);
    const token = localStorage.token;
    const username = localStorage.username;
    const warehouse = localStorage.warehouse;
    let history = useHistory();
    const [persons, setPersons] = useState([]);
    const [person, setPerson] = useState("");
    const [keterangan, setKeterangan] = useState("");
    const [bank, setBank] = useState("");
    const [banks, setBanks] = useState([]);
    const [qtyTotal, setTotalQty] = useState(0);


  const getItemDataSaved = (id) => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/po-retur/list-by-supplier`, {

        warehouse_id: warehouse,
        person_code: id

    }).then(async response => {
        let stateItem = [];
        let stateEditing = [];
        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
              code_retur: data.code_retur,
              tgl_tempo: data.tgl_tempo,
              payment_total : data.payment_total,
              keterangan2 : data.keterangan2,
              tgl_tempo: data.tgl_tempo,
              potongan: 0,
              bayar : data.payment_total,
                data: {
                  code_retur: data.code_retur,
                  tgl_tempo: data.tgl_tempo,
                  payment_total : data.payment_total,
                  keterangan2 : data.keterangan2,
                  tgl_tempo: data.tgl_tempo,
                  potongan: 0,
                  bayar : data.payment_total,
                },
            }];
            stateEditing = [...stateEditing, {
                editing: false
            }];
        }));
        setSavedItems(stateItem);
    })
}

  const handlePotongan = (index, value) => {
    let updateList = savedItems;
    let aqtyTotal = parseInt(updateList[index].harga) + value;
    if(!isNaN(value) && value.length > -1){
      updateList[index] = {...updateList[index], potongan: value};
      setSavedItems(updateList);
      setTotalQty(qtyTotal + aqtyTotal);
    }else{return false}
  }

  const handleBayar = (index, value) => {
    let updateList = savedItems;
    let aqtyTotal = parseInt(updateList[index].potongan) + value;
    if(!isNaN(value) && value.length > -1){
      updateList[index] = {...updateList[index], bayar: value};
      setSavedItems(updateList);
      setTotalQty(qtyTotal + aqtyTotal);
    }else{return false}
  }
  
    const deleteItem = (id) => {
      let array = [...savedItems];
  
      let index = array.findIndex(object => {
        return object.item_id === id;
      });
  
      if (index !== -1) {
        array.splice(index, 1);
        setSavedItems(array);
      }
    }

  useEffect(() => {
    getPerson();
    getbank();

  }, []);

  const getPerson = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/person/list`,
        { headers }
      )
      .then((data) => {
        setPersons(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getPersonCode = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/person/${id}`,
        { headers }
      )
      .then((data) => {
        getItemDataSaved(data.data.response.person_code);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getbank = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/account/list-by-kas/${warehouse}`,
        { headers }
      )
      .then((data) => {
        setBanks(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                warehouse_id: dataItem.warehouse_id,
                code_retur : dataItem.code_retur,
                // tgl_tempo : dataItem.tgl_tempo,
                payment_total : dataItem.payment_total,
                keterangan2 : dataItem.keterangan2, 
                potongan: dataItem.potongan,
                bayar : dataItem.bayar,
            }]);
    let data = {
      warehouse_id : parseInt(warehouse),
      username : username,
      account_id : parseInt(bank),
      person_id : parseInt(person),
      payment_method : parseInt(bank),
      keterangan1 : keterangan,
      status_af :3,
      status_d :3,
      // created_at : moment(tanggal).format("YYYY-MM-DD 00:00:00"),
      list : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/bkm/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/bm-retur/kas/page")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={CreateData}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };

const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}
  
  return (
    <>
    {alert}
    <SimpleHeader name="Buat BM Retur" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col"> 
                <CardBody>
                <Card className="bg-secondary shadow">
                          <CardHeader className="bg-white border-0">
                              <h3>Buat BM Retur</h3>
                          </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Supplier
                              </Label>
                              <Col sm={7}>
                                <Select2
                                      className="form-control-alternative"
                                      defaultValue="0"
                                      value={person}
                                      onChange={(e) => {
                                        setPerson(e.target.value);
                                        getPersonCode(e.target.value)
                                      }}
                                      options={{
                                        placeholder: "Pilih Supplier"
                                      }}
                                      data={persons.map((person), key => {
                                        return (
                                        { id: person.id, text: person.person_name, key:key}
                                        );
                                      })}
                                    />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                    sm={4}
                                >
                                    Akun
                                </Label>
                                <Col sm={7}>
                                <Input
                                autoComplete="off"
                                    name="coa"
                                    type="select"
                                    value={bank}
                                    onChange={(e) => {
                                    setBank(e.target.value);
                                    }}
                                >
                                    <option value="" disabled selected hidden>Pilih Akun</option>
                                    {banks.map((coa, key) => {
                                    return (
                                        <option key={key} value={coa.id}>
                                        {coa.account_name}
                                        </option>
                                    );
                                    })}
                                </Input>
                                </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                             <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Keterangan
                              </Label>
                              <Col sm={7}>
                                <Input
                                className="form-control-alternative"
                                
                                  type="textarea"
                                  name="desc"
                                  rows = "4"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                </Card>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Retur</h3>
                    </CardHeader>
                        <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Kode Retur</b></th>
                                <th><b>Tempo</b></th>
                                <th><b>Tagihan</b></th>
                                <th><b>Keterangan</b></th>
                                <th><b>Potongan</b></th>
                                <th><b>Bayar</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.code_retur}</td>
                                      <td>{savedItem.tgl_tempo}</td>
                                      <td>{formatRupiah(savedItem.payment_total)}</td>
                                      <td>{savedItem.keterangan2}</td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="Rp 0"
                                          type="number"
                                          value={savedItem.potongan}
                                          onChange={(e) => {
                                              handlePotongan(key, e.target.value);
                                          }}/>
                                        </td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="Rp 0"
                                          type="number"
                                          value={savedItem.bayar}
                                          onChange={(e) => {
                                              handleBayar(key, e.target.value);
                                          }}/>
                                        </td>
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/bm-retur/kas/page">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={()=>setQuestionAlert()}>
                          Simpan
                      </Button>
                </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>
    </>
  );
}

export default CreateFinanceBkmRetur;