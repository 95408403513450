/*eslint-disable*/
import React from "react";
import {
  Card,
  CardBody,
  Row,
  Container,
  CardImg,
} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function PagePenjualan() {
   
  return (
    <>
      <div>
      <SimpleHeader name="Laporan Penjualan" parentName="Report" />
      <Container className="mt--6" fluid>
                        {/* <div style={{ textAlign: 'left' }}>
                            <Link className="btn btn-link" to="/admin/bkk-finance">
                                <i className="fa fa-arrow-circle-left fa-2x" /> 
                            </Link>
                        </div> */}
        <Row>
          <div className="col">
                <CardBody>
                    <Row>
                        <div className="card-columns">
                        <Card>
                                <Link to="/admin/laporan/penjualan/laporan-rfq-penjualan">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/rfq.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/laporan/penjualan/laporan-penjualan">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/penjualan.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        <Card>
                                <Link to="/admin/laporan/penjualan/laporan-pengiriman">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/surat-jalan-penjualan.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/laporan/penjualan/laporan-faktur-jual">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/faktur-penjualan.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/laporan/penjualan/laporan-retur-jual">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/retur-penjualan.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/laporan/penjualan/laporan-kasir-nota">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/laporan-kasir.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/laporan/penjualan/laporan-kasir-settlement">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/laporan-tutup-kasir.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/laporan/penjualan/laporan-kasir-surat-jalan">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/laporan-surat jalan-kasir.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            {/* <Card>
                                <Link to="/admin/laporan/penjualan/laporan-kasir-retur">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/surat-jalan-eksternal.png").default}
                                        top
                                    />
                                </Link>
                                kasir Retur
                            </Card> */}
                        </div>
					            </Row>
                </CardBody>
          </div>
        </Row>
      </Container>
      </div>
    </>
  );
}