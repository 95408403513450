/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input,

} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
// import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";
import CurrencyInput from 'react-currency-input-field';

export default function DetailReturPO(props)  {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const [persons, setPersons] = useState([]);
  const [person, setPerson] = useState("");
  const [codeinvoice,setCodeInvoice ] = useState("");
  const [payment_method,setPaymentMethod] = useState(0);
  const [type,setType] =useState("");
  const [keterangan, setKeterangan] = useState("");
  const [allJangkaWaktu, setAllJangkaWaktu] = useState([]);
  const [jangkaWaktu,setJangkaWaktu] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [savedItems, setSavedItems] = useState([]);
  // const [statusd, setStatusD] = useState("");
  // const [statusm, setStatusM] = useState("");
  const [ongkir, setOngkir] = useState(0);
  // const [lainnya, setLainnya] = useState(0);
  const [allpajak, setAllPajak] = useState([]);
  const [pajak, setPajak] = useState(1);
  const [diskonglobalnominal, setDiskonGlobalNominal] = useState(0);
  const [diskonglobalpersen, setDiskonGlobalPersen] = useState(0);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [editingItem, setEditingitem] = useState([{ editing: false}]);
  // const [editable, setEditable] = useState(false);
  const [qtyTotal, setTotalQty] = useState(0);
  const [diskontotal, setDiskonTotal] = useState(0);
  // const [isConfEditableOpen, setIsConfEditableOpen] = useState(false);
  // const [confPassEditable, setConfPassEditable] = useState('');
  const [totalPrice1, setTotalPrice1] = useState(0);
  const a = 0;
	const b = 0;
  const c = 0;
  const [dp, setDp] = useState(0);
  const [dp1, setDp1] = useState(0);
  const [diskonglobalnominal1,setDiskonGlobalNominal1] = useState(0)
const [totaldiskon, setTotalDiskon] = useState(0);
  const [totaldiskon2, setTotalDiskon2] = useState(0);
  const [totaldiskon3, setTotalDiskon3] = useState(0);
const [ppn, setPPN] = useState(0);
const [grandtotal, setGrandTotal] = useState(0);
// const [tanggal, setTanggal] = useState("");
const [ongkir1, setOngkir1] = useState(0);
const [ppnnew, setPPNNEW] = useState(0);
const [diskonglobal, setDiskonGLobal] = useState(0);
const [banksupplier, setBankSupplier] = useState("")
  const [banksuppliers, setBankSuppliers] = useState([])
  const allInfo = JSON.parse(localStorage.allinfo);
  const returPOs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Retur PO").map((p) => p.read_access));
  
	// useEffect(() => {
  //   setDiskonGlobalNominal1(diskonglobalnominal);
  // }, [diskonglobalnominal]);
  
  //   // diskon bayangan
  //   useEffect(() => {
  //   setTotalDiskon(diskonglobalnominal1);
  // }, [diskonglobalnominal1]);
  
  // useEffect(() => {
  //   setTotalDiskon3(diskontotal-a);
  // }, [diskontotal, a]);
  
  //   //diskon tampil
  //   useEffect(() => {
  //   setTotalDiskon2(totaldiskon - a);
  // }, [totaldiskon3, totaldiskon, a]);
  
  // //ongkir tampil
  // useEffect(() => {
  //   setOngkir1(ongkir - b);
  // }, [ongkir, b]);
  
  
  //   useEffect(() => {
  //   setTotalPrice1(qtyTotal);
  // }, [qtyTotal]);
  
  
  //   // diskonglobalnominal dan persen
  //   useEffect(() => {
  //     diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
  //     diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
  //   }, [diskonglobalnominal, qtyTotal, diskonglobalpersen,diskontotal,diskonglobalpersen]);
  
  //   // hasil nominal dari PPN
  //   useEffect(() => {
  //       setPPNNEW( (qtyTotal - totaldiskon) * (ppn / 100));
  //  }, [qtyTotal,totaldiskon,ppn]);
  
  //   // hasil grandtotal
  // useEffect(() => {
  //   setGrandTotal(( qtyTotal - totaldiskon2) + ppnnew + ongkir1- a );
  // }, [qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);

//   useEffect(() => {
//     setDiskonGlobalNominal1(diskonglobalnominal);
//   }, [diskonglobalnominal]);

//     // diskon bayangan
//     useEffect(() => {
//     setTotalDiskon(diskonglobalnominal1);
//   }, [diskonglobalnominal1]);

//   useEffect(() => {
//     setTotalDiskon3(diskontotal-a);
//   }, [diskontotal, a]);

//     //diskon tampil
//     useEffect(() => {
//     setTotalDiskon2(totaldiskon - a);
//   }, [totaldiskon3, totaldiskon, a]);

//   //ongkir tampil
//   useEffect(() => {
//     setOngkir1(ongkir - b);
//   }, [ongkir, b]);


//     useEffect(() => {
//     setTotalPrice1(qtyTotal - ppnnew);
//   }, [qtyTotal, ppnnew]);


//     // diskonglobalnominal dan persen
//     useEffect(() => {
//       diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
//       diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
//     }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);

//      // hasil nominal dari PPN
//    useEffect(() => {
//     setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
// }, [qtyTotal,totaldiskon,ppn]);

useEffect(() => {
  setDiskonGlobalNominal1(diskonglobalnominal);
}, [diskonglobalnominal]);

  // diskon bayangan
  useEffect(() => {
  setTotalDiskon(diskonglobalnominal1);
}, [diskonglobalnominal1]);

useEffect(() => {
  setTotalDiskon3(diskontotal-a);
}, [diskontotal, a]);

  //diskon tampil
  useEffect(() => {
  setTotalDiskon2((totaldiskon)/((ppn/100)+1));
}, [totaldiskon3, totaldiskon, ppn , a]);

//ongkir tampil
useEffect(() => {
  setOngkir1(ongkir - b);
}, [ongkir, b]);

  useEffect(() => {
  setTotalPrice1((qtyTotal)/((ppn/100)+1));
}, [qtyTotal,ppn]);

useEffect(() => {
  setDp1(dp - c);
}, [dp, c]);

  // diskonglobalnominal dan persen
  useEffect(() => {
    diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
    diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
  }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);

  // hasil nominal dari PPN
  useEffect(() => {
    setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
  }, [qtyTotal,totaldiskon,ppn]);

  // hasil grandtotal
useEffect(() => {
  setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew - dp1 +  ongkir1- a );
}, [totalPrice1,qtyTotal, totaldiskon2, ppnnew,dp1, ongkir1, a]);


	const handleEditQty = (index, value) => {
		let updateList = savedItems;
		let minTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty) - parseInt(updateList[index].diskon_nominal);
		let plusTotal = parseInt(updateList[index].harga) * value - parseInt(updateList[index].diskon_nominal);
		let aqtyTotala = parseInt(updateList[index].harga) * value ;
		let aqtyTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty);
		let diskonnominal1 = parseInt(updateList[index].diskon_nominal) * value ;
		let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
		
		if(!isNaN(value)&&value.length>0){
			updateList[index] = {...updateList[index], qty: value};
			setSavedItems(updateList);
			setTotalPrice(totalPrice + plusTotal - minTotal);
			setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
			setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
		}else{return false}
	}

	const handleEditHarga = (index, value) => {
		if(!isNaN(value)&&value.length>0){
			let updateList = savedItems;
			let minTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty) - parseInt(updateList[index].diskon_nominal);
			let aqtyTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty);
			updateList[index] = {...updateList[index], harga: value};
			setSavedItems(updateList);
			let plusTotal = parseInt(updateList[index].qty) * value - parseInt(updateList[index].diskon_nominal);
			setTotalPrice(totalPrice + plusTotal - minTotal);
			let aqtyTotala = parseInt(updateList[index].qty) * value;
			setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
		}else{return false}
	}

	const handleEditDiskonNominal = (index, value) => {
		if(!isNaN(value)&&value.length>0){
			let updateList = savedItems;
			let diskonnominal1 = parseInt(updateList[index].qty) * value ;
			let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
			let persentasediskonnya = value / parseInt(updateList[index].harga) * 100;
			let aqtyTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty);
			let minTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty) - parseInt(updateList[index].diskon_nominal);
			updateList[index] = {...updateList[index], diskon_nominal: value, diskon_persen: persentasediskonnya};
			setSavedItems(updateList);
			let plusTotal = parseInt(updateList[index].qty) * parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal);
			setTotalPrice(totalPrice+plusTotal-minTotal);
			let aqtyTotala = parseInt(updateList[index].harga) * parseInt(updateList[index].qty);
			setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
			setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
		}else{return false}
	}

	const handleEditDiskonPersen = (index, value) => {
		if(!isNaN(value)&&value.length>0){
			let updateList = savedItems;
			let  nominaldiskonnya = parseInt(updateList[index].harga) * value/100;
			let minTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty) - parseInt(updateList[index].diskon_nominal);
			updateList[index] = {...updateList[index], diskon_nominal: nominaldiskonnya, diskon_persen: value};
			setSavedItems(updateList);
			let plusTotal = parseInt(updateList[index].qty) * parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal);
			setTotalPrice(totalPrice+plusTotal-minTotal);
			let qatyTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty);
			setTotalQty(qtyTotal + qatyTotal);
			let diskonnominal1 = parseInt(updateList[index].qty) * value ;
			let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
			setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2 )
		}else{return false}
	}

  useEffect(() => {
    getByPajak();
}, [pajak]);

const getByPajak = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(
      `${process.env.REACT_APP_API_BASE_URL}/pajak/${pajak}`,
      { headers }
    )
    .then((data) => {;
      setPPN(data.data.response.persentase);
    })
    .catch(function (error) {
      console.log(error);
    });
};

  const getPerson = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/person/list`,
        { headers }
      )
      .then((data) => {
        setPersons(data.data.response);
        setPerson(id)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getbankSupplier = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-person/${id}`, { headers })
      .then((data) => {
        setBankSuppliers(data.data.response);
        // setBankSupplier(id)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getJangkaWaktu = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/jwkredit/list`, { headers })
      .then((data) => {
        setAllJangkaWaktu(data.data.response);
        setJangkaWaktu(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  const getPajak = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
      .then((data) => {
        setAllPajak(data.data.response);
        setPajak(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/po-retur/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        getPerson(data.data.response.person_id);
        getbankSupplier(data.data.response.person_id);
        setBankSupplier(data.data.response.account_number);
        setCodeInvoice(data.data.response.invoice_code);
        getPajak(data.data.response.pajak_id);
        // setPoCode(data.data.response.code_po);
        // setStatusD(data.data.response.status_d);
        // setStatusM(data.data.response.status_ar);
        // setType(data.data.response.type);
        setOngkir(data.data.response.ongkir);
        setTotalQty(data.data.response.price_real);
        setDiskonTotal(data.data.response.diskon_total);
          setDp(data.data.response.um_nominal)
          // setLainnya(data.data.response.lainnya);
        setKeterangan(data.data.response.keterangan);
        setTotalPrice(data.data.response.price_real);
        getJangkaWaktu(data.data.response.jangka_waktu);
        setPaymentMethod(data.data.response.payment_method);
        setDiskonGlobalNominal(data.data.response.diskon_global_nominal);
        setDiskonGlobalPersen(data.data.response.diskon_global_persen);
        // setTanggal(moment(data.data.response.created_at, "DD/MM/YYYY").format("YYYY-MM-DD"));
        setCodeInvoice(data.data.response.retur_code)
        getItemDataSaved();
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/po-retur/item`, {

        rpo_id: props.match.params.id

    }).then(async response => {
        let stateItem = [];
        let stateEditing = [];

        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                satuan: data.satuan,
                qty: data.qty,
                harga: data.harga,
                diskon_nominal: data.diskon_nominal,
                diskon_persen: data.diskon_persen,
                note : data.note,
                data: {
                    item_name: data.item_name,
                    qty: data.qty,
                    harga: data.harga
                },
            }];
                stateEditing = [...stateEditing, {
                  editing: false
            }];
      }));
        // setEditingitem(stateEditing);
        setSavedItems(stateItem);
    })
}

const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}
  

  const HargaDiskonPersen = (e) => {
    const value = e === undefined ? 'undefined' : e;
    setDiskonGlobalPersen(value || ' ');
    setDiskonGLobal("diskonglobalpersen")
    };
  
    const HargaDiskonNominal = (e) => {
      const value = e === undefined ? 'undefined' : e;
      setDiskonGlobalNominal(value || ' ');
      setDiskonGLobal("diskonglobalnominal")
      };
      
      const HargaOngkir = (e) => {
        const value = e === undefined ? 'undefined' : e;
        setOngkir(value || ' ');
        };


  return (
    <>
    {alert}
    {returPOs && returPOs === "1" ? (
			<div>
    <SimpleHeader name="Detail Retur Pembelian" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Detail Retur PO</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode Retur
                              </Label>
                              <Col sm={6}>
                                <Input
                                  disabled
                                  className="form-control-alternative"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Masukan Kode Invoice"
                                  value={codeinvoice}
                                  onChange={(e) => {
                                    setCodeInvoice(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Supplier
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  name="person"
                                  type="select"
                                  value={person}
                                  onChange={(e) => {
                                    setPerson(e.target.value);
                                  }}
                                >
                                  <option value=''>Pilih Supplier</option>
                                  {persons.map((person, key) => {
                                      return (
                                        <option key={key} value={person.id}>
                                          {person.person_name}
                                        </option>
                                      );
                                    })}
                                </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                Bank
                              </Label>
                              <Col sm={6}>
                                <Input
                                  disabled
                                  className="form-control-alternative"
                                  type="select"
                                  value={banksupplier}
                                  onChange={(e) => {
                                    setBankSupplier(e.target.value);
                                  }}>
                                  <option value="" disabled selected hidden>Pilih Bank</option>
                                    {banksuppliers.map((supplier, key) => {
                                      return (
                                          <option key={key} value={supplier.account_number}>
                                            {supplier.bank_name} {supplier.account_number} a/n {supplier.account_name} 
                                          </option>
                                      );
                                    })}
                                  </Input>
                              </Col>
													  </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                Diskon
                              </Label>
                              <Col sm={3}>
                                <CurrencyInput
                                  disabled
                                  id="validation-example-2-field"
                                  placeholder="0"
                                  allowDecimals={false}
                                  value={diskonglobalpersen}
                                  onValueChange={HargaDiskonPersen}
                                  className={`form-control`}
                                  suffix={'%'}
                                  step={10}
                                />
                              </Col>
                              <Col sm={3}>
                              <CurrencyInput
                                  disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={diskonglobalnominal}
                                  onValueChange={HargaDiskonNominal}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                className="form-control-label" for="exampleEmail" sm={3}>
                                    PPN
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        disabled
                                        className="form-control-alternative"
                                        type="select"
                                        value={pajak}
                                        onChange={(e) => {
                                            setPajak(e.target.value);
                                    }}>
                                <option value='' disabled selected hidden>Pilih PPN</option>
                                  {allpajak.map((warehouse2, key) => {
                                    return (
                                        <option key={key} value={warehouse2.id}>
                                          {warehouse2.keterangan}
                                        </option>
                                    );
                                  })}
                                </Input>
														</Col>
													  </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Metode Pembayaran
                            </Label>
                            <Col sm={6}>
                            <Input
                            disabled
                            className="form-control-alternative"
                                name="Tipe Po"
                                type="select"
                                value={payment_method}
                                onChange={(e) => {
                                    setPaymentMethod(e.target.value);
                                }}
                              >
                                <option value={""}>Pilih Metode Pembayaran</option>
                                <option value={1}>Lunas</option>
                                <option value={2}>Tempo</option>
                                <option value={5}>Dp/Kredit</option>
                              </Input>
                            </Col>
                            </FormGroup>
                            {payment_method >  1  ? (
                              <FormGroup row>
                                <Label
                                className="form-control-label"
                                  for="exampleEmail"
                                  sm={3}
                                >
                                  Jangka Waktu
                                </Label>
                                <Col sm={4}>
                                  <Input
                                  disabled
                                    className="form-control-alternative"
                                    type="select"
                                    name="desc"
                                    value={jangkaWaktu}
                                    onChange={(e) => {
                                      setJangkaWaktu(e.target.value);
                                    }}
                                  >
                                  <option value="" disabled selected hidden>Pilih Jangka Waktu</option>
                                  {allJangkaWaktu.map((waktu, key) => {
                                    return (
                                      <option key={key} value={waktu.durasi}>
                                        {waktu.durasi}
                                      </option>
                                    );
                                  })}
                                </Input>
                                </Col> 
                                <Label
                                className="form-control-label" for="exampleEmail" sm={3}>
                                  <b>Hari</b>
                                </Label>
                              </FormGroup>
                            ):(
                              ""
                            )}
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Ongkir
                              </Label>
                              <Col sm={6}>
                              <CurrencyInput
                                    disabled
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={ongkir}
                                    onValueChange={HargaOngkir}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                  />
                              </Col>                             
                            </FormGroup>
                            {/* {payment_method === 5 ? 
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Uang Muka
                              </Label>
                              <Col sm={6}>
                              <CurrencyInput
                              disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={dp}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>                             
                            </FormGroup>
                            : 
                            ""
                            } */}
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Keterangan/Alasan</h3>
                    </CardHeader>
                <CardBody>
                  <Row md="6">
                    <Col md="6">
                      <FormGroup row>
                        <Label
                          className="form-control-label"
                          for="exampleEmail"
                          sm={3}
                        >
                          Keterangan
                        </Label>
                        <Col sm={6}>
                          <Input
                          disabled
                          className="form-control-alternative"
                            type="textarea"
                            name="desc"
                            rows = "4"
                            placeholder="Masukan Keterangan"
                            value={keterangan}
                            onChange={(e) => {
                              setKeterangan(e.target.value);
                            }}
                          />
                        </Col>                             
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                        <CardBody>
                        <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>kode Item</b></th>
                                <th><b>Harga</b></th>
                                <th><b>Qty</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Diskon %</b></th>
                                <th><b>Diskon (N)</b></th>
                                <th><b>Sub Total</b></th>
                                <th><b>Note</b></th>
                                {/* <th>
                                  {editable?(<Button color="danger" onClick={() => setEditable(false)} size="sm">Simpan</Button>
                                  ):(<Button onClick={() => setIsConfEditableOpen(true)} size="sm">Izinkan Edit</Button>)}
                                </th> */}
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td>{formatRupiah(savedItem.harga)}</td>
                                      <td>{savedItem.qty}</td>
                                      <td>{savedItem.satuan}</td>
                                      <td>{savedItem.diskon_persen}</td>
                                      <td>{formatRupiah(savedItem.diskon_nominal)}</td>
                                      <td>
                                      {/* {formatRupiah((((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) - ((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) * (ppn/100)))} */}
                                      {formatRupiah(((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) / (ppn/100 + 100/100))}

                                        {/* {formatRupiah((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty)} */}
                                      </td>
                                      <td>{savedItem.note}</td>

                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter >
                          <Row md="12">
                            <Col md="6">
                            </Col>
                            <Col md="2">
                            </Col>
                            <Col md="4">
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                                size="small"
                              >
                                <b>Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Harga Total"
                                // value={totalPrice1}
                                value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                <b>Diskon</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                style={{fontWeight: 'bold'}}
                                disabled
                                type="text"
                                name="barcode"
                                placeholder="Diskon"
                                value={"Rp." + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                <b>PPN</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                <b>Ongkir</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              {/* <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Uang Muka</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Uang Muka"
                                value={"Rp." + dp1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup> */}
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                <b>Grand Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                type="text"
                                name="barcode"
                                style={{fontWeight: 'bold'}}
                                placeholder="Grand Total"
                                value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>

                            </Col>
                          </Row>
                        </CardFooter>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/po-retur/histori">
                        Kembali
                      </Link>
                </CardFooter>
              </Card>
          </div>
        </Row>
    </Container>  
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}