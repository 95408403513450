/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  FormGroup,
  Label,
  Input,
  
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment";
import Halaman404 from "views/404";

export default function DetailRFQCabang(props) {
    const token = localStorage.token;
    const [rfq_code,setRfqCode] = useState("");
    const [typereq,setTypeReq] = useState([]);
    const [keterangan,setKeterangan] = useState("");
    const [savedItems, setSavedItems] = useState([]);
    const [tanggal, setTanggal] = useState("");
    const allInfo = JSON.parse(localStorage.allinfo);
    const permintaanBarangs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Rfq PO").map((p) => p.read_access));
    
    useEffect(() => {
      getById();
    }, []);
  
    const getById = () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/rfq-po/get/${props.match.params.id}`,
          { headers }
        )
        .then((data) => {
          getItemDataSaved();
          // setTanggal(moment(data.data.response.created_at, "DD/MM/YYYY").format("YYYY-MM-DD"));
          setTanggal(data.data.response.created_at);
          setRfqCode(data.data.response.rfq_code);
          setTypeReq(data.data.response.type);
          setKeterangan(data.data.response.keterangan);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const getItemDataSaved = () => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/rfq-po/item`, {

            rfq_id: props.match.params.id

        }).then(async response => {
            let stateItem = [];

            await Promise.all(response.data.response.map(async (data) => {
                stateItem = [...stateItem, {
                    item_id: data.item_id,
                    item_name:data.item_name,
                    item_code:data.item_code,
                    qty: data.qty,
                    satuan : data.satuan,
                    note : data.note,
                    data: {
                        item_name: data.item_name,
                        qty: data.qty
                    },
                }];
            }));

            setSavedItems(stateItem);
        })
    }

  return (
    <>
     {permintaanBarangs && permintaanBarangs === "1" ? (
			<div>
    <SimpleHeader name="Detail Permintaan Barang" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Detail Permintaan Barang</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                            
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode RFQ
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                  type="text"
                                  name="desc"
                                  placeholder="Masukan RfqCode"
                                  value={rfq_code}
                                  onChange={(e) => {
                                    setRfqCode(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Tanggal
                              </Label>
                              <Col sm={6}>
                                <Input
                                  disabled
                                  className="form-control-alternative"
                                  autoComplete="off"
                                  type="date"
                                  placeholder="Masukan Tanggal"
                                  value={tanggal}
                                  onChange={(e) => {
                                    setTanggal(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Tipe PO
                            </Label>
                            <Col sm={6}>
                            <Input
                                disabled
                                name="Tipe Po"
                                type="select"
                                value={typereq}
                                onChange={(e) => {
                                    setTypeReq(e.target.value);
                                }}
                              >
                                <option value="">Pilih Request</option>
                                <option value={1}>Customer Request</option>
                                <option value={2}>Stok Request</option>
                              </Input>
                            </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Keterangan
                              </Label>
                              <Col sm={6}>
                                <Input
                                  disabled
                                  type="textarea"
                                  name="desc"
                                  rows = "5"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                    <CardBody>
                    <h3>Item</h3>
                      <Table size="sm" responsive>
                              <thead>
                                <tr>
                                  <th>
                                    Nama Item
                                  </th>
                                  <th>
                                    Kode Item
                                  </th>
                                  <th>
                                    Qty
                                  </th>
                                  <th>
                                    Satuan
                                  </th>
                                  <th>
                                    Note
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                    savedItems.map((savedItem, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{savedItem.item_name}</td>
                                                <td>{savedItem.item_code}</td>
                                                <td>{savedItem.qty}</td>
                                                <td>{savedItem.satuan}</td>
                                                <td>{savedItem.note}</td>
                                            </tr>
                                        )
                                    })
                                }
                              </tbody>
                      </Table>
                    </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/permintaan-barang/histori">
                        Kembali
                      </Link>
                </CardFooter>
              </Card>
          </div>
        </Row>
    </Container>  
    </div>
     ):(
      <Halaman404 />
     )}
    </>
  );
}
