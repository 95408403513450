/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  FormGroup,
  Label,
  Input,
  Modal, ModalHeader, ModalBody
  
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";
import CurrencyInput from 'react-currency-input-field';

export default function EditPermintaanBarang(props) {
const [alert, setAlert] = React.useState(null);
  const [tanggal,setTanggal] = useState("")
  const token = localStorage.token;
    const warehouse = localStorage.warehouse;
    const username = localStorage.username;
    let history = useHistory();
    const [statusrfq, setStatusRfq] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [rfqcode,setRfqCode] = useState("");
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState("");
    const [typereq,setTypeReq] = useState([]);
    const [pengiriman, setPengiriman] = useState("");
    const [keterangan,setKeterangan] = useState("");
    const [sales, setSales] = useState("");
    const [savedItems, setSavedItems] = useState([]);
    const [allpajak, setAllPajak] = useState([]);
    const [ppn, setPPN] = useState(0);
    const [totaldiskon, setTotalDiskon] = useState(0);
    const [grandtotal, setGrandTotal] = useState(0);
    const [alamat, setAlamat] = useState("");
    const [diskonglobalnominal, setDiskonGlobalNominal] = useState(0);
    const [diskonglobalpersen,setDiskonGlobalPersen] = useState(0);
    const [ongkir, setOngkir] = useState(0);
    const [pajak, setPajak] = useState("");
    const [payment, setPayment] = useState("");
    const [lainnya, setLainnya] = useState(0);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [ongkir1, setOngkir1] = useState(0);
    const [ppnnew, setPPNNEW] = useState(0);
    const [diskonglobal, setDiskonGLobal] = useState(0);
    const [totalPrice1, setTotalPrice1] = useState(0);
    const [a, setA] = useState(0);
    const [b, setB] = useState(0);
    const [diskonglobalnominal1,setDiskonGlobalNominal1] = useState(0)
    const [totaldiskon2, setTotalDiskon2] = useState(0);
    const [totaldiskon3, setTotalDiskon3] = useState(0);
    const [qtyTotal, setTotalQty] = useState(0);
    const [diskontotal, setDiskonTotal] = useState(0);
    const allInfo = JSON.parse(localStorage.allinfo);
    const permintaanBarangs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Penawaran SO").map((p) => p.read_access));
    

    useEffect(() => {
      setDiskonGlobalNominal1(diskonglobalnominal);
    }, [diskonglobalnominal]);
  
      // diskon bayangan
      useEffect(() => {
      setTotalDiskon(diskonglobalnominal1);
    }, [diskonglobalnominal1]);
  
    useEffect(() => {
      setTotalDiskon3(diskontotal-a);
    }, [diskontotal, a]);
  
      //diskon tampil
      useEffect(() => {
      setTotalDiskon2((totaldiskon)/((ppn/100)+1));
    }, [totaldiskon3, totaldiskon, ppn , a]);
  
    //ongkir tampil
    useEffect(() => {
      setOngkir1(ongkir - b);
    }, [ongkir, b]);
  
      useEffect(() => {
      setTotalPrice1((qtyTotal)/((ppn/100)+1));
    }, [qtyTotal, ppnnew,ppn]);
  
  
      // diskonglobalnominal dan persen
      useEffect(() => {
        diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
        diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
      }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);
  
      // hasil nominal dari PPN
      useEffect(() => {
        setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
      }, [qtyTotal,totaldiskon,ppn]);
  
      // hasil grandtotal
    useEffect(() => {
      setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew + ongkir1- a );
    }, [totalPrice1,qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);
  
    

    // useEffect(() => {
    //   setDiskonGlobalNominal1(diskonglobalnominal);
    // }, [diskonglobalnominal]);
  
    //   // diskon bayangan
    //   useEffect(() => {
    //   setTotalDiskon(diskonglobalnominal1);
    // }, [diskonglobalnominal1]);
  
    // useEffect(() => {
    //   setTotalDiskon3(diskontotal-a);
    // }, [diskontotal, a]);
  
    //   //diskon tampil
    //   useEffect(() => {
    //   setTotalDiskon2(totaldiskon - a);
    // }, [totaldiskon3, totaldiskon, a]);
  
    // //ongkir tampil
    // useEffect(() => {
    //   setOngkir1(ongkir - b);
    // }, [ongkir, b]);
  
  
    //   useEffect(() => {
    //   setTotalPrice1(qtyTotal - ppnnew);
    // }, [qtyTotal, ppnnew]);
  
  
    //   // diskonglobalnominal dan persen
    //   useEffect(() => {
    //     diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
    //     diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
    //   }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);
  
    //   // hasil nominal dari PPN
    //   useEffect(() => {
    //     setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
    //   }, [qtyTotal,totaldiskon,ppn]);
  
    //   // hasil grandtotal
    // useEffect(() => {
    //   setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew + ongkir1- a );
    // }, [totalPrice1,qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);

    
  // useEffect(() => {
  //   setDiskonGlobalNominal1(diskonglobalnominal);
  // }, [diskonglobalnominal]);

  //   // diskon bayangan
  //   useEffect(() => {
  //   setTotalDiskon(diskonglobalnominal1);
  // }, [diskonglobalnominal1]);

  // useEffect(() => {
  //   setTotalDiskon3(diskontotal-a);
  // }, [diskontotal, a]);

  //   //diskon tampil
  //   useEffect(() => {
  //   setTotalDiskon2(totaldiskon - a);
  // }, [totaldiskon3, totaldiskon, a]);

  // //ongkir tampil
  // useEffect(() => {
  //   setOngkir1(ongkir - b);
  // }, [ongkir, b]);


  //   useEffect(() => {
  //   setTotalPrice1(qtyTotal);
  // }, [qtyTotal]);


  //   // diskonglobalnominal dan persen
  //   useEffect(() => {
  //   // diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((qtyTotal - diskontotal) * diskonglobalpersen);
  //   diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal - diskontotal) * (diskonglobalpersen/100));
  // }, [diskonglobalnominal, qtyTotal, diskonglobalpersen,diskontotal]);

  //   // hasil nominal dari PPN
  //   useEffect(() => {
  //       setPPNNEW( (qtyTotal - totaldiskon) * (ppn / 100));
  //  }, [qtyTotal,totaldiskon,ppn]);

  //   // hasil grandtotal
  // useEffect(() => {
  //   setGrandTotal(( qtyTotal - totaldiskon2) + ppnnew + ongkir1- a );
  // }, [qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);

  
  //   useEffect(() => {
  //     getByIds();
  // }, [pajak]);
  
  const getByIds = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/pajak/${id}`,
        { headers }
      )
      .then((data) => {;
        setPPN(data.data.response.persentase);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  
    useEffect(() => {
      getById();
      getPajak();
    }, []);
  
    const getById = () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/rfq-so/get/${props.match.params.id}`,
          { headers }
        )
        .then((data) => {
          getCustomer(data.data.response.customer_id);
          getPajak(data.data.response.pajak_id);
          getByIds(data.data.response.pajak_id);
          setSales(data.data.response.username);
          getItemDataSaved();
          setTanggal(data.data.response.created_at)
          setRfqCode(data.data.response.rfq_code);
          setTotalQty(data.data.response.price_real);
          setDiskonTotal(data.data.response.diskon_total);
          setTypeReq(data.data.response.type);
          setPengiriman(data.data.response.pengiriman);
          setKeterangan(data.data.response.keterangan);
          setOngkir(data.data.response.ongkir);
          setLainnya(data.data.response.lainnya);
          setPayment(data.data.response.payment_method);
          setDiskonGlobalNominal(data.data.response.diskon_global_nominal);
          setDiskonGlobalPersen(data.data.response.diskon_global_persen);
          setAlamat(data.data.response.manual_address);
        })
        .catch(function (error) {
          console.log(error);
        });
    };


    const getPajak = (id) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      };
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
        .then((data) => {
          setAllPajak(data.data.response);
          setPajak(id);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const getItemDataSaved = () => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/rfq-so/item`, {

            rfq_id: props.match.params.id

        }).then(async response => {
            let stateItem = [];

            await Promise.all(response.data.response.map(async (data) => {
                stateItem = [...stateItem, {
                    item_id: data.item_id,
                    item_name:data.item_name,
                    item_code:data.item_code,
                    satuan : data.satuan,
                    qty: data.qty,
                    harga : data.harga,
                    diskon_nominal: data.diskon_nominal,
                    diskon_persen : data.diskon_persen,
                    data: {
                        item_name: data.item_name,
                        harga: data.harga
                    },
                }];
            }));
            setSavedItems(stateItem);
        })
    }

    const getCustomer = (id) => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/customer/list`,
            { headers }
          )
          .then((data) => {
            setCustomers(data.data.response);
            setCustomer(id);
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      const handleSubmit1 = () => {
        hideAlert();
        setTimeout(() => {EditData()}, 1000);
      };
    
    function EditData() {
      setLoading(true);
      let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga : dataItem.harga,
                diskon_nominal: dataItem.diskon_nominal,
                diskon_persen : dataItem.diskon_persen,
            }]);
        let data = {
        warehouse_id : parseInt(warehouse),
        pajak_id : parseInt(pajak),
        username : sales,
        customer_id: parseInt(customer),
        status_rfq: parseInt(statusrfq),
        status : 3,
        sales_head: username,
        pengiriman : parseInt(pengiriman),
        type : parseInt(typereq),
        keterangan: keterangan,
        manual_address : alamat, 
        diskon_global_nominal : diskonglobalnominal,
        diskon_global_persen : diskonglobalpersen,
        ongkir : parseInt(ongkir),
        lainnya : parseInt(lainnya),
        payment_method: parseInt(payment),
        items : dataItems
        };
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/rfq-so/update/${props.match.params.id}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          
          setSuccessAlert(response.data.message);
				setTimeout(() => (history.push("/admin/penawaran-barang/validasi-manajer-toko")), 1000);
        })
        .then((json) => {
          setLoading(false);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
      }

      const setSuccessAlert = (id) => {
        setAlert(
          <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
          />
        )
        }
      
      const setGagalAlert = (id) => {
      setAlert(
        <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
        />
      );
      };

      const setQuestionAlert = () => {
        setAlert(
          <SweetAlert
          warning
          showCancel
          confirmBtnText="Lanjutkan"
          confirmBtnBsStyle="danger"
          title="Apakah Kamu Yakin?"
          onConfirm={handleSubmit1}
          onCancel={hideAlert}
          focusCancelBtn
          />
        );
        };
    
      const hideAlert = () => {
        setAlert(null);
        }
 
    const handleSubmit = (e) => {
      {
        
      EditData();
  
      }
    };

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
      }

      const HargaDiskonPersen = (e) => {
        const value = e === undefined ? 'undefined' : e;
        setDiskonGlobalPersen(value || ' ');
        setDiskonGLobal("diskonglobalpersen")
        };
    
        const HargaDiskonNominal = (e) => {
          const value = e === undefined ? 'undefined' : e;
          setDiskonGlobalNominal(value || ' ');
          setDiskonGLobal("diskonglobalnominal")
          };
        
          const HargaOngkir = (e) => {
            const value = e === undefined ? 'undefined' : e;
            setOngkir(value || ' ');
            };
        
  return (
    <>
    {alert}
    {permintaanBarangs && permintaanBarangs === "1" ? (
			<div>
    <SimpleHeader name="Validasi Manajer Toko" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
        <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                      <h3>Validasi Manajer Toko</h3>
                  </CardHeader>
                    <CardBody>
                    <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Tanggal
                              </Label>
                              <Col sm={6}>
                                <Input
                                  disabled
                                  className="form-control-alternative"
                                  type="text"
                                  placeholder="Tanggal"
                                  value={tanggal}
                                />
                              </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode RFQ
                              </Label>
                              <Col sm={6}>
                                <Input
                                  disabled
                                  className="form-control-alternative"
                                  type="text"
                                  placeholder="Kode RFQ"
                                  value={rfqcode}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Customer
                              </Label>
                              <Col sm={6}>
                                <Select2
                                disabled
                                      className="form-control-alternative"
                                      defaultValue="1"
                                      value={customer}
                                      options={{
                                        placeholder: "Pilih Customer"
                                      }}
                                      data={customers.map((customer) => {
                                        return (
                                        { id: customer.id, text: customer.name}
                                        );
                                      })}
                                    />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Alamat
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                  type="textarea"
                                  rows = "4"
                                  placeholder="Masukan Alamat"
                                  value={alamat}
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Tipe SO
                            </Label>
                            <Col sm={6}>
                            <Input
                            disabled
                            className="form-control-alternative"
                                name="Tipe Request"
                                type="select"
                                value={typereq}
                              >
                                <option value="" disabled hidden selected>Pilih Tipe SO</option>
                                <option value={1}>Ready</option>
                                <option value={2}>Indent</option>
                              </Input>
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                Diskon
                              </Label>
                              <Col sm={3}>
                                <CurrencyInput
                                  disabled
                                  id="validation-example-2-field"
                                  placeholder="0"
                                  allowDecimals={false}
                                  value={diskonglobalpersen}
                                  className={`form-control`}
                                  suffix={'%'}
                                  step={10}
                                />
                              </Col>
                              <Col sm={3}>
                              <CurrencyInput
                                  disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={diskonglobalnominal}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                PPN
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                  className="form-control-alternative"
                                    type="select"
                                    value={pajak}
                                   >
                                    <option value='' disabled hidden selected>Pilih PPN</option>
                                      {allpajak.map((warehouse2, key) => {
                                        return (
                                            <option key={key} value={warehouse2.id}>
                                              {warehouse2.keterangan}
                                            </option>
                                        );
                                      })}
                                </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Col
                              sm={3}
                            >
                              <Label
                              className="form-control-label">Validasi</Label>
                              
                            </Col>
                              <Col sm={6}>
                                <div style={{ display: "flex" }}>
                                  <div className="custom-control custom-radio mb-3">
                                    <Input
                                      className="custom-control-input"
                                      id="customRadio12"
                                      name="custom-radio-5"
                                      type="radio"
                                      value={5}
                                      checked={statusrfq === 5}
                                      onChange={() => setStatusRfq(5)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio12"
                                    >
                                    </Label>
                                    <b>SETUJU</b>

                                  </div>
                                  <div
                                    className="custom-control custom-radio mb-3"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <Input
                                      className="custom-control-input"
                                      id="customRadio13"
                                      name="custom-radio-5"
                                      type="radio"
                                      value={4}
                                      checked={statusrfq === 4}
                                      onChange={() => setStatusRfq(4)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio13"
                                    >
                                    </Label>
                                    <b>TOLAK</b>
                                  </div>
                                </div>
                              </Col>
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                  </CardHeader>
                  <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Harga</b></th>
                                <th><b>Qty</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Diskon %</b></th>
                                <th><b>Diskon (N)</b></th>
                                <th><b>Sub Total</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td><>{formatRupiah(savedItem.harga)}</></td>
                                      <td>{savedItem.qty}</td>
                                      <td>{savedItem.satuan}</td>
                                      <td><>{savedItem.diskon_persen}</></td>
                                      <td><>{formatRupiah(savedItem.diskon_nominal)}</></td>
                                      <td>
																{formatRupiah(((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) / (ppn/100 + 100/100))}

                                      {/* {formatRupiah((((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) - ((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) * (ppn/100)))} */}

                                        {/* {formatRupiah((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty)} */}
                                        </td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter >
                          <Row md="12">
                            <Col md="6">
                            <FormGroup row>
                              <Col sm={7}>
                                <Input
                                disabled
                                  type="textarea"
                                  name="keterangan"
                                  rows="10"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            </Col>
                            <Col md="2">
                            </Col>
                            <Col md="4">
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                                size="small"
                              >
                                <b>Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Harga Total"
                                // value={totalPrice1}
                                value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Diskon</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                style={{fontWeight: 'bold'}}
                                disabled
                                type="text"
                                name="barcode"
                                placeholder="Diskon"
                                value={"Rp." + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>PPN</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Ongkir</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Grand Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                type="text"
                                name="barcode"
                                style={{fontWeight: 'bold'}}
                                placeholder="Grand Total"
                                value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>

                            </Col>
                          </Row>
                        </CardFooter>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/penawaran-barang/validasi-manajer-toko">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={()=>setQuestionAlert()}>
                          Simpan
                      </Button>
                </CardFooter>
                {/* modal */}
                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader toggle={toggle} align="center"></ModalHeader>
                  <ModalBody align="center">
                  <font size="5"><b>Apakah Anda Sudah Yakin ?</b></font><br></br><br></br><br></br>
                  {!isLoading && (
                    <Button color="primary" onClick={() => handleSubmit()}>
                    Lanjutkan
                    </Button>
                  )}
                  {isLoading && (
                    <Button color="primary" disabled>
                    <i className="fas fa-spinner fa-spin"></i>
                    {""}
                    loading...
                    </Button>
                  )}
                  <Button color="secondary" onClick={toggle}>
                    Cancel
                  </Button>
                  </ModalBody>
                </Modal>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>  
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}
