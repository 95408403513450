import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Label
} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";
import moment from "moment";

const ModalCetakSJCCeklist = ({ open, toggle, data }) => {
    const token = localStorage.token;
    const [savedItems, setSavedItems] = useState([]);
    const [download, setDownload] = useState("")
    const [codepo, setCodePo] = useState("");
    const [keterangan, setKeterangan] = useState("");
    const [supplier, setSupplier] = useState("");
    const [warehouserfq, setWarehouseRfq] = useState("");
    const [waktu,setWaktu] = useState([]);
    const [address, setAddress] = useState("")


  const getById = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-cashier/cetak-checker/${data.id}`,
        { headers }
      )
      .then((data) => {  
        setAddress(data.data.response.sj.address)
        setSupplier(data.data.response.sj.person);
        setDownload(data.data.response.sj.counter);
        setWaktu(data.data.response.sj.created)
        setWarehouseRfq(data.data.response.sj.warehouse);
        setKeterangan(data.data.response.sj.keterangan);
        setCodePo(data.data.response.sj.sjc_code);
        setSupplier(data.data.response.sj.customer);
        setWaktu(data.data.response.sj.created)
        setWarehouseRfq(data.data.response.sj.warehouse);
        setKeterangan(data.data.response.sj.keterangan);

       
      })
      .catch(function (error) {
        console.log(error);
      });
  },[token,data.id]);

  const HitCounter = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-cashier/hit-counter-checker/${data.id}`,
        { headers }
      )
      .then((data) => {  
        console.log("suskes Cuk");
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const getItemDataSaved = useCallback(() => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/surat-jalan-cashier/cetak-checker/${data.id}`,{ headers }
    
    ).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.list.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                qty: data.qty,
                harga: data.harga,
                satuan: data.satuan,
                diskon_nominal: data.diskon_nominal,
                diskon_persen : data.diskon_persen,
                sub_total : data.sub_total,
                data: {
                    item_name: data.item_name,
                    harga: data.harga
                },
            }];
        }));

        setSavedItems(stateItem);
    })
},[token,data.id]);

useEffect(() => {
  getById();
  getItemDataSaved();
}, [getById,getItemDataSaved]);


  function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    // console.log(item)
    window.print();
    window.close();
    document.body.innerHTML = originalContents;
  }

  
  return (
    <div>
        <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top:"-15%" }}>
      <ModalHeader toggle={toggle} align="center">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'right' }}>
                    <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            &nbsp;
                        </Label><br></br>
                        <Button color="info" onClick={() => {print();HitCounter()}}>Print</Button>
                    </div>
                </div>
      </ModalHeader>
      <ModalBody>
      <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          {renderPDF()}
        </CardBody>
        {/* <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          <div className="col">
            <KopSurat 
            warehouseName={warehouserfq}
            // user={username}
             />
          </div>
          <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                        <Row md="12">
                            <Col md="12">
                                <h3><b><center>FORM CEKLIST BARANG SURAT JALAN</center></b></h3>
                            </Col>
                        </Row>
                        <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                        <br></br>
                        <Row md="12">
                            <Col md="5">
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Tanggal</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-4 ">
                                    <b>{waktu}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Kode SJ</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{codepo}</b>
                                </div>
                            </div>
                             <div className="row align-items-center mb-1">
                                <div className="col-3">Nopol</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>------------------------</b>
                                </div>
                            </div>
                            </Col>
                            <Col md="1">
                            </Col>
                            <Col md="6">
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Customer</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{supplier}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Alamat</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{address}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Phone</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{phone}</b>
                                </div>
                            </div>
                            </Col>
                        </Row>
                    <CardBody>
                      <br></br>
                      <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th>
                            <b>Nama Item</b>
                            </th>
                            <th>
                            <b>Kode Item</b>
                            </th>
                            <th>
                            <b>Qty</b>
                            </th>
                            <th>
                            <b>Satuan</b>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            savedItems.map((savedItem, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{savedItem.data.item_name}</td>
                                        <td>{savedItem.item_code}</td>
                                        <td>{savedItem.qty}</td>
                                        <td>{savedItem.satuan}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                      </Table>
                    </CardBody>
                    <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                        <center>Terms of Price, delivery & shipping required</center>
                        <br></br>
                                <Row md="20">
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Kepala Gudang</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Driver</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Helper</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                                <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                      {download === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Download</i> {download} Kali Oleh {username}</div>
                    </div>
                      )
                      
                      }
        </CardBody> */}
      </ModalBody>
    </Modal>
    </div>
    
  );

  function renderPDF(){
    // let sheet = 1;
    // let length = savedItems.length
    // if (item != length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []  
    // for (let index = 0; index < sheet; index++) {
    //   let start = 0
    //   let end = 0
    //   if(sheet == 1){
    //     end = length
    //   }else{
    //     if(sheet > 1 && index < (sheet - 1)){
    //       end = item
    //     }else{
    //       end = length % item
    //     }
    //   }

    // let sheet = 1;
    // let length = savedItems.length
    // if (item != length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []
    // for (let index = 0; index < sheet; index++) {
    //   let start = (item * index)
    //   let end = length
    //   let sisa = (length % item)
    //   if(sheet == 1){
    //     end = length
    //   }else{
    //     if(index < (sheet - 1) && sheet == 2){
    //       end = item
    //     }else{
    //       if (index == (sheet - 1)) {
    //         end = length - sisa + (index * item)
    //       }else{
    //         if (index == 0) {
    //           end = item
    //         }else{
    //           end = (index+1) * item
    //         }
    //       }
    //     }
    //   } 
      
    //   let data = savedItems.slice(start,end)

    const itemsPerPage = 10; // Maksimal 7 item per halaman
    const totalItems = savedItems.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage); // Hitung total halaman
  
    const sheets = [];
    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      // Hitung indeks awal dan akhir untuk slice item per halaman
      const start = pageIndex * itemsPerPage;
      const end = start + itemsPerPage;
  
      // Ambil item untuk halaman saat ini
      const currentItems = savedItems.slice(start, end);
      // console.log(data.length)
      let username = moment().format("YYYY-MM-DD hh:mm:ss") + " " + localStorage.username;
      sheets.push(
        <div className='pagebreak'>
          <div className="col">
            <KopSurat warehouseName={warehouserfq} />
          </div>
          <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
          <Row md="12">
              <Col md="12">
                  <h3 className=""><b><center>FORM CEKLIST BARANG SURAT JALAN KASIR</center></b></h3>
              </Col>
          </Row>
          <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
          
          <Row md="12">
            <Col style={{marginLeft:"2%",width:"70%",flexBasis: "unset"}}>
             
              <div className="row align-items-center ">
                  <div style={{width:"8%"}}  className="text-table">Tanggal</div>
                  <div style={{width:"2%"}}  className="text-center text-table">:</div>
                  <div style={{width:"85%"}}  className="text-table">
                      <b>{waktu}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"8%"}} className="text-table">No SJ</div>
                  <div style={{width:"2%"}} className="text-center text-table">:</div>
                  <div style={{width:"85%"}} className="text-table">
                      <b>{codepo}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"8%"}}  className="text-table">keterangan </div>
                  <div style={{width:"2%"}}  className="text-center text-table">:</div>
                  <div style={{width:"85%"}}  className="text-table">
                      <b>{keterangan}</b>
                  </div>
              </div>
            </Col>
            <Col style={{width:"25%",flexBasis: "unset"}}>
              <div className="row align-items-center ">
                  <div style={{width:"30%"}} className="text-table">Supplier</div>
                  <div style={{width:"5%"}} className="text-table">:</div>
                  <div style={{width:"65%"}} className="text-table">
                      <b>{supplier}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"30%"}} className="text-table">Alamat</div>
                  <div style={{width:"5%"}} className="text-table">:</div>
                  <div style={{width:"65%"}} className="text-table">
                      <b>{address}</b>
                  </div>
              </div>
            </Col>
          </Row>
              <Table style={{width:"100%"}} responsive>
                <thead>
                <tr>
                    <th className="text-table">
                    <b>No</b>
                    </th>
                    <th className="text-table">
                    <b>Kode Barang</b>
                    </th>
                    <th className="text-table">
                    <b>Nama Barang</b>
                    </th>
                    <th className="text-table">
                    <b>Qty</b>
                    </th>
                    <th className="text-table">
                    <b>Sat</b>
                    </th>
                    <th className="text-table">
                    <b>Keterangan</b>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    currentItems.map((temp,index, key) => {
                       
                        return (
                            <tr>
                                <td className="text-table">{index+1}</td>
                                <td className="text-table">{temp.item_code}</td>
                                <td className="text-table">{temp.item_name}</td>
                                <td className="text-table">{temp.qty}</td>
                                <td className="text-table">{temp.satuan}</td>
                                <td className="text-table"></td>
                            </tr>
                        );
                    })
                }
                </tbody>
              </Table>
              <div ></div>
              <Row md="12">
              </Row>
          <div className="w-100 text-table" style={{ border: "0.5px solid #b3b3b3" }}></div>
          <center className="text-table">Terms of Price, delivery & shipping required</center>
          <Row md="20">
            <Col style={{ marginLeft:"2%",width:"33%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">kepala Gudang</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>------------------</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col> 
            <Col style={{ width:"33%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Driver</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                                            <b>------------------</b>

                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col>
            <Col style={{ width:"33%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Helper</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                                            <b>------------------</b>

                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col>
          </Row>
          <div className="w-100" style={{ border: "0.5px solid black" }}></div>
                      {download === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Download</i> {download} Kali Oleh {username}</div>
                    </div>
                      )}
          <div className="divFooter">{username}</div>
        </div>
      )
    }
    return (
      <div>
        {sheets}
      </div>
    );
  }

};

export default ModalCetakSJCCeklist;
