/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CardBody,
  Row,
  Container,
  CardImg,
} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";
import HeaderTotal from "components/Headers/HeaderTotal";

export default function PermintaanBarangPoCabang() {
    const warehouse = localStorage.warehouse;
    const token = localStorage.token;
    const [totalItem, setTotalItem] = useState(0);
    const [proses, setProses] = useState(0);
    const [tolak, setTolak] = useState(0);
    const [setuju, setSetuju] = useState(0);
    const page = 1;
    const perPage = 10;
    const allInfo = JSON.parse(localStorage.allinfo);
    const receivingPOs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Receiving PO").map((p) => p.read_access));
    const receivingPO = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Receiving PO").map((p) => p.read_access));
	const validasiKepalaGudang = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi Receiving PO").map((p) => p.read_access));
	const cetak = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Cetak Receiving PO").map((p) => p.read_access));
   
    useEffect(() => {
        gettotal();
        getProses();
        getTolak();
        getSetuju()
      }, []);
    
      // fungsi dari ambil data
      const gettotal = () => {
        let filter = { 
          page: page, 
          per_page: perPage,
          warehouse_id: parseInt(warehouse),
        };
        
        const data = filter;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/receiving-po/page`, data, {
            headers,
          })
          .then((data) => {
            setTotalItem(data.data.total_item);
          })
          .catch(function (error) {
          });
    }

    const getProses = () => {
        let filter = { 
          page: page, 
          per_page: perPage,
          status_receive : 3 ,
          warehouse_id: parseInt(warehouse),
        };
        
        const data = filter;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/receiving-po/page`, data, {
            headers,
          })
          .then((data) => {
            setProses(data.data.total_item);
          })
          .catch(function (error) {
          });
    }

    const getTolak = () => {
        let filter = { 
          page: page, 
          per_page: perPage,
          status_receive : 4 ,
          warehouse_id: parseInt(warehouse),
        };
        
        const data = filter;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/receiving-po/page`, data, {
            headers,
          })
          .then((data) => {
            setTolak(data.data.total_item);
          })
          .catch(function (error) {
          });
    }

    const getSetuju = () => {
        let filter = { 
          page: page, 
          per_page: perPage,
          status_receive : 5 ,
          warehouse_id: parseInt(warehouse),
        };
        
        const data = filter;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/receiving-po/page`, data, {
            headers,
          })
          .then((data) => {
            setSetuju(data.data.total_item);
          })
          .catch(function (error) {
          });
    }

    return (
    <>
    {receivingPOs && receivingPOs === "1" ? (
      <div>
      <SimpleHeader name="BPB Pembelian" parentName="Report" />
      <HeaderTotal 
       totalItem = {totalItem}
       proses ={proses}
       tolak={tolak}
       setuju={setuju}

      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <CardBody>
                    <Row>
                        <div className="card-columns">
                        {receivingPO && receivingPO === "1" && (
                            <Card>
                                <Link to="/admin/receiving-po/page">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/bpb-pembelian-barang.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {validasiKepalaGudang && validasiKepalaGudang === "1" && (
                            <Card>
                                <Link to="/admin/receiving-po/validasi-kepala-gudang">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-kepala-gudang.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {cetak && cetak === "1" && (
                            <Card>
                                <Link to="/admin/receiving-po/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        </div>
					</Row>
                </CardBody>
          </div>
        </Row>
      </Container>
      </div>
    ):(
        <Halaman404 />
    )}
    </>
  );
}