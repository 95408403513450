/*eslint-disable*/
import React, {  useState} from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	CardHeader,
    CardFooter,
	Col,
	Button,
	Container,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ItemGambar1 from "components/SelectedFile/itemgambar1";
import SweetAlert from "react-bootstrap-sweetalert";

export default function EditGambarKaryawan(props) {
    const [alert, setAlert] = React.useState(null);
    const token = localStorage.token;
	let history = useHistory();
    const [image,setImage] = useState([]);

      function UpdateKaryawan() {
        const headers = {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        };
        let data = new FormData();
        data.append("profil",image)
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/karyawan/update-profil/${props.match.params.id}`, data, {
          headers
        })
          .then(function (response) {
            setSuccessAlert(response.data.message);
            setTimeout(() => (history.push("/admin/karyawan")), 1000);
            })
            .catch(function (error) {
              setGagalAlert(error.response.data.message);
            });
      }
    
      const setSuccessAlert = (id) => {
        setAlert(
          <SweetAlert
            success
            showConfirm
            confirmBtnText="Ok"
            title={id}
            onCancel={hideAlert}
            onConfirm={hideAlert}
          />
        )
      }
    
    const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
      danger
      showConfirm
      confirmBtnText="Ok"
      title={id}
      onCancel={hideAlert}
      onConfirm={hideAlert}
      />
    );
    };
    
    const setQuestionAlert = () => {
    setAlert(
      <SweetAlert
      warning
      showCancel
      confirmBtnText="Lanjutkan"
      confirmBtnBsStyle="danger"
      title="Apakah Kamu Yakin?"
      onConfirm={UpdateKaryawan}
      onCancel={hideAlert}
    //   timeout={1000}
      focusCancelBtn
      />
    );
    };
    
      const hideAlert = () => {
        setAlert(null);
      }

	return (
			<div>
                {alert}
				<SimpleHeader name="Upload Gambar" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
                            <Card className="bg-secondary shadow">
                                <CardHeader>
                                    <h3>Upload Gambar</h3>
                                </CardHeader>
                                <CardBody>
                                    <Row md="12">
                                        <Col md="6">
                                                <FormGroup row>
                                                    <Label className="form-control-label" for="exampleEmail" sm={4}>
                                                        <b>Foto</b><br></br>
                                                        Format gambar .jpg .jpeg .png dan ukuran minimum <b>300px X 300px.</b>
                                                    </Label>
                                                        <Col sm={2}>
                                                        <label htmlFor1="formId1" >
                                                            <input
                                                            hidden
                                                            id="formId1"
                                                            name="file"
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={(e)=>{
                                                                setImage(e.target.files[0])
                                                            }}
                                                            />
                                                            
                                                            <ItemGambar1
                                                                    onclick={() => { }}
                                                                    imageSrc1={image != "" && image != undefined ? URL.createObjectURL(image) : null}
                                                                />
                                                        </label>
                                                        </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    <Link className="btn btn-info" to="/admin/karyawan">
                                        Kembali
                                    </Link>
                                    <Button color="danger"  onClick={() => setQuestionAlert()}>
                                        Simpan
                                    </Button>
                                </CardFooter>
                            </Card>
						</div>
					</Row>
				</Container>
			</div>
	);
}
