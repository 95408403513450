/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Container,
    Form, 
    FormGroup, 
    Label, 
    Input ,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";

import * as FileSaver from "file-saver";

const PageLaporanPlanogram = () => {
  const token = localStorage.token;
  const username = localStorage.username
  const [alert, setAlert] = React.useState(null);
  const [allPenawaranSo, setAllPenawaranSo] = useState([]);
  const [cabang, setCabang] = useState("")
  const [cabangs, setCabangs] = useState([]);


  useEffect(() => {
    getCabang();
  }, []);

  const getCabang = () => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`, { headers
      })
      .then(data => {
        setCabangs(data.data.response);
      })
        .catch(function (error) {
          console.log(error)
        })
    }

//   page, perPage, sort,
  const updateDataTable = (cabang) => {
    getLaporanPembelian(cabang);
    setCabang(cabang);
  }

  useEffect(() => {
    getLaporanPembelian();
  }, []);

  // fungsi dari ambil data
  const getLaporanPembelian = async (page) => {
    
    let filter = { 
      username : username,
      warehouse_id : cabang
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/laporan/planogram`, data, {
        headers,
      })
      .then((data) => {
        setAllPenawaranSo(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const downloadExcel = async ()=> {
    var fileName = 'Laporan-planogram';
    var filter = { 
      warehouse_id : cabang,
      username:username,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/laporan/planogram`, filter, {
        headers,
      })
      .then((res) => {
        var apiPo = res.data.response.list;
        var tglcetak = res.data.response.download_date;
        var tableToExcel = (function() {
          var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
            format = function(s, c) {
              return s.replace(/{(\w+)}/g, function(m, p) {
                return c[p];
              })
            }
          return function(table, name) {
            var heading = 'Laporan Planogram';
            var imgsrc1 = 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhAVKx5R3RdjeXQuRdKan2RNLsZn2U4qXYOgU4jqILz6u6MLSzlvzY1b5x9Xiz4sKHhM0UJ1NKKoFVx6ZEI8JqgANlrZ8KwCJ2j9pOmJN-e50-HzVhTFRvEahjCJB51O4oMmJ25V2yQtYOGfxV2b7C2aT9VKBruh0_znTbORz66pu9P47DMB5aP4SuF/s320/Hokky1.png';
            var po = '';
            var a ;
            for(a=0; a < apiPo.length; a++){
              po += `
              <tr style="border: 1px solid black">
                  <td><center>${apiPo[a].id}</center></td>
                  <td><center>${apiPo[a].cabang}</center></td>
                  <td><center>${apiPo[a].rak_code}</center></td>
                  <td><center>${apiPo[a].area}</center></td>
                  <td><center>${apiPo[a].sub_area}</center></td>
                  <td><center>${apiPo[a].sisi}</center></td>
                  <td><center>${apiPo[a].hook}</center></td>
                  <td><center>${apiPo[a].position}</center></td>
                  <td><center>${apiPo[a].sale_state}</center></td>
                  <td><center>${apiPo[a].keterangan}</center></td>
              </tr>
              `
            }
            
            var table = `
            <table className="table table-striped" id="account_table">
                <tbody>
                <tr>
                    <td colspan="8"><center><b><h1>LAPORAN PLANOGRAM</h1></b><center></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Nama</td>
                    <td>${username}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Tanggal Cetak</td>
                    <td>${tglcetak}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                  <tr style="border: 1px solid black">
                      <th>No</th>
                      <th>Cabang</th>
                      <th>Kode</th>
                      <th>Area</th>
                      <th>Sub Area</th>
                      <th>Sisi</th>
                      <th>Hook</th>
                      <th>Lokasi</th> 
                      <th>Status</th>
                      <th>Keterangan</th>
                    </tr>
                        ${po}
                </tbody>
            </table>
            `;
            var ctx = {
              worksheet: name || 'WorkBook',
              imgsrc1: imgsrc1,
              heading: heading,
              table: table
            }
            var blob = new Blob([format(template, ctx)]);
            return blob;
          }
        })()
        var blobURL = tableToExcel('account_table', 'Data Planogram');
        FileSaver.saveAs(blobURL, fileName+'.xls');
       
      })
      .catch(function (error) {
        // if(error.response.data.status == 404){
            setGagalAlert(error.response.data.message);
            // }
      });
  }

  const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <div>
        {alert}
        <SimpleHeader name="Laporan Planogram" parentName="Report" />
        <Container className="mt--6" fluid>
            <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                    {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
                      {/* <div style={{ textAlign: 'left' }}>
                        <Link className="btn btn-link" to="/admin/laporan/master">
                          <i className="fa fa-arrow-circle-left fa-2x" /> 
                        </Link>
                      </div> */}
                      {/* <div style={{ textAlign: 'right' }}>
                                          <Input
                                              autoComplete="off"
                                              type="select"
                                              placeholder=""
                                              value={cabang}
                                              onChange={(e) => {
                                                setCabang(e.target.value);
                                              }}
                                          >
                                              <option value={""}>Masukan Cabang</option>
                                              {cabangs.map((warehouse2, key) => {
                                                  return (
                                                      <option key={key} value={warehouse2.id}>
                                                      {warehouse2.name}
                                                      </option>
                                                  );
                                                  })}
                                          </Input> 
                                      <UncontrolledDropdown nav>
                                          <DropdownToggle caret color="primary">
                                              Export
                                          </DropdownToggle>
                                          <DropdownMenu>
                                              <DropdownItem onClick={()=> {downloadExcel(allPenawaranSo)}}>Excel</DropdownItem>
                                          </DropdownMenu>
                                      </UncontrolledDropdown>
                      </div>
                      </div> */}
                      <Row md="12">
											<Col md="3 d-lg-block d-none">
                        <Link className="btn btn-link" to="/admin/laporan/master">
                          <i className="fa fa-arrow-circle-left fa-2x" /> 
                        </Link>
											</Col>
                      <Col md="2"></Col>
											<Col md="7 d-lg-block d-none">
                          <FormGroup row>
                            <Col sm={4}>
                              <Input
                                  autoComplete="off"
                                  type="select"
                                  placeholder=""
                                  value={cabang}
                                  onChange={(e) => {
                                    setCabang(e.target.value);
                                  }}
                              >
                                  <option value={""}>Masukan Cabang</option>
                                  {cabangs.map((warehouse2, key) => {
                                      return (
                                          <option key={key} value={warehouse2.id}>
                                          {warehouse2.name}
                                          </option>
                                      );
                                      })}
                              </Input> 
                            </Col>
                            <Col sm={2}>
                              <UncontrolledDropdown nav>
                                  <DropdownToggle caret color="primary">
                                      Export
                                  </DropdownToggle>
                                  <DropdownMenu>
                                      <DropdownItem onClick={()=> {downloadExcel(allPenawaranSo)}}>Excel</DropdownItem>
                                  </DropdownMenu>
                              </UncontrolledDropdown>
                            </Col>
                          </FormGroup>
											</Col>
										</Row>
                    {/* </div> */}
                </CardHeader>
                <CardBody>
                        <Form>
                        <Row md="12" hidden>
                                    <Col md="3">
                                      <FormGroup>
                                        <Label htmlFor="exampleFormControlSelect3">Cabang</Label>
                                        <Input
                                            autoComplete="off"
                                            type="select"
                                            placeholder=""
                                            value={cabang}
                                             onChange={(e) => {
                                              setCabang(e.target.value);
                                            }}
                                        >
                                            <option value={""}>Masukan Cabang</option>
                                            {cabangs.map((warehouse2, key) => {
                                                return (
                                                    <option key={key} value={warehouse2.id}>
                                                    {warehouse2.name}
                                                    </option>
                                                );
                                                })}
                                        </Input>
                                      </FormGroup>
                                    </Col>
                                    <Col md="" sm="3" hidden>
                                      <Label>&nbsp;</Label>
                                      <br></br>
                                      <Button hidden type='button' onClick={e => updateDataTable(1,cabang)} className="btn btn-info"><i className="fa fa-filter"></i></Button>
                                      <UncontrolledDropdown nav>
                                          <DropdownToggle caret color="primary">
                                              Export
                                          </DropdownToggle>
                                          <DropdownMenu>
                                              <DropdownItem onClick={()=> {downloadExcel(allPenawaranSo)}}>Excel</DropdownItem>
                                          </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </Col>
                                </Row>
                        </Form>
                </CardBody>
              </Card>
            </div>
            </Row>
        </Container>
    </div>
  );
}

export default PageLaporanPlanogram;