/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Container,
    ButtonGroup, 
    Form, 
    FormGroup, 
    Label, 
    Input ,
    ButtonDropdown,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import SimpleHeader from "components/Headers/SimpleHeader.js";
  import SweetAlert from "react-bootstrap-sweetalert";
  import paginationFactory from "react-bootstrap-table2-paginator";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import {PDFDownloadLink} from '@react-pdf/renderer';
// import PdfReportSo from './Pdf';

const PageLaporanFakturPenjualan = () => {
  const token = localStorage.token;
  const username = localStorage.username
  const warehouse = parseInt(localStorage.warehouse);
  const redirectPrefix = `/admin/sales-order/so-penawaran/edit/`;
  const redirectPrefix1 = `/admin/sales-order/so-penawaran/detail/`;
  const [alert, setAlert] = React.useState(null);
  const [rowIndex, setRowIndex] = useState(0);
  const [allPenawaranSo, setAllPenawaranSo] = useState([]);
  const [status, setStatus] = useState(0);
  const [description, setDescription] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [warehouseid, setWarehouseId] = useState("");
  const [warehouseids, setWarehouseIds] = useState([]);
//   const [coderfq, setCodeRfq] = useState("");
  const [customerid, setCustomerId] = useState("");
  const [customerids, setCustomerIds] = useState("");
  const [statusph, setStatusPh] = useState("");
  const [cabang, setCabang] = useState("")
  const [cabangs, setCabangs] = useState([]);
  const [supplier, setSupplier] = useState("")
  const [suppliers, setSuppliers] = useState([]);
  const [codepo, setCodePo] = useState("")
  const [codeRfq, setCodeRfq] = useState("")
  const [statusap, setStatusAp] = useState("")
  const [statusd, setStatusD] = useState("")
  const [approve, setApprove] = useState("")
  const [keterangan, setKeterangan] = useState("")
  const [active, setActive] = useState("")
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [socode, setSoCode] = useState("");
  const [awal, setAwal] = useState("");
  const [end, setEnd] = useState("");
  const [tglctk, setTglCtk] = useState(new Date().toISOString().split("T")[0])
  const [dropdownOpen, setDropdownOpen] = useState(false);


  useEffect(() => {
    getCabang();
    getPerson()
  }, []);

  const getCabang = () => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`, { headers
      })
      .then(data => {
        setCabangs(data.data.response);
        // setCabang(id);
      })
        .catch(function (error) {
          console.log(error)
        })
    }

    const getPerson = () => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/person/list`, { headers
        })
        .then(data => {
          setSuppliers(data.data.response);
          // setCabang(id);
        })
          .catch(function (error) {
            console.log(error)
          })
      }

  
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, startdate, enddate, cabang);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

//   page, perPage, sort,
  const updateDataTable = (page, startdate, enddate, cabang) => {
    getLaporanPembelian(page, startdate, enddate, cabang);
    setPage(page);
    // setPerpage(perPage);
    // setRowIndex((page - 1) * perPage);
    // setCurrentSort(sort);
    // setSoCode(socode);
    // setCodeRfq(coderfq);
    // setStatusPh(statusph);
    setStartDate(startdate);
    setEndDate(enddate);
    setCabang(cabang);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable( startdate, enddate, cabang)
    }
  }

  
  useEffect(() => {
    // getLaporanPembelian();
  }, []);

  // fungsi dari ambil data
  const getLaporanPembelian = async (page, start_date= "", end_date = "", warehouse_id="") => {
    
    let filter = { 
      // page: page, 
      // per_page: 100,
      username : username
    }
    if (start_date !== "") {
      filter = Object.assign(filter, { start_date: start_date })
    }
    if (end_date !== "") {
        filter = Object.assign(filter, { end_date: end_date });
    }
    if (warehouse_id !== "") {
      filter = Object.assign(filter, { warehouse_id: warehouse_id });
  }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/laporan/faktur-jual`, data, {
        headers,
      })
      .then((data) => {
        setAllPenawaranSo(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const reset = () => {
  //   setStatus("");
  //   setDescription("");
  //   updateDataTable(1, perPage, currentSort, "", "");
  // }

  const downloadExcel1 = async ()=> {
    var fileName = 'Laporan-PO'
    // get data all by filter
    var filter = { 
      // page: page, 
      // per_page: 1000,
      warehouse_id : parseInt(warehouse)
    };
    if (startdate !== '') {
      filter = Object.assign(filter, { start_date: startdate })
    }
    if (enddate !== '') {
      filter = Object.assign(filter, { end_date: enddate })
    }
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/page`, filter, {
        headers,
      })
      .then((res) => {
        var apiData = res.data.response.map((i)=>{
          return {
            'Tanggal' : i.created_at,
            'Kode PO' : i.po_code,
            'Supplier' : i.person_name,
            'Total Barang' : i.qty_total,
            'Harga Total' : i.price_total,
            'Kode Partial' : i.partial_code,
            'Keterangan' : i.keterangan,
          }
        });
        const ws = XLSX.utils.json_to_sheet(apiData);
        const fileType ="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const downloadExcel = async ()=> {
    var fileName = 'Laporan-Faktur-Penjualan';
    const fs = require('fs');
    // get data all by filter
    var filter = { 
      // page: page, 
      // per_page: 1000,
      warehouse_id : cabang,
      start_date : startdate,
      end_date: enddate,
      username:username,
    };
    // if (startdate !== '') {
    //   filter = Object.assign(filter, { start_date: startdate })
    // }
    // if (enddate !== '') {
    //   filter = Object.assign(filter, { end_date: enddate })
    // }
    const data = filter 
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/laporan/faktur-jual`, data, {
        headers,
      })
      .then((res) => {
        // setGagalAlert(res.data.message);
        // console.log(res.data.status);
        var apiPo = res.data.response.list;
        var tglcetak = res.data.response.download_date;
        // setTglCtk(res.data.response.download_date)
        // var apiRec = res.data.response[0].listReceiving;
        // var apiInv = res.data.response[0].listInvoice;
        // var apiBkk = res.data.response[0].listBkk;
        var tableToExcel = (function() {
          var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
            base64 = function(s) {
              return window.btoa(unescape(encodeURIComponent(s)))
            },
            format = function(s, c) {
              return s.replace(/{(\w+)}/g, function(m, p) {
                return c[p];
              })
            }
          return function(table, name) {
            var heading = 'Laporan Faktur Penjualan' ;
            var imgsrc1 = 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhAVKx5R3RdjeXQuRdKan2RNLsZn2U4qXYOgU4jqILz6u6MLSzlvzY1b5x9Xiz4sKHhM0UJ1NKKoFVx6ZEI8JqgANlrZ8KwCJ2j9pOmJN-e50-HzVhTFRvEahjCJB51O4oMmJ25V2yQtYOGfxV2b7C2aT9VKBruh0_znTbORz66pu9P47DMB5aP4SuF/s320/Hokky1.png';
            var po = '';
            var a ;
            for(a=0; a < apiPo.length; a++){
              po += `
              <tr style="border: 1px solid black">
                  <td><center>${apiPo[a].created_at}</center></td>
                  <td><center>${apiPo[a].time_limit}</center></td>
                  <td><center>${apiPo[a].code_so}</center></td>
                  <td><center>${apiPo[a].invoice_code}</center></td>
                  <td><center>${apiPo[a].person_name}</center></td>
                  <td><center>${apiPo[a].item_code}</center></td>
                  <td><center>${apiPo[a].item_name}</center></td>
                  <td><center>${apiPo[a].harga}</center></td>
                  <td><center>${apiPo[a].qty}</center></td>
                  <td><center>${apiPo[a].satuan}</center></td>
                  <td><center>${apiPo[a].diskon_persen}</center></td>
                  <td><center>${apiPo[a].diskon_nominal}</center></td>
                  <td><center>${apiPo[a].price_total}</center></td>
                  <td><center>${apiPo[a].bank_name} ${apiPo[a].account_number} ${apiPo[a].account_name}</center></td>
                  <td><center>${apiPo[a].keterangan}</center></td>
                  <td><center>${apiPo[a].payment_method}</center></td>
              </tr>
              `
            }
            
            var table = `
            <table className="table table-striped" id="account_table">
                <tbody>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td colspan="8"><center><b><h1>LAPORAN FAKTUR PENJUALAN</h1></b><center></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Start Date</td>
                    <td>${startdate}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Nama : </td>
                    <td>${username}</td>
                </tr>
                <tr>
                    <td>End Date</td>
                    <td>${enddate}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Tanggal Cetak</td>
                    <td>${tglcetak}</td>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                  <tr style="border: 1px solid black">
                      <th>Tanggal</th>
                      <th>Expired</th>
                      <th>Kode PO</th>
                      <th>Kode Invoice</th>
                      <th>Supplier</th>
                      <th>Kode Item</th>
                      <th>Nama Item</th>
                      <th>Harga</th> 
                      <th>Qty</th> 
                      <th>Satuan</th>
                      <th>Diskon %</th> 
                      <th>Diskon N</th> 
                      <th>Total</th> 
                      <th>Bank Account</th>
                      <th>Keterangan</th>
                      <th>Status</th>
                    </tr>
                        ${po}
                </tbody>
            </table>
            `;
            var ctx = {
              worksheet: name || 'WorkBook',
              imgsrc1: imgsrc1,
              heading: heading,
              table: table
            }
            var blob = new Blob([format(template, ctx)]);
            return blob;
          }
        })()
        var blobURL = tableToExcel('account_table', 'Data Faktur Penjualan');
        FileSaver.saveAs(blobURL, fileName+'.xls');
    
      })
      .catch(function (error) {
        // if(error.response.data.status == 404){
            setGagalAlert(error.response.data.message);
            // }
      });
  }

  const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <div>
        {alert}
        <SimpleHeader name="Laporan Faktur Penjualan" parentName="Report" />
        <Container className="mt--6" fluid>
            <Row>
            <div className="col">
            <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                      <Link className="btn btn-link" to="/admin/laporan/penjualan">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                      </Link>
                    </div>
                    {/* <div style={{ textAlign: 'right' }}>
                        <Link className="btn btn-info" to="/admin/sales-order/so-penawaran/create">
                        Tambah
                        </Link>
                    </div> */}
                    </div>
                </CardHeader>
                <CardBody>
                        <Form>
                        <Row md="12">
                                    <Col sm="3">
                                        <FormGroup>
                                        <Label htmlFor="exampleFormControlSelect3">Start Date</Label>
                                        <Input
                                            autoComplete="off"
                                            className="form-control-alternative"
                                            type="date"
                                            name="itemCode"
                                            placeholder="Tanggal Awal"
                                            value={startdate}
                                            // onChange={e => updateDataTable(1, perPage, currentSort,e.target.value, enddate)}
                                            onChange={(e) => {
                                              setStartDate(e.target.value);
                                            }}
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                    <FormGroup>
                                        <Label htmlFor="exampleFormControlSelect3">End Date</Label>
                                        <Input
                                            autoComplete="off"
                                            className="form-control-alternative"
                                            type="date"
                                            name="itemCode"
                                            placeholder="Tanggal AKhir"
                                            value={enddate}
                                            // onChange={e => updateDataTable( 1, perPage, currentSort,startdate, e.target.value )}
                                            onChange={(e) => {
                                              setEndDate(e.target.value);
                                            }}
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                        <Label htmlFor="exampleFormControlSelect3">Cabang</Label>
                                        <Input
                                            autoComplete="off"
                                            type="select"
                                            placeholder=""
                                            value={cabang}
                                            // onChange={e => updateDataTable(1, perPage, tanggal, e.target.value, name)}
                                            onChange={(e) => {
                                              setCabang(e.target.value);
                                            }}
                                        >
                                            <option value={""}>Masukan Cabang</option>
                                            {cabangs.map((warehouse2, key) => {
                                                return (
                                                    <option key={key} value={warehouse2.id}>
                                                    {warehouse2.name}
                                                    </option>
                                                );
                                                })}
                                        </Input>
                                        </FormGroup>
                                    </Col>
                                   
                                    <Col md="" sm="3">
                                    <Label>&nbsp;</Label>
                                    <br></br>
                                    <Button hidden type='button' onClick={e => updateDataTable(1, startdate, enddate, cabang)} className="btn btn-info"><i className="fa fa-filter"></i></Button>
                                    {/* <ButtonDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(true)}> */}
                                    <UncontrolledDropdown nav>
                                        <DropdownToggle caret color="primary">
                                            Export
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={()=> {downloadExcel(allPenawaranSo)}}>Excel</DropdownItem>
                                            {/* <DropdownItem>PDF</DropdownItem> */}
                                            {/* <DropdownItem>
                                        <PDFDownloadLink
                                            document={
                                            <PdfReportSo data={{startdate, enddate}}/>}
                                            fileName="Report PO.pdf"
                                            style={{color: '#000'}}>
                                                PDF
                                        </PDFDownloadLink>
                                        </DropdownItem> */}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    {/* </ButtonDropdown> */}
                                    </Col>
                                </Row>
                                {/* <Row md="12">
                                <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="exampleFormControlSelect3">Status PO</Label>
                                        <Input
                                            autoComplete="off"
                                            type="select"
                                            placeholder="Masukan Kode PO"
                                            value={active}
                                            onChange={e => updateDataTable(1, perPage, tanggal, e.target.value, name)}
                                        >
                                            <option value={0}>Pilih Status</option>
                                            <option value={1}>Aktif</option>
                                            <option value={2}>Tidak Aktif</option>
                                        </Input>
                                    </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                        <Label htmlFor="exampleFormControlSelect3">Kode PO</Label>
                                        <Input
                                            autoComplete="off"
                                            type="text"
                                            placeholder="Masukan Kode PO"
                                            value={codepo}
                                            onChange={e => updateDataTable(1, perPage, tanggal, e.target.value, name)}
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                        <Label htmlFor="exampleFormControlSelect3">Kode RFQ</Label>
                                        <Input
                                            autoComplete="off"
                                            type="text"
                                            placeholder="Masukan Kode RFQ"
                                            value={codeRfq}
                                            onChange={e => updateDataTable(1, perPage, tanggal, e.target.value, name)}
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3">
                                        <FormGroup>
                                        <Label htmlFor="exampleFormControlSelect3">Keterangan</Label>
                                        <Input
                                            autoComplete="off"
                                            type="text"
                                            placeholder="Masukan Keterangan"
                                            value={keterangan}
                                            onChange={e => updateDataTable(1, perPage, tanggal, e.target.value, name)}
                                        />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row md="12">
                                    <Col md="3">
                                        <FormGroup>
                                        <Label htmlFor="exampleFormControlSelect3">Supplier</Label>
                                            <Input
                                            autoComplete="off"
                                            type="select"
                                            placeholder="Masukan Cabang"
                                            value={supplier}
                                            onChange={e => updateDataTable(1, perPage, tanggal, e.target.value, name)}
                                            >
                                            <option value={0}>masukan Supplier</option>
                                            {suppliers.map((supplier, key) => {
                                                return (
                                                    <option key={key} value={supplier.id}>
                                                    {supplier.person_name}
                                                    </option>
                                                );
                                                })}
                                        </Input>
                                    </FormGroup>
                                    </Col>
                                    <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="exampleFormControlSelect3">Status Approve</Label>
                                        <Input
                                            autoComplete="off"
                                            type="select"
                                            placeholder="Masukan Kode PO"
                                            value={approve}
                                            onChange={e => updateDataTable(1, perPage, tanggal, e.target.value, name)}
                                        >
                                            <option value={0}>Pilih Status</option>
                                            <option value={3}>Proses</option>
                                            <option value={4}>Tolak</option>
                                            <option value={5}>Setuju</option>
                                        </Input>
                                    </FormGroup>
                                    </Col>
                                    <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="exampleFormControlSelect3">Status Kepala Purchasing</Label>
                                        <Input
                                            autoComplete="off"
                                            type="select"
                                            placeholder="Masukan Kode PO"
                                            value={statusap}
                                            onChange={e => updateDataTable(1, perPage, tanggal, e.target.value, name)}
                                        >
                                            <option value={0}>Pilih Status</option>
                                            <option value={3}>Proses</option>
                                            <option value={4}>Tolak</option>
                                            <option value={5}>Setuju</option>
                                        </Input>
                                    </FormGroup>
                                    </Col>
                                    <Col md="3">
                                    <FormGroup>
                                        <Label htmlFor="exampleFormControlSelect3">Status Direktur</Label>
                                        <Input
                                            autoComplete="off"
                                            type="select"
                                            placeholder="Masukan Kode PO"
                                            value={statusd}
                                            onChange={e => updateDataTable(1, perPage, tanggal, e.target.value, name)}
                                        >
                                            <option value={0}>Pilih Status</option>
                                            <option value={3}>Proses</option>
                                            <option value={4}>Tolak</option>
                                            <option value={5}>Setuju</option>
                                        </Input>
                                    </FormGroup>
                                    </Col>
                                </Row> */}
                                
                        </Form>
                        {/* <ToolkitProvider
                            rowNumber={rowIndex}
                            data={allPenawaranSo}
                            keyField="id"
                            columns={[
                                {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                    dataField: "created_at",
                                    text: "Tanggal Buat",
                                    sort: true,
                                  },
                                  {
                                      dataField: "po_code",
                                      text: "Kode PO",
                                      sort: true,
                                  },
                                  {
                                      dataField: "person_name",
                                      text: "Supplier",
                                      sort: true,
                                  },
                                  {
                                      dataField: "qty_total",
                                      text: "QTY",
                                      sort: true,
                                  },
                                  {
                                      dataField: "keterangan",
                                      text: "Keterangan",
                                      sort: true,
                                  },
                                  {
                                      dataField: "status_ap",
                                      text: "Status",
                                      sort: true,
                                      formatter: (cell, row) => {
                                        return row.status_ap === 3
                                          ? 'proses'
                                          : row.status_ap === 4
                                          ? 'Tidak Setuju'
                                          : 'Setuju';
                                      },
                                  },
                                // {
                                //     dataField: "", text: "", formatter: (cell, row, index) => {
                                //     return (
                                //         <ButtonGroup>
                                //         <Button>
                                //             <Link
                                //             to={redirectPrefix + row.id}
                                //             id={"tooltip_" + row.id}
                                //             >
                                //             <i className="fas fa-user-edit" /> Edit
                                //             </Link>
                                //         </Button>
                                //         &nbsp;
                                //         <Button>
                                //             <Link
                                //             to={redirectPrefix1 + row.id}
                                //             id={"tooltip1_" + row.id}
                                //             >
                                //             <i className="fas fa-user-edit" /> Detail
                                //             </Link>
                                //         </Button>
                                //         </ButtonGroup>
                                //     )
                                //     }
                                // },
                              ]}
                            >
                                {(props) => (
                                <div className="py-4 table-responsive">
                                    <BootstrapTable
                                    remote
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={false}
                                    hover={true}
                                    // pagination={paginationFactory({ ...paginationOption })}
                                    // onTableChange={handleTableChange}
                                    />
                                </div>
                            )}
                        </ToolkitProvider> */}
                </CardBody>
                </Card>
            </div>
            </Row>
        </Container>
    </div>
  );
}

export default PageLaporanFakturPenjualan;