/*eslint-disable*/
import React, { useState } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	CardHeader,
	CardFooter,
	Col,
	Button,
	Container,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import ItemGambar1 from "components/SelectedFile/itemgambar1";
import ItemGambar2 from "components/SelectedFile/itemgambar2";
import ItemGambar3 from "components/SelectedFile/itemgambar3";
import Halaman404 from "views/404";

export default function EditGambarItem(props) {
	const token = localStorage.token;
	let history = useHistory();
	const [image1, setImage1] = useState("");
	const [image2, setImage2] = useState("");
	const [image3, setImage3] = useState("");
	const [alert, setAlert] = React.useState(null);
	const allInfo = JSON.parse(localStorage.allinfo);
	const itempusat = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Item Pusat").map((p) => p.read_access));
	

	const EditData = () => {
		const headers = {
			"Content-Type": "multipart/form-data",
			Authorization: `Bearer ${token}`,
		};
		let data = new FormData();
		data.append("foto_1", image1);
		data.append("foto_2", image2);
		data.append("foto_3", image3);
		axios
			.post(`${process.env.REACT_APP_API_BASE_URL}/items/update-foto/${props.match.params.id}`, data, { headers })
			.then(function (response) {
				setSuccessAlert(response.data.message);
				setTimeout(() => (history.push("/admin/item")), 2000);
				})
				.catch(function (error) {
				  setGagalAlert(error.response.data.message);
				});
		  }
		
		  const setSuccessAlert = (id) => {
			setAlert(
			  <SweetAlert
				success
				showConfirm
				confirmBtnText="Ok"
				title={id}
				onCancel={hideAlert}
				onConfirm={hideAlert}
			  />
			)
		  }
		
		const setGagalAlert = (id) => {
		setAlert(
		  <SweetAlert
		  danger
		  showConfirm
		  confirmBtnText="Ok"
		  title={id}
		  onCancel={hideAlert}
		  onConfirm={hideAlert}
		  />
		);
		};
		
		const setQuestionAlert = () => {
		setAlert(
		  <SweetAlert
		  warning
		  showCancel
		  confirmBtnText="Lanjutkan"
		  confirmBtnBsStyle="danger"
		  title="Apakah Kamu Yakin?"
		  onConfirm={EditData}
		  onCancel={hideAlert}
		  focusCancelBtn
		  />
		);
		};
		
		  const hideAlert = () => {
			setAlert(null);
		  }


	return (
		<>
		{alert}
		{itempusat && itempusat === "1" ? (
			<div>
				<SimpleHeader name="Upload Gambar" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
								<Card className="bg-secondary shadow">
									<CardHeader>
                                        <h3>Upload Gambar</h3>
									</CardHeader>
									<CardBody>
                                        <Row md="12">
                                            <Col md="10">
                                                    <FormGroup row>
                                                        <Label
														className="form-control-label"
														for="exampleEmail" sm={3}>
                                                            <b>Foto Produk</b><br></br>
                                                            Format gambar .jpg .jpeg .png dan ukuran minimum <b>300px X 300px</b>.
                                                        </Label>
                                                            <Col sm={2}>
                                                            <label htmlFor1="formId1" >
                                                                <input
                                                                hidden
                                                                id="formId1"
                                                                name="file"
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(event) => {
                                                                    setImage1(event.target.files[0]);
                                                                }}
                                                                />
                                                                <ItemGambar1
                                                                onclick={() => { }}
                                                                imageSrc1={image1 != "" && image1 != undefined ? URL.createObjectURL(image1) : null}
                                                                />
                                                            </label>
                                                            </Col>
                                                            <Col sm={2}>
                                                            <label htmlFor1="formId1" >
                                                                <input
                                                                hidden
                                                                id="formId1"
                                                                name="file"
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(event) => {
                                                                    setImage2(event.target.files[0]);
                                                                }}
                                                                />
                                                                <ItemGambar2
                                                                onclick={() => { }}
                                                                imageSrc2={image2 != "" && image2 != undefined ? URL.createObjectURL(image2) : null}
                                                                />
                                                            </label>
                                                            </Col>
                                                            <Col sm={2}>
                                                            <label htmlFor1="formId1" >
                                                                <input
                                                                hidden
                                                                id="formId1"
                                                                name="file"
                                                                type="file"
                                                                accept="image/*"
                                                                onChange={(event) => {
                                                                    setImage3(event.target.files[0]);
                                                                }}
                                                                />
                                                                <ItemGambar3
                                                                onclick={() => { }}
                                                                imageSrc3={image3 != "" && image3 != undefined ? URL.createObjectURL(image3) : null}
                                                                />
                                                            </label>
                                                            </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
									</CardBody>
                                    <CardFooter>
										<Link className="btn btn-info" to="/admin/item">
											Kembali
										</Link>
										<Button color="danger" onClick={setQuestionAlert}>
											Simpan
										</Button>
									</CardFooter>
								</Card>
						</div>
					</Row>
				</Container>
			</div>
		):(
			<Halaman404 />
		)}
		</>
	);
}
