/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Container,
    Form, 
    FormGroup, 
    Label, 
    Input ,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";


const PageLaporanCabang = () => {
  const token = localStorage.token;
  const username = localStorage.username
  const warehouse = parseInt(localStorage.warehouse);
  const [alert, setAlert] = React.useState(null);
  const [allPenawaranSo, setAllPenawaranSo] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [warehouseid, setWarehouseId] = useState("");
  const [warehouseids, setWarehouseIds] = useState([]);
//   const [coderfq, setCodeRfq] = useState("");
  const [customerid, setCustomerId] = useState("");
  const [customerids, setCustomerIds] = useState("");
  const [statusph, setStatusPh] = useState("");
  const [cabang, setCabang] = useState("")
  const [cabangs, setCabangs] = useState([]);
  const [supplier, setSupplier] = useState("")
  const [suppliers, setSuppliers] = useState([]);
  const [codepo, setCodePo] = useState("")
  const [codeRfq, setCodeRfq] = useState("")
  const [statusap, setStatusAp] = useState("")
  const [statusd, setStatusD] = useState("")
  const [approve, setApprove] = useState("")
  const [keterangan, setKeterangan] = useState("")
  const [active, setActive] = useState("")
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");
  const [socode, setSoCode] = useState("");
  const [awal, setAwal] = useState("");
  const [end, setEnd] = useState("");
  const [tglctk, setTglCtk] = useState(new Date().toISOString().split("T")[0])
  const [dropdownOpen, setDropdownOpen] = useState(false);


  useEffect(() => {
    getCabang();
    getPerson()
  }, []);

  const getCabang = () => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`, { headers
      })
      .then(data => {
        setCabangs(data.data.response);
        // setCabang(id);
      })
        .catch(function (error) {
          console.log(error)
        })
    }

    const getPerson = () => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/person/list`, { headers
        })
        .then(data => {
          setSuppliers(data.data.response);
          // setCabang(id);
        })
          .catch(function (error) {
            console.log(error)
          })
      }

  
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, startdate, enddate, cabang);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

//   page, perPage, sort,
  const updateDataTable = (page, startdate, enddate, cabang) => {
    getLaporanPembelian(page, startdate, enddate, cabang);
    setPage(page);
    setStartDate(startdate);
    setEndDate(enddate);
    setCabang(cabang);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable( startdate, enddate, cabang)
    }
  }

  
  useEffect(() => {
    // getLaporanPembelian();
  }, []);

  // fungsi dari ambil data
  const getLaporanPembelian = async (page, start_date= "", end_date = "", warehouse_id="") => {
    
    let filter = { 
      page: page, 
      per_page: 100,
      username : username
    }
    if (start_date !== "") {
      filter = Object.assign(filter, { start_date: start_date })
    }
    if (end_date !== "") {
        filter = Object.assign(filter, { end_date: end_date });
    }
    if (warehouse_id !== "") {
      filter = Object.assign(filter, { warehouse_id: warehouse_id });
  }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/laporan/cabang`, data, {
        headers,
      })
      .then((data) => {
        setAllPenawaranSo(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const downloadExcel = async ()=> {
    var fileName = 'Laporan-cabang';
    const fs = require('fs');
    var filter = { 
     warehouse_id : cabang,
      start_date : startdate,
      end_date: enddate,
      username:username,
    };
   const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/laporan/cabang`, filter, {
        headers,
      })
      .then((res) => {
        var apiPo = res.data.response.list;
        var tglcetak = res.data.response.download_date;
        setTglCtk(res.data.response.download_date)
        var tableToExcel = (function() {
          var uri = 'data:application/vnd.ms-excel;base64,',
            template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
            base64 = function(s) {
              return window.btoa(unescape(encodeURIComponent(s)))
            },
            format = function(s, c) {
              return s.replace(/{(\w+)}/g, function(m, p) {
                return c[p];
              })
            }
          return function(table, name) {
            var heading = 'Laporan Cabang';
            var imgsrc1 = 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhAVKx5R3RdjeXQuRdKan2RNLsZn2U4qXYOgU4jqILz6u6MLSzlvzY1b5x9Xiz4sKHhM0UJ1NKKoFVx6ZEI8JqgANlrZ8KwCJ2j9pOmJN-e50-HzVhTFRvEahjCJB51O4oMmJ25V2yQtYOGfxV2b7C2aT9VKBruh0_znTbORz66pu9P47DMB5aP4SuF/s320/Hokky1.png';
            var po = '';
            var a ;
            for(a=0; a < apiPo.length; a++){
              po += `
              <tr style="border: 1px solid black">
                  <td><center>${apiPo[a].code}</center></td>
                  <td><center>${apiPo[a].level}</center></td>
                  <td><center>${apiPo[a].name}</center></td>
                  <td><center>${apiPo[a].province}</center></td>
                  <td><center>${apiPo[a].city}</center></td>
                  <td><center>${apiPo[a].address}</center></td>
                  <td><center>${apiPo[a].phone}</center></td>
                  <td><center>${apiPo[a].pusat_name}</center></td>
                  <td><center>${apiPo[a].region_name}</center></td>
                  <td><center>${apiPo[a].toko_name}</center></td>
              </tr>
              `
            }
            
            var table = `
            <table className="table table-striped" id="account_table">
                <tbody>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td colspan="8"><center><b><h1>LAPORAN CABANG</h1></b><center></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Start Date</td>
                    <td>${startdate}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Nama : </td>
                    <td>${username}</td>
                </tr>
                <tr>
                    <td>End Date</td>
                    <td>${enddate}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Tanggal Cetak</td>
                    <td>${tglcetak}</td>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                  <tr style="border: 1px solid black">
                      <th>Kode</th>
                      <th>Level</th>
                      <th>Cabang</th>
                      <th>Provinsi</th>
                      <th>Kota</th>
                      <th>Alamat</th>
                      <th>Phone</th>
                      <th>Pusat</th>
                      <th>Region</th> 
                      <th>Toko</th>
                    </tr>
                        ${po}
                </tbody>
            </table>
            `;
            var ctx = {
              worksheet: name || 'WorkBook',
              imgsrc1: imgsrc1,
              heading: heading,
              table: table
            }
            var blob = new Blob([format(template, ctx)]);
            return blob;
          }
        })()
        var blobURL = tableToExcel('account_table', 'Data Cabang');
        FileSaver.saveAs(blobURL, fileName+'.xls');
       
      })
      .catch(function (error) {
        // if(error.response.data.status == 404){
          setGagalAlert(error.response.data.message);
          // }
      });
  }

  const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <div>
      {alert}
        <SimpleHeader name="Laporan Cabang" parentName="Report" />
        <Container className="mt--6" fluid>
            <Row>
            <div className="col">
            <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                      <Link className="btn btn-link" to="/admin/laporan/master">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                      </Link>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                    <UncontrolledDropdown nav>
                                        <DropdownToggle caret color="primary">
                                            Export
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={()=> {downloadExcel(allPenawaranSo)}}>Excel</DropdownItem>
                                         </DropdownMenu>
                                    </UncontrolledDropdown>
                    </div>
                    </div>
                </CardHeader>
                <CardBody>
                        <Form>
                        <Row md="12">
                                    <Col sm="3" hidden>
                                        <FormGroup>
                                        <Label htmlFor="exampleFormControlSelect3">Start Date</Label>
                                        <Input
                                            autoComplete="off"
                                            className="form-control-alternative"
                                            type="date"
                                            name="itemCode"
                                            placeholder="Tanggal Awal"
                                            value={startdate}
                                           onChange={(e) => {
                                              setStartDate(e.target.value);
                                            }}
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3" hidden>
                                    <FormGroup>
                                        <Label htmlFor="exampleFormControlSelect3">End Date</Label>
                                        <Input
                                            autoComplete="off"
                                            className="form-control-alternative"
                                            type="date"
                                            name="itemCode"
                                            placeholder="Tanggal AKhir"
                                            value={enddate}
                                            onChange={(e) => {
                                              setEndDate(e.target.value);
                                            }}
                                        />
                                        </FormGroup>
                                    </Col>
                                    <Col md="3" hidden>
                                        <FormGroup>
                                        <Label htmlFor="exampleFormControlSelect3">Cabang</Label>
                                        <Input
                                            autoComplete="off"
                                            type="select"
                                            placeholder=""
                                            value={cabang}
                                            onChange={(e) => {
                                              setCabang(e.target.value);
                                            }}
                                        >
                                            <option value={""}>Masukan Cabang</option>
                                            {cabangs.map((warehouse2, key) => {
                                                return (
                                                    <option key={key} value={warehouse2.id}>
                                                    {warehouse2.name}
                                                    </option>
                                                );
                                                })}
                                        </Input>
                                        </FormGroup>
                                    </Col>
                                   
                                    <Col md="" sm="3" hidden>
                                    <Label>&nbsp;</Label>
                                    <br></br>
                                  <UncontrolledDropdown nav>
                                        <DropdownToggle caret color="primary">
                                            Export
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={()=> {downloadExcel(allPenawaranSo)}}>Excel</DropdownItem>
                                         </DropdownMenu>
                                    </UncontrolledDropdown>
                                     </Col>
                                </Row>
                        </Form>
                </CardBody>
                </Card>
            </div>
            </Row>
        </Container>
    </div>
  );
}

export default PageLaporanCabang;