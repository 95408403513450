/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Row,
  CardBody,
  CardHeader,
  Form,
  Input,
  Col,
  FormGroup,
  Nav,
  NavItem,
  Label ,
  Button ,
  TabPane,
  DropdownItem,
  Badge,
  Card,
  DropdownMenu,
  UncontrolledDropdown,
  Container,
  DropdownToggle,
  Modal, ModalHeader, ModalBody
} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Detail from "views/pages/itemStock/StockPribadi/Detail"
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";


const CekBarcode = () => {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  const [hide, setHide] = useState(true);
  const [nameStock, setNameStock] = useState("");
  const [codeStock, setCodeStock] = useState("");
  const [barcode, setBarcode] = useState("");
  const [status, setStatus] = useState("")
  const [allItemStock, setAllItemStock] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [rowIndex, setRowIndex] = useState(0);
  const [detailItemStok, setDetailItemStok] = useState(0);

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(
        page,
        perPage,
        currentSort,
        nameStock,
        codeStock,
        barcode,
        status
      );
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label
        className="form-control-label"
        >
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort);
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  };

  const updateDataTable = (page, perPage, sort, nameStock, codeStock, barcode, status) => {
    getItemOwnStock(page, perPage, sort, nameStock, codeStock, barcode, status);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setNameStock(nameStock);
    setCodeStock(codeStock);
    setBarcode(barcode);
    setStatus(status);
  };

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`;
      updateDataTable(page, perPage, sort, nameStock, codeStock, barcode, status);
    }
  };

  useEffect(() => {
    getItemOwnStock(page, perPage, currentSort, "", "");
  }, []);

  const getItemOwnStock = async (page, perPage, currentSort, item_name=null , item_code=null , barcode=null, active_flag=null ) => {
    let filter = {
      page: page,
      per_page: perPage,
      warehouse_id: parseInt(warehouse)
    };
    if (item_name !== null ) {
      filter = Object.assign(filter, { item_name: item_name })
    }
    if (item_code !== null ) {
        filter = Object.assign(filter, { item_code: item_code })
    }
    if (barcode !== null ) {
      filter = Object.assign(filter, { barcode: barcode })
    }
    if (active_flag !== null ) {
        filter = Object.assign(filter, { active_flag: active_flag })
      }
    const data = filter;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/ubah-barcode/page`,
      data,
      { headers }
    )
    .then((data) => {
    setAllItemStock(data.data.response);
    setPage(data.data.current_page + 1);
    setPerpage(data.data.per_page);
    setTotalItem(data.data.total_item);
  })
    .catch(function (error) {
        //   setGagalAlert(error.response.data.message);
    setAllItemStock(error.response.data.response);

    });
}

const setGagalAlert = (id) => {
setAlert(
<SweetAlert
danger
showConfirm
confirmBtnText="Ok"
title={id}
onCancel={hideAlert}
onConfirm={hideAlert}
/>
);
};

const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const hideAlert = () => {
  setAlert(null);
};

const updateBarcode = (id) => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/ubah-barcode/update/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        getItemOwnStock();
      })
      .catch(function (error) {
        console.log(error);
      });
    }

  const reset = () => {
    setNameStock("");
    setCodeStock("");
    setBarcode("");
    setStatus("");
    updateDataTable(1, perPage, currentSort, "", "");
  }
  const rowEvents = {
    onDoubleClick: (e, row, rowIndex) => {
      setHide(false);
      setDetailItemStok(row.id);
    },
  };

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }

  return (
    <div>
      {alert}
      <SimpleHeader name="Cek Barcode" parentName="PO" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                {/* <div
                  style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3></h3>
                  {CreateButton && CreateButton === "1" && (
                  <div style={{ textAlign: 'right' }}>
                    <Link className="btn btn-danger" to="/admin/stock-item/create">
                    <i className="fas fa-plus" /> Tambah
                    </Link>
                  </div>
                  )}
                </div> */}
                </CardHeader>
              <CardBody>
                      <Form>
                        <Row md="12">
                          <Col md="3">
                            <FormGroup>
                              <Label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect3">Nama Item</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Nama Item"
                                value={nameStock}
                                onChange={e => updateDataTable(1, perPage, currentSort, e.target.value, codeStock, barcode, status)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect3">Kode Item</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Kode Item"
                                value={codeStock}
                                onChange={e => updateDataTable(1, perPage, currentSort, nameStock, e.target.value, barcode, status)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect3">Barcode</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Barcode"
                                value={barcode}
                                onChange={e => updateDataTable(1, perPage, currentSort, nameStock, codeStock, e.target.value, status )}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect3">Status</Label>
                              <Input
                                type="select"
                                placeholder="Masukan Barcode"
                                value={status}
                                onChange={e => updateDataTable(1, perPage, currentSort, nameStock, codeStock,barcode, e.target.value)}
                              >
                              <option value={""}>Pilih Status</option>
                              <option value={1}>Belum Perbarui</option>
                              <option value={2}>Sudah Perbarui</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label
                              className="form-control-label"
                              >&nbsp;</Label>
                              <br></br>
                              <Button type="button" onClick={reset} color="secondary">
                                Reset
                              </Button>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                      {allItemStock === null ?
                      <div>
                      <ToolkitProvider
                              rowNumber={rowIndex}
                              data={""}
                              keyField="id"
                              columns={[
                              {
                                dataField: "no",
                                text: "#",
                                sort: true,
                                page: 1,
                                formatter: (cell, row, index) => {
                                let currentRow = ++index;
                                return currentRow + rowIndex;
                                },
                            },
                            {
                                dataField: "",
                                text: "Kode Item",
                                sort: true,
                            },
                            {
                                dataField: "",
                                text: "Barcode",
                                sort: true,
                            },
                            {
                                dataField: "",
                                text: "Nama Item",
                                sort: true,
                            },
                            {
                                dataField: "",
                                text: "Harga Lama",
                                sort: true,
                            },
                            {
                                dataField: "",
                                text: "Harga Baru",
                                sort: true,
                            },
                              {
                                dataField: "",
                                text: "Status",
                                sort: true,
                            },
                              ]}
                          >
                              {(props) => (
                              <div className="py-4 table-responsive">
                                  <BootstrapTable
                                  remote
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  bordered={false}
                                  hover={true}
                                  onTableChange={handleTableChange}
                                  />
                              </div>
                              )}
                      </ToolkitProvider>
                      <CardHeader>
                          <center><h3>DATA NOT FOUND</h3></center>
                      </CardHeader>
                    </div> 
                    :
                        
                        <div>
                        <ToolkitProvider
                        rowNumber={rowIndex}
                        data={allItemStock}
                        keyField="id"
                        columns={[
                        {
                            dataField: "no",
                            text: "#",
                            sort: true,
                            page: 1,
                            formatter: (cell, row, index) => {
                            let currentRow = ++index;
                            return currentRow + rowIndex;
                            },
                        },
                        {
                            dataField: "item_code",
                            text: "Kode Item",
                            sort: true,
                        },
                        {
                            dataField: "barcode",
                            text: "Barcode",
                            sort: true,
                        },
                        {
                            dataField: "item_name",
                            text: "Nama Item",
                            sort: true,
                        },
                        {
                            dataField: "harga_lama",
                            text: "Harga Lama",
                            sort: true,
                            formatter: (value) => formatRupiah(value)
                        },
                        {
                            dataField: "harga_baru",
                            text: "Harga Baru",
                            sort: true,
                            formatter: (value) => formatRupiah(value)
                        },
                        {
                            dataField: "active_flag",
                            text: "Status",
                            sort: true,
                            formatter: (cell, row) => {
                            return row.active_flag === 1
                                ? 
                                <Badge color="" className="badge-dot mr-4">
                                    <i className="bg-warning" />
                                    Barcode Belum Di Perbarui
                                </Badge>
                                : <Badge color="" className="badge-dot mr-4">
                                    <i className="bg-success" />
                                    Barcode Sudah Di Perbarui
                                </Badge>
                            },
                        },
                        {
                        dataField: "", text: "", formatter: (cell, row, index) => {
                            return row.active_flag === 1
                            ?  <Button color="danger"  onClick={() => updateBarcode(row.id)} ><i className="fa fa-check-circle"  aria-hidden="true"/> Validasi</Button>
                            :
                            <Badge color="" className="badge-dot mr-4">
                              <i className="bg-danger" />
                              Sudah Validasi
                            </Badge>
                        }
                        },
                        ]}
                        >
                        {(props) => (
                        <div className="py-4 table-responsive">
                            <BootstrapTable
                            remote
                            {...props.baseProps}
                            bootstrap4={true}
                            bordered={false}
                            hover={true}
                            // rowEvents={rowEvents}
                            pagination={paginationFactory({ ...paginationOption })}
                            onTableChange={handleTableChange}
                            />
                        </div>
                        )}
                        </ToolkitProvider>
                        </div>
                    }
                     {/* <div className="table-sm my--4"> */}
              </CardBody>
            </Card>
            </CardBody>
              <Nav tabs>
                <NavItem>
                </NavItem>
              </Nav>
              {hide ? (
                <></>
              ) : (
                <>
                    <TabPane tabId="1">
                      <Row>
                        <Col md="12">
                          <Detail code={detailItemStok}/>
                        </Col>
                      </Row>
                    </TabPane>
                </>
              )}
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default CekBarcode;