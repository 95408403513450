/*eslint-disable*/
import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Col,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";

export default function EditTypeRak(props) {
  const token = localStorage.token;
  let history = useHistory();
  const [durasipo,setDurasiPo] = useState("");
  const [alert, setAlert] = React.useState(null);


  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/rak-type/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setDurasiPo(data.data.response.name);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function EditData() {
    let data = {
      name: durasipo,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/rak-type/update/${props.match.params.id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setTimeout(() => (history.push("/admin/tipe-rak")), 2000);
        })
        .catch(function (error) {
          setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={EditData}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  }

  const handleSubmit = (e) => {
    {
      EditData();
    }
  };
  return (
    <>
    {alert}
      <SimpleHeader name="Edit Tipe Rak" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-secondary shadow">
                <Form onSubmit={handleSubmit}>
                <CardHeader className="bg-white border-0">

                    <h3>Edit Tipe Rak</h3>
                  </CardHeader>
                  <CardBody>
                    <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Nama Tipe Rak
                              </Label>
                              <Col sm={4}>
                                <Input
                                autoComplete="off"
                                  type="text"
                                  name="desc"
                                  placeholder="Masukan Tipe Rak"
                                  value={durasipo}
                                  onChange={(e) => {
                                    setDurasiPo(e.target.value);
                                  }}
                                />
                              </Col>                        
                            </FormGroup>
                          </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Link className="btn btn-info" to="/admin/tipe-rak">
                      Kembali
                    </Link>
                    <Button color="danger" onClick={setQuestionAlert}>
                        Simpan
                    </Button>
                  </CardFooter>
                </Form>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
