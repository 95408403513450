/*eslint-disable*/
import React, { useState, useEffect } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  FormGroup,
  Label,
  Input,
  Container,
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import FotoArea from "components/SelectedFile/fotoarea";
import FotoPosisi from "components/SelectedFile/fotoposisi";
import FotoSisi from "components/SelectedFile/fotosisi";
import FotoDisplay from "components/SelectedFile/fotodisplay";
import Halaman404 from "views/404";
import FotoPartisi from "components/SelectedFile/fotopartisi";

const CreateRak = () => {
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  const history = useHistory();
  const [minimumstock, setMinimumStock] = useState(0);
  const [maximumstock, setMaximumStock] = useState(0);
  const [area, setArea] = useState("");
  const [sisi, setSisi] = useState("");
  const [hook, setHook] = useState("");
  const [subarea, setSubArea] = useState("");
  const [tipe, setTipe] = useState("");
  const [tipes, setTipes] = useState([]);
  const [subarea1, setSubArea1] = useState("");
  const [area1, setArea1] = useState("");
  const [sisi1, setSisi1] = useState("");
  const [hook1, setHook1] = useState("");
  const [partisi, setPartisi] = useState(0);
  const [partisi1, setPartisi1] = useState("");
  const [position, setPosition] = useState("");
  const [sale, setSale] = useState(0);
  const [alert, setAlert] = React.useState(null);
  const allInfo = JSON.parse(localStorage.allinfo);
  const rak = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Lokasi Barang").map((p) => p.read_access));
 

  useEffect(() => {
    getRak();
  }, []);

  const getRak = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/rak-type/list`,
        { headers }
      )
      .then((data) => {
        setTipes(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const CreateRak = () => {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    
    const myjson = JSON.stringify ({
        warehouse_id: parseInt(warehouse) ,
        tipe,
        area,
        sub_area : subarea,
        sisi,
        total_real_location :partisi,
        hook,
        position : parseInt(position),
        minimum_stok: parseInt(minimumstock),
        maximum_stok: parseInt(maximumstock),
        active_flag:1,
        sale_state : parseInt(sale), 
    });     
    let data = new FormData();
    data.append("body",myjson)
    data.append("area",area1)
    data.append("sub_area",subarea1)
    data.append("sisi",sisi1)
    data.append("hook",hook1)
    data.append("img_total_real_location", partisi1)
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/rak/save`, data, { headers })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setTimeout(() => (history.push("/admin/rak")), 1000);
        })
        .catch(function (error) {
          setGagalAlert(error.response.data.message);
        });
  };

    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }

  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };

  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={CreateRak}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };

    const hideAlert = () => {
      setAlert(null);
    }

  return (
    <div>
      {alert}
      {rak && rak === "1" ? (
			<div>
      <SimpleHeader name="Tambah Planogram" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                            <h3>Rak</h3>
                        </CardHeader>
                        <CardBody>
                        <Row md="12">
                            <Col md="6">
                           
                                <FormGroup row>
                                    <Label
                                    className="form-control-label"
                                        for="exampleEmail"
                                        sm={4}
                                        >
                                        Tipe Rak
                                    </Label>
                                    <Col sm={7}>
                                        <Input
                                        autoComplete="off"
                                            className="form-control-alternative"
                                            type="select"
                                            value={tipe}
                                            onChange={(e) => setTipe(e.target.value)}
                                        >
                                        <option value="">Pilih Tipe Rak</option>
                                            {tipes.map((Tipe, key) => {
                                                return (
                                                <option key={key} value={Tipe.name}>
                                                    {Tipe.name}
                                                </option>
                                                );
                                            })}
                                    </Input>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                <Label
                                className="form-control-label"
                                    for="exampleEmail"
                                    sm={4}
                                >
                                    Area
                                </Label>
                                <Col sm={7}>
                                    <Input
                                    autoComplete="off"
                                        className="form-control-alternative"
                                    type="text"
                                    name="Area"
                                    placeholder="Masukan Area"
                                    value={area}
                                    onChange={(e) => {
                                        setArea(e.target.value);
                                    }}
                                    />
                                </Col>
                                </FormGroup>
                                <FormGroup row>
                                <Label
                                className="form-control-label"
                                    for="exampleEmail"
                                    sm={4}
                                >
                                    Sub Area
                                </Label>
                                <Col sm={7}>
                                    <Input
                                    autoComplete="off"
                                        className="form-control-alternative"
                                    type="text"
                                    name="SISI"
                                    placeholder="Masukan Sub Area"
                                    value={subarea}
                                    onChange={(e) => {
                                        setSubArea(e.target.value);
                                    }}
                                    />
                                </Col>
                                </FormGroup>
                                <FormGroup row>
                                <Label
                                className="form-control-label"
                                    for="exampleEmail"
                                    sm={4}
                                >
                                    Sisi
                                </Label>
                                <Col sm={7}>
                                    <Input
                                    autoComplete="off"
                                        className="form-control-alternative"
                                    type="text"
                                    name="SISI"
                                    placeholder="Masukan Sisi"
                                    value={sisi}
                                    onChange={(e) => {
                                        setSisi(e.target.value);
                                    }}
                                    />
                                </Col>
                                </FormGroup>
                                <FormGroup row>
                                <Label
                                className="form-control-label"
                                    for="exampleEmail"
                                    sm={4}
                                >
                                    Hook
                                </Label>
                                <Col sm={7}>
                                    <Input
                                    autoComplete="off"
                                        className="form-control-alternative"
                                    type="text"
                                    name="SISI"
                                    placeholder="Masukan Hook"
                                    value={hook}
                                    onChange={(e) => {
                                        setHook(e.target.value);
                                    }}
                                    />
                                </Col>
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                {/* <FormGroup row>
                                <Label
                                className="form-control-label"
                                    for="exampleEmail"
                                    sm={4}
                                >
                                    Stok Minimum 
                                </Label>
                                <Col sm={7}>
                                    <Input
                                    autoComplete="off"
                                        className="form-control-alternative"
                                    type="number"
                                    name="Minimum Stock"
                                    placeholder="Masukan Stok Minimum"
                                    value={minimumstock}
                                    onChange={(e) => {
                                    setMinimumStock(e.target.value);
                                    }}
                                    />
                                </Col>
                                </FormGroup>
                                <FormGroup row>
                                <Label
                                className="form-control-label"
                                    for="exampleEmail"
                                    sm={4}
                                >
                                    Stok Maximum
                                </Label>
                                <Col sm={7}>
                                    <Input
                                    autoComplete="off"
                                        className="form-control-alternative"
                                    type="number"
                                    name="Minimum Stock"
                                    placeholder="Masukan Stok Maximum"
                                    value={maximumstock}
                                    onChange={(e) => {
                                    setMaximumStock(e.target.value);
                                    }}
                                    />
                                </Col>
                                </FormGroup> */}
                                <FormGroup row>
                                <Label
                                className="form-control-label"
                                    for="exampleEmail"
                                    sm={4}
                                >
                                    Total Partisi
                                </Label>
                                <Col sm={7}>
                                    <Input
                                    autoComplete="off"
                                    className="form-control-alternative"
                                    type="number"
                                    name="Partisi"
                                    placeholder="Masukan Partisi"
                                    value={partisi}
                                    onChange={(e) => {
                                      setPartisi(e.target.value);
                                    }}
                                    />
                                </Col>
                                </FormGroup>
                                <FormGroup row>
                                  <Col sm={4}>
                                  <Label
                                    className="form-control-label"
                                    >
                                  Lokasi
                                  </Label>
                                  </Col>
                                  <Col sm={7}>
                                    <div style={{ display: "flex" }}>
                                      <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                                        <input
                                          className="custom-control-input"
                                          id="customCheck9"
                                          type="checkbox"
                                          value={1}
                                          checked={position === 1}
                                          onChange={() => setPosition(1)}
                                        />
                                        <label
                                          className="custom-control-label" 
                                          htmlFor="customCheck9">
                                        </label>
                                        <b>TOKO</b> 

                                      </div>
                                      &nbsp;&nbsp;&nbsp;
                                      <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                                        <input
                                          
                                          className="custom-control-input"
                                          id="customCheck8"
                                          type="checkbox"
                                          value={2}
                                          checked={position === 2}
                                          onChange={() => setPosition(2)}
                                        />
                                        <label
                                          className="custom-control-label" 
                                          htmlFor="customCheck8">
                                        </label>
                                        <b>GUDANG</b>

                                      </div>
                                    </div>
                                  </Col>
                          </FormGroup>
                          <FormGroup row>
                          <Col sm={4}>
                            <Label
                            className="form-control-label"
                            >
                            Status
                            </Label>
                          
                          </Col>
                          <Col sm={7}>
                            <div style={{ display: "flex" }}>
                              <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                                <input
                                  className="custom-control-input"
                                  id="customCheck5"
                                  type="checkbox"
                                  value={1}
                                  checked={sale === 1}
                                  onChange={() => setSale(1)}
                                />
                                <label
                                  className="custom-control-label" 
                                  htmlFor="customCheck5">
                                </label>
                                <b>JUAL</b> 

                              </div>
                              &nbsp;&nbsp;&nbsp;
                              <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                                <input
                                  className="custom-control-input"
                                  id="customCheck6"
                                  type="checkbox"
                                  value={2}
                                  checked={sale === 2}
                                  onChange={() => setSale(2)}
                                />
                                <label
                                  className="custom-control-label" 
                                  htmlFor="customCheck6">
                                </label>
                                <b>TIDAK DI JUAL</b>
                              </div>
                            </div>
                          </Col>
                  </FormGroup>
                            </Col>
                        </Row>
                        </CardBody>
                    </Card>
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                            <h3>Gambar Rak</h3>
                        </CardHeader>
                        <CardBody>
                        <Row md="12">
                            <Col md="6">
                                  <FormGroup row>
                                    <Label
                                    className="form-control-label"
                                      for="exampleEmail"
                                      sm={4}
                                    >
                                      Area
                                    </Label>
                                    <Col sm={7}>
                                      <label
                                      className="form-control-label" htmlFor1="formId1" >
                                        <input
                                          hidden
                                          id="formId1"
                                          name="file"
                                          type="file"
                                          accept="image/*"
                                          onChange={(event) => {
                                            setArea1(event.target.files[0]);
                                          }}
                                        />
                                        <FotoArea
                                          onclick={() => { }}
                                          imageSrc1={area1 != "" && area1 != undefined ? URL.createObjectURL(area1) : null}
                                        />
                                      </label>
                                    </Col>
                                  </FormGroup>
                                <FormGroup row>
                                    <Label
                                    className="form-control-label"
                                      for="exampleEmail"
                                      sm={4}
                                    >
                                      Sub Area
                                    </Label>
                                    <Col sm={7}>
                                      <label
                                      className="form-control-label" htmlFor2="formId2" >
                                        <input
                                          hidden
                                          id="formId2"
                                          name="file"
                                          type="file"
                                          accept="image/*"
                                          onChange={(event) => {
                                            setSubArea1(event.target.files[0]);
                                          }}
                                        />
                                        <FotoPosisi
                                          onclick={() => { }}
                                          imageSrc2={subarea1 != "" && subarea1 != undefined ? URL.createObjectURL(subarea1) : null}
                                        />
                                      </label>
                                    </Col>
                                  </FormGroup>
                                  <FormGroup row>
                                    <Label
                                    className="form-control-label"
                                      for="exampleEmail"
                                      sm={4}
                                    >
                                      Partisi
                                    </Label>
                                    <Col sm={7}>
                                      <label
                                      className="form-control-label" htmlFor5="formId5" >
                                        <input
                                          hidden
                                          id="formId5"
                                          name="file"
                                          type="file"
                                          accept="image/*"
                                          onChange={(event) => {
                                            setPartisi1(event.target.files[0]);
                                          }}
                                        />
                                        <FotoPartisi
                                          onclick={() => { }}
                                          imageSrc5={partisi1 != "" && partisi1 != undefined ? URL.createObjectURL(partisi1) : null}
                                        />
                                      </label>
                                    </Col>
                                  </FormGroup>
                            </Col>
                            <Col md="6">
                            <FormGroup row>
                                    <Label
                                    className="form-control-label"
                                      for="exampleEmail"
                                      sm={4}
                                    >
                                      Sisi
                                    </Label>
                                    <Col sm={7}>
                                      <label
                                      className="form-control-label" htmlFor3="formId3" >
                                        <input
                                          hidden
                                          id="formId3"
                                          name="file"
                                          type="file"
                                          accept="image/*"
                                          onChange={(event) => {
                                            setSisi1(event.target.files[0]);
                                          }}
                                        />
                                        <FotoSisi
                                          onclick={() => { }}
                                          imageSrc3={sisi1 != "" && sisi1 != undefined ? URL.createObjectURL(sisi1) : null}
                                        />
                                      </label>
                                    </Col>
                                  </FormGroup>
                                <FormGroup row>
                                    <Label
                                    className="form-control-label"
                                      for="exampleEmail"
                                      sm={4}
                                    >
                                      Hook
                                    </Label>
                                    <Col sm={7}>
                                      <label
                                      className="form-control-label" htmlFor4="formId4" >
                                        <input
                                          hidden
                                          id="formId4"
                                          name="file"
                                          type="file"
                                          accept="image/*"
                                          onChange={(event) => {
                                            setHook1(event.target.files[0]);
                                          }}
                                        />
                                        <FotoDisplay
                                          onclick={() => { }}
                                          imageSrc4={hook1 != "" && hook1 != undefined ? URL.createObjectURL(hook1) : null}
                                        />
                                      </label>
                                    </Col>
                                  </FormGroup>
                            </Col>
                        </Row>
                        </CardBody>
                        <CardFooter>
                            <Link className="btn btn-info" to="/admin/rak">
                            Kembali
                            </Link>
                            <Button color="danger" onClick={() => setQuestionAlert()}>
                                Simpan
                            </Button>
                        </CardFooter>
                    </Card>
          </div>
        </Row>
      </Container>
      </div>
      ):(
        <Halaman404 />
      )}
    </div>
    
  );
};

export default CreateRak;