/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  CardBody,
  CardHeader,
  Container,
  UncontrolledTooltip,
  ButtonGroup,
  Badge,
  Modal, ModalHeader, ModalBody
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

const PasswordDuration = () => {
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  const [datalog, setDataLog] = useState([])
	const toggle1 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
  const redirectPrefix = `/admin/password-operasional/edit/`;
  const [alert, setAlert] = React.useState(null);
  const [rowIndex, setRowIndex] = useState(0);
  const [allPasswordOperational, setAllPasswordOperational] = useState([]);
  const [allPasswordOperational1, setAllPasswordOperational1] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const allInfo = JSON.parse(localStorage.allinfo);
  const durasipassword = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Otoritas").map((p) => p.read_access));
  const UpdateButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Otoritas").map((p) => p.update_access));

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort);
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  };

  const updateDataTable = (page, perPage, sort) => {
    getDurationPassword2(page, perPage, sort);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
  };

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`;
      updateDataTable(page, perPage, sort);
    }
  };

  useEffect(() => {
    getDurationPassword(page, perPage, currentSort);
    getDurationPassword2(page, perPage, currentSort);
  }, []);

  const getDurationPassword = (page, perPage, currentSort) => {
    let filter = { 
        page: page,
        per_page: perPage ,
        warehouse_id : parseInt(warehouse)
    };
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/password-operasional/page`, data, { headers })
      .then((data) => {
        setAllPasswordOperational(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getDurationPassword2 = (page, perPage, currentSort) => {
    let filter = { 
        page: page,
        per_page: perPage ,
        warehouse_id : parseInt(warehouse)
    };
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/log-otorisasi/page`, data, { headers })
      .then((data) => {
        setAllPasswordOperational1(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const delatePajak = (id) => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/pajak/delete/${id}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setSuccessAlert();
        getDurationPassword();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const setSuccessAlert = () => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title="Pejak deleted"
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };


  const getDataLog = (id) => {
		let filter = { 
			transaction : id,
			features : "MASTER4",
			warehouse_id : warehouse
		}
		const data = filter;
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
			setDataLog(data.data.response);
            setModal1(!modal1);

		})
		  .catch(function (error) {
			setGagalAlert(error.response.data.message);
		  })
	  }

	  const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
		);
		};
		
		const hideAlert = () => {
		  setAlert(null);
		};

  return (
    <div>
      {alert}
      {durasipassword && durasipassword === "1" ? (
        <div>
      <SimpleHeader name="Password Otoritas" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
              <div
											style={{ display: "flex", justifyContent: "space-between" }}
										>
											<h3>Password Otoritas</h3>
											<div style={{ textAlign: "rigth" }}>
											</div>
										</div>
              </CardHeader>
              <CardBody>
                <ToolkitProvider
                  rowNumber={rowIndex}
                  data={allPasswordOperational}
                  keyField="id"
                  columns={[
                    {
                      dataField: "no",
                      text: "#",
                      sort: true,
                      page: 1,
                      formatter: (cell, row, index) => {
                        let currentRow = ++index;
                        return currentRow + rowIndex;
                      },
                    },
                    {
                        dataField: "password_otorisasi",
                        text: "Password Otorisasi",
                        sort: true,
                    },
                    
                    {
                     
                      dataField: "",
                      text: "",
                      formatter: (cell, row, index) => {
                        return (
                         
                          <ButtonGroup>
                              {UpdateButton && UpdateButton === "1" && (
                            <Button>
                              <Link
                                to={redirectPrefix + row.id}
                                id={"tooltip_" + row.id}
                              >
                                <i className="fas fa-user-edit" />
                              </Link>
                            </Button>
                             )}
                            <UncontrolledTooltip
                              delay={0}
                              target={"tooltip_" + row.id}
                            >
                              Edit
                            </UncontrolledTooltip>
                            <Button color="link"  onClick={() => (getDataLog(row.id))}>
                                <i className="fa fa-info-circle" aria-hidden="true"></i>
                            </Button>
                          </ButtonGroup>
                          
                          
                        );
                      },
                    },
                    
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        remote
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        hover={true}
                        // pagination={paginationFactory({ ...paginationOption })}
                        // onTableChange={handleTableChange}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
            <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3>Log Otoritas</h3>
                 
                </div>
              </CardHeader>
              <CardBody>
                <ToolkitProvider
                  rowNumber={rowIndex}
                  data={allPasswordOperational1}
                  keyField="id"
                  columns={[
                    {
                      dataField: "no",
                      text: "#",
                      sort: true,
                      page: 1,
                      formatter: (cell, row, index) => {
                        let currentRow = ++index;
                        return currentRow + rowIndex;
                      },
                    },
                    {
                      dataField: "created_at",
                      text: "Tanggal",
                      sort: true,
                    },
                    {
                      dataField: "username",
                      text: "PIC",
                      sort: true,
                    },
                    {
                      dataField: "note",
                      text: "Keterangan",
                      sort: true,
                    },
                    {
                      dataField: "position",
                      text: "Menu",
                      sort: true,
                      formatter: (cell, row) => {
                        return row.position === 1
                          ? 
                            <Badge color="" className="badge-dot mr-4">
                              <i className="bg-danger" />
                              Pembelian
                            </Badge>
                          : row.position === 2
                          ? <Badge color="" className="badge-dot mr-4">
                              <i className="bg-info" />
                              Penjualan
                            </Badge>
                          : <Badge color="" className="badge-dot mr-4">
                              <i className="bg-success" />
                              Kasir
                            </Badge>
                      },
                    },
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        remote
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        hover={true}
                        pagination={paginationFactory({ ...paginationOption })}
                        onTableChange={handleTableChange}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>  
      </div>
      ):(
        <Halaman404 />
      )}
    </div>
  );
};

export default PasswordDuration;
