/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
  Card, 
  Button, 
  Row, 
  Col, 
  CardBody, 
  CardHeader, 
  Container,
  Form, 
  FormGroup, 
  Label, 
  Input,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
	Modal, ModalHeader, ModalBody,
  Badge

} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import paginationFactory from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from 'views/404';

const TransferRak = () => {
  const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle1 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
  const token = localStorage.token;
  const warehouse = parseInt(localStorage.warehouse);
  const [allRak, setAllRak] = useState([]);
  const redirectPrefix = `/admin/transfer-rak/detail/`;
  const [rakCode, setRakCode] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const allInfo = JSON.parse(localStorage.allinfo);
  const transferRak = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Transfer Eksternal").map((p) => p.read_access));
  const CreateButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Transfer Internal").map((p) => p.create_access));

  
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, rakCode, keterangan);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label
        className="form-control-label"
        >
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, rakCode, keterangan) => {
    getRak(page, perPage, sort, rakCode, keterangan);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setRakCode(rakCode);
    setKeterangan(keterangan);
    
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, rakCode, keterangan)
    }
  }

  useEffect(() => {
    getRak(page, perPage, currentSort, "", "");
  }, []);

  const getRak = async (page, perPage, currentSort, pr_code = null, keterangan = null) => {
    let filter = {
       page: page, 
       per_page: perPage,
       warehouse_id: parseInt(warehouse) 
      }
      if (pr_code !== null) {
        filter = Object.assign(filter, { pr_code: pr_code })
      }
      if (keterangan !== null) {
        filter = Object.assign(filter, { keterangan: keterangan })
      }
    const data = filter;
    const headers = {
      'Authorization': `Bearer ${token}`,
    };
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/pindah-rak/page `, data, { headers })

    .then((data) => {
      setAllRak(data.data.response);
      setPage(data.data.current_page + 1);
      setPerpage(data.data.per_page);
      setTotalItem(data.data.total_item);
    })
    .catch(function (error) {
      setAllRak(error.response.data.response);
    });
    };

  const reset = () => {
    setKeterangan("");
    setRakCode("");
    updateDataTable(1, perPage, currentSort, "", "");
  }

  const getDataLog = (id) => {
		let filter = { 
			transaction : id,
			features : "TRANSFER3",
			warehouse_id : warehouse
		}
		const data = filter;
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
			setDataLog(data.data.response);
            setModal1(!modal1);

		})
		  .catch(function (error) {
			setGagalAlert(error.response.data.message);
		  })
	  }

	  const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
		);
		};
		
		const hideAlert = () => {
		  setAlert(null);
		};

  return (
    <div>
      {alert}
      {transferRak && transferRak === "1" ? (
        <div>
     <SimpleHeader name="Transfer Internal" parentName="Inventory" />
      <Container className="mt--6" fluid>
       <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3>Transfer Internal</h3>
                  <div style={{ textAlign: "right" }}>
                  {CreateButton && CreateButton === "1" && (
                    <Link className="btn btn-danger" to="/admin/transfer-rak/create">
                    <i className="fas fa-plus" /> Tambah
                    </Link>
                  )}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                  <Form>
                    <Row md="12">
                      <Col md="3">
                        <FormGroup>
                          <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect3">Kode</Label>
                          <Input
                            type="text"
                            placeholder="Masukan Kode"
                            value={rakCode}
                            onChange={e => updateDataTable(1, perPage, currentSort, e.target.value, rakCode)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button type="button" onClick={reset} color="secondary">Reset</Button>
                      </Col>
                    </Row>
                  </Form>
                  {allRak === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                      dataField: "no",
                                      text: "#",
                                      sort: true,
                                      page: 1,
                                      formatter: (cell, row, index) => {
                                      let currentRow = ++index;
                                      return currentRow + rowIndex;
                                      },
                                  },
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                  dataField: "",
                                  text: "Kode Transfer Rak",
                                  sort: true,
                                },
                                
                                {
                                  dataField: "",
                                  text: "Keterangan",
                                  sort: true,
                                },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                        </div>
                        ):(
                <ToolkitProvider
                  rowNumber={rowIndex}
                  data={allRak}
                  keyField="id"
                  search={ {
                    defaultSearch: 'search something here'
                  } }
                  columns={[
                    {
                      dataField: "no",
                      text: "#",
                      sort: true,
                      page: 1,
                      formatter: (cell, row, index) => {
                        let currentRow = ++index;
                        return currentRow + rowIndex;
                      }
                    },
                    {
                      dataField: "pr_code",
                      text: "Kode TR",
                      sort: true,
                      formatter: (cell, row) => {
                        return row.pr_code === ""
                          ? <h2>
                            <Badge color="warning" >
                              {row.pr_code}
                            </Badge>
                            </h2>
                          : 
                          <h2>
                          <Badge color="warning" >
                              {row.pr_code}
                            </Badge>
                            </h2>
                      },
                  },
                    {
                      dataField: "code_rak1",
                      text: "Rak Asal",
                      sort: true,
                    },
                    {
                      dataField: "code_rak2",
                      text: "Rak Tujuan",
                      sort: true,
                    },
                    {
                      dataField: "keterangan",
                      text: "Keterangan",
                      sort: true,
                    },
                     {
                        dataField: "",
                        text: "",
                        formatter: (cell, row, index) => {
                          return (
                          <UncontrolledDropdown nav>
                                <DropdownToggle color="danger">
                                    Tindakan
                                  </DropdownToggle>
                                  <DropdownMenu center>
                                        <Link  onClick={() => getDataLog(row.id)}>
                                                <DropdownItem>
                                                <i className="fa fa-info-circle" aria-hidden="true"></i> <span>Info</span>
                                                </DropdownItem>
                                            </Link>
                                        <Link  to={redirectPrefix + row.id}
                                          id={"tooltip_" + row.id}>
                                        <DropdownItem>
                                          <i className="fas fa-user-edit" /><span>Detail</span>
                                        </DropdownItem>
                                        </Link>
                                  </DropdownMenu>
                              </UncontrolledDropdown>
                              )
                            
                        },
                      },
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        remote
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        hover={true}
                        pagination={paginationFactory({ ...paginationOption })}
                        onTableChange={handleTableChange}
                      />
                    </div>
                  )}
                </ToolkitProvider>
                        )}
              </CardBody>
            </Card>
          </div>
       </Row>
     </Container>
     <Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>
     </div>
      ):(
        <Halaman404 />
      )}
    </div>
  );
}

export default TransferRak;