/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
	Col,
	Button,
	Container,
    Badge,
	Modal, ModalHeader, ModalBody

} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import CurrencyInput from 'react-currency-input-field';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";

export default function Userss() {
    const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle1 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
    const token = localStorage.token;
	const warehouseId = localStorage.warehouse;
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
    const [awal, setAwal] = useState("");
    const [resign, setResign] = useState(null);
    const [gender, setGender] = useState([]);
    const [itemId, setItemId] = useState(1);
    const [status, setStatus] = useState("");
    const [warehouse, setWarehouse] = useState("");
    const [warehouses, setWarehouses] = useState([]);
    const [allItem, setAllItem] = useState([]);
	const [query, setQuery] = useState("");
	const [isSearchShow, setIsSearchShow] = useState(false);
	const headers = { Authorization: `Bearer ${token}` };
    const [nik,setNik] = useState("");
    const [ktp,setKtp] = useState("");
    const [image,setImage] = useState("");
    const [alamat,setAlamat] = useState("");
    const [provinces,setProvinces] = useState([]);
    const [province,setProvince] = useState("");
    const [citys,setCitys] = useState([]);
    const [city,setCity] = useState("");
    const [name,setName] = useState("");
    const [kelahiran,setKelahiran] = useState("");
    const [sim,setSim] = useState("");
    const [nosim,setNoSim] = useState("");
    const [statuskk,setStatusKK] = useState("");
    const [namakk,setNamaKK] = useState("");
    const [genderkk,setGenderKK] = useState("");
    const [alamatkk,setAlamatKK] = useState("");
    const [teleponkk,setTeleponKK] = useState("");
    const [telepon,setTelepon] = useState("");
    const [hubungankk,setHubunganKK] = useState("");
    const [masasim,setMasaSim] = useState("");
    const [unit, setUnit] = useState("");
    const [units, setUnits] = useState([])
    const [group, setGroup] = useState("")
    const [groups, setGroups] = useState([])
    const [gaji, setGaji] = useState(0);
    const redirectPrefix = `/admin/karyawan/edit/`;
    const redirectPrefix1 = `/admin/karyawan/edit/gambar/`;
     

    useEffect(() => {
		const getData = setTimeout(async() => {
			if(query != ""){
				axios
				.post(`${process.env.REACT_APP_API_BASE_URL}/karyawan/page`, { name: query ,  },{ headers })
				.then((res) => {
					setAllItem(res.data);
					setIsSearchShow(true);
				})
				.catch(function (error) {
					setGagalAlert(error.response.data.message);
				});
			}
		  }, 1000)
		  return () => clearTimeout(getData)
	}, [query]);

    useEffect(() => {
		getItems();
	}, [page]);

	const getItems = async () => {
		let filter = {
			page: page,
			per_page: 1,
		};
		const data = filter;
		const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/karyawan/page`, data, { headers });
		if(res.data.response.id !== null){
			getById(res.data.response[0].id);
			setItemId(res.data.response[0].id);
			setTotalPages(res.data.total_page);
		}
	};

	  const getById = (id) => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/karyawan/get/${id}`,
	        { headers }
	      )
	      .then((data) => {
            getWarehouse(data.data.response.warehouse_id);
            getGroup(data.data.response.grup_id);
            getUnit(data.data.response.unit_id);
            getProvinsi(data.data.response.province_id);
            getKota(data.data.response.province_id);
            setProvince(data.data.response.province_id);
            setCity(data.data.response.city_id);
            setAlamat(data.data.response.address);
            setAwal(data.data.response.recruit);
            setResign(data.data.response.resign);
            setNik(data.data.response.nik);
            setKtp(data.data.response.ktp);
            setImage(data.data.response.karyawan_image);
            setName(data.data.response.name);
            setGaji(data.data.response.gaji);
            setGender(data.data.response.gender);
            setStatus(data.data.response.active_flag);
            setKelahiran(data.data.response.kelahiran);
            setSim(data.data.response.sim);
            setNoSim(data.data.response.nomor_sim);
            setMasaSim(data.data.response.masa_sim);
            setStatusKK(data.data.response.status_kk);
            setNamaKK(data.data.response.nama_kk);
            setGenderKK(data.data.response.gender_kk);
            setAlamatKK(data.data.response.alamat_kk);
            setTeleponKK(data.data.response.telepon_kk);
            setTelepon(data.data.response.phone);
            setHubunganKK(data.data.response.hubungan_kk);
            // setDepartement(data.data.response. department);
            // setDivisi(data.data.response.divisi);
            // setJabatan(data.data.response.jabatan);
            // setStatus(data.data.response.active_flag);
            // setPosisi(data.data.response.description);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

    const getWarehouse = (id) => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`,
            { headers }
          )
          .then((data) => {
            setWarehouses(data.data.response);
            setWarehouse(id)
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      const getGroup = (id) => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
    axios
        .get(
        `${process.env.REACT_APP_API_BASE_URL}/grup/list`,
        { headers }
        )
        .then((data) => {
        setGroups(data.data.response);
        setGroup(id)
        })
        .catch(function (error) {
        console.log(error);
        });
    };

    const getUnit = (id) => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
    axios
        .get(
        `${process.env.REACT_APP_API_BASE_URL}/unit/list`,
        { headers }
        )
        .then((data) => {
        setUnits(data.data.response);
        setUnit(id)
        })
        .catch(function (error) {
        console.log(error);
        });
    };

    const getProvinsi = () => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/province/list`, { headers
        })
        .then(data => {
          setProvinces(data.data.response_data);
        })
          .catch(function (error) {
            console.log(error)
          })
      }
    
      const getKota = (id) => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/city?province_id=${id}`, { headers
        })
        .then(data => {
          setCitys(data.data.response_data);
        })
          .catch(function (error) {
            console.log(error)
          })
      }

    const searchShow = (item) => {
		getById(item.id);
        setItemId(item.id);
        // setPage(item.id)
		setIsSearchShow(false);
	};
    
    const priceGaji = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setGaji(value || ' ');
	  };

      const getDataLog = (id) => {
		let filter = { 
			transaction : id,
			features : "HRD1",
			warehouse_id : 0
		}
		const data = filter;
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
			setDataLog(data.data.response);
			setModal1(!modal1);
	
		})
		  .catch(function (error) {
			setGagalAlert(error.response.data.message);
		  })
	  }
	
	  const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
		);
		};
		
		const hideAlert = () => {
		  setAlert(null);
		};

	return (
		<>
        {alert}
			<div>
				<SimpleHeader name="Karyawan" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
								<Card className="position-sticky boxShadow bg-secondary" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
									 		<Col md="5 d-lg-block d-none">
                                                <Button onClick={() => setPage(1)} disabled={page === 1} color="danger" type="button">
													First 
												</Button>
												<Button onClick={() => setPage((page) => page - 1)} disabled={page === 1} color="success" type="button">
													<i className="ni ni-bold-left" /> Prev
												</Button>
												<Button onClick={() => setPage((page) => page + 1)} disabled={page >= totalPages} color="success" type="button">
													Next <i className="ni ni-bold-right" />
												</Button>
												<Button onClick={() => setPage(totalPages)} disabled={page == totalPages} color="warning" type="button">
													End
												</Button>
											</Col>
											<Col md="4">
                                                <FormGroup row>
													<Col sm={9}>
														<Input
															placeholder="Search Karyawan"
															type="search"
															value={query}
															onChange={(e) => setQuery(e.target.value)}
														/>
													</Col>
												</FormGroup>
											</Col>
											<Col md="3 d-lg-block d-none">
												<div style={{ textAlign: "right" }}>
                                                <Button color="warning" onClick={() => (getDataLog(itemId))}>
																<i className="fa fa-info-circle" aria-hidden="true"></i>
													</Button>
                                                    <Link className="btn btn-info"
														to={redirectPrefix + itemId}
													>
														<i className="fas fa-user-edit" /> Edit
													</Link>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
								{isSearchShow && query && (
									<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
										<div style={{ position: "absolute", top: "1.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
											<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
										</div>
										<span className="text-center mb-3">
											<b>Pencarian berdasarkan:</b> {query}
										</span>
										{allItem?.response ? (
											allItem.response.map((item) => (
												<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" style={{minHeight: 'auto'}} onClick={() => (searchShow(item),setQuery(''))}>
													<div>
														<b>Nama :</b> {item.name}
													</div>
												</CardBody>
											))
										) : (
											<div className="text-center mb-3 text-danger">User "{query}" tidak ada bosku!</div>
										)}
									</Card>
								)}
                                    <Row md="12">
										<Col md="6">
                                            <Card className="bg-secondary shadow">
                                                <CardHeader className="bg-white border-0">
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <h3>Karyawan</h3>
                                                        <div style={{ textAlign: 'right' }}>
                                                            <h1>
                                                                {status === 1 ?  
                                                                <Badge color="success">
                                                                    Bekerja
                                                                </Badge> :  
                                                                <Badge color="danger">
                                                                    Tidak Bekerja
                                                                </Badge>}
                                                           
                                                            </h1>
                                                        </div>
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            Cabang
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input 
                                                            disabled
                                                            className="form-control-alternative"
                                                            name="person"
                                                            type="select"
                                                            value={warehouse}
                                                            onChange={(e) => {
                                                                setWarehouse(e.target.value);
                                                            }}
                                                            >
                                                            <option value=''>Pilih Cabang</option>
                                                            {warehouses.map((warehouse2, key) => {
                                                                return (
                                                                    <option key={key} value={warehouse2.id}>
                                                                    {warehouse2.name}
                                                                    </option>
                                                                );
                                                                })}
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            NIP
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input 
                                                            disabled
                                                                className="form-control-alternative"
                                                                type="text"
                                                                
                                                                placeholder="Masukan Nomor Induk Pegawai"
                                                                value={nik}
                                                                onChange={(e) => {
                                                                    setNik(e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            KTP
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input 
                                                            disabled
                                                                className="form-control-alternative"
                                                                type="text"
                                                                
                                                                placeholder="Masukan KTP"
                                                                value={ktp}
                                                                onChange={(e) => {
                                                                    setKtp(e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            Nama
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input 
                                                            disabled
                                                                className="form-control-alternative"
                                                                type="text"
                                                                name="barcode"
                                                                placeholder="Masukan Nama"
                                                                value={name}
                                                                onChange={(e) => {
                                                                    setName(e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            Kelahiran
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input 
                                                            disabled
                                                                className="form-control-alternative"
                                                                type="text"
                                                                
                                                                placeholder="Masukan Kelahiran"
                                                                value={kelahiran}
                                                                onChange={(e) => {
                                                                    setKelahiran(e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Col
                                                        for="exampleEmail"
                                                        sm={4}
                                                        >
                                                        Jenis Kelamin
                                                        </Col>
                                                        <Col sm={7}>
                                                            <div style={{ display: "flex" }}>
                                                                <div className="custom-control custom-radio mb-4">
                                                                    <Input 
                                                                    disabled
                                                                    className="custom-control-input"
                                                                    id="customRadio10"
                                                                    name="custom-radio-4"
                                                                    type="radio"
                                                                    value={1}
                                                                    checked={gender === 1}
                                                                    onChange={() => setGender(1)}
                                                                    />
                                                                    <Label
                                                                    className="custom-control-label"
                                                                    htmlFor="customRadio10"
                                                                    >
                                                                    </Label>
                                                                    <b>PRIA</b>
                                                                </div>
                                                                <div
                                                                    className="custom-control custom-radio mb-4"
                                                                    style={{ marginLeft: "20px" }}
                                                                >
                                                                    <Input 
                                                                    disabled
                                                                    className="custom-control-input"
                                                                    id="customRadio11"
                                                                    name="custom-radio-4"
                                                                    type="radio"
                                                                    value={2}
                                                                    checked={gender === 2}
                                                                    onChange={() => setGender(2)}
                                                                    />
                                                                    <Label
                                                                    className="custom-control-label"
                                                                    htmlFor="customRadio11"
                                                                    >
                                                                    </Label>
                                                                    <b>WANITA</b>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                    <Label
                                                    className="form-control-label"
                                                    for="exampleEmail" sm={4}>
                                                        Status Perkawinan
                                                    </Label>
                                                    <Col sm={7}>
                                                        <Input
                                                        disabled
                                                        autoComplete="off"
                                                            className="form-control-alternative"
                                                            type="select"
                                                            
                                                            placeholder="Masukan Status"
                                                            value={statuskk}
                                                            onChange={(e) => {
                                                                setStatusKK(e.target.value);
                                                            }}>
                                                            <option value="" selected >Pilih Status Perkawinan</option>
                                                            <option value="Belum Kawin">Belum Kawin</option>
                                                            <option value="Kawin">Kawin</option>
                                                            <option value="Duda">Duda</option>
                                                            <option value="Janda">Janda</option>
                                                        </Input>
                                                    </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            Phone
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input 
                                                            disabled
                                                                className="form-control-alternative"
                                                                type="text"
                                                                
                                                                placeholder="Masukan Telepone"
                                                                value={telepon}
                                                                onChange={(e) => {
                                                                    setTelepon(e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label
className="form-control-label"

                                                            for="exampleEmail"
                                                            sm={4}
                                                        >
                                                            Provinsi
                                                            
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                            disabled
                                                            autoComplete="off"
                                                                className="form-control-alternative"
                                                                type="select"
                                                                value={province}
                                                                onChange={(e) => {
                                                                setProvince(e.target.value);
                                                                getKota(e.target.value);
                                                                }}
                                                            >
                                                                <option>Pilih Provinsi</option>
                                                                {
                                                                provinces.map((a, key) => {
                                                                    return <option key={key} value={a.id}>{a.name}</option>
                                                                })
                                                                }
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label
className="form-control-label"

                                                            for="exampleEmail"
                                                            sm={4}
                                                        >
                                                            Kota
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                            disabled
                                                            autoComplete="off"
                                                                className="form-control-alternative"
                                                                name="function"
                                                                type="select"
                                                                value={city}
                                                                onChange={(e) => {
                                                                setCity(e.target.value);
                                                                }}
                                                            >
                                                                <option>Pilih Kota</option>
                                                                {
                                                                citys.map((b, key) => {
                                                                    return <option key={key} value={b.id}>{b.name}</option>
                                                                })
                                                                }
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label
className="form-control-label"

                                                            for="exampleEmail"
                                                            sm={4}
                                                        >
                                                            Alamat
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                            disabled
                                                            autoComplete="off"
                                                                className="form-control-alternative"
                                                                placeholder="Masukan Alamat"
                                                                Rows="5"
                                                                type="textarea"
                                                                value={alamat}
                                                                onChange={(e) => {
                                                                setAlamat(e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </CardBody>
                                            </Card>
										</Col>
										<Col md="6">
                                            <Card className="bg-secondary shadow">
                                                <CardHeader className="bg-white border-0">
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <h3 >Gambar</h3>
                                                        <div style={{ textAlign: 'right' }}>
                                                        <Link className="btn btn-warning" to={redirectPrefix1 + itemId}>
                                                        <i className="fas fa-pen" />
                                                        </Link>
                                                        </div>
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            &nbsp;
                                                        </Label>
                                                                    <Col sm={7}>
                                                                        {image === "" ?(
                                                                            <img
                                                                            alt="..."
                                                                            src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
                                                                            width="40%"
                                                                            />
                                                                        ):(
                                                                        <img
                                                                            alt="..."
                                                                            src={image}
                                                                            width="40%"
                                                                        />
                                                                        )}
                                                                    </Col>
                                                        </FormGroup>
                                                    </CardBody>
                                                </Card>
                                                <Card className="bg-secondary shadow">
                                                    <CardBody>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                SIM
                                                            </Label>
                                                            <Col sm={7}>
                                                                <Input 
                                                                disabled
                                                                    className="form-control-alternative"
                                                                    type="text"
                                                                    
                                                                    placeholder="Masukan SIM"
                                                                    value={sim}
                                                                    onChange={(e) => {
                                                                        setSim(e.target.value);
                                                                    }}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                No SIM
                                                            </Label>
                                                            <Col sm={7}>
                                                                <Input 
                                                                disabled
                                                                    className="form-control-alternative"
                                                                    type="text"
                                                                    
                                                                    placeholder="Masukan No SIM"
                                                                    value={nosim}
                                                                    onChange={(e) => {
                                                                        setNoSim(e.target.value);
                                                                    }}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                Masa SIM
                                                            </Label>
                                                            <Col sm={7}>
                                                                <Input 
                                                                disabled
                                                                    className="form-control-alternative"
                                                                    type="text"
                                                                    placeholder="Masukan Masa Berlaku SIM"
                                                                    value={masasim}
                                                                    onChange={(e) => {
                                                                        setMasaSim(e.target.value);
                                                                    }}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                Jabatan
                                                            </Label>
                                                            <Col sm={7}>
                                                                <Input
                                                                disabled
                                                                autoComplete="off"
                                                                className="form-control-alternative"
                                                                name="person"
                                                                type="select"
                                                                value={group}
                                                                onChange={(e) => {
                                                                    setGroup(e.target.value);
                                                                }}
                                                                >
                                                                <option value=''>Pilih Jabatan</option>
                                                                {groups.map((warehouse2, key) => {
                                                                    return (
                                                                        <option key={key} value={warehouse2.id}>
                                                                        {warehouse2.description}
                                                                        </option>
                                                                    );
                                                                    })}
                                                                </Input>
                                                            </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label
                                                                className="form-control-label"
                                                                for="exampleEmail" sm={4}>
                                                                    Unit
                                                                </Label>
                                                                <Col sm={7}>
                                                                    <Input
                                                                    disabled
                                                                    autoComplete="off"
                                                                    className="form-control-alternative"
                                                                    name="person"
                                                                    type="select"
                                                                    value={unit}
                                                                    onChange={(e) => {
                                                                        setUnit(e.target.value);
                                                                    }}
                                                                    >
                                                                    <option value=''>Pilih Unit</option>
                                                                    {units.map((warehouse2, key) => {
                                                                        return (
                                                                            <option key={key} value={warehouse2.id}>
                                                                            {warehouse2.description}
                                                                            </option>
                                                                        );
                                                                        })}
                                                                    </Input>
                                                                </Col>
                                                            </FormGroup>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                Gaji
                                                            </Label>
                                                            <Col sm={7}>
                                                                <CurrencyInput
                                                                    disabled
                                                                    id="validation-example-2-field"
                                                                    placeholder="Rp0"
                                                                    allowDecimals={false}
                                                                    value={gaji}
                                                                    onValueChange={priceGaji}
                                                                    className={`form-control`}
                                                                    prefix={'Rp'}
                                                                    step={10}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                Tanggal Rekrut
                                                            </Label>
                                                            <Col sm={7}>
                                                                <Input 
                                                                disabled
                                                                    className="form-control-alternative"
                                                                    type="text"
                                                                    name="itemCode"
                                                                    placeholder="Tanggal Rekrut"
                                                                    value={awal}
                                                                    onChange={(e) => {
                                                                        setAwal(e.target.value);
                                                                    }}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                Tanggal Resign
                                                            </Label>
                                                            <Col sm={7}>
                                                                <Input 
                                                                disabled
                                                                    className="form-control-alternative"
                                                                    type="text"
                                                                    name="barcode"
                                                                    placeholder="Tanggal Resign"
                                                                    value={resign}
                                                                    onChange={(e) => {
                                                                        setResign(e.target.value);
                                                                    }}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                </CardBody>
                                            </Card>
										</Col>
									</Row>
                                
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <h3>DATA SAUDARA</h3>
                                    </CardHeader>
                                    <Row md="12">
                                        <Col md="6">
                                            <CardBody>
                                                <FormGroup row>
                                                    <Label
                                                    className="form-control-label"
                                                    for="exampleEmail" sm={4}>
                                                        Nama 
                                                    </Label>
                                                    <Col sm={7}>
                                                        <Input 
                                                        disabled
                                                            className="form-control-alternative"
                                                            type="text"
                                                            
                                                            placeholder="Masukan Nama Lengkap"
                                                            value={namakk}
                                                            onChange={(e) => {
                                                                setNamaKK(e.target.value);
                                                            }}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label
                                                    className="form-control-label"
                                                    for="exampleEmail" sm={4}>
                                                        Alamat
                                                    </Label>
                                                    <Col sm={7}>
                                                        <Input 
                                                        disabled
                                                            className="form-control-alternative"
                                                            type="text"
                                                            
                                                            placeholder="Masukan Alamat"
                                                            value={alamatkk}
                                                            onChange={(e) => {
                                                                setAlamatKK(e.target.value);
                                                            }}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label
className="form-control-label"

                                                    for="exampleEmail"
                                                    sm={4}
                                                    >
                                                    Jenis Kelamin
                                                    </Label>
                                                    <Col sm={7}>
                                                        <div style={{ display: "flex" }}>
                                                            <div className="custom-control custom-radio mb-3">
                                                                <Input 
                                                                disabled
                                                                className="custom-control-input"
                                                                id="customRadio13"
                                                                name="custom-radio-5"
                                                                type="radio"
                                                                value={1}
                                                                checked={genderkk === 1}
                                                                onChange={() => setGenderKK(1)}
                                                                />
                                                                <Label
                                                                className="custom-control-label"
                                                                htmlFor="customRadio13"
                                                                >
                                                                
                                                                </Label>
                                                                <b>PRIA</b>
                                                            </div>
                                                            <div
                                                                className="custom-control custom-radio mb-3"
                                                                style={{ marginLeft: "20px" }}
                                                            >
                                                                <Input 
                                                                disabled
                                                                className="custom-control-input"
                                                                id="customRadio14"
                                                                name="custom-radio-5"
                                                                type="radio"
                                                                value={2}
                                                                checked={genderkk === 2}
                                                                onChange={() => setGenderKK(2)}
                                                                />
                                                                <Label
                                                                className="custom-control-label"
                                                                htmlFor="customRadio14"
                                                                >
                                                                </Label>
                                                                <b>WANITA</b>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </FormGroup>
                                            </CardBody>
                                        </Col>
                                        <Col md="6">
                                            <CardBody>
                                                <FormGroup row>
                                                    <Label
                                                    className="form-control-label"
                                                    for="exampleEmail" sm={4}>
                                                        Phone
                                                    </Label>
                                                    <Col sm={7}>
                                                        <Input 
                                                        disabled
                                                            className="form-control-alternative"
                                                            type="text"
                                                            
                                                            placeholder="Masukan Telepone"
                                                            value={teleponkk}
                                                            onChange={(e) => {
                                                                setTeleponKK(e.target.value);
                                                            }}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label
                                                    className="form-control-label"
                                                    for="exampleEmail" sm={4}>
                                                        Hubungan
                                                    </Label>
                                                    <Col sm={7}>
                                                        <Input 
                                                        disabled
                                                            className="form-control-alternative"
                                                            type="text"
                                                            
                                                            placeholder="Masukan Hubungan"
                                                            value={hubungankk}
                                                            onChange={(e) => {
                                                                setHubunganKK(e.target.value);
                                                            }}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </CardBody>
                                        </Col>
                                    </Row>
                                </Card>
						</div>
					</Row>
				</Container>
                <Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>
            Kembali
          </Button>
        </ModalBody>
      </Modal> 
			</div>
		</>
	);
}
