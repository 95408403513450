/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
	Col,
	Button,
  Badge,
	Table,
	Container,
    Modal, ModalHeader, ModalBody

} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment"
import Halaman404 from "views/404";

export default function PromoIndexProduk() {
    const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle1 = () => setModal1(!modal1);
    const [alldata,setAlldata] = useState("")
	const [modal1, setModal1] = useState(false);
  const redirectPrefix = `/admin/promo/promo-produk/edit/`;
	const [page, setPage] = useState(1);
	const [perPage, setPerpage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [totalItem, setTotalItem] = useState(0);
	const [currentSort, setCurrentSort] = useState("");
  const [itemId, setItemId] = useState(1);
  const [allItem, setAllItem] = useState([]);
  const [isSearchShow, setIsSearchShow] = useState(false);
  const headers = { Authorization: `Bearer ${token}` };
  const token = localStorage.token;
  let history = useHistory();
  const [isLoading,setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
    const [subCategory, setSubCategory] = useState("");
    const [subCategories, setSubCategories] = useState([]);
    const [categoryId, setCategoryId] = useState("");
    const [subCategoryId, setSubCategoryId] = useState("");
    const [nameFunction, setNameFunction] = useState("")
    const [nameFunctions, setNameFunctions] = useState([]);
    const [nameSubFunction, setNameSubFunction] = useState("");
    const [nameSubFunctions, setNameSubFunctions] = useState([]);
    const [merek, setMerek] = useState("");
    const [allMerek, setAllMerek] = useState([]);
    const [submerek, setSubMerek] = useState("");
    const [allSubMerek, setAllSubMerek] = useState([]);
  const [namapromo,setNamaPromo] = useState("");
  const [keterangan,setKeterangan] = useState("");
  const [uniontype,setUnionType] = useState("");
  const [senin,setSenin] = useState(2);
  const [selasa,setSelasa] = useState(2);
  const [rabu,setRabu] = useState(2);
  const [kamis,setKamis] = useState(2);
  const [jumat,setJumat] = useState(2);
  const [sabtu,setSabtu] = useState(2);
  const [minggu,setMinggu] = useState(2);
  const [januari,setJanuari] = useState(2);
  const [februari,setFebruari] = useState(2);
  const [maret,setMaret] = useState(2);
  const [april,setApril] = useState(2);
  const [mei,setMei] = useState(2);
  const [juni,setJuni] = useState(2);
  const [juli,setJuli] = useState(2);
  const [agustus,setAgustus] = useState(2);
  const [september,setSeptember] = useState(2);
  const [oktober,setOktober] = useState(2);
  const [november,setNovember] = useState(2);
  const [desember,setDesember] = useState(2);
  const [warehouse,setWarehouse] = useState([]);
  const [items,setItems] = useState([]);
  const [warehouses,setWarehouses] = useState([]);
  const [pic,setPic]=useState("")
  const [karyawans,setKaryawans] = useState([]);
  const [start,setStart] = useState("");
  const [end,setEnd] = useState("");
  const [status,setStatus] = useState(2);
  const [savedItems1,setSavedItems1] = useState([]);
  const [modal,setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [isSearchShowww,setIsSearchShowww] = useState(false);
  const [allItemmm,setAllItemmm] = useState([]);
  const [queryyy,setQueryyy] = useState("");
  const [qtyTotal1,setTotalQty1] = useState(0);
  const [budgetawal,setBudgetAwal] = useState("");
  const [promoglobalpersen,setPromoGlobalPersen]=useState("")
  const [promoglobalnominal,setPromoGlobalNominal]=useState("")
  const [category,setCategory] = useState("")
//   const [alert,setAlert] = React.useState(null);
  const [kondisi,setKondisi] = useState("")
  const [query, setQuery] = useState(null);
  const [usernameuserss, setUsernameUserss] = useState("");
  const [codepromo, setCodePromo] = useState("");
  const [type, setType] = useState("");
  const [globaltype, setGlobalType] = useState("");
  const [potonganperqty, setPotonganPerQty] = useState("");
  const [budgetqtypromo, setBudgetQtyPromo] = useState(0);
  const allInfo = JSON.parse(localStorage.allinfo);
  const promoToko = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Promo Toko").map((p) => p.read_access));

	useEffect(() => {
		getItems();
	},[page]);


	//cari item
	useEffect(() => {
		const getData = setTimeout(async() => {
			if(query != ""){
				axios
				.post(`${process.env.REACT_APP_API_BASE_URL}/promo/page`, { promo_name: query , type: 3,  },{ headers })
				.then((res) => {
					setAllItem(res.data);
					setIsSearchShow(true);
					setLoading(false);
				})
				.catch(function (error) {

				});
			}else{
				// getItems();
			}
		  }, 100)
		  return () => clearTimeout(getData)
	}, [query]);

	//get list item berdasarkan warehouse
	const getItems = async () => {
		setLoading(true);
		let filter = {
			page: page,
			per_page: 1,
            type:3
		};
		const data = filter;
		const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/promo/page`, data, { headers });
		if(res.data.response.id !== null){
			getById(res.data.response[0].id);
			setItemId(res.data.response[0].id);
			setTotalPages(res.data.total_page);
		}
	};

	  const getById = (id) => {
		setLoading(true)
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/promo/get/poin-tiga/${id}`,
	        { headers }
	      )
	      .then((data) => {
            setLoading(false)
            setBudgetQtyPromo(data.data.response.budget_qty_promo)
            getCategory(data.data.response.kategori_id);
            setCategory(data.data.response.kategori_id);
            setSubCategory(data.data.response.subkategori_id);
            setNameFunction(data.data.response.fungsi_id);                                                                                                                                         
            setNameSubFunction(data.data.response.subfungsi_id);
            setMerek(data.data.response.merek_id);
            setSubMerek(data.data.response.submerek_id);
            setBudgetAwal(data.data.response.budget_awal);
            setPotonganPerQty(data.data.response.potongan_per_qty);
            setKondisi(data.data.response.conds);
            setUsernameUserss(data.data.response.username);
            setCodePromo(data.data.response.promo_code);
            setNamaPromo(data.data.response.promo_name);
            setPromoGlobalPersen(data.data.response.promo_global_persen)
            setPromoGlobalNominal(data.data.response.promo_global_nominal)
            setKeterangan(data.data.response.keterangan)
            setType(data.data.response.type)
            setGlobalType(data.data.response.global_type)
            setUnionType(data.data.response.union_type)
            setSenin(data.data.response.senin)
            setSelasa(data.data.response.selasa)
            setRabu(data.data.response.rabu)
            setKamis(data.data.response.kamis)
            setJumat(data.data.response.jumat)
            setSabtu(data.data.response.sabtu)
            setMinggu(data.data.response.minggu)
            setJanuari(data.data.response.januari)
            setFebruari(data.data.response.februari)
            setMaret(data.data.response.maret)
            setApril(data.data.response.april)
            setMei(data.data.response.mei)
            setJuni(data.data.response.juni)
            setJuli(data.data.response.juli)
            setAgustus(data.data.response.agustus)
            setSeptember(data.data.response.september)
            setOktober(data.data.response.oktober)
            setNovember(data.data.response.november)
            setDesember(data.data.response.desember)
            setStart(moment(data.data.response.start_date, "YYYY/MM/DD").format("YYYY-MM-DD"));
            setEnd(moment(data.data.response.end_date, "YYYY/MM/DD").format("YYYY-MM-DD"));
            setStatus(data.data.response.active_flag)
            setWarehouse(data.data.response.warehouse)
            setItems(data.data.response.item)
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

      const getCategory = () => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/item-kategori/list`, {
				headers,
			})
			.then((data) => {
				setCategories(data.data.response);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getSubCategory = (id) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/sub-kategori/list?kategori_id=${id}`, { headers
		})
		.then(data => {
			setSubCategories(data.data.response);
		})
		  .catch(function (error) {
			console.log(error)
		  })
	  }
	
	const getFunction = (id) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/item-function/list?kategori_id=${categoryId}&sub_kategori_id=${id}`, { headers
		})
		.then(data => {
		  setNameFunctions(data.data.response);
		})
		  .catch(function (error) {
			console.log(error)
		  })
	  }

	const getSubFunction = (id) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/sub-function/list?kategori_id=${categoryId}&sub_kategori_id=${subCategoryId}&function_id=${id}`, { headers
		})
		.then(data => {
			setNameSubFunctions(data.data.response);
		})
		  .catch(function (error) {
			console.log(error)
		  })
	  }

	const getMerek = () => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/merek/list`, { headers })
			.then((data) => {
				setAllMerek(data.data.response);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getSubMerek = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/sub-merek/list?merek_id=${id}`, { headers })
			.then((data) => {
				setAllSubMerek(data.data.response);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

		let paginationOption = {
		page: page,
		alwaysShowAllBtns: true,
		override: true,
		showTotal: true,
		withFirstAndLast: false,
		sizePerPage: perPage,
		// totalSize: totalItem,
		onPageChange: (page) => {
			updateDataTable(page, perPage, currentSort);
		},
		sizePerPageRenderer: () => (
			<div className="dataTables_length" id="datatable-basic_length">
				<label
                className="form-control-label"
                >
					Show{" "}
					{
						<select
							name="datatable-basic_length"
							aria-controls="datatable-basic"
							className="form-control form-control-sm"
							onChange={(e) => {
								updateDataTable(page, e.target.value, currentSort);
							}}>
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="25">25</option>
							<option value="50">50</option>
						</select>
					}{" "}
					entries.
				</label>
			</div>
		),
	};

		const updateDataTable = (page, perPage, sort) => {
		setPage(page);
		setPerpage(perPage);
		setRowIndex((page - 1) * perPage);
		setCurrentSort(sort);
	};

		const handleTableChange = (type, { sortField, sortOrder }) => {
		if (type === "sort") {
			let sort = `${sortField} ${sortOrder}`;
			updateDataTable(page, perPage, sort);
		}
	};

		const searchShow = (item) => {
		getById(item.id);
		// getPenawaranPo(item.id)
		setIsSearchShow(false);
	};

	const formatRupiah = (money) => {
		return new Intl.NumberFormat('id-ID',
			{ style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
		).format(money);
	  }

      const getDataLog = (id) => {
		let filter = { 
			transaction : id,
			features : "MASTER10",
			warehouse_id : 0
		}
		const data = filter;
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
			setDataLog(data.data.response);
			setModal1(!modal1);
	
		})
		  .catch(function (error) {
			setGagalAlert(error.response.data.message);
		  })
	  }
	
	  const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
		);
		};
		
		const hideAlert = () => {
		  setAlert(null);
		};


	return (
		<>
        {alert}
            {promoToko && promoToko === "1" ? (
      <div>
				<SimpleHeader name="Promo Produk" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
							{/* <Form onSubmit={handleSubmit}> */}
								{/* <CardBody> */}
								{/* Button card */}
								<Card className="position-sticky boxShadow" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
											<Col md="5 d-lg-block d-none">
												<Button onClick={() => setPage(1)} disabled={page === 1} color="danger" type="button">
													First
												</Button>
												<Button onClick={() => setPage((page) => page - 1)} disabled={page === 1} color="success" type="button">
												<i className="fa fa-angle-left" /> Prev
												</Button>
												<Button onClick={() => setPage((page) => page + 1)} disabled={page >= totalPages} color="success" type="button">
													Next <i className="fa fa-angle-right" />
												</Button>
												<Button onClick={() => setPage(totalPages)} disabled={page == totalPages} color="warning" type="button">
													End
												</Button>
											</Col>
											<Col md="3">
												<FormGroup row>
													<Col sm={10}>
														<Input
															placeholder="Search promo"
															type="search"
															value={query}
															onChange={(e) => setQuery(e.target.value)}
														/>
													</Col>
												</FormGroup>
											</Col>
											<Col md="4 d-lg-block d-none">
												<div style={{ textAlign: "right" }}>
                                                <Button color="warning" onClick={() => (getDataLog(itemId))}>
												<i className="fa fa-info-circle" aria-hidden="true"></i>
												</Button>
                                                    {kondisi > 3 ?(
																""
															):(
                                                                <Link className="btn btn-info" to={redirectPrefix + itemId}
                                                                >
                                                                    <i className="fas fa-user-edit" /> Edit
                                                                </Link>
                                                            )
                                                        }
													<Link className="btn btn-danger" to="/admin/promo/promo-produk/create">
                                                        <i className="fas fa-plus" /> Tambah
													</Link>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
									{isSearchShow && query && (
										<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
											<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
												<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
											</div>
											<span className="text-center mb-3">
												<b>Pencarian berdasarkan:</b> {query}
											</span>
											{allItem?.response ? (
												allItem.response.map((item) => (
													<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" onClick={() => searchShow(item)}style={{minHeight: 'auto'}}>
														<div className=" d-flex align-items-center">
															<div className="ml-3">
																<div>
																	<b>Nama</b> {item.promo_name}
																</div>
															</div>
														</div>
													</CardBody>
												))
											) : (
												<div className="text-center mb-3 text-danger">Item "{query}" tidak ada bosku!</div>
											)}
										</Card>
									)}
								<CardBody>
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <h3>Promo</h3>
                                                <div style={{ textAlign: 'right' }}>
                                                    <h1>
                                                    <Badge color="success">
                                                        {globaltype}
                                                    </Badge>
                                                    </h1>
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <Row md="12">
                                            <Col md="6">
                                                <CardBody>
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            Kode Promo
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                                disabled
                                                                autoComplete="off"    
                                                                className="form-control-alternative"
                                                                type="text"
                                                                name="barname"
                                                                placeholder="Masukan Kode Promo"
                                                                value={codepromo}
                                                                onChange={(e) => {
                                                                    setCodePromo(e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            Nama Promo
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                            disabled
                                                                autoComplete="off"    
                                                                className="form-control-alternative"
                                                                type="text"
                                                                name="barname"
                                                                placeholder="Masukan Nama Promo"
                                                                value={namapromo}
                                                                onChange={(e) => {
                                                                    setNamaPromo(e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                        <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            Keterangan
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                            disabled
                                                                autoComplete="off"
                                                                className="form-control-alternative"
                                                                type="textarea"
                                                                rows ="5"
                                                                placeholder="Masukan Keterangan"
                                                                value={keterangan}
                                                                onChange={(e) => {
                                                                    setKeterangan(e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </CardBody>
                                            </Col>
                                            <Col md="6">
                                                <CardBody>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                Budget
                                                            </Label>
                                                            <Col sm={7}>
                                                                <Input
                                                                disabled
                                                                autoComplete="off"
                                                                    className="form-control-alternative"
                                                                    type="number"
                                                                    placeholder="Rp.0"
                                                                    value={budgetawal}
                                                                    onChange={(e) => {
                                                                        setBudgetAwal(e.target.value);
                                                                    }}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                Batas Promo
                                                            </Label>
                                                            <Col sm={3}>
                                                                <Input
                                                                disabled
                                                                    autoComplete="off"
                                                                    className="form-control-alternative"
                                                                    type="number"
                                                                    placeholder="Masukan Jumlah Item "
                                                                    value={budgetqtypromo}
                                                                    onChange={(e) => {
                                                                        setBudgetQtyPromo(e.target.value);
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col sm={5}>
                                                                <Label
                                                                className="form-control-label"
                                                                for="exampleEmail" sm={5}>
                                                                    QTY
                                                                </Label>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                Potongan
                                                            </Label>
                                                            <Col sm={3}>
                                                                <Input
                                                                    disabled
                                                                    autoComplete="off"
                                                                    className="form-control-alternative"
                                                                    type="number"
                                                                    placeholder="Rp.0"
                                                                    value={potonganperqty}
                                                                    onChange={(e) => {
                                                                        setPotonganPerQty(e.target.value);
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col sm={5}>
                                                                <Label
                                                                className="form-control-label"
                                                                for="exampleEmail" sm={5}>
                                                                    / Qty
                                                                </Label>
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                Tipe Promo
                                                            </Label>
                                                            <Col sm={7}>
                                                                <Input
                                                                disabled
                                                                autoComplete="off"
                                                                    className="form-control-alternative"
                                                                    name="Supplier"
                                                                    type="text"
                                                                    placeholder="Masukan Type"
                                                                    value={uniontype}
                                                                    onChange={(e) => {
                                                                        setUnionType(e.target.value);
                                                                    }} />
                                                                {/* <option value="" disabled selected hidden> Tipe Promo</option>
                                                                <option value="1">Gabung</option>
                                                                <option value="2">Tidak Gabung</option>
                                                                </Input> */}
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                        Start Date
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                            disabled
                                                                autoComplete="off"
                                                                className="form-control-alternative"
                                                                type="date"
                                                                
                                                                placeholder="Masukan Tanggal"
                                                                value={start}
                                                                onChange={(e) => {
                                                                    setStart(e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                        End Date
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                            disabled
                                                                autoComplete="off"
                                                                className="form-control-alternative"
                                                                type="date"
                                                                placeholder="Masukan Tanggal"
                                                                value={end}
                                                                onChange={(e) => {
                                                                    setEnd(e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                    <Col
                                                            for="exampleEmail"
                                                            sm={4}
                                                        >
                                                            <Label
                                                            className="form-control-label"
                                                            >
                                                                Status
                                                            </Label>
                                                        </Col>
                                                        <Col sm={7}>
                                                                {status === 1 ?  
                                                                <h2>
                                                                <Badge color="success">
                                                                    AKTIF
                                                                </Badge>
                                                                </h2> :
                                                                <h2>  
                                                                <Badge color="danger">
                                                                    TIDAK AKTIF
                                                                </Badge>
                                                                </h2>}
                                                        </Col>
                                                    </FormGroup>
                                                </CardBody>
                                            </Col>
                                        </Row>
                                    </Card>
                                    {/* kategori */}
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            <h3>KATEGORI</h3>
                                        </CardHeader>
                                        <CardBody>
                                            <Row md="12">
                                                <Col md="6">
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            Kategori
                                                        </Label>
                                                        <Col sm={5}>
                                                            <Input
                                                            disabled
                                                            autoComplete="off"
                                                                className="form-control-alternative"
                                                                name="kategory"
                                                                type="text"
                                                                value={category}
                                                                onChange={(e) => {
                                                                    setCategory(e.target.value);
                                                                    setCategoryId(e.target.value);
                                                                    getSubCategory(e.target.value);
                                                                }}>
                                                                <option value="">Pilih Kategori</option>
                                                                {categories.map((category, key) => {
                                                                    return (
                                                                        <option key={key} value={category.id}>
                                                                            {category.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            Sub Kategori
                                                        </Label>
                                                        <Col sm={5}>
                                                            <Input
                                                            disabled
                                                            autoComplete="off"
                                                                className="form-control-alternative"
                                                                name="subKategory"
                                                                type="text"
                                                                value={subCategory}
                                                                onChange={(e) => {
                                                                    setSubCategory(e.target.value);
                                                                    setSubCategoryId(e.target.value);
                                                                    getFunction(e.target.value)
                                                                }}>
                                                                <option value="">Pilih Sub Kategori</option>
                                                                {subCategories.map((category, key) => {
                                                                    return (
                                                                        <option key={key} value={category.id}>
                                                                            {category.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            Function
                                                        </Label>
                                                        <Col sm={5}>
                                                            <Input
                                                            disabled
                                                            autoComplete="off"
                                                                className="form-control-alternative"
                                                                name="function"
                                                                type="text"
                                                                value={nameFunction}
                                                                onChange={(e) => {
                                                                    setNameFunction(e.target.value);
                                                                    // setFunctionId(e.target.value);
                                                                    getSubFunction(e.target.value)
                                                                }}>
                                                                <option value="">Pilih Function</option>
                                                                {nameFunctions.map((nameFunction, key) => {
                                                                    return (
                                                                        <option key={key} value={nameFunction.id}>
                                                                            {nameFunction.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            Sub Function
                                                        </Label>
                                                        <Col sm={5}>
                                                            <Input
                                                            disabled
                                                            autoComplete="off"
                                                                className="form-control-alternative"
                                                                name="subFunction"
                                                                type="text"
                                                                value={nameSubFunction}
                                                                onChange={(e) => {
                                                                    setNameSubFunction(e.target.value);
                                                                    // setSubFunctionId(e.target.value);
                                                                }}>
                                                                <option value="">Pilih Sub Function</option>
                                                                {nameSubFunctions.map((nameFunction, key) => {
                                                                    return (
                                                                        <option key={key} value={nameFunction.id}>
                                                                            {nameFunction.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col md="6">
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            Merek
                                                        </Label>
                                                        <Col sm={5}>
                                                            <Input
                                                            disabled
                                                            autoComplete="off"
                                                                className="form-control-alternative"
                                                                name="merek"
                                                                type="text"
                                                                value={merek}
                                                                onChange={(e) => {
                                                                    setMerek(e.target.value);
                                                                    // getSubMerek(e.target.value);
                                                                }}>
                                                                <option value="">Pilih Merek</option>
                                                                {allMerek.map((m, key) => {
                                                                    return (
                                                                        <option key={key} value={m.id}>
                                                                            {m.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            Sub Merek
                                                        </Label>
                                                        <Col sm={5}>
                                                            <Input
                                                            disabled
                                                            autoComplete="off"
                                                                className="form-control-alternative"
                                                                name="merek"
                                                                type="text"
                                                                value={submerek}
                                                                onChange={(e) => {
                                                                    setSubMerek(e.target.value);
                                                                }}>
                                                                <option value="">Pilih Sub Merek</option>
                                                                {allSubMerek.map((submerek, key) => {
                                                                    return (
                                                                        <option key={key} value={submerek.id}>
                                                                            {submerek.name}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </Input>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    {/* Hari */}
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <h3>Promo Hari</h3>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                        <Table size="sm" responsive>
                                                <thead>
                                                    <tr>
                                                    <th><center>Senin</center></th>
                                                    <th><center>Selasa</center></th>
                                                    <th><center>Rabu</center></th>
                                                    <th><center>Kamis</center></th>
                                                    <th><center>Jumat</center></th>
                                                    <th><center>Sabtu</center></th>
                                                    <th><center>Minggu</center></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                        <tr>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked={
                                                                        senin == 1 ? true : false
                                                                    }
                                                                    value={senin == 1 ? "2" : "1"}
                                                                    onChange={(e) => {
                                                                            setSenin(e.target.value);
                                                                        }}
                                                                />
                                                                <Label
                                                                className="form-control-label"
                                                                check>
                                                                </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {selasa == 1 ? true : false}
                                                                    value={selasa == 1 ? "2" : "1"}
                                                                    onChange={(e) => setSelasa(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {rabu == 1 ? true : false}
                                                                    value={rabu == 1 ? "2" : "1"}
                                                                    onChange={(e) => setRabu(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {kamis == 1 ? true : false}
                                                                    value={kamis == 1 ? "2" : "1"}
                                                                    onChange={(e) => setKamis(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {jumat == 1 ? true : false}
                                                                    value={jumat == 1 ? "2" : "1"}
                                                                    onChange={(e) => setJumat(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {sabtu == 1 ? true : false}
                                                                    value={sabtu == 1 ? "2" : "1"}
                                                                    onChange={(e) => setSabtu(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {minggu == 1 ? true : false}
                                                                    value={minggu == 1 ? "2" : "1"}
                                                                    onChange={(e) => setMinggu(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        </tr>
                                                </tbody>
                                                </Table>
                                        </CardBody>
                                    </Card>
                                    {/* Bulan */}
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <h3>Promo Bulan</h3>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                        <Table size="sm" responsive>
                                                <thead>
                                                    <tr>
                                                    <th><center>Januari</center></th>
                                                    <th><center>Februari</center></th>
                                                    <th><center>Maret</center></th>
                                                    <th><center>April</center></th>
                                                    <th><center>Mei</center></th>
                                                    <th><center>Juni</center></th>
                                                    <th><center>Juli</center></th>
                                                    <th><center>Agustus</center></th>
                                                    <th><center>September</center></th>
                                                    <th><center>Oktober</center></th>
                                                    <th><center>November</center></th>
                                                    <th><center>Desember</center></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                        <tr>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked={
                                                                        januari == 1 ? true : false
                                                                    }
                                                                    value={januari == 1 ? "2" : "1"}
                                                                    onChange={(e) => {
                                                                            setJanuari(e.target.value);
                                                                        }}
                                                                />
                                                                <Label
                                                                className="form-control-label"
                                                                check>
                                                                </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {februari == 1 ? true : false}
                                                                    value={februari == 1 ? "2" : "1"}
                                                                    onChange={(e) => setFebruari(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {maret == 1 ? true : false}
                                                                    value={maret == 1 ? "2" : "1"}
                                                                    onChange={(e) => setMaret(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {april == 1 ? true : false}
                                                                    value={april == 1 ? "2" : "1"}
                                                                    onChange={(e) => setApril(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {mei == 1 ? true : false}
                                                                    value={mei == 1 ? "2" : "1"}
                                                                    onChange={(e) => setMei(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {juni == 1 ? true : false}
                                                                    value={juni == 1 ? "2" : "1"}
                                                                    onChange={(e) => setJuni(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {juli == 1 ? true : false}
                                                                    value={juli == 1 ? "2" : "1"}
                                                                    onChange={(e) => setJuli(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {agustus == 1 ? true : false}
                                                                    value={agustus == 1 ? "2" : "1"}
                                                                    onChange={(e) => setAgustus(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {september == 1 ? true : false}
                                                                    value={september == 1 ? "2" : "1"}
                                                                    onChange={(e) => setSeptember(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {oktober == 1 ? true : false}
                                                                    value={oktober == 1 ? "2" : "1"}
                                                                    onChange={(e) => setOktober(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {november == 1 ? true : false}
                                                                    value={november == 1 ? "2" : "1"}
                                                                    onChange={(e) => setNovember(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                disabled
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {desember == 1 ? true : false}
                                                                    value={desember == 1 ? "2" : "1"}
                                                                    onChange={(e) => setDesember(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        </tr>
                                                </tbody>
                                                </Table>
                                        </CardBody>
                                    </Card>
                                    <Card>
										<CardHeader>
											<div style={{ display: "flex", justifyContent: "space-between" }}>
												<h3>Cabang</h3>
											</div>
										</CardHeader>
										<CardBody>
											<ToolkitProvider
												rowNumber={rowIndex}
												data={warehouse}
												keyField="id"
												columns={[
													{
														dataField: "no",
														text: "#",
														sort: true,
														page: 1,
														formatter: (cell, row, index) => {
															let currentRow = ++index;
															return currentRow + rowIndex;
														},  
													},
													{
														dataField: "warehouse_code",
														text: "Kode Cabang",
														sort: true,
													},
                          {
														dataField: "warehouse_name",
														text: "Cabang",
														sort: true,
													},
												]}>
												{(props) => (
													<div className="py-4 table-responsive">
														<BootstrapTable
															remote
															{...props.baseProps}
															bootstrap4={true}
															bordered={false}
															hover={true}
															// pagination={paginationFactory({ ...paginationOption })}
															onTableChange={handleTableChange}
														/>
													</div>
												)}
											</ToolkitProvider>
										</CardBody>
									</Card>
                                    {/* item */}
                                    <Card>
										<CardHeader>
											<div style={{ display: "flex", justifyContent: "space-between" }}>
												<h3>Item</h3>
											</div>
										</CardHeader>
										<CardBody>
											<ToolkitProvider
												rowNumber={rowIndex}
												data={items}
												keyField="id"
												columns={[
													{
														dataField: "no",
														text: "#",
														sort: true,
														page: 1,
														formatter: (cell, row, index) => {
															let currentRow = ++index;
															return currentRow + rowIndex;
														},  
													},
													{
														dataField: "item_name",
														text: "Kode Cabang",
														sort: true,
													},
                                                    {
														dataField: "budget_qty",
														text: "Qty",
														sort: true,
                                                        formatter: (value) => (value + "Item")

													},
                                                    {
														dataField: "promo_persen",
														text: "promo %",
														sort: true,
                                                        formatter: (value) => (value + "%")

													},
                                                    {
														dataField: "promo_nominal",
														text: "promo (N)",
														sort: true,
                                                        formatter: (value) => formatRupiah(value)
													},
												]}>
												{(props) => (
													<div className="py-4 table-responsive">
														<BootstrapTable
															remote
															{...props.baseProps}
															bootstrap4={true}
															bordered={false}
															hover={true}
															// pagination={paginationFactory({ ...paginationOption })}
															onTableChange={handleTableChange}
														/>
													</div>
												)}
											</ToolkitProvider>
										</CardBody>
									</Card>
								</CardBody>
							{/* </Form> */}
						</div>
					</Row>
				</Container>
                <Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>
            Kembali
          </Button>
        </ModalBody>
      </Modal> 
			</div>
            ):(
                <Halaman404 />
            )}
		</>
	);
}