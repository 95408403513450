/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CardBody,
  Row,
  Container,
  CardImg,
} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";
import HeaderTotal from 'components/Headers/HeaderTotal';

export default function PermintaanBarangPoCabang() {
    const warehouse = localStorage.warehouse;
    const token = localStorage.token;
    const [totalItem, setTotalItem] = useState(0);
    const [proses, setProses] = useState(0);
    const [tolak, setTolak] = useState(0);
    const [setuju, setSetuju] = useState(0);
    const page = 1;
    const perPage = 10;
    const allInfo = JSON.parse(localStorage.allinfo);
    const ReturSoKasirs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Retur Cashier").map((p) => p.read_access));
    const ReturSoKasir = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Retur Cashier").map((p) => p.read_access));
    const validasiadmin1 = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi Retur Cashier Admin1").map((p) => p.read_access));
    const validasiadmin2 = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi Retur Cashier Admin2").map((p) => p.read_access));
    const validasidirektur = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi Retur Cashier Direktur").map((p) => p.read_access));
    const cetak = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Cetak Retur Cashier").map((p) => p.read_access));

    useEffect(() => {
        gettotal();
        getProses();
        getTolak();
        getSetuju()
      }, []);
    
      // fungsi dari ambil data
      const gettotal = () => {
        let filter = { 
          page: page, 
          per_page: perPage,
          warehouse_id: parseInt(warehouse),
        };
        
        const data = filter;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/cashier-retur/page`, data, {
            headers,
          })
          .then((data) => {
            setTotalItem(data.data.total_item);
          })
          .catch(function (error) {
          });
    }

    const getProses = () => {
        let filter = { 
          page: page, 
          per_page: perPage,
          status_ar1:3,
          status_ar2 :3,
          status_d :3,
          status:2,
          warehouse_id: parseInt(warehouse),
        };
        
        const data = filter;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/cashier-retur/page`, data, {
            headers,
          })
          .then((data) => {
            setProses(data.data.total_item);
          })
          .catch(function (error) {
          });
    }

    const getTolak = () => {
        let filter = { 
          page: page, 
          per_page: perPage,
          status_ar1:5,
          status_ar2 :5,
          status_d :4,
          status:2,
          warehouse_id: parseInt(warehouse),
        };
        
        const data = filter;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/cashier-retur/page`, data, {
            headers,
          })
          .then((data) => {
            setTolak(data.data.total_item);
          })
          .catch(function (error) {
          });
    }

    const getSetuju = () => {
        let filter = { 
          page: page, 
          per_page: perPage,
          status_ar1:5,
          status_ar2 :5,
          status_d :5,
          status:2,
          warehouse_id: parseInt(warehouse),
        };
        
        const data = filter;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/cashier-retur/page`, data, {
            headers,
          })
          .then((data) => {
            setSetuju(data.data.total_item);
          })
          .catch(function (error) {
          });
    }

  return (
    <>
     {ReturSoKasirs && ReturSoKasirs === "1" ? (
      <div>
      <SimpleHeader name="Retur Kasir" parentName="Report" />
      <HeaderTotal
       totalItem = {totalItem}
       proses ={proses}
       tolak={tolak}
       setuju={setuju}

      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <CardBody>
                    <Row>
                        <div className="card-columns">
                        {ReturSoKasir && ReturSoKasir === "1" && (
                            <Card>
                                <Link to="/admin/kasir-retur/page">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/retur-kasir.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {validasiadmin1 && validasiadmin1 === "1" && (
                            <Card>
                                <Link to="/admin/kasir-retur/validasi-manajer-toko">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-manajer-toko.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {/* {validasiadmin2 && validasiadmin2 === "1" && (
                            <Card>
                                <Link to="/admin/kasir-retur/validasi-manajer-office">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-manajer-office.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {validasidirektur && validasidirektur === "1" && (
                            <Card>
                                <Link to="/admin/kasir-retur/validasi-direktur">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-direktur.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )} */}
                        {cetak && cetak === "1" && (
                            <Card>
                                <Link to="/admin/kasir-retur/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        </div>
					</Row>
                </CardBody>
          </div>
        </Row>
      </Container>
      </div>
     ):(
        <Halaman404 />
     )}
    </>
  );
}