import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";
// import * as FileSaver from "file-saver";

const ModalCetakFinanceBkkRetur = ({ open, toggle, data }) => {
    const token = localStorage.token;
    // const username = localStorage.username;
    const [savedItems, setSavedItems] = useState([]);
    // const [download, setDownload] = useState("")
    const [tanggal,setTanggal] = useState("")
    const [creator,setCreator] = useState("")
    const [keterangan1,setKeterangan1] = useState("")
    const [cabang,setCabang] = useState("")
    const [code,setCode] = useState("")
    const [person,setPerson] = useState("")
    const [validator1,setValidator1] = useState("")
    const [validator2,setValidator2] = useState("")
    const [metodepayment,setMetodePayment] = useState("")

  const getById = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/bkk/retur/cetak/${data.id}`, {
        headers,
      })
      .then((data) => {
        setTanggal(data.data.response.bkkretur.transaction_date);
        setCreator(data.data.response.bkkretur.creator);
        setKeterangan1(data.data.response.bkkretur.keterangan1);
        setCabang(data.data.response.bkkretur.warehouse);
        setCode(data.data.response.bkkretur.bkm_code);
        setPerson(data.data.response.bkkretur.customer);
        setValidator1(data.data.response.bkkretur.validator1);
        setValidator2(data.data.response.bkkretur.validator2);
        setMetodePayment(data.data.response.bkkretur.payment_method);
        
      })
      .catch(function (error) {
        console.log(error);
      });
  },[token, data.id]);

  const getItemDataSaved = useCallback(() => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/bkk/retur/cetak/${data.id}`,{ headers }
    
    ).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.list.map(async (data) => {
            stateItem = [...stateItem, {
                tgl_tempo : data.tgl_tempo,
                code_retur : data.code_retur,
                payment_total : data.payment_total,
                keterangan2 : data.keterangan2,
				potongan: data.potongan,
                bayar : data.bayar,
                data: {
                    tgl_tempo : data.tgl_tempo,
                    code_retur : data.code_retur,
                    payment_total : data.payment_total,
                    keterangan2 : data.keterangan2,
                    potongan: data.potongan,
                    bayar : data.bayar,
                },
            }];
        }));

        setSavedItems(stateItem);
    })
},[token, data.id]);

useEffect(() => {
    getById();
    getItemDataSaved();
  }, [getById,getItemDataSaved]);

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }
    

  function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
  
  return (
    <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top:"-15%" }}>
      <ModalHeader toggle={toggle} align="center">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <Button color="info" onClick={() => print()}>Print</Button>
                        {/* <Button color="success" onClick={()=> (downloadExcel())}>Excel</Button> */}
                    </div>
                </div>
      </ModalHeader>
      <ModalBody>
        <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          <div className="col">
            <KopSurat 
            warehouseName={cabang}
            // user={username}
             />
          </div>
          <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                        <Row md="12">
                            <Col md="12">
                                <h3><b><center>Bukti Kas Keluar Retur</center></b></h3>
                            </Col>
                        </Row>
                        <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                        <br></br>
                        <Row md="12">
                            <Col md="5">
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Tanggal</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-4 ">
                                    <b>{tanggal}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Kode</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{code}</b>
                                </div>
                            </div>
                             <div className="row align-items-center mb-1">
                                <div className="col-3">Keterangan</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{keterangan1}</b>
                                </div>
                            </div>
                            </Col>
                            <Col md="1">
                            </Col>
                            <Col md="6">
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Customer</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{person}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Akun</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{metodepayment}</b>
                                </div>
                            </div>
                            </Col>
                        </Row>
                    <CardBody>
                      <br></br>
                      <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th>
                            <b>Retur</b>
                            </th>
                            <th>
                            <b>Tempo</b>
                            </th>
                            <th>
                            <b>Tagihan</b>
                            </th>
                            <th>
                            <b>keterangan</b>
                            </th>
                            <th>
                            <b>Potongan</b>
                            </th>
                            <th>
                            <b>Bayar</b>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            savedItems.map((savedItem, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{savedItem.code_retur}</td>
                                        <td>{savedItem.tgl_tempo}</td>
                                        <td>{formatRupiah(savedItem.payment_total)}</td>
                                        <td>{savedItem.keterangan2}</td>
                                        <td>{formatRupiah(savedItem.potongan)}</td>
                                        <td>{formatRupiah(savedItem.bayar)}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                      </Table>
                    </CardBody>
                    <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                        <center>Terms of Price, delivery & shipping required</center>
                        <br></br>
                                <Row md="20">
                                <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Di Buat</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{creator}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Kepala Finance</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{validator1}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Direktur</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>{validator2}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                                <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                                {/* {download === 0 ? (
                                    <div className="row align-items-center mb-3">
                                    <div className="col-4"><i>Download Original oleh {username}</i></div>
                                    </div>
                                ):(
                                    <div className="row align-items-center mb-3">
                                    <div className="col-4"><i>Di Download</i> {download} Kali Oleh {username}</div>
                                    </div>
                                    )
                                } */}
        </CardBody>
      </ModalBody>
    </Modal>
  );
};

export default ModalCetakFinanceBkkRetur;
