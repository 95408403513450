/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  FormGroup,
  Label,
  Input,
  InputGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem 
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

const CreateSuratJalanSo = () => {
  const [alert, setAlert] = React.useState(null);
  const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState("");
  const [pengiriman, setPengiriman] = useState([]);
  const [keterangan, setKeterangan] = useState("");
  const [savedItems, setSavedItems] = useState([]);
  const [codeso, setCodeSo] = useState("");
  const [isSearchShow, setIsSearchShow] = useState(false);
  const [isSearchShows, setIsSearchShows] = useState(false);
  const headers = { Authorization: `Bearer ${token}`};
  const [allItem, setAllItem] = useState([]);
  const [query, setQuery] = useState("");
  const [querys, setQuerys] = useState("");
  const allInfo = JSON.parse(localStorage.allinfo);
  const suratjalanso = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Surat Jalan SO").map((p) => p.read_access));
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterType, setFilterType]=useState("supplier")

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const handleFilterTypeChange = (type) => {
    setFilterType(type);
  };

  const searchShow = (item) => {
    AmbilDataCok(item.id);
    setIsSearchShow(false);
    setIsSearchShows(false);
  };

  useEffect(() => {
    const getData = setTimeout(async() => {
      if(query != ""){
        axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/sales-order/page`, {  
          page: 1,
          per_page: 10,
          active_flag:1,
          status_ph: 5,
          customer_name: query,
          warehouse_id : parseInt(warehouse),
        },{ headers })
        .then((res) => {
          setAllItem(res.data);
          setIsSearchShow(true);
          
        })
        .catch(function (error) {
          setAllItem(error.response.data.response);
          setIsSearchShow(true);

          // setGagalAlert(error.response.data.message);
        });
      }}, 1000)
      return () => clearTimeout(getData)
  }, [query]);

  useEffect(() => {
    const getData = setTimeout(async() => {
      if(querys != ""){
        axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/sales-order/page`, {  
          page: 1,
          per_page: 10,
          active_flag:1,
          status_ph: 5,
          so_code: querys,
          warehouse_id : parseInt(warehouse),
        },{ headers })
        .then((res) => {
          setAllItem(res.data);
          setIsSearchShows(true);
          
        })
        .catch(function (error) {
          setAllItem(error.response.data.response);

          // setGagalAlert(error.response.data.message);
          setIsSearchShows(true);

        });
      }}, 1000)
      return () => clearTimeout(getData)
  }, [querys]);
  
const AmbilDataCok = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/sales-order/get/${id}`,
        { headers }
      )
      .then((data) => {
        getCustomer(data.data.response.customer_id);
        setCodeSo(data.data.response.so_code);
        setPengiriman(data.data.response.pengiriman);
        setKeterangan(data.data.response.manual_address);
        getItemDataSaved(data.data.response.so_code);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/sales-order/item-by-code`, {

        so_code: id

    },{headers}).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
              item_id: data.item_id,
              item_name:data.item_name,
              item_code : data.item_code,
              satuan : data.satuan,
              qty: data.qty,
              harga: data.harga,
              diskon_nominal: data.diskon_nominal,
              diskon_persen: data.diskon_persen,
                data: {
                    item_id: data.item_id,
                    item_name: data.item_name,
                    qty: data.qty,
                    harga: data.harga,
                    diskon_nominal: data.diskon_nominal,
                    diskon_persen: data.diskon_persen,
                },
            }];
        }));
        setSavedItems(stateItem);
    })
}

  const getCustomer = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/customer/list`,
        { headers }
      )
      .then((data) => {
        setCustomers(data.data.response);
        setCustomer(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit2 = () => {
    hideAlert();
    setTimeout(() => {CreateData()}, 1000);
  };

  function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga: dataItem.harga,
                diskon_nominal : dataItem.diskon_nominal,
                diskon_persen: dataItem.diskon_persen,
            }]);
    let data = {
      warehouse_id : parseInt(warehouse),
      username : username,
      code_so: codeso,
      status_sj: 3,
      customer_id: parseInt(customer),
      pengiriman: parseInt(pengiriman),
      keterangan: keterangan,
      items : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
				setTimeout(() => (history.push("/admin/surat-jalan-so/page")), 1000);

        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
    }
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }

  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };

  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={handleSubmit2}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };
  
    const hideAlert = () => {
      setAlert(null);
    };


  return (
    <>
    {alert}
    {suratjalanso && suratjalanso === "1" ? (
			<div>
    <SimpleHeader name="Buat Surat Jalan Penjualan" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
                <Card className="position-sticky bg-secondary BoxShadow" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
											<Col md="4">
											</Col>
											<Col md="4">
                      <FormGroup>
                      <InputGroup>
                        <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                          <DropdownToggle caret color="primary">
                            {filterType === 'supplier' ? 'Nama Customer' : 'Kode SO'}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => {handleFilterTypeChange('supplier');setIsSearchShows(false)}}>
                              Nama Customer
                            </DropdownItem>
                            <DropdownItem onClick={() => {handleFilterTypeChange('po');setIsSearchShow(false)}}>
                              Kode SO
                            </DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      {filterType === "supplier" ? 
                        <Input
                            placeholder="Search Customer"
                            type="search"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          : 
                          <Input
                            className="form-control"
                            placeholder={filterType === "supplier" ? "Search Customer" : "Search Kode SO"}
                            type="search"
                            value={querys}
                            onChange={(e) => setQuerys(e.target.value)}
                          />
                          }
                        </InputGroup>
                      </FormGroup>
											</Col>
                      <Col md="4">
                      </Col>
										</Row>
									</CardBody>
								</Card>
									{isSearchShow && query && (
										<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
											<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
												<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
											</div>
											<span className="text-center mb-3">
												<b>Pencarian berdasarkan:</b> {query}
											</span>
											{allItem?.response ? (
												allItem.response.map((item) => (
													<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" style={{minHeight: 'auto'}} onClick={() => {searchShow(item); setQuery(''); setIsSearchShow(false);}}>
														<div className=" d-flex align-items-center">
														<div className="ml-3">
																<div>
																	<b>Customer :</b> {item.customer_name}
																</div>
															</div>
                              <div className="ml-3">
																<div>
																	<b>Kode :</b> {item.so_code}
																</div>
															</div>
														</div>
													</CardBody>
												))
											) : (
												<div className="text-center mb-3 text-danger">Customer "{query}" tidak ada bosku!</div>
											)}
										</Card>
									)} 
                  {isSearchShows && querys && (
										<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
											<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
												<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShows(false)}></i>
											</div>
											<span className="text-center mb-3">
												<b>Pencarian berdasarkan:</b> {querys}
											</span>
											{allItem?.response ? (
												allItem.response.map((item) => (
													<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" style={{minHeight: 'auto'}} onClick={() => {searchShow(item); setQuerys(''); setIsSearchShows(false);}}>
														<div className=" d-flex align-items-center">
														<div className="ml-3">
																<div>
																	<b>Customer :</b> {item.customer_name}
																</div>
															</div>
                              <div className="ml-3">
																<div>
																	<b>Kode :</b> {item.so_code}
																</div>
															</div>
														</div>
													</CardBody>
												))
											) : (
												<div className="text-center mb-3 text-danger">SO "{querys}" tidak ada bosku!</div>
											)}
										</Card>
									)} 
              <Card className="bg-secondary shadow">
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                  for="exampleEmail"
                                  sm={3}
                                >
                                  Kode SO
                                </Label>
                                <Col sm={6}>
                                  <Input
                                    className="form-control-alternative"
                                    disabled
                                    placeholder="Masukan Kode SO"
                                    name="customer" 
                                    type="text"
                                    value={codeso}
                                  >
                                  </Input>
                                </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Customer
                              </Label>
                              <Col sm={6}>
                                <Input
                                  className="form-control-alternative"
                                  disabled
                                  name="customer" 
                                  type="select"
                                  value={customer}
                                  onChange={(e) => {
                                    setCustomer(e.target.value);
                                  }}
                                >
                                  <option value=''>Pilih Customer</option>
                                  {customers.map((customer, key) => {
                                      return (
                                        <option key={key} value={customer.id}>
                                          {customer.name}
                                        </option>
                                      );
                                    })}
                                  </Input>
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Pengiriman
                            </Label>
                            <Col sm={6}>
                            <Input
                                className="form-control-alternative"
                                disabled
                                name="Tipe Request"
                                type="select"
                                value={pengiriman}
                                onChange={(e) => {
                                  setPengiriman(e.target.value);
                                }}
                              >
                                <option value="">Pilih Request</option>
                                <option value={1}>Ambil Sendiri</option>
                                <option value={2}>Delivery</option>
                                <option value={3}>Kurir</option>
                              </Input>
                            </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Alamat Kirim
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="textarea"
                                  name="Alamat Kirim"
                                  rows = "7"
                                  placeholder="Masukan Alamat Kirim"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                    <CardBody>
                      <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th><b>Nama Item</b></th>
                            <th><b>Kode Item</b></th>
                            <th><b>Qty</b></th>
                            <th><b>Satuan</b></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            savedItems.map((savedItem, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{savedItem.data.item_name}</td>
                                        <td>{savedItem.item_code}</td>
                                        <td>
                                            {savedItems[key].editingItem ? (
                                                    <Input
                                                        placeholder="qty"
                                                        type="number"
                                                        value={savedItems[key].qty}
                                                        onChange={(e) => {
                                                            setSavedItems([
                                                                ...savedItems.slice(0, key),
                                                                Object.assign({}, savedItems[key], { qty: e.target.value}),
                                                                ...savedItems.slice(key + 1)
                                                            ]);
                                                        }}
                                                    />
                                            ) : (
                                                        <>{savedItem.qty}</>
                                                    )}
                                        </td>
                                        <td>{savedItem.satuan}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                      </Table>
                    </CardBody>
                <CardFooter>
                      
                      <Link className="btn btn-info" to="/admin/surat-jalan-so/page">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={()=>setQuestionAlert()}>
                          Simpan
                      </Button>
                </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}

export default CreateSuratJalanSo;