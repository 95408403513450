/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {Card,Button,Row,Col,CardBody,CardHeader,CardFooter,Table,Container,Form,FormGroup,Label,Input,Modal, ModalHeader, ModalBody} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";
import CurrencyInput from 'react-currency-input-field';

export default function EditPenawaranPoPusat(props)  {
  const token = localStorage.token;
  const [alert, setAlert] = React.useState(null);
  const [reasons, setReasons] = useState([])
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [persons, setPersons] = useState([]);
  const [person, setPerson] = useState("");
  const [durasis, setDurasis] = useState([]);
  const [durasi, setDurasi] = useState("");
  const [paymentmetode,setPaymentMethod] = useState(1);
  const [keterangan, setKeterangan] = useState("");
  const [allJangkaWaktu, setAllJangkaWaktu] = useState([]);
  const [jangkaWaktu,setJangkaWaktu] = useState([]);
  // const [totalPrice, setTotalPrice] = useState(0);
  const [savedItems, setSavedItems] = useState([]);
  const [coderfq, setCodeRfq] = useState([]);
  const [ongkir, setOngkir] = useState(0);
  const [lainnya, setLainnya] = useState(0);
  const [allpajak,setAllPajak] = useState([]);
  const [pajak,setPajak] = useState(1);
  const [ppn, setPPN] = useState(0);
  const [totaldiskon, setTotalDiskon] = useState(0);
  const [grandtotal, setGrandTotal] = useState(0);
  const [diskonglobalnominal, setDiskonGlobalNominal] = useState(0);
  const [diskonglobalpersen, setDiskonGlobalPersen] = useState(0);
  const [isSearchShoww, setIsSearchShoww] = useState(false);
	const [allItemm, setAllItemm] = useState([]);
	const [queryy, setQueryy] = useState("");
  const headers = { Authorization: `Bearer ${token}`};
  const [ongkir1, setOngkir1] = useState(0);
	const [ppnnew, setPPNNEW] = useState(0);
	const [diskonglobal, setDiskonGLobal] = useState(0);
  const [totalPrice1, setTotalPrice1] = useState(0);
  const [diskonglobalnominal1, setDiskonGlobalNominal1] = useState(0)
  const a = 0;
	const b = 0;
	const c = 0;
  const [dp, setDp] = useState(0);
  const [dp1, setDp1] = useState(0);
	const [totaldiskon2, setTotalDiskon2] = useState(0);
	const [totaldiskon3, setTotalDiskon3] = useState(0);
	const [editable, setEditable] = useState(false);
	const [isConfEditableOpen, setIsConfEditableOpen] = useState(false);
	const [confPassEditable, setConfPassEditable] = useState('');
	const [qtyTotal, setTotalQty] = useState(0);
	const [diskontotal, setDiskonTotal] = useState(0);
  const [cabang, setCabang] = useState("");
  const [cabangs, setCabangs] = useState([]);
  const [tanggal, setTanggal] = useState("");
  const [codepo,setCodePo] = useState("");
  const [nameotorisasi, setNameOtorisasi] = useState("");
	const [nameotorisasiError, setNameOtorisasiError] = useState(null);
	const [keterangan5, setKeterangan5] = useState("");
	const [keteranganError, setKeteranganError] = useState(null);
  const [banksupplier, setBankSupplier] = useState("")
  const [banksuppliers, setBankSuppliers] = useState([])
  const allInfo = JSON.parse(localStorage.allinfo);
  const penawaranPOs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub PO Pusat").map((p) => p.read_access));
  
  const validateForm = () => {
		let error = false;
		if (nameotorisasi === "") {
			setNameOtorisasiError("invalid");
		  error = true;
		}
		if (keterangan5 === "") {
			setKeteranganError("invalid");
		  error = true;
		}
		return error;
	  };

	  const handleSubmit4 = (e) => {
		e.preventDefault();
		if (!validateForm()) {
			cekKasir();
		}
	  }

  useEffect(() => {
    getCabang();
    getReason();
  }, []);
  
  const getReason = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
      `${process.env.REACT_APP_API_BASE_URL}/reason/list?type=2`,
      { headers }
      )
      .then((data) => {
      setReasons(data.data.response);
      })
      .catch(function (error) {
      console.log(error);
      });
    };

	  const cekKasir = async () => {
      let data = {
        code_transaction: codepo,
        warehouse_id: parseInt(warehouse),
        username: nameotorisasi,
        password_otorisasi: confPassEditable,
        note : keterangan5
        }
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/password-operasional/po`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
        })
        .then(function (response) {
        setEditable(true);setIsConfEditableOpen(false);setConfPassEditable('');setNameOtorisasi('');setKeterangan5('')
        setSuccessAlert(response.data.message);  
      })
        .catch(function (error) {
        if(error.response.data.status == 400){
          setEditable(false);setIsConfEditableOpen(true);setConfPassEditable('');setNameOtorisasi('');setKeterangan5('')
          setGagalAlert(error.response.data.message);
        }
        });
      }
  

  const getCabang = (id) => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/cabang`, { headers
      })
      .then(data => {
        setCabangs(data.data.response);
        setCabang(id);
      })
        .catch(function (error) {
          console.log(error)
        })
    }

    useEffect(() => {
      setDiskonGlobalNominal1(diskonglobalnominal);
    }, [diskonglobalnominal]);
  
      // diskon bayangan
      useEffect(() => {
      setTotalDiskon(diskonglobalnominal1);
    }, [diskonglobalnominal1]);
  
    useEffect(() => {
      setTotalDiskon3(diskontotal-a);
    }, [diskontotal, a]);
  
     //diskon tampil
     useEffect(() => {
      setTotalDiskon2((totaldiskon)/((ppn/100)+1));
    }, [totaldiskon3, totaldiskon, ppn , a]);
  
    //ongkir tampil
    useEffect(() => {
      setOngkir1(ongkir - b);
    }, [ongkir, b]);

    //uang muka tampil
    useEffect(() => {
      setDp1(dp - c);
    }, [dp, c]);
  
  
    useEffect(() => {
      setTotalPrice1((qtyTotal)/((ppn/100)+1));
    }, [qtyTotal, ppnnew, ppn ]);
  
  
      // diskonglobalnominal dan persen
      useEffect(() => {
        diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
        diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
      }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);
  
      // hasil nominal dari PPN
      useEffect(() => {
        setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + 1)));
    }, [qtyTotal,totaldiskon,ppn]);
  
      // hasil grandtotal
    useEffect(() => {
      setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew - dp1 + ongkir1- a );
    }, [totalPrice1,qtyTotal, totaldiskon2, ppnnew, dp1, ongkir1, a]);


  useEffect(() => {
    const getData = setTimeout(async() => {
        if(queryy != ''){
            axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/items-po`, { item_info: queryy , per_page: 10, warehouse_id: parseInt(cabang)},{ headers })
            .then((res) => {
                setAllItemm(res.data);
                setIsSearchShoww(true);
            })
            .catch(function (error) {
            });
        }else{
      setAllItemm(null);
        }
      }, 500)
      return () => clearTimeout(getData)
  }, [queryy]);


  useEffect(() => {
    calculateTotalQty();
  }, [savedItems]);
  
  const calculateTotalQty = () => {
    const newTotalQty = savedItems.reduce((total, item) => {
      const itemTotal = (parseInt(item.harga) - parseInt(item.diskon_nominal)) * parseInt(item.qty);
      return total + itemTotal;
    }, 0);
    setTotalQty(newTotalQty);
  };
  
  
    const handleEditQty = (index, value) => {
      if (!isNaN(value) && value.length > 0) {
        let updateList = [...savedItems];
        updateList[index] = { ...updateList[index], qty: value };
        setSavedItems(updateList);
      } else {
        return false;
      }
    }
  
  const handleEditHarga = (index, value) => {
    if (!isNaN(value) && value.length > 0) {
      let updateList = [...savedItems];
      updateList[index] = { ...updateList[index], harga: value };
      setSavedItems(updateList);
    } else {
      return false;
    }
  }
  
  const handleEditDiskonNominal = (index, value) => {
    if (!isNaN(value) && value.length > 0) {
      let updateList = [...savedItems];
      let persentasediskonnya = (value / parseInt(updateList[index].harga)) * 100;
      updateList[index] = { ...updateList[index], diskon_nominal: value, diskon_persen: persentasediskonnya };
      setSavedItems(updateList);
    } else {
      return false;
    }
  }
  
  const handleEditDiskonPersen = (index, value) => {
    if (!isNaN(value) && value.length > 0) {
      let updateList = [...savedItems];
      let nominaldiskonnya = (parseInt(updateList[index].harga) * value) / 100;
      updateList[index] = { ...updateList[index], diskon_nominal: nominaldiskonnya, diskon_persen: value };
      setSavedItems(updateList);
    } else {
      return false;
    }
  }
  
  const handleEditNote = (index, value) => {
    let updateList = [...savedItems];
    if (value) {
      updateList[index] = { ...updateList[index], note: value };
      setSavedItems(updateList);
    } else {
      return false;
    }
  }
  
  
  // save
  const saveItemOrUpdate = (item) => {
    let oldobj = savedItems.find(o => o.item_id === item.id);
    if(oldobj===undefined){
      setSavedItems([...savedItems,{
        item_id: item.id,
        item_name: item.item_name,
        item_code:item.item_code,
        satuan : item.satuan,
        barcode: item.barcode,
        qty: 1,
        diskon_nominal : item.diskon_nominal,
        diskon_persen:  item.diskon_persen,
        // harga: item.price,
        harga: 0,
        note : "",
      }]);
      return setTotalQty(qtyTotal + (0 - item.diskon_nominal) * 1)
      // setTotalPrice(totalPrice + (item.price - item.diskon_nominal) * 1),
      // setDiskonTotal(diskontotal + diskonglobalnominal * 1)
    }else{
    let index = savedItems.findIndex(e=>e.item_id===item.id);
    let updateList = savedItems;
    let minTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
    let qtyTotall = parseInt(updateList[index].harga) * parseInt(updateList[index].qty);
    let diskontotall = parseInt(updateList[index].diskon_nominal) * parseInt(updateList[index].qty);
    let qtyupdate = parseInt(updateList[index].qty)+parseInt(1);
    updateList[index] = {...updateList[index], qty: qtyupdate};
    setSavedItems(updateList);
    // let fixPrice = totalPrice - minTotal;
    // let fixQty = qtyTotal - qtyTotall ;
    let fixQty = qtyTotal - minTotal ;
    let fixDiskon = diskontotal - diskontotall ;
    return setTotalQty(fixQty + (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal))  * qtyupdate),
    setDiskonTotal(fixDiskon + parseInt(updateList[index].diskon_nominal) * qtyupdate)
    // setTotalPrice(fixPrice + (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * qtyupdate)
    };
  }

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    {
      setQueryy("");
      setIsSearchShoww("");
      return true
    }
  };

  useEffect(() => {
    getByIds();
}, [pajak]);

const getByIds = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(
      `${process.env.REACT_APP_API_BASE_URL}/pajak/${pajak}`,
      { headers }
    )
    .then((data) => {;
      setPPN(data.data.response.persentase);
    })
    .catch(function (error) {
      console.log(error);
    });
};

useEffect(() => {
  getById();
  getDurasiOperasional();
}, []);

const getById = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(
      `${process.env.REACT_APP_API_BASE_URL}/purchase-order/get/${props.match.params.id}`,
      { headers }
    )
    .then((data) => {
      getItemDataSaved();
      getPerson(data.data.response.person_id);
      getCabang(data.data.response.warehouse_id);
      getPajak(data.data.response.pajak_id);
      getJangkaWaktu(data.data.response.jangka_waktu);
      getDurasiOperasional(data.data.response.duration_limit);
      // getbankSupplier(data.data.response.person_id, data.data.response.account_number);
      // getbankSupplier(data.data.response.person_id);
      setBankSupplier(data.data.response.account_number)
      setCodeRfq(data.data.response.code_rfq);
      setPaymentMethod(data.data.response.payment_method);
      setDp(data.data.response.um_nominal);
      setTotalQty(data.data.response.price_real);
      setDiskonTotal(data.data.response.diskon_total);
      setKeterangan(data.data.response.keterangan);
      setOngkir(data.data.response.ongkir);
      setLainnya(data.data.response.lainnya);
      setDiskonGlobalNominal(data.data.response.diskon_global_nominal);
      setDiskonGlobalPersen(data.data.response.diskon_global_persen);
      setTanggal(data.data.response.transaction_date);
      setCodePo(data.data.response.po_code);
    })
    .catch(function (error) {
      console.log(error);
    });
};

// Update bank suppliers when person changes, but only if it's necessary
// useEffect(() => {
//   if (person && !banksupplier) {
//     getbankSupplier(person);
//   }
// }, [person, banksupplier]);

const getPerson = (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/person/list`, { headers })
    .then((data) => {
      setPersons(data.data.response);
      getbankSupplier(id)
      setPerson(id);
    })
    .catch(function (error) {
      console.log(error);
    });
};

const getbankSupplier = (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-person/${id}`, { headers })
    .then((data) => {
      setBankSuppliers(data.data.response);
      // if (id) {
      //   setBankSupplier(id);
      // } else if (data.data.response.length > 0) {
      //   setBankSupplier(data.data.response[0].account_number);
      // }
    })
    .catch(function (error) {
      console.log(error);
    });
};

  const getPajak = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
			.then((data) => {
				setAllPajak(data.data.response);
				setPajak(id);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

  const getJangkaWaktu = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/jwkredit/list`, { headers })
      .then((data) => {
        setAllJangkaWaktu(data.data.response);
        setJangkaWaktu(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/item`, {

        po_id: props.match.params.id

    }).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.map(async (data) => {
          stateItem = [...stateItem, {
            editing:false,
            item_id: data.item_id,
            item_name:data.item_name,
            item_code: data.item_code,
            qty: data.qty,
            satuan : data.satuan,
            harga: data.harga,
            diskon_persen: data.diskon_persen,
            diskon_nominal: data.diskon_nominal,
            sub_total : data.sub_total,
            note : data.note,
            data: {
                item_name: data.item_name,
                qty: data.qty,
                harga: data.harga,
                diskon_persen: data.diskon_persen,
                diskon_nominal: data.diskon_nominal,
                sub_total : data.sub_total,
                note : data.note,

            },
        }];
    }));
    setSavedItems(stateItem);
})
}

const getDurasiOperasional = (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(
      `${process.env.REACT_APP_API_BASE_URL}/durasi-operasional/list`,
      { headers }
    )
    .then((data) => {
      setDurasis(data.data.response);
      setDurasi(id)
    })
    .catch(function (error) {
      console.log(error);
    });
};
  
  
    const handleSubmit2 = () => {
      hideAlert();
      setTimeout(() => {EditData()}, 1000);
  };
  
  function EditData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga: dataItem.harga,
                diskon_persen: dataItem.diskon_persen,
                diskon_nominal: dataItem.diskon_nominal,
                note : dataItem.note
            }]);
    let data = {
      warehouse_id : parseInt(cabang),
      username : username,
      code_rfq: coderfq,
      person_id: parseInt(person),
      pajak_id: parseInt(pajak),
      type : 1,
      approve:3,
      status_ap: 3,
      status_d: 3,
      um_nominal : dp,
      duration_limit:parseInt(durasi),
      ongkir: parseFloat(ongkir),
      lainnya:parseFloat(lainnya),
      account_number : banksupplier,
      payment_method: parseInt(paymentmetode),
      jangka_waktu:parseInt(jangkaWaktu),
      diskon_global_nominal: parseFloat(diskonglobalnominal),
      diskon_global_persen: parseInt(diskonglobalpersen),
      keterangan: keterangan ,
      transaction_date : moment(tanggal).format("YYYY-MM-DD 00:00:00"),
      items : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/purchase-order/update/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => ( history.push("/admin/pusat/purchase-order/page")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={handleSubmit2}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }

  const deleteItem = (id) => {
    let array = [...savedItems];

    let index = array.findIndex(object => {
      return object.item_id === id;
    });

    if (index !== -1) {
      // setTotalPrice(totalPrice - (array[index].harga - array[index].diskon_nominal) * array[index].qty)  ;
      setTotalQty(qtyTotal - (array[index].harga - array[index].diskon_nominal) * array[index].qty) ;
      setDiskonTotal(diskontotal - array[index].diskon_nominal * array[index].qty);
      array.splice(index, 1);
      setSavedItems(array);
    }
  }

  const HargaDiskonPersen = (e) => {
    const value = e === undefined ? 'undefined' : e;
    setDiskonGlobalPersen(value || ' ');
    setDiskonGLobal("diskonglobalpersen")
    };
  
    const HargaDiskonNominal = (e) => {
      const value = e === undefined ? 'undefined' : e;
      setDiskonGlobalNominal(value || ' ');
      setDiskonGLobal("diskonglobalnominal")
      };
      
      const HargaOngkir = (e) => {
        const value = e === undefined ? 'undefined' : e;
        setOngkir(value || ' ');
        };

        const HargaDp = (e) => {
          const value = e === undefined ? 'undefined' : e;
          setDp(value || ' ');
          };

  return (
    <>
    {alert}
    {penawaranPOs && penawaranPOs === "1" ? (
			<div>
    <SimpleHeader name="Edit Pembelian Barang" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Edit Pembelian Barang</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          {coderfq === "" ? (
                              ""
                            ):(
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                  Kode Rfq
                              </Label>
                              <Col sm={6}>
                                  <Input
                                  disabled
                                  autoComplete="off"
                                  className="form-control-alternative"
                                  name="person"
                                  type="text"
                                  value={coderfq}
                                  onChange={(e) => {
                                      setCodeRfq(e.target.value);
                                  }}
                                  />
                              </Col>
                            </FormGroup>
                            )}
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode PO
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                  type="text"
                                  name="desc"
                                  rows = "4"
                                  placeholder="Masukan Kode Po"
                                  value={codepo}
                                  onChange={(e) => {
                                    setCodePo(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Tanggal
                              </Label>
                              <Col sm={6}>
                                <Input
                                  className="form-control-alternative"
                                  autoComplete="off"
                                  type="date"
                                  placeholder="Masukan Tanggal"
                                  value={tanggal}
                                  onChange={(e) => {
                                    setTanggal(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                  Cabang
                              </Label>
                              <Col sm={6}>
                                  <Input
                                  disabled={coderfq !== ""}
                                  autoComplete="off"
                                  className="form-control-alternative"
                                  name="person"
                                  type="select"
                                  value={cabang}
                                  onChange={(e) => {
                                      setCabang(e.target.value);
                                  }}
                                  >
                                  <option value=''>Pilih Cabang</option>
                                  {cabangs.map((warehouse2, key) => {
                                      return (
                                          <option key={key} value={warehouse2.id}>
                                          {warehouse2.name}
                                          </option>
                                      );
                                      })}
                                  </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Supplier
                              </Label>
                              <Col sm={6}>
                                <Select2
                                      className="form-control-alternative"
                                      defaultValue="1"
                                      value={person}
                                      onChange={(e) => {
                                        setPerson(e.target.value);
                                        getbankSupplier(e.target.value);
                                      }}
                                      options={{
                                        placeholder: "Pilih Supplier"
                                      }}
                                      data={persons.map((person, key) => {
                                        return (
                                        { id: person.id, text: person.person_name, key:key}
                                        );
                                      })}
                                    />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                Bank
                              </Label>
                              <Col sm={6}>
                                <Input
                                  className="form-control-alternative"
                                  type="select"
                                  value={banksupplier}
                                  onChange={(e) => {
                                    setBankSupplier(e.target.value);
                                  }}>
                                  <option value="" disabled selected hidden>Pilih Bank</option>
                                    {banksuppliers.map((supplier, key) => {
                                      return (
                                          <option key={key} value={supplier.account_number}>
                                            {supplier.bank_name} {supplier.account_number} a/n {supplier.account_name} 
                                          </option>
                                      );
                                    })}
                                  </Input>
                              </Col>
													  </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                Diskon
                              </Label>
                              <Col sm={3}>
                                <CurrencyInput
                                  disabled={qtyTotal < 1000}
                                  id="validation-example-2-field"
                                  placeholder="0"
                                  allowDecimals={false}
                                  value={diskonglobalpersen}
                                  onValueChange={HargaDiskonPersen}
                                  className={`form-control`}
                                  suffix={'%'}
                                  step={10}
                                />
                              </Col>
                              <Col sm={3}>
                              <CurrencyInput
                                  disabled={qtyTotal < 1000}
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={diskonglobalnominal}
                                  onValueChange={HargaDiskonNominal}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                PPN
                                <span className="text-danger">*</span>

                              </Label>
                              <Col sm={6}>
                                <Input
                                  className="form-control-alternative"
                                    type="select"
                                    value={pajak}
                                    onChange={(e) => {
                                      setPajak(e.target.value);
                                    }}>
                                      {allpajak.map((warehouse2, key) => {
                                        return (
                                            <option key={key} value={warehouse2.id}>
                                              {warehouse2.keterangan}
                                            </option>
                                        );
                                      })}
                                </Input>
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Durasi PO
                              </Label>
                              <Col sm={4}>
                                <Input
                                className="form-control-alternative"
                                  name="person"
                                  type="select"
                                  value={durasi}
                                  onChange={(e) => {
                                    setDurasi(e.target.value);
                                  }}
                                >
                                  <option value="" disabled selected hidden>Pilih Durasi</option>
                                  {durasis.map((person, key) => {
                                      return (
                                        <option key={key} value={person.durasi}>
                                          {person.durasi}
                                        </option>
                                      );
                                    })}
                                </Input>
                              </Col>
                              <Label
                              className="form-control-label"
                                    for="exampleEmail"
                                    sm={3}
                                >
                                    <b>Hari</b>
                                </Label>
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={4}
                            >
                              Metode Pembayaran
                            </Label>
                            <Col sm={6}>
                            <Input
                            className="form-control-alternative"
                                name="Tipe Po"
                                type="select"
                                value={paymentmetode}
                                onChange={(e) => {
                                    setPaymentMethod(e.target.value);
                                }}
                              >
                                <option value="" disabled selected hidden>Pilih Metode Pembayaran</option>
                                <option value={1}>Lunas</option>
                                <option value={2}>Tempo</option>
                                <option value={5}>Dp/Kredit</option>
                              </Input>
                            </Col>
                            </FormGroup>
                            {paymentmetode >  1  ? (
                              <FormGroup row>
                                <Label
                                className="form-control-label"
                                  for="exampleEmail"
                                  sm={4}
                                >
                                  Jangka Waktu
                                </Label>
                                <Col sm={4}>
                                  <Input
                                    className="form-control-alternative"
                                    type="select"
                                    name="desc"
                                    value={jangkaWaktu}
                                    onChange={(e) => {
                                      setJangkaWaktu(e.target.value);
                                    }}
                                  >
                                  <option value="" disabled selected hidden>Pilih Jangka Waktu</option>
                                  {allJangkaWaktu.map((waktu, key) => {
                                    return (
                                      <option key={key} value={waktu.durasi}>
                                        {waktu.durasi}
                                      </option>
                                    );
                                  })}
                                </Input>
                                </Col> 
                                <Label
                                className="form-control-label" for="exampleEmail" sm={3}>
                                  <b>Hari</b>
                                </Label>
                              </FormGroup>
                            ):(
                              ""
                            )}

                          {paymentmetode === 5 ? 
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Uang Muka
                              </Label>
                              <Col sm={6}>
                              <CurrencyInput
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={dp}
                                  onValueChange={HargaDp}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>                             
                            </FormGroup>
                            : 
                            ""
                            }

                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Ongkir
                              </Label>
                              <Col sm={6}>
                                <CurrencyInput
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={ongkir}
                                    onValueChange={HargaOngkir}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                  />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Keterangan
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                  type="textarea"
                                  name="desc"
                                  rows = "5"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                        <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Harga&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th style={{ width: '200px' }}><b>Qty&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Diskon %</b></th>
                                <th><b>Diskon(N)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th><b>Sub Total</b></th>
                                <th><b>Note&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td>
                                      <Input
                                          className="form-control-alternative"
                                            placeholder="Harga"
                                            type="number"
                                            value={savedItem.harga}
                                            onChange={(e) => {
                                              handleEditHarga(key, e.target.value);
                                            }}
                                          />
                                      </td>
                                      <td style={{ width: '200px' }} >
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="Qty"
                                          type="number"
                                          value={savedItem.qty}
                                          onChange={(e) => {
                                              handleEditQty(key, e.target.value);
                                              setDiskonGLobal("diskonglobalpersen")
                                          }}/>
                                      </td>
                                      <td>{savedItem.satuan}</td>
                                      <td>
                                      <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon Persen"
                                            type="number"
                                            value={savedItem.diskon_persen}
                                            onChange={(e) => {
                                              handleEditDiskonPersen(key, e.target.value);
                                            }}
                                          />
                                      </td>
                                      <td>
                                      <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon nominal"
                                            type="number"
                                            value={savedItem.diskon_nominal}
                                            onChange={(e) => {
                                              handleEditDiskonNominal(key, e.target.value);
                                            }}
                                          />
                                       
                                      </td>
                                      <td>
                                      {formatRupiah(((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) / (ppn/100 + 100/100))}
                                     </td>
                                      <td>
                                      <Input
                                          className="form-control-alternative"
                                          placeholder="Note"
                                          type="text  "
                                          value={savedItem.note}
                                          onChange={(e) => {
                                            handleEditNote(key, e.target.value);
                                          }}/>
                                      </td>
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              <tr>
                                <td>
                                  <><Form onSubmit={handleSubmit1}>
                                          <Input
                                            autoComplete="off"
                                            placeholder="Masukan Item Manual"
                                            type="search"
                                            value={queryy}
                                            onChange={(e) => setQueryy(e.target.value)}
                                          />
                                        
                                        {isSearchShoww && queryy && (
                                        <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                          <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                            <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
                                          </div>
                                          {allItemm?.response ? (
                                            allItemm.response.map((item) => (
                                              <CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {saveItemOrUpdate(item);setQueryy('');setIsSearchShoww(false);}}>
                                                <div>
                                                  <b>Nama item:</b> {item.item_name}
                                                </div>
                                              </CardBody>
                                            ))
                                          ) : (
                                            <div className="text-center mb-3 text-danger">Item "{queryy}" tidak ada bosku!</div>
                                          )}
                                        </Card>
                                      )}
                                  </Form></>
                                  </td>
                                 </tr>
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter >
                          <Row md="12">
                            <Col md="6">
                            </Col>
                            <Col md="2">
                            </Col>
                            <Col md="4">
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                                size="small"
                              >
                                <b>Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Harga Total"
                                value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Diskon</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                style={{fontWeight: 'bold'}}
                                disabled
                                type="text"
                                name="barcode"
                                placeholder="Diskon"
                                value={"Rp." + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>PPN</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Ongkir</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Uang Muka</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Uang Muka"
                                value={"Rp." + dp1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Grand Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                type="text"
                                name="barcode"
                                style={{fontWeight: 'bold'}}
                                placeholder="Grand Total"
                                value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardFooter>
                    <CardFooter>
                          <Link className="btn btn-info" to="/admin/pusat/purchase-order/page">
                              Kembali
                          </Link>
                          <Button color="danger" onClick={()=>setQuestionAlert()}>
                              Simpan
                          </Button>
                    </CardFooter>
              </Card>
          </div>
        </Row>
        {/* modal konfirmasi editable */}
			<Modal isOpen={isConfEditableOpen} fade={false} style={{ minWidth: "30%", top: "10%" }}>
				<ModalHeader toggle={() => setIsConfEditableOpen(!isConfEditableOpen)}>Masukan Username Dan Password</ModalHeader>
				<ModalBody
					cssModule={{
						alignText: "center",
					}}>
						<Form onSubmit={handleSubmit4}>
						<Row className="justify-content-center mt-4">
								<Col xs={7}>
									<Input
										autoComplete="off"
										placeholder="Masukan Username"
										type="text"
										value={nameotorisasi}
										invalid={nameotorisasiError === "invalid"}
										onChange={(e) => {
											setNameOtorisasi(e.target.value);
											if (e.target.value !== "") {
											setNameOtorisasiError("");
											}
										}}
									/>
								</Col>
							</Row>
							<Row className="justify-content-center mt-4">
								<Col xs={7}>
									<Input
										autoComplete="off"
										placeholder="Masukan Password Otorisasi"
										type="password"
										value={confPassEditable}
										onChange={(e) => setConfPassEditable(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="justify-content-center mt-4">
								<Col xs={7}>
										<Input
                      //   className="form-control-alternative"
                        autoComplete="off"
                        type="select"
											  value={keterangan5}
											  invalid={keteranganError === "invalid"}
											  onChange={(e) => {
												  setKeterangan5(e.target.value);
												  if (e.target.value !== "") {
												  setKeteranganError("");
												  }
											  }}>
                          <option value="" disabled hidden selected>Pilih Alasan</option>
                            {reasons.map((reason, key) => {
                                return (
                                <option key={key} value={reason.description}>
                                    {reason.description}
                                </option>
                                );
                            })}
                      </Input>
									<br/>
									<Button color="primary" type="submit">CEK <i className="fa fa-search" /></Button>
								</Col>
							</Row>
						</Form>
				</ModalBody>
			</Modal>
    </Container>  
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}