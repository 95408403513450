/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Label,
  Badge,
	FormGroup,
	Row,
	Input,
	CardHeader,
	Col,
  Form ,
	Button,
  CardGroup,
  CardTitle,
	Container,
  Modal, ModalHeader, ModalBody
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
// import paginationFactory from "react-bootstrap-table2-paginator";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Halaman404 from "views/404";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";
import ModalCetakRak from "./ModalCetakRak";
import ModalCetakAll from "./ModalCetakAll";

export default function Userss() {
	const token = localStorage.token;
	const warehouseId = localStorage.warehouse;
  const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle1 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
  const toggle2 = () => setModal2(!modal2);
	const [modal2, setModal2] = useState(false);
  const [areacok, setAreaCok] = useState("");
  const redirectPrefix1 = `/admin/rak/edit/gambar/`;
  const redirectPrefix2 = `/admin/rak/item-rak/`;
  const [allUom, setAllUom] = useState([]);
  const [uomCode, setUomCode] = useState("");
  const [description, setDescription] = useState("");
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [minimumstock, setMinimumStock] = useState([]);
  const [maximumstock, setMaximumStock] = useState([]);
  const [code, setCode] = useState("");
  const [area, setArea] = useState("");
  const [sisi, setSisi] = useState("");
  const [hook, setHook] = useState("");
  const [subarea, setSubArea] = useState("")
  const [tipe, setTipe] = useState("");
  const [tipes, setTipes] = useState([]);
  const [partisi, setPartisi] = useState("")
  const [partisi1, setPartisi1] = useState("")
  const [subarea1, setSubArea1] = useState("");
  const [area1, setArea1] = useState("");
  const [sisi1, setSisi1] = useState("");
  const [position, setPosition] = useState("");
  const [sale, setSale] = useState("");
  const [hook1, setHook1] = useState("");
  const [itemId, setItemId] = useState(1);
  const [allItem, setAllItem] = useState([]);
  const [query, setQuery] = useState("");
  const [openModalCetak, setOpenModalCetak] = useState(false);
  const [dataModalCetak, setDataModalCetak] = useState({ id: 0,});
  const toggle = () => setOpenModalCetak(!openModalCetak);
  const [openModalCetak1, setOpenModalCetak1] = useState(false);
  const [dataModalCetak1, setDataModalCetak1] = useState({ cabang: 0, area: 0 });
  const toggle3 = () => setOpenModalCetak1(!openModalCetak1);
  const [isSearchShow, setIsSearchShow] = useState(false);
  const headers = { Authorization: `Bearer ${token}` };
  const redirectPrefix = `/admin/rak/edit/`;
  const allInfo = JSON.parse(localStorage.allinfo);
  const rak = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Lokasi Barang").map((p) => p.read_access));
  const createButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Lokasi Barang").map((p) => p.create_access));
  const UpdateButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Lokasi Barang").map((p) => p.update_access));


  const displayModalCetak = (id) => {
    setDataModalCetak({
      id: id,
      
    });
    setOpenModalCetak(true);
  };

  const displayModalCetak1 = (id) => {
    setDataModalCetak1({
      cabang: id,
      area : areacok
    });
    setOpenModalCetak1(true);
  };
  
  useEffect(() => {
		getItems();
	}, [page]);

	//get list item berdasarkan warehouse
	const getItems = async () => {
		let filter = {
			page: page,
			per_page: 1,
			warehouse_id: parseInt(warehouseId),
		};
		const data = filter;
		const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/rak`, data, { headers });
		if(res.data.response.id !== null){
			getById(res.data.response[0].id);
			setItemId(res.data.response[0].id);
			setTotalPages(res.data.total_page);
		}else{return false}
	};


    //cari item
	useEffect(() => {
		const getData = setTimeout(async() => {
			if(query != ''){
				axios
				.post(`${process.env.REACT_APP_API_BASE_URL}/rak`, { rak_code: query , per_page: 10, warehouse_id: parseInt(warehouseId), },{ headers })
				.then((res) => {
					setAllItem(res.data);
					setIsSearchShow(true);
				})
				.catch(function (error) {
          setAllItem(error.response.data.response);
					setIsSearchShow(true);
        });
			}
		  }, 1000)
		  return () => clearTimeout(getData)
	}, [query]);

	  const getById = (id) => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/rak/get/${id}`,
	        { headers }
	      )
	      .then((data) => {
            setCode(data.data.response.rak_code)
            getRakListItem(data.data.response.rak_code)
            setMinimumStock(data.data.response.minimum_stok)
            setMaximumStock(data.data.response.maximum_stok)
            setArea(data.data.response.area)
            setSisi(data.data.response.sisi)
            setHook(data.data.response.hook)
            setSubArea(data.data.response.sub_area)
            setTipe(data.data.response.tipe)
            setSale(data.data.response.sale_state)
            setPosition(data.data.response.position)
            setPartisi(data.data.response.total_real_location)
            setPartisi1(data.data.response.img_total_real_location)
            setSubArea1(data.data.response.img_sub_area)
            setArea1(data.data.response.img_area)
            setSisi1(data.data.response.img_sisi)
            setHook1(data.data.response.img_hook)
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };


      const updateDataTable = (page, perPage, sort) => {
        getRakListItem(page, perPage, sort);
        setPage(page);
        setPerpage(perPage);
        setRowIndex((page - 1) * perPage);
        setCurrentSort(sort);
        setUomCode(uomCode);
        setDescription(description);
      }
    
      const handleTableChange = (type, { sortField, sortOrder }) => {
        if (type === "sort") {
          let sort = `${sortField} ${sortOrder}`
          updateDataTable(page, perPage, sort,  uomCode, description)
        }
      }
    
      const getRakListItem = async (id) => {
        let filter = { rak_code : id, warehouse_id : warehouseId }
        const data = filter;
        const headers = {
          'Authorization': `Bearer ${token}`,
        };
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/rak/list-item`, data, { headers })
        .then((data) => {
          setAllUom(data.data.response);
          setTotalItem(data.data.total_item);
        })
        .catch(function (error) {
          setAllUom(error.response.data.response);
        });
         
        }

	const searchShow = (item) => {
    // setPage(item.id);
		setItemId(item.id);
    getById(item.id);
		setIsSearchShow(false);
	};

  const handlePrevious = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const getDataLog = (id) => {
		let filter = { 
			transaction : id,
			features : "MASTER2",
			warehouse_id : warehouseId
		}
		const data = filter;
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
			setDataLog(data.data.response);
            setModal1(!modal1);

		})
		  .catch(function (error) {
			setGagalAlert(error.response.data.message);
		  })
	  }

    const getDataLogItem = (id) => {
      let filter = { 
        transaction : id,
        features : "MASTER14",
        warehouse_id : warehouseId
      }
      const data = filter;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
        }
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
      })
      .then(data => {
        setDataLog(data.data.response);
              setModal1(!modal1);
  
      })
        .catch(function (error) {
        setGagalAlert(error.response.data.message);
        })
      }

	  const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
		);
		};
		
		const hideAlert = () => {
		  setAlert(null);
		};

    const setQuestionAlertActiveFlag1 = (id,partisi,minimum,maximum) => {
      setAlert(
        <SweetAlert
          warning 
          showCancel
          confirmBtnText="Lanjutkan"
          confirmBtnBsStyle="danger"
          title="Apakah Kamu Yakin?"
          onConfirm={()=>functionActiveflag1(id,partisi,minimum,maximum)}
          onCancel={hideAlert}
          focusCancelBtn
          />
        );
      };

      const setQuestionAlertDelete = (id) => {
        setAlert(
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Lanjutkan"
            confirmBtnBsStyle="danger"
            title="Apakah Kamu Yakin?"
            onConfirm={()=>functionActiveDelete(id)}
            onCancel={hideAlert}
            focusCancelBtn
            />
          );
        };
  
      const setQuestionAlertActiveFlag2 = (id,partisi,minimum,maximum) => {
        setAlert(
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Lanjutkan"
            confirmBtnBsStyle="danger"
            title="Apakah Kamu Yakin?"
            onConfirm={()=>functionActiveflag2(id,partisi,minimum,maximum)}
            onCancel={hideAlert}
            focusCancelBtn
            />
          );
        };

    const functionActiveflag1 = async (id,partisi,minimum,maximum) => {
      let data = {
        active_flag : 1,
        real_location : partisi,
        minimum_stok : minimum,
        maximum_stok : maximum
        }
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/item-stok/update/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
        })
        .then(function (response) {
          getItems()
          statuscok(response.data.message)
      })
      .catch(function (error) {
        getItems()
        statuscok(error.response.data.message)
      });
    }
  
    const functionActiveflag2 = async (id,partisi,minimum,maximum) => {
      let data = {
        active_flag : 2,
        real_location : partisi,
        minimum_stok : minimum,
        maximum_stok : maximum

  
        }
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/item-stok/update/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
        })
        .then(function (response) {
          getItems()
          statuscok(response.data.message)
      })
      .catch(function (error) {
        getItems()
        statuscok(error.response.data.message)
      });
    }

    const functionActiveDelete = async (id) => {
      let data = {
        rak_code : code,
        item_id : id,
        warehouse_id : warehouseId
  
        }
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/rak/delete-item`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
        })
        .then(function (response) {
          getItems()
          statuscok(response.data.message)
      })
      .catch(function (error) {
        getItems()
        statuscok(error.response.data.message)
      });
    }

    const statuscok = (id) => {
      setAlert(
      <SweetAlert
      success
      showConfirm
      confirmBtnText="Ok"
      title={id}
      timeout={2000}
      onCancel={hideAlert}
      onConfirm={hideAlert}
      />
      );
      };

	return (
		<>
    {alert}
      {rak && rak === "1" ? (
			<div>
				<SimpleHeader name="Planogram" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
								<Card className="position-sticky boxShadow" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
                                            <Col md="5 d-lg-block d-none">
												<Button onClick={() => setPage(1)} disabled={page === 1} color="danger" type="button">
													First
												</Button>
                        <Button onClick={handlePrevious} disabled={page === 1} color="success" type="button">
                          <i className="fa fa-angle-left" /> Prev
                        </Button>
                        <Button onClick={handleNext} disabled={page >= totalPages} color="success" type="button">
                          Next <i className="fa fa-angle-right" />
                        </Button>
												<Button onClick={() => setPage(totalPages)} disabled={page == totalPages} color="warning" type="button">
													End
												</Button>
											</Col>
											<Col md="3">
												<FormGroup row>
													<Col sm={10}>
														<Input
															placeholder="Search Rak"
															type="search"
                              value={query}
															onChange={(e) => setQuery(e.target.value)}
														/>
													</Col>
												</FormGroup>
											</Col>
											<Col md="4 d-lg-block d-none">
												<div style={{ textAlign: "right" }}>
                            {createButton && createButton === "1" && (
                              <Button color="warning" onClick={() => {setModal2(true)}}><i className="fas fa-user-edit" /></Button>
                                // <Link className="btn btn-info" to="/admin/tipe-rak">
                                //     Cetak All 
                                // </Link>
                            )}
                            {createButton && createButton === "1" && (
                                <Link className="btn btn-warning" to="/admin/tipe-rak">
                                    <i className="fas fa-plus" /> Tipe Rak 
                                </Link>
                            )}
                            {UpdateButton && UpdateButton === "1" && (
                                <Link className="btn btn-info"
                                  to={redirectPrefix + itemId}
                                >
                                  <i className="fas fa-user-edit" /> Edit
                                </Link>
                            )}
                            {createButton && createButton === "1" && (
                                <Link className="btn btn-danger" to="/admin/rak/create">
                                  <i className="fas fa-plus" /> Tambah
                                </Link>
                            )}
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
								{isSearchShow && query && (
									<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
										<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
											<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
										</div>
										<span className="text-center mb-3">
											<b>Pencarian berdasarkan:</b> {query}
										</span>
										{allItem?.response ? (
											allItem.response.map((item) => (
												<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" style={{minHeight: 'auto'}} onClick={() => {searchShow(item); setQuery('')}}>
													<div>
														<b>Kode Rak:</b> {item.rak_code}
													</div>
												</CardBody> 
											))
										) : (
											<div className="text-center mb-3 text-danger">Rak "{query}" tidak ada bosku!</div>
										)}
									</Card>
								)}
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                    <div
											style={{ display: "flex", justifyContent: "space-between" }}
										>
											<h3>Rak</h3>
											<div style={{ textAlign: "rigth" }}>
                        <Button color="info"  onClick={() => displayModalCetak(itemId)}>
												<i className="fa fa-print" aria-hidden="true"></i>
												</Button>
												<Button color="warning" onClick={() => (getDataLog(itemId))}>
												<i className="fa fa-info-circle" aria-hidden="true"></i>
												</Button>
											</div>
										</div>
                    </CardHeader>
                    <CardBody>
                    <Row md="12">
                        <Col md="6">
                            <FormGroup row>
                                <Label
                                 className="form-control-label"
                                    for="exampleEmail"
                                    sm={4}
                                >
                                    Kode
                                </Label>
                                <Col sm={7}>
                                    <Input
                                    disabled
                                        className="form-control-alternative"
                                    type="text"
                                    name="Area"
                                    placeholder="Masukan Area"
                                    value={code}
                                    />
                                </Col>
                            </FormGroup>
                            
                            <FormGroup row>
                            <Label
                             className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                            >
                                Area
                            </Label>
                            <Col sm={7}>
                                <Input
                                disabled
                                    className="form-control-alternative"
                                type="text"
                                name="Area"
                                placeholder="Masukan Area"
                                value={area === "" ? 
                                "Area Belum Di isi"
                                :
                                area
                              }
                                />
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label
                             className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                            >
                                Sub Area
                            </Label>
                            <Col sm={7}>
                                <Input
                                disabled
                                    className="form-control-alternative"
                                type="text"
                                name="Area"
                                placeholder="Masukan Sub Area"
                                value={subarea === "" ? 
                                  "Sub Area Belum Di Isi"
                                  :
                                  subarea
                                }
                                />
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label
                             className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                            >
                                Sisi
                            </Label>
                            <Col sm={7}>
                                <Input
                                disabled
                                    className="form-control-alternative"
                                type="text"
                                name="SISI"
                                placeholder="Masukan Sisi"
                                value={sisi === "" ? 
                                  "Sisi Belum Di Isi"
                                  :
                                  sisi
                                }
                                />
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label
                             className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                            >
                                Hook
                            </Label>
                            <Col sm={7}>
                                <Input
                                disabled
                                    className="form-control-alternative"
                                type="text"
                                name="SISI"
                                placeholder="Masukan Hook"
                                value={hook === "" ? 
                                  "Hook Belum Di Isi"
                                  :
                                  hook
                                }
                                />
                            </Col>
                            </FormGroup>
                            
                        </Col>
                        <Col md="6">
                        <FormGroup row>
                                <Label
                                 className="form-control-label"
                                    for="exampleEmail"
                                    sm={4}
                                    >
                                    Tipe Rak
                                </Label>
                                <Col sm={7}>
                                    <Input
                                        disabled
                                        className="form-control-alternative"
                                        type="text"
                                        placeholder="Masukan tipe Rak"
                                        value={tipe === "" ? 
                                          "Tipe Belum Di Isi"
                                          :
                                          tipe
                                        }
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                  for="exampleEmail"
                                  sm={4}
                              >
                                  Partisi
                              </Label>
                              <Col sm={7}>
                                  <Input
                                  disabled
                                  className="form-control-alternative"
                                  type="text"
                                  name="Partisi"
                                  placeholder="Masukan Partisi"
                                  value={partisi === null ? 
                                    "Partisi Belum Di Isi"
                                    :
                                    partisi
                                  }
                                  />
                              </Col>
                            </FormGroup>
                            {/* <FormGroup row>
                            <Label
                             className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                            >
                                Stok Minimum 
                            </Label>
                            <Col sm={7}>
                                <Input
                                disabled
                                    className="form-control-alternative"
                                type="number"
                                name="Minimum Stock"
                                placeholder="Masukan Stok Minimum"
                                value={minimumstock}
                                />
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label
                             className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                            >
                                Stok Maximum
                            </Label>
                            <Col sm={7}>
                                <Input
                                disabled
                                    className="form-control-alternative"
                                type="number"
                                name="Minimum Stock"
                                placeholder="Masukan Stok Maximum"
                                value={maximumstock}
                                />
                            </Col>
                            </FormGroup> */}
                              <FormGroup row>
                                    <Col sm={4}>
                                      <Label
                                       className="form-control-label"
                                      >
                                    Lokasi
                                    </Label>
                                    </Col>
                                    <Col sm={7}>
                                    {/* <div style={{ display: "flex" }}> */}
                                      {position === 1 ? 
                                      <h1>
                                       <Badge color="success">
                                          TOKO
                                      </Badge>
                                      </h1>:
                                      <h1>
                                      <Badge color="danger">
                                          GUDANG
                                      </Badge>
                                      </h1>
                                    }
                                    {/* </div> */}
                                    </Col>
                            </FormGroup>
                            <FormGroup row>
                <Col sm={4}>
                  <Label
                  className="form-control-label"
                  >
                  Status
                  </Label>
                </Col>
                <Col sm={7}>
                  {/* <div style={{ display: "flex" }}> */}
                    {sale === 1 ? 
                    <h1>
                    <Badge color="success">
                    JUAL
                </Badge>
                </h1>:
                <h1>
                <Badge color="danger">
                    TIDAK DI JUAL
                    </Badge>
                    </h1>
                  }
                  {/* </div> */}
                </Col>
        </FormGroup>
                        </Col>
                    </Row>
                    </CardBody>
                </Card>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h3>Planogram</h3>
                        {UpdateButton && UpdateButton === "1" && (
													<div style={{ textAlign: 'right' }}>
													<Link className="btn btn-warning" to={redirectPrefix1 + itemId}>
													<i className="fas fa-pen" />
													</Link>
													</div>
                          )}
												</div>
                    </CardHeader>
                    <CardBody>
                    <Row md="12">
                        <Col md="12">
                            <CardGroup>
                                <Card>
                                  <div align="center">
                                        {area1 === "" ?(
                                            <img
                                            alt="..."
                                            src={"https://i.imgur.com/QC87gHD.png"}
                                            width="80%"
                                            align="center"
                                            />
                                        ):(
                                          <Zoom>
                                        <img
                                            alt=""
                                            src={area1}
                                            width="80%"
                                            align="center"
                                        />
                                        </Zoom>
                                        )}
                                    </div>
                                    <CardBody>
                                    <CardTitle tag="h3" align="center">
                                        Area
                                    </CardTitle>
                                    </CardBody>
                                </Card>
                                <Card>
                                <div align="center">
                                    {subarea1 === "" ?(
                                            <img
                                            alt="..."
                                            src={"https://i.imgur.com/QC87gHD.png"}
                                            width="80%"
                                            />
                                        ):(
                                          <Zoom>
                                        <img
                                            alt=""
                                            src={subarea1}
                                            width="80%"
                                        />
                                        </Zoom>
                                        )}
                                        </div>
                                    <CardBody>
                                    <CardTitle tag="h3" align="center">
                                        Sub Area
                                    </CardTitle>
                                    </CardBody>
                                </Card>
                                <Card>
                                <div align="center">
                                    {sisi1 === "" ?(
                                            <img
                                            alt="..."
                                            src={"https://i.imgur.com/QC87gHD.png"}
                                            width="80%"
                                            />
                                        ):(
                                          <Zoom>
                                        <img
                                            alt=""
                                            src={sisi1}
                                            width="80%"
                                        />
                                        </Zoom>
                                        )}
                                        </div>
                                    <CardBody>
                                    <CardTitle tag="h3" align="center">
                                        Sisi
                                    </CardTitle>
                                    </CardBody>
                                </Card>
                                <Card>
                                <div align="center">
                                    {hook1 === "" ?(
                                            <img
                                            alt="..."
                                            src={"https://i.imgur.com/QC87gHD.png"}
                                            width="80%"
                                            />
                                        ):(
                                          <Zoom>
                                        <img
                                            alt=""
                                            src={hook1}
                                            width="80%"
                                        />
                                        </Zoom>
                                        )}
                                        </div>
                                    <CardBody>
                                    <CardTitle tag="h3" align="center">
                                        Hook
                                    </CardTitle>
                                    </CardBody>
                                </Card>
                                <Card>
                                <div align="center">
                                    {partisi1 === "" ?(
                                            <img
                                            alt="..."
                                            src={"https://i.imgur.com/QC87gHD.png"}
                                            width="80%"
                                            />
                                        ):(
                                          <Zoom>
                                        <img
                                            alt=""
                                            src={partisi1}
                                            width="80%"
                                        />
                                        </Zoom>
                                        )}
                                        </div>
                                    <CardBody>
                                    <CardTitle tag="h3" align="center">
                                        Partisi
                                    </CardTitle>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Col>
                    </Row>
                    </CardBody>
                </Card>
                <Card>
                <Card className="bg-secondary shadow">
                  <CardHeader>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <h3>List Item</h3>
                      {createButton && createButton === "1" && (
                        
                      <div style={{ textAlign: 'right' }}>
                        {/* {allUom.length === partisi ?
                          ""
                        :
                        <Link className="btn btn-warning" to={redirectPrefix2 + itemId}>
													<i className="fas fa-pen" />
												</Link>
                        } */}
                        <Link className="btn btn-warning" to={redirectPrefix2 + itemId}>
													<i className="fas fa-pen" />
												</Link>
												<Button color="warning" onClick={() => (getDataLogItem(itemId))}>
												<i className="fa fa-info-circle" aria-hidden="true"></i>
												</Button>	
                      </div>
                      )}
                    </div>
                  </CardHeader>
                  <CardBody>
                  {allUom === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                  dataField: "",
                                  text: "Barcode",
                                  sort: true,
                                },
                                  {
                                    dataField: "",
                                    text: "Nama Item",
                                    sort: true,
                                  },
                                  {
                                    dataField: "",
                                    text: "Kode item",
                                    sort: true,
                                  },
                                  {
                                    dataField: "",
                                    text: "Qty",
                                    sort: true,
                                  },
                                  {
                                    dataField: "",
                                    text: "satuan",
                                    sort: true,
                                  },
                                  {
                                    dataField: "",
                                    text: "status",
                                    sort: true,
                                  },
                                {
                                  dataField: "", text: "", 
                              },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                        </div>
                        ):(
                        <ToolkitProvider
                          rowNumber={rowIndex}
                          data={allUom}
                          keyField="id"
                          columns={[
                            {
                              dataField: "no",
                              text: "#",
                              sort: true,
                              page: 1,
                              formatter: (cell, row, index) => {
                                let currentRow = ++index;
                                return currentRow + rowIndex;
                              }
                            },
                            {
                              dataField: "barcode",
                              text: "Barcode",
                              sort: true,
                            },
                              {
                                dataField: "item_name",
                                text: "Nama Item",
                                sort: true,
                              },
                              {
                                dataField: "item_code",
                                text: "Kode item",
                                sort: true,
                              },
                              {
                                dataField: "real_location",
                                text: "Partisi",
                                sort: true,
                              },
                              {
                                dataField: "minimum_stok",
                                text: "Min",
                                sort: true,
                              },
                              {
                                dataField: "maximum_stok",
                                text: "Max",
                                sort: true,
                              },
                              {
                                dataField: "qty_sisa",
                                text: "Stok",
                                sort: true,
                              },
                              {
                                dataField: "satuan",
                                text: "satuan",
                                sort: true,
                              },
                              {
                                dataField: "active_flag",
                                text: "status",
                                sort: true,
                                formatter: (cell, row) => {
                                  return row.active_flag === 1 ? 
                                  <h2>
                                      <Badge color="info" >
                                          Aktif 
                                      </Badge>
                                      {/* {row.active_flag === 1 ? 
                                        <Badge color="danger" >
                                          New
                                        </Badge>
                                      :
                                      ""
                                      }  */}
                                     
                                  </h2>
                                  :
                                  <h2>
                                      <Badge color="danger" >
                                          Nonaktif
                                      </Badge>
                                      {/* {row.active_flag === 1 ? 
                                        <Badge color="danger" >
                                          New
                                        </Badge>
                                      :
                                      ""
                                      } */}
                                  </h2>
                                }
                              },
                              {
                                dataField: "", text: "", formatter: (cell, row, index) => {
                                return row.active_flag === 1 ? 
                                <>
                                  <Button type="button" onClick={()=>{setQuestionAlertActiveFlag2(row.id, row.real_location, row.minimum_stok, row.maximum_stok)}} color="primary">
                                      Nonaktif
                                  </Button>
                                    {row.qty_sisa === 0 ? 
                                        <Button type="button" onClick={()=>{setQuestionAlertDelete(row.item_id)}} color="danger">
                                        <i className="fas fa-trash" />
                                        </Button>
                                      :
                                      ""
                                      }
                                </>
                                :
                                <>
                                  <Button type="button" onClick={()=>{setQuestionAlertActiveFlag1(row.id, row.real_location, row.minimum_stok, row.maximum_stok)}} color="warning">
                                    Aktif
                                  </Button>
                                  {row.qty_sisa === 0 ? 
                                        <Button type="button" onClick={()=>{setQuestionAlertDelete(row.item_id)}} color="danger">
                                        <i className="fas fa-trash" />
                                        </Button>
                                      :
                                      ""
                                      }
                                </>
                                }
                            },
                          ]}
                        >
                          {(props) => (
                            <div className="py-4 table-responsive">
                              <BootstrapTable
                                remote
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                                hover={true}
                                // pagination={paginationFactory({ ...paginationOption })}
                                onTableChange={handleTableChange}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                        )}
                  </CardBody>
                </Card>
            </Card>
						</div>
					</Row>
				</Container>
        <Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>  
      {/* modal cetak all */}
      <Modal isOpen={modal2} toggle={toggle2} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle2} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Cetak</b></h2> 
          <CardBody>
          <Form >
						<Row className="justify-content-center mt-4">
								<Col xs={5}>
									<Input
										autoComplete="off"
										placeholder="Masukan Area"
										type="text"
                    value={areacok}
                    onChange={(e) => {
                      		setAreaCok(e.target.value);
                      	}
                      }
									/>
								</Col>
							</Row>
              <Row className="justify-content-center mt-4">
								<Col xs={7}>
									<br/>
									{/* <Button color="primary" type="submit">CETAK</Button> */}
                  <Button color="info"  onClick={() => displayModalCetak1(warehouseId)}>
                    <i className="fa fa-print" aria-hidden="true"></i>
                  </Button>
								</Col>
							</Row>
						</Form>
          </CardBody>
          {/* <Button color="secondary" onClick={toggle2}>
            Kembali
          </Button> */}
        </ModalBody>
      </Modal>  
			</div>
      ):(
        <Halaman404 />
      )}
      {openModalCetak && (
            <ModalCetakRak
              open={openModalCetak}
              data={dataModalCetak}
              toggle={toggle}
            />
          )}

{openModalCetak1 && (
            <ModalCetakAll
              open={openModalCetak1}
              data={dataModalCetak1}
              toggle={toggle3}
            />
          )}
		</>
	);
}5.20
