/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CardBody,
  Row,
  Container,
  CardImg,
} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import HeaderTotal from "components/Headers/HeaderTotal";

export default function PageAdjustment() {
  const warehouse = localStorage.warehouse;
    const token = localStorage.token;
    const [totalItem, setTotalItem] = useState(0);
    const [proses, setProses] = useState(0);
    const [tolak, setTolak] = useState(0);
    const [setuju, setSetuju] = useState(0);
    const page = 1;
    const perPage = 10;
  const allInfo = JSON.parse(localStorage.allinfo);
  const adjustment = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Adjustment").map((p) => p.read_access));
  const ValidasiAdjustment = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi Adjustment").map((p) => p.read_access));
  const Cetakk = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Cetak Adjustment").map((p) => p.read_access));
  
  useEffect(() => {
    gettotal();
    getProses();
    getTolak();
    getSetuju()
  }, []);

  // fungsi dari ambil data
  const gettotal = () => {
    let filter = { 
      page: page, 
      per_page: perPage,
      warehouse_id: parseInt(warehouse),
    };
    
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/adjustment/page`, data, {
        headers,
      })
      .then((data) => {
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
      });
}

const getProses = () => {
    let filter = { 
      page: page, 
      per_page: perPage,
      status_d:3,
      warehouse_id: parseInt(warehouse),
    };
    
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/adjustment/page`, data, {
        headers,
      })
      .then((data) => {
        setProses(data.data.total_item);
      })
      .catch(function (error) {
      });
}

const getTolak = () => {
    let filter = { 
      page: page, 
      per_page: perPage,
      status_d:4,
      warehouse_id: parseInt(warehouse),
    };
    
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/adjustment/page`, data, {
        headers,
      })
      .then((data) => {
        setTolak(data.data.total_item);
      })
      .catch(function (error) {
      });
}

const getSetuju = () => {
    let filter = { 
      page: page, 
      per_page: perPage,
      status_d:5,
      warehouse_id: parseInt(warehouse),
    };
    
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/adjustment/page`, data, {
        headers,
      })
      .then((data) => {
        setSetuju(data.data.total_item);
      })
      .catch(function (error) {
      });
}

  return (
    <>
      <div>
      <SimpleHeader name="Adjustment" parentName="Report" />
      <HeaderTotal
       totalItem = {totalItem}
       proses ={proses}
       tolak={tolak}
       setuju={setuju}

      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <CardBody>
                    <Row>
                        <div className="card-columns">
                        {adjustment && adjustment === "1" && (
                            <Card>
                                <Link to="/admin/stock-adjustment/page">
                                    <CardImg
                                    alt="..."
                                    src={require("assets/img/theme/adjustment.png").default}
                                    top
                                    />
                                </Link>
                            </Card>
                        )}
                        {ValidasiAdjustment && ValidasiAdjustment === "1" && (
                            <Card>
                                <Link to="/admin/stock-adjustment/validasi-direktur">
                                    <CardImg
                                    alt="..."
                                    src={require("assets/img/theme/validasi-direktur.png").default}
                                    top
                                    />
                                </Link>
                            </Card>
                        )}
                        {Cetakk && Cetakk === "1" && (
                            <Card>
                                <Link to="/admin/stock-adjustment/histori">
                                    <CardImg
                                    alt="..."
                                    src={require("assets/img/theme/history.png").default}
                                    top
                                    />
                                </Link>
                            </Card>
                        )}
                        </div>
					</Row>
                </CardBody>
          </div>
        </Row>
      </Container>
      </div>
    </>
  );
}