/*eslint-disable*/
import React from "react";
import {
  Card,
  CardBody,
  Row,
  Container,
  CardImg,
} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function PageMaster() {
    const allInfo = JSON.parse(localStorage.allinfo);
    const invoicePO = String(allInfo.privileges.filter((i) => i.privilege_name === "Cetak Cabang").map((p) => p.read_access));
	const validasiAdminFinance = String(allInfo.privileges.filter((i) => i.privilege_name === "Cetak Planogram").map((p) => p.read_access));
	const validasiDirektur = String(allInfo.privileges.filter((i) => i.privilege_name === "Cetak Katalog").map((p) => p.read_access));
	const cetak = String(allInfo.privileges.filter((i) => i.privilege_name === "Cetak Gambar").map((p) => p.read_access));

  return (
    <>
      <div>
      <SimpleHeader name="Laporan Master" parentName="Report" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <CardBody>
                    <Row>
                        <div className="card-columns">
                            <Card>
                                <Link to="/admin/laporan/master/laporan-cabang">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/laporan-cabang.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        <Card>
                                <Link to="/admin/laporan/master/laporan-planogram">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/laporan-planogram.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                         <Card>
                                <Link to="/admin/laporan/master/laporan-supplier">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/laporan-supplier.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/laporan/master/laporan-customer">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/laporan-customer.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        {/* {validasiAdminFinance && validasiAdminFinance === "1" && (
                            <Card>
                                <Link to="/admin/laporan/laporan-planogram">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-kepala-purchasing.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {validasiDirektur && validasiDirektur === "1" && (
                            <Card>
                                <Link to="/admin/laporan/laporan-supplier">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-manajer-office.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {cetak && cetak === "1" && (
                            <Card>
                                <Link to="/admin/laporan/laporan-customer">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                         {cetak && cetak === "1" && (
                            <Card>
                                <Link to="/admin/laporan/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                         {cetak && cetak === "1" && (
                            <Card>
                                <Link to="/admin/laporan/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                         {cetak && cetak === "1" && (
                            <Card>
                                <Link to="/admin/laporan/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                         {cetak && cetak === "1" && (
                            <Card>
                                <Link to="/admin/laporan/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                         {cetak && cetak === "1" && (
                            <Card>
                                <Link to="/admin/laporan/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )} */}
                        </div>
					</Row>
                </CardBody>
          </div>
        </Row>
      </Container>
      </div>
    </>
  );
}