/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
	CardFooter,
	Col,
	Button,
	Table,
	Container,
} from "reactstrap";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import CurrencyInput from 'react-currency-input-field';
import Halaman404 from "views/404";

export default function EditHargaPerToko(props) {
	const token = localStorage.token;
	const username = localStorage.username;
	const [id, setId] = useState("");
	const [wid, setWid] = useState("");
	const [hargaBeli, setHargaBeli] = useState(0);
	const [allpajak, setAllPajak] = useState([]);
	const [allpajaks, setAllPajaks] = useState([]);
	const [pajakBeli, setPajakBeli] = useState(11);
	const [pajakBeliNominal, setPajakBeliNominal] = useState(0);
	const [pajakjual, setPajakJual] = useState(11);
	const [pajakjualnominal, setPajakJualNominal] = useState(0);
	const [pajakjualnominal2, setPajakJualNominal2] = useState(0);
	const [pajakjualnominal3, setPajakJualNominal3] = useState(0);
	const [pajakjualnominal4, setPajakJualNominal4] = useState(0);
	const [pajakjualnominal5, setPajakJualNominal5] = useState(0);
	const [diskonsuplierpersen, setDiskonSuplierPersen] = useState(0);
	const [diskonnominal, setDiskonNominal] = useState(0);
	const [diskonpersen, setDiskonPersen] = useState(0);
	const [diskonsupliernominal, setDiskonSuplierNominal] = useState(0);
	const [hargajualminimal, setHargaJualMinimal] = useState(0);
	const [hargajual, setHargaJual] = useState(0);
	const [hargabelitotal, setHargaBeliTotal] = useState(0);
	const [marginglobalpersen, setMarginGlobalPersen] = useState(0);
	const [marginglobalnominal, setMarginGlobalNominal] = useState(0);
	const [level1, setLevel1] = useState("");
	const [level2, setLevel2] = useState("");
	const [level3, setLevel3] = useState("");
	const [level4, setLevel4] = useState("");
	const [level5, setLevel5] = useState("");
	const [level6, setLevel6] = useState("");
	const [qty1, setQty1] = useState(0);
	const [qty2, setQty2] = useState(0);
	const [qty3, setQty3] = useState(0);
	const [qty4, setQty4] = useState(0);
	const [qty5, setQty5] = useState(0);
	const [qty6, setQty6] = useState(0);
	const [price1, setPrice1] = useState(0);
	const [price2, setPrice2] = useState(0);
	const [price3, setPrice3] = useState(0);
	const [price4, setPrice4] = useState(0);
	const [price5, setPrice5] = useState(0);
	const [price6, setPrice6] = useState(0);
	const [margin1, setMargin1] = useState(0);
	const [margin2, setMargin2] = useState(0);
	const [margin3, setMargin3] = useState(0);
	const [margin4, setMargin4] = useState(0);
	const [margin5, setMargin5] = useState(0);
	const [margin6, setMargin6] = useState(0);
	const [persentase1, setPersentase1] = useState(0);
	const [persentase2, setPersentase2] = useState(0);
	const [persentase3, setPersentase3] = useState(0);
	const [persentase4, setPersentase4] = useState(0);
	const [persentase5, setPersentase5] = useState(0);
	const [persentase6, setPersentase6] = useState(0);
	const [diskon, setDiskon] = useState("");
	const [pajak, setPajak] = useState("");
	const [margin, setMargin] = useState("");
	const [diskonJual, setDiskonJual] = useState("");
	const [marginke1, setMarginKe1] = useState(0);
	const [marginke2, setMarginKe2] = useState(0);
	const [marginke3, setMarginKe3] = useState(0);
	const [marginke4, setMarginKe4] = useState(0);
	const [marginke5, setMarginKe5] = useState(0);
	const [marginke6, setMarginKe6] = useState(0);
	const [hmd, setHMD] = useState(0);
	const [alert, setAlert] = React.useState(null);
	const allInfo = JSON.parse(localStorage.allinfo);
	const itempusat = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Item Pusat").map((p) => p.read_access));
	

	useEffect(() => {
        getById();
	}, []);

	  const getById = () => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/price-detail/get?item_id=${props.match.params.itemid}&wid=${props.match.params.id}`,
	        { headers }
	      )
	      .then((data) => {
			getPajakJual(data.data.response.pajak_jual);
			getPajakBeli(data.data.response.pajak_beli);
			setId(data.data.response.item_id);
			setWid(data.data.response.warehouse_id);
	        setHargaBeli(data.data.response.harga_beli);
	        setHargaBeliTotal(data.data.response.hpp_beli);
	        setHargaJualMinimal(data.data.response.hpp_jual);
	        setDiskonNominal(data.data.response.diskon_nominal);
			setMarginGlobalNominal(data.data.response.margin_global_nominal);
			setMarginGlobalPersen(data.data.response.margin_global_persen);
	        setDiskonPersen(data.data.response.diskon_persen);
	        setDiskonSuplierNominal(data.data.response.diskon_nominal_suplier);
	        setDiskonSuplierPersen(data.data.response.diskon_persen_suplier);
			setHargaJual(data.data.response.harga_jual);
	        setLevel1(data.data.response.level_1);
	        setLevel2(data.data.response.level_2);
	        setLevel3(data.data.response.level_3);
	        setLevel4(data.data.response.level_4);
	        setLevel5(data.data.response.level_5);
	        setLevel6(data.data.response.level_6);
	        setPersentase1(data.data.response.persentase_1);
	        setPersentase2(data.data.response.persentase_2);
	        setPersentase3(data.data.response.persentase_3);
	        setPersentase4(data.data.response.persentase_4);
	        setPersentase5(data.data.response.persentase_5);
	        setPersentase6(data.data.response.persentase_6);
	        setMargin1(data.data.response.nominal_1);
	        setMargin2(data.data.response.nominal_2);
	        setMargin3(data.data.response.nominal_3);
	        setMargin4(data.data.response.nominal_4);
	        setMargin5(data.data.response.nominal_5);
	        setMargin6(data.data.response.nominal_6);
	        setQty1(data.data.response.min_qty_1);
	        setQty2(data.data.response.min_qty_2);
	        setQty3(data.data.response.min_qty_3);
	        setQty4(data.data.response.min_qty_4);
	        setQty5(data.data.response.min_qty_5);
	        setPrice1(data.data.response.price_1);
	        setPrice2(data.data.response.price_2);
	        setPrice3(data.data.response.price_3);
	        setPrice4(data.data.response.price_4);
	        setPrice5(data.data.response.price_5);
	        setPrice6(data.data.response.price_6);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

	  // diskon supplier 
	  useEffect(() => {
		diskonsupliernominal && diskon === "diskonNominal" && setDiskonSuplierPersen(diskonsuplierpersen);
		diskonsuplierpersen && diskon === "diskonPersen" && setDiskonSuplierNominal(hargaBeli * (diskonsuplierpersen / 100));
	}, [hargaBeli, diskonsuplierpersen, diskonsupliernominal]);

	// Harga - PPN supplier
	useEffect(() => {
		pajakBeliNominal && pajak === "pajakNominal" && setPajakBeli((pajakBeliNominal / hargaBeli) * 100);
		pajakBeli && pajak === "pajakPersen" && setPajakBeliNominal(hargaBeli * (pajakBeli / 100));
	}, [hargaBeli,pajak, pajakBeli, pajakBeliNominal]);

	// Harga - Harga Beli Total
	useEffect(() => {
		hargaBeli && diskonsupliernominal && pajakBeliNominal && setHargaBeliTotal(Number(hargaBeli) - Number(diskonsupliernominal) + Number(pajakBeliNominal));
	}, [hargaBeli, diskonsuplierpersen, diskonsupliernominal, pajakBeli, pajakBeliNominal]);

	// Harga - HPP
	useEffect(() => {
		setHargaJualMinimal(hargabelitotal);
	}, [hargabelitotal]);

	// Harga - Margin
	useEffect(() => {
		marginglobalnominal && margin === "marginNominal" && setMarginGlobalPersen((marginglobalnominal / hargajualminimal) * 100);
		marginglobalpersen && margin === "marginPersen" && setMarginGlobalNominal(hargajualminimal * (marginglobalpersen / 100));
	}, [hargajualminimal, marginglobalpersen, marginglobalnominal]);

	// Harga - Diskon
	useEffect(() => {
		diskonnominal && diskonJual === "diskonNominal" && setDiskonPersen((diskonnominal / hargajualminimal) * 100);
		diskonpersen && diskonJual === "diskonPersen" && setDiskonNominal(hargajualminimal * (diskonpersen / 100));
	}, [hargajualminimal, diskonpersen, diskonnominal]);

	// Harga HPP + MarginNominal - DiskonNominal
	useEffect(() => {
		setHMD(Number(hargajualminimal) + Number(marginglobalnominal) - Number(diskonnominal) );
	}, [hargajualminimal,marginglobalnominal,diskonnominal]);

	// Harga - PPN
	useEffect(() => {
		setPajakJualNominal(Number(hmd));
	}, [hargajualminimal, pajakjual, pajakjualnominal,hmd]);

	// PPN Price 2
	useEffect(() => {
		setPajakJualNominal2((Number(hargajualminimal) + Number(margin2) - Number(diskonnominal)));
	}, [hargajualminimal, pajakjual, diskonnominal,margin2]);

	// PPN Price 3
	useEffect(() => {
		setPajakJualNominal3((Number(hargajualminimal) + Number(margin3) - Number(diskonnominal)));
	}, [hargajualminimal, pajakjual, diskonnominal,margin3]);

	// PPN Price 4
	useEffect(() => {
		setPajakJualNominal4((Number(hargajualminimal) + Number(margin4) - Number(diskonnominal)));
	}, [hargajualminimal, pajakjual, diskonnominal,margin4]);

	// PPN Price 5
	useEffect(() => {
		setPajakJualNominal5((Number(hargajualminimal) + Number(margin5) - Number(diskonnominal)));
	}, [hargajualminimal, pajakjual, diskonnominal,margin5]);

	// Harga - Harga Jual
	useEffect(() => {
			setHargaJual(Number(hargajualminimal)+ Number(marginglobalnominal) - Number(diskonnominal));
	}, [hargajualminimal, marginglobalnominal, diskonnominal, pajakjualnominal]);

	// Margin 1

	useEffect(() => {
		margin1 && marginke1 === "marginnominal1" && setPersentase1((margin1 / hargajualminimal) * 100);
		persentase1 && marginke1 === "marginpersentase1" && setMargin1(hargajualminimal * (persentase1 / 100));
	}, [margin1, hargajualminimal, persentase1]);

	useEffect(() => {
		setPersentase1(marginglobalpersen);
		setMargin1(marginglobalnominal);
	}, [margin1, hargajual, persentase1]);

	// Margin 2
	useEffect(() => {
		margin2 && marginke2 === "marginnominal2" && setPersentase2((margin2 / hargajualminimal) * 100);
		persentase2 && marginke2 === "marginpersentase2" && setMargin2(hargajualminimal * (persentase2 / 100));
	}, [margin2, hargajualminimal, persentase2]);

	// Margin 3
	useEffect(() => {
		margin3 && marginke3 === "marginnominal3" && setPersentase3((margin3 / hargajualminimal) * 100);
		persentase3 && marginke3 === "marginpersentase3" && setMargin3(hargajualminimal * (persentase3 / 100));
	}, [margin3, hargajualminimal, persentase3]);

	// Margin 4
	useEffect(() => {
		margin4 && marginke4 === "marginnominal4" && setPersentase4((margin4 / hargajualminimal) * 100);
		persentase4 && marginke4 === "marginpersentase4" && setMargin4(hargajualminimal * (persentase4 / 100));
	}, [margin4, hargajualminimal, persentase4]);

	// Margin 5
	useEffect(() => {
		margin5 && marginke5 === "marginnominal5" && setPersentase5((margin5 / hargajualminimal) * 100);
		persentase5 && marginke5 === "marginpersentase5" && setMargin5(hargajualminimal * (persentase5 / 100));
	}, [margin5, hargajualminimal, persentase5]);

	useEffect(() => {
		margin6 && marginke6 === "marginnominal6" && setPersentase6((margin6 / hargajualminimal) * 100);
		persentase6 && marginke6 === "marginpersentase6" && setMargin6(hargajualminimal * (persentase6 / 100));
	}, [margin6, hargajualminimal, persentase6]);

	//price 1

	useEffect(() => {
		setPrice1(hargajual);
	}, [hargajual]);
	
	//price 2

	useEffect(() => {
		margin2 && hargajualminimal && setPrice2(Number(hargajualminimal) +  Number(margin2) - Number(diskonnominal));
	}, [margin2, hargajualminimal,diskonnominal]);

	//price 3

	useEffect(() => {
		margin3 && hargajualminimal && setPrice3(Number(hargajualminimal) +  Number(margin3) - Number(diskonnominal));
	}, [margin3, hargajualminimal,diskonnominal]);

	//price 4

	useEffect(() => {
		margin4 && hargajualminimal && setPrice4(Number(hargajualminimal) +  Number(margin4) - Number(diskonnominal));
	}, [margin4, hargajualminimal,diskonnominal]);

	//price 5

	useEffect(() => {
		margin5 && hargajualminimal && setPrice5(Number(hargajualminimal) +  Number(margin5) - Number(diskonnominal));
	}, [margin5, hargajualminimal,diskonnominal]);

	useEffect(() => {
		margin6 && hargajualminimal && setPrice6(Number(hargajualminimal) +  Number(margin6) - Number(diskonnominal));
	}, [margin6, hargajualminimal,diskonnominal]);
	

	useEffect(() => {
		getPajakJual();
		getPajakBeli();
	}, []);

	const getPajakJual = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
			.then((data) => {
				setAllPajak(data.data.response);
				setPajakJual(id)
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	
	const getPajakBeli = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
			.then((data) => {
				setAllPajaks(data.data.response);
				setPajakBeli(id);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	function EditPrice() {
		let data = {
			warehouse_id :parseInt(wid),
			item_id : parseInt(id),
			username: username,
			hpp_beli : parseFloat(hargabelitotal),
			hpp_jual : parseFloat(hargajualminimal),
			harga_beli: parseInt(hargaBeli),
			harga_jual : parseInt(hargajual),
			margin_global_nominal: parseInt(marginglobalnominal),
			margin_global_persen : parseInt(marginglobalpersen),
			diskon_persen_suplier: parseFloat(diskonsuplierpersen),
			diskon_nominal_suplier: parseFloat(diskonsupliernominal),
			pajak_beli: parseFloat(pajakBeli),
			pajak_jual: parseFloat(pajakjual),
			diskon_persen: parseFloat(diskonpersen),
			diskon_nominal: parseFloat(diskonnominal),
			nominal_1: parseFloat(margin1),
			persentase_1: parseFloat(persentase1),
			min_qty_1: parseFloat(qty1),
			price_1:parseFloat(price1),
			nominal_2: parseFloat(margin2),
			persentase_2: parseFloat(persentase2),
			min_qty_2: parseFloat(qty2),
			price_2:parseFloat(price2),
			nominal_3: parseFloat(margin3),
			persentase_3: parseFloat(persentase3),
			min_qty_3: parseFloat(qty3),
			price_3:parseFloat(price3),
			nominal_4: parseFloat(margin4),
			persentase_4: parseFloat(persentase4),
			min_qty_4: parseFloat(qty4),
			price_4:parseFloat(price4),
			nominal_5: parseFloat(margin5),
			persentase_5: parseFloat(persentase5),
			min_qty_5: parseFloat(qty5),
			price_5:parseFloat(price5),
			nominal_6: parseFloat(margin6),
			persentase_6: parseFloat(persentase6),
			active_flag: 1,
		};
		axios
			.post(`${process.env.REACT_APP_API_BASE_URL}/price-detail/update`, data, {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			})
			.then(function (response) {
				setSuccessAlert(response.data.message);
				setTimeout(() => (window.history.back()), 1000);
				})
				.catch(function (error) {
				  setGagalAlert(error.response.data.message);
				});
		  }
		
		  const setSuccessAlert = (id) => {
			setAlert(
			  <SweetAlert
				success
				showConfirm
				confirmBtnText="Ok"
				title={id}
				onCancel={hideAlert}
				onConfirm={hideAlert}
			  />
			)
		  }
		
		const setGagalAlert = (id) => {
		setAlert(
		  <SweetAlert
		  danger
		  showConfirm
		  confirmBtnText="Ok"
		  title={id}
		  onCancel={hideAlert}
		  onConfirm={hideAlert}
		  />
		);
		};
		
		const setQuestionAlert = () => {
		setAlert(
		  <SweetAlert
		  warning
		  showCancel
		  confirmBtnText="Lanjutkan"
		  confirmBtnBsStyle="danger"
		  title="Apakah Kamu Yakin?"
		  onConfirm={EditPrice}
		  onCancel={hideAlert}
		  focusCancelBtn
		  />
		);
		};
		
		  const hideAlert = () => {
			setAlert(null);
		  }


    const handleSubmit1 = () => 
	{
		window.history.back();
	};
	
		const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
		const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");
	

	const formatRupiah = (money) => {
		return new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR", minimumFractionDigits: 0 }).format(money);
	};
	  
	const pricehargabeli = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setHargaBeli(value || ' ');
																
	  };

	  const diskonpersensupplier = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setDiskonSuplierPersen(value || ' ');
		setDiskon("diskonPersen")
																
	  };

	  const diskonnominalsupplier = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setDiskonSuplierNominal(value || ' ');
		setDiskon("diskonNominal")
																
	  };

	const pricehargabelitotal = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setHargaBeliTotal(value || ' ');
																
	  };

	const pricehpp = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setHargaJualMinimal(value || ' ');
																
	  };

	const marginpersenhj = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setMarginGlobalPersen(value || ' ');
		setMargin("marginPersen")
																
	  };

	  const marginnominalhj = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setMarginGlobalNominal(value || ' ');
		setMargin("marginNominal")
																
	  };

	const diskonpersenhj = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setDiskonPersen(value || ' ');
		setDiskonJual("diskonPersen");
	  };

	  const diskonnominalhj = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setDiskonNominal(value || ' ');
		setDiskonJual("diskonNominal");
	  };

	const hargapricejual = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setHargaJual(value || ' ')
	
	  };

	const hargaprice1 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPrice1(value || ' ')
	
	  };

	  const hargaprice2 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPrice2(value || ' ')
	
	  };

	  const hargaprice3 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPrice3(value || ' ')
	
	  };

	  const hargaprice4 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPrice4(value || ' ')
	
	  };

	  const hargaprice5 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPrice5(value || ' ')
	
	  };

	  const hargaprice6 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPrice6(value || ' ')
	
	  };

	  const nominalmargin1 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setMargin1(value || ' ');
		setMarginKe1("marginnominal1")
	
	  };

	  const nominalmargin2 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setMargin2(value || ' ');
		setMarginKe2("marginnominal2")
	
	  };

	  const nominalmargin3 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setMargin3(value || ' ');
		setMarginKe3("marginnominal3")
	
	  };

	  const nominalmargin4 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setMargin4(value || ' ');
		setMarginKe4("marginnominal4")
	
	  };

	  const nominalmargin5 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setMargin5(value || ' ');
		setMarginKe5("marginnominal5")
	
	  };

	  const nominalmargin6 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setMargin6(value || ' ');
		setMarginKe6("marginnominal6")

	
	  };

	  const persenmargin1 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPersentase1(value || ' ');
		setMarginKe1("marginpersentase1")
	  };

	  const persenmargin2 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPersentase2(value || ' ');
		setMarginKe2("marginpersentase2")
	  };

	  const persenmargin3 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPersentase3(value || ' ');
		setMarginKe3("marginpersentase3")
	  };

	  const persenmargin4 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPersentase4(value || ' ');
		setMarginKe4("marginpersentase4")
	  };

	  const persenmargin5 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPersentase5(value || ' ');
		setMarginKe5("marginpersentase5")
	  };

	  const persenmargin6 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPersentase6(value || ' ');
		setMarginKe6("marginpersentase6")

	  };

	return (
		<>
		{alert}
		{itempusat && itempusat === "1" ? (
			<div>
				<SimpleHeader name="Edit Harga" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
							<CardBody>
								<Card className="bg-secondary shadow">
									<CardHeader className="bg-white border-0">
										<h3>HARGA</h3>
									</CardHeader>
									<CardBody>
										<Row md="12">
											<Col md="6">
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Harga Beli
													</Label>
													<Col sm={7}>
														<CurrencyInput
															disabled
															id="validation-example-2-field"
															placeholder="Rp0"
															allowDecimals={false}
															value={hargaBeli}
															onValueChange={pricehargabeli}
															className={`form-control`}
															prefix={'Rp'}
															step={10}
														/>
													</Col>
												</FormGroup>
												<FormGroup row floating>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Diskon
													</Label>
													<Col sm={3}>
														<CurrencyInput
															disabled
															id="validation-example-2-field"
															placeholder="Rp0"
															allowDecimals={false}
															value={diskonsuplierpersen}
															onValueChange={diskonpersensupplier}
															className={`form-control`}
															suffix={'%'}
															step={10}
														/>
													</Col>
													<Col sm={4}>
														<CurrencyInput
															disabled
															id="validation-example-2-field"
															placeholder="Rp0"
															allowDecimals={false}
															value={diskonsupliernominal}
															onValueChange={diskonnominalsupplier}
															className={`form-control`}
															prefix={'Rp'}
															step={10}
														/>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														PPN
													</Label>
													<Col sm={7}>
														<Input
														disabled
															autoComplete="off"
															type="select"
															value={pajakBeli}
															onChange={(e) => {
																setPajakBeli(e.target.value);
															}}
															>
															<option value="">Pilih PPN</option>
															{allpajaks.map((customer, key) => {
																return (
																	<option key={key} value={customer.persentase}>
																		{customer.keterangan}
																	</option>
																);
															})}
														</Input>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Harga Beli Total
													</Label>
													<Col sm={7}>
														<CurrencyInput
															disabled
															id="validation-example-2-field"
															placeholder="Rp0"
															allowDecimals={false}
															value={hargabelitotal}
															onValueChange={pricehargabelitotal}
															className={`form-control`}
															prefix={'Rp'}
															step={10}
														/>
													</Col>
												</FormGroup>
											</Col>
											<Col md="6">
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														HPP
													</Label>
													<Col sm={7}>
														<CurrencyInput
															disabled
															id="validation-example-2-field"
															placeholder="Rp0"
															allowDecimals={false}
															value={hargajualminimal}
															onValueChange={pricehpp}
															className={`form-control`}
															prefix={'Rp'}
															step={10}
														/>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="marginpersen" sm={4}>
														Margin
													</Label>
													<Col sm={3}>
														<CurrencyInput
															id="validation-example-2-field"
															placeholder="Rp0"
															allowDecimals={false}
															value={marginglobalpersen}
															onValueChange={marginpersenhj}
															className={`form-control`}
															suffix={'%'}
															step={10}
														/>
													</Col>
													<Col sm={4}>
														<CurrencyInput
															id="validation-example-2-field"
															placeholder="Rp0"
															allowDecimals={false}
															value={marginglobalnominal}
															onValueChange={marginnominalhj}
															className={`form-control`}
															prefix={'Rp'}
															step={10}
														/>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Diskon
													</Label>
													<Col sm={3}>
														<CurrencyInput
															id="validation-example-2-field"
															placeholder="Rp0"
															allowDecimals={false}
															value={diskonpersen}
															onValueChange={diskonpersenhj}
															className={`form-control`}
															suffix={'%'}
															step={10}
														/>
													</Col>
													<Col sm={4}>
														<CurrencyInput
															id="validation-example-2-field"
															placeholder="Rp0"
															allowDecimals={false}
															value={diskonnominal}
															onValueChange={diskonnominalhj}
															className={`form-control`}
															prefix={'Rp'}
															step={10}
														/>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														PPN
													</Label>
													<Col sm={7}>
														<Input
															autoComplete="off"
															type="select"
															value={pajakjual}
															onChange={(e) => {
																setPajakJual(e.target.value);
															}}
															>
															<option value="" disabled selected hidden>Pilih PPN</option>
															{allpajak.map((customer, key) => {
																return (
																	<option key={key} value={customer.persentase}>
																		{customer.keterangan}
																	</option>
																);
															})}
														</Input>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Harga Jual
													</Label>
													<Col sm={7}>
														<CurrencyInput
															disabled
															id="validation-example-2-field"
															placeholder="Rp0"
															allowDecimals={false}
															value={hargajual}
															onValueChange={hargapricejual}
															className={`form-control`}
															prefix={'Rp'}
															step={10}
														/>
													</Col>
												</FormGroup>
											</Col>
										</Row>
										<Table>
											<tbody>
												<tr>
													<td>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Level
														</Label>
													</td>
													<td>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															type="text"
															name="Level1"
															value={level1}
															onChange={(e) => {
																setLevel1(e.target.value);
															}}
															disabled
														/>
													</td>
													<td>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															type="text"
															name="Level2"
															value={level2}
															onChange={(e) => {
																setLevel2(e.target.value);
															}}
															disabled
														/>
													</td>
													<td>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															type="text"
															name="Level3"
															value={level3}
															onChange={(e) => {
																setLevel3(e.target.value);
															}}
															disabled
														/>
													</td>
													<td>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															type="text"
															name="Level4"
															value={level4}
															onChange={(e) => {
																setLevel4(e.target.value);
															}}
															disabled
														/>
													</td>
													<td>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															type="text"
															name="Level5"
															
															value={level5}
															onChange={(e) => {
																setLevel5(e.target.value);
															}}
															disabled
														/>
													</td>
													<td>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															type="text"
															name="Level5"
															placeholder="Level 6"
															value={level6}
															onChange={(e) => {
																setLevel6(e.target.value);
															}}
															disabled
														/>
													</td>
												</tr>
												<tr>
													<td>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Min Qty
														</Label>
													</td>
													<td>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															type="number"
															name="qty1"
															placeholder="Qty 1"
															value={qty1}
															onChange={(e) => {
																setQty1(e.target.value);
															}}
														/>
													</td>
													<td>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															type="number"
															name="qty2"
															placeholder="Qty 2"
															value={qty2}
															onChange={(e) => {
																setQty2(e.target.value);
															}}
														/>
													</td>
													<td>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="qty3"
															type="number"
															placeholder="Qty 3"
															value={qty3}
															onChange={(e) => {
																setQty3(e.target.value);
															}}
														/>
													</td>
													<td>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="qty4"
															type="number"
															placeholder="Qty 4"
															value={qty4}
															onChange={(e) => {
																setQty4(e.target.value);
															}}
														/>
													</td>
													<td>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="qty5"
															type="number"
															placeholder="Qty 5"
															value={qty5}
															onChange={(e) => {
																setQty5(e.target.value);
															}}
														/>
													</td>
													<td>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="qty5"
															type="number"
															placeholder="Qty 6"
															value={qty6}
															onChange={(e) => {
																setQty6(e.target.value);
															}}
														/>
													</td>
												</tr>
												<tr>
													<td>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Price
														</Label>
													</td>
													<td>
														<CurrencyInput
														disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={price1}
																onValueChange={hargaprice1}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
													</td>
													<td>
													<CurrencyInput
													disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={price2}
																onValueChange={hargaprice2}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
													</td>
													<td>
													<CurrencyInput
													disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={price3}
																onValueChange={hargaprice3}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
													</td>
													<td>
													<CurrencyInput
													disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={price4}
																onValueChange={hargaprice4}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
													</td>
													<td>
													<CurrencyInput
													disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={price5}
																onValueChange={hargaprice5}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
													</td>
													<td>
													<CurrencyInput
													disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={price6}
																onValueChange={hargaprice6}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
													</td>
												</tr>
												<tr>
													<td>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Margin (N)
														</Label>
													</td>
													<td>
														<CurrencyInput
														disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={margin1}
																onValueChange={nominalmargin1}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
													</td>
													<td>
															<CurrencyInput
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={margin2}
																onValueChange={nominalmargin2}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
													</td>
													<td>
													<CurrencyInput
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={margin3}
																onValueChange={nominalmargin3}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
													</td>
													<td>
													<CurrencyInput
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={margin4}
																onValueChange={nominalmargin4}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
													</td>
													<td>
													<CurrencyInput
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={margin5}
																onValueChange={nominalmargin5}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
													</td>
													<td>
													<CurrencyInput
																// disabled
																id="validation-example-2-field"
																placeholder="Rp0"
																allowDecimals={false}
																value={margin6}
																onValueChange={nominalmargin6}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
													</td>
												</tr>
												<tr>
													<td>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Margin %
														</Label>
													</td>
													<td>
														<CurrencyInput
															disabled
															id="validation-example-2-field"
															placeholder="Rp0"
															allowDecimals={false}
															value={persentase1}
															onValueChange={persenmargin1}
															className={`form-control`}
															suffix={'%'}
															step={10}
														/>
													</td>
													<td>
													<CurrencyInput
															id="validation-example-2-field"
															placeholder="Rp0"
															allowDecimals={false}
															value={persentase2}
															onValueChange={persenmargin2}
															className={`form-control`}
															suffix={'%'}
															step={10}
														/>
													</td>
													<td>
													<CurrencyInput
															id="validation-example-2-field"
															placeholder="Rp0"
															allowDecimals={false}
															value={persentase3}
															onValueChange={persenmargin3}
															className={`form-control`}
															suffix={'%'}
															step={10}
														/>
													</td>
													<td>
													<CurrencyInput
															id="validation-example-2-field"
															placeholder="Rp0"
															allowDecimals={false}
															value={persentase4}
															onValueChange={persenmargin4}
															className={`form-control`}
															suffix={'%'}
															step={10}
														/>
													</td>
													<td>
													<CurrencyInput
															id="validation-example-2-field"
															placeholder="Rp0"
															allowDecimals={false}
															value={persentase5}
															onValueChange={persenmargin5}
															className={`form-control`}
															suffix={'%'}
															step={10}
														/>
													</td>
													<td>
													<CurrencyInput
															// disabled
															id="validation-example-2-field"
															placeholder="Rp0"
															allowDecimals={false}
															value={persentase6}
															onValueChange={persenmargin6}
															className={`form-control`}
															suffix={'%'}
															step={10}
														/>
													</td>
												</tr>
											</tbody>
										</Table>
									</CardBody>
									<CardFooter>
										<Button color="info" onClick={() => handleSubmit1()}>
											Kembali
										</Button>
										<Button color="danger" onClick={setQuestionAlert}>
											Simpan
										</Button>
									</CardFooter>
								</Card>
							</CardBody>
						</div>
					</Row>
				</Container>
				</div>
		):(
			<Halaman404 />
		)}
		</>
	);
}
