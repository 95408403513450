/*eslint-disable*/
import React, { useEffect, useState } from 'react';
  import { Card, Button, Row, Col, CardBody, CardHeader, Badge, Container, Form, FormGroup, Label, Input,DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import paginationFactory from "react-bootstrap-table2-paginator";
import Select2 from "react-select2-wrapper";
import ModalCetakMemo from '../po/MemoKurirPo/CetakMemo/ModalCetakMemo';
import SweetAlert from "react-bootstrap-sweetalert";
import ModalCetakPoCabang from './OrderPusat/Cetak/ModalCetakPoCabang';
import Halaman404 from 'views/404';
import HeaderTotal from 'components/Headers/HeaderTotal';

const PurchaseOrderCabang = () => {
  const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle2 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
  const token = localStorage.token;
  const warehouse = parseInt(localStorage.warehouse);
  const [allPo, setAllPo] = useState([]);
  const [uomCode, setUomCode] = useState("");
  const [tanggal, setTanggal] = useState("")
  const [description, setDescription] = useState("");
  const [direktur, setDirektur] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [totalItem1, setTotalItem1] = useState(0);
  const [proses, setProses] = useState(0);
  const [tolak, setTolak] = useState(0);
  const [setuju, setSetuju] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [openModalCetak, setOpenModalCetak] = useState(false);
  const [dataModalCetak, setDataModalCetak] = useState({ id: 0,});
  const toggle = () => setOpenModalCetak(!openModalCetak);
  const [openModalCetak1, setOpenModalCetak1] = useState(false);
  const [dataModalCetak1, setDataModalCetak1] = useState({ id: 0,});
  const toggle1 = () => setOpenModalCetak1(!openModalCetak1);
  const [persons, setPersons] = useState([]);
  const allInfo = JSON.parse(localStorage.allinfo);
	const order = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub PO Cabang").map((p) => p.read_access));
	const cetak = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Cetak PO Cabang").map((p) => p.read_access));
  
  useEffect(() => {
    getPerson();
  }, []);

  const getPerson = () => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/person/list`, { headers
      })
      .then(data => {
        setPersons(data.data.response);
      })
        .catch(function (error) {
          console.log(error)
        })
    }

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort,tanggal, uomCode, description, direktur);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, tanggal, uomCode, description, direktur) => {
    getPo(page, perPage, sort,tanggal, uomCode, description, direktur);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setTanggal(tanggal)
    setUomCode(uomCode);
    setDirektur(direktur);
    setDescription(description);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort,tanggal,  uomCode, description, direktur)
    }
  }

  useEffect(() => {
    gettotal();
    getProses();
    getTolak();
    getSetuju()
  }, []);

  // fungsi dari ambil data
  const gettotal = () => {
    let filter = { 
      page: page, 
      per_page: perPage,
      warehouse_id: parseInt(warehouse),
    };
    
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/page`, data, {
        headers,
      })
      .then((data) => {
        setTotalItem1(data.data.total_item);
      })
      .catch(function (error) {
      });
}

const getProses = () => {
    let filter = { 
      page: page, 
      per_page: perPage,
      approve:3, 
      status_ap:3,
      status_d: 3,
      warehouse_id: parseInt(warehouse),
    };
    
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/page`, data, {
        headers,
      })
      .then((data) => {
        setProses(data.data.total_item);
      })
      .catch(function (error) {
      });
}

const getTolak = () => {
    let filter = { 
      page: page, 
      per_page: perPage,
      approve:3, 
      status_ap:3,
      status_d: 4,
      warehouse_id: parseInt(warehouse),
    };
    
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/page`, data, {
        headers,
      })
      .then((data) => {
        setTolak(data.data.total_item);
      })
      .catch(function (error) {
      });
}

const getSetuju = () => {
    let filter = { 
      page: page, 
      per_page: perPage,
      approve:5, 
      status_ap:5,
      status_d: 5,
      warehouse_id: parseInt(warehouse),
    };
    
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/page`, data, {
        headers,
      })
      .then((data) => {
        setSetuju(data.data.total_item);
      })
      .catch(function (error) {
      });
}

  
  useEffect(() => {
    getPo(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getPo = (page, perPage, currentSort,transaction_date=null, po_code = null, person_id = null, status_d=null) => {
    
    let filter = { 
      
      page: page, 
      per_page: perPage, 
      approve:5, 
      status_ap:5,
      warehouse_id:parseInt(warehouse)
    };
    if (transaction_date !== "") {
      filter = Object.assign(filter, { transaction_date: transaction_date });
    }
    if (po_code !== null) {
      filter = Object.assign(filter, { po_code: po_code });
    }
    if (person_id !== null) {
      filter = Object.assign(filter, { person_id: person_id });
    }
    if (status_d !== null) {
      filter = Object.assign(filter, { status_d: status_d });
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/page`, data, {
        headers,
      })
      .then((data) => {
        setAllPo(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        setAllPo(error.response.data.response);
  });
}

  const reset = () => {
    setTanggal("")
    setUomCode("");
    setDescription("");
    setDirektur("");
    updateDataTable(1, perPage, currentSort, "", "");
  }

  const displayModalCetak = (id) => {
    setDataModalCetak({
      id: id,
    });
    setOpenModalCetak(true);
  };

  const displayModalCetak1 = (id) => {
    setDataModalCetak1({
      id: id,
    });
    setOpenModalCetak1(true);
  };

  const getDataLog = (id) => {
		let filter = { 
			transaction : id,
			features : "BELI4",
			warehouse_id : warehouse
		}
		const data = filter;
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
			setDataLog(data.data.response);
            setModal1(!modal1);

		})
		  .catch(function (error) {
			setGagalAlert(error.response.data.message);
		  })
	  }

	  const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
		);
		};
		
		const hideAlert = () => {
		  setAlert(null);
		};

  return (
    <div>
      {alert}
      {order && order === "1" ? (
			<div>
    <SimpleHeader name="Pembelian Barang" parentName="Report" />
      <HeaderTotal 
        totalItem = {totalItem1}
        proses ={proses}
        tolak={tolak}
        setuju={setuju}

        />
      <Container className="mt--5" fluid>
        <Row>
          <div className="col">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                </div>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row md="12">
                    <Col md="3">
                      <FormGroup>
                        <Label className="form-control-label"
                                htmlFor="exampleFormControlSelect3">
                          Tanggal
                        </Label>
                        <Input
                        className="form-control-alternative"
                          type="date"
                          placeholder="Masukan Tanggal"
                          value={tanggal}
                          onChange={(e) =>
                            updateDataTable(
                              1,
                              perPage,
                              currentSort,
                              e.target.value,
                              uomCode,
                              description,
                              direktur
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label className="form-control-label"
                                htmlFor="exampleFormControlSelect3">
                          Kode PO
                        </Label>
                        <Input
                        className="form-control-alternative"
                          type="text"
                          placeholder="Masukan Kode PO"
                          value={uomCode}
                          onChange={(e) =>
                            updateDataTable(
                              1,
                              perPage,
                              currentSort,
                              tanggal,
                              e.target.value,
                              description,
                              direktur
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                    <FormGroup> 
                            <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect3"
                            >
                                Supplier
                            </Label>
                            <Select2
                                      className="form-control-alternative"
                                      defaultValue="0"
                                      value={description}
                                      onChange={(e) =>
                                        updateDataTable(1, perPage, currentSort,tanggal,uomCode, e.target.value,direktur)
                                        }
                                      options={{
                                        placeholder: "Pilih Supplier"
                                      }}
                                      data={persons.map((person, key) => {
                                        return (
                                        { id: person.id, text: person.person_name, key:key}
                                        );
                                      })}
                                    />
                            </FormGroup>
                    </Col>
                    <Col md="3">
                              <FormGroup>
                                <Label className="form-control-label" htmlFor="exampleFormControlSelect3">
                                  Status
                                </Label>
                                <Input
                                  type="select"
                                  placeholder="Masukan Kode"
                                  value={direktur}
                                  onChange={e => updateDataTable(1, perPage, currentSort,tanggal, uomCode, description, e.target.value)}
                                >
                                  <option value={''}>Pilih Status </option>
                                  <option value={3}>Proses</option>
                                  <option value={4}>Tolak</option>
                                  <option value={5}>Setuju</option>
                                </Input>
                              </FormGroup>
                        </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button type="button" onClick={reset} color="secondary">
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </Form>
                {allPo === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                      dataField: "no",
                                      text: "#",
                                      sort: true,
                                      page: 1,
                                      formatter: (cell, row, index) => {
                                      let currentRow = ++index;
                                      return currentRow + rowIndex;
                                      },
                                  },
                                {
                                  dataField: "",
                                  text: "Tanggal",
                                  sort: true,
                                },
                                {
                                  dataField: "",
                                  text: "Kode PO",
                                  sort: true,
                                },
                                {
                                    dataField: "",
                                    text: "Supplier",
                                    sort: true,
                                },
                                {
                                  dataField: "",
                                  text: "Status",
                                  sort: true,
                                  
                              },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                        </div>
                        ):(
                    <ToolkitProvider
                            rowNumber={rowIndex}
                            data={allPo}
                            keyField="id"
                            columns={[
                            {
                                dataField: "no",
                                text: "#",
                                sort: true,
                                page: 1,
                                formatter: (cell, row, index) => {
                                let currentRow = ++index;
                                return currentRow + rowIndex;
                                },
                            },
                            {
                              dataField: "transaction_date",
                              text: "Tanggal",
                              sort: true,
                            },
                            {
                              dataField: "po_code",
                              text: "Kode PO",
                              sort: true,
                              formatter: (cell, row) => {
                                return row.po_code === ""
                                  ? <h2>
                                    <Badge color="warning" >
                                      {row.po_code}
                                    </Badge>
                                    </h2>
                                  : 
                                  <h2>
                                  <Badge color="warning" >
                                      {row.po_code}
                                    </Badge>
                                    </h2>
                              },
                            },
                            {
                                dataField: "person_name",
                                text: "Supplier",
                                sort: true,
                            },
                            {
                              dataField: "status_d",
                              text: "Status",
                              sort: true,
                              formatter: (cell, row) => {
                                return row.status_d === 3
                                  ? 
                                    <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-danger" />
                                      Proses
                                    </Badge>
                                  : row.status_d === 4
                                  ? <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-warning" />
                                      Tolak
                                    </Badge>
                                  : <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-success" />
                                      Setuju
                                    </Badge>;
                              },
                          },
                            {
                              dataField: "",
                              text: "",
                              formatter: (cell, row, index) => {
                                return row.status_d === 3
                                  ? <UncontrolledDropdown nav>
                                      <DropdownToggle color="danger">
                                          Tindakan
                                        </DropdownToggle>
                                        <DropdownMenu center>
                                        {cetak && cetak === "1" && (
                                            <DropdownItem
                                              onClick={() => displayModalCetak(row.id)}
                                            >
                                              <i className="fas fa-print" />
                                              <span>Cetak</span>
                                            </DropdownItem>
                                            )}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                  : row.status_d === 4
                                  ?  <UncontrolledDropdown nav>
                                  <DropdownToggle color="danger">
                                      Tindakan
                                    </DropdownToggle>
                                    <DropdownMenu center>
                                    {cetak && cetak === "1" && (
                                          <DropdownItem
                                            onClick={() => displayModalCetak(row.id)}
                                          >
                                            <i className="fas fa-print" />
                                            <span>Cetak</span>
                                          </DropdownItem>
                                    )}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                  : <UncontrolledDropdown nav>
                                  <DropdownToggle color="danger">
                                      Tindakan
                                    </DropdownToggle>
                                    <DropdownMenu center>
                                    <Link  onClick={() => getDataLog(row.id)}>
                                                <DropdownItem>
                                                <i className="fa fa-info-circle" aria-hidden="true"></i> <span>Info</span>
                                                </DropdownItem>
                                            </Link>
                                        {/* {memoKurirPO && memoKurirPO === "1" && (
                                          <DropdownItem
                                            onClick={() => displayModalCetak1(row.id)}
                                          >
                                            <i className="fas fa-print" />
                                            <span>Cetak Memo</span>
                                          </DropdownItem>
                                          )} */}
                                          {cetak && cetak === "1" && (
                                             <Link onClick={() => displayModalCetak(row.id)}>
                                              <DropdownItem>
                                                <i className="fas fa-print" />
                                                <span>Cetak</span>
                                          </DropdownItem>
                                          </Link>
                                          )}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                              },
                            },
                            ]}
                        >
                            {(props) => (
                            <div className="py-4 table-responsive"> 
                                <BootstrapTable
                                remote
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                                hover={true}
                                pagination={paginationFactory({ ...paginationOption })}
                                onTableChange={handleTableChange}
                                />
                            </div>
                            )}
                    </ToolkitProvider>
                        )}
              </CardBody>
            </Card>
          </div>
        </Row>
      {openModalCetak && (
        <ModalCetakPoCabang
          open={openModalCetak}
          data={dataModalCetak}
          toggle={toggle}
        />
      )}
      {openModalCetak1 && (
        <ModalCetakMemo
          open={openModalCetak1}
          data={dataModalCetak1}
          toggle={toggle1}
        />
      )}
      </Container>
      <Modal isOpen={modal1} toggle={toggle2} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle2} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle2}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>
      </div>
      ):(
        <Halaman404 />
      )}
    </div>
  );
}

export default PurchaseOrderCabang;
