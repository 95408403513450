/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
	Col,
	Container,
	CardGroup,
	DropdownMenu,
	Badge,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Barcodee from "react-barcode";
import QRCode from "qrcode.react";
import Halaman404 from "views/404";

export default function DetailRegion(props) {
	const token = localStorage.token;
	const redirectPrefix1 = `/admin/item/region-detail/edit-harga/itm/`;
	const redirectPrefix6 = `/cbg/`;
	const [allOpname, setAllOpname] = useState([]);
	const [rowIndex, setRowIndex] = useState(0);
	const [page, setPage] = useState(1);
	const [perPage, setPerpage] = useState(1);
	const [totalItem, setTotalItem] = useState(0);
	const [currentSort, setCurrentSort] = useState("");
	const [itemCode, setItemCode] = useState("");
	const [nameItem, setNameItem] = useState("");
	const [description, setDescription] = useState("");
	const [itemproduksi, setItemProduksi] = useState("");
	const [satuan, setSatuan] = useState("");
	const [grade, setGrade] = useState("");
	const [category, setCategory] = useState("")
	const [subCategory, setSubCategory] = useState("");
	const [nameFunction, setNameFunction] = useState("")
	const [nameSubFunction, setNameSubFunction] = useState("");
	const [merek, setMerek] = useState("");
	const [submerek, setSubMerek] = useState("");
	const [panjang, setPanjang] = useState("");
	const [lebar, setLebar] = useState("");
	const [tinggi, setTinggi] = useState("");
	const [nameType, setNameType] = useState("");
	const [barcode, setBarcode] = useState("");
	const [status, setStatus] = useState("");
	const [minimumstock, setMinimumStock] = useState("");
	const [itemgrup, setItemGrup] = useState("");
	const [berat, setBerat] = useState("");
	const [image1, setImage1] = useState("");
	const [image2, setImage2] = useState("");
	const [image3, setImage3] = useState("");
	// const redirectPrefix = `/admin/item/region-detail/kartu-stok/itm/`;
	// const redirectPrefix5 = `/cbg/`;
	const allInfo = JSON.parse(localStorage.allinfo);
	const itempusat = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Item Pusat").map((p) => p.read_access));
	

	useEffect(() => {
		getById();
	}, []);

	  const getById = () => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/get-items-detail?id=${props.match.params.itemid}&wid=${props.match.params.id}`,
	        { headers }
	      )
	      .then((data) => {
			setSatuan(data.data.response.satuan);
			setGrade(data.data.response.grade);
			setCategory(data.data.response.kategori);
			setSubCategory(data.data.response.sub_kategori);
			setNameFunction(data.data.response.function);
			setNameSubFunction(data.data.response.sub_function);
			setMerek(data.data.response.merek);
			setSubMerek(data.data.response.sub_merek);
			setItemGrup(data.data.response.group)
			setBarcode(data.data.response.barcode);
	        setBerat(data.data.response.berat);
	        setStatus(data.data.response.active_flag);
	        setSatuan(data.data.response.uom_code);
	        setMerek(data.data.response.merek_name);
	        setNameItem(data.data.response.item_name);
	        setDescription(data.data.response.item_description);
	        setPanjang(data.data.response.panjang);
	        setLebar(data.data.response.lebar);
	        setImage1(data.data.response.image_1);
	        setImage2(data.data.response.image_2);
	        setImage3(data.data.response.image_3);
	        setTinggi(data.data.response.tinggi);
	        setMinimumStock(data.data.response.minimum_stok);
	        setItemCode(data.data.response.item_code);
			setNameType(data.data.response.type);
			setItemProduksi(data.data.response.part);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

    useEffect(() => {
      getById4();
  }, []);
  
    const getById4 = () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/get-items-pusat?id=${props.match.params.itemid}&wid=${props.match.params.id}`,
          { headers }
        )
        .then((data) => {
          setAllOpname(data.data.response);
          setPage(data.data.current_page + 1);
          setPerpage(data.data.per_page);
          setTotalItem(data.data.total_item);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

		let paginationOption = {
		page: page,
		alwaysShowAllBtns: true,
		override: true,
		showTotal: true,
		withFirstAndLast: false,
		sizePerPage: perPage,
		totalSize: totalItem,
		onPageChange: (page) => {
			updateDataTable(page, perPage, currentSort);
		},
		sizePerPageRenderer: () => (
			<div className="dataTables_length" id="datatable-basic_length">
				<label
				className="form-control-label"
				>
					Show{" "}
					{
						<select
							name="datatable-basic_length"
							aria-controls="datatable-basic"
							className="form-control form-control-sm"
							onChange={(e) => {
								updateDataTable(page, e.target.value, currentSort);
							}}>
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="25">25</option>
							<option value="50">50</option>
						</select>
					}{" "}
					entries.
				</label>
			</div>
		),
	};

		const updateDataTable = (page, perPage, sort) => {
		setPerpage(perPage);
		setRowIndex((page - 1) * perPage);
		setCurrentSort(sort);
	};

		const handleTableChange = (type, { sortField, sortOrder }) => {
		if (type === "sort") {
			let sort = `${sortField} ${sortOrder}`;
			updateDataTable(page, perPage, sort);
		}
	};

	return (
		<>
			{itempusat && itempusat === "1" ? (
			<div>
				<SimpleHeader name="Detail Region" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
								<CardBody>
									<Row md="12">
										<Col md="7">
											<Card className="bg-secondary shadow">
												<CardHeader className="bg-white border-0">
												<div style={{ display: "flex", justifyContent: "space-between" }}>
                                                        <h3>ITEM</h3>
                                                        <div style={{ textAlign: 'right' }}>
                                                            <h1>
                                                                {status === 1 ?  
                                                                <Badge color="success">
                                                                    AKTIF
                                                                </Badge> :  
                                                                <Badge color="danger">
                                                                    TIDAK AKTIF
                                                                </Badge>}
                                                           
                                                            </h1>
                                                        </div>
                                                    </div>
												</CardHeader>
												<CardBody response>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Kode Item
														</Label>
														<Col sm={7}>
															<Input
																disabled
																className="form-control-alternative"
																type="text"
																name="itemCode"
																placeholder="Masukan Kode Item"
																value={itemCode}
																onChange={(e) => {
																	setItemCode(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Barcode
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="barcode"
																placeholder="Masukan Barcode"
																value={barcode}
																onChange={(e) => {
																	setBarcode(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Nama Item
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="nameItem"
																placeholder="Masukan Nama Item"
																value={nameItem}
																onChange={(e) => {
																	setNameItem(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Jenis Barang
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																name="kategory"
																type="text"
																value={itemgrup}
																onChange={(e) => {
																	setItemGrup(e.target.value);
																}}>
															</Input>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Satuan
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																name="satuan"
																type="text"
																value={satuan}
																onChange={(e) => {
																	setSatuan(e.target.value);
																}}>
															</Input>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Dimensi
														</Label>
														<Col sm={2}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="panjang"
																placeholder="Panjang"
																value={panjang}
																onChange={(e) => {
																	setPanjang(e.target.value);
																}}
															/>
														</Col>
														<Col sm={3}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="lebar"
																placeholder="Lebar"
																value={lebar}
																onChange={(e) => {
																	setLebar(e.target.value);
																}}
															/>
														</Col>
														<Col sm={2}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="tinggi"
																placeholder="Tinggi"
																value={tinggi}
																onChange={(e) => {
																	setTinggi(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Berat
														</Label>
														<Col sm={4}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="Berat"
																placeholder="Masukan Berat"
																value={berat}
																onChange={(e) => {
																	setBerat(e.target.value);
																}}
															/>
														</Col>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															<b>Gram</b>
														</Label>
													</FormGroup>
													<FormGroup row>
														<Label
														className="form-control-label"
														for="exampleEmail" sm={4}>
															Minimum Stock
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																type="number"
																name="stock"
																placeholder="Masukan Stock"
																value={minimumstock}
																onChange={(e) => {
																	setMinimumStock(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
												</CardBody>
											</Card>
										</Col>
										<Col md="5">
											<Card className="bg-secondary shadow">
												<CardHeader className="bg-white border-0">
													<h3>Deskripsi</h3>
												</CardHeader>
												<CardBody>
													<FormGroup row>
														<Input
														disabled
															className="form-control-alternative"
															rows="13"
															type="textarea"
															name="deskripsi Item"
															placeholder="Masukan Deskripsi Item"
															value={description}
															onChange={(e) => {
																setDescription(e.target.value);
															}}
														/>
													</FormGroup>
													<FormGroup row>
															<Card>
																<CardBody>
																	<CardGroup>
																		<Row md="10">
																			<Col md="5">
																				<QRCode className="qrcode"  value={barcode} />
																			</Col>
																			<Col md="5">
																				<Barcodee className="qrcode" value={barcode} />
																			</Col>
																		</Row>
																	</CardGroup>
																</CardBody>
															</Card>
													</FormGroup>
												</CardBody>
											</Card>
										</Col>
									</Row>
									<Card className="bg-secondary shadow">
									<CardHeader className="bg-white border-0">
										<h3>Gambar</h3>
									</CardHeader>
									<CardBody>
										<Row md="12">
											<Col md="10">
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={3}>
														<b>Foto Produk</b><br></br>
														Format gambar .jpg .jpeg .png dan ukuran minimum <b>300px X 300px</b>.
													</Label>
													<Col sm={2}>
													{image1 === "" ?(
														<img
														alt="..."
														src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
														width="100%"
														/>
													):(
													<img
														alt=""
														src={image1}
														width="100%"
													/>
													)}
													</Col>
													<Col sm={2}>
													{image2 === "" ?(
														<img
														alt="..."
														src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
														width="100%"
														/>
													):(
													<img
														alt=""
														src={image2}
														width="100%"
													/>
													)}
													</Col>
													<Col sm={2}>
													{image3 === "" ?(
														<img
														alt="..."
														src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
														width="100%"
														/>
													):(
													<img
														alt=""
														src={image3}
														width="100%"
													/>
													)}
													</Col>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
									</Card>
									<Card className="bg-secondary shadow">
									<CardHeader className="bg-white border-0">
										<h3>KATEGORI</h3>
									</CardHeader>
									<CardBody>
										<Row md="12">
											<Col md="6">
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Tipe Item
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															type="text"
															name="nameItem"
															placeholder="Masukan Tipe Item"
															value={nameType}
															onChange={(e) => {
																setNameType(e.target.value);
															}}>
														</Input>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Kategori
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="kategory"
															type="text"
															value={category}
															onChange={(e) => {
																setCategory(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Sub Kategori
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="subKategory"
															type="text"
															value={subCategory}
															onChange={(e) => {
																setSubCategory(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Function
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="function"
															type="text"
															value={nameFunction}
															onChange={(e) => {
																setNameFunction(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Sub Function
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="subFunction"
															type="text"
															value={nameSubFunction}
															onChange={(e) => {
																setNameSubFunction(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
											</Col>
											<Col md="6">
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Merek
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="merek"
															type="text"
															value={merek}
															onChange={(e) => {
																setMerek(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Sub Merek
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="merek"
															type="text"
															value={submerek}
															onChange={(e) => {
																setSubMerek(e.target.value);

															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Grade
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="grade"
															type="text"
															value={grade}
															onChange={(e) => {
																setGrade(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												{/* <FormGroup row>
													<Col
													 sm={4}
													>
													<Label
													className="form-control-label"
													for="exampleEmail">
														Status
													</Label>

													</Col>
													
													<Col sm={7}>
														<div style={{ display: "flex" }}>
															<div className="custom-control custom-radio mb-3">
																<Input
																disabled
																autoComplete="off"
																	className="custom-control-input"
																	id="customRadio11"
																	name="custom-radio-4"
																	type="checkbox"
																	value={1}
																	checked={status === 1}
																	onChange={() => setStatus(1)}
																/>
																<Label
																className="custom-control-label" htmlFor="customRadio11">
																	
																</Label>
																<b>Aktif</b>
															</div>
															<div className="custom-control custom-radio mb-3" style={{ marginLeft: "20px" }}>
																<Input
																disabled
																autoComplete="off"
																	className="custom-control-input"
																	id="customRadio12"
																	name="custom-radio-4"
																	type="radio"
																	value={2}
																	checked={status === 2}
																	onChange={() => setStatus(2)}
																/>
																<Label
																className="custom-control-label" htmlFor="customRadio12">
																	
																</Label>
																<b>Tidak Aktif</b>
															</div>
														</div>
													</Col>
												</FormGroup> */}
											</Col>
										</Row>
									</CardBody>
									</Card>
								<Card>
										<CardHeader>
											<div style={{ display: "flex", justifyContent: "space-between" }}>
												<h3>Item Produksi</h3>
											</div>
										</CardHeader>
										<CardBody>
										{itemproduksi.length > 0 ? (
											<ToolkitProvider
											rowNumber={rowIndex}
											data={itemproduksi}
											keyField="id"
											columns={[
												{
													dataField: "no",
													text: "#",
													sort: true,
													page: 1,
													formatter: (cell, row, index) => {
														let currentRow = ++index;
														return currentRow + rowIndex;
													},
												},
												{
													dataField: "item_name",
													text: "Nama Item",
													sort: true,
												},
												{
													dataField: "item_code",
													text: "Kode Item",
													sort: true,
												},
												{
													dataField: "qty",
													text: "Qty",
													sort: true,
												},
												{
													dataField: "satuan",
													text: "Satuan",
													sort: true,
												},
											]}>
											{(props) => (
												<div className="py-4 table-responsive">
													<BootstrapTable
														remote
														{...props.baseProps}
														bootstrap4={true}
														bordered={false}
														hover={true}
														// pagination={paginationFactory({ ...paginationOption })}
														onTableChange={handleTableChange}
													/>
												</div>
											)}
										</ToolkitProvider>
												):(
												<CardHeader>
													<center><h3>DATA NOT FOUND</h3></center>
												</CardHeader>
												)}
										</CardBody>
									</Card>
									<Card>
										<CardHeader>
											<div style={{ display: "flex", justifyContent: "space-between" }}>
												<h3>Stok Setiap Toko</h3>
											</div>
										</CardHeader>
										<CardBody>
										{allOpname.length > 0 ? (
											<ToolkitProvider
												rowNumber={rowIndex}
												data={allOpname}
												keyField="id"
												columns={[
												{
													dataField: "no",
													text: "#",
													sort: true,
													page: 1,
													formatter: (cell, row, index) => {
													let currentRow = ++index;
													return currentRow + rowIndex;
													},
												},
												{
													dataField: "warehouse",
													text: "Cabang",
													sort: true,
												},
												{
													dataField: "address",
													text: "Alamat",
													sort: true,
												},
												{
													dataField: "stok",
													text: "Stok",
													sort: true,
												},
												{
													dataField: "", text: "", formatter: (cell, row, index) => {
													return (
														<UncontrolledDropdown nav>
															<DropdownToggle color="warning">
																<i class="fas fa-bars"></i>
															</DropdownToggle>
														<DropdownMenu right>
															<Link to={redirectPrefix1 + row.id + redirectPrefix6 + row.item_id}
															id={"tooltip_" + row.id}
															itemid = {"tooltip_" + row.item_id} >
																<DropdownItem>
																	<span>Edit Harga</span>
																</DropdownItem>
															</Link>
															{/* <Link  to={redirectPrefix + row.id + redirectPrefix5 + row.item_id}
															id={"tooltip_" + row.id}
															itemid = {"tooltip_" + row.item_id}>
																<DropdownItem>
																	<span>Detail</span>
																</DropdownItem>
															</Link> */}
														</DropdownMenu>
													</UncontrolledDropdown>
													)
													}
												},
												]}
											>
												{(props) => (
												<div className="py-4 table-responsive">
													<BootstrapTable
													remote
													{...props.baseProps}
													bootstrap4={true}
													bordered={false}
													hover={true}
													// pagination={paginationFactory({ ...paginationOption })}
													onTableChange={handleTableChange}
													/>
												</div>
												)}
											</ToolkitProvider>
											):(
												<CardHeader>
													<center><h3>DATA NOT FOUND</h3></center>
												</CardHeader>
												)}
										</CardBody>
									</Card>
								</CardBody>
						</div>
					</Row>
				</Container>
			</div>
			):(
				<Halaman404 />
			)}
		</>
	);
}