/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Collapse ,
  UncontrolledCollapse
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";

export default function EditInvoicePo(props)  {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [savedItems, setSavedItems] = useState([]);
  const [pocode, setPoCode] = useState([]);
  const [allpajak,setAllPajak] = useState([]);
  const [pajak,setPajak] = useState("");
  const [grandtotal, setGrandTotal] = useState(0);
  const [jangkaWaktu,setJangkaWaktu] = useState(0);
  const [allJangkaWaktu, setAllJangkaWaktu] = useState([]);
  const [codereceiving, setCodeReceiving] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [banks, setBanks] = useState([]);
  const [paymenttotal,setPaymentTotal] = useState("")
    const [banks1, setBanks1] = useState([]);
    const [banks2, setBanks2] = useState([]);
    const [banks3, setBanks3] = useState([]);
    const [banks4, setBanks4] = useState([]);
    const [banks5, setBanks5] = useState([]);
    const [banks6, setBanks6] = useState([]);
    const [pay1, setPay1] = useState(0)
    const [pay2, setPay2] = useState(0)
    const [pay3, setPay3] = useState(0)
    const [pay4, setPay4] = useState(0)
    const [pay5, setPay5] = useState(0)
    const [pay6, setPay6] = useState(0)
    const [payment_method1, setPaymentMethod1] = useState("");
    const [payment_method2, setPaymentMethod2] = useState("");
    const [payment_method3, setPaymentMethod3] = useState("");
    const [payment_method4, setPaymentMethod4] = useState("");
    const [payment_method5, setPaymentMethod5] = useState("");
    const [payment_method6, setPaymentMethod6] = useState("");
    const [bayar1, setBayar1] = useState("");
    const [bayar2, setBayar2] = useState("");
    const [bayar3, setBayar3] = useState("");
    const [bayar4, setBayar4] = useState("");
    const [bayar5, setBayar5] = useState("");
    const [bayar6, setBayar6] = useState("");
    const [sup1,setSup1] = useState("")
    const [sup2,setSup2] = useState("")
    const [sup3,setSup3] = useState("")
    const [sup4,setSup4] = useState("")
    const [sup5,setSup5] = useState("")
    const [sup6,setSup6] = useState("")
    const [isOpen1, setIsOpen1] = useState(false);
    const toggleOpen1 = () => setIsOpen1(!isOpen1);
    const [isOpen2, setIsOpen2] = useState(false);
    const toggleOpen2 = () => setIsOpen2(!isOpen2);
    const [isOpen3, setIsOpen3] = useState(false);
    const toggleOpen3 = () => setIsOpen3(!isOpen3);
    const [isOpen4, setIsOpen4] = useState(false);
    const toggleOpen4 = () => setIsOpen4(!isOpen4);
    const [isOpen5, setIsOpen5] = useState(false);
    const toggleOpen5 = () => setIsOpen5(!isOpen5);
    const [isOpen6, setIsOpen6] = useState(false);
    const toggleOpen6 = () => setIsOpen6(!isOpen6);
    const [cabang, setCabang] = useState("");

    
    // hasil grandtotal
	useEffect(() => {
		setGrandTotal(paymenttotal);
	}, [paymenttotal]);

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/invoice-po/cicilan/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
       
        setPaymentTotal(data.data.response.payment_total);
        setPay1(data.data.response.pay_1);
        setPay2(data.data.response.pay_2);
        setPay3(data.data.response.pay_3);
        setPay4(data.data.response.pay_4);
        setPay5(data.data.response.pay_5);
        setPay6(data.data.response.pay_6);
        getBank(data.data.response.person_id);
        getBank1(data.data.response.person_id);
        getBank2(data.data.response.person_id);
        getBank3(data.data.response.person_id);
        getBank4(data.data.response.person_id);
        getBank5(data.data.response.person_id);
        setBayar1(data.data.response.pay_1);
        setBayar2(data.data.response.pay_2);
        setBayar3(data.data.response.pay_3);
        setBayar4(data.data.response.pay_4);
        setBayar5(data.data.response.pay_5);
        setBayar6(data.data.response.pay_6);
        setPaymentMethod1(data.data.response.account_number1);
        setPaymentMethod2(data.data.response.account_number2);
        setPaymentMethod3(data.data.response.account_number3);
        setPaymentMethod4(data.data.response.account_number4);
        setPaymentMethod5(data.data.response.account_number5);
        setPaymentMethod6(data.data.response.account_number6);
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getBank = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-person/${id}`, { headers })
      .then((data) => {
        setBanks(data.data.response);
        // setPaymentMethod1(payment_method1);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

    const getBank1 = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-person/${id}`, { headers })
      .then((data) => {
        setBanks1(data.data.response);
        // setPaymentMethod2(payment_method2);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

    const getBank2 = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-person/${id}`, { headers })
      .then((data) => {
        setBanks2(data.data.response);
        // setPaymentMethod3(payment_method3);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

    const getBank3 = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-person/${id}`, { headers })
      .then((data) => {
        setBanks3(data.data.response);
                // setPaymentMethod4(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

    const getBank4 = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-person/${id}`, { headers })
      .then((data) => {
        setBanks4(data.data.response);
                // setPaymentMethod5(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

    const getBank5 = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-person/${id}`, { headers })
      .then((data) => {
        setBanks5(data.data.response);
                // setPaymentMethod6(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function EditData() {
    setLoading(true);
    let data = {
      pay_1: parseInt(pay1),
      account_number1: payment_method1,
      pay_2: parseInt(pay2),
      account_number2: payment_method2,
      pay_3: parseInt(pay3),
      account_number3: payment_method3,
      pay_4: parseInt(pay4),
      account_number4: payment_method4,
      pay_5: parseInt(pay5),
      account_number5: payment_method5,
      pay_6: parseInt(pay6),
      account_number6: payment_method6,
        // status:5
    }
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/invoice-po/cicilan/update/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
				setTimeout(() => (history.push("/admin/invoice-po/page")), 1000);
        })
        .then((json) => {
          setLoading(false);
        })
        .catch(function (error) {
          // if(error.response.data.status == 404){
              setGagalAlert(error.response.data.message);
              // }
        });
    }
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }

  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };

  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={EditData}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };
  
    const hideAlert = () => {
      setAlert(null);
    };

const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}
  
  const handleSubmit = (e) => {
    {
      EditData();
    }
  };

  return (
    <>
    {alert}
    <SimpleHeader name="Payment BPB Pembelian" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
        <div className="col">
          <Card className="bg-secondary shadow">
                <Form onSubmit={handleSubmit}>
                    <CardHeader className="bg-white border-0">
                      <h3>Payment BPB Pembelian</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          <Input
                          className="form-control-alternative"
                                  type="hidden"
                                  name="desc"
                                  placeholder="Masukan Keterangan"
                                  value={pocode}
                                  onChange={(e) => {
                                    setPoCode(e.target.value);
                                  }}
                                />
                                <Input
                                className="form-control-alternative"
                                  type="hidden"
                                  name="desc"
                                  placeholder="Masukan Keterangan"
                                  value={codereceiving}
                                  onChange={(e) => {
                                    setCodeReceiving(e.target.value);
                                  }}
                                />
                          </Col>
                      </Row>
                      <div className="container">
                        <div className="row align-items-center mb-3">
                          <div className="col-3">Nominal Pembayaran 1</div>
                          <div className="col-1 text-center">:</div>
                          <div className="col-4 text-center">
                            <Input
                            autoComplete="off" 
                            className="form-control-alternative"
                              type="number"
                              disabled={bayar1 > 0}
                              value={pay1}
                              placeholder="Masukan Nomminal Pembayaran"
                              onChange={(event) => {
                                setPay1(event.target.value);
                              }}></Input>
                          </div>
                          <div className="col-4 text-center">
                            <Input
                            autoComplete="off" 
                            className="form-control-alternative"
                              name="Tipe Po"
                              type="select"
                              disabled={bayar1 > 0}
                              placeholder="Metode Pembayaran 1"
                              value={payment_method1}
                              onChange={(e) => {
                                setPaymentMethod1(e.target.value);
                              }}>
                              <option value="">Pilih Bank</option>
                              {banks.map((bank, key) => {
                                return (
                                  <option key={key} value={bank.account_number}>
                                    {bank.bank_name} {bank.account_number} {bank.account_name}
                                  </option>
                                );
                              })}
                            </Input>
                          </div>
                        </div>
                       
                        <div className="row align-items-center mb-3">
                          <div className="col-3">
                                            {/* <h3 onClick={toggleOpen1}>
                                                {isOpen1 === true ? (
                                                // <Button color="primary" ><i className="fa fa-minus danger " aria-hidden="true"></i></Button>
                                                <Button color="primary" ><i className="fa fa-minus danger " aria-hidden="true"></i></Button>
                                                ) : (
                                                <Button color="primary" ><i className="fa fa-plus danger " aria-hidden="true"></i></Button>
                                                )}
                                            </h3> */}
                                            <Button color="primary" id="buttonToggler1">
                                              <i className="fa fa-plus danger " aria-hidden="true"></i>
                                            </Button>
                                        </div>
                        </div>
                        {/* <Collapse isOpen={isOpen1}> */}
                        <UncontrolledCollapse toggler="#buttonToggler1">
                        <div className="row align-items-center mb-3">
                          <div className="col-3">Nominal Pembayaran 2</div>
                          <div className="col-1 text-center">:</div>
                          <div className="col-4 text-center">
                            <Input
                            autoComplete="off" 

                            disabled={bayar2 > 0}
                            className="form-control-alternative" type="number" value={pay2} onChange={(event) => setPay2(event.target.value)} placeholder="Masukan Nomminal Pembayaran"></Input>
                          </div> 
                          <div className="col-4 text-center">
                              <Input
                              autoComplete="off" 
                              className="form-control-alternative"
                              disabled={bayar2 > 0}
                              name="Tipe Po"
                              type="select"
                              placeholder="Metode Pembayaran 2"
                              value={payment_method2}
                              onChange={(e) => {
                                setPaymentMethod2(e.target.value);
                              }}>
                              <option value="">Pilih Bank</option>
                              {banks1.map((bank, key) => {
                                return (
                                  <option key={key} value={bank.account_number}>
                                    {bank.bank_name} {bank.account_number} {bank.account_name}
                                  </option>
                                );
                              })}
                            </Input>
                          </div>
                        </div>
                        
                        <div className="row align-items-center mb-3">
                          <div className="col-3">
                                            {/* <h3 onClick={toggleOpen2}>
                                                {isOpen2 === true ? (
                                                <Button color="primary" ><i className="fa fa-minus danger " aria-hidden="true"></i></Button>
                                                ) : (
                                                <Button color="primary" ><i className="fa fa-plus danger " aria-hidden="true"></i></Button>
                                                )}
                                            </h3> */}
                                             <Button color="primary" id="buttonToggler2">
                                              <i className="fa fa-plus danger " aria-hidden="true"></i>
                                            </Button>
                                        </div>
                        </div>
                        </UncontrolledCollapse>
                        {/* </Collapse> */}
                        {/* <Collapse isOpen={isOpen2}> */}
                        <UncontrolledCollapse toggler="#buttonToggler2">
                        <div className="row align-items-center mb-3">
                          <div className="col-3">Nominal Pembayaran 3</div>
                          <div className="col-1 text-center">:</div>
                          <div className="col-4 text-center">
                            <Input
                            autoComplete="off" 

                            disabled={bayar3 > 0}
                            className="form-control-alternative" type="number" value={pay3} onChange={(event) => setPay3(event.target.value)} placeholder="Masukan Nomminal Pembayaran"></Input>
                          </div>
                          <div className="col-4 text-center">
                            <Input
                            autoComplete="off" 
                            className="form-control-alternative"
                              name="Tipe Po"
                              type="select"
                              disabled={bayar3 > 0}
                              placeholder="Metode Pembayaran 3"
                              value={payment_method3}
                              onChange={(e) => {
                                setPaymentMethod3(e.target.value);
                              }}>
                              <option value="">Pilih Bank</option>
                              {banks2.map((bank, key) => {
                                return (
                                  <option key={key} value={bank.account_number}>
                                    {bank.bank_name} {bank.account_number} {bank.account_name}
                                  </option>
                                );
                              })}
                            </Input>
                          </div>
                        </div>
                        
                        <div className="row align-items-center mb-3">
                            <div className="col-3">
                                {/* <h3 onClick={toggleOpen3}>
                                    {isOpen3 === true ? (
                                    <Button color="primary" ><i className="fa fa-minus danger " aria-hidden="true"></i></Button>
                                    ) : (
                                    <Button color="primary" ><i className="fa fa-plus danger " aria-hidden="true"></i></Button>
                                    )}
                                </h3> */}
                                <Button color="primary" id="buttonToggler3">
                                  <i className="fa fa-plus danger " aria-hidden="true"></i>
                                </Button>
                            </div>
                            </div>
                          {/* </Collapse> */}
                        </UncontrolledCollapse>
                          <UncontrolledCollapse toggler="#buttonToggler3">
                            {/* <Collapse isOpen={isOpen3}> */}
                              <div className="row align-items-center mb-3">
                              <div className="col-3">Nominal Pembayaran 4</div>
                              <div className="col-1 text-center">:</div>
                              <div className="col-4 text-center">
                                <Input
                                autoComplete="off" 

                                disabled={bayar4 > 0}
                                className="form-control-alternative" type="number" value={pay4} onChange={(event) => setPay4(event.target.value)} placeholder="Masukan Nomminal Pembayaran"></Input>
                              </div>
                              <div className="col-4 text-center">
                                <Input
                                autoComplete="off" 
                                className="form-control-alternative"
                                  name="Tipe Po"
                                  type="select"
                                  // disabled={pay4 > 1000}
                                  disabled={bayar4 > 0}
                                  placeholder="Metode Pembayaran 4"
                                  value={payment_method4}
                                  onChange={(e) => {
                                    setPaymentMethod4(e.target.value);
                                  }}>
                                  <option value="">Pilih Bank</option>
                                  {banks3.map((bank, key) => {
                                    return (
                                      <option key={key} value={bank.account_number}>
                                        {bank.bank_name} {bank.account_number} {bank.account_name}
                                      </option>
                                    );
                                  })}
                                </Input>
                              </div>
                            </div>
                            
                            <div className="row align-items-center mb-3">
                            <div className="col-3">
                                            {/* <h3 onClick={toggleOpen4}>
                                                {isOpen4 === true ? (
                                                <Button color="primary" ><i className="fa fa-minus danger " aria-hidden="true"></i></Button>
                                                ) : (
                                                <Button color="primary" ><i className="fa fa-plus danger " aria-hidden="true"></i></Button>
                                                )}
                                            </h3> */}
                                <Button color="primary" id="buttonToggler4">
                                  <i className="fa fa-plus danger " aria-hidden="true"></i>
                                </Button>
                                </div>
                              </div>
                              {/* </Collapse> */}
                          </UncontrolledCollapse>
                          <UncontrolledCollapse toggler="#buttonToggler4">
                        {/* <Collapse isOpen={isOpen4}> */}
                        <div className="row align-items-center mb-3">
                          <div className="col-3">Nominal Pembayaran 5</div>
                          <div className="col-1 text-center">:</div>
                          <div className="col-4 text-center">
                            <Input
                            autoComplete="off" 
                            disabled={bayar5 > 0}
                            // disabled={pay5 > 1000}
                            className="form-control-alternative" type="number" value={pay5} onChange={(event) => setPay5(event.target.value)} placeholder="Masukan Nomminal Pembayaran"></Input>
                          </div>
                          <div className="col-4 text-center">
                            <Input
                            autoComplete="off" 
                            className="form-control-alternative"
                              name="Tipe Po"
                              type="select"
                              disabled={bayar5 > 0}
                              // disabled={pay5 > 1000}
                              placeholder="Metode Pembayaran 5"
                              value={payment_method5}
                              onChange={(e) => {
                                setPaymentMethod5(e.target.value);
                              }}>
                              <option value="">Pilih Bank</option>
                              {banks4.map((bank, key) => {
                                return (
                                  <option key={key} value={bank.account_number}>
                                    {bank.bank_name} {bank.account_number} {bank.account_name}
                                  </option>
                                );
                              })}
                            </Input>
                          </div>
                        </div>
                        
                        <div className="row align-items-center mb-3">
                          <div className="col-3">
                                            {/* <h3 onClick={toggleOpen5}>
                                                {isOpen5 === true ? (
                                                <Button color="primary" ><i className="fa fa-minus danger " aria-hidden="true"></i></Button>
                                                ) : (
                                                <Button color="primary" ><i className="fa fa-plus danger " aria-hidden="true"></i></Button>
                                                )}
                                            </h3> */}
                                             <Button color="primary" id="buttonToggler5">
                                  <i className="fa fa-plus danger " aria-hidden="true"></i>
                                </Button>
                                        </div>
                          </div>
                                    {/* </Collapse> */}
                          </UncontrolledCollapse>
                          <UncontrolledCollapse toggler="#buttonToggler5">
                      {/* <Collapse isOpen={isOpen5}> */}
                      <div className="row align-items-center mb-3">
                          <div className="col-3">Nominal Pembayaran 6</div>
                          <div className="col-1 text-center">:</div>
                          <div className="col-4 text-center">
                            <Input
                            autoComplete="off" 
                            disabled={bayar6 > 0}
                            // disabled={pay6 > 1000} 
                            className="form-control-alternative" type="number" value={pay6} onChange={(event) => setPay6(event.target.value)} placeholder="Masukan Nomminal Pembayaran"></Input>
                          </div>
                          <div className="col-4 text-center">
                            <Input
                            autoComplete="off" 
                            auto
                            className="form-control-alternative"
                              name="Tipe Po"
                              type="select"
                              // disabled={pay6 > 1000}
                              disabled={bayar6 > 0}
                              placeholder="Metode Pembayaran 6"
                              value={payment_method6}
                              onChange={(e) => {
                                setPaymentMethod6(e.target.value);
                              }}>
                              <option value="">Pilih Bank</option>
                              {banks5.map((bank, key) => {
                                return (
                                  <option key={key} value={bank.account_number}>
                                    {bank.bank_name} {bank.account_number} {bank.account_name}
                                  </option>
                                );
                              })}
                            </Input>
                          </div>
                        </div>
                                    {/* </Collapse> */}
                          </UncontrolledCollapse>
                        <div className="row align-items-center mb-3">
                          <div className="col-3 text-start  display-1">DP</div>
                          <div className="col-1 text-center">:</div>
                          <div className="col-6 text-center">
                            <textarea
                              className="form-control"
                              disabled
                              value={"Rp." + pay1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                              style={{
                                fontSize: 40,
                                paddingTop: 20,
                                top: "50%",
                                height: 95,
                                fontWeight: 'bold',
                                resize: "none",
                              }}></textarea>
                          </div>
                        </div>
                        <div className="row align-items-center mb-3">
                          <div className="col-3  display-3">SISA </div>
                          <div className="col-1 text-center">:</div>
                          <div className="col-6 text-center">
                            <textarea
                              className="form-control"
                              disabled
                              value={"Rp." + ( parseInt(grandtotal) - (parseInt(pay1) + parseInt(pay2)  + parseInt(pay3) + parseInt(pay4) + parseInt(pay5) + parseInt(pay6))).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                              style={{
                                fontSize: 40,
                                paddingTop: 20,
                                fontWeight: 'bold',
                                top: "50%",
                                height: 95,
                                resize: "none",
                              }}></textarea>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                </Form>
              </Card>
              {/* <Card className="bg-secondary shadow">
                <Form onSubmit={handleSubmit}>
                    <CardHeader className="bg-white border-0">
                      <h3>Pembayaran</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                            <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={4}
                              >
                                Bayar
                              </Label>
                              <Col sm={7}>
                                <Input
                                autoComplete="off"
                                className="form-control-alternative"
                                  name="person"
                                  type="number"
                                  placeholder="Masukan Pembayaran Total"
                                  value={pay1}
                                  onChange={(e) => {
                                    setPay1(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={4}
                              >
                                Metode Pembayaran
                              </Label>
                              <Col sm={7}>
                                <Input
                                className="form-control-alternative"
                                  name="Tipe Po"
                                  type="select"
                                  value={payment_method1}
                                  onChange={(e) => {
                                      setPaymentMethod1(e.target.value);
                                  }}
                                >
                                  <option value={""}>Pilih Metode Pembayaran</option>
                                  <option value={1}>Tunai</option>
                                  <option value={2}>Transfer</option>
                                  <option value={3}>Termin Of Payment</option>
                                  <option value={4}>Indent DP</option>
                                  <option value={5}>Indent Lunas</option>
                                </Input>
                              </Col>                             
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={4}
                              >
                                Keterangan
                              </Label>
                              <Col sm={7}>
                                <Input
                                autoComplete="off"
                                className="form-control-alternative"
                                  type="textarea"
                                  rows ="5"
                                  name="desc"
                                  placeholder="Masukan Keterangan Pembayaran"
                                  value={keteranganbayar}
                                  onChange={(e) => {
                                    setKeteranganBayar(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                </Form>
              </Card> */}
              <Card className="bg-secondary shadow">
                <Form onSubmit={handleSubmit}>
                <CardFooter>
                      <Button color="danger" onClick={()=>setQuestionAlert()}>
                          Simpan
                      </Button>
                      <Link className="btn btn-info" to="/admin/invoice-po/page">
                        Kembali
                      </Link>
                </CardFooter>
                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader toggle={toggle} align="center"></ModalHeader>
                  <ModalBody align="center">
                  <font size="5"><b>Apakah Anda Sudah Yakin ?</b></font><br></br><br></br><br></br>
                  {!isLoading && (
                    <Button color="primary" onClick={() => handleSubmit()}>
                    Lanjutkan
                    </Button>
                  )}
                  {isLoading && (
                    <Button color="primary" disabled>
                    <i className="fas fa-spinner fa-spin"></i>
                    {""}
                    loading...
                    </Button>
                  )}
                  <Button color="secondary" onClick={toggle}>
                    Cancel
                  </Button>
                  </ModalBody>
                </Modal>
                </Form>
              </Card>
          </div>
        </Row>
    </Container>  
    </>
  );
}