/*eslint-disable*/
import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Col,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

export default function EditPasswordOperasional(props) {
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [passwordpo,setPasswordPo] = useState([]);
  const [alert, setAlert] = React.useState(null);
  const allInfo = JSON.parse(localStorage.allinfo);
  const durasipassword = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Otoritas").map((p) => p.read_access));
  

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/password-operasional/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setPasswordPo(data.data.response.password_otorisasi);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function EditData() {
    let data = {
        
      warehouse_id: parseInt(warehouse),
      password_otorisasi: parseInt(passwordpo),
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/password-operasional/update/${props.match.params.id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setTimeout(() => (history.push("/admin/password-operasional")), 2000);
        })
        .catch(function (error) {
          setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={EditData}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  }
  
  return (
    <>
    {alert}
    {durasipassword && durasipassword === "1" ? (
        <div>
      <SimpleHeader name="Edit Otoritas" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <h3>Edit Otoritas</h3>
                  </CardHeader>
                  <CardBody>
                    <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Password Otoritas
                              </Label>
                              <Col sm={7}>
                                <Input
                                autoComplete="off"
                                  type="text"
                                  placeholder="Masukan Password Otorisasi"
                                  value={passwordpo}
                                  onChange={(e) => {
                                    setPasswordPo(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Link className="btn btn-info" to="/admin/password-operasional">
                      Kembali
                    </Link>
                    <Button color="danger" onClick={setQuestionAlert}>
                        Simpan
                    </Button>
                  </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}
