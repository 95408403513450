/*eslint-disable*/
import React, { useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardFooter,
  FormGroup,
  Label,
  Container,
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectFile from "components/SelectedFile/index";

const UpdateGambarCabang = (props) => {
  const token = localStorage.token;
  const history = useHistory();
  const [image, setImage] = useState([]);
  const [alert, setAlert] = React.useState(null);
  
  const CreateData = () => {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let data = new FormData();
    data.append("logo",image)
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/warehouse/update-logo/${props.match.params.id}`, data, { headers })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setTimeout(() => (history.push("/admin/warehouse")), 1000);
        })
        .catch(function (error) {
          setGagalAlert(error.response.data.message);
        });
        };

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const setQuestionAlert = () => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Lanjutkan"
        confirmBtnBsStyle="danger"
        title="Apakah Kamu Yakin?"
        onConfirm={CreateData}
        onCancel={hideAlert}
        focusCancelBtn
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  }

  return (
    <div>
      {alert}
      <SimpleHeader name="Edit Gambar" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-secondary shadow">
              <CardBody>
                <Row md="12">
                  <Col md="6">
                    <FormGroup row>
                      <Col sm={7}>
                      <label htmlFor="formId" >
                            <input
                              hidden
                              id="formId"
                              name="file"
                              type="file"
                              accept="image/*"
                              onChange={(event) => {
                                setImage(event.target.files[0]);
                              }}
                            />
                            <SelectFile
                              onclick={() => { }}
                              imageSrc={image != "" && image != undefined ? URL.createObjectURL(image) : null}
                            />
                          </label>
                      </Col>

                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                  <Link className="btn btn-info" to="/admin/warehouse">
                    Kembali
                  </Link>
                  <Button color="danger" onClick={setQuestionAlert}>
                    Simpan
                  </Button>
                </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </div>

  );
};

export default UpdateGambarCabang;