import React, { useEffect, useState,useCallback } from "react";
import {Button,Row,Col,CardBody,Table,Modal,ModalHeader,ModalBody,Label} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";
// import moment from "moment";

const ModalCetakPusatRFQ = ({ open, toggle, data }) => {
        const token = localStorage.token;
        const username = localStorage.username;
        const [counteradmin, setCounterAdmin] = useState("");
        const [savedItems, setSavedItems] = useState([]);
        const [warehouserfq, setWarehouseRfq] = useState("");
        const [keterangan, setKeterangan] = useState("");
        const [coderfq, setCodeRfq] = useState("");
        const [waktu, setWaktu] = useState([]);
        const [phone, setPhone] = useState("");
        const [creator,setCreator] = useState("")
        const [validator,setValidator] = useState("")
        // const [item, setItem] = useState("")
        
          const HitCounter = () => {
            const headers = {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            };
            axios
              .get(
                `${process.env.REACT_APP_API_BASE_URL}/rfq-po/hit-counter-pusat/${data.id}`,
                { headers }
              )
              .then((data) => {  
                console.log("suskes Cuk");
              })
              .catch(function (error) {
                console.log(error);
              });
          };

          const getById = useCallback(() => {
            const headers = {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            };
            axios
              .get(`${process.env.REACT_APP_API_BASE_URL}/rfq-po/cetak/${data.id}`, {
                headers,
              })
              .then((data) => {
                setCodeRfq(data.data.response.rfqpo.rfq_code);
                setPhone(data.data.response.rfqpo.type)
                setCounterAdmin(data.data.response.rfqpo.counter_pusat);
                setWarehouseRfq(data.data.response.rfqpo.warehouse);
                setWaktu(data.data.response.rfqpo.created)
                setValidator(data.data.response.rfqpo.validator1)
                setCreator(data.data.response.rfqpo.creator)
                setKeterangan(data.data.response.rfqpo.keterangan);
                // setItem(data.data.response.list.length);

              })
              .catch(function (error) {
                console.log(error);
              });
          },[token, data.id]);
        
          const getItemDataSaved = useCallback(() => {
            const headers = {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              };
            axios.get(`${process.env.REACT_APP_API_BASE_URL}/rfq-po/cetak/${data.id}`,{ headers }
            
            ).then(async response => {
                let stateItem = [];
                await Promise.all(response.data.response.list.map(async (data) => {
                    stateItem = [...stateItem, {
                        item_id: data.item_id,
                        item_name:data.item_name,
                        item_code:data.item_code,
                        qty: data.qty,
                        harga: data.harga,
                        satuan: data.satuan,
                        diskon_nominal: data.diskon_nominal,
                        diskon_persen : data.diskon_persen,
                        sub_total : data.sub_total,
                        note : data.note,
                        data: {
                            item_name: data.item_name,
                            harga: data.harga
                        },
                    }];
                }));
        
                setSavedItems(stateItem);
            })
        },[token, data.id])

        useEffect(() => {
          getById();
          getItemDataSaved();
        }, [getById,getItemDataSaved]);
  // function print() {
  //   var printContents = document.getElementById("targetContent").innerHTML;
  //   var originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;
  //   // console.log(item)
  //   window.print();
  //   window.close();
  //   document.body.innerHTML = originalContents;
  // }

  function print() {
    const printContents = document.getElementById("targetContent").innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents; // Kembalikan konten awal setelah print
    HitCounter();
    setTimeout(() => window.location.reload("/admin/rfq-pusat"), 1000);
  }
  
  // function clickItem(e) {
  //   setItem(e.target.value)
  // }

  return (
    <div>
    {/* {alert} */}
    
    <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top: "-15%" }}>
      <ModalHeader toggle={toggle} align="center">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {/* <div style={{ textAlign: 'left' }}>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            Set Item
                        </Label>
                        <Input type="number" pattern="[0-9]*" value={item} min="1" max={savedItems.length} onChange={clickItem} style={{width: '70px',marginRight:'50px'}}/>
                    </div> */}
                    <div style={{ textAlign: 'right' }}>
                    <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            &nbsp;
                        </Label><br></br>
                        <Button color="info" onClick={() => print()}>Print</Button>
                     </div>
                </div>
      </ModalHeader>
      <ModalBody>
        <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          {renderPDF()}
        </CardBody>
      </ModalBody>
    </Modal>
    </div>
  );
  function renderPDF() {
    const itemsPerPage = 10; // Maksimal 7 item per halaman
    const totalItems = savedItems.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage); // Hitung total halaman
  
    const sheets = [];
    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      // Hitung indeks awal dan akhir untuk slice item per halaman
      const start = pageIndex * itemsPerPage;
      const end = start + itemsPerPage;
  
      // Ambil item untuk halaman saat ini
      const currentItems = savedItems.slice(start, end);
  
      sheets.push(
        <div className='pagebreak'>
          <div className="col">
            <KopSurat warehouseName={warehouserfq} />
          </div>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          <Row md="12">
            <Col md="12">
              <h3 className="" style={{ color: "black" }}>
                <b><center>RFQ CABANG</center></b>
              </h3>
            </Col>
          </Row>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
  
          <Row md="12">
            <Col style={{ marginLeft: "2%", width: "50%", flexBasis: "unset" }}>
              <div className="row align-items-center">
                <div style={{ width: "10%" }} className="text-table">Tanggal</div>
                <div style={{ width: "2%" }} className="text-center text-table">:</div>
                <div style={{ width: "85%" }} className="text-table">
                  {/* <b>{moment(waktu).format('DD-MM-YYYY')}</b> */}
                  <b>{waktu}</b>
                </div>
              </div>
              <div className="row align-items-center">
                <div style={{ width: "10%" }} className="text-table">No RFQ</div>
                <div style={{ width: "2%" }} className="text-center text-table">:</div>
                <div style={{ width: "85%" }} className="text-table">
                  <b>{coderfq}</b>
                </div>
              </div>
            </Col>
  
            <Col style={{ width: "25%", flexBasis: "unset" }}>
              <div className="row align-items-center">
                <div style={{ width: "30%" }} className="text-table">Tipe PO</div>
                <div style={{ width: "5%" }} className="text-table">:</div>
                <div style={{ width: "65%" }} className="text-table">
                  {phone === 1 ? <b>Customer Request</b> : <b>Stok Request</b>}
                </div>
              </div>
              <div className="row align-items-center">
                <div style={{ width: "30%" }} className="text-table">Keterangan</div>
                <div style={{ width: "5%" }} className="text-center text-table">:</div>
                <div style={{ width: "65%" }} className="text-table">
                  <b>{keterangan}</b>
                </div>
              </div>
            </Col>
          </Row>
          <Table style={{width:"100%"}} responsive>
            <thead>
              <tr>
                <th className="text-table"><b>No</b></th>
                <th className="text-table"><b>Kode</b></th>
                <th className="text-table"><b>Nama Barang</b></th>
                {/* <th className="text-table"><b>Brand</b></th> */}
                <th className="text-table"><b>Qty</b></th>
                <th className="text-table"><b>Satuan</b></th>
                <th className="text-table"><b>Note</b></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((temp, index, manok) => (
                <tr key={temp.item_id || index}>
                  <td className="text-table">{start + index + 1}</td>
                  <td className="text-table">{temp.item_code}</td>
                  <td className="text-table">{temp.item_name}</td>
                  {/* <td className="text-table">-</td> */}
                  <td className="text-table">{temp.qty}</td>
                  <td className="text-table">{temp.satuan}</td>
                  <td className="text-table">{temp.note}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="w-100 text-table" style={{ border: "0.5px solid #b3b3b3" }}></div>
          <center className="text-table">Terms of Price, delivery & shipping required</center>
          <Row md="12">
            <Col style={{ marginLeft: "2%", width: "25%" }}>
              <div className="row align-items-center">
                <div className="col-3 text-table">Di Buat</div>
                <div className="col-1 text-center text-table">:</div>
                <div className="col-5 text-table">
                  <b>{creator}</b>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-3 text-table">Signature</div>
                <div className="col-1 text-center text-table">:</div>
                <div className="col-5 text-table">
                  <b>------------------</b>
                </div>
              </div>
            </Col>
  
            <Col style={{ width: "35%" }}>
              <div className="row align-items-center">
                <div className="col-4 text-table">Kepala Purchasing</div>
                <div className="col-1 text-center text-table">:</div>
                <div className="col-7 text-table">
                  <b>{validator}</b>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-4 text-table">Signature</div>
                <div className="col-1 text-center text-table">:</div>
                <div className="col-7 text-table">
                  <b>------------------</b>
                </div>
              </div>
            </Col>
          </Row>
          <div className="w-100" style={{ border: "0.5px solid black" }}></div>
                      {counteradmin === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Download {counteradmin} Kali Oleh {username}</i></div>
                    </div>
                      )
                      }
          <div className="divFooter">{username}</div>
        </div>
      );
    }
  
    return <div>{sheets}</div>;
  }
};

export default ModalCetakPusatRFQ;
