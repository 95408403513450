/*eslint-disable*/
import React, { useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  FormFeedback,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

export default function CreatePajak() {
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [persentase, setPersentase] = useState("");
  const [persentaseError, setPersentaseError] = useState(null);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(null);
  const [alert, setAlert] = React.useState(null);
  const allInfo = JSON.parse(localStorage.allinfo);
  const pajak = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Ppn").map((p) => p.read_access));


  const validateForm = () => {
    let error = false;
    if (persentase === "") {
      setPersentaseError("invalid");
      error = true;
    }
    if (description === "") {
      setDescriptionError("invalid");
      error = true;
    }
    return error;
  };

  const handleSubmit = () => {
		hideAlert();
		setTimeout(() => {CreateData()}, 1000);
};

  function CreateData() {
    let data = {
      active_flag: 1,
      warehouse_id: parseInt(warehouse),
      keterangan: description,
      persentase: parseFloat(persentase),
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/pajak/save`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setTimeout(() => (history.push("/admin/pajak")), 2000);
        })
        .catch(function (error) {
          setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

    const setGagalAlert = (id) => {
    setAlert(
        <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
        />
    );
    };

    const setQuestionAlert = () => {
    setAlert(
        <SweetAlert
        warning
        showCancel
        confirmBtnText="Lanjutkan"
        confirmBtnBsStyle="danger"
        title="Apakah Kamu Yakin?"
        onConfirm={handleSubmit}
        onCancel={hideAlert}
        focusCancelBtn
        // timeout={2000}
        />
    );
    };

  const hideAlert = () => {
    setAlert(null);
  }

  return (
    <>
    {alert}
    {pajak && pajak === "1" ? (
      <div>
      <SimpleHeader name="Tambah Pajak" parentName="Admin" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Tambah Pajak</h3>
                    </CardHeader>
                    <CardBody>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Persentase
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="persentase"
                          placeholder="Masukan Persentase"
                          value={persentase}
                          invalid={persentaseError === "invalid"}
                          onChange={(e) => {
                            setPersentase(e.target.value);
                            if (e.target.value !== "") {
                              setPersentaseError("");
                            }
                          }}
                        />
                        <FormFeedback>Persentase tidak boleh kosong</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Keterangan
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="keterangan"
                          placeholder="Masukan Keterangan"
                          value={description}
                          invalid={descriptionError === "invalid"}
                          onChange={(e) => {
                            setDescription(e.target.value);
                            if (e.target.value !== "") {
                              setDescriptionError("");
                            }
                          }}
                        />
                        <FormFeedback>Keterangan tidak boleh kosong</FormFeedback>
                      </FormGroup>
                    </CardBody>
                    <CardFooter>
                      <Link className="btn btn-info" to="/admin/pajak">
                        Kembali
                      </Link>
                       <Button color="danger" onClick={setQuestionAlert}>
                          Simpan
                      </Button>
                    </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
      </Container>
      </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}
