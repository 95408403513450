/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  Form,
  CardHeader,
  CardFooter,
  FormGroup,
  Label,
  Input,
  Container,
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import { Maps } from "components/Maps";
import { getAddressMap } from "utils/getMapAddress";
import Halaman404 from "views/404";

const CreateGudangToko = (props) => {
	const allInfo = JSON.parse(localStorage.allinfo);
	const cabang = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Cabang").map((p) => p.read_access));
  const token = localStorage.token;
  const history = useHistory();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState([]);
  const [province, setProvince] = useState([]);
  const [level, setLevel] = useState("");
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [warehousepusat, setWarehousePusat] = useState("");
  const [warehousepusats, setWarehousePusats] = useState([]);
  const [warehousepusatt, setWarehousePusatt] = useState("");
  const [warehousepusatss, setWarehousePusatss] = useState([]);
  const [warehousepusattt, setWarehousePusattt] = useState("");
  const [warehousepusatsss, setWarehousePusatsss] = useState([]);
  const [citys, setCitys] = useState([]);
  const [alert, setAlert] = React.useState(null);
  const [long, setLong] = useState(0);
  const [lat, setLat] = useState(0);
  const [radius, setRadius] = useState(null);
	const [onmap, setOnmap] = useState()

  const defaultProps = {
    center: {
      lat: lat ? lat : -7.3106144,
      lng: long ? long : 112.6841525
    },
    zoom: 5
  };

  const [selectAddress, setSelectAddress] = useState("");
  const [loadMap, setLoadMap] = useState();


  const successCallback = (position) => {
    // console.log(position);
  };

  const errorCallback = (error) => {
    // console.log(error);
  };

  navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/warehouse/get/${props.match.params.id}`,
        { headers }
      )
      .then(async (data) => {
        setName(data.data.response.warehouse_name);
        setAddress(data.data.response.address);
        setWarehousePusat(data.data.response.pusat_id)
        getRegion(data.data.response.pusat_id, data.data.response.region_id);
        setWarehousePusatt(data.data.response.region_id);
        getToko(data.data.response.region_id);
        setImage(data.data.response.logo);
        setWarehousePusattt(data.data.response.toko_id);
        setLevel(data.data.response.level);
        setProvince(data.data.response.province_id)
        getKota(data.data.response.province_id);
        setCity(data.data.response.city_id);
        setPhone(data.data.response.phone);
        setLong(data.data.response.longitude);
        setLat(data.data.response.latitude);
        setRadius(data.data.response.jarak_absen);
        const value = {
          lat: data.data.response.latitude,
          lng: data.data.response.longitude
        };
        await onLoadMaps(data.data.response.latitude, data.data.response.longitude)
        await loadMapClick()
        await onClickMap(value);

      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getProvinsi();
    getPusat();
  }, []);

  useEffect(() => {
    if (selectAddress) {
      setAddress(selectAddress);
    }
  }, [selectAddress]);
  
  const getProvinsi = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/province/list`, {
      headers
    })
      .then(data => {
        setProvinces(data.data.response_data);
        // getKota(id);
        // setProvince(id);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getKota = (id) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/city?province_id=${id}`, {
      headers
    })
      .then(data => {
        setCitys(data.data.response_data);
        // setCity(id);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getPusat = (id) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/pusat`, {
      headers
    })
      .then(data => {
        setWarehousePusats(data.data.response);
        // setWarehousePusat(id);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getRegion = (id) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/region/${id}`, {
      headers
    })
      .then(data => {
        setWarehousePusatss(data.data.response);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getToko = (id) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/toko/${id}`, {
      headers
    })
      .then(data => {
        setWarehousePusatsss(data.data.response);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  function CreateData() {
    let data = {
      name,
      active_flag: 1,
      pusat_id: parseInt(warehousepusat),
      region_id: parseInt(warehousepusatt),
      toko_id: parseInt(warehousepusattt),
      address,
      latitude: lat,
      longitude: long,
      jarak_absen: radius,
      level: parseInt(level),
      city: parseInt(city),
      province: parseInt(province),
      phone,
    };
    axios
    .post(`${process.env.REACT_APP_API_BASE_URL}/warehouse/update/${props.match.params.id}`, data , {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setTimeout(() => (history.push("/admin/warehouse")), 1000);
      })
      .catch(function (error) {
          setGagalAlert(error.response.data.message);
      });
      };

  // const CreateData = () => {
  //   const headers = {
  //     "Content-Type": "multipart/form-data",
  //     Authorization: `Bearer ${token}`,
  //   };
  //   const myjson = JSON.stringify({
  //     name,
  //     active_flag: 1,
  //     pusat_id: parseInt(warehousepusat),
  //     region_id: parseInt(warehousepusatt),
  //     toko_id: parseInt(warehousepusattt),
  //     address,
  //     latitude: lat,
  //     longitude: long,
  //     jarak_absen: radius,
  //     level: parseInt(level),
  //     city: parseInt(city),
  //     province: parseInt(province),
  //     phone,
  //   });
  //   let data = new FormData();
  //   data.append("body", myjson)
  //   data.append("logo", image)
  //   axios
  //     .post(`${process.env.REACT_APP_API_BASE_URL}/warehouse/update/${props.match.params.id}`, data, { headers })
  //     .then(function (response) {
  //       setSuccessAlert(response.data.message);
  //       setTimeout(() => (history.push("/admin/warehouse")), 1000);
  //     })
  //     .catch(function (error) {
  //         setGagalAlert(error.response.data.message);
  //     });
  // };

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const setQuestionAlert = () => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Lanjutkan"
        confirmBtnBsStyle="danger"
        title="Apakah Kamu Yakin?"
        onConfirm={CreateData}
        onCancel={hideAlert}
        focusCancelBtn
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  }

  const handleSubmit = (e) => {
    {
      CreateData();
    }
  };

  const onLoadMaps = async (lats, longs) => {
		if (onmap) {
			var bounds = new window.google.maps.LatLngBounds();
			bounds.extend(new window.google.maps.LatLng(lats, longs));
			// bounds.extend(new window.google.maps.Marker({position : new window.google.maps.LatLng(defaultProps.center.lat, defaultProps.center.lng)}));
			// 	new google.maps.Marker({
			// 		position:pin,
			// });
			onmap.fitBounds(bounds)
		}
		onmap.setZoom(14)
			// console.log("new window.google.maps", onmap);
	}

  const onClickMap = async (e) => {
    if (e) {
      setLong(e.lng)
      setLat(e.lat)
      const data = await getAddressMap(e.lat, e.lng);

      if (data.results.length > 2) {
        setSelectAddress(data.results[2].formatted_address);
        document.getElementById('pac-input').value = data.results[2].formatted_address;
        // await loadMapClick(loadMap);
      }
    }
  }

  const apiIsLoaded = async (map, maps, center) => {
    setOnmap(map)
    if (map && lat > 0) {
      setLoadMap(map);
      await loadMapClick(map, maps);
    }
  };

  const loadMapClick = async (map) => {
    const circle = new window.google.maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.3,
      map,
      center: defaultProps.center,
      radius: radius
    });
  }

  return (
    <div>
      {alert}
      {cabang && cabang === "1" ? (
      <div>
      <SimpleHeader name="Edit Cabang" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-secondary shadow">
              <CardBody>
                <Row md="12">
                  <Col md="6">
                    <FormGroup row>
                      <Label
                       className="form-control-label"
                        for="exampleEmail"
                        sm={4}
                      >
                        Nama
                      </Label>
                      <Col sm={7}>
                        <Input
                          autoComplete="off"
                          className="form-control-alternative"
                          type="text"
                          name="Nama"
                          placeholder="Masukan Nama"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                       className="form-control-label"
                        for="exampleEmail"
                        sm={4}
                      >
                        Alamat

                      </Label>
                      <Col sm={7}>
                        <Input
                          autoComplete="off"
                          className="form-control-alternative"
                          type="text"
                          name="Alamat"
                          placeholder="Masukan Alamat"
                          value={address}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                        />
                      </Col>
                    </FormGroup>
                    {level < 2 ? (
                      ""
                    ) : (
                      <FormGroup row>
                        <Label
                         className="form-control-label"
                          for="exampleEmail"
                          sm={4}
                        >
                          Pusat

                        </Label>
                        <Col sm={7}>
                          <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="select"
                            value={warehousepusat}
                            onChange={(e) => {
                              setWarehousePusat(e.target.value);
                              getRegion(e.target.value)
                            }}
                          >
                            <option>Pilih Pusat</option>
                            {
                              warehousepusats.map((a, key) => {
                                return <option key={key} value={a.id}>{a.name}</option>
                              })
                            }
                          </Input>
                        </Col>
                      </FormGroup>
                    )
                    }
                    {level < 3 ? (
                      ""
                    ) : (
                      <FormGroup row>
                        <Label
                         className="form-control-label"
                          for="exampleEmail"
                          sm={4}
                        >
                          Region

                        </Label>
                        <Col sm={7}>
                          <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="select"
                            value={warehousepusatt}
                            onChange={(e) => {
                              setWarehousePusatt(e.target.value);
                              getToko(e.target.value)
                            }}
                          >
                            <option>Pilih Region</option>
                            {
                              warehousepusatss.map((a, key) => {
                                return <option key={key} value={a.id}>{a.name}</option>
                              })
                            }
                          </Input>
                        </Col>
                      </FormGroup>
                    )
                    }
                    {level < 4 ? (
                      ""
                    ) : (
                      <FormGroup row>
                        <Label
                         className="form-control-label"
                          for="exampleEmail"
                          sm={4}
                        >
                          Toko
                        </Label>
                        <Col sm={7}>
                          <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="select"
                            value={warehousepusattt}
                            onChange={(e) => {
                              setWarehousePusattt(e.target.value);

                            }}
                          >
                            <option>Pilih Toko</option>
                            {
                              warehousepusatsss.map((a, key) => {
                                return <option key={key} value={a.id}>{a.name}</option>
                              })
                            }
                          </Input>
                        </Col>
                      </FormGroup>
                    )
                    }
                    <FormGroup row>
                      <Label
                       className="form-control-label"
                        for="exampleEmail"
                        sm={4}
                      >
                        Provinsi

                      </Label>
                      <Col sm={7}>
                        <Input
                          autoComplete="off"
                          className="form-control-alternative"
                          type="select"
                          value={province}
                          onChange={(e) => {
                            setProvince(e.target.value);
                            // getKota(e.target.value);
                          }}
                        >
                          <option>Pilih Provinsi</option>
                          {
                            provinces.map((a, key) => {
                              return <option key={key} value={a.id}>{a.name}</option>
                            })
                          }
                        </Input>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                       className="form-control-label"
                        for="exampleEmail"
                        sm={4}
                      >
                        Kota

                      </Label>
                      <Col sm={7}>
                        <Input
                          autoComplete="off"
                          className="form-control-alternative"
                          name="function"
                          type="select"
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                          }}
                        >
                          <option>Pilih Kota</option>
                          {
                            citys.map((b, key) => {
                              return <option key={key} value={b.id}>{b.name}</option>
                            })
                          }
                        </Input>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup row>
                      <Label
                       className="form-control-label"
                        for="exampleEmail"
                        sm={4}
                      >
                        Kontak

                      </Label>
                      <Col sm={7}>
                        <Input
                          autoComplete="off"
                          className="form-control-alternative"
                          type="number"
                          pattern='[0-9]{0,5}'
                          name="Phone"
                          placeholder="Masukan Telephone"
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label
                       className="form-control-label"
                        for="exampleEmail"
                        sm={4}
                      >
                        Gambar

                      </Label>
                      <Col sm={7}>
                         {image === "" ?(
                              <img
                              alt="..."
                              src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
                              width="35%"
                              />
                          ):(
                          <img
                              alt=""
                              src={image}
                              width="35%"
                          />
                          )}
                      </Col>

                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card className="bg-secondary shadow">
              <Form onSubmit={handleSubmit}>
                <CardHeader>
                  <h3>lokasi</h3>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ textAlign: "center" }}>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row md="16">
                    <Col md="4">
                      <FormGroup row>
                        <Label
                         className="form-control-label"
                          for="exampleEmail"
                          sm={2}
                        >
                          lat
                        </Label>
                        <Col sm={9}>
                          <Input
                            // disabled
                            autoComplete="off"
                            className="form-control-alternative"
                            type="number"
                            pattern='[0-9]{0,5}'
                            name="Phone"
                            placeholder="Masukan Latitude"
                            value={lat}
                            onChange={(e) => {
                              setLat(e.target.value);
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup row>
                        <Label
                         className="form-control-label"
                          for="exampleEmail"
                          sm={3}
                        >
                          Lng
                        </Label>
                        <Col sm={9}>
                          <Input
                            // disabled
                            autoComplete="off"
                            className="form-control-alternative"
                            type="number"
                            pattern='[0-9]{0,5}'
                            name="Phone"
                            placeholder="Masukan Longitude"
                            value={long}
                            onChange={(e) => {
                              setLong(e.target.value);
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup row>
                        <Label
                         className="form-control-label"
                          for="exampleEmail"
                          sm={3}
                        >
                          Radius
                        </Label>
                        <Col sm={9}>
                          <Input
                            // disabled
                            autoComplete="off"
                            className="form-control-alternative"
                            type="number"
                            pattern='[0-9]{0,5}'
                            name="Phone"
                            placeholder="Masukan Radius"
                            value={radius}
                            onChange={(e) => {
                              setRadius(e.target.value);
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                    </Col>
                  </Row>
                  <Maps
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    onClickMap={onClickMap}
                    apiIsLoaded={apiIsLoaded}
                    // onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
                    isSearch={true}
                    long={long}
                    lat={lat}
                    address={selectAddress}
                    setLong={setLong}
                    setLat={setLat}
                  />
                </CardBody>
                <CardFooter>
                  <Link className="btn btn-info" to="/admin/warehouse">
                    Kembali
                  </Link>
                  <Button color="danger" onClick={setQuestionAlert}>
                    Simpan
                  </Button>
                </CardFooter>
              </Form>
            </Card>
          </div>
        </Row>
      </Container>
      </div>
      ):(
        <Halaman404 />
      )}
    </div>

  );
};

export default CreateGudangToko;