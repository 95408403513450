import React, { useCallback, useEffect, useState } from "react";
import {
  // Card,
  Button,
  Row,
  Col,
  CardBody,
//   CardHeader,
//   CardFooter,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  // Input,
  Label,
} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";
// import * as FileSaver from "file-saver";
// import SweetAlert from "react-bootstrap-sweetalert";
// import styles from '../../../../../assets/scss/Print.scss'
import moment from "moment";

const ModalCetakInvoiceBaruPo = ({ open, toggle, data }) => {
    const token = localStorage.token;
    const username = localStorage.username;
    const [savedItems, setSavedItems] = useState([]);
    const [download, setDownload] = useState("")
    // const [alert, setAlert] = React.useState(null);
    const [codepo, setCodePo] = useState("");
    // const [payment_method,setPaymentMethod] = useState([]);
    const [keterangan, setKeterangan] = useState("");
    const [supplier, setSupplier] = useState("");
    // const [jangkaWaktu,setJangkaWaktu] = useState([]);
    const [warehouserfq, setWarehouseRfq] = useState("");
    // const [coderfq, setCodeRfq] = useState([]);
    const [ongkir, setOngkir] = useState(0);
    const [waktu,setWaktu] = useState([]);
    const [hargatotal, setHargaTotal] = useState([]);
    const [validator, setValidator] = useState("");
    const [validator1, setValidator1] = useState("");
    const [address, setAddress] = useState("")
    const [phone, setPhone] = useState("")
    const [bank, setBank] = useState("")
    const [rekening, setRekening] = useState("")
    const [pricereal, setPriceReal]= useState("")
    const [diskontotal, setDiskonTotal] = useState("")
    const [pajak, setPajak] = useState("");
    const [creator, setCreator] = useState("")
    // const [item, setItem] = useState("")
    const [ppn, setPPN] = useState("")
    const [payment, setPayment] = useState("")
    const [dp, setDp] = useState(0)

  

  const getById = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/invoice-po/cetak/${data.id}`,
        { headers }
      )
      .then((data) => {  
        // setCodeRfq(data.data.response.invoicepo.invoice_code);
        setDownload(data.data.response.invoicepo.counter);
        setAddress(data.data.response.invoicepo.address)
        setPhone(data.data.response.invoicepo.phone)
        setCreator(data.data.response.invoicepo.creator)
        setSupplier(data.data.response.invoicepo.person);
        setPayment(data.data.response.invoicepo.payment_method);
        setDp(data.data.response.invoicepo.um_nominal);
        // setJangkaWaktu(data.data.response.invoicepo.jangka_waktu);
        setWaktu(data.data.response.invoicepo.created)
        setWarehouseRfq(data.data.response.invoicepo.warehouse);
        setDiskonTotal(data.data.response.invoicepo.diskon_total);
        setPajak(data.data.response.invoicepo.nominal_pajak);
        setHargaTotal(data.data.response.invoicepo.payment_total);
        setPriceReal(data.data.response.invoicepo.price_total);
        setKeterangan(data.data.response.invoicepo.keterangan);
        setOngkir(data.data.response.invoicepo.ongkir);
        setCodePo(data.data.response.invoicepo.invoice_code);
        setValidator(data.data.response.invoicepo.validator1);
        setValidator1(data.data.response.invoicepo.validator2);
        setBank(data.data.response.invoicepo.bank_account1);
        setRekening(data.data.response.invoicepo.bank_number1);
        setPPN(data.data.response.invoicepo.persen_pajak)
        // setItem(data.data.response.list.length);
        // getItemDataSaved(data.data.response.invoicepo.id)
      })
      .catch(function (error) {
        console.log(error);
      });
  },[token, data.id]);

  const HitCounter = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/invoice-po/hit-counter/${data.id}`,
        { headers }
      )
      .then((data) => {  
        console.log("suskes Cuk");
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const getItemDataSaved = useCallback(() => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/invoice-po/cetak/${data.id}`,{ headers }
    
    ).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.list.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                qty: data.qty,
                note : data.note,
                harga: data.harga,
                satuan: data.satuan,
                diskon_nominal: data.diskon_nominal,
                diskon_persen : data.diskon_persen,
                sub_total : data.sub_total,
                data: {
                    item_name: data.item_name,
                    harga: data.harga
                },
            }];
        }));

        setSavedItems(stateItem);
    })
},[token, data.id])

useEffect(() => {
  getById();
  getItemDataSaved();
}, [getById,getItemDataSaved]);

// const downloadExcel = async ()=> {
//     const J = "Invoice-PO"
//     const fileName = J;
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     };
//     axios
//       .get(`${process.env.REACT_APP_API_BASE_URL}/invoice-po/cetak/${data.id}`,{headers})
//       .then((data) => {
//         setCodeRfq(data.data.response.invoicepo.code_rfq);
//         setAddress(data.data.response.invoicepo.address)
//         setPhone(data.data.response.invoicepo.phone)
//         setCreator(data.data.response.invoicepo.creator)
//         setSupplier(data.data.response.invoicepo.person);
//         // setPaymentMethod(data.data.response.invoicepo.payment_method);
//         // setJangkaWaktu(data.data.response.invoicepo.jangka_waktu);
//         setWaktu(data.data.response.invoicepo.created)
//         setWarehouseRfq(data.data.response.invoicepo.warehouse);
//         setDiskonTotal(data.data.response.invoicepo.diskon_total);
//         setPajak(data.data.response.invoicepo.nominal_pajak);
//         setHargaTotal(data.data.response.invoicepo.payment_total);
//         setPriceReal(data.data.response.invoicepo.price_total);
//         setKeterangan(data.data.response.invoicepo.keterangan);
//         setOngkir(data.data.response.invoicepo.ongkir);
//         setCodePo(data.data.response.invoicepo.po_code);
//         setValidator(data.data.response.invoicepo.validator1);
//         setValidator1(data.data.response.invoicepo.validator2);
//         setBank(data.data.response.invoicepo.bank_account1);
//         setRekening(data.data.response.invoicepo.bank_number1);
//         setItem(data.data.response.list.length);

//         var apiPo = data.data.response.list;
//         // var tglcetak = data.data.response.download_date;
//         var tableToExcel = (function() {
//           // var uri = 'data:application/vnd.ms-excel;base64,',
//             var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
//             // base64 = function(s) {
//             //   return window.btoa(unescape(encodeURIComponent(s)))
//             // },
//             format = function(s, c) {
//               return s.replace(/{(\w+)}/g, function(m, p) {
//                 return c[p];
//               })
//             }
//           return function(table, name) {
//             var heading = 'Purchase Order' ;
//             var imgsrc1 = 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhAVKx5R3RdjeXQuRdKan2RNLsZn2U4qXYOgU4jqILz6u6MLSzlvzY1b5x9Xiz4sKHhM0UJ1NKKoFVx6ZEI8JqgANlrZ8KwCJ2j9pOmJN-e50-HzVhTFRvEahjCJB51O4oMmJ25V2yQtYOGfxV2b7C2aT9VKBruh0_znTbORz66pu9P47DMB5aP4SuF/s320/Hokky1.png';
//             var po = '';
//             var a ;
//             for(a=0; a < apiPo.length; a++){
//               po += `
//               <tr style="border: 1px solid black">
//                   <td>${apiPo[a].item_name}</td>
//                   <td>${apiPo[a].item_code}</td>
//                   <td>${formatRupiah(apiPo[a].harga)}</td>
//                   <td>${apiPo[a].qty}</td>
//                   <td>${apiPo[a].satuan}</td>
//                   <td>${apiPo[a].diskon_persen}</td>
//                   <td>${formatRupiah(apiPo[a].diskon_nominal)}</td>
//                   <td>${formatRupiah(apiPo[a].sub_total)}</td>
//               </tr>
//               `
//             }
//             var table = `
//             <table id="account_table">
//                 <tbody>
//                 <tr>
//                     <td><img src="https://i.ibb.co/jkFYDqg/150x75.png"/> </td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>${warehouserfq}</td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Jl Menganti Karangan No.562</td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Wiyung surabaya barat - Jawa Timur </td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Telp: 081 217 85 3300 </td>
//                 </tr>
//                 <tr>
//                     <td colSpan="8"><center><b><h1>PURCHASE ORDER</h1></b><center></td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                 </tr>
//                 <tr>
//                     <td>Tanggal</td>
//                     <td>: ${waktu}</td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Supplier</td>
//                     <td>: ${supplier}</td>
//                 </tr>
//                 <tr>
//                     <td>Kode PO</td>
//                     <td>: ${codepo}</td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Alamat</td>
//                     <td>: ${address}</td>
//                 <tr>
//                 <tr>
//                     <td>Kode RFQ</td>
//                     <td>: ${coderfq}</td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Phone</td>
//                     <td>: ${phone}</td>
//                 <tr>
//                 <tr>
//                     <td>Ket</td>
//                     <td rowspan="2">: ${keterangan}</td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Phone</td>
//                     <td>: ${bank}</td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Rekening</td>
//                     <td>: ${rekening}</td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                 </tr>
//                   <tr style="border: 1px solid black">
//                       <th>Nama Item</th>
//                       <th>Kode Item</th>
//                       <th>Harga</th>
//                       <th>Qty</th>
//                       <th>Satuan</th>
//                       <th>Disc %</th>
//                       <th>Disc N</th>
//                       <th>Sub Total</th>
//                     </tr>
//                         ${po}
//                 </tbody>
//             </table>
//             `;
//             var ctx = {
//                 worksheet: name || 'WorkBook',
//                 imgsrc1: imgsrc1,
//                 heading: heading,
//                 table: table
//             }
//             var blob = new Blob([format(template, ctx)]);
//             return blob;
//             }
//         })()
//         var blobURL = tableToExcel('account_table', 'Invoice PO');
//         FileSaver.saveAs(blobURL, fileName+'.xls');
        
//         })
//       .catch(function (error) {
//             setGagalAlert();
//       });
//   }
  
//   const setGagalAlert = (id) => {
//     setAlert(
//       <SweetAlert
//         danger
//         showConfirm
//         confirmBtnText="Ok"
//         title="Gagal Cok"
//         onCancel={hideAlert}
//         onConfirm={hideAlert}
//       />
//     );
//   };
  
//   const hideAlert = () => {
//     setAlert(null);
//   };

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }
    
//   console.log("cek pdf cok");

//   function print() {
//     var printContents = document.getElementById("targetContent").innerHTML;
//     var originalContents = document.body.innerHTML;
//     document.body.innerHTML = printContents;
//     window.print();
//     document.body.innerHTML = originalContents;
//   }

function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    // console.log(item)
    window.print();
    window.close();
    document.body.innerHTML = originalContents;
  }
  
  // function clickItem(e) {
  //   setItem(e.target.value)
  // }
  
  return (
    <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top:"-15%" }}>
      <ModalHeader toggle={toggle} align="center">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {/* <div style={{ textAlign: 'left' }}>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            Set Item
                        </Label>
                        <Input type="number" pattern="[0-9]*" value={item} min="1" max={savedItems.length} onChange={clickItem} style={{width: '70px',marginRight:'50px'}}/>
                    </div> */}
                    <div style={{ textAlign: 'right' }}>
                    <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            &nbsp;
                        </Label><br></br>
                        <Button color="info" onClick={() => {print();HitCounter();setTimeout(() => (window.location.reload("/admin/invoice-po/histori")), 1000)}}>Print</Button>
                        {/* <Button color="success" onClick={()=> (downloadExcel(),HitCounter())}>Excel</Button> */}
                    </div>
                </div>
      </ModalHeader>
      <ModalBody>
      <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          {renderPDF()}
        </CardBody>
        {/* <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          <div className="col">
            <KopSurat warehouseName={warehouserfq} />
          </div>
          <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                        <Row md="12">
                            <Col md="12">
                                <h3><b><center>INVOICE PO</center></b></h3>
                            </Col>
                        </Row>
                        <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                        <br></br>
                        <Row md="12">
                            <Col md="5">
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Tanggal</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-4 ">
                                    <b>{waktu}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Kode</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{codepo}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Keterangan</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{keterangan}</b>
                                </div>
                            </div>
                            </Col>
                            <Col md="1">
                            </Col>
                            <Col md="6">
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Supplier</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{supplier}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Alamat</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{address}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Telephone</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{phone}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Bank</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{bank}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Rekening</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7">
                                    <b>{rekening}</b>
                                </div>
                            </div>
                            </Col>
                        </Row>
                    <CardBody>
                      <br></br>
                      <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th>
                            <b>Nama Item</b>
                            </th>
                            <th>
                            <b>Kode Item</b>
                            </th>
                            <th>
                            <b>Harga</b>
                            </th>
                            <th>
                            <b>Qty</b>
                            </th>
                            <th>
                            <b>Satuan</b>
                            </th>
                            <th>
                            <b>Disc %</b>
                            </th>
                            <th>
                            <b>Disc N</b>
                            </th>
                            <th>
                            <b>Sub Total</b>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            savedItems.map((savedItem, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{savedItem.data.item_name}</td>
                                        <td>{savedItem.item_code}</td>
                                        <td>{formatRupiah(savedItem.harga)}</td>
                                        <td>{savedItem.qty}</td>
                                        <td>{savedItem.satuan}</td>
                                        <td>{savedItem.diskon_persen}</td>
                                        <td>{savedItem.diskon_nominal}</td>
                                        <td>{formatRupiah(savedItem.sub_total)}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                      </Table>
                      <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                      <Row md="12">
                                    <Col md="3">
                                    </Col>
                                    <Col md="3">
                                    </Col>
                                    <Col md="2">
                                    </Col>
                                    <Col md="4">
                                    <div className="row align-items-center mb-1">
                                        <div className="col-5 text-right">Total</div>
                                        <div className="col-2 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{formatRupiah(pricereal)}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-1">
                                        <div className="col-5 text-right">Diskon</div>
                                        <div className="col-2 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{formatRupiah(diskontotal)}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-1">
                                        <div className="col-5 text-right">PPN</div>
                                        <div className="col-2 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{formatRupiah(pajak)}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-1">
                                        <div className="col-5 text-right">Ongkir</div>
                                        <div className="col-2 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{formatRupiah(ongkir)}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-1">
                                        <div className="col-5 text-right">Grand Total</div>
                                        <div className="col-2 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{formatRupiah(hargatotal)}</b>
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                    </CardBody>
                    <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                        <center>Terms of Price, delivery & shipping required</center>
                        <br></br>
                                <Row md="20">
                                <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Di Buat</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{creator}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>----------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Kepala Finance</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{validator}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>----------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Direktur</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{validator1}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>----------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                                <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                      {download === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Download {download} Kali Oleh {username}</i></div>
                    </div>
                      )
                      }
        </CardBody> */}
      </ModalBody>
    </Modal>
  );
  function renderPDF(){
    // let sheet = 1;
    // let length = savedItems.length
    // if (item != length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []  
    // for (let index = 0; index < sheet; index++) {
    //   let start = 0
    //   let end = 0
    //   if(sheet == 1){
    //     end = length
    //   }else{
    //     if(sheet > 1 && index < (sheet - 1)){
    //       end = item
    //     }else{
    //       end = length % item
    //     }
    //   }

    // let sheet = 1;
    // let length = savedItems.length
    // if (item != length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []
    // for (let index = 0; index < sheet; index++) {
    //   let start = (item * index)
    //   let end = length
    //   let sisa = (length % item)
    //   if(sheet == 1){
    //     end = length
    //   }else{
    //     if(index < (sheet - 1) && sheet == 2){
    //       end = item
    //     }else{
    //       if (index == (sheet - 1)) {
    //         end = length - sisa + (index * item)
    //       }else{
    //         if (index == 0) {
    //           end = item
    //         }else{
    //           end = (index+1) * item
    //         }
    //       }
    //     }
    //   } 
    //   let data = savedItems.slice(start,end)
      // console.log(data.length)

      const itemsPerPage = 10; // Maksimal 7 item per halaman
    const totalItems = savedItems.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage); // Hitung total halaman
  
    const sheets = [];
    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      // Hitung indeks awal dan akhir untuk slice item per halaman
      const start = pageIndex * itemsPerPage;
      const end = start + itemsPerPage;
  
      // Ambil item untuk halaman saat ini
      const currentItems = savedItems.slice(start, end);

      // let sub_total = 0;
      // let diskon = 0;
      // let pajak = 0;
      let usernamecok = moment().format("YYYY-MM-DD hh:mm:ss") + " " + localStorage.username;
      sheets.push(
        <div className='pagebreak'>
          <div className="col">
            <KopSurat warehouseName={warehouserfq} />
          </div>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          <Row md="12">
              <Col md="12">
                  <h3 style={{ color: "black" }} ><b><center>FAKTUR PEMBELIAN</center></b></h3>
              </Col>
          </Row>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          
          <Row md="12">
            <Col style={{marginLeft:"2%",width:"50%",flexBasis: "unset"}}>
             
              <div className="row align-items-center ">
                  <div style={{width:"15%"}}  className="text-table">Tanggal</div>
                  <div style={{width:"2%"}}  className="text-center text-table">:</div>
                  <div style={{width:"83%"}}  className="text-table">
                      <b>{waktu}</b>
                  </div>
              </div>
               <div className="row align-items-center ">
                  <div style={{width:"15%"}} className="text-table">No Faktur</div>
                  <div style={{width:"2%"}} className="text-center text-table">:</div>
                  <div style={{width:"83%"}} className="text-table">
                      <b>{codepo}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"15%"}} className="text-table">Pembayaran</div>
                  <div style={{width:"2%"}} className="text-center text-table">:</div>
                  <div style={{width:"83%"}} className="text-table">
                    {payment === 1 ? 
                    <b>Lunas</b>
                    : payment === 2 ? 
                    <b>Tempo</b>
                    :
                    <b>Dp</b>
                  }
                  </div>
              </div>
              {/* <div className="row align-items-center ">
                  <div style={{width:"10%"}} className="text-table">No BPB</div>
                  <div style={{width:"2%"}} className="text-center text-table">:</div>
                  <div style={{width:"85%"}} className="text-table">
                      <b>-</b>
                  </div>
              </div> */}
              <div className="row align-items-center ">
                  <div style={{width:"15%"}}  className="text-table">keterangan </div>
                  <div style={{width:"2%"}}  className="text-center text-table">:</div>
                  <div style={{width:"83%"}}  className="text-table">
                      <b>{keterangan}</b>
                  </div>
              </div>
            </Col>
            <Col style={{width:"25%",flexBasis: "unset"}}>
              <div className="row align-items-center ">
                  <div style={{width:"25%"}} className="text-table">Supplier</div>
                  <div style={{width:"5%"}} className="text-table">:</div>
                  <div style={{width:"65%"}} className="text-table">
                      <b>{supplier}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"25%"}} className="text-table">Alamat</div>
                  <div style={{width:"5%"}} className="text-table">:</div>
                  <div style={{width:"65%"}} className="text-table">
                      <b>{address}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"25%"}} className="text-table">Kontak</div>
                  <div style={{width:"5%"}} className="text-table">:</div>
                  <div style={{width:"65%"}} className="text-table">
                      <b>{phone}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"25%"}} className="text-table">Rekening</div>
                  <div style={{width:"5%"}} className="text-table">:</div>
                  <div style={{width:"65%"}} className="text-table">
                      <b>{bank} {rekening}</b>
                  </div>
              </div>
            </Col>
          </Row>
            
              <Table style={{width:"100%"}} responsive>
                <thead>
                <tr>
                    <th className="text-table">
                    <b>No</b>
                    </th>
                    <th className="text-table">
                    <b>Kode</b>
                    </th>
                    <th className="text-table">
                    <b>Nama Barang</b>
                    </th>
                    <th className="text-table">
                    <b>Harga</b>
                    </th>
                    <th className="text-table">
                    <b>Qty</b>
                    </th>
                    <th className="text-table">
                    <b>Sat</b>
                    </th>
                    <th className="text-table">
                    <b>Disc %</b>
                    </th>
                    <th className="text-table">
                    <b>Disc N</b>
                    </th>
                    <th className="text-table">
                    <b>Total</b>
                    </th>
                    <th className="text-table">
                    <b>Note</b>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    currentItems.map((temp,index, key) => {
                        // let item_name =  temp.data.item_name
                        // sub_total = sub_total + temp.sub_total
                        // diskon = diskon + temp.diskon_nominal
                        return (
                            <tr>
                                <td className="text-table">{index+1}</td>
                                <td className="text-table">{temp.item_code}</td>
                                <td className="text-table">{temp.item_name}</td>
                                <td className="text-table">{formatRupiah(temp.harga / (ppn/100 + 100/100))}</td>
                                <td className="text-table">{temp.qty}</td>
                                <td className="text-table">{temp.satuan}</td> 
                                <td className="text-table">{temp.diskon_persen}</td>
                                <td className="text-table">{temp.diskon_nominal}</td>
                                <td className="text-table">
                                  {/* {formatRupiah(temp.sub_total)} */}
                                  {formatRupiah(((temp.harga - temp.diskon_nominal) * temp.qty) / (ppn/100 + 100/100))}
                                </td>
                                <td className="text-table">{temp.note}</td>
                            </tr>
                        );
                    })
                }
                  <tr className="w-100 text-table" style={{ border: "1px solid black" }}></tr>
                  <tr>
                    <td colSpan="7" className="border-non text-table"></td>
                    <td colSpan="2" className="text-right text-table border-non">Sub Total
                    </td>
                    <td className="text-left text-table border-non">
                          <b>{formatRupiah(pricereal)}</b>
                    </td>
                    <td className="border-non text-table"></td>
                  </tr>
                  <tr>
                    <td colSpan="7" className="border-non text-table"></td>
                    <td colSpan="2" className="text-right text-table border-non">Diskon
                    </td>
                    <td className="text-left text-table border-non">
                          <b>{formatRupiah(diskontotal)}</b>
                    </td>
                    <td className="border-non text-table"></td>
                  </tr>
                  <tr>
                    <td colSpan="7" className="border-non text-table"></td>
                    <td colSpan="2" className="text-right text-table border-non">PPN
                    </td>
                    <td className="text-left text-table border-non">
                          <b>{formatRupiah(pajak)}</b>
                    </td>
                    <td className="border-non text-table"></td>
                  </tr>
                  <tr>
                    <td colSpan="7" className="border-non text-table"></td>
                    <td colSpan="2" className="text-right text-table border-non">Ongkir
                    </td>
                    <td className="text-left text-table border-non">
                          <b>{formatRupiah(ongkir)}</b>
                    </td>
                    <td className="border-non text-table"></td>
                  </tr>
                  {dp === 0 ? 
                    ""
                    :
                    <tr>
                      <td colSpan="7" className="border-non text-table"></td>
                      <td colSpan="2" className="text-right text-table border-non">Uang Muka
                      </td>
                      <td className="text-left text-table border-non">
                            <b>{formatRupiah(dp)}</b>
                      </td>
                      <td className="border-non text-table"></td>
                    </tr>
                  }
                  <tr>
                    <td colSpan="7" className="border-non text-table"></td>
                    <td colSpan="2" className="text-right text-table border-non">Grand Total
                    </td>
                    <td className="text-left text-table border-non">
                          <b>{formatRupiah(hargatotal)}</b>
                    </td>
                    <td className="border-non text-table"></td>
                  </tr>
                </tbody>
              </Table>
              <div ></div>
              <Row md="12">
              </Row>
          <div className="w-100 text-table" style={{ border: "0.5px solid #b3b3b3" }}></div>
          <center className="text-table">Terms of Price, delivery & shipping required</center>
          <Row md="20">
            <Col style={{ marginLeft:"2%",width:"25%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Di Buat</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-5 text-table">
                      <b>{creator}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-5 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col>
            <Col style={{ width:"35%" }}>
              <div className="row align-items-center ">
                  <div className="col-5 text-table">Kepala Finance</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-5 text-table">
                      <b>{validator}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-5 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-5 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col>
            <Col style={{ width:"35%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Direktur</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>{validator1}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col>
          </Row>
          <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                      {download === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Download {download} Kali Oleh {username}</i></div>
                    </div>
                      )
                      }
          <div className="divFooter">{usernamecok}</div>
        </div>
      )
    }
    return (
      <div>
        {sheets}
      </div>
    );
  }
};

export default ModalCetakInvoiceBaruPo;
