/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  Form,
  CardFooter,
  FormGroup,
  Label,
  Input,
  Container,
  CardHeader,
  FormFeedback
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import ItemGambar1 from "components/SelectedFile/itemgambar1";
import ItemGambar2 from "components/SelectedFile/itemgambar2";
import ItemGambar3 from "components/SelectedFile/itemgambar3";
import Halaman404 from "views/404";

const Create = () => {
  const token = localStorage.token;
  const warehouseId = localStorage.warehouse;
  const username = localStorage.username;
  const history = useHistory();
  const [nameItem, setNameItem] = useState("");
  const [barcode, setBarcode] = useState("");
  const [kodeitem, setKodeItem] = useState("");
  const [description, setDescription] = useState("");
  const [allSatuan, setAllSatuan] = useState([]);
  const [minimumstock, setMinimumStock] = useState("");
  const [satuan, setSatuan] = useState("");
  const [allGrade, setAllGrade] = useState([]);
  const [grade, setGrade] = useState("");
  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoryId,setCategoryId] = useState("");
  const [subCategoryId,setSubCategoryId] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subCategoryError, setSubCategoryError] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [nameFunction, setNameFunction] = useState("");
  const [nameFunctions, setNameFunctions] = useState([]);
  const [nameSubFunction, setNameSubFunction] = useState("");
  const [nameSubFunctions, setNameSubFunctions] = useState([]);
  const [merek, setMerek] = useState("");
  const [allMerek, setAllMerek] = useState([]);
  const [submerek, setSubMerek] = useState("");
  const [allSubMerek, setAllSubMerek] = useState([]);
  const [panjang, setPanjang] = useState("");
  const [lebar, setLebar] = useState("");
  const [tinggi, setTinggi] = useState("");
  const [nameType, setNameType] = useState(1);
  const [itemgrup, setItemGrup] = useState("");
  const [itemgrups, setItemGrups] = useState([]);
  const [berat, setBerat] = useState("");
  const [image1,setImage1] = useState("");
  const [image2,setImage2] = useState("");
  const [image3,setImage3] = useState("");
  const [codecek, setCodeCek] = useState("");
  const headers = { Authorization: `Bearer ${token}` };
  const [alert, setAlert] = React.useState(null);
  const allInfo = JSON.parse(localStorage.allinfo);
	const itempusat = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Item Pusat").map((p) => p.read_access));
	

  const validateForm = () => {
    let error = false;
    if (category === "") {
      setCategoryError("invalid");
      error = true;
    }
    if (subCategory === "") {
      setSubCategoryError("invalid");
      error = true;
    }
    return error;
  };

  useEffect(() => {
    getSatuan();
    getCategory();
    getMerek();
    getGrade();
    getItemGrup();
  }, []);

  const getItemGrup = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/item-group/list`,
        { headers }
      )
      .then((data) => {
        setItemGrups(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getGrade = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/item-grade/list`, { headers })
      .then((data) => {
        setAllGrade(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getSatuan = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/uom/list`, { headers })
      .then((data) => {
        setAllSatuan(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const getCategory = () => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/item-kategori/list`, {
				headers,
			})
			.then((data) => {
				setCategories(data.data.response);
				// getSubCategory(id);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getSubCategory = (id) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/sub-kategori/list?kategori_id=${id}`, { headers
		})
		.then(data => {
			setSubCategories(data.data.response);
		})
		  .catch(function (error) {
			console.log(error)
		  })
	  }
	
	const getFunction = (id) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/item-function/list?kategori_id=${categoryId}&sub_kategori_id=${id}`, { headers
		})
		.then(data => {
		  setNameFunctions(data.data.response);
		})
		  .catch(function (error) {
			console.log(error)
		  })
	  }

	const getSubFunction = (id) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/sub-function/list?kategori_id=${categoryId}&sub_kategori_id=${subCategoryId}&function_id=${id}`, { headers
		})
		.then(data => {
			setNameSubFunctions(data.data.response);
		})
		  .catch(function (error) {
			console.log(error)
		  })
	  }

	const getMerek = () => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/merek/list`, { headers })
			.then((data) => {
				setAllMerek(data.data.response);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getSubMerek = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/sub-merek/list?merek_id=${id}`, { headers })
			.then((data) => {
				setAllSubMerek(data.data.response);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

  const GetCekCode = async () => {
		let filter = {
            kategori_id: parseInt(category),
            subkategori_id: parseInt(subCategory) 
		};
		const data = filter;

		const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/items/check-code`, data, { headers });
		setCodeCek(res.data.response);
	};
  

  const CreateItem = () => {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    const myjson = JSON.stringify ({
      warehouse_id: parseInt(warehouseId),
      username: username,
      barcode : barcode,
      item_description: description,
      item_code : kodeitem,
      uom_id: parseInt(satuan),
      kategori_id: parseInt(category),
      subkategori_id: parseInt(subCategory),
      grade_id: parseInt(grade),
      function_id: parseInt(nameFunction),
      subfunction_id: parseInt(nameSubFunction),
      merek_id: parseInt(merek),
      submerek_id : parseInt(submerek),
      ig_id: parseInt(itemgrup),
      berat : parseInt(berat),
      item_name: nameItem,
      minimum_stok : minimumstock,
      panjang,
      lebar,
      tinggi,
      stock: 0,
      type: nameType,
  });  

  let data = new FormData();
    data.append("body",myjson)
    data.append("image1",image1)
    data.append("image2",image2)
    data.append("image3",image3)
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/items/save`, data, {headers})
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setTimeout(() => (history.push("/admin/item")), 1000);
        })
        .catch(function (error) {
          setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={CreateItem}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      GetCekCode();
    }
  }

  return (
    <div>
      {alert}
      {itempusat && itempusat === "1" ? (
			<div>
      <SimpleHeader name="Tambah Item" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <Card className="bg-secondary shadow">
                <CardHeader>
                  <h3>Tambah Item</h3>
                </CardHeader>
                <CardBody>
                  <Row md="12">
                      <Col md="6">
                      <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Kode Item
                          </Label>
                          <Col sm={7}>
                            <Input
                            autoComplete="off"
                              className="form-control-alternative"
                              type="number"
                             
                              placeholder="Masukan Kode Item"
                              value={kodeitem}
                              onChange={(e) => {
                                setKodeItem(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                      {/* <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Barcode
                          </Label>
                          <Col sm={7}>
                            <Input
                            autoComplete="off"
                              className="form-control-alternative"
                              type="number"
                             
                              placeholder="Masukan barcode"
                              value={barcode}
                              onChange={(e) => {
                                setBarcode(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup> */}
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Nama Item
                          </Label>
                          <Col sm={7}>
                            <Input
                            autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                             
                              placeholder="Masukan Nama Item"
                              value={nameItem}
                              onChange={(e) => {
                                setNameItem(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Jenis Barang
                          </Label>
                          <Col sm={7}>
                            <Input
                            autoComplete="off"
                              className="form-control-alternative"
                              name="kategory"
                              type="select"
                              value={itemgrup}
                              onChange={(e) => {
                                setItemGrup(e.target.value);
                              }}
                            >
                              <option value="">Pilih Jenis Barang</option>
                              {itemgrups.map((categorysss, key) => {
                                return (
                                  <option key={key} value={categorysss.id}>
                                    {categorysss.name}
                                  </option>
                                );
                              })}
                              </Input>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Satuan
                          </Label>
                          <Col sm={7}>
                            <Input
                            autoComplete="off"
                          className="form-control-alternative"
                          name="satuan"
                          type="select"
                          value={satuan}
                          onChange={(e) => {
                            setSatuan(e.target.value);
                          }}
                        >
                          <option value="">Pilih Satuan</option>
                          {allSatuan.map((satuan, key) => {
                            return (
                              <option key={key} value={satuan.id}>
                                {satuan.description}
                              </option>
                            );
                          })}
                        </Input>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Dimensi <b>(PxLxT)</b>
                          </Label>
                          <Col sm={2}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="number"
                            name="panjang"
                            placeholder="P"
                            value={panjang}
                           onChange={(e) => {
                              setPanjang(e.target.value);
                            }}
                            />
                          </Col>
                          <Col sm={3}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="number"
                            name="lebar"
                            placeholder="L"
                            value={lebar}
                            onChange={(e) => {
                              setLebar(e.target.value);
                             
                            }}
                            />
                          </Col>
                          <Col sm={2}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="number"
                            name="tinggi"
                            placeholder="T"
                            value={tinggi}
                            onChange={(e) => {
                              setTinggi(e.target.value);
                             
                            }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Berat
                          </Label>
                          <Col sm={4}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="number"
                            name="Berat"
                            placeholder="Masukan Berat"
                            value={berat}
                            onChange={(e) => {
                              setBerat(e.target.value);
                              }}
                            />
                          </Col>
                          <Label
                          className="form-control-label"
                          for="exampleEmail"
                          sm={4}
                          >
                            <b>Gram</b>
                          </Label>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Minimum Stock
                          </Label>
                          <Col sm={7}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="number"
                            name="Minimum Stock"
                            placeholder="Masukan Stock"
                            value={minimumstock}
                            onChange={(e) => {
                              setMinimumStock(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Deskripsi
                              </Label>
                              <Col sm={7}>
                                <Input
                                autoComplete="off"
                                  className="form-control-alternative"
                                  type="textarea"
                                  name="descripsi"
                                  rows="8"
                                  placeholder="Masukan Deskripsi"
                                  value={description}
                                  onChange={(e) => {
                                    setDescription(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                      </Col>
                      <Col md="6">
                      <Form onSubmit={handleSubmit}>
                          {/* <FormGroup row>
														<Label
                            className="form-control-label" for="exampleEmail" sm={3}>
															Generate Kode
														</Label>
														<Col sm={6}>
                              <Input
                              autoComplete="off" 
                                  disabled
                                  className="form-control-alternative"
                                  placeholder="Generate Urutan Kode Item"
                                  name="kategory"
                                  type="text"
                                  value={codecek}
                                  onChange={(e) => {
                                    setCodeCek(e.target.value);
                                  }}
                              />
														</Col>
                            <Col sm={3}>
                              <Button color="primary" className="mb-3" type="submit"><i className="fa fa-search"></i></Button> 
														</Col> 
													</FormGroup> */}
                      </Form>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                          >
                            Tipe Item
                          </Label>
                          <Col sm={6}>
                            <Input
                              disabled
                              autoComplete="off"
                              className="form-control-alternative"
                              type="select"
                              placeholder="Masukan Tipe Item"
                              value={nameType}
                              onChange={(e) => {
                                setNameType(e.target.value);
                              
                              }}
                            >
                              <option value={1} selected>Material</option>
                              <option value={2}>Produksi</option>
                            </Input>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                          >
                            Kategori
                          </Label>
                          <Col sm={6}>
                            <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              name="kategory"
                              type="select"
                              value={category}
                              invalid={categoryError === "invalid"}
                              onChange={(e) => {
                                setCategory(e.target.value);
																setCategoryId(e.target.value);
																// getSubCategory(e.target.value);
                                if (e.target.value !== "") {
                                    setCategoryError("");
                                }
                                getSubCategory(e.target.value);
															}}>
                              <option value="">Pilih Kategori</option>
                                {categories.map((category, key) => {
                                  return (
                                    <option key={key} value={category.id}>
                                      {category.name}
                                    </option>
                                  );
                                })}
                              </Input>
                              <FormFeedback>
                                Kategori Tidak Boleh Kosong
                              </FormFeedback>
                          </Col>
                          <Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-kategori/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                          >
                            Sub Kategori
                          </Label>
                          <Col sm={6}>
                            <Input
                            autoComplete="off"
                                  className="form-control-alternative"
                                  name="subKategory"
                                  type="select"
                                  value={subCategory}
                                  invalid={subCategoryError === "invalid"}
                                  onChange={(e) => {
                                    setSubCategory(e.target.value);
                                    setSubCategoryId(e.target.value);
                                  if (e.target.value !== "") {
                                      setSubCategoryError("");
                                  }
                                  getFunction(e.target.value);
															  }}>
                                  <option value="">Pilih Sub Kategori</option>
                              {subCategories.map((category, key) => {
                                return (
                                  <option key={key} value={category.id}>
                                    {category.name}
                                  </option>
                                );
                              })}
                            </Input>
                            <FormFeedback>
                              Sub Kategori tidak boleh kosong
                            </FormFeedback>
                          </Col>
                          <Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-sub-kategori/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                          >
                            Function
                          </Label>
                          <Col sm={6}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                              name="function"
                              type="select"
                              value={nameFunction}
                              onChange={(e) => {
                                setNameFunction(e.target.value);
                                getSubFunction(e.target.value)
                              }}
                            >
                              <option value="">Pilih Function</option>
                              {nameFunctions.map((nameFunction, key) => {
                                return (
                                  <option key={key} value={nameFunction.id}>
                                    {nameFunction.name}
                                  </option>
                                );
                              })}
                            </Input>
                          </Col>
                          <Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-function/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                          >
                            Sub Function
                          </Label>
                          <Col sm={6}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                              name="subFunction"
                              type="select"
                              value={nameSubFunction}
                              onChange={(e) => {
                                setNameSubFunction(e.target.value);
                              }}
                            >
                              <option value="">Pilih Sub Function</option>
                              {nameSubFunctions.map((nameFunction, key) => {
                                return (
                                  <option key={key} value={nameFunction.id}>
                                    {nameFunction.name}
                                  </option>
                                );
                              })}
                            </Input>
                          </Col>
                          <Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-sub-function/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                          >
                            Merek
                          </Label>
                          <Col sm={6}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                              name="merek"
                              type="select"
                              value={merek}
                              onChange={(e) => {
                                setMerek(e.target.value);
                                getSubMerek(e.target.value)
                              }}
                            >
                              <option value="">Pilih Merek</option>
                              {allMerek.map((m, key) => {
                                return (
                                  <option key={key} value={m.id}>
                                    {m.name}
                                  </option>
                                );
                              })}
                            </Input>
                          </Col>
                          <Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-merek/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                          >
                            Sub Merk
                          </Label>
                          <Col sm={6}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                              name="merek"
                              type="select"
                              value={submerek}
                              onChange={(e) => {
                                setSubMerek(e.target.value);
                              }}
                            >
                              <option value="">Pilih Merek</option>
                              {allSubMerek.map((m, key) => {
                                return (
                                  <option key={key} value={m.id}>
                                    {m.name}
                                  </option>
                                );
                              })}
                            </Input>
                          </Col>
                          <Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-sub-merek/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                          >
                            Grade
                          </Label>
                          <Col sm={6}>
                          <Input
                          className="form-control-alternative"
                          name="grade"
                          type="select"
                          value={grade}
                          onChange={(e) => {
                            setGrade(e.target.value);
                            }}
                        >
                          <option value="">Pilih Grade</option>
                          {allGrade.map((a, key) => {
                            return (
                              <option key={key} value={a.id}>
                                {a.nilai}
                              </option>
                            );
                          })}
                        </Input>
                          </Col>
                          
                          <Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-grade/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
                        </FormGroup>
                      </Col>
                  </Row>
                </CardBody>
                </Card>
                <Card className="bg-secondary shadow">
                <CardHeader>
                  <h3>Upload Gambar</h3>
                </CardHeader>
                <CardBody>
                  <Row md="12">
                    <Col md="10">
												<FormGroup row>
													<Label
                          className="form-control-label" for="exampleEmail" sm={3}>
														<b>Foto Produk</b><br></br>
														Format gambar .jpg .jpeg .png dan ukuran minimum <b>300px X 300px</b>.
													</Label>
														<Col sm={2}>
														<label
                            className="form-control-label" htmlFor1="formId1" >
															<input
															hidden
															id="formId1"
															name="file"
															type="file"
                              accept="image/*"
															onChange={(event) => {
																setImage1(event.target.files[0]);
															}}
															/>
															<ItemGambar1
															onclick={() => { }}
															imageSrc1={image1 != "" && image1 != undefined ? URL.createObjectURL(image1) : null}
															/>
														</label>
														</Col>
														<Col sm={2}>
														<label
                            className="form-control-label" htmlFor1="formId1" >
															<input
															hidden
															id="formId1"
															name="file"
															type="file"
                              accept="image/*"
															onChange={(event) => {
																setImage2(event.target.files[0]);
															}}
															/>
															<ItemGambar2
															onclick={() => { }}
															imageSrc2={image2 != "" && image2 != undefined ? URL.createObjectURL(image2) : null}
															/>
														</label>
														</Col>
														<Col sm={2}>
														<label
                            className="form-control-label" htmlFor1="formId1" >
															<input
															hidden
															id="formId1"
															name="file"
															type="file"
                              accept="image/*"
															onChange={(event) => {
																setImage3(event.target.files[0]);
															}}
															/>
															<ItemGambar3
															onclick={() => { }}
															imageSrc3={image3 != "" && image3 != undefined ? URL.createObjectURL(image3) : null}
															/>
														</label>
														</Col>
											</FormGroup>
										</Col>
                  </Row>
                </CardBody>
                <CardFooter>
                    <Link className="btn btn-info" to="/admin/item">
                      Kembali
                    </Link>
                    <Button color="danger" onClick={() => setQuestionAlert()}>
                        Simpan
                      </Button>
                </CardFooter>            
                </Card>
          </div>
        </Row>
      </Container>
      </div>
      ):(
        <Halaman404 />
      )}
    </div>
    
  );
};

export default Create;