/*eslint-disable*/
import React from "react";
import {
  Card,
  CardBody,
  Row,
  Container,
  CardImg,
} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";

export default function BankBMReturPage() {
    const allInfo = JSON.parse(localStorage.allinfo);
    const BkmReturKas = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Bukti Masuk Retur Beli").map((p) => p.read_access));
    const BkmRetur = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab BM Retur").map((p) => p.read_access));
	const validasiKepalaFinance = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi BM Admin").map((p) => p.read_access));
	const validasiDirektur = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi BM Direktur").map((p) => p.read_access));
	const Histori = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Cetak BM Retur").map((p) => p.read_access));
 return (
    <>
       {BkmReturKas && BkmReturKas === "1" ? (
      <div>
      <SimpleHeader name="BBM Retur" parentName="Report" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <CardBody>
                    <Row>
                        <div className="card-columns">
                        {BkmRetur && BkmRetur === "1" && (
                            <Card>
                                <Link to="/admin/bm-retur/benk/page">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/bm-retur-beli.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {validasiKepalaFinance && validasiKepalaFinance === "1" && (
                            <Card>
                                <Link to="/admin/bm-retur/benk/validasi-kepala-finance">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-kepala-finance.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {validasiDirektur && validasiDirektur === "1" && (
                            <Card>
                                <Link to="/admin/bm-retur/benk/validasi-direktur">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-direktur.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {Histori && Histori === "1" && (
                            <Card>
                                <Link to="/admin/bm-retur/benk/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        </div>
					</Row>
                </CardBody>
          </div>
        </Row>
      </Container>
      </div>
       ):(
        <Halaman404 />
       )}
    </>
  );
}