/*eslint-disable*/
import React, { useState, useEffect  } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Label,
  Container
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import FotoArea from "components/SelectedFile/fotoarea";
import FotoPosisi from "components/SelectedFile/fotoposisi";
import FotoSisi from "components/SelectedFile/fotosisi";
import FotoDisplay from "components/SelectedFile/fotodisplay";
import Halaman404 from "views/404";

export default function EditGambarRak(props) {
  const token = localStorage.token;
  const history = useHistory();
  const [subarea, setSubArea] = useState("");
  const [area1, setArea1] = useState("");
  const [sisi1, setSisi1] = useState("");
  const [code, setCode] = useState("");
  const [hook1, setHook1] = useState("");
  const [alert, setAlert] = React.useState(null);
  const allInfo = JSON.parse(localStorage.allinfo);
  const rak = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Lokasi Barang").map((p) => p.read_access));

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/rak/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setCode(data.data.response.rak_code)
      })
      .catch(function (error) {
        console.log(error);
      });
  };
 
  const EditRak = () => {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    let data = new FormData();
    data.append("area",area1)
    data.append("sub_area",subarea)
    data.append("sisi",sisi1)
    data.append("hook",hook1)
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/rak/update-denah/${props.match.params.id}`, data, {headers
      })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setTimeout(() => (history.push("/admin/rak")), 1000);
        })
        .catch(function (error) {
          setGagalAlert(error.response.data.message);
        });
    }

    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }

  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };

  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={EditRak}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };

    const hideAlert = () => {
      setAlert(null);
    }

  return (
    <div>
      {alert}
      {rak && rak === "1" ? (
			<div>
      <SimpleHeader name="Edit Planogram" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
              <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                      <h3>Gambar Rak {code}</h3>
                  </CardHeader>
                  <CardBody>
                  <Row md="12">
                      <Col md="6">
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                 Area
                              </Label>
                              <Col sm={7}>
                                <label htmlFor1="formId1" >
                                  <input
                                    hidden
                                    id="formId1"
                                    name="file"
                                    type="file"
                                    accept="image/*"
                                    onChange={(event) => {
                                      setArea1(event.target.files[0]);
                                    }}
                                  />
                                  <FotoArea
                                    onclick={() => { }}
                                    imageSrc1={area1 != "" && area1 != undefined ? URL.createObjectURL(area1) : null}
                                  />
                                </label>
                              </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Sub Area
                              </Label>
                              <Col sm={7}>
                                <label htmlFor2="formId2" >
                                  <input
                                    hidden
                                    id="formId2"
                                    name="file"
                                    type="file"
                                    accept="image/*"
                                    onChange={(event) => {
                                      setSubArea(event.target.files[0]);
                                    }}
                                  />
                                  <FotoPosisi
                                    onclick={() => { }}
                                    imageSrc2={subarea != "" && subarea != undefined ? URL.createObjectURL(subarea) : null}
                                  />
                                </label>
                              </Col>
                            </FormGroup>
                      </Col>
                      <Col md="6">
                      <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                 Sisi
                              </Label>
                              <Col sm={7}>
                                <label htmlFor3="formId3" >
                                  <input
                                    hidden
                                    id="formId3"
                                    name="file"
                                    type="file"
                                    accept="image/*"
                                    onChange={(event) => {
                                      setSisi1(event.target.files[0]);
                                    }}
                                  />
                                  <FotoSisi
                                    onclick={() => { }}
                                    imageSrc3={sisi1 != "" && sisi1 != undefined ? URL.createObjectURL(sisi1) : null}
                                  />
                                </label>
                              </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                 Hook
                              </Label>
                              <Col sm={7}>
                                <label htmlFor4="formId4" >
                                  <input
                                    hidden
                                    id="formId4"
                                    name="file"
                                    type="file"
                                    accept="image/*"
                                    onChange={(event) => {
                                      setHook1(event.target.files[0]);
                                    }}
                                  />
                                  <FotoDisplay
                                    onclick={() => { }}
                                    imageSrc4={hook1 != "" && hook1 != undefined ? URL.createObjectURL(hook1) : null}
                                  />
                                </label>
                              </Col>
                            </FormGroup>
                      </Col>
                  </Row>
                  </CardBody>
              <CardFooter>
                  <Link className="btn btn-info" to="/admin/rak">
                  Kembali
                  </Link>
                  <Button color="danger" onClick={() => setQuestionAlert()}>
                      Simpan
                  </Button>
              </CardFooter>
              </Card>
          </div>
        </Row>
      </Container>
      </div>
      ):(
        <Halaman404 />
      )}
    </div>
    
  );
};
