/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
    CardFooter,
	Col,
	Button,
	Container,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import CurrencyInput from 'react-currency-input-field';

export default function Userss(props) {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
	let history = useHistory();
    const [awal, setAwal] = useState("");
    const [resign, setResign] = useState(null);
    const [gender, setGender] = useState([]);
    const [unit, setUnit] = useState("");
    const [citys, setCitys] = useState([]);
    const [city, setCity] = useState("");
    const [provinces, setProvinces] = useState([]);
    const [province, setProvince] = useState("");
    const [status, setStatus] = useState("");
    const [warehouse, setWarehouse] = useState("");
    const [warehouses, setWarehouses] = useState([]);
    const [nik,setNik] = useState("");
    const [ktp,setKtp] = useState("");
    const [alamat, setAlamat] = useState("");
    const [name,setName] = useState("");
    const [jabatan,setJabatan] = useState("");
    const [kelahiran,setKelahiran] = useState("");
    const [sim,setSim] = useState("");
    const [nosim,setNoSim] = useState("");
    const [statuskk,setStatusKK] = useState("");
    const [namakk,setNamaKK] = useState("");
    const [genderkk,setGenderKK] = useState("");
    const [alamatkk,setAlamatKK] = useState("");
    const [teleponkk,setTeleponKK] = useState("");
    const [telepon,setTelepon] = useState("");
    const [hubungankk,setHubunganKK] = useState("");
    const [masasim,setMasaSim] = useState("");
    const [units, setUnits] = useState([])
    const [group, setGroup] = useState("")
    const [groups, setGroups] = useState([])
    const [gaji, setGaji] = useState("")
     
	useEffect(() => {
        getById();
	}, []);

	  const getById = () => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/karyawan/get/${props.match.params.id}`,
	        { headers }
	      )
	      .then((data) => {
            getWarehouse(data.data.response.warehouse_id);
            getGroup(data.data.response.grup_id);
            getUnit(data.data.response.unit_id);
            getProvinsi(data.data.response.province_id);
            getKota(data.data.response.province_id);
            setProvince(data.data.response.province_id);
            setCity(data.data.response.city_id);
            setAlamat(data.data.response.address);
            setResign(data.data.response.resign);
            setAwal(data.data.response.recruit);
            setStatus(data.data.response.active_flag);
            setNik(data.data.response.nik);
            setKtp(data.data.response.ktp);
            setName(data.data.response.name);
            setJabatan(data.data.response.jabatan);
            setGender(data.data.response.gender);
            setKelahiran(data.data.response.kelahiran)
            setMasaSim(data.data.response.masa_sim)
            setSim(data.data.response.sim);
            setNoSim(data.data.response.nomor_sim);
            setStatusKK(data.data.response.status_kk);
            setNamaKK(data.data.response.nama_kk);
            setGenderKK(data.data.response.gender_kk);
            setAlamatKK(data.data.response.alamat_kk);
            setTeleponKK(data.data.response.telepon_kk);
            setTelepon(data.data.response.phone);
            setHubunganKK(data.data.response.hubungan_kk);
            setGaji(data.data.response.gaji);
            // setAwal(moment(data.data.response.recruit, "DD/MM/YYYY").format("YYYY-MM-DD"));
            // setKelahiran(moment(data.data.response.kelahiran, "DD/MM/YYYY").format("YYYY-MM-DD"));
            // setMasaSim(moment(data.data.response.masa_sim, "DD/MM/YYYY").format("YYYY-MM-DD"));
            // setResign(data.data.response.recruit);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

    const getWarehouse = (id) => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`,
            { headers }
          )
          .then((data) => {
            setWarehouses(data.data.response);
            setWarehouse(id)
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      const getGroup = (id) => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
    axios
        .get(
        `${process.env.REACT_APP_API_BASE_URL}/grup/list`,
        { headers }
        )
        .then((data) => {
        setGroups(data.data.response);
        setGroup(id)
        })
        .catch(function (error) {
        console.log(error);
        });
    };

    const getUnit = (id) => {
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };
    axios
        .get(
        `${process.env.REACT_APP_API_BASE_URL}/unit/list`,
        { headers }
        )
        .then((data) => {
        setUnits(data.data.response);
        setUnit(id)
        })
        .catch(function (error) {
        console.log(error);
        });
    };

      const getProvinsi = (id) => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/province/list`, { headers
        })
        .then(data => {
          setProvinces(data.data.response_data);
          setProvince(id)
        })
          .catch(function (error) {
            console.log(error)
          })
      }
    
      const getKota = (id) => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/city?province_id=${id}`, { headers
        })
        .then(data => {
          setCitys(data.data.response_data);
          setCity(id)
        })
          .catch(function (error) {
            console.log(error)
          })
      }

    function UpdateKaryawan() {
        let data = {
            warehouse_id: parseInt(warehouse),
            nik: nik,
            ktp: ktp,
            name:name,
            address: alamat,
            grup_id : parseInt(group),
            unit_id : parseInt(unit),
            jabatan: parseInt(jabatan),
            gender: parseInt(gender),
            province : province,
            city : city,
            kelahiran : kelahiran ,
            masa_sim : masasim,
            nomor_sim : nosim,
            sim: sim,
            status_kk:statuskk,
            nama_kk:namakk,
            gender_kk:parseInt(genderkk),
            alamat_kk:alamatkk,
            telepon_kk:teleponkk,
            phone:telepon,
            hubungan_kk:hubungankk,
            recruit : awal,
            resign : resign,
          //   resign : resign === null ? resign : moment(resign).format("YYYY-MM-DD"),
            active_flag: parseInt(status),
            gaji : parseInt(gaji)
        };
        axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/karyawan/update/${props.match.params.id}`, data , {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then(function (response) {
              setSuccessAlert(response.data.message);
              setTimeout(() => (history.push("/admin/karyawan")), 1000);
              })
              .catch(function (error) {
                setGagalAlert(error.response.data.message);
              });
        }

    //   function UpdateKaryawan() {
    //     const headers = {
    //       "Content-Type": "multipart/form-data",
    //       Authorization: `Bearer ${token}`,
    //     };
    //     const myjson = JSON.stringify({
    //       warehouse_id: parseInt(warehouse),
    //       nik: nik,
    //       ktp: ktp,
    //       name:name,
    //       address: alamat,
    //       grup_id : parseInt(group),
    //       unit_id : parseInt(unit),
    //       jabatan: parseInt(jabatan),
    //     //   province: parseInt(province),
    //     //   city: parseInt(city),
    //       gender: parseInt(gender),
    //       kelahiran : kelahiran ,
    //       masa_sim : masasim,
    //       nomor_sim : nosim,
    //       sim: sim,
    //       status_kk:statuskk,
    //       nama_kk:namakk,
    //       gender_kk:parseInt(genderkk),
    //       alamat_kk:alamatkk,
    //       telepon_kk:teleponkk,
    //       hubungan_kk:hubungankk,
    //     //   recruit : moment(awal).format("YYYY-MM-DD"),
    //       recruit : awal,
    //       resign : resign,
    //     //   resign : resign === null ? resign : moment(resign).format("YYYY-MM-DD"),
    //       active_flag: parseInt(status),
    //       gaji : parseInt(gaji)
    //     });
    //     let data = new FormData();
    //     data.append("body",myjson)
    //     data.append("profile",image)
    //     axios.post(`${process.env.REACT_APP_API_BASE_URL}/karyawan/update/${props.match.params.id}`, data, {
    //       headers
    //     })
    //       .then(function (response) {
    //         setSuccessAlert(response.data.message);
    //         setTimeout(() => (history.push("/admin/karyawan")), 1000);
    //         })
    //         .catch(function (error) {
    //           setGagalAlert(error.response.data.message);
    //         });
    //   }
    
      const setSuccessAlert = (id) => {
        setAlert(
          <SweetAlert
            success
            showConfirm
            confirmBtnText="Ok"
            title={id}
            onCancel={hideAlert}
            onConfirm={hideAlert}
          />
        )
      }
    
    const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
      danger
      showConfirm
      confirmBtnText="Ok"
      title={id}
      onCancel={hideAlert}
      onConfirm={hideAlert}
      />
    );
    };
    
    const setQuestionAlert = () => {
    setAlert(
      <SweetAlert
      warning
      showCancel
      confirmBtnText="Lanjutkan"
      confirmBtnBsStyle="danger"
      title="Apakah Kamu Yakin?"
      onConfirm={UpdateKaryawan}
      onCancel={hideAlert}
      focusCancelBtn
      />
    );
    };
    
      const hideAlert = () => {
        setAlert(null);
      }

      const priceGaji = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setGaji(value || ' ');
																
	  };

	return (
			<div>
                {alert}
				<SimpleHeader name="Edit Karyawan" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <h3>Edit Karyawan</h3>
                                </CardHeader>
                                <Row md="12">
                                    <Col md="6">
                                        <CardBody>
                                            <FormGroup row>
                                            <Label
                                            className="form-control-label"
                                             for="exampleEmail" sm={4}>
                                                Cabang
                                            </Label>
                                            <Col sm={7}>
                                                <Input
                                                autoComplete="off"
                                                disabled
                                                className="form-control-alternative"
                                                name="person"
                                                type="select"
                                                value={warehouse}
                                                onChange={(e) => {
                                                    setWarehouse(e.target.value);
                                                }}
                                                >
                                                <option value=''>Pilih Cabang</option>
                                                {warehouses.map((warehouse2, key) => {
                                                    return (
                                                        <option key={key} value={warehouse2.id}>
                                                        {warehouse2.name}
                                                        </option>
                                                    );
                                                    })}
                                                </Input>
                                            </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                 for="exampleEmail" sm={4}>
                                                    NIP
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="number"
                                                        
                                                        placeholder="Masukan Nomor Induk Pegawai"
                                                        value={nik}
                                                        onChange={(e) => {
                                                            setNik(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                 for="exampleEmail" sm={4}>
                                                    KTP
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="number"
                                                        
                                                        placeholder="Masukan KTP"
                                                        value={ktp}
                                                        onChange={(e) => {
                                                            setKtp(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                 for="exampleEmail" sm={4}>
                                                    Nama
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="text"
                                                        name="barcode"
                                                        placeholder="Masukan Nama"
                                                        value={name}
                                                        onChange={(e) => {
                                                            setName(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                 for="exampleEmail" sm={4}>
                                                    Kelahiran
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="date"
                                                        placeholder="Masukan Kelahiran"
                                                        value={kelahiran}
                                                        onChange={(e) => {
                                                            setKelahiran(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Col
                                                for="exampleEmail"
                                                sm={4}
                                                >
                                                Jenis Kelamin
                                                </Col>
                                                <Col sm={7}>
                                                    <div style={{ display: "flex" }}>
                                                        <div className="custom-control custom-radio mb-4">
                                                            <Input
                                                            autoComplete="off"
                                                            className="custom-control-input"
                                                            id="customRadio10"
                                                            name="custom-radio-4"
                                                            type="radio"
                                                            value={1}
                                                            checked={gender === 1}
                                                            onChange={() => setGender(1)}
                                                            />
                                                            <Label
                                                            className="custom-control-label"
                                                            htmlFor="customRadio10"
                                                            >
                                                            </Label>
                                                            <b>PRIA</b>
                                                        </div>
                                                        <div
                                                            className="custom-control custom-radio mb-4"
                                                            style={{ marginLeft: "20px" }}
                                                        >
                                                            <Input
                                                            autoComplete="off"
                                                            className="custom-control-input"
                                                            id="customRadio11"
                                                            name="custom-radio-4"
                                                            type="radio"
                                                            value={2}
                                                            checked={gender === 2}
                                                            onChange={() => setGender(2)}
                                                            />
                                                            <Label
                                                            className="custom-control-label"
                                                            htmlFor="customRadio11"
                                                            >
                                                            </Label>
                                                            <b>WANITA</b>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                 for="exampleEmail" sm={4}>
                                                    Status Perkawinan
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="select"
                                                        
                                                        placeholder="Masukan Status"
                                                        value={statuskk}
                                                        onChange={(e) => {
                                                            setStatusKK(e.target.value);
                                                        }}>
                                                        <option value="" selected>Pilih Status Perkawinan</option>
                                                        <option value="Belum Kawin">Belum Kawin</option>
                                                        <option value="Kawin">Kawin</option>
                                                        <option value="Duda">Duda</option>
                                                        <option value="Janda">Janda</option>

                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                 for="exampleEmail" sm={4}>
                                                    Phone
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="number"
                                                        
                                                        placeholder="Masukan Telepone"
                                                        value={telepon}
                                                        onChange={(e) => {
                                                            setTelepon(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
className="form-control-label"

                                                    for="exampleEmail"
                                                    sm={4}
                                                >
                                                    Provinsi
                                                    
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="select"
                                                        value={province}
                                                        onChange={(e) => {
                                                        setProvince(e.target.value);
                                                        getKota(e.target.value);
                                                        }}
                                                    >
                                                        <option>Pilih Provinsi</option>
                                                        {
                                                        provinces.map((a, key) => {
                                                            return <option key={key} value={a.id}>{a.name}</option>
                                                        })
                                                        }
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
className="form-control-label"

                                                    for="exampleEmail"
                                                    sm={4}
                                                >
                                                    Kota
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        name="function"
                                                        type="select"
                                                        value={city}
                                                        onChange={(e) => {
                                                        setCity(e.target.value);
                                                        }}
                                                    >
                                                        <option>Pilih Kota</option>
                                                        {
                                                        citys.map((b, key) => {
                                                            return <option key={key} value={b.id}>{b.name}</option>
                                                        })
                                                        }
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
className="form-control-label"

                                                    for="exampleEmail"
                                                    sm={4}
                                                >
                                                    Alamat
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        placeholder="Masukan Alamat"
                                                        Rows="5"
                                                        type="textarea"
                                                        value={alamat}
                                                        onChange={(e) => {
                                                        setAlamat(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                    </Col>
                                    <Col md="6">
                                        <CardBody>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                 for="exampleEmail" sm={4}>
                                                    Jabatan
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    autoComplete="off"
                                                    className="form-control-alternative"
                                                    name="person"
                                                    type="select"
                                                    value={group}
                                                    onChange={(e) => {
                                                        setGroup(e.target.value);
                                                    }}
                                                    >
                                                    <option value=''>Pilih Jabatan</option>
                                                    {groups.map((warehouse2, key) => {
                                                        return (
                                                            <option key={key} value={warehouse2.id}>
                                                            {warehouse2.description}
                                                            </option>
                                                        );
                                                        })}
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                 for="exampleEmail" sm={4}>
                                                    Unit
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    autoComplete="off"
                                                    className="form-control-alternative"
                                                    name="person"
                                                    type="select"
                                                    value={unit}
                                                    onChange={(e) => {
                                                        setUnit(e.target.value);
                                                    }}
                                                    >
                                                    <option value=''>Pilih Unit</option>
                                                    {units.map((warehouse2, key) => {
                                                        return (
                                                            <option key={key} value={warehouse2.id}>
                                                            {warehouse2.description}
                                                            </option>
                                                        );
                                                        })}
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                 for="validation-example-2-field" sm={4}>
                                                    Gaji
                                                </Label>
                                                <Col sm={7}>
                                                    {/* <Input
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="number"
                                                        placeholder="Rp. 0"
                                                        value={gaji}
                                                        onChange={(e) => {
                                                            setGaji(e.target.value);
                                                        }}
                                                    /> */}
                                                    <CurrencyInput
															id="validation-example-2-field"
															// placeholder="Rp0"
															allowDecimals={false}
															value={gaji}
															onValueChange={priceGaji}
															className={`form-control`}
															prefix={'Rp'}
															step={10}
														/>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                 for="exampleEmail" sm={4}>
                                                    SIM
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="select"
                                                        
                                                        placeholder="Masukan SIM"
                                                        value={sim}
                                                        onChange={(e) => {
                                                            setSim(e.target.value);
                                                        }} >
                                                        <option value="" selected>Pilih SIM</option>
                                                        <option value="A">A</option>
                                                        <option value="BUmum">B Umum</option>
                                                        <option value="B1">B1</option>
                                                        <option value="B2">B2</option>
                                                        <option value="C">C</option>
                                                        <option value="D">D</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                 for="exampleEmail" sm={4}>
                                                    No SIM
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="number"
                                                        
                                                        placeholder="Masukan No SIM"
                                                        value={nosim}
                                                        onChange={(e) => {
                                                            setNoSim(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                 for="exampleEmail" sm={4}>
                                                    Masa Berlaku SIM
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="date"
                                                        placeholder="Masukan Masa Berlaku SIM"
                                                        value={masasim}
                                                        onChange={(e) => {
                                                            setMasaSim(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                 for="exampleEmail" sm={4}>
                                                    Tanggal Bekerja
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="date"
                                                        name="itemCode"
                                                        placeholder="Tanggal Bekerja"
                                                        value={awal}
                                                        onChange={(e) => {
                                                            setAwal(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                 for="exampleEmail" sm={4}>
                                                    Tanggal Resign
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    autoComplete="off" 
                                                        className="form-control-alternative"
                                                        type="date"
                                                        name="barcode"
                                                        placeholder="Tanggal Resign"
                                                        value={resign}
                                                        onChange={(e) => {
                                                            setResign(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <h3>DATA SAUDARA</h3>
                                    </CardHeader>
                                    <Row md="12">
                                        <Col md="6">
                                            <CardBody>
                                                <FormGroup row>
                                                    <Label
                                                    className="form-control-label"
                                                     for="exampleEmail" sm={4}>
                                                        Nama 
                                                    </Label>
                                                    <Col sm={7}>
                                                        <Input
                                                        autoComplete="off"
                                                            className="form-control-alternative"
                                                            type="text"
                                                            
                                                            placeholder="Masukan Nama Lengkap"
                                                            value={namakk}
                                                            onChange={(e) => {
                                                                setNamaKK(e.target.value);
                                                            }}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label
                                                    className="form-control-label"
                                                     for="exampleEmail" sm={4}>
                                                        Alamat
                                                    </Label>
                                                    <Col sm={7}>
                                                        <Input
                                                        autoComplete="off"
                                                            className="form-control-alternative"
                                                            type="text"
                                                            
                                                            placeholder="Masukan Alamat"
                                                            value={alamatkk}
                                                            onChange={(e) => {
                                                                setAlamatKK(e.target.value);
                                                            }}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Col
                                                    for="exampleEmail"
                                                    sm={4}
                                                    >
                                                    Jenis Kelamin
                                                    </Col>
                                                    <Col sm={7}>
                                                        <div style={{ display: "flex" }}>
                                                            <div className="custom-control custom-radio mb-3">
                                                                <Input
                                                                autoComplete="off"
                                                                className="custom-control-input"
                                                                id="customRadio13"
                                                                name="custom-radio-5"
                                                                type="radio"
                                                                value={1}
                                                                checked={genderkk === 1}
                                                                onChange={() => setGenderKK(1)}
                                                                />
                                                                <Label
                                                                className="custom-control-label"
                                                                htmlFor="customRadio13"
                                                                >
                                                                </Label>
                                                               <b>PRIA</b>
                                                            </div>
                                                            <div
                                                                className="custom-control custom-radio mb-3"
                                                                style={{ marginLeft: "20px" }}
                                                            >
                                                                <Input
                                                                autoComplete="off"
                                                                className="custom-control-input"
                                                                id="customRadio14"
                                                                name="custom-radio-5"
                                                                type="radio"
                                                                value={2}
                                                                checked={genderkk === 2}
                                                                onChange={() => setGenderKK(2)}
                                                                />
                                                                <Label
                                                                className="custom-control-label"
                                                                htmlFor="customRadio14"
                                                                >
                                                                </Label>
                                                                <b>WANITA</b>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </FormGroup>
                                            </CardBody>
                                        </Col>
                                        <Col md="6">
                                            <CardBody> 
                                                <FormGroup row>
                                                    <Label
                                                    className="form-control-label"
                                                     for="exampleEmail" sm={4}>
                                                        Phone
                                                    </Label>
                                                    <Col sm={7}>
                                                        <Input
                                                        autoComplete="off"
                                                            className="form-control-alternative"
                                                            type="number"
                                                            
                                                            placeholder="Masukan Telepone"
                                                            value={teleponkk}
                                                            onChange={(e) => {
                                                                setTeleponKK(e.target.value);
                                                            }}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label
                                                    className="form-control-label"
                                                     for="exampleEmail" sm={4}>
                                                        Hubungan
                                                    </Label>
                                                    <Col sm={7}>
                                                        <Input
                                                        autoComplete="off"
                                                            className="form-control-alternative"
                                                            type="select"
                                                            
                                                            placeholder="Masukan Hubungan"
                                                            value={hubungankk}
                                                            onChange={(e) => {
                                                                setHubunganKK(e.target.value);
                                                            }}
                                                        >
                                                         <option value="" selected>Pilih Hubungan</option>
                                                            <option value="Orang Tua">Orang Tua</option>
                                                            <option value="Suami/Istri">Suami/Istri</option>
                                                            <option value="Saudara Kandung">Saudara Kandung</option>
                                                            <option value="Teman">Teman</option>
                                                            </Input>
                                                    </Col>
                                                </FormGroup>
                                            </CardBody>
                                        </Col>
                                    </Row>
                                    <CardFooter>
                                        <Link className="btn btn-info" to="/admin/karyawan">
                                            Kembali
                                        </Link>
                                        <Button color="danger"  onClick={() => setQuestionAlert()}>
                                            Simpan
                                        </Button>
                                    </CardFooter>
                                </Card>
						</div>
					</Row>
				</Container>
			</div>
	);
}
