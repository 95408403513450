/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Container,
    Form, 
    FormGroup, 
    Label, 
    Input ,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import * as FileSaver from "file-saver";
import Select2 from "react-select2-wrapper";

const PageLaporanAbsensiKaryawan = () => {
  const token = localStorage.token;
  const username = localStorage.username
  const [alert, setAlert] = React.useState(null);
  const [allPenawaranSo, setAllPenawaranSo] = useState([]);
  const [names, setNames] = useState([]);
  const [name, setName] = useState("");
  const [page, setPage] = useState(1);
  const [cabang, setCabang] = useState("")
  const [cabangs, setCabangs] = useState([]);
  const [startdate, setStartDate] = useState("");
  const [month, setMonth] = useState("");
  const [enddate, setEndDate] = useState("");
  const [year, setYear] = useState("");
  const [tglctk, setTglCtk] = useState(new Date().toISOString().split("YYYY-")[0])
  // const [tgl1, setTgl1] = useState("");
  // const [tgl2, setTgl2] = useState("");
  // const [tgl3, setTgl3] = useState("");
  // const [tgl4, setTgl4] = useState("");
  // const [tgl5, setTgl5] = useState("");
  // const [tgl6, setTgl6] = useState("");
  // const [tgl7, setTgl7] = useState("");
  // const [tgl8, setTgl8] = useState("");
  // const [tgl9, setTgl9] = useState("");
  // const [tgl10, setTgl10] = useState("");
  // const [tgl11, setTgl11] = useState("");
  // const [tgl12, setTgl12] = useState("");
  // const [tgl13, setTgl13] = useState("");
  // const [tgl14, setTgl14] = useState("");
  // const [tgl15, setTgl15] = useState("");
  // const [tgl16, setTgl16] = useState("");
  // const [tgl17, setTgl17] = useState("");
  // const [tgl18, setTgl18] = useState("");
  // const [tgl19, setTgl19] = useState("");
  // const [tgl20, setTgl20] = useState("");
  // const [tgl21, setTgl21] = useState("");
  // const [tgl22, setTgl22] = useState("");
  // const [tgl23, setTgl23] = useState("");
  // const [tgl24, setTgl24] = useState("");
  // const [tgl25, setTgl25] = useState("");
  // const [tgl26, setTgl26] = useState("");
  // const [tgl27, setTgl27] = useState("");
  // const [tgl28, setTgl28] = useState("");
  // const [tgl29, setTgl29] = useState("");
  // const [tgl30, setTgl30] = useState("");
  // const [tgl31, setTgl31] = useState("");

  useEffect(() => {
    getCabang();
  }, []);

  const getCabang = () => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`, { headers
      })
      .then(data => {
        setCabangs(data.data.response);
        // setCabang(id);
      })
        .catch(function (error) {
          console.log(error)
        })
    }


  const updateDataTable = (page, startdate, enddate, cabang) => {
    getLaporanPembelian(page, startdate, enddate, cabang);
    setPage(page);
    setStartDate(startdate);
    setEndDate(enddate);
    setCabang(cabang);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable( startdate, enddate, cabang)
    }
  }

  
  useEffect(() => {
    // getUser();
    // getLaporanPembelian();
  }, []);

  // fungsi dari ambil data
  const getLaporanPembelian = async (page, start_date= "", end_date = "", warehouse_id="") => {
    
    let filter = { 
      page: page, 
      per_page: 100,
      username : username
    }
    if (start_date !== "") {
      filter = Object.assign(filter, { start_date: start_date })
    }
    if (end_date !== "") {
        filter = Object.assign(filter, { end_date: end_date });
    }
    if (warehouse_id !== "") {
      filter = Object.assign(filter, { warehouse_id: warehouse_id });
  }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/laporan/absensi-horizontal`, data, {
        headers,
      })
      .then((data) => {
        setAllPenawaranSo(data.data.response);
        setPage(data.data.current_page + 1);
        // setTgl1(res.data.response.tanggal.tgl1)
        // setTgl2(res.data.response.tanggal.tgl2)
        // setTgl3(res.data.response.tanggal.tgl3)
        // setTgl4(res.data.response.tanggal.tgl4)
        // setTgl5(res.data.response.tanggal.tgl5)
        // setTgl6(res.data.response.tanggal.tgl6)
        // setTgl7(res.data.response.tanggal.tgl7)
        // setTgl8(res.data.response.tanggal.tgl8)
        // setTgl9(res.data.response.tanggal.tgl9)
        // setTgl10(res.data.response.tanggal.tgl10)
        // setTgl11(res.data.response.tanggal.tgl11)
        // setTgl12(res.data.response.tanggal.tgl12)
        // setTgl13(res.data.response.tanggal.tgl13)
        // setTgl14(res.data.response.tanggal.tgl14)
        // setTgl15(res.data.response.tanggal.tgl15)
        // setTgl16(res.data.response.tanggal.tgl16)
        // setTgl17(res.data.response.tanggal.tgl17)
        // setTgl18(res.data.response.tanggal.tgl18)
        // setTgl19(res.data.response.tanggal.tgl19)
        // setTgl20(res.data.response.tanggal.tgl20)
        // setTgl21(res.data.response.tanggal.tgl21)
        // setTgl22(res.data.response.tanggal.tgl22)
        // setTgl23(res.data.response.tanggal.tgl23)
        // setTgl24(res.data.response.tanggal.tgl24)
        // setTgl25(res.data.response.tanggal.tgl25)
        // setTgl26(res.data.response.tanggal.tgl26)
        // setTgl27(res.data.response.tanggal.tgl27)
        // setTgl28(res.data.response.tanggal.tgl28)
        // setTgl29(res.data.response.tanggal.tgl29)
        // setTgl30(res.data.response.tanggal.tgl30)
        // setTgl31(res.data.response.tanggal.tgl31)
      })
      .catch(function (error) {
        if(error.response.data.status == 404){
          setGagalAlert(error.response.data.message);
      } 
      });
  };

  const getUser = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/karyawan/list/${id}`,
        { headers }
      )
      .then((data) => {
        setNames(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const downloadExcel = async ()=> {
    var fileName = 'Laporan-Absensi-Karyawan';
    // const fs = require('fs');
    // get data all by filter
    var filter = { 
      page: page, 
      per_page: 1000,
      warehouse_id : cabang,
      month : month,
      year : year,
      username_search : name,
      username:username,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/laporan/absensi-horizontal`, filter, {
        headers,
      })
      .then((res) => {
        const tgl1 = res.data.response.tanggal.tgl1
        const tgl2 = res.data.response.tanggal.tgl2
        const tgl3 = res.data.response.tanggal.tgl3
        const tgl4 = res.data.response.tanggal.tgl4
        const tgl5 = res.data.response.tanggal.tgl5
        const tgl6 = res.data.response.tanggal.tgl6
        const tgl7 = res.data.response.tanggal.tgl7
        const tgl8 = res.data.response.tanggal.tgl8
        const tgl9 = res.data.response.tanggal.tgl9
        const tgl10 = res.data.response.tanggal.tgl10
        const tgl11 = res.data.response.tanggal.tgl11
        const tgl12 = res.data.response.tanggal.tgl12
        const tgl13 = res.data.response.tanggal.tgl13
        const tgl14 = res.data.response.tanggal.tgl14
        const tgl15 = res.data.response.tanggal.tgl15
        const tgl16 = res.data.response.tanggal.tgl16
        const tgl17 = res.data.response.tanggal.tgl17
        const tgl18 = res.data.response.tanggal.tgl18
        const tgl19 = res.data.response.tanggal.tgl19
        const tgl20 = res.data.response.tanggal.tgl20
        const tgl21 = res.data.response.tanggal.tgl21
        const tgl22 = res.data.response.tanggal.tgl22
        const tgl23 = res.data.response.tanggal.tgl23
        const tgl24 = res.data.response.tanggal.tgl24
        const tgl25 = res.data.response.tanggal.tgl25
        const tgl26 = res.data.response.tanggal.tgl26
        const tgl27 = res.data.response.tanggal.tgl27
        const tgl28 = res.data.response.tanggal.tgl28
        const tgl29 = res.data.response.tanggal.tgl29
        const tgl30 = res.data.response.tanggal.tgl30
        const tgl31 = res.data.response.tanggal.tgl31
        const bulan = res.data.response.bulan
        const cabang = res.data.response.cabang
        var apiPo = res.data.response.data
        var tglcetak = res.data.response.download_date
        var tableToExcel = (function() {
          // var uri = 'data:application/vnd.ms-excel;base64,',
          var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
            // base64 = function(s) {
            //   return window.btoa(unescape(encodeURIComponent(s)))
            // },
            format = function(s, c) {
              return s.replace(/{(\w+)}/g, function(m, p) {
                return c[p];
              })
            }
          return function(table, name) {
            var heading = 'Laporan Absensi';
            var imgsrc1 = 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhAVKx5R3RdjeXQuRdKan2RNLsZn2U4qXYOgU4jqILz6u6MLSzlvzY1b5x9Xiz4sKHhM0UJ1NKKoFVx6ZEI8JqgANlrZ8KwCJ2j9pOmJN-e50-HzVhTFRvEahjCJB51O4oMmJ25V2yQtYOGfxV2b7C2aT9VKBruh0_znTbORz66pu9P47DMB5aP4SuF/s320/Hokky1.png';
            var po = '';
            var a ;
            for(a=0; a < apiPo.length; a++){
              po += `
              <tr style="border: 1px solid black">
                  <td><center>${apiPo[a].name}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang01}</center></td>
                  <td><center>${apiPo[a].pulang01}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang02}</center></td>
                  <td><center>${apiPo[a].pulang02}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang03}</center></td>
                  <td><center>${apiPo[a].pulang03}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang04}</center></td>
                  <td><center>${apiPo[a].pulang04}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang05}</center></td>
                  <td><center>${apiPo[a].pulang05}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang06}</center></td>
                  <td><center>${apiPo[a].pulang06}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang07}</center></td>
                  <td><center>${apiPo[a].pulang07}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang08}</center></td>
                  <td><center>${apiPo[a].pulang08}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang09}</center></td>
                  <td><center>${apiPo[a].pulang09}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang10}</center></td>
                  <td><center>${apiPo[a].pulang10}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang11}</center></td>
                  <td><center>${apiPo[a].pulang11}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang12}</center></td>
                  <td><center>${apiPo[a].pulang12}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang13}</center></td>
                  <td><center>${apiPo[a].pulang13}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang14}</center></td>
                  <td><center>${apiPo[a].pulang14}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang15}</center></td>
                  <td><center>${apiPo[a].pulang15}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang16}</center></td>
                  <td><center>${apiPo[a].pulang16}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang17}</center></td>
                  <td><center>${apiPo[a].pulang17}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang18}</center></td>
                  <td><center>${apiPo[a].pulang18}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang19}</center></td>
                  <td><center>${apiPo[a].pulang19}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang20}</center></td>
                  <td><center>${apiPo[a].pulang20}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang21}</center></td>
                  <td><center>${apiPo[a].pulang21}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang22}</center></td>
                  <td><center>${apiPo[a].pulang22}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang23}</center></td>
                  <td><center>${apiPo[a].pulang23}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang24}</center></td>
                  <td><center>${apiPo[a].pulang24}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang25}</center></td>
                  <td><center>${apiPo[a].pulang25}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang26}</center></td>
                  <td><center>${apiPo[a].pulang26}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang27}</center></td>
                  <td><center>${apiPo[a].pulang27}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang28}</center></td>
                  <td><center>${apiPo[a].pulang28}</center></td>
                  ${tgl29 === null ? `` : `<td style="background-color: #ac00e6"><center>${apiPo[a].datang29}</center></td>`}
                  ${tgl29 === null ? `` : `<td><center>${apiPo[a].pulang29}</center></td>`}
                  ${tgl30 === null ? `` : `<td style="background-color: #ac00e6"><center>${apiPo[a].datang30}</center></td>`}
                  ${tgl30 === null ? `` : `<td><center>${apiPo[a].pulang30}</center></td>`}
                  ${tgl31 === null ? `` : `<td style="background-color: #ac00e6"><center>${apiPo[a].datang31}</center></td>`}
                  ${tgl31 === null ? `` : `<td><center>${apiPo[a].pulang31}</center></td>`}
                  <td style="background-color: green"><center>${apiPo[a].total_kerja}</center></td>
                  <td style="background-color: red"><center>${apiPo[a].total_alpha}</center></td>
                  <td style="background-color: blue"><center>${apiPo[a].total_ijin}</center></td>
                  <td style="background-color: coral"><center>${apiPo[a].total_lembur}</center></td>
              </tr>
              `
            }
            
            var table = `
            <table className="table table-striped" id="account_table">
                <tbody>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td colspan="60"><center><b><h1>LAPORAN ABSENSI KARYAWAN</h1></b><center></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr>
                    <td>Bulan</td>
                    <td colspan="2">: ${bulan}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Nama</td>
                    <td colspan="2">: ${username}</td>
                </tr>
                <tr>
                    <td>Cabang</td>
                    <td colspan="2">: ${cabang}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>Tanggal Cetak</td>
                    <td colspan="2">: ${tglcetak}</td>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                  <tr style="border: 1px solid black">
                      <th rowspan="2">Nama</th>
                      <th colspan="2"> ${tgl1}</th>
                      <th colspan="2"> ${tgl2}</th>
                      <th colspan="2"> ${tgl3}</th>
                      <th colspan="2"> ${tgl4}</th>
                      <th colspan="2"> ${tgl5}</th>
                      <th colspan="2"> ${tgl6}</th>
                      <th colspan="2"> ${tgl7}</th>
                      <th colspan="2"> ${tgl8}</th>
                      <th colspan="2"> ${tgl9}</th>
                      <th colspan="2"> ${tgl10}</th>
                      <th colspan="2"> ${tgl11}</th>
                      <th colspan="2"> ${tgl12}</th>
                      <th colspan="2"> ${tgl13}</th>
                      <th colspan="2"> ${tgl14}</th>
                      <th colspan="2"> ${tgl15}</th>
                      <th colspan="2"> ${tgl16}</th>
                      <th colspan="2"> ${tgl17}</th>
                      <th colspan="2"> ${tgl18}</th>
                      <th colspan="2"> ${tgl19}</th>
                      <th colspan="2"> ${tgl20}</th>
                      <th colspan="2"> ${tgl21}</th>
                      <th colspan="2"> ${tgl22}</th>
                      <th colspan="2"> ${tgl23}</th>
                      <th colspan="2"> ${tgl24}</th>
                      <th colspan="2"> ${tgl25}</th>
                      <th colspan="2"> ${tgl26}</th>
                      <th colspan="2"> ${tgl27}</th>
                      <th colspan="2"> ${tgl28}</th>
                      ${tgl29 === null ? `` : ` <th colspan="2"> ${tgl29}</th>`}
                      ${tgl30 === null ? `` : ` <th colspan="2"> ${tgl30}</th>`}
                      ${tgl31 === null ? `` : ` <th colspan="2"> ${tgl31}</th>`}
                      <th rowspan="2" style="background-color: green"> Masuk</th>
                      <th rowspan="2" style="background-color: red"> Alpha</th>
                      <th rowspan="2" style="background-color: blue"> Izin</th>
                      <th rowspan="2" style="background-color: coral"> L/LA</th>
                    </tr>
                    <tr style="border: 1px solid black">
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                      ${tgl29 === null ? `` : `<th style="background-color: #ac00e6">Masuk</th>`}
                      ${tgl29 === null ? `` : `<th>Pulang</th>`}
                      ${tgl30 === null ? `` : `<th style="background-color: #ac00e6">Masuk</th>`}
                      ${tgl30 === null ? `` : `<th>Pulang</th>`}
                      ${tgl31 === null ? `` : `<th style="background-color: #ac00e6">Masuk</th>`}
                      ${tgl31 === null ? `` : `<th>Pulang</th>`}
                    </tr>
                        ${po}
                </tbody>
            </table>
            `;
            var ctx = {
              worksheet: name || 'WorkBook',
              imgsrc1: imgsrc1,
              heading: heading,
              table: table
            }
            var blob = new Blob([format(template, ctx)]);
            return blob;
          }
        })()
        var blobURL = tableToExcel('account_table', 'Data Absensi Karyawan');
        FileSaver.saveAs(blobURL, fileName+'.xls');
       
      })
      .catch(function (error) {
      });
  }

  const downloadExcelVertikal = async ()=> {
    var fileName = 'Laporan-Absensi-PerKaryawan';
    // const fs = require('fs');
    // get data all by filter
    var filter = { 
      page: page, 
      per_page: 1000,
      warehouse_id : cabang,
      month : month,
      year : year,
      username:name,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/laporan/absensi-vertikal-by-username`, filter, {
        headers,
      })
      .then((res) => {
        // const tgl1 = res.data.response.tanggal.tgl1
        // const tgl2 = res.data.response.tanggal.tgl2
        // const tgl3 = res.data.response.tanggal.tgl3
        // const tgl4 = res.data.response.tanggal.tgl4
        // const tgl5 = res.data.response.tanggal.tgl5
        // const tgl6 = res.data.response.tanggal.tgl6
        // const tgl7 = res.data.response.tanggal.tgl7
        // const tgl8 = res.data.response.tanggal.tgl8
        // const tgl9 = res.data.response.tanggal.tgl9
        // const tgl10 = res.data.response.tanggal.tgl10
        // const tgl11 = res.data.response.tanggal.tgl11
        // const tgl12 = res.data.response.tanggal.tgl12
        // const tgl13 = res.data.response.tanggal.tgl13
        // const tgl14 = res.data.response.tanggal.tgl14
        // const tgl15 = res.data.response.tanggal.tgl15
        // const tgl16 = res.data.response.tanggal.tgl16
        // const tgl17 = res.data.response.tanggal.tgl17
        // const tgl18 = res.data.response.tanggal.tgl18
        // const tgl19 = res.data.response.tanggal.tgl19
        // const tgl20 = res.data.response.tanggal.tgl20
        // const tgl21 = res.data.response.tanggal.tgl21
        // const tgl22 = res.data.response.tanggal.tgl22
        // const tgl23 = res.data.response.tanggal.tgl23
        // const tgl24 = res.data.response.tanggal.tgl24
        // const tgl25 = res.data.response.tanggal.tgl25
        // const tgl26 = res.data.response.tanggal.tgl26
        // const tgl27 = res.data.response.tanggal.tgl27
        // const tgl28 = res.data.response.tanggal.tgl28
        // const tgl29 = res.data.response.tanggal.tgl29
        // const tgl30 = res.data.response.tanggal.tgl30
        // const tgl31 = res.data.response.tanggal.tgl31
        // const bulan = res.data.response.bulan
        // const cabang = res.data.response.cabang
        var apiPo = res.data.response.kalender
        var tglcetak = res.data.response.tanggal_cetak
        var namacok = res.data.response.name
        // var usercetakcok = res.data.response.username_cetak
        var izin = res.data.response.ijin
        var masuk = res.data.response.masuk
        var alpha = res.data.response.alpha
        var cabangcok = res.data.response.cabang
        var tableToExcel = (function() {
          // var uri = 'data:application/vnd.ms-excel;base64,',
          var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
            // base64 = function(s) {
            //   return window.btoa(unescape(encodeURIComponent(s)))
            // },
            format = function(s, c) {
              return s.replace(/{(\w+)}/g, function(m, p) {
                return c[p];
              })
            }
          return function(table, name) {
            var heading = 'Laporan Absensi';
            var imgsrc1 = 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhAVKx5R3RdjeXQuRdKan2RNLsZn2U4qXYOgU4jqILz6u6MLSzlvzY1b5x9Xiz4sKHhM0UJ1NKKoFVx6ZEI8JqgANlrZ8KwCJ2j9pOmJN-e50-HzVhTFRvEahjCJB51O4oMmJ25V2yQtYOGfxV2b7C2aT9VKBruh0_znTbORz66pu9P47DMB5aP4SuF/s320/Hokky1.png';
            var po = '';
            var a ;
            for(a=0; a < apiPo.length; a++){
              po += `
              <tr style="border: 1px solid black">
                  <td><center>${apiPo[a].tanggal}</center></td>
                  <td style="background-color: #ac00e6"><center>${apiPo[a].datang}</center></td>
                  <td><center>${apiPo[a].pulang}</center></td>
              </tr>
              `
            }
            
            var table = `
            <table className="table table-striped" id="account_table">
                <tbody>
                <tr>
                    <td colspan="3"><center><b><h1>LAPORAN ABSENSI KARYAWAN</h1></b><center></td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr style="border: 1px solid black">
                      <th>Cabang</th>
                      <th colspan="2">${cabangcok}</th>
                    </tr>
                    <tr style="border: 1px solid black">
                      <th>Nama</th>
                      <th colspan="2">${namacok}</th>
                    </tr>
                    <tr style="border: 1px solid black">
                      <th>Tanggal</th>
                      <th style="background-color: #ac00e6">Masuk</th>
                      <th>Pulang</th>
                    </tr>
                        ${po}
                    <tr style="border: 1px solid black">
                      <th style="background-color: #E9AC03">Masuk</th>
                      <th colspan="2" >${masuk} Hari</th>
                    </tr>
                    <tr style="border: 1px solid black">
                      <th style="background-color: #E9AC03">Alpha</th>
                      <th colspan="2">${alpha} Hari</th>
                    </tr>
                    <tr style="border: 1px solid black">
                      <th style="background-color: #E9AC03">Izin</th>
                      <th colspan="2">${izin} Hari</th>
                    </tr>
                    <tr style="border: 1px solid black">
                        <th style="background-color: #E9AC03">Download</th>
                        <th colspan="2">${tglcetak}</th>
                    </tr>
                </tbody>
            </table>
            `;
            var ctx = {
              worksheet: name || 'WorkBook',
              imgsrc1: imgsrc1,
              heading: heading,
              table: table
            }
            var blob = new Blob([format(template, ctx)]);
            return blob;
          }
        })()
        var blobURL = tableToExcel('account_table', 'Data Absensi Per Karyawan');
        FileSaver.saveAs(blobURL, fileName+'.xls');
       
      })
      .catch(function (error) {
      });
  }

  const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <div>
      {alert}
        <SimpleHeader name="Laporan Absensi Karyawan" parentName="Report" />
        <Container className="mt--6" fluid>
            <Row>
            <div className="col">
            <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                      <Link className="btn btn-link" to="/admin/laporan/hrd">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                      </Link>
                    </div>
                    </div>
                </CardHeader>
                <CardBody>
                        <Form>
                        <Row md="12">
                        <Col md="3">
                                        <FormGroup>
                                        <Label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect3">Cabang</Label>
                                        <Input
                                            autoComplete="off"
                                            type="select"
                                            placeholder=""
                                            value={cabang}
                                            // onChange={e => updateDataTable(1, perPage, tanggal, e.target.value, name)}
                                            onChange={(e) => {
                                              setCabang(e.target.value);
                                              getUser(e.target.value)
                                            }}
                                        >
                                            <option value={""}>Masukan Cabang</option>
                                            {cabangs.map((warehouse2, key) => {
                                                return (
                                                    <option key={key} value={warehouse2.id}>
                                                    {warehouse2.name}
                                                    </option>
                                                );
                                                })}
                                        </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                      <FormGroup>
                                        <Label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect3"
                                        >
                                          Nama
                                        </Label>
                                          <Select2
                                                className="form-control-alternative"
                                                defaultValue="0"
                                                value={name}
                                                onChange={(e) => {
                                                  setName(e.target.value);
                                                }}
                                                options={{
                                                  placeholder: "Pilih Nama"
                                                }}
                                                data={names.map((name) => {
                                                  return (
                                                  { id: name.username, text: name.name}
                                                  );
                                                })}
                                              />
                                      </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup>
                                        <Label 
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect3">Bulan</Label>
                                        <Input
                                            autoComplete="off"
                                            className="form-control-alternative"
                                            type="select"
                                            name="itemCode"
                                            value={month}
                                            // onChange={e => updateDataTable(1, perPage, currentSort,e.target.value, enddate)}
                                            onChange={(e) => {
                                              setMonth(e.target.value);
                                            }}
                                        >
                                          <option value="">Pilih Bulan</option>
                                          <option value="1">Januari</option>
                                          <option value="2">Februari</option>
                                          <option value="3">Maret</option>
                                          <option value="4">April</option>
                                          <option value="5">Mei</option>
                                          <option value="6">Juni</option>
                                          <option value="7">Juli</option>
                                          <option value="8">Agustus</option>
                                          <option value="9">September</option>
                                          <option value="10">Oktober</option>
                                          <option value="11">November</option>
                                          <option value="12">Desember</option>


                                          </Input>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup>
                                        <Label 
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect3">Tahun</Label>
                                        <Input
                                            autoComplete="off"
                                            className="form-control-alternative"
                                            type="select"
                                            name="itemCode"
                                            value={year}
                                            // onChange={e => updateDataTable(1, perPage, currentSort,e.target.value, enddate)}
                                            onChange={(e) => {
                                              setYear(e.target.value);
                                            }}
                                        >
                                          <option value="">Pilih Tahun</option>
                                          <option value="2024">2024</option>
                                          <option value="2023">2023</option>
                                          </Input>
                                        </FormGroup>
                                    </Col>
                                   
                                    <Col md="" sm="3">
                                    <Label>&nbsp;</Label>
                                    <br></br>
                                    {/* <Button type='button' onClick={e => updateDataTable(1, startdate, enddate, cabang)} className="btn btn-info"><i className="fa fa-filter"></i></Button> */}
                                     <UncontrolledDropdown nav>
                                        <DropdownToggle color="primary">
                                            Export
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem onClick={()=> {downloadExcel()}}>Excel</DropdownItem>
                                            <DropdownItem onClick={()=> {downloadExcelVertikal()}}>Excel Perkaryawan</DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    </Col>
                                </Row>
                        </Form>
                </CardBody>
                </Card>
            </div>
            </Row>
        </Container>
    </div>
  );
}

export default PageLaporanAbsensiKaryawan;