/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Label,
  ButtonGroup,
  Input,
	Modal, ModalHeader, ModalBody

} from "reactstrap";
import axios from "axios";
import ToolkitProvider from "react-bootstrap-table2-toolkit"; 
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";

const ItemKatalog = () => {
  const token = localStorage.token;
  const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle1 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
  const [allItem, setAllItem] = useState([]);
  const [nameItem, setNameItem] = useState("");
  const [codeItem, setCodeItem] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
   
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, nameItem, codeItem);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort);
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  };

  const updateDataTable = (page, perPage, sort, nameItem, codeItem) => {
    getItems(page, perPage, sort, nameItem, codeItem);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setNameItem(nameItem);
    setCodeItem(codeItem);
  };

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`;
      updateDataTable(page, perPage, sort, nameItem, codeItem);
    }
  };

  useEffect(() => {
    getItems(page, perPage, currentSort, "", "");
  }, []);

  const getItems = async (page, perPage, currentSort, item_name = null, item_code = null) => 
  {
    let filter = {
      page: page,
      per_page: perPage,
    };
    if (item_name !== null) {
      filter = Object.assign(filter, { item_name: item_name });
    }
    if (item_code !== null) {
      filter = Object.assign(filter, { item_code: item_code });
    }
    const data = filter;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/items-katalog `,
      data,
      { headers }
    );
    setAllItem(res.data.response);
    setPage(res.data.current_page + 1);
    setPerpage(res.data.per_page);
    setTotalItem(res.data.total_item);
  };

  const reset = () => {
    setNameItem("");
    setCodeItem("");
    updateDataTable(1, perPage, currentSort, "", "");
  };

  const getDataLog = (id) => {
		let filter = { 
			transaction : id,
			features : "MASTER10",
			warehouse_id : 0
		}
		const data = filter;
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
			setDataLog(data.data.response);
			setModal1(!modal1);
	
		})
		  .catch(function (error) {
			setGagalAlert(error.response.data.message);
		  })
	  }
	
	  const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
		);
		};
		
		const hideAlert = () => {
		  setAlert(null);
		};


  return (
    <div>
      {alert}
      <Row>
        <div className="col">
          <Card>
            <CardHeader>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3>List Item</h3>
              </div>
            </CardHeader>
            <CardBody>
                <Form>
                  <Row md="12">
                    <Col md="3">
                      <FormGroup>
                        <Label 
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3">
                          Nama Item
                        </Label>
                        <Input
                          type="text"
                          placeholder="Masukan Nama Item"
                          value={nameItem}
                          onChange={(e) =>
                            updateDataTable(
                              1,
                              perPage,
                              currentSort,
                              e.target.value,
                              codeItem
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Label 
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3">
                          Kode Item
                        </Label>
                        <Input
                          type="text"
                          placeholder="Masukan Kode Item"
                          value={codeItem}
                          onChange={(e) =>
                            updateDataTable(
                              1,
                              perPage,
                              currentSort,
                              nameItem,
                              e.target.value
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button type="button" onClick={reset} color="secondary">
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </Form>
              <ToolkitProvider
                rowNumber={rowIndex}
                data={allItem}
                keyField="id"
                search={{
                  defaultSearch: "search something here",
                }}
                columns={[
                  {
                    dataField: "no",
                    text: "#",
                    sort: true,
                    page: 1,
                    formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                    },
                  },
                  {
                    dataField: "item_code",
                    text: "Kode Item",
                    sort: true,
                  },
                  {
                    dataField: "barcode",
                    text: "Barcode",
                    sort: true,
                  },
                  {
                    dataField: "item_name",
                    text: "Nama Item",
                    sort: true,
                  },
                  {
                    dataField: "kategori_name",
                    text: "Kategori",
                    sort: true,
                  },
                  {
                    dataField: "function_name",
                    text: "Fungsi",
                    sort: true,
                  },
                  {
                    dataField: "merek_name",
                    text: "Merek",
                    sort: true,
                  },
                  {
                     
                    dataField: "",
                    text: "",
                    formatter: (cell, row, index) => {
                      return (
                       
                        <ButtonGroup>
                           
                          <Button color="warning" onClick={() => (getDataLog(row.id))}>
                              <i className="fa fa-info-circle" aria-hidden="true"></i>
                          </Button>
                        </ButtonGroup>
                        
                        
                      );
                    },
                  },
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                      pagination={paginationFactory({ ...paginationOption })}
                      onTableChange={handleTableChange}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </div>
      </Row>
      <Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>
            Kembali
          </Button>
        </ModalBody>
      </Modal> 
      </div>
  );
};

export default ItemKatalog;
