/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Container,
  CardImg,
} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function PagePembelian() {
  return (
    <>
      <div>
      <SimpleHeader name="Laporan Pembelian" parentName="Report" />
      <Container className="mt--6" fluid>
                        {/* <div style={{ textAlign: 'left' }}>
                            <Link className="btn btn-link" to="/admin/bkk-finance">
                                <i className="fa fa-arrow-circle-left fa-2x" /> 
                            </Link>
                        </div> */}
        <Row>
          <div className="col">
                <CardBody>
                    <Row>
                        
                        <div className="card-columns">
                        {/* {invoicePO && invoicePO === "1" && ( */}
                            <Card>
                                <Link to="/admin/laporan/pembelian/laporan-rfq">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/rfq.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        {/* )} */}
                        <Card>
                                <Link to="/admin/laporan/pembelian/laporan-pembelian">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/pembelian.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/laporan/pembelian/laporan-receiving">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/bpb-pembelian-barang.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/laporan/pembelian/laporan-faktur-pembelian">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/faktur-pembelian.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/laporan/pembelian/laporan-retur-pembelian">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/retur-pembelian.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        {/* {validasiAdminFinance && validasiAdminFinance === "1" && (
                            <Card>
                                <Link to="/admin/po-retur/validasi-kepala-purchasing">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-kepala-purchasing.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {validasiDirektur && validasiDirektur === "1" && (
                            <Card>
                                <Link to="/admin/po-retur/validasi-manajer-office">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-manajer-office.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {cetak && cetak === "1" && (
                            <Card>
                                <Link to="/admin/po-retur/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                         {cetak && cetak === "1" && (
                            <Card>
                                <Link to="/admin/po-retur/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                         {cetak && cetak === "1" && (
                            <Card>
                                <Link to="/admin/po-retur/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                         {cetak && cetak === "1" && (
                            <Card>
                                <Link to="/admin/po-retur/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                         {cetak && cetak === "1" && (
                            <Card>
                                <Link to="/admin/po-retur/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                         {cetak && cetak === "1" && (
                            <Card>
                                <Link to="/admin/po-retur/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )} */}
                        </div>
					</Row>
                </CardBody>
          </div>
        </Row>
      </Container>
      </div>
    </>
  );
}