import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  CardBody,
  // Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";
// import * as FileSaver from "file-saver";
// import SweetAlert from "react-bootstrap-sweetalert";
const ModalCetakRiwayatIzinHrdBefore = ({ open, toggle, data }) => {
    const token = localStorage.token;
    // const username = localStorage.username;
    // const [savedItems, setSavedItems] = useState([]);
    // const [download, setDownload] = useState("")
    // const [alert, setAlert] = React.useState(null);
    const [tanggal,setTanggal] = useState("")
    const [tanggalizin,setTanggalIzin] = useState("")
    const [creator,setCreator] = useState("")
    const [keterangan,setKeterangan] = useState("")
    const [cabang,setCabang] = useState("")
    const [person,setPerson] = useState("")
    // const [validator1,setValidator1] = useState("")
    // const [validator2,setValidator2] = useState("")
    // const [logo,setLogo] = useState("")
    // const [id,setId] = useState("")
    // const [metodepayment,setMetodePayment] = useState("")


  const getById = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/absensi-form/cetak/${data.id}`, {
        headers,
      })
      .then((data) => {
        setTanggal(data.data.response.created);
        setTanggalIzin(data.data.response.tanggal_ijin);
        setCreator(data.data.response.creator);
        setKeterangan(data.data.response.keterangan);
        setCabang(data.data.response.warehouse);
        setPerson(data.data.response.nama);
        // setValidator1(data.data.response.validator1);
        // setValidator2(data.data.response.validator2);
        
      })
      .catch(function (error) {
        console.log(error);
      });
  },[token,data.id]);

  useEffect(() => {
    getById();
    // getItemDataSaved();
  }, [getById]);

//   const getItemDataSaved = () => {
//     const headers = {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`,
//       };
//     axios.get(`${process.env.REACT_APP_API_BASE_URL}/absensi-form/cetak/${data.id}`,{ headers }
    
//     ).then(async response => {
//         let stateItem = [];
//         await Promise.all(response.data.response.list.map(async (data) => {
//             stateItem = [...stateItem, {
//                 tgl_tempo : data.tgl_tempo,
//                 code_invoice : data.code_invoice,
//                 payment_total : data.payment_total,
//                 keterangan2 : data.keterangan2,
// 				potongan: data.potongan,
//                 bayar : data.bayar,
//                 data: {
//                     tgl_tempo : data.tgl_tempo,
//                     code_invoice : data.code_invoice,
//                     payment_total : data.payment_total,
//                     keterangan2 : data.keterangan2,
//                     potongan: data.potongan,
//                     bayar : data.bayar,
//                 },
//             }];
//         }));

//         setSavedItems(stateItem);
//     })
// }

// const downloadExcel = async ()=> {
//     const J = "Bukti-Kas-Masuk"
//     const fileName = J;
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     };
//     axios
//       .get(`${process.env.REACT_APP_API_BASE_URL}/absensi-form/cetak/${data.id}`,{headers})
//       .then((data) => {
//         setTanggal(data.data.response.transaction_date);
//         setCreator(data.data.response.creator);
//         setKeterangan1(data.data.response.keterangan1);
//         setCreated(data.data.response.created);
//         setCabang(data.data.response.warehouse);
//         setPaymentTotal(data.data.response.payment_total);
//         setCode(data.data.response.bkm_code);
//         setPerson(data.data.response.customer);
//         setValidator1(data.data.response.validator1);
//         setValidator2(data.data.response.validator2);
//         setLogo(data.data.response.logo);
//         setId(data.data.response.id);
//         setMetodePayment(data.data.response.payment_method);
//         var apiPo = data.data.response.list;
//         var tglcetak = data.data.response.download_date;
//         var tableToExcel = (function() {
//           var uri = 'data:application/vnd.ms-excel;base64,',
//             template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
//             base64 = function(s) {
//               return window.btoa(unescape(encodeURIComponent(s)))
//             },
//             format = function(s, c) {
//               return s.replace(/{(\w+)}/g, function(m, p) {
//                 return c[p];
//               })
//             }
//           return function(table, name) {
//             var heading = 'Bukti Kas Masuk' ;
//             var imgsrc1 = 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhAVKx5R3RdjeXQuRdKan2RNLsZn2U4qXYOgU4jqILz6u6MLSzlvzY1b5x9Xiz4sKHhM0UJ1NKKoFVx6ZEI8JqgANlrZ8KwCJ2j9pOmJN-e50-HzVhTFRvEahjCJB51O4oMmJ25V2yQtYOGfxV2b7C2aT9VKBruh0_znTbORz66pu9P47DMB5aP4SuF/s320/Hokky1.png';
//             var po = '';
//             var a ;
//             for(a=0; a < apiPo.length; a++){
//               po += `
//               <tr style="border: 1px solid black">
//                   <td>${apiPo[a].code_invoice}</td>
//                   <td>${apiPo[a].tgl_tempo}</td>
//                   <td>${formatRupiah(apiPo[a].payment_total)}</td>
//                   <td>${apiPo[a].keterangan2}</td>
//                   <td>${formatRupiah(apiPo[a].potongan)}</td>
//                   <td>${formatRupiah(apiPo[a].bayar)}</td>
//               </tr>
//               `
//             }
//             var table = `
//             <table id="account_table">
//                 <tbody>
//                 <tr>
//                     <td><img src="https://i.ibb.co/jkFYDqg/150x75.png"/> </td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>${cabang}</td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Jl Menganti Karangan No.562</td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Wiyung surabaya barat - Jawa Timur </td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Telp: 081 217 85 3300 </td>
//                 </tr>
//                 <tr>
//                     <td colspan="6"><center><b><h1>Bukti kas Masuk</h1></b><center></td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                 </tr>
//                 <tr>
//                     <td>Tanggal</td>
//                     <td>: ${tanggal}</td>
//                     <td></td>
//                     <td></td>
//                     <td>Customer</td>
//                     <td>: ${person}</td>
//                 </tr>
//                 <tr>
//                     <td>Kode</td>
//                     <td>: ${code}</td>
//                     <td></td>
//                     <td></td>
//                     <td>kas</td>
//                     <td>: ${metodepayment}</td>
//                 <tr>
//                 <tr>
//                     <td>Ket</td>
//                     <td rowspan="2">: ${keterangan1}</td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                 <tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                 </tr>
//                   <tr style="border: 1px solid black">
//                       <th>Invoice</th>
//                       <th>Tempo</th>
//                       <th>Tagihan</th>
//                       <th>Keterangan</th>
//                       <th>Potongan</th>
//                       <th>Bayar</th>
//                     </tr>
//                         ${po}
//                 </tbody>
//             </table>
//             `;
//             var ctx = {
//                 worksheet: name || 'WorkBook',
//                 imgsrc1: imgsrc1,
//                 heading: heading,
//                 table: table
//             }
//             var blob = new Blob([format(template, ctx)]);
//             return blob;
//             }
//         })()
//         var blobURL = tableToExcel('account_table', 'Bukti Kas Masuk');
//         FileSaver.saveAs(blobURL, fileName+'.xls');
        
//         })
//       .catch(function (error) {
//             setGagalAlert();
//       });
//   }
  
  // const setGagalAlert = (id) => {
  //   setAlert(
  //     <SweetAlert
  //       danger
  //       showConfirm
  //       confirmBtnText="Ok"
  //       title="Gagal Cok"
  //       onCancel={hideAlert}
  //       onConfirm={hideAlert}
  //     />
  //   );
  // };
  
  // const hideAlert = () => {
  //   setAlert(null);
  // };

  // const formatRupiah = (money) => {
  //   return new Intl.NumberFormat('id-ID',
  //       { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  //   ).format(money);
  // }
    

  function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
  
  return (
    <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top:"-15%" }}>
      <ModalHeader toggle={toggle} align="center">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <Button color="info" onClick={() => print()}>Print</Button>
                        {/* <Button color="success" onClick={()=> (downloadExcel())}>Excel</Button> */}
                    </div>
                </div>
      </ModalHeader>
      <ModalBody>
        <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          <div className="col">
            <KopSurat 
            warehouseName={cabang}
            // user={username}
             />
          </div>
          <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                        <Row md="12">
                            <Col md="12">
                                <h3><b><center>FORM IZIN</center></b></h3>
                            </Col>
                        </Row>
                        <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                        <br></br>
                        <Row md="12">
                            <Col md="10">
                            <div className="row align-items-center mb-1">
                                <div className="col-2">Tanggal Pengajuan</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-3 ">
                                    <b>{tanggal}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-2">Tanggal Izin</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{tanggalizin}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-2">Nama</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{person}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-2">Keterangan</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{keterangan}</b>
                                </div>
                            </div>
                            </Col>
                        </Row>
                    <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                        <center>Terms of Price, delivery & shipping required</center>
                        <br></br>
                                <Row md="20">
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Di Buat</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{creator}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">HRD</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>Bu Nurul</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Mengetahui</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>Bu. JESSIE</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                                <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                                <div className="row align-items-center mb-3">
                                    <div className="col-4"><i>NB : PENGAJUAN MIN 7 HARI DAN MAKSIMAL 2 HARI SEBELUMNYA</i></div>
                                    </div>
        </CardBody>
      </ModalBody>
    </Modal>
  );
};

export default ModalCetakRiwayatIzinHrdBefore;
