/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, CardBody, CardHeader, CardFooter, Table, Container, FormGroup, Label, Input} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";
import CurrencyInput from 'react-currency-input-field';


export default function EditUangMukaPembelian(props)  {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [tipe, setTipe] = useState("");
  const [nominal, setNominal] = useState("")
  const [keterangan,setKeterangan] = useState("")
  const allInfo = JSON.parse(localStorage.allinfo);
  const invoicePOs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Invoice PO").map((p) => p.read_access));
   

  const handleSubmit2 = () => {
    hideAlert();
    setTimeout(() => {CreateData()}, 1000);
  };

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/uang-muka/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setTipe(data.data.response.type);
        setNominal(data.data.response.nominal);
        setKeterangan(data.data.response.keterangan);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  function CreateData() {
    let data = {
      warehouse_id : parseInt(warehouse),
      username : username,
      nominal: nominal,
      type : tipe,
      keterangan : keterangan
      
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/uang-muka/update/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/uang-muka/pembelian/page")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={handleSubmit2}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };



    const HargaNominalUangMuka = (e) => {
      const value = e === undefined ? 'undefined' : e;
      setNominal(value || ' ');
      };
  

  return (
    <>
    {alert}
    {invoicePOs && invoicePOs === "1" ? (
			<div>
    <SimpleHeader name="Edit Uang Muka Pembelian" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
              <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                      <h3></h3>
                    </CardHeader>
                <CardBody>
                  <Row md="12">
                      <Col md="6">
                      <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                          >
                            Nominal
                          </Label>
                          <Col sm={6}>
                          <CurrencyInput
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={nominal}
                                    onValueChange={HargaNominalUangMuka}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                  />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                          >
                            Keterangan
                          </Label>
                          <Col sm={6}>
                            <Input
                            className="form-control-alternative"
                              type="textarea"
                              name="desc"
                              rows = "4"
                              placeholder="Masukan Keterangan"
                              value={keterangan}
                              onChange={(e) => {
                                setKeterangan(e.target.value);
                              }}
                            />
                          </Col>                             
                        </FormGroup>
                      </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/uang-muka/pembelian/page">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={()=>setQuestionAlert()}>
                          Simpan
                      </Button>
                </CardFooter>
              </Card>
          </div>
        </Row>
    </Container>  
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}


