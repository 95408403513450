/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

const CreateOpname = () => {
  const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse
    const [alert, setAlert] = React.useState(null);
  let history = useHistory();
  const [area,setArea] = useState("")
  const [keterangan,setKeterangan] = useState("");
  const [savedItems1,setSavedItems1] = useState([]);
  const [queryyy, setQueryyy] = useState("");
  const [isSearchShowww, setIsSearchShowww] = useState(false);
  const [allItemmm, setAllItemmm] = useState([]);
  const headers = { Authorization: `Bearer ${token}` };
  const allInfo = JSON.parse(localStorage.allinfo);
  const StokOpnames = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Stok Opname").map((p) => p.read_access));
 


  const getAllCabangTerbaru = () => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/rak/list/${warehouse}`, {headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.map(async (data) => {
          stateItem = [...stateItem, {
            editing:false,
            id : data.id,
            code_rak: data.rak_code,
            data: {
              id : data.id,
              code_rak: data.rak_code,
            },
        }];
    }));
    setSavedItems1(stateItem);
})
}

useEffect(() => {
  const getData = setTimeout(async() => {
      if(queryyy != ''){
          axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/rak`, { code_rak: queryyy , per_page: 10,warehouse_id: parseInt(warehouse)},{ headers })
          .then((res) => {
              setAllItemmm(res.data);
              setIsSearchShowww(true);
          })
          .catch(function (error) {
              setAllItemmm(null);
          });
      }else{
    setAllItemmm(null);
      }
    }, 500)
    return () => clearTimeout(getData)
}, [queryyy]);


const saveItemOrUpdate1 = (item) => {
  let oldobj = savedItems1.find(o => o.id === item.id);
  if(oldobj===undefined){
    setSavedItems1([...savedItems1,{
      id : item.id,
      code_rak: item.rak_code,
    }]);
    
  }else{
    let index = savedItems1.findIndex(e=>e.id===item.id);
    let updateList = savedItems1;
    let qtyupdate = parseInt(updateList[index].qty)+parseInt(1);
    updateList[index] = {...updateList[index], qty: qtyupdate};
    setSavedItems1(updateList);
  };
}

const handleSubmit2 = () => {
  hideAlert();
  setTimeout(() => {CreateData()}, 1000);
};

  function CreateData() {
    let dataItems = [];
        savedItems1.map((dataItem) => dataItems = [...dataItems, 
            { 
                code_rak: dataItem.code_rak
            }]);
    let data = {
      username : username,
      status_d : 3,
      keterangan: keterangan ,
      direktur: "",
      warehouse_id : warehouse,
      area : area
      // rak : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/opname/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message)
          history.push("/admin/stock-opname/page");
        })
        .catch(function (error) {
            setGagalAlert(error.response.data.message);
          });
    };
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }
  
  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };
  
  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={handleSubmit2}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };

  const hideAlert = () => {
    setAlert(null);
  };
 
  
  const getAllCabang = async (e) => {
    e.preventDefault();
    {
      getAllCabangTerbaru();
      return true
    }
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    {
      setQueryyy("");
      setIsSearchShowww("");
      return true
    }
  };



  const deleteItem1 = (id) => {
    let array = [...savedItems1];

    let index = array.findIndex(object => {
        return object.code_rak === id;
    });

    if (index !== -1) {
        array.splice(index, 1);
        setSavedItems1(array);
    }
}

  return (
    <>
    {alert}
    {StokOpnames && StokOpnames === "1" ? (
      <div>
    <SimpleHeader name="Stock Opname" parentName="Inventori" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <CardBody>
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Stock Opname</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="9">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={2}
                              >
                                Area
                              </Label>
                              <Col sm={10}>
                                <Input
                                autoComplete="off"
                                  type="text"
                                  name="keterangan"
                                  placeholder="Masukan Area"
                                 
                                  value={area}
                                  onChange={(e) => {
                                    setArea(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={2}
                              >
                                Keterangan
                              </Label>
                              <Col sm={10}>
                                <Input
                                autoComplete="off"
                                  type="textarea"
                                  name="keterangan"
                                  placeholder="Masukan Keterangan"
                                  style={{
                                    fontSize: 14,
                                    paddingTop: 20,
                                    top: "50%",
                                    height: 117,
                                    resize: "none",
                                  }}
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                        <Link className="btn btn-info" to="/admin/stock-opname/page">
                            Kembali
                        </Link>
                        <Button color="danger" onClick={setQuestionAlert}>
                            Simpan
                        </Button>
                    </CardFooter>
                  </Card>
                  {/* <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <h3>Rak</h3>
                            <Form onSubmit={getAllCabang}>
                                <div style={{ textAlign: "right" }}>
                                    <Button color="primary" className="mb-2" type="submit">Pilih Semua</Button> 
                                </div>
                            </Form>
                        </div>
                    </CardHeader>
                    <CardBody>
                        <Table size="sm" responsive>
                        <thead>
                            <tr>
                                <th><b>Kode Rak</b></th>
                                <th></th>
                            </tr>
                        </thead>
                            <tbody>
                            {
                            savedItems1.map((savedItem1, key) => {
                                return (
                                <tr key={key}>
                                    <td>{savedItem1.code_rak}</td>
                                    <td>
                                    <Button color="danger" onClick={() => deleteItem1(savedItem1.code_rak)}><i className="fas fa-trash" /></Button>
                                    </td>
                                </tr>
                                )
                            })
                            }
                            <tr>
                            <td>
                                <><Form onSubmit={handleSubmit1}>
                                        <Input
                                        autoComplete="off"
                                        placeholder="Masukan Kode Rak"
                                        type="search"
                                        value={queryyy}
                                        onChange={(e) => setQueryyy(e.target.value)}
                                        />
                                    
                                    {isSearchShowww && queryyy && (
                                    <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                        <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                        <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShowww(false)}></i>
                                        </div>
                                        {allItemmm?.response ? (
                                        allItemmm.response.map((item) => (
                                            <CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {saveItemOrUpdate1(item);setQueryyy('');setIsSearchShowww(false);}}>
                                            <div>
                                                <b>Kode Rak:</b> {item.rak_code}
                                            </div>
                                            </CardBody>
                                        ))
                                        ) : (
                                        <div className="text-center mb-3 text-danger">Cabang "{queryyy}" tidak ada bosku!</div>
                                        )}
                                    </Card>
                                    )}
                                </Form></>
                                </td>
                                </tr>
                            </tbody>
                        </Table>
                    </CardBody>
                    <CardFooter>
                        <Link className="btn btn-info" to="/admin/stock-opname/page">
                            Kembali
                        </Link>
                        <Button color="danger" onClick={setQuestionAlert}>
                            Simpan
                        </Button>
                    </CardFooter>
                  </Card> */}
              </CardBody>
          </div>
        </Row>
    </Container>  
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}

export default CreateOpname;