/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
	Col,
	Button,
	Container,
	Modal, ModalHeader, ModalBody
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";

export default function DurasiOperasional() {
	const token = localStorage.token;
	const warehouseId = localStorage.warehouse;
	const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle1 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
    const [durasipo, setDurasiPo] = useState("")
    const [itemId, setItemId] = useState(1);
    const [itemTotal, setItemTotal] = useState(null);
    const [allItem, setAllItem] = useState([]);
    const [query, setQuery] = useState(null);
    const [isSearchShow, setIsSearchShow] = useState(false);
    const headers = { Authorization: `Bearer ${token}` };
	const redirectPrefix = `/admin/durasi-operasional/edit/`;
    const allInfo = JSON.parse(localStorage.allinfo);
    const batasoperasional = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Durasi Operasional").map((p) => p.read_access));
    const createButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Durasi Operasional").map((p) => p.create_access));
    const UpdateButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Durasi Operasional").map((p) => p.update_access));

	useEffect(() => {
        getById();
		getUser();
	}, [itemId]);

	  const getById = () => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/durasi-operasional/${itemId}`,
	        { headers }
	      )
	      .then((data) => {
            setDurasiPo(data.data.response.durasi);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

	const getUser = async () => {
		let filter = {
			page: 1,
			per_page: 10,
			warehouse_id: parseInt(warehouseId),
		};
		const data = filter;
		const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/durasi-operasional`, data, { headers });
		setItemTotal(res.data.total_item);
	};

	const search = async () => {
		if (Number(query) > 0) {
			const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/durasi-operasional`, { durasi_po: query , warehouse_id: parseInt(warehouseId)  }, { headers });
			if (res.data.status !== 404) setAllItem(res.data);
			else {
				const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/durasi-operasional`, { durasi_so: query , warehouse_id: parseInt(warehouseId) }, { headers });
				if (res.data.status !== 404) setAllItem(res.data);
				else setAllItem(null);
			}
		} else {
			const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/durasi-operasional`, { durasi_tw: query , warehouse_id: parseInt(warehouseId) }, { headers });
			if (res.data.status !== 404) setAllItem(res.data);
			else setAllItem(null);
		}
		setIsSearchShow(true);
	};

	const searchShow = (item) => {
		setItemId(item.id);
		setIsSearchShow(false);
	};

	const getDataLog = (id) => {
		let filter = { 
			transaction : id,
			features : "MASTER3",
			warehouse_id : 0
		}
		const data = filter;
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
			setDataLog(data.data.response);
            setModal1(!modal1);

		})
		  .catch(function (error) {
			setGagalAlert(error.response.data.message);
		  })
	  }

	  const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
		);
		};
		
		const hideAlert = () => {
		  setAlert(null);
		};

	return (
		<>
		{alert}
		{batasoperasional && batasoperasional === "1" ? (
			<div>
				<SimpleHeader name="Batas Operasional" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
								<Card className="position-sticky boxShadow" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
											<Col md="5 d-lg-block d-none">
												<Button onClick={() => setItemId(1)} disabled={itemId === 1} color="danger" type="button">
													First
												</Button>
												<Button onClick={() => setItemId((prev) => prev - 1)} disabled={itemId === 1} color="success" type="button">
                                                <i className="fa fa-angle-left" /> Prev
												</Button>
												<Button onClick={() => setItemId((prev) => prev + 1)} disabled={itemId === itemTotal} color="success" type="button">
													Next <i className="fa fa-angle-right" />
												</Button>
												<Button onClick={() => setItemId(itemTotal)} disabled={itemTotal === null} color="warning" type="button">
													End
												</Button>
											</Col>
											<Col md="3">
												<FormGroup row>
													<Col sm={10}>
														<Input
															placeholder="Search Batas Operasional"
															type="search"
															onChange={(e) => setQuery(e.target.value)}
															onKeyDown={search}
														/>
													</Col>
													<Col sm={2}>
													</Col>
												</FormGroup>
											</Col>
											<Col md="4 d-lg-block d-none">
												<div style={{ textAlign: "right" }}>
                                                    {UpdateButton && UpdateButton === "1" && (
													<Link className="btn btn-info"
														to={redirectPrefix + itemId}
													>
														<i className="fas fa-user-edit" /> Edit
													</Link>
                                                    )}
                                                    {createButton && createButton === "1" && (
													<Link className="btn btn-danger" to="/admin/durasi-operasional/create">
                                                        <i className="fas fa-plus" /> Tambah
													</Link>
                                                    )}
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>

								{isSearchShow && query && (
									<Card className="boxShadow" style={{ maxHeight: "10rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
										<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
											<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
										</div>
										<span className="text-center mb-3">
											<b>Pencarian berdasarkan:</b> {query}
										</span>
										{allItem?.response ? (
											allItem.response.map((item) => (
												<CardBody key={item.id} style={{ minHeight: "3rem", padding: "1rem" }} className="bgSearch" onClick={() => searchShow(item)}>
													<div>
														<b>Durasi:</b> {item.durasi}
													</div>
													<hr style={{ margin: "0.75rem -1rem 0 -1rem" }} />
												</CardBody>
											))
										) : (
											<div className="text-center mb-3 text-danger">User "{query}" tidak ada bosku!</div>
										)}
									</Card>
								)}
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
									<div
											style={{ display: "flex", justifyContent: "space-between" }}
										>
											<h3>Batas Operasional</h3>
											<div style={{ textAlign: "rigth" }}>
												<Button color="warning" onClick={() => (getDataLog(itemId))}>
												<i className="fa fa-info-circle" aria-hidden="true"></i>
												</Button>
											</div>
										</div>
                                    </CardHeader>
                                    <CardBody>
                                        <Row md="12">
                                            <Col md="6">
                                                <FormGroup row>
                                                <Label
												className="form-control-label"
                                                    for="exampleEmail"
                                                    sm={3}
                                                >
                                                    Durasi
                                                </Label>
                                                <Col sm={4}>
                                                    <Input
                                                    disabled
                                                    className="form-control-alternative"
                                                    type="text"
                                                    name="Area"
                                                    placeholder="Masukan Durasi"
                                                    value={durasipo}
                                                    onChange={(e) => {
                                                        setDurasiPo(e.target.value);
                                                    }}
                                                    />
                                                </Col>
                                                <Col sm={3}>
                                                <Label
												className="form-control-label"
                                                    for="exampleEmail"
                                                    sm={10}
                                                >
                                                    <b>Hari</b>
                                                </Label>
                                                </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
						</div>
					</Row>
				</Container>
				<Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>  
			</div>
		):(
			<Halaman404 />
		)}
		</>
	);
}
