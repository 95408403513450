import React from "react";
import './file-styles.css'
import { Col, Row } from "reactstrap";
// import CameraAltIcon from '@mui/icons-material/CameraAlt';
// import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
// import ImageIcon from '@mui/icons-material/Image';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
// import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const FotoDisplay = ({onclick,imageSrc4, htmlFor4}) => {
  return (
    <Row>
      <Col>
        <div class="dashed" onClick={() => onclick()} htmlFor={htmlFor4}>
          {
            imageSrc4 != null ?
            <img
              src={imageSrc4}
              alt=""
            />
            :
            <div>
              {/* <AddPhotoAlternateIcon color="success" fontSize="large" /> */}
              <i class="fa fa-camera-retro fa-3x" color="success"></i>
              <p>Foto Hook</p>
            </div>
          }
        </div>
      </Col>
    </Row>

  )
}

export default FotoDisplay;