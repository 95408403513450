/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    Container, 
    Form, 
    CardHeader,
    FormGroup, 
    Label, 
    Badge,
    Input ,
    DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle,
    Modal, ModalHeader, ModalBody

} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ModalCetakPusatRFQ from './ModalCetakRfqUntukPusat';
import Halaman404 from 'views/404'; 

const RfqPusat = () => {
  const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
  const [tanggal, setTanggal] = useState("");
	const toggle1 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
  const token = localStorage.token;
  const redirectPrefix1 = `/admin/rfq-pusat/detail/`;
  const [rfqcode, setRfqCode] = useState("");
  const [allPermintaanBarang, setAllPermintaanBarang] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const allInfo = JSON.parse(localStorage.allinfo);
	const rfqPusat = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Rfq PO Pusat").map((p) => p.read_access));
  const cetak = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Cetak Rfq PO Pusat").map((p) => p.read_access));
  const [warehouseid, setWarehouseId] = useState("");
  const [warehouseids, setWarehouseIds] = useState([]);
  const [openModalCetak, setOpenModalCetak] = useState(false);
  const [dataModalCetak, setDataModalCetak] = useState({ id: 0,});
  const toggle = () => setOpenModalCetak(!openModalCetak);

  const displayModalCetak = (id) => {
    setDataModalCetak({
      id: id,
    });
    setOpenModalCetak(true);
  };

  useEffect(() => {
    getPusat();
  }, []);

  const getPusat = () => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`, { headers
      })
      .then(data => {
        setWarehouseIds(data.data.response);
      })
        .catch(function (error) {
          console.log(error)
        })
    }


  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort,tanggal, warehouseid, rfqcode);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label
        className="form-control-label">
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort,tanggal, warehouseid, rfqcode)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort,tanggal, warehouseid, rfqcode) => {
    getPermintaanBarang(page, perPage, sort,tanggal, warehouseid, rfqcode);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setTanggal(tanggal)
    setWarehouseId(warehouseid);
    setRfqCode(rfqcode)
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort,tanggal,  warehouseid, rfqcode)
    }
  }

  
  useEffect(() => {
    getPermintaanBarang(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getPermintaanBarang = (page, perPage, currentSort,transaction_date=null, warehouse_id = null, rfq_code=null) => {
    
    let filter = { 
      
      page: page, 
      per_page: perPage,
      status_rfq: 5,
    };
    if (transaction_date !== null) {
      filter = Object.assign(filter, {transaction_date: transaction_date})
    }
    if (warehouse_id !== null) {
      filter = Object.assign(filter, { warehouse_id: warehouse_id });
    }
    if (rfq_code !== null) {
      filter = Object.assign(filter, { rfq_code: rfq_code });
    }
    
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/rfq-po/page`, data, {
        headers,
      })
      .then((data) => {
        setAllPermintaanBarang(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        setAllPermintaanBarang(error.response.data.response);
      });
  };

  const reset = () => {
    setTanggal("")
    setWarehouseId("");
    setRfqCode("");
    updateDataTable(1, perPage, currentSort, "", "");
  }

  const getDataLog = (id) => {
		let filter = { 
			transaction : id,
			features : "BELI1",
			warehouse_id : warehouseid
		}
		const data = filter;
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
			setDataLog(data.data.response);
            setModal1(!modal1);

		})
		  .catch(function (error) {
			setGagalAlert(error.response.data.message);
		  })
	  }

	  const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
		);
		};
		
		const hideAlert = () => {
		  setAlert(null);
		};

  return (
    <>
    {alert}
     {rfqPusat && rfqPusat === "1" ? (
			<div>
    <SimpleHeader name="Permintaan Barang Cabang" parentName="PO" />
        <Container className="mt--6" fluid>
            <Row>
            <div className="col">
            <Card className="bg-secondary shadow">
                <CardBody>
                    <Form>
                    <Row md="12">
                    <Col md="3">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                            >Tanggal</Label>
                              <Input
                              className="form-control-alternative"
                                type="date"
                                placeholder="Masukan Kode BPB"
                                value={tanggal}
                                onChange={e => updateDataTable(1, perPage, currentSort, e.target.value,warehouseid, rfqcode)}
                              />
                            </FormGroup>
                          </Col>
                    <Col md="3">
                        <FormGroup>
                        <Label
                            className="form-control-label"
                        >
                            Cabang
                        </Label>
                        <Input
                            name="account"
                            type="select"
                            value={warehouseid}
                            onChange={(e) =>
                            updateDataTable(1, perPage, currentSort,tanggal, e.target.value, rfqcode)
                            }
                        >
                            <option value="">Pilih Cabang</option>
                            {warehouseids.map((dep, key) => {
                            return (
                                <option key={key} value={dep.id}>
                                {dep.name}
                                </option>
                            );
                            })}
                        </Input>
                        </FormGroup>
                    </Col>
                    <Col md="3">
                            <FormGroup>
                            <Label
                                className="form-control-label"
                            >Kode RFQ</Label>
                              <Input
                              className="form-control-alternative"
                                type="text"
                                placeholder="Masukan Kode RFQ"
                                value={rfqcode}
                                onChange={e => updateDataTable(1, perPage, currentSort,tanggal, warehouseid, e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Button type="button" onClick={reset} color="secondary">
                            Reset
                        </Button>
                        </Col>
                    </Row>
                    </Form>
                    {allPermintaanBarang === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                  dataField: "",
                                  text: "Tanggal",
                                  sort: true,
                                  },
                                  {
                                      dataField: "",
                                      text: "Kode RFQ",
                                      sort: true,
                                  },
                                  // {
                                  //     dataField: "",
                                  //     text: "Supplier",
                                  //     sort: true,
                                  // },
                                  {
                                    dataField: "",
                                    text: "Status",
                                    sort: true,
                                },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <h3>DATA NOT FOUND</h3>
                          </CardHeader>
                        </div>
                        ):(
                        <ToolkitProvider
                                rowNumber={rowIndex}
                                data={allPermintaanBarang}
                                keyField="id"
                                columns={[
                                {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                dataField: "transaction_date",
                                text: "Tanggal",
                                sort: true,
                                },
                                {
                                    dataField: "rfq_code",
                                    text: "Kode RFQ",
                                    sort: true,
                                    formatter: (cell, row) => {
                                      return row.rfq_code === ""
                                        ? <h2>
                                          <Badge color="warning" >
                                            {row.rfq_code}
                                          </Badge>
                                          </h2>
                                        : 
                                        <h2>
                                        <Badge color="warning" >
                                            {row.rfq_code}
                                          </Badge>
                                          </h2>
                                    },
                                },
                                // {

                                //   dataField : "person_name",
                                //   text : "Supplier",
                                //   sort : true,
                                // },
                                {
                                  dataField: "status_rfq",
                                  text: "Status",
                                  sort: true,
                                  formatter: (cell, row) => {
                                    return row.status_rfq === 3
                                      ? 
                                        <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-danger" />
                                          Proses
                                        </Badge>
                                      : row.status_rfq === 4
                                      ? <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-warning" />
                                          Tolak
                                        </Badge>
                                      : <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-success" />
                                          Setuju
                                        </Badge>;
                                  },
                              },
                                {
                                    dataField: "", text: "", formatter: (cell, row, index) => {
                                    return (
                                        <UncontrolledDropdown nav>
                                        <DropdownToggle color="danger">
                                                Tindakan
                                        </DropdownToggle>
                                        <DropdownMenu>
                                                <DropdownItem onClick={() => getDataLog(row.id)}>
                                                  <i className="fa fa-info-circle" aria-hidden="true"></i><span>Info</span>
                                                </DropdownItem>
                                                <Link to={redirectPrefix1 + row.id}
                                                id={"tooltip_" + row.id}>
                                                <DropdownItem>
                                                <i className="fas fa-book"/><span>Detail</span>
                                                </DropdownItem>
                                                </Link>
                                                {cetak && cetak === "1" && (
                                                <DropdownItem onClick={() => displayModalCetak(row.id)}>
                                                  <i className="fas fa-print" /><span>Cetak</span>
                                                </DropdownItem>
                                                )}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    )
                                    }
                                },
                                ]}
                            >
                                {(props) => (
                                <div className="py-4 table-responsive">
                                    <BootstrapTable
                                    remote
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={false}
                                    hover={true}
                                    pagination={paginationFactory({ ...paginationOption })}
                                    onTableChange={handleTableChange}
                                    />
                                </div>
                                )}
                        </ToolkitProvider>
                        )}
                </CardBody>
                </Card>
            </div>
            </Row>
            {openModalCetak && (
        <ModalCetakPusatRFQ
          open={openModalCetak}
          data={dataModalCetak}
          toggle={toggle}
        />
      )}
        </Container>
        <Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>
        </div>
     ):(
      <Halaman404 />
     )}
    </>
  );
}

export default RfqPusat;
