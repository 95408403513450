/*eslint-disable*/
import React from "react";
import {
  Card,
  CardBody,
  Row,
  Container,
  CardImg,
} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";

export default function PermintaanBarangPoCabang() {
    const allInfo = JSON.parse(localStorage.allinfo);
    const buktiBankKeluars = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Bukti Bank Keluar").map((p) => p.read_access));
    const buktiKasKeluar = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab BBK").map((p) => p.read_access));
	const validasiKepalaFinance = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi BBK Admin").map((p) => p.read_access));
	const validasiDirektur = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi BBK Direktur").map((p) => p.read_access));
	const histori = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Cetak BBK").map((p) => p.read_access));
 
    return (
    <>
     {buktiBankKeluars && buktiBankKeluars === "1" ? (
      <div>
        <SimpleHeader name="BBK Pembelian" parentName="Report" />
        <Container className="mt--6" fluid>
            <Row>
            <div className="col">
                    <CardBody>
                        <Row>
                            <div className="card-columns">
                            {buktiKasKeluar && buktiKasKeluar === "1" && (
                                <Card>
                                    <Link to="/admin/bbk-finance/page">
                                        <CardImg
                                            alt="..."
                                            src={require("assets/img/theme/bbk-pembelian.png").default}
                                            top
                                        />
                                    </Link>
                                </Card>
                            )}
                                {validasiKepalaFinance && validasiKepalaFinance === "1" && (
                                <Card>
                                    <Link to="/admin/bbk-finance/validasi-kepala-finance">
                                        <CardImg
                                            alt="..."
                                            src={require("assets/img/theme/validasi-kepala-finance.png").default}
                                            top
                                        />
                                    </Link>
                                </Card>
                            )}
                            {validasiDirektur && validasiDirektur === "1" && (
                                <Card>
                                    <Link to="/admin/bbk-finance/validasi-direktur">
                                        <CardImg
                                            alt="..."
                                            src={require("assets/img/theme/validasi-direktur.png").default}
                                            top
                                        />
                                    </Link>
                                </Card>
                            )}
                            {histori && histori === "1" && (
                                <Card>
                                    <Link to="/admin/bbk-finance/histori">
                                        <CardImg
                                            alt="..."
                                            src={require("assets/img/theme/history.png").default}
                                            top
                                        />
                                    </Link>
                                </Card>
                            )}
                            </div>
                        </Row>
                    </CardBody>
            </div>
            </Row>
        </Container>
      </div>
     ):(
        <Halaman404 />
     )}
    </>
  );
}