/*eslint-disable*/
import React from "react";
import {
  Card,
  CardBody,
  Row,
  Container,
  CardImg,
} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function PageEcommerce() {
  
  return (
    <>
      <div>
      <SimpleHeader name="Laporan E-Commerce" parentName="Report" />
      <Container className="mt--6" fluid>
                        {/* <div style={{ textAlign: 'left' }}>
                            <Link className="btn btn-link" to="/admin/bkk-finance">
                                <i className="fa fa-arrow-circle-left fa-2x" /> 
                            </Link>
                        </div> */}
        <Row>
          <div className="col">
                <CardBody>
                    <Row>
                        <div className="card-columns">
                            {/* <Card>
                                <Link to="/admin/laporan/e-commerce/laporan-kurir">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/retur-pembelian.png").default}
                                        top
                                    />
                                </Link>
                            </Card> */}
                        </div>
					</Row>
                </CardBody>
          </div>
        </Row>
      </Container>
      </div>
    </>
  );
}