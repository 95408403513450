/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Table,
  Container,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

export default function ValidasiSuratJalanSo(props) {
  const [alert, setAlert] = React.useState(null);
  const [tanggal,setTanggal] = useState("")
  const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState("");
  const [pengiriman, setPengiriman] = useState([]);
  const [keterangan, setKeterangan] = useState("");
  const [savedItems, setSavedItems] = useState([]);
  const [statussj,setStatusSj] = useState([]);
  const [codeso, setCodeSo] = useState("");
  const [codesj, setCodeSj] = useState("");
  const [driver, setDriver] = useState("");
  const [driverError, setDriverError] = useState(null);
  const [helper, setHelper] = useState("");
  const [helperError, setHelperError] = useState(null);
  const [nopol, setNopol] = useState("");
  const [nopolError, setNopolError] = useState(null);
  const allInfo = JSON.parse(localStorage.allinfo);
  const suratjalanso = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Surat Jalan SO").map((p) => p.read_access));
 
  const validateForm = () => {
    let error = false;
    if (driver === null) {
      setDriverError("invalid");
      error = true;
    }
    if (helper === null) {
        setHelperError("invalid");
      error = true;
    }
    if (nopol === null) {
      setNopolError("invalid");
      error = true;
    }
    return error;
  };

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        // getCustomer(data.data.response.customer_id);
        setTanggal(data.data.response.created_at);
        setCustomer(data.data.response.customer_name)
        setStatusSj(data.data.response.status_sj);
        setKeterangan(data.data.response.keterangan);
        setCodeSo(data.data.response.code_so);
        setCodeSj(data.data.response.sj_code);
        setPengiriman(data.data.response.pengiriman);
        setDriver(data.data.response.driver);
        setHelper(data.data.response.helper);
        setNopol(data.data.response.nopol);
        getItemDataSaved();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/item`, {

        sj_id: props.match.params.id

    }).then(async response => {
        let stateItem = [];

        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code : data.item_code,
                satuan : data.satuan,
                qty: data.qty,
                harga: data.harga,
                diskon_nominal: data.diskon_nominal,
                diskon_persen : data.diskon_persen,
                data: {
                    item_name: data.item_name,
                    harga: data.harga,
                    qty: data.qty,
                    diskon_nominal: data.diskon_nominal,
                    diskon_persen : data.diskon_persen,
                },
            }];
        }));

        setSavedItems(stateItem);
    })
}

const getCustomer = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/customer/list`,
        { headers }
      )
      .then((data) => {
        setCustomers(data.data.response);
        setCustomer(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit2 = () => {
    hideAlert();
    setTimeout(() => {EditData()}, 1000);
  };

   function EditData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga:dataItem.harga,
            }]);
    let data = {
        warehouse_id : parseInt(warehouse),
        code_so: codeso,
        code_sj: codesj,
        status_sj: parseInt(statussj),
        admin_gudang: username,
        // customer_id: parseInt(customer),
        pengiriman: parseInt(pengiriman),
        keterangan: keterangan ,
        driver : driver,
        helper : helper,
        nopol : nopol,
        items : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/update/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message)
          setTimeout(() => (history.push("/admin/surat-jalan-so/validasi-kepala-gudang")), 1000);

        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
    }
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }

  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };

  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={handleSubmit2}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };

  const handleSubmit3 = (e) => {
    e.preventDefault();
    if (!validateForm()) {
        setQuestionAlert();
    }
  }
  
    const hideAlert = () => {
      setAlert(null);
    };

  return (
    <>
    {alert}
    {suratjalanso && suratjalanso === "1" ? (
			<div>
    <SimpleHeader name="Validasi Kepala Gudang " parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
        <div className="col">
            <CardBody>
            <Form onSubmit={handleSubmit3}>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Validasi Kepala Gudang</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Tanggal
                              </Label> 
                              <Col sm={6}>
                              <Input
                                  className="form-control-alternative"
                                  disabled
                                  name="customer"
                                  type="text"
                                  value={tanggal}
                                />
                              </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode SJ
                              </Label>
                              <Col sm={6}>
                              <Input
                                  className="form-control-alternative"
                                  disabled
                                  name="customer"
                                  type="text"
                                  value={codesj}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode SO
                              </Label>
                              <Col sm={6}>
                              <Input
                                  className="form-control-alternative"
                                  disabled
                                  name="customer"
                                  type="text"
                                  value={codeso}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Customer
                              </Label>
                              <Col sm={6}>
                              <Input
                                  className="form-control-alternative"
                                  disabled
                                  name="customer"
                                  type="text"
                                  placeholder="Masukan Customer"
                                  value={customer}
                                />
                              </Col>
                            </FormGroup>
                          {/* <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Customer
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                              disabled
                                  name="customer"
                                  type="select"
                                  value={customer}
                                  onChange={(e) => {
                                    setCustomer(e.target.value);
                                  }}
                                >
                                  <option value=''>Pilih Customer</option>
                                  {customers.map((customer, key) => {
                                      return (
                                        <option key={key} value={customer.id}>
                                          {customer.name}
                                        </option>
                                      );
                                    })}
                                  </Input>
                              </Col>
                            </FormGroup> */}
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                  for="exampleEmail"
                                  sm={3}
                                >
                                  Driver
                                </Label>
                                <Col sm={6}>
                                    <Input
                                    disabled
                                      className="form-control-alternative"
                                      placeholder="Masukan Driver"
                                      name="customer"
                                      type="text"
                                      value={driver}
                                      invalid={driverError === "invalid"}
                                      onChange={(e) => {
                                        setDriver(e.target.value);
                                        if (e.target.value !== "") {
                                          setDriverError("");
                                        }
                                      }}
                                    >
                                  </Input>
                                  <FormFeedback>
                                      Driver tidak boleh kosong
                                  </FormFeedback>
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                className="form-control-label"
                                  for="exampleEmail"
                                  sm={3}
                                >
                                  Helper
                                </Label>
                                <Col sm={6}>
                                  <Input
                                  disabled
                                      className="form-control-alternative"
                                      placeholder="Masukan Helper"
                                      name="customer"
                                      type="text"
                                      invalid={helperError === "invalid"}
                                      value={helper}
                                      onChange={(e) => {
                                        setHelper(e.target.value);
                                        if (e.target.value !== "") {
                                          setHelperError("");
                                        }
                                      }}
                                    >
                                  </Input>
                                  <FormFeedback>
                                                              Helper tidak boleh kosong
                                                          </FormFeedback>
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                className="form-control-label"
                                  for="exampleEmail"
                                  sm={3}
                                >
                                  Nopol
                                </Label>
                                <Col sm={6}>
                                  <Input
                                  disabled
                                      className="form-control-alternative"
                                      placeholder="Masukan Nopol"
                                      name="customer"
                                      type="text"
                                      value={nopol}
                                      invalid={nopolError === "invalid"}
                                      onChange={(e) => {
                                        setNopol(e.target.value);
                                        if (e.target.value !== "") {
                                          setNopolError("");
                                        }
                                      }}
                                    >
                                  </Input>
                                  <FormFeedback>
                                      Nopol tidak boleh kosong
                                  </FormFeedback>
                                </Col>
                              </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Pengiriman
                            </Label>
                            <Col sm={6}>
                            <Input
                            className="form-control-alternative"
                            disabled
                                name="Tipe Request"
                                type="select"
                                value={pengiriman}
                                onChange={(e) => {
                                  setPengiriman(e.target.value);
                                }}
                              >
                                <option value="">Pilih Request</option>
                                <option value={1}>Ambil Sendiri</option>
                                <option value={2}>Delivery</option>
                                <option value={3}>Kurir</option>
                              </Input>
                            </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Alamat Kirim
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="textarea"
                                  name="keterangan"
                                  rows = "6"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Col
                              for="exampleEmail"
                              sm={3}
                            >
                              <Label className="form-control-label">Validasi</Label>
                              
                            </Col>
                              <Col sm={7}>
                                <div style={{ display: "flex" }}>
                                  <div className="custom-control custom-radio mb-3">
                                    <Input
                                      className="custom-control-input"
                                      id="customRadio12"
                                      name="custom-radio-5"
                                      type="radio"
                                      value={5}
                                      checked={statussj === 5}
                                      onChange={() => setStatusSj(5)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio12"
                                    >
                                    </Label>
                                    <b>SETUJU</b>

                                  </div>
                                  <div
                                    className="custom-control custom-radio mb-3"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <Input
                                      className="custom-control-input"
                                      id="customRadio13"
                                      name="custom-radio-5"
                                      type="radio"
                                      value={4}
                                      checked={statussj === 4}
                                      onChange={() => setStatusSj(4)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio13"
                                    >
                                    </Label>
                                    <b>TOLAK</b>

                                  </div>
                                </div>
                              </Col>
                          </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                
              </Card>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                    <CardBody>
                      <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th><b>Nama Item</b></th>
                            <th><b>Kode Item</b></th>
                            <th><b>Qty</b></th>
                            <th><b>Satuan</b></th>
                        </tr>
                        </thead>
                        <tbody>
                          {
                              savedItems.map((savedItem, key) => {
                                  return (
                                      <tr key={key}>
                                          <td>{savedItem.item_name}</td>
                                          <td>{savedItem.item_code}</td>
                                          <td>{savedItem.qty}</td>
                                          <td>{savedItem.satuan}</td>
                                      </tr>
                                  )
                              })
                          }
                        </tbody>
                      </Table>
                    </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/surat-jalan-so/validasi-kepala-gudang">
                        Kembali
                      </Link>
                      <Button color="danger" type="submit">
                          Simpan
                      </Button>
                </CardFooter>
              </Card>
              </Form>
            </CardBody>
          </div>
        </Row>
    </Container> 
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}