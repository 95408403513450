/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, Row, Col, Input,  ButtonGroup ,FormFeedback,Container,  InputGroup,  InputGroupText, FormGroup, Badge, Form, Table, Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import 'react-select2-wrapper/css/select2.css';
import SweetAlert from "react-bootstrap-sweetalert";
import  'react-multiple-select-dropdown-lite/dist/index.css'
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { ViewCetakSoKasir } from "utils/CetakCetak2";
import CurrencyInput from 'react-currency-input-field';
import ModalCekHarga from "./ModalCekHarga";

export default function CreateSalesOrder() {
	const token = localStorage.token;
	const warehouse = localStorage.warehouse;
	const username = localStorage.username;
	const [rowIndex, setRowIndex] = useState(0);
	const waktu= new Date().toLocaleDateString("en-US");
	const [jasa, setJasa] = useState(0);
	const [jasa1, setJasa1] = useState(0);
	const [notakasirid, setNotaKasirId] = useState(0);

	// manual add w search n kembalian
	const [isKembalianOpen, setIsKembalianOpen] = useState(false);
	const [isPackingList, setIsPackingList] = useState(false);

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	// const [modal5, setModal5] = useState(false);
	// const toggles = () => setModal5(!modal5);
	const [modal6, setModal6] = useState(false);
	const toggless = () => setModal6(!modal6);
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenkembaliancok, setIsOpenKembalianCok] = useState(false);
	const [isOpenCustomer, setIsOpenCustomer] = useState(false);
	const [qtyTotal, setTotalQty] = useState(0);
	const [diskontotal, setDiskonTotal] = useState(0);
	const [barcode, setBarcode] = useState("");
	const [pengiriman, setPengiriman] = useState(1);
	const [customers, setCustomers] = useState([]);
	const [customer, setCustomer] = useState("");
	const [payment_method1, setPaymentMethod1] = useState(1);
	const [payment_method2, setPaymentMethod2] = useState(0);
	const [savedItems, setSavedItems] = useState([]);
	const [savedPromos, setSavedPromos] = useState([]);
	const [payment_method, setPaymentMethod] = useState(1);
	const [pay1, setPay1] = useState(0);
	const [pay2, setPay2] = useState(0);
	// const [change, setChange] = useState(0);
	const [banks, setBanks] = useState([]);
	const [ppn, setPPN] = useState(0);
	const [ongkir, setOngkir] = useState(0);
	const [ongkir1, setOngkir1] = useState(0);
	const [totaldiskon, setTotalDiskon] = useState(0);
    // const [totaldiskon1, setTotalDiskon1] = useState(0);
	const [grandtotal, setGrandTotal] = useState(0);
	const [alamatcustomer, setAlamatCustomer] = useState("");
	const [pajak, setPajak] = useState(1);
	const [allpajak, setAllPajak] = useState([]);
	const [sales, setSales] = useState("");
	const [promo,setPromo]= useState("");
	const [allItemm, setAllItemm] = useState([]);
	// const [allPromo,setAllPromo]= useState([]);
	// const [isSearchShowPromo, setIsSearchShowPromo]= useState("");
	const [queryy, setQueryy] = useState("");
	const [isSearchShoww, setIsSearchShoww] = useState(false);
	const [ppnnew, setPPNNEW] = useState(0);
    const [totalPrice1, setTotalPrice1] = useState(0);
	const headers = { Authorization: `Bearer ${token}`};
    const a = 0;
	const b = 0;
	const [diskonglobalnominal1,setDiskonGlobalNominal1] = useState(0)
	const [totaldiskon2, setTotalDiskon2] = useState(0);
	const [totaldiskon3, setTotalDiskon3] = useState(0);
	const [pricetype, setPriceType] = useState("");
	const [namecustomer, setNameCustomer] = useState("");
    const [provinces, setProvinces] = useState([]);
    const [province, setProvince] = useState("");
    const [cities, setCities] = useState([]);
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
	const [editable, setEditable] = useState(false);
	const [buttondelete, setButtonDelete] = useState(false);
	const [isConfEditableOpen, setIsConfEditableOpen] = useState(false);
	const [confPassEditable, setConfPassEditable] = useState('');
	// const [isLoading, setLoading] = useState(false);
	const [nameotorisasi, setNameOtorisasi] = useState("");
	const [nameotorisasiError, setNameOtorisasiError] = useState(null);
	const [keterangan, setKeterangan] = useState("");
	const [keteranganError, setKeteranganError] = useState(null);
	const [reasons, setReasons] = useState([])
	const [codenota, setCodeNota] = useState("");
	const [namajasa, setNamaJasa] = useState("");
	// const [selisihbulat, setSelisihBulat] = useState(0);
	const [namapaymentmethod, setNamaPaymentMethod] = useState("");
	const [totalitem,setTotalItem] = useState("");
	const [listitempending,setListItemPending] = useState([])
	const [promoglobal,setPromoGlobal] = useState(0);
	const [field, setField] = useState(0);
	const [idnotakasir, setIdNotaKasir] = useState(0);
	const [alert, setAlert] = React.useState(null);
	const [setItem, setSetItem] = useState([])
	const [returcode,setReturCode] = useState("");
	const [nominalretur, setNominalRetur] = useState(0);
	const [page, setPage] = useState(1);
	const [perPage, setPerpage] = useState(10);
	const [qtyError, setQtyError] = useState(null);
	// const [codepo, setCodePo] = useState("");
    // const [waktutransaksi,setWaktuTransaksi] = useState("");
	const [savedItemsPackingList,setSavedItemsPackingList] = useState([]);
	const [itemIDActive, setItemIDActive] = useState([])
	const [openModalCetak, setOpenModalCetak] = useState(false);
	const toggle1 = () => setOpenModalCetak(!openModalCetak);

	const displayCekHarga = () => {
		setOpenModalCetak(true);
	  };

	const validateFormQty = () => {
		let error = false;
		savedItems.map((savedItem, key) => { 
		  if (savedItem.qty > savedItem.qtysisa) {
			setQtyError("invalid");
			setQtyBarangCok()
			error = true;
		  }
		})
		return error;
	};
	
	const handleformvalidasi = (e) => {
	  e.preventDefault();
	  if (!validateFormQty()) {
		UpdateKasirTerakhir();
	  }
	}

	// useEffect(() => {
	// 	getPackingListItem();
	// 	getItemPackingList();
	//   }, []);
	
	//   const getPackingListItem = () => {
	// 	const headers = {
	// 	  "Content-Type": "application/json",
	// 	  Authorization: `Bearer ${token}`,
	// 	};
	// 	axios
	// 	  .get(
	// 		`${process.env.REACT_APP_API_BASE_URL}/picking-list/nota/${notakasirid}`,
	// 		{ headers }
	// 	  )
	// 	  .then((data) => {  
	// 		setWaktuTransaksi(data.data.response[0].transaction_date)
	// 		setCodePo(data.data.response[0].code_from);
	// 	  })
	// 	  .catch(function (error) {
	// 		console.log(error);
	// 	  });
	//   };

	  const getItemPackingList = () => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		  };
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/picking-list/nota/${notakasirid}`,{ headers }
		
		).then(async response => {
			let stateItem = [];
			await Promise.all(response.data.response.map(async (data) => {
				stateItem = [...stateItem, {
					code_rak: data.code_rak,
					item_name:data.item_name,
					item_code:data.item_code,
					qty: data.qty,
					satuan: data.satuan,
					data: {
						item_name: data.item_name,
						harga: data.harga
					},
				}];
			}));
	
			setSavedItemsPackingList(stateItem);
		})
	}

	//fungsi untuk dobel klik button update kasir
    const EditData = async (e) => {setTimeout(handleClick, 1000);};
	function handleClick() {cekPromo.current.onClick();}
	const cekPromo = useRef();
	function UntukCekPromo() {setTimeout(inputRef.current.onClick(), 1000); }
	const inputRef = useRef();
	const getDataPending = async (e) => {setTimeout(handlepending, 1000);};
	function handlepending() {CekPending.current.onClick();}
	const CekPending = useRef();

	// const [bulatin, setBulatin] = useState(0);
	// const [bulat, setBulat] = useState(0);

	// cetak
	const componentCetakSoKasir = useRef();
	const handleCetak = () => {
		const content = componentCetakSoKasir.current;
		const printWindow = window.open('', '', 'height=1,width=1');
		printWindow.document.write(content.innerHTML);
		printWindow.document.close();
		printWindow.onload = function() {
		printWindow.focus();	
		printWindow.print({silent: true});
		printWindow.close();
		};
	  };

    function simpanCetakSoKasir (data) {
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/pos-cashier/close/${notakasirid}`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		.then(function () {
			getItemPackingList();
			setIsPackingList(true);
			setIsOpen(true) ;
			handleCetak();

			// getPackingListItem();
			// setIsKembalianOpen(true);
			// setTimeout(() => setIsKembalianOpen(false), 7000);
			setTimeout(() => (window.location.reload("/admin/kasir-sales-order/so-kasir")), 10000);
		})
		.catch(function (error) {
			setGagalAlert(error.response.data.message)
		});
	};

	const validateForm = () => {
		let error = false;
		if (nameotorisasi === "") {
			setNameOtorisasiError("invalid");
		  error = true;
		}
		if (keterangan === "") {
			setKeteranganError("invalid");
		  error = true;
		}
		return error;
	};

	//Button New Kasir
    function Newkasir() {
		// setLoading(true);
			let data = {
				warehouse_id: parseInt(warehouse),
				username: username,
			};
		axios
			.post(
			  `${process.env.REACT_APP_API_BASE_URL}/pos-cashier/open`,
			  data,
			  {
				headers: {
				  Authorization: `Bearer ${token}`,
				},
			  }
			)
			.then(function (response) {
            getKasirId();
            getPenawaranSo();
			})
			.catch(function (error) {
			  console.log(error);
			});
	  }
	  

    useEffect(() => {
        getKasirId();
		getReason();
		cekButton();
      }, []);
	
	  const getReason = () => {
		const headers = {
		  "Content-Type": "application/json",
		  Authorization: `Bearer ${token}`,
		};
		axios
		  .get(
			`${process.env.REACT_APP_API_BASE_URL}/reason/list?type=2`,
			{ headers }
		  )
		  .then((data) => {
			setReasons(data.data.response);
		  })
		  .catch(function (error) {
			console.log(error);
		  });
	  };
	
	  const updateDataTable = (page, perPage, currentSort) => {
		getSalesTrackingDetail(page, perPage, currentSort);
		setPage(page);
		setPerpage(perPage);
		setRowIndex((page - 1) * perPage);
	  };
	
	  const handleTableChange = (type, { sortField, sortOrder }) => {
		if (type === "sort") {
		  let sort = `${sortField} ${sortOrder}`;
		  updateDataTable(page, perPage, sort);
		}
	  };

	  // Fungsi Untuk Hidden/disabled field/button
	  const cekButton = () => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        let data = {
			warehouse_id: parseInt(warehouse),
			username : username
		  }
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/chasier-settlement/settlement-button/${username}`, data,
            { headers }
          )
          .then((data) => {
			setField(data.data.response.button_state);
			// getPackingListItem();
			// getItemPackingList();
          })
          .catch(function (error) {
            console.log(error);
          });
      };
    
	  //fungsi untuk menampilkan data di getId.
      const getKasirId = () => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        let data = {
			warehouse_id: parseInt(warehouse),
			username : username
		  }
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/pos-cashier/recent?warehouse_id=${warehouse}&username=${username}&pending_id=${idnotakasir}`, data,
            { headers }
          )
          .then((data) => {
			getCustomer(data.data.response.customer_id);
			getByCustomerAlamat(data.data.response.customer_id);
            getPajak(data.data.response.pajak_id);
			getBank(data.data.response.payment_method1);
			getBankPayment1(data.data.response.payment_method1);
			getItemDataSaved(data.data.response.nota_code);
			getPromoDataSaved(data.data.response.nota_code)
			setNamaJasa(data.data.response.keterangan_jasa);
			setIdNotaKasir(data.data.response.id)
			setNotaKasirId(data.data.response.id);
            setCodeNota(data.data.response.nota_code);
            setPaymentMethod(data.data.response.payment_method);
			setAlamatCustomer(data.data.response.manual_address);
            setOngkir(data.data.response.ongkir);
            setSales(data.data.response.sales);
            setPengiriman(data.data.response.pengiriman);
			setPromoGlobal(data.data.response.promo_total);
            setPay1(data.data.response.pay_1);
            setPay2(data.data.response.pay_2);
			setPaymentMethod1(data.data.response.payment_method1)
			setTotalQty(data.data.response.price_real);
			cekButton();
          })
          .catch(function (error) {
            console.log(error);
          });
      };

	   // fungsi untuk menampilkan data promo Berdasarkan Code Nota
	   const getPromoDataSaved = (id) => {
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-promo/list-by-nota`, {
			nota_code: id,
		}).then(async response => {
			let stateItem = [];
			await Promise.all(response.data.response.map(async (data) => {
				stateItem = [...stateItem, {
					code_promo: data.code_promo,
					potongan_promo : data.potongan_promo,
						data: {
							code_promo: data.code_promo,
							potongan_promo : data.potongan_promo,
						},
				}];
			}));
			setSavedPromos(stateItem);
		})
	}


	  // fungsi untuk menampilkan data Item Berdasarkan Code Nota
	  const getItemDataSaved = (id) => {
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/pos-cashier/get-cashier-item`, {
			nota_code: id,
		}).then(async response => {
			let stateItem = [];
			await Promise.all(response.data.response.map(async (data) => {
				stateItem = [...stateItem, {
					item_id: data.item_id,
					item_name:data.item_name,
					item_code : data.item_code,
					satuan : data.satuan,
					code_promo: data.code_promo,
					qtysisa : data.max_qty,
					type : data.type,
					parent_child : data.parent_child,
					qty: data.qty,
					harga: data.harga,
					diskon_persen: data.diskon_persen,
					diskon_nominal: data.diskon_nominal,
					data: {
						item_name: data.item_name,
						harga: data.harga,
						code_promo: data.code_promo,
						type : data.type,
						parent_child : data.parent_child,
						diskon_persen: data.diskon_persen,
						diskon_nominal: data.diskon_nominal,
						qty: data.qty,
					},
				}];
			}));
			setSavedItems(stateItem);
		})
	}

	//fungsi untuk handle izinkan Edit kalau field tidak isi
	const handleEditItem = (e) => {
	e.preventDefault();
	if (!validateForm()) {
		CekPasswordOtoritas();
	}
	};

	//fungsi untuk edit kasir
	  const CekPasswordOtoritas = async () => {
		let data = {
			code_transaction: codenota,
			warehouse_id: parseInt(warehouse),
			username: nameotorisasi,
			password_otorisasi: confPassEditable,
			note : keterangan
		  }
			await axios.post(`${process.env.REACT_APP_API_BASE_URL}/password-operasional/cashier`, data, {
			headers: {
			  Authorization: `Bearer ${token}`,
			}
		  })
		  .then(function (response) {
			setEditable(true);setIsConfEditableOpen(false);setConfPassEditable('');setNameOtorisasi('');setKeterangan('')
			setSuccessAlert(response.data.message);
			let oldobj = savedItems.find(o => o.item_id === itemIDActive);
				if(oldobj===undefined){}else{
				let index = savedItems.findIndex(e=>e.item_id===itemIDActive);
				let updateList = savedItems;
				updateList[index] = {...updateList[index], edit_item: true};
				setSavedItems(updateList);
				}  
		})
		  .catch(function (error) {
				setEditable(false);setIsConfEditableOpen(true);setConfPassEditable('');setNameOtorisasi('');setKeterangan('')
				setGagalAlert(error.response.data.message);
		  });
	};

	const handleButtonDelete = (e) => {
		e.preventDefault();
		if (!validateForm()) {
			buttondeletecuaks();
		}
		};
	
		//fungsi untuk edit kasir
		  const buttondeletecuaks = async () => {
			let data = {
				code_transaction: codenota,
				warehouse_id: parseInt(warehouse),
				username: nameotorisasi,
				password_otorisasi: confPassEditable,
				note : keterangan
			  }
				await axios.post(`${process.env.REACT_APP_API_BASE_URL}/password-operasional/cashier`, data, {
				headers: {
				  Authorization: `Bearer ${token}`,
				}
			  })
			  .then(function (response) {
				setButtonDelete(false);setConfPassEditable('');setNameOtorisasi('');setKeterangan('');setDeleteItem();setSuccessAlert(response.data.message);
			})
			  .catch(function (error) {
					setButtonDelete(true);setConfPassEditable('');setNameOtorisasi('');setKeterangan('');setGagalAlert(error.response.data.message);
			  });
		};

		const cekbuttondeleteitem = (savedItem) => {
			setSetItem(savedItem)
		};

		const cekcekcek = () => {
			deleteItem(setItem.item_id);
		};

	const setDeleteItem = () => {
		setAlert(
		  <SweetAlert
			success
			showConfirm
			confirmBtnText="Ok"
			title="Delete Item Sukses"
			onCancel={hideAlert}
			onConfirm={(EditData(),cekcekcek(),hideAlert)}
		  />
		)
	  }

	  const setSuccessAlert = (id) => {
		setAlert(
		  <SweetAlert
			success
			showConfirm
			confirmBtnText="Ok"
			title={id}
			onCancel={hideAlert}
			onConfirm={hideAlert}
		  />
		)
	  }
	
	const setGagalAlert = (id) => {
	setAlert(
	  <SweetAlert
	  danger
	  showConfirm
	  confirmBtnText="Ok"
	  title={id}
	  onCancel={hideAlert}
	  onConfirm={hideAlert}
	  />
	);
	};

	const setSimpan = () => {
		setAlert(
		  <SweetAlert
			success
			showConfirm
			confirmBtnText="Ok"
			title="Edit Item Sukses"
			onCancel={hideAlert}
			onConfirm={hideAlert}
		  />
		)
	  }

	  const setQtyBarangCok = () => {
		setAlert(
		  <SweetAlert
			danger
			showConfirm
			confirmBtnText="Ok"
			title="Mohon Cek Ketersedian Barang"
			onCancel={hideAlert}
			onConfirm={hideAlert}
		  />
		)
	  }
	
	const hideAlert = () => {
		setAlert(null);
	  }

	//   useEffect(() => {
	// 	setTotalPrice1(qtyTotal);
	// }, [qtyTotal]);

	// useEffect(() => {
	// 	setDiskonGlobalNominal1(diskonglobalnominal);
	//   }, [diskonglobalnominal]);
	  
	// 	// diskon bayangan
	// 	useEffect(() => {
	// 	setTotalDiskon(diskonglobalnominal1);
	//   }, [diskonglobalnominal1]);
	  
	//   useEffect(() => {
	// 	setTotalDiskon3(diskontotal-a);
	//   }, [diskontotal, a]);
	  
		//diskon tampil
		useEffect(() => {
		setTotalDiskon2((totaldiskon)/((ppn/100)+1));
	  }, [totaldiskon3, totaldiskon, ppn , a]);
	  
	  //ongkir tampil
	  useEffect(() => {
		setOngkir1(ongkir - b);
	  }, [ongkir, b]);
	  
	// 	useEffect(() => {
	// 	setTotalPrice1((qtyTotal)/((ppn/100)+1));
	//   }, [qtyTotal, ppnnew,ppn]);
	  
	  
		// diskonglobalnominal dan persen
	// 	useEffect(() => {
	// 	  diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
	// 	  diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
	// 	}, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);
	  
	// 	// hasil nominal dari PPN
	// 	useEffect(() => {
	// 	  setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
	// 	}, [qtyTotal,totaldiskon,ppn]);
	  
	// 	// hasil grandtotal
	//   useEffect(() => {
	// 	setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew + ongkir1- a );
	//   }, [totalPrice1,qtyTotal, totaldiskon2, ppnnew, ongkir1, a]);

	// useEffect(() => {
	// 	setTotalPrice1(qtyTotal - ppnnew);
	//   }, [qtyTotal, ppnnew]);

	  useEffect(() => {
		setTotalPrice1((qtyTotal)/((ppn/100)+1));
	  }, [qtyTotal, ppnnew,ppn]);
	  

	useEffect(() => {
		setDiskonGlobalNominal1(promoglobal);
	}, [promoglobal]);

    // diskon bayangan
    useEffect(() => {
		setTotalDiskon(diskonglobalnominal1);
	}, [diskonglobalnominal1]);

	//diskon tampil
	useEffect(() => {
		setTotalDiskon2((totaldiskon)/((ppn/100)+1));
	  }, [totaldiskon3, totaldiskon, ppn , a]);
	  

    // //diskon tampil
    // useEffect(() => {
	// 	setTotalDiskon2(totaldiskon - a);
	// }, [ totaldiskon, a]);

	useEffect(() => {
		setTotalDiskon3(totaldiskon2 - a);
	}, [ totaldiskon2, a]);

	 // hasil nominal dari PPN
	 useEffect(() => {
		setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
	}, [qtyTotal,totaldiskon,ppn]);
	 
	//ongkir tampil
	useEffect(() => {
		setOngkir1(ongkir - b);
	}, [ongkir, b]);

	//jasa tampil
	useEffect(() => {
		setJasa1(jasa - b);
	}, [jasa, b]);

	// useEffect(() => {
	// 	setSelisihBulat(bulat - grandtotal);
	// }, [bulat, grandtotal]);
	   
    // hasil grandtotal
	useEffect(() => {
		setGrandTotal(((totalPrice1 - totaldiskon3) + ppnnew + ongkir1 + jasa1 - a) - (nominalretur) );
	}, [totalPrice1, totaldiskon3,ppnnew, ongkir1, jasa1,nominalretur, a]);

	//fungsi untuk menampilkan pajak dengan persen
	useEffect(() => {
        getByIdPajak();
	}, [pajak]);

	const getByIdPajak = () => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/pajak/${pajak}`,
	        { headers }
	      )
	      .then((data) => {;
	        setPPN(data.data.response.persentase);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	};

	//menampilkan address customer dengan mengambil id customer
	const getByCustomerAlamat = (id) => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/customer/get/${id}`,
	        { headers }
	      )
	      .then((data) => {
            setAlamatCustomer(data.data.response.address)
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	};


	const getPajak = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
			.then((data) => {
				setAllPajak(data.data.response);
				setPajak(id);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getCustomer = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/customer/list`, { headers })
			.then((data) => {
				setCustomers(data.data.response);
				setCustomer(id)
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	//fungsi untuk menampilkan list bank
	const getBank = () => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-wh/${warehouse}`, { headers })
			.then((data) => {
				setBanks(data.data.response);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	// untuk menampilkan metode Pembayaran di Struk Kasir
	const getBankPayment1 = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/bank/get/${id}`, { headers })
			.then((data) => {
				setNamaPaymentMethod(data.data.response.bank_name);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	
	// fungsi untuk menampilkan data dengan cara di select
	useEffect(() => {
		const getData = setTimeout(async() => {
			if(queryy != ''){
				axios
				.post(`${process.env.REACT_APP_API_BASE_URL}/items-so-cashier`, { item_info: queryy , per_page: 10, warehouse_id: parseInt(warehouse)},{ headers })
				.then((res) => {
					setAllItemm(res.data);
					setIsSearchShoww(true);
				})
				.catch(function (error) {
				});
			}else{
		  setAllItemm(null);
			}
		  }, 1000)
		  return () => clearTimeout(getData)
	  }, [queryy]);


	  const savePromoOrUpdate = (promos) => {
		let oldobj = savedPromos.find(o => o.code_promo === promos.promo_code);
		if(oldobj===undefined){
			setSavedPromos([...savedPromos,{ 
				code_promo : promos.promo_code,
				code_nama_promo: promos.promo_code,
				potongan_promo : promos.potongan_promo,
				qty: 1,
			}]);
			return 	setPromoGlobal(promoglobal + promos.potongan_promo)
		}else{
			let index = savedPromos.findIndex(e=>e.code_promo===promos.promo_code);
			let updateList = savedPromos;
			let qtyupdate = parseInt(updateList[index].qty)+parseInt(1);
			updateList[index] = {...updateList[index], qty: qtyupdate};
			setSavedPromos(updateList)
		};
	}

	// fungsi update kasir
      function UpdateKasir() {
		let dataPromos = [];
			savedPromos.map(
				(dataPromo) =>
					(dataPromos = [
						...dataPromos,
						{
							code_promo: dataPromo.code_promo,
							potongan_promo : dataPromo.potongan_promo
						},
					])
			);
        let dataItems = [];
        savedItems.map(
            (dataItem) =>
                (dataItems = [
                    ...dataItems,
                    {
                        item_id: dataItem.item_id,
                        qty: dataItem.qty,
						code_promo: 0,
						type : 0,
						parent_child : 0,
                        harga: dataItem.harga,
                        diskon_nominal : dataItem.diskon_nominal,
                        diskon_persen : dataItem.diskon_persen
                    },
                ])
        );
        let data = {
			warehouse_id: parseInt(warehouse),
			username: username,
			code_retur: returcode,
			nominal_retur: nominalretur,
			customer_id: parseInt(customer),
			sales : sales,
			promo_total : promoglobal,
			ongkir : parseInt(ongkir),
			pajak_id: pajak,
			manual_address: alamatcustomer,
			keterangan_jasa : namajasa,
			nominal_jasa: parseInt(jasa),
			pengiriman: parseInt(pengiriman),
			payment_method: parseInt(payment_method),
			jangka_waktu: "",
			keterangan: "",
			pay_1: parseInt(pay1),
			payment_method1: parseInt(payment_method1),
			pay_2: parseInt(pay2),
			payment_method2: parseInt(payment_method2),
			pay_3: 0,
			payment_method3: 0,
			keterangan3: "0",
			pay_4: 0,
			payment_method4: 0,
			keterangan4: "0",
			pay_5: 0,
			payment_method5: 0,
			keterangan5: "0",
			pay_6: 0,
			payment_method6: 0,
			keterangan6: "0",
			promo : dataPromos,
			items: dataItems,
		};
        axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/pos-cashier/close/${notakasirid}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(function (response) {
                // history.push("/admin/pelunasan");
            })
            .catch(function (error) {
                console.log(error);
            });
  }

  function UpdateKasirTerakhir() {
	if (-1 * parseInt(grandtotal) + parseInt(pay1) + parseInt(pay2) < -100000000) {
		alert("Nominal Belum Mencukupi");
	} else {
		let dataPromos = [];
		savedPromos.map(
			(dataPromo) =>
				(dataPromos = [
					...dataPromos,
					{
						code_promo: dataPromo.code_promo,
						potongan_promo : dataPromo.potongan_promo
					},
				])
		);
		let dataItems = [];
		savedItems.map(
			(dataItem) =>
				(dataItems = [
					...dataItems,
					{
						item_id: dataItem.item_id,
						qty: dataItem.qty,
						harga: dataItem.harga,
						diskon_nominal : dataItem.diskon_nominal,
						diskon_persen : dataItem.diskon_persen
					},
				])
		);
		let data = {
			warehouse_id: parseInt(warehouse),
			username: username,
			code_retur: returcode,
			nominal_retur: nominalretur,
			customer_id: parseInt(customer),
			keterangan_jasa : namajasa,
			sales : sales,
			ongkir : parseInt(ongkir),
			pajak_id: pajak,
			promo_total : promoglobal,
			manual_address: alamatcustomer,
			nominal_jasa: parseInt(jasa),
			pengiriman: parseInt(pengiriman),
			payment_method: parseInt(payment_method),
			jangka_waktu: "",
			keterangan: "",
			pay_1: parseInt(pay1),
			payment_method1: parseInt(payment_method1),
			pay_2: parseInt(pay2),
			payment_method2: parseInt(payment_method2),
			pay_3: 0,
			payment_method3: 0,
			keterangan3: "0",
			pay_4: 0,
			payment_method4: 0,
			keterangan4: "0",
			pay_5: 0,
			payment_method5: 0,
			keterangan5: "0",
			pay_6: 0,
			payment_method6: 0,
			keterangan6: "0",
			promo:dataPromos,
			items: dataItems,
		};
		simpanCetakSoKasir(data);
		cekButton();
	}
}

	// fungsi Cek Value Promo
  function CekValuePromo() {
	let dataPromos = [];
		savedPromos.map(
			(dataPromo) =>
				(dataPromos = [
					...dataPromos,
					{
						code_promo: dataPromo.code_promo,
						potongan_promo : dataPromo.potongan_promo
					},
				])
		);
	let dataItems = [];
	savedItems.map(
		(dataItem) =>
			(dataItems = [
				...dataItems,
				{
					item_id: dataItem.item_id,
					qty: dataItem.qty,
					harga: dataItem.harga,
					code_promo: dataItem.code_promo,
					parent_child : dataItem.parent_child,
					type : dataItem.type,
					diskon_nominal : dataItem.diskon_nominal,
					diskon_persen : dataItem.diskon_persen
				},
			])
	);
	let data = {
		nota_code: codenota,
		warehouse_id: parseInt(warehouse),
		payment_method: parseInt(payment_method),
		promo : dataPromos,
		items: dataItems,
	};
	axios
		.post(`${process.env.REACT_APP_API_BASE_URL}/promo/cashier-calculation`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		.then(function (response) {
			// setPromoGlobal(response.data.response.potongan_promo)
		})
		.catch(function (error) {
			console.log(error);
		});
	}

	function CekItemPromo() {
	let dataPromos = [];
		savedPromos.map(
			(dataPromo) =>
				(dataPromos = [
					...dataPromos,
					{
						code_promo: dataPromo.code_promo,
						potongan_promo : dataPromo.potongan_promo
					},
				])
		);
	let dataItems = [];
	savedItems.map(
		(dataItem) =>
			(dataItems = [
				...dataItems,
				{
					item_id: dataItem.item_id,
					qty: dataItem.qty,
					code_promo: dataItem.code_promo,
					type : dataItem.type,
					harga: dataItem.harga,
					parent_child : dataItem.parent_child,
					diskon_nominal : dataItem.diskon_nominal,
					diskon_persen : dataItem.diskon_persen
				},
			])
	);
	let data = {
		nota_code: codenota,
		warehouse_id: parseInt(warehouse),
		payment_method: parseInt(payment_method),
		promo : dataPromos,
		items: dataItems,
	};
	axios
		.post(`${process.env.REACT_APP_API_BASE_URL}/promo/cashier-calculation-items`, data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}).then(async response => {
			let stateItem = [];
			await Promise.all(response.data.response.map(async (data) => {
				stateItem = [...stateItem, {
					item_id: data.item_id,
					item_name:data.item_name,
					item_code : data.item_code,
					satuan : data.satuan,
					code_promo: data.code_promo,
					type : data.type,
					qty: data.qty,
					parent_child : data.parent_child,
					harga: data.harga,
					diskon_persen: data.diskon_persen,
					diskon_nominal: data.diskon_nominal,
					data: {
						item_name: data.item_name,
						harga: data.harga,
						code_promo: data.code_promo,
						type : data.type,
						parent_child : data.parent_child,
						diskon_persen: data.diskon_persen,
						diskon_nominal: data.diskon_nominal,
						qty: data.qty,
					},
				}];
			}));
			setSavedItems(stateItem);
		})
	}

	  useEffect(() => {
		getPenawaranSo();
	  }, []);	  
	
	  // fungsi badge untuk mengetahui banyaknya pending order
	  const getPenawaranSo = async () => {
		let filter = { 
		  active_flag: 4,
		  username : username
		};
		const data = filter;
		const headers = {
		  "Content-Type": "application/json",
		  Authorization: `Bearer ${token}`,
		};
		axios
		  .post(`${process.env.REACT_APP_API_BASE_URL}/pos-cashier/page`, data, {
			headers,
		  })
		  .then((data) => {
			setTotalItem(data.data.total_item);
			setListItemPending(data.data.response);
		  })
		  .catch(function (error) {
			console.log(error);
		  });
	  };


	const handleEditQty = (index, value) => {
		let updateList = savedItems;
		let minTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
		let plusTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * value ;
		let aqtyTotala = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal))* value ;
		let aqtyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
		let diskonnominal1 = parseInt(updateList[index].diskon_nominal) * value ;
		let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
		
		if(!isNaN(value)&&value.length>0){
		  updateList[index] = {...updateList[index], qty: value};
		  setSavedItems(updateList);
		  setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
		  // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
		}else{return false}
	  }
	  
	  const handleEditHarga = (index, value) => {
		if(!isNaN(value)&&value.length>0){
		  let updateList = savedItems;
		   let aqtyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal))* parseInt(updateList[index].qty)  ;
		  updateList[index] = {...updateList[index], harga: value};
		  setSavedItems(updateList);
		  let aqtyTotala = parseInt(updateList[index].qty) * (value  - parseInt(updateList[index].diskon_nominal));
		  setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
		}else{return false}
	  }
	  
	  const handleEditDiskonNominal = (index, value) => {
		if(!isNaN(value)&&value.length>0){
		  let updateList = savedItems;
		  let diskonnominal1 = parseInt(updateList[index].qty) * value ;
		  let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
		  let persentasediskonnya = value / parseInt(updateList[index].harga) * 100;
		  let aqtyTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
		  updateList[index] = {...updateList[index], diskon_nominal: value, diskon_persen: persentasediskonnya};
		  setSavedItems(updateList);
		 let aqtyTotala = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty );
		  setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
		  // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
		}else{return false}
	  }
	  
	  const handleEditDiskonPersen = (index, value) => {
		if(!isNaN(value)&&value.length>0){
		  let updateList = savedItems;
		  let  nominaldiskonnya = parseInt(updateList[index].harga) * value/100;
		  let aqtyTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
		  updateList[index] = {...updateList[index], diskon_nominal: nominaldiskonnya, diskon_persen: value};
		  setSavedItems(updateList);
		 let qatyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty)  ;
		  setTotalQty(qtyTotal + qatyTotal - aqtyTotal);
		  let diskonnominal1 = parseInt(updateList[index].qty) * value ;
		  let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
		  // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2 )
		}else{return false}
	  }
	  
	  // save
	  const saveItemOrUpdate = (item) => {
		let oldobj = savedItems.find(o => o.item_id === item.id);
		if(oldobj===undefined){
		  setSavedItems([...savedItems,{
			item_id: item.id,
			item_name: item.item_name,
			item_code:item.item_code,
			qtysisa : item.stock,
			satuan : item.satuan,
			barcode: item.barcode,
			qty: 1,
			diskon_nominal : item.diskon_nominal,
			diskon_persen:  item.diskon_persen,
			harga: item.price,
		  }]);
		  return setTotalQty(qtyTotal + (item.price - item.diskon_nominal) * 1)
		  // setDiskonTotal(diskontotal + diskonglobalnominal * 1)
		}else{
		let index = savedItems.findIndex(e=>e.item_id===item.id);
		let updateList = savedItems;
		let qtyTotall = parseInt(updateList[index].harga) * parseInt(updateList[index].qty);
		let diskontotall = parseInt(updateList[index].diskon_nominal) * parseInt(updateList[index].qty);
		let qtyupdate = parseInt(updateList[index].qty)+parseInt(1);
		updateList[index] = {...updateList[index], qty: qtyupdate};
		let minTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ; 
		setSavedItems(updateList);
		// let fixQty = qtyTotal - qtyTotall ;
		let fixQty = qtyTotal - minTotal ;
		let fixDiskon = diskontotal - diskontotall ;
		return setTotalQty(fixQty + (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal))  * qtyupdate),
		setDiskonTotal(fixDiskon + parseInt(updateList[index].diskon_nominal) * qtyupdate)
		};
	  }


	useEffect(() => {
        getProvince();
      }, []);
    
      const getProvince = () => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/province/list`, { headers
        })
        .then(data => {
            setProvinces(data.data.response_data)
        })
          .catch(function (error) {
            console.log(error)
        })
      }
    
      const getCity = (provinceId) => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/city?province_id=${provinceId}`, { headers
        })
        .then(data => {
            setCities(data.data.response_data)
        })
          .catch(function (error) {
            console.log(error)
          })
      }
	  

	// barcode
	const handleBarcodeSubmit = (e) =>{
		e.preventDefault();{
			EditData()
			setBarcode("");
		}
		if(barcode.length>0){
			axios
				.get(
					`${process.env.REACT_APP_API_BASE_URL}/items-by-barcode?barcode=${barcode}&warehouse_id=${warehouse}&qty=1
	                `,
					{ headers }
				)
				.then(async (response) => {
					let item = [];
					item.push({
						id: response.data.response.id,
						image_url : response.data.response.image_url,
						item_name: response.data.response.item_name,
						code_promo: response.data.response.code_promo,
						parent_child : response.data.response.parent_child,
						type : response.data.response.type,
						qtysisa : response.data.response.stock,
						item_code:response.data.response.item_code,
						barcode: response.data.response.barcode,
						price: response.data.response.harga,
				});
					saveItemOrUpdate(item[0]);
				})
				.catch(error => {
					console.log('DATA TIDAK DITEMUKAN');
				});
		}else{
			return false;
		}
		
	};

	// barcode
	const handlePromoSubmit = (e) =>{
		e.preventDefault();{
			EditData();
			setPromo("");
		}
		if(promo.length>0){
			axios
				.get(
					`${process.env.REACT_APP_API_BASE_URL}/promo/get-by-cabang?code_promo=${promo}&warehouse_id=${warehouse}
	                `,
					{ headers }
				)
				.then(async (response) => {
					let promos = [];
					promos.push({
						promo_code : response.data.response.promo_code,
						code_promo : response.data.response.promo_code,
						potongan_promo : response.data.response.promo_global_nominal

				});
					savePromoOrUpdate(promos[0]);
				})
				.catch(error => {
					console.log('DATA TIDAK DITEMUKAN');
				});
		}else{
			return false;
		}
		
	};

	const handleReturSubmit = (e) =>{
		e.preventDefault();{
			EditData();
		}
		if(returcode.length>0){
			axios
				.get(
					`${process.env.REACT_APP_API_BASE_URL}/cashier-retur/check-value/${returcode}`,
					{ headers }
				)
				.then(async (response) => {
					setNominalRetur(response.data.response)
				})
				.catch(error => {
					console.log('DATA TIDAK DITEMUKAN');
					setReturCode("");
				});
		}else{
			return false;
		}
		
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		{
			setBarcode("");
			setQueryy("");
			setIsSearchShoww("");
			return true
		}
	};


	function CreateDataCustomer() {
        let data = {
          name: namecustomer,
          active_flag: 1,
          province: province,
          city: city,
          address: address,
          phone: phoneNumber,
          email: email,
		  price_type : pricetype,
        }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/customer/save`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
          .then(function (response) {
            getCustomer();
            setIsOpenCustomer(!isOpenCustomer)

          })
          .catch(function (error) {
            console.log(error)
          })
      }

	const handleCreateCustomer = async (e) => {
        e.preventDefault();
        {
            CreateDataCustomer();
            setNameCustomer("");
            setAddress("");
            setEmail("");
            setCity("");
            setProvince("");
            setPhoneNumber("");
			setPriceType("");
        }
      } 

	  const deleteItem = (id) => {
		let array = [...savedItems];
	
		let index = array.findIndex(object => {
		  return object.item_id === id;
		});
	
		if (index !== -1) {
		  setTotalQty(qtyTotal - (array[index].harga - array[index].diskon_nominal) * array[index].qty) ;
		  setDiskonTotal(diskontotal - array[index].diskon_nominal * array[index].qty);
		  array.splice(index, 1);
		  setSavedItems(array);
		}
	  }

	  const simpanEdit = async () => {
		let oldobj = savedItems.find(o => o.item_id === itemIDActive);
		if(oldobj===undefined){}else{
		  let index = savedItems.findIndex(e=>e.item_id===itemIDActive);
		  let updateList = savedItems;
		  updateList[index] = {...updateList[index], edit_item: false};
		  setSavedItems(updateList);
		}
	  }

	const deletePromo = (id) => {
		let array = [...savedPromos];

		let index = array.findIndex(object => {
			return object.code_promo === id;
		});

		if (index !== -1) {
			setPromoGlobal(promoglobal - array[index].potongan_promo)
			array.splice(index, 1);
			setSavedPromos(array);
		}
	}

	const formatRupiah = (money) => {
		return new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR", minimumFractionDigits: 0 }).format(money);
	};

	const pricejasa = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setJasa(value || ' ');
																
	  };

	  const priceNominalRetur = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setNominalRetur(value || ' ');
																
	  };

	  const pricePromoNominal1 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setDiskonGlobalNominal1(value || ' ');
																
	  };

	  const pricepay1 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPay1(value || ' ');
																
	  };

	  const pricepay2 = (e) => {
		const value = e === undefined ? 'undefined' : e;
		setPay2(value || ' ');
																
	  };
	

	return (
		<>
		{alert}
			<SimpleHeader name="Cashier" parentName="SO" />
			<Container className="mt--6" fluid>
				<Row>
					<div className="col">
                        <Row md="12">
                            <Col md="9">
								<Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
										<div style={{ display: "flex", justifyContent: "space-between" }}>
                                            <div style={{ textAlign: 'left' }}>
                                                <h1>{codenota}</h1>
                                            </div>
											<div style={{ textAlign: 'right' }}>
                                                <Button color="warning" onClick={() => {displayCekHarga();}}> Cek Harga <i className="fas fa-check" /> </Button>
                                                <Button color="info" onClick={() => {Newkasir();}}> New <i className="fas fa-plus" /> </Button>
												<Button color="danger" onClick={toggle} hidden={field === 2}> Closing Cashier </Button>
												<Button hidden color="danger" outline onClick={handleClick}> Klik Fungsi EditData </Button>
												<Button hidden color="danger" outline ref={cekPromo} onClick={()=>{CekValuePromo();CekItemPromo();UntukCekPromo()}}>Klik Cek promo </Button>
												<Button hidden color="danger" outline onClick={() => UpdateKasir()} ref={inputRef}> Klik Updata Data </Button>
												<Button hidden color="danger" outline onClick={handlepending}> Fungsi Handle Klik get Nota Kasir </Button>
												<Button hidden color="danger" outline ref={CekPending} onClick={()=>{getKasirId()}}> Klik ambil Id Nota Kasir </Button>
											</div>
										</div>
                                    </CardHeader>
                                    <CardBody response>
										<Row md="8">
											<Col md="7">
												<FormGroup row>
														<textarea
															className="form-control"
															disabled
															style={{
																fontWeight: 'bold',
																fontSize: 50,
																paddingTop: 20,
																top: "50%",
																height: 120,
																resize: "none",
															}}
															value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}>
														</textarea>
												</FormGroup>
											</Col>
											<Col md="5">
												<FormGroup>
													<Button
														hidden={field === 1}
														disabled={editable === true}
														style={{
															fontSize: 40,
															paddingTop: 10,
															height: 120,
															resize: "none",
															}}
														block
														color="primary"
														onClick={() => setIsOpen(!isOpen)}>
															Bayar
													</Button>
												</FormGroup>
											</Col>
										</Row>
										<Row md="8">
											<Col md="4">
												<FormGroup row>
													<Col sm={12}>
														<Label style={{ color: "darkgray", fontSize: "14px" }}>List Promo</Label>
															<Form onSubmit={handlePromoSubmit}>
																			<Input valid
																			disabled={field === 1}
																			outline
																			autoComplete="off"
																			placeholder="Cek Promo"
																			type="text"
																			value={promo}
																			onChange={(e) => {
																				setPromo(e.target.value);
																			}}
																			autoFocus
																		/>
															</Form>
													</Col>
												</FormGroup>
											</Col>
											<Col md="4">
												<FormGroup row>
													<Col sm={12}>
														<Label style={{ color: "darkgray", fontSize: "14px" }}>Promo (Nominal)</Label>
														<InputGroup>
															<InputGroupText>
															Rp
															</InputGroupText>
															<CurrencyInput
															disabled
																id="validation-example-2-field"
																// placeholder="Rp0"
																allowDecimals={false}
																value={diskonglobalnominal1}
																onValueChange={pricePromoNominal1}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
														</InputGroup>
													</Col>
												</FormGroup>
											</Col>
											<Col md="4">
												<FormGroup row>
													<Col sm={12}>
														<Label style={{ color: "darkgray", fontSize: "14px" }}>Pajak</Label>
														<Input
															disabled={field === 1}
															autoComplete="off"
															type="select"
															value={pajak}
															onChange={(e) => {
																setPajak(e.target.value);
															}}>
															<option value="" disabled selected hidden>Pilih PPN</option>
															{allpajak.map((ppn, key) => {
																return (
																	<option key={key} value={ppn.id}>
																		{ppn.keterangan}
																	</option>
																);
															})}
														</Input>
													</Col>
												</FormGroup>
											</Col>
											<Col md="2">
											</Col>
										</Row>
										<Row md="8">
											<Col md="4">
												<FormGroup row>
													<Col sm={12}>
															<Form onSubmit={handleReturSubmit}>
																			<Input valid
																			disabled={field === 1}
																			outline
																			autoComplete="off"
																			placeholder="Cek Retur"
																			type="text"
																			value={returcode}
																			onChange={(e) => {
																				setReturCode(e.target.value);
																			}}
																			autoFocus
																		/>
															</Form>
													</Col>
												</FormGroup>
											</Col>
											<Col md="4">
												<FormGroup row>
													<Col sm={12}>
														<InputGroup>
															<InputGroupText>
															Rp
															</InputGroupText>
															<CurrencyInput
															disabled
																id="validation-example-2-field"
																// placeholder="Rp0"
																allowDecimals={false}
																value={nominalretur}
																onValueChange={priceNominalRetur}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
														</InputGroup>
													</Col>
												</FormGroup>
											</Col>
											{pengiriman >  1  ?(
											<Col md="4">
												<FormGroup row>
													<Col sm={12}>
														<InputGroup>
															<InputGroupText>
																Ongkir
															</InputGroupText>
															<Input
																disabled={field === 1}
																autoComplete="off"
																type="number"
																value={ongkir}
																onChange={(e) => {
																	setOngkir(e.target.value);
																}} />
														</InputGroup>
													</Col>
												</FormGroup>
											</Col>
											):(
												""
											)}
										</Row>
										{/* <Row md="8">
											<Col md="4">
												<FormGroup row>
													<Col sm={12}>
															<Input
																disabled={field === 1}
																autoComplete="off"
																placeholder="Masukan Jasa "
																type="text"
																value={namajasa}
																onChange={(e) => {
																	setNamaJasa(e.target.value);
																}}
															/>
													</Col>
												</FormGroup>
											</Col>
											<Col md="4">
												<FormGroup row>
													<Col sm={12}>
													<InputGroup>
														<InputGroupText>
															Jasa
														</InputGroupText>
														<CurrencyInput
																id="validation-example-2-field"
																// placeholder="Rp0"
																allowDecimals={false}
																value={jasa}
																onValueChange={pricejasa}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
														
													</InputGroup>
													</Col>
												</FormGroup>
											</Col>
											
											<Col md="2">
											</Col>
										</Row> */}
										<Row md="8">
											<Table>
												<thead>
													<tr>
														<th><b>Kode Promo : </b>
														{savedPromos.map((savedPromo) => {
															return (
														<Button color="danger" onClick={() => {EditData(); deletePromo(savedPromo.code_promo)}}>{savedPromo.code_promo} {savedPromo.potongan_promo} <i className="fas fa-trash" /></Button>
														
														)})}
														</th>
													</tr>
												</thead>
											</Table>
										</Row>
                                    </CardBody>
                                </Card>
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
											<div style={{ justifyContent: "space-between" }}>
												<div style={{ textAlign: 'right' }}>
													<Form onSubmit={handleBarcodeSubmit}>
														<Row md="12">
															<Col md="6">
																<Col sm={12}>
																	<Input valid
																	hidden={field === 1}
																	outline
																	autoComplete="off"
																	placeholder="Scan Barcode"
																	type="text"
																	value={barcode}
																	onChange={(e) => {
																		setBarcode(e.target.value);
																	}}
																	autoFocus
																/>
																</Col>
															</Col>
															<Col md="6">
																<Button color="info" onClick={toggless} hidden={field === 1}>
																	<i className="fas fa-list" />List Pending Order
																	<Badge color="danger" className="badge-md badge-circle badge-floating border-white">{totalitem}</Badge>
																</Button>
															</Col>
														</Row>
													</Form>
												</div>
											</div>
                                    </CardHeader>
                                    <CardBody>
                                        <Table responsive size="sm">
                                            <thead>
                                                <tr>
													<th><b></b></th>
                                                    <th><b>Nama Item</b></th>
                                                    <th><b>Kode Item</b></th>
                                                    <th><b>Harga&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                                    <th><b>Qty&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                                    <th><b>Diskon %</b></th>
                                                    <th><b>Diskon (N) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                                    <th><b>Sub Total</b></th>
                                                    <th>
                                                        {/* {editable?<Button color="warning" onClick={() => {EditData();setSimpan();setEditable(false)}} size="sm">Simpan</Button>
                                                        :<Button hidden={field === 1} onClick={() => setIsConfEditableOpen(true)} size="sm">Izinkan Edit</Button>} */}
                                                    </th>
                                                </tr>
                                            </thead>
                                                <tbody>
                                                {
                                                    savedItems.map((savedItem, key) => {
                                                        return (
                                                            <tr key={key}>
																<td>
																			<img height="100px" width="100px"
																			alt=""
																			src={savedItem.image_url}
																			/>
																</td>
                                                                <td>{savedItem.item_name}</td>
                                                                <td>{savedItem.item_code}</td>
                                                                <td>
                                                                {savedItem.edit_item ? (
																	 <FormGroup>
                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            placeholder="Diskon Persen"
                                                                            type="number"
                                                                            value={savedItem.harga}
                                                                            onChange={(e) => {
                                                                                handleEditHarga(key, e.target.value);
                                                                            }}
                                                                        />
																		</FormGroup>
                                                                    ) : (
                                                                        <>{formatRupiah(savedItem.harga)}</>
                                                                    )}
                                                                
                                                                </td>
                                                                <td>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        placeholder="qty"
                                                                        type="number"
																		// invalid={qtyError === "invalid"}
                                                                        value={savedItem.qty}
                                                                        onChange={(e) => {
                                                                                handleEditQty(key, e.target.value);
																				// if (savedItem.qty > savedItem.qtysisa) {
																				// 	setQtyError("");
																				//   }
                                                                        }}
																	/>
																	 <FormFeedback>
																		Max Barang <b>{savedItem.qtysisa}</b> 
																	</FormFeedback>
                                                                </td>
                                                                <td>
                                                                    {savedItem.edit_item ? (
                                                                        <Input
                                                                        className="form-control-alternative"
                                                                            placeholder="Diskon Persen"
                                                                            type="number"
                                                                            value={savedItem.diskon_persen}
                                                                            onChange={(e) => {
                                                                                handleEditDiskonPersen(key, e.target.value);
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <>{savedItem.diskon_persen}</>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {savedItem.edit_item ? (
                                                                        <Input
                                                                            className="form-control-alternative"
                                                                            placeholder="Diskon nominal"
                                                                            type="number"
                                                                            value={savedItem.diskon_nominal}
                                                                            onChange={(e) => {
                                                                                handleEditDiskonNominal(key, e.target.value);
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <>{formatRupiah(savedItem.diskon_nominal)}</>
                                                                    )}
                                                                </td>
                                                                <td>
																{/* {formatRupiah((((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) - ((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) * (ppn/100)))} */}
																{formatRupiah(((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) / (ppn/100 + 100/100))}

                                                                    {/* {formatRupiah((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty)} */}
                                                                </td>
                                                                <td>
																{savedItem.edit_item?(<Button color="primary" onClick={() => {EditData();setSimpan();setEditable(false);simpanEdit();}}><i className="fa fa-save" /></Button>
																):(<Button color="warning" onClick={() => {setIsConfEditableOpen(true);setItemIDActive(savedItem.item_id);}}><i className="fas fa-user-edit" /></Button>)}
																	
																	{/* {editable?<Button color="warning" onClick={() => {EditData();setSimpan();setEditable(false)}} size="sm">Simpan</Button>
                                                        :<Button hidden={field === 1} onClick={() => setIsConfEditableOpen(true)} size="sm">Izinkan Edit</Button>} */}
																	<Button color="danger"  onClick={() => {setButtonDelete(true);cekbuttondeleteitem(savedItem)}} ><i className="fas fa-trash" /></Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                                <tr>
                                                    <td colSpan="4">
                                                        <>
															<Form onSubmit={handleSubmit}>
																			<Input
																				hidden={field === 1}
																				autoComplete="off"
																				placeholder="Masukan Item Manual"
																				type="search"
																				value={queryy}
																				onChange={(e) => setQueryy(e.target.value)}
																			/>
																		{isSearchShoww && queryy && (
																		<Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
																			<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
																				<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
																			</div>
																			{allItemm?.response ? (
																				allItemm.response.map((item) => (
																						<CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {EditData();saveItemOrUpdate(item);setQueryy('');setIsSearchShoww(false);}}>
																							<div>
																								<b>Nama item:</b> {item.item_name}
																							</div>
																						</CardBody>
																				))
																			) : (
																				<div className="text-center mb-3 text-danger">Item "{queryy}" tidak ada bosku!</div>
																			)}
																		</Card>
																	)}
															</Form>
														</>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md="3">
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
										<div style={{ display: "flex", justifyContent: "space-between" }}>
											<h3>Transaksi</h3>
											<div style={{ textAlign: 'right' }}>
												<Button
													onClick={() => setIsOpenCustomer(!isOpenCustomer)}
													color="secondary"
													type="button">
													<i className="ni ni-fat-add"/> Customer
												</Button>
											</div>
										</div>
                                    </CardHeader>
                                    <CardBody response>
                                        <FormGroup row>
											<Col sm={12}>
												<Select2
													disabled={field === 1}
													value={customer}
													onChange={(e) => {
														setCustomer(e.target.value);
														getByCustomerAlamat(e.target.value);
													}}
													options={{ 
														placeholder: "Pilih Customer"
													}}
													data={customers.map((customer) => {
														return (
														{ id: customer.id, text: customer.name }
														);
													})}
												/>
											</Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col sm={12}>
												<Input
													disabled={field === 1}
													autoComplete="off"
													type="select"
													value={payment_method}
													onChange={(e) => {
														setPaymentMethod(e.target.value);
													}}>
													<option value="" selected disabled hidden>Jenis Transaksi</option>
													<option value={1} >
														Lunas
													</option>
													<option value={3} >
														Inden DP
													</option>
													<option value={4} >
														Inden Lunas
													</option>
												</Input>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Col sm={12}>
												<Input
													disabled={field === 1}
													autoComplete="off"
													type="select"
													value={pengiriman}
													onChange={(e) => {
														setPengiriman(e.target.value);
													}}>
													<option value="" selected disabled hidden>Pilih Pengiriman</option>
													<option value={1}>Ambil Sendiri</option>
													<option value={2}>
														Delivery
													</option>
													<option value={3}>Kurir</option>
												</Input>
                                            </Col>
                                        </FormGroup>
										{pengiriman >  1  ?(
												<FormGroup row>
												<Col sm={12}>
													<Input
														disabled={field === 1}
														autoComplete="off"
														placeholder="Masukan Alamat Kirim "
														type="text"
														value={alamatcustomer}
														onChange={(e) => {
															setAlamatCustomer(e.target.value);
														}}
													/>
												</Col>
											</FormGroup>
											):(
												""
											)
										}
										
                                        <hr />
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={5}
                                                size="small"
                                            >
                                                <b>Sub Total</b>
                                            </Label>
                                        <Col sm={7}>
                                            <Input
                                                className="form-control-alternative"
                                                disabled
                                                style={{fontWeight: 'bold'}}
                                                type="text"
                                                name="barcode"
                                                placeholder="Harga Total"
                                                value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                            />
                                        </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={5}
                                            >
                                                <b>Promo</b>
                                            </Label>
                                        <Col sm={7}>
                                            <Input
                                                className="form-control-alternative"
                                                style={{fontWeight: 'bold'}}
                                                disabled
                                                type="text"
                                                name="barcode"
                                                placeholder="Total Diskon"
                                                value={"Rp." + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                            />
                                        </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={5}
                                            >
                                                <b>PPN</b>
                                            </Label>
                                        <Col sm={7}>
                                            <Input
                                                className="form-control-alternative"
                                                disabled
                                                style={{fontWeight: 'bold'}}
                                                type="text"
                                                name="barcode"
                                                placeholder="PPN"
                                                value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                            />
                                        </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={5}
                                            >
                                                <b>Ongkir</b>
                                            </Label>
                                        <Col sm={7}>
                                            <Input
                                                className="form-control-alternative"
                                                disabled
                                                style={{fontWeight: 'bold'}}
                                                type="text"
                                                name="barcode"
                                                placeholder="PPN"
                                                value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                            />
                                        </Col>
                                        </FormGroup>
										{/* <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={5}
                                            >
                                                <b>Jasa</b>
                                            </Label>
											<Col sm={7}>
												<Input
													className="form-control-alternative"
													disabled
													style={{fontWeight: 'bold'}}
													type="text"
													name="barcode"
													placeholder="PPN"
													value={"Rp." + jasa1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
												/>
											</Col>
                                        </FormGroup> */}
                                        <FormGroup row>
                                            <Label
                                                for="exampleEmail"
                                                sm={5}
                                            >
                                                <b>Grand Total</b>
                                            </Label>
											<Col sm={7}>
												<Input
													className="form-control-alternative"
													disabled
													type="text"
													name="barcode"
													style={{fontWeight: 'bold'}}
													placeholder="Grand Total"
													value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
												/>
											</Col>
                                        </FormGroup>
										
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
					</div>
				</Row>
			</Container>

				{/* list pending order */}
				<Modal isOpen={modal6} toggle={toggless} style={{ minWidth: "50%", top: "-10%" }}>
                  <ModalHeader toggle={toggless} align="center"></ModalHeader>
                  <ModalBody align="center">
					<Container className="mt--6" fluid>
						<Row>
							<div className="col">
								<CardBody>
									<Card>
										<CardHeader className="bg-secondary border-0">
											<div
											style={{ display: "flex", justifyContent: "space-between" }}
											>
											<h3>Pending Order</h3>
											</div>
										</CardHeader>
										<CardBody>
											<ToolkitProvider
											rowNumber={rowIndex}
											data={listitempending}
											keyField="id"
											columns={[
												{
												dataField: "no",
												text: "#",
												sort: true,
												page: 1,
												formatter: (cell, row, index) => {
													let currentRow = ++index;
													return currentRow + rowIndex;
												},
												},
												{
													dataField: "nota_code",
													text: "kode Nota",
													sort: true,
												},
												{
													dataField: "", text: "", formatter: (cell, row, index) => {
													return (
														<ButtonGroup>
														<Button>
															<Link
															// to={redirectPrefix + row.id}
															id={"tooltip_" + row.id}
															onClick={() => {setIdNotaKasir(row.id);getDataPending();toggless();}}
															>
															<i className="fas fa-user-edit" />
															</Link>
														</Button>
														</ButtonGroup>
													)
													}
												},
											]}
											>
											{(props) => (
												<div className="py-4 table-responsive">
												<BootstrapTable
													remote
													{...props.baseProps}
													bootstrap4={true}
													bordered={false}
													hover={true}
													onTableChange={handleTableChange}
												/>
												</div>
											)}
											</ToolkitProvider>
										</CardBody>
									</Card>
								</CardBody>
							
							</div>
						</Row>
					</Container>
                  <Button color="secondary" onClick={toggless}>
                    Cancel
                  </Button>
                  </ModalBody>
                </Modal>

			{/* modal pembayaran */}
			<Modal toggle={() => setIsOpen(!isOpen)} isOpen={isOpen} fade={false} style={{ minWidth: "70%", top: "5%" }}>
				<ModalHeader toggle={() => setIsOpen(!isOpen)}>Metode Pembayaran</ModalHeader>
				<ModalBody
					cssModule={{
						alignText: "center",
					}}>
					<div className="container">
						<div className="row align-items-center mb-3">
							<div className="col-3">Nominal Pembayaran 1</div>
							<div className="col-1 text-center">:</div>
							<div className="col-4 text-center">
								{/* <Input className="form-control-alternative"
									type="number"
									value={pay1}
									placeholder="Masukan Nomminal Pembayaran"
									onChange={(event) => {
										setChange(parseInt(change) - parseInt(event.target.value));
										setPay1(event.target.value);
									}}></Input> */}
									<CurrencyInput
																id="validation-example-2-field"
																// placeholder="Rp0"
																allowDecimals={false}
																value={pay1}
																onValueChange={pricepay1}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
							</div>
							<div className="col-4 text-center">
								<Input
									// className="form-control-alternative"
									
									type="select"
									placeholder="Metode Pembayaran 1"
									value={payment_method1}
									onChange={(e) => {
										setPaymentMethod1(e.target.value);
									}}>
									<option value="">Pilih Metode Pembayaran</option>
									{banks.map((bank, key) => {
										return (
											<option key={key} value={bank.id}>
												{bank.bank_name}
											</option>
										);
									})}
								</Input>
							</div>
						</div>
						<div className="row align-items-center mb-3">
							<div className="col-3">Nominal Pembayaran 2</div>
							<div className="col-1 text-center">:</div>
							<div className="col-4 text-center">
							<CurrencyInput
																id="validation-example-2-field"
																// placeholder="Rp0"
																allowDecimals={false}
																value={pay2}
																onValueChange={pricepay2}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/>
								{/* <Input className="form-control-alternative" type="number" value={pay2} onChange={(event) => setPay2(event.target.value)} placeholder="Masukan Nomminal Pembayaran"></Input> */}
							</div>
							<div className="col-4 text-center">
								<Input 
									// className="form-control-alternative"	
									type="select"
									placeholder="Metode Pembayaran 2"
									value={payment_method2}
									onChange={(e) => {
										setPaymentMethod2(e.target.value);
									}}>
									<option value="">Pilih Metode Pembayaran</option>
									{banks.map((bank, key) => {
										return (
											<option key={key} value={bank.id}>
												{bank.bank_name}
											</option>
										);
									})}
								</Input>
							</div>
						</div>
						<div className="row align-items-center mb-3">
							<div className="col-3 text-start  display-1">Total</div>
							<div className="col-1 text-center">:</div>
							<div className="col-6 text-center">
								<textarea
									className="form-control"
									disabled
									value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
									style={{
										fontSize: 40,
										paddingTop: 20,
										top: "50%",
										height: 95,
                                        fontWeight: 'bold',
										resize: "none",
									}}></textarea>
							</div>
						</div>
						<div className="row align-items-center mb-3">
							<div className="col-3  display-3">Kembali</div>
							<div className="col-1 text-center">:</div>
							<div className="col-6 text-center">
								<textarea
									className="form-control"
									disabled
									value={"Rp." + (-1 * parseInt(grandtotal) + parseInt(pay1) + parseInt(pay2)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
									style={{
										fontSize: 40,
										paddingTop: 20,
                                        fontWeight: 'bold',
										top: "50%",
										height: 95,
										resize: "none",
									}}></textarea>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<Form onSubmit={handleformvalidasi}>
						<Button
							color="danger"
							outline
							disabled={parseInt(pay1) + parseInt(pay2) < 10}
							// onClick={() => {
							// 	if (-1 * parseInt(grandtotal) + parseInt(pay1) + parseInt(pay2) < -100000000) {
							// 		alert("Nominal Belum Mencukupi");
							// 	} else {
							// 		let dataPromos = [];
							// 		savedPromos.map(
							// 			(dataPromo) =>
							// 				(dataPromos = [
							// 					...dataPromos,
							// 					{
							// 						code_promo: dataPromo.code_promo,
							// 						potongan_promo : dataPromo.potongan_promo
							// 					},
							// 				])
							// 		);
							// 		let dataItems = [];
							// 		savedItems.map(
							// 			(dataItem) =>
							// 				(dataItems = [
							// 					...dataItems,
							// 					{
							// 						item_id: dataItem.item_id,
							// 						qty: dataItem.qty,
							// 						harga: dataItem.harga,
							// 						diskon_nominal : dataItem.diskon_nominal,
							// 						diskon_persen : dataItem.diskon_persen
							// 					},
							// 				])
							// 		);
							// 		let data = {
							// 			warehouse_id: parseInt(warehouse),
							// 			username: username,
							// 			code_retur: returcode,
							// 			nominal_retur: nominalretur,
							// 			customer_id: parseInt(customer),
							// 			keterangan_jasa : namajasa,
							// 			sales : sales,
							// 			ongkir : parseInt(ongkir),
							// 			pajak_id: pajak,
							// 			promo_total : promoglobal,
							// 			manual_address: alamatcustomer,
							// 			nominal_jasa: parseInt(jasa),
							// 			pengiriman: parseInt(pengiriman),
							// 			payment_method: parseInt(payment_method),
							// 			jangka_waktu: "",
							// 			keterangan: "",
							// 			pay_1: parseInt(pay1),
							// 			payment_method1: parseInt(payment_method1),
							// 			pay_2: parseInt(pay2),
							// 			payment_method2: parseInt(payment_method2),
							// 			pay_3: 0,
							// 			payment_method3: 0,
							// 			keterangan3: "0",
							// 			pay_4: 0,
							// 			payment_method4: 0,
							// 			keterangan4: "0",
							// 			pay_5: 0,
							// 			payment_method5: 0,
							// 			keterangan5: "0",
							// 			pay_6: 0,
							// 			payment_method6: 0,
							// 			keterangan6: "0",
							// 			promo:dataPromos,
							// 			items: dataItems,
							// 		};
							// 		simpanCetakSoKasir(data);
							// 		cekButton();
							// 	}
							// }}
							// type="submit"
							onClick={UpdateKasirTerakhir}
							>
							Konfirmasi Pembayaran
						</Button>{""}
					</Form>
					<Button onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
				</ModalFooter>
			</Modal>

			{/* Customer */}
            
            <Modal toggle={() => setIsOpenCustomer(!isOpenCustomer)} isOpen={isOpenCustomer} fade={false} style={{ minWidth: "70%", top: "-20%" }}>
				<ModalHeader toggle={() => setIsOpenCustomer(!isOpenCustomer)}>Buat Customer</ModalHeader>
                <Form onSubmit={handleCreateCustomer}>
                    <ModalBody
                        cssModule={{
                            alignText: "center",
                        }}>
                        <div className="container">
                            <div className="row align-items-center mb-3">
                                <div className="col-3">Nama</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-4 text-center">
                                    <Input className="form-control-alternative"
                                        type="text"
                                        value={namecustomer}
                                        placeholder="Masukan Nama Customer"
                                        onChange={(e) => 
                                        setNameCustomer(e.target.value)}>
                                    </Input>
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col-3">Provinsi</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-4 text-center">
                                    <Input
                                        autoComplete="off"
                                        name="Province"
                                        type="select"
                                        value={province}
                                        onChange={(e) => {
                                        setProvince(e.target.value);
                                        getCity(e.target.value);
                                        }}
                                    >
                                        <option value="">Pilih Province</option>
                                        {
                                        provinces.map((prov, key) => {
                                            return <option key={key} value={prov.id}>{prov.name}</option>
                                        })
                                        }
                                    </Input>
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col-3">Kota</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-4 text-center">
                                    <Input
                                        autoComplete="off"
                                        name="Kota"
                                        type="select"
                                        value={city}
                                        onChange={(e) => {
                                        setCity(e.target.value);
                                        }}
                                    >
                                        <option value="">Pilih Kota</option>
                                        {
                                        cities.map((city, key) => {
                                            return <option key={key} value={city.id}>{city.name}</option>
                                        })
                                        }
                                    </Input>
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col-3">Alamat</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-4 text-center">
                                    <Input
                                        autoComplete="off"
                                        type="text"
                                        placeholder="Masukan Alamat"
                                        value={address}
                                        onChange={(e) => {
                                        setAddress(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col-3">Phone</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-4 text-center">
                                    <Input
                                        autoComplete="off"
                                        type="text"
                                        placeholder="Masukan Nomor Telpon"
                                        value={phoneNumber}
                                        onChange={(e) => {
                                        setPhoneNumber(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col-3">Email</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-4 text-center">
                                    <Input
                                        autoComplete="off"
                                        type="text"
                                        placeholder="Masukan Email"
                                        value={email}
                                        onChange={(e) => {
                                        setEmail(e.target.value);
                                        }}
                                    />
                                </div>
                            </div>
							<div className="row align-items-center mb-3">
                                <div className="col-3">Tipe Harga</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-4 text-center">
                                    <Input
                                        autoComplete="off"
                                        type="select"
                                        value={pricetype}
                                        onChange={(e) => {
                                        setPriceType(e.target.value);
                                        }}
                                    >
										<option value="" selected hidden>Pilih Level Harga</option>
										<option value="1" >Level 1</option>
										<option hidden value="2" >Level 2</option>
										<option hidden value="3" >Level 3</option>
										<option hidden value="4" >Level 4</option>
										<option hidden value="5" >Level 5</option>
										</Input>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="danger"
                            outline
                            type="submit"
                            >
                            Tambahkan
                        </Button>{" "}
                        <Button onClick={() => setIsOpenCustomer(!isOpenCustomer)}>Cancel</Button>
                    </ModalFooter>
                </Form>
			</Modal>

			{/* modal konfirmasi editable */}
			<Modal isOpen={isConfEditableOpen} fade={false} style={{ minWidth: "30%", top: "10%" }}>
				<ModalHeader toggle={() => setIsConfEditableOpen(!isConfEditableOpen)}>Masukan Username Dan Password</ModalHeader>
				<ModalBody
					cssModule={{
						alignText: "center",
					}}>
						<Form onSubmit={handleEditItem}>
						<Row className="justify-content-center mt-4">
								<Col xs={7}>
									<Input
										autoComplete="off"
										placeholder="Masukan Username"
										type="text"
										value={nameotorisasi}
										invalid={nameotorisasiError === "invalid"}
										onChange={(e) => {
											setNameOtorisasi(e.target.value);
											if (e.target.value !== "") {
											setNameOtorisasiError("");
											}
										}}
									/>
								</Col>
							</Row>
							<Row className="justify-content-center mt-4">
								<Col xs={7}>
									<Input
										autoComplete="off"
										placeholder="Masukan Password Otorisasi"
										type="password"
										value={confPassEditable}
										onChange={(e) => setConfPassEditable(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="justify-content-center mt-4">
								<Col xs={7}>
										<Input
                                              autoComplete="off"
                                              type="select"
											  value={keterangan}
											  invalid={keteranganError === "invalid"}
											  onChange={(e) => {
												  setKeterangan(e.target.value);
												  if (e.target.value !== "") {
												  setKeteranganError("");
												  }
											  }}>
                                           <option value="" disabled hidden selected>Pilih Alasan</option>
                                              {reasons.map((reason, key) => {
                                                  return (
                                                  <option key={key} value={reason.description}>
                                                      {reason.description}
                                                  </option>
                                                  );
                                              })}
                                        </Input>
									<br/>
									<Button color="primary" type="submit">CEK <i className="fa fa-search" /></Button>
								</Col>
							</Row>
						</Form>
				</ModalBody>
			</Modal>

			{/* modal Deleye editable */}
			<Modal isOpen={buttondelete} fade={false} style={{ minWidth: "30%", top: "10%" }}>
				<ModalHeader toggle={() => setButtonDelete(!buttondelete)}>Masukan Username Dan Password</ModalHeader>
				<ModalBody
					cssModule={{
						alignText: "center",
					}}>
						<Form onSubmit={handleButtonDelete}>
						<Row className="justify-content-center mt-4">
								<Col xs={7}>
									<Input
										autoComplete="off"
										placeholder="Masukan Username"
										type="text"
										value={nameotorisasi}
										invalid={nameotorisasiError === "invalid"}
										onChange={(e) => {
											setNameOtorisasi(e.target.value);
											if (e.target.value !== "") {
											setNameOtorisasiError("");
											}
										}}
									/>
								</Col>
							</Row>
							<Row className="justify-content-center mt-4">
								<Col xs={7}>
									<Input
										autoComplete="off"
										placeholder="Masukan Password Otorisasi"
										type="password"
										value={confPassEditable}
										onChange={(e) => setConfPassEditable(e.target.value)}
									/>
								</Col>
							</Row>
							<Row className="justify-content-center mt-4">
								<Col xs={7}>
										<Input
                                              autoComplete="off"
                                              type="select"
											  value={keterangan}
											  invalid={keteranganError === "invalid"}
											  onChange={(e) => {
												  setKeterangan(e.target.value);
												  if (e.target.value !== "") {
												  setKeteranganError("");
												  }
											  }}>
                                           <option value="" disabled hidden selected>Pilih Alasan</option>
                                              {reasons.map((reason, key) => {
                                                  return (
                                                  <option key={key} value={reason.description}>
                                                      {reason.description}
                                                  </option>
                                                  );
                                              })}
                                        </Input>
									<br/>
									<Button color="primary" type="submit">CEK <i className="fa fa-search" /></Button>
								</Col>
							</Row>
						</Form>
				</ModalBody>
			</Modal>

			{/* modal kembalian */}
			<Modal isOpenkembaliancok={isKembalianOpen} fade={false} style={{ minWidth: "20%", top: "-15%" }}>
				<ModalHeader toggle={() => setIsOpenKembalianCok(!isOpenkembaliancok)} style={{
						alignText: "center",
						alignItems:"center"
					}}>Kembalian</ModalHeader>
				<ModalBody
					style={{
						alignText: "center",
						alignItems:"center"
					}}>
							<div className="container">
								<p style={{fontSize:'50px', fontFamily:'Tahoma'}}><strong>{"Rp." + (-1 * parseInt(grandtotal) + parseInt(pay1) + parseInt(pay2)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}</strong></p>
							</div>
				</ModalBody>
			</Modal>

			<Modal isOpen={isPackingList} style={{ minWidth: "65%", top: "5%" }}>
			<ModalHeader toggle={() => setIsOpen(!isOpen)} style={{
						alignText: "center",
						alignItems:"center"
					}}>Kembalian</ModalHeader>
				<ModalBody
					style={{
						alignText: "center",
						alignItems:"center"
					}}>
							<div className="container">
								<p style={{fontSize:'50px', fontFamily:'Tahoma'}}><strong>{"Rp." + (-1 * parseInt(grandtotal) + parseInt(pay1) + parseInt(pay2)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}</strong></p>
							</div>
				</ModalBody>
				<ModalBody>
					<CardBody
					className="border"
					id="targetContent"
					style={{ width: "100%" }}
					>
					<div className="col">
					</div>
					<div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
									<Row md="12">
										<Col md="12" style={{
						alignText: "center",
						alignItems:"center"
					}}>
											<h3><b>RAK BARANG</b></h3>
										</Col>
									</Row>
									<div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
									<br></br>
									{/* <Row md="12">
										<Col md="5">
										<div className="row align-items-center mb-1">
											<div className="col-3">Tanggal</div>
											<div className="col-1 text-center">:</div>
											<div className="col-4 ">
												<b>{waktutransaksi}</b>
											</div>
										</div>
										<div className="row align-items-center mb-1">
											<div className="col-3">Nota</div>
											<div className="col-1 text-center">:</div>
											<div className="col-5 ">
												<b>{codepo}</b>
											</div>
										</div>
										</Col>
									</Row> */}
								<CardBody>
								<br></br>
								<Table size="sm" responsive>
									<thead>
									<tr>
									<th>
										<b>Kode Rak</b>
										</th>
										<th>
										<b>Nama Item</b>
										</th>
										<th>
										<b>Kode Item</b>
										</th>
										<th>
										<b>Barcode</b>
										</th>
										<th>
										<b>Qty</b>
										</th>
										<th>
										<b>Satuan</b>
										</th>
									</tr>
									</thead>
									<tbody>
									{
										savedItemsPackingList.map((savedItem, key) => {
											return (
												<tr key={key}>
													<td>{savedItem.code_rak}</td>
													<td>{savedItem.data.item_name}</td>
													<td>{savedItem.item_code}</td>
													<td>{savedItem.barcode}</td>
													<td>{savedItem.qty}</td>
													<td>{savedItem.satuan}</td>
												</tr>
											)
										})
									}
									</tbody>
								</Table>
								</CardBody>
								<div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
					</CardBody>
				</ModalBody>
			</Modal>

			{openModalCetak && (
            <ModalCekHarga
              open={openModalCetak}
              toggle={toggle1}
            />
          )}

			{/* modal Closing Kasir */}
			<Modal isOpen={modal} toggle={toggle} 
			// contentClassName="bg-gradient-info"
			>
				<ModalHeader toggle={toggle} align="center"></ModalHeader>
				<ModalBody align="center">
				<font size="5"><b>Apakah Anda Sudah Yakin Ingin Closing ?</b></font><br></br><br></br><br></br>
				<Button color="secondary" onClick={toggle}>
				Cancel
				</Button>
				<Link className="btn btn-primary" to="/admin/kasir-sales-order/detail">
					Lanjutkan
				</Link> 
				</ModalBody>
			</Modal> 

			{/* cetak */}
			{<div style={{visibility:'hidden', display:'none'}}>
			<ViewCetakSoKasir
				namapaymentmethod={namapaymentmethod}
				nonota={codenota}
				kasir={username}
				today={waktu}
				customer={customer} 
				alamatcustomer={alamatcustomer} 
				detailpembelian={savedItems}
				total={"Rp. " + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				promo = {"Rp. " + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				// diskon={"Rp. " + totaldiskon1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				ppn={"Rp. " + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				ongkir={"Rp. " + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				jasa={"Rp. " + jasa1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				grandtotal={"Rp. " + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				// pembulatan={"Rp. " + selisihbulat.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				// totalasli={"Rp. " + bulat.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				dibayar={"Rp. " + (parseInt(pay1) + parseInt(pay2)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				kembali={"Rp. " + (- 1 * parseInt(grandtotal) + parseInt(pay1) + parseInt(pay2)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
				
				ref={componentCetakSoKasir}/>
			</div>}
		</>
	);
}