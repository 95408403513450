/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Col,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment";
import Select2 from "react-select2-wrapper";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";


export default function CreateUser() {
  const allInfo = JSON.parse(localStorage.allinfo);
  const users = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Users").map((p) => p.read_access));
  const [alert, setAlert] = React.useState(null);
  const token = localStorage.token;
  let history = useHistory();
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(12345);
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [tglrekrut, setTglRekrut] = useState(null);
  const [warehousepusat, setWarehousePusat] = useState("");
  const [warehousepusats, setWarehousePusats] = useState([]);
  const [grup, setGrup] = useState("")
  const [unit, setUnit] = useState("")
  const [units, setUnits] = useState([])

  useEffect(() => {
    getPusat();
  }, []);

  const getPusat = () => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`, { headers
    })
    .then(data => {
      setWarehousePusats(data.data.response);
    })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getUnit = (id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/unit/list/${id}`, { headers
    })
    .then(data => {
      setUnits(data.data.response);
    })
      .catch(function (error) {
        console.log(error)
      })
  }


  function CreateData() {
    let data = {
      warehouse_id: parseInt(warehousepusat),
      username: username,
      name: name,
      email: email,
      password: password,
      gender: parseInt(gender),
      is_karyawan:1,
      role: [2],
      recruit : moment(tglrekrut).format("YYYY-MM-DD"),
      address: address,
      email : email,
      grup_id : grup,
      unit_id : unit
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/users/register`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setTimeout(() => (history.push("/admin/user")), 1000);
      })
      .catch(function (error) {
          setGagalAlert(error.response.data.message);
      });
      };
      
      const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
      }

      const setGagalAlert = (id) => {
      setAlert(
        <SweetAlert
          danger
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
          />
        );
      };

      const setQuestionAlert = () => {
      setAlert(
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Lanjutkan"
          confirmBtnBsStyle="danger"
          title="Apakah Kamu Yakin?"
          onConfirm={CreateData}
          onCancel={hideAlert}
          focusCancelBtn
        />
        );
      };

      const hideAlert = () => {
      setAlert(null);
      }

  return (
    <>
    {alert}
    {users && users === "1" ? (
      <div>
      <SimpleHeader name="Buat User" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                    <h3>Buat User</h3>
                  </CardHeader>
                  <Row md="12">
										<Col md="6">
                      <CardBody>
                      <FormGroup row>
                              <Label
                              className="form-control-label"
                              for="exampleEmail" sm={4}>
                                  Tanggal Rekrut
                              </Label>
                              <Col sm={7}>
                                  <Input
                                    autoComplete="off"
                                      className="form-control-alternative"
                                      type="date"
                                      value={tglrekrut}
                                      onChange={(e) => {
                                          setTglRekrut(e.target.value);
                                      }}
                                  />
                              </Col>
                          </FormGroup>
                      <FormGroup row>
                            <Label
className="form-control-label"

                                for="exampleEmail"
                                sm={4}
                            >
                                Cabang
                                
                            </Label>
                            <Col sm={7}>
                                <Input
                                    autoComplete="off"
                                    className="form-control-alternative"
                                    type="select"
                                    value={warehousepusat}
                                    onChange={(e) => {
                                    setWarehousePusat(e.target.value);
                                    
                                    }}
                                >
                                    <option value={""}  selected disabled hidden>Pilih Cabang</option>
                                    {
                                    warehousepusats.map((a, key) => {
                                        return <option key={key} value={a.id}>{a.name}</option>
                                    })
                                    }
                                </Input>
                            </Col>
                        </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                              for="exampleEmail" sm={4}>
                                  Username
                              </Label>
                              <Col sm={7}>
                                  <Input
                                    autoComplete="off"
                                      className="form-control-alternative"
                                      type="text"
                                      name="itemCode"
                                      placeholder="Masukan Username"
                                      value={username}
                                      onChange={(e) => {
                                          setUsername(e.target.value);
                                      }}
                                  />
                              </Col>
                          </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                              for="exampleEmail" sm={4}>
                                  Nama
                              </Label>
                              <Col sm={7}>
                                  <Input
                                    autoComplete="off"
                                      className="form-control-alternative"
                                      type="text"
                                      name="barcode"
                                      placeholder="Masukan Nama"
                                      value={name}
                                      onChange={(e) => {
                                          setName(e.target.value);
                                      }}
                                  />
                              </Col>
                          </FormGroup>
                          <FormGroup row>
                              <Col sm={4}>
                                <Label
                                className="form-control-label"
                                >
                                 Kelamin
                                </Label>
                              </Col>
                                <Col sm={7}>
                                    <div style={{ display: "flex" }}>
                                        <div className="custom-control custom-radio mb-4">
                                            <Input
                                              autoComplete="off"
                                              className="custom-control-input"
                                              id="customRadio10"
                                              name="custom-radio-4"
                                              type="radio"
                                              value={1}
                                              checked={gender === 1}
                                              onChange={() => setGender(1)}
                                            />
                                            <Label
                                              className="custom-control-label"
                                              htmlFor="customRadio10"
                                            >
                                            </Label>
                                            <b>Laki-Laki</b>
                                        </div>
                                        <div
                                            className="custom-control custom-radio mb-4"
                                            style={{ marginLeft: "20px" }}
                                        >
                                            <Input
                                              autoComplete="off"
                                              className="custom-control-input"
                                              id="customRadio11"
                                              name="custom-radio-4"
                                              type="radio"
                                              value={2}
                                              checked={gender === 2}
                                              onChange={() => setGender(2)}
                                            />
                                            <Label
                                              className="custom-control-label"
                                              htmlFor="customRadio11"
                                            >
                                            </Label>
                                            <b>Perempuan</b>
                                            
                                        </div>
                                    </div>
                                </Col>
                          </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                              for="exampleEmail" sm={4}>
                                  Alamat
                              </Label>
                              <Col sm={7}>
                                  <Input
                                    autoComplete="off"
                                      className="form-control-alternative"
                                      name="Supplier"
                                      type="textarea"
                                      rows="5"
                                      placeholder="Masukan Alamat"
                                      value={address}
                                      onChange={(e) => {
                                          setAddress(e.target.value);
                                      }}
                                  />
                              </Col>
                          </FormGroup>
                      </CardBody>
										</Col>
										<Col md="6">
                      <CardBody>
                    
                          <FormGroup row>
                              <Label
                              className="form-control-label"

                                for="exampleEmail"
                                sm={4}
                              >
                                Jabatan
                              </Label>
                              <Col sm={7}>
                              <Input
                                  className="form-control-alternative"
                                  autoComplete="off"
                                  name="person"
                                  type="select"
                                  value={grup}
                                  onChange={(e) => {
                                    setGrup(e.target.value);
                                    getUnit(e.target.value)
                                  }}
                                >
                                  <option value="" disabled selected hidden>Pilih Jabatan</option>
                                  <option value="1">PIMPINAN</option>
                                  <option value="2">MANAJERIAL</option>
                                  <option value="3">STAFF</option>
                                
                                  </Input>
                                  
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Unit
                              </Label>
                              <Col sm={7}>
                                   <Select2
                                      className="form-control-alternative"
                                      defaultValue="1"
                                      value={unit}
                                      onChange={(e) => {
                                        setUnit(e.target.value);
                                      }}
                                      options={{
                                        placeholder: "Pilih Unit"
                                      }}
                                      data={units.map((unit) => {
                                        return (
                                        { id: unit.id, text: unit.description}
                                        );
                                      })}
                                    />
                              </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                              for="exampleEmail" sm={4}>
                                  Email
                              </Label>
                              <Col sm={7}>
                                  <Input
                                      autoComplete="off"
                                      className="form-control-alternative"
                                      name="Supplier"
                                      type="text"
                                      placeholder="contoh@gmail.com"
                                      value={email}
                                      onChange={(e) => {
                                          setEmail(e.target.value);
                                      }}
                                  />
                              </Col>
                          </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                              for="exampleEmail" sm={4}>
                                  Password
                              </Label>
                              <Col sm={7}>
                                  <Input
                                      plaintext
                                      placeholder="Masukan Password"
                                      value={password}
                                  />
                              </Col>
                          </FormGroup>
                        </CardBody>
										</Col>
									</Row>
                  <CardFooter>
                    <Link className="btn btn-info" to="/admin/user">
                      Kembali
                    </Link>
                    <Button color="danger" onClick={setQuestionAlert}>
                        Simpan
                    </Button>
                  </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      </div>
    ):(
      <Halaman404 />
    )}
      
    </>
  );
}
