/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Col,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form, FormFeedback,
  Modal, ModalHeader, ModalBody
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function CreateRekonsiliasi() {
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [nameAccount1, setNameAccount1] = useState("");
  const [codeAccount, setCodeAccount] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [keterangan, setKeterangan] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [bank, setBank] = useState("");
  const[banks, setBanks] = useState([]);
  const [kodetransaksi, setKodeTransaksi] = useState("")
  const [debet, setDebet] = useState(0)
  const [kredit, setKredit] = useState(0)
  const [saldo,setSaldo] = useState(0)

  useEffect(() => {
    getbank();
  }, []);

  const getbank = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/bank/get-for-rekonsiliasi/${warehouse}`,
        { headers }
      )
      .then((data) => {
        setBanks(data.data.response);
        setBank(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function CreateData() {
    setLoading(true);
    let data = {
      warehouse_id : parseInt(warehouse),
      bank_id : parseInt(bank),
      note : keterangan,
      code_transaction : kodetransaksi,
      debet_total : parseInt(debet),
      kredit_total : parseInt(kredit),
      saldo_total : parseInt(saldo),
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/rekonsiliasi-bank/save`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        history.push("/admin/rekonsiliasi");
      })
      .then(json => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
      CreateData();
  }
  return (
    <>
      <SimpleHeader name="Buat Rekonsiliasi Bank" parentName="Master" />
      <Container className="mt--6" fluid>
      <Row>
          <div className="col"> 
                <CardBody>
                <Card className="bg-secondary shadow">
                          <CardHeader className="bg-white border-0">
                              <h3>Buat Rekonsiliasi Bank</h3>
                          </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                            <FormGroup row>
                                <Label
                                    sm={4}
                                >
                                    Bank
                                </Label>
                                <Col sm={7}>
                                <Input
                                autoComplete="off"
                                    name="coa"
                                    type="select"
                                    value={bank}
                                    onChange={(e) => {
                                    setBank(e.target.value);
                                    }}
                                >
                                    <option value="" disabled selected hidden>Pilih Bank</option>
                                    {banks.map((coa, key) => {
                                    return (
                                        <option key={key} value={coa.id}>
                                        {coa.bank_name} - {coa.account_number} - {coa.account_name} 
                                        </option>
                                    );
                                    })}
                                </Input>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={4}
                              >
                                Keterangan
                              </Label>
                              <Col sm={7}>
                                <Input
                                
                                className="form-control-alternative"
                                  type="textarea"
                                  name="desc"
                                  rows = "4"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={4}
                              >
                                Kode Transaksi
                              </Label>
                              <Col sm={7}>
                                <Input
                                
                                className="form-control-alternative"
                                  type="text"
                                  name="desc"
                                  placeholder="Masukan Kode Transaksi"
                                  value={kodetransaksi}
                                  onChange={(e) => {
                                    setKodeTransaksi(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={4}
                              >
                                Total Debet
                              </Label>
                              <Col sm={7}>
                                <Input
                                
                                  className="form-control-alternative"
                                  type="number"
                                  placeholder="Masukan Total Debet"
                                  value={debet}
                                  onChange={(e) => {
                                    setDebet(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={4}
                              >
                                Total Kredit
                              </Label>
                              <Col sm={7}>
                                <Input
                                
                                  className="form-control-alternative"
                                  type="number"
                                  placeholder="Masukan Total Kredit"
                                  value={kredit}
                                  onChange={(e) => {
                                    setKredit(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={4}
                              >
                                Total Saldo
                              </Label>
                              <Col sm={7}>
                                <Input
                                  className="form-control-alternative"
                                  type="number"
                                  placeholder="Masukan Total Saldo"
                                  value={saldo}
                                  onChange={(e) => {
                                    setSaldo(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                            
                            {/* <FormGroup row>
                            <Label
                              for="exampleEmail"
                              sm={4}
                            >
                              Validasi
                            </Label>
                              <Col sm={7}>
                                <div style={{ display: "flex" }}>
                                  <div className="custom-control custom-radio mb-3">
                                    <Input
                                      className="custom-control-input"
                                      id="customRadio8"
                                      name="custom-radio-3"
                                      type="radio"
                                      value={5}
                                      checked={statusd === 5}
                                      onChange={() => setStatusD(5)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio8"
                                    >
                                      Disetujui
                                    </Label>
                                  </div>
                                  <div
                                    className="custom-control custom-radio mb-3"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <Input
                                      className="custom-control-input"
                                      id="customRadio9"
                                      name="custom-radio-3"
                                      type="radio"
                                      value={4}
                                      checked={statusd === 4}
                                      onChange={() => setStatusD(4)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio9"
                                    >
                                      Ditolak
                                    </Label>
                                  </div>
                                </div>
                              </Col>
                            </FormGroup> */}
                          </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <Link className="btn btn-info" to="/admin/rekonsiliasi">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={toggle}>
                          Simpan
                      </Button>
                </CardFooter>
                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader toggle={toggle} align="center"></ModalHeader>
                  <ModalBody align="center">
                  <font size="5"><b>Apakah Anda Sudah Yakin ?</b></font><br></br><br></br><br></br>
                  {!isLoading && (
                    <Button color="primary" onClick={() => CreateData()}>
                    Lanjutkan
                    </Button>
                  )}
                  {isLoading && (
                    <Button color="primary" disabled>
                    <i className="fas fa-spinner fa-spin"></i>
                    {""}
                    loading...
                    </Button>
                  )}
                  <Button color="secondary" onClick={toggle}>
                    Cancel
                  </Button>
                  </ModalBody>
                </Modal>
                </Card>
            </CardBody>
          </div>
        </Row>
      </Container>
    </>
  );
}
