/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

const CreateSuratJalanSo = () => {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState("");
  const [pengiriman, setPengiriman] = useState([]);
  const [keterangan, setKeterangan] = useState("");
  const [savedItems, setSavedItems] = useState([]);
  const [codeso, setCodeSo] = useState("");
  const [isSearchShow, setIsSearchShow] = useState(false);
  const headers = { Authorization: `Bearer ${token}`};
  const [allItem, setAllItem] = useState([]);
  const [query, setQuery] = useState("");
  const allInfo = JSON.parse(localStorage.allinfo);
  const suratjalankasir = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Surat Jalan Cashier").map((p) => p.read_access));

  const searchShow = (item) => {
    AmbilDataCok(item.nota_code);
    setIsSearchShow(false);
  };

  useEffect(() => {
    const getData = setTimeout(async() => {
      if(query != ""){
        axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/pos-cashier/page`, {  
          page: 1,
          per_page: 10,
          active_flag:5,
          nota_code: query,
          warehouse_id : parseInt(warehouse),
          pengiriman : 2,
          sjc_flag : 1
        },{ headers })
        .then((res) => {
          setAllItem(res.data);
          setIsSearchShow(true);
          
        })
        .catch(function (error) {
          setGagalAlert(error.response.data.message)
        });
      }
      }, 1000)
      return () => clearTimeout(getData)
  }, [query]);
  
const AmbilDataCok = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/pos-cashier/get-by-nota/${id}`,
        { headers }
      )
      .then((data) => {
        getCustomer(data.data.response.customer_id);
        setCodeSo(data.data.response.nota_code);
        setPengiriman(data.data.response.pengiriman);
        setKeterangan(data.data.response.manual_address);
        getItemDataSaved(data.data.response.nota_code);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = (id) => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/pos-cashier/get-cashier-item`, {

      nota_code: id

    }).then(async response => {
        let stateItem = [];
        let tempItem = [];
        let stateEditing = [];

        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code : data.item_code,
                satuan : data.satuan,
                qty: data.qty,
                harga: data.harga,
                diskon_nominal: data.diskon_nominal,
                diskon_persen: data.diskon_persen,
                data: {
                    item_name: data.item_name,
                    harga: data.harga
                },
            }];
        }));
        setSavedItems(stateItem);
    })
}


  function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga: dataItem.harga,
                diskon_nominal : dataItem.diskon_nominal,
                diskon_persen: dataItem.diskon_persen,
            }]);
    let data = {
      warehouse_id : parseInt(warehouse),
      username : username,
      code_nota: codeso,
      manual_address : keterangan,
      customer_id: parseInt(customer),
      pengiriman: parseInt(pengiriman),
      keterangan: keterangan ,
      items : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-cashier/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
				  setTimeout(() => (history.push("/admin/surat-jalan-kasir/page")), 1000);

        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
    }
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }

  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };

  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={CreateData}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };
  
    const hideAlert = () => {
      setAlert(null);
    };

const getCustomer = (id) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(
      `${process.env.REACT_APP_API_BASE_URL}/customer/list`,
      { headers }
    )
    .then((data) => {
      setCustomers(data.data.response);
      setCustomer(id);
    })
    .catch(function (error) {
      console.log(error);
    });
};

  return (
    <>
    {alert}
    {suratjalankasir && suratjalankasir === "1" ? (
      <div>
    <SimpleHeader name="Buat Surat Jalan Kasir" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
                <Card className="position-sticky bg-secondary BoxShadow" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
											<Col md="4">
											</Col>
											<Col md="4">
												<FormGroup row>
													<Col sm={10}>
														<Input
															className="form-control-alternative"
															placeholder="Search Kode Nota"
															type="search"
															value={query}
															onChange={(e) => setQuery(e.target.value)}
														/>
													</Col>
												</FormGroup>
											</Col>
                      <Col md="4">
                      </Col>
										</Row>
									</CardBody>
								</Card>
									{isSearchShow && query && (
										<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
											<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
												<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
											</div>
											<span className="text-center mb-3">
												<b>Pencarian berdasarkan:</b> {query}
											</span>
											{allItem?.response ? (
												allItem.response.map((item) => (
													<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" style={{minHeight: 'auto'}} onClick={() => {searchShow(item); setQuery(''); setIsSearchShow(false);}}>
														{/* <CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {saveItemOrUpdate(item);setQueryy('');setIsSearchShoww(false);}}> */}
                            <div className=" d-flex align-items-center">
														<div className="ml-3">
																<div>
																	<b>Kode Nota:</b> {item.nota_code}
																</div>
															</div>
														</div>
													</CardBody>
												))
											) : (
												<div className="text-center mb-3 text-danger">Item "{query}" tidak ada bosku!</div>
											)}
										</Card>
									)} 
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Row md="12">
                      <Col md="6">
                      <FormGroup row>
                        <Label
                        className="form-control-label"
                          for="exampleEmail"
                          sm={3}
                        >
                          Kode Nota
                        </Label>
                        <Col sm={6}>
                        <Input
                        className="form-control-alternative"
                        disabled
                        placeholder="Masukan Kode Nota"
                            name="Tipe Request"
                            type="text"
                            value={codeso}
                          />
                        </Col>
                        </FormGroup>
                      <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                          >
                            Customer
                          </Label>
                          <Col sm={6}>
                          <Input
                          className="form-control-alternative"
                          disabled
                              name="customer"
                              type="select"
                              value={customer}
                              onChange={(e) => {
                                setCustomer(e.target.value);
                              }}
                            >
                              <option value=''>Pilih Customer</option>
                              {customers.map((customer, key) => {
                                  return (
                                    <option key={key} value={customer.id}>
                                      {customer.name}
                                    </option>
                                  );
                                })}
                              </Input>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                      <FormGroup row>
                        <Label
                        className="form-control-label"
                          for="exampleEmail"
                          sm={3}
                        >
                          Pengiriman
                        </Label>
                        <Col sm={6}>
                        <Input
                        className="form-control-alternative"
                        disabled
                            name="Tipe Request"
                            type="select"
                            value={pengiriman}
                            onChange={(e) => {
                              setPengiriman(e.target.value);
                            }}
                          >
                            <option value="">Pilih Request</option>
                            <option value={1}>Ambil Sendiri</option>
                            <option value={2}>Delivery</option>
                            <option value={3}>Kurir</option>
                          </Input>
                        </Col>
                        </FormGroup>
                      <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                          >
                            Alamat Kirim
                          </Label>
                          <Col sm={6}>
                            <Input
                              disabled
                              className="form-control-alternative"
                              type="textarea"
                              name="keterangan"
                              rows = "6"
                              placeholder="Masukan Alamat Kirim"
                              value={keterangan}
                              onChange={(e) => {
                                setKeterangan(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        
                      </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                    <CardBody>
                    <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th><b>Nama Item</b></th>
                            <th><b>Kode Item</b></th>
                            <th><b>Qty</b></th>
                            <th><b>Satuan</b></th>
                        </tr>
                        </thead>
                        <tbody>
                          {
                            savedItems.map((savedItem, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{savedItem.item_name}</td>
                                        <td>{savedItem.item_code}</td>
                                        <td>{savedItem.qty}</td>
                                        <td>{savedItem.satuan}</td>
                                        <td hidden>{savedItem.harga}</td>
                                        <td hidden>{savedItem.diskon_nominal}</td>
                                        <td hidden>{savedItem.diskon_persen}</td>
                                    </tr>
                                )
                            })
                          }
                        </tbody>
                      </Table>
                    </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/surat-jalan-kasir/page">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={()=>setQuestionAlert()}>
                          Simpan
                      </Button>
                </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}

export default CreateSuratJalanSo;