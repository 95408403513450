/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  FormGroup,
  Label,
  Input,
  Container,
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import SelectFile from "components/SelectedFile/index";
import { getAddressMap } from "utils/getMapAddress";
import { Maps } from "components/Maps";
import Halaman404 from "views/404";

const CreateWarehousePusat = () => {
  const allInfo = JSON.parse(localStorage.allinfo);
	const cabang = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Cabang").map((p) => p.read_access));
  const token = localStorage.token;
  const history = useHistory();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState([]);
  const [province, setProvince] = useState([]);
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [citys, setCitys] = useState([]);
  const [alert, setAlert] = React.useState(null);
  const [long, setLong] = useState("");
  const [lat, setLat] = useState("");
  const [radius, setRadius] = useState("")

  const [selectAddress, setSelectAddress] = useState("");
  const [loadMap, setLoadMap] = useState();
  const [loadMaps, setLoadMaps] = useState();


  const successCallback = (position) => {
    console.log(position);
  };

  const errorCallback = (error) => {
    console.log(error);
  };

  navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

  const googleMapsApiKey = `${process.env.REACT_APP_MAP}`

  const AnyReactComponent = ({ }) =>
    <div style={{
      // color: 'blue', 
      // background: 'blue',
      padding: '5px 5px',
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)'
    }}>
      <img src="https://i.ibb.co/0JhYD5r/cabang.png" width="40px" />
    </div>;

const defaultProps = {
  center: {
    lat: lat ? lat : -7.3106144,
    lng: long ? long : 112.6841525
  },
  zoom: 14
};

  useEffect(() => {
    getProvinsi();
    setLong();
    setLat();
    setRadius()
  }, []);

  const getProvinsi = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/province/list`, {
      headers
    })
      .then(data => {
        setProvinces(data.data.response_data);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getKota = (id) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/city?province_id=${id}`, {
      headers
    })
      .then(data => {
        setCitys(data.data.response_data);
      })
      .catch(function (error) {
        console.log(error)
      })
  }


  const CreatePusat = () => {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };

    const myjson = JSON.stringify({

      name,
      address,
      level: 1,
      city: parseInt(city),
      province: parseInt(province),
      phone,
      pusat_id: 0,
      toko_id: 0,
      latitude: lat,
      longitude: long,
      jarak_absen: radius,
    });
    let data = new FormData();
    data.append("body", myjson)
    data.append("logo", image)
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/warehouse/save`, data, { headers })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setTimeout(() => (history.push("/admin/warehouse")), 1000);
      })
      .catch(function (error) {
          setGagalAlert(error.response.data.message);
      });
  };
  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const setQuestionAlert = () => {
    setAlert(
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Lanjutkan"
        confirmBtnBsStyle="danger"
        title="Apakah Kamu Yakin?"
        onConfirm={CreatePusat}
        onCancel={hideAlert}
        focusCancelBtn
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  }

  const onClickMap = async (e) => {
    if (e) {
      setLong(e.lng)
      setLat(e.lat)
      const data = await getAddressMap(e.lat, e.lng);

      if (data.results.length > 2) {
        setSelectAddress(data.results[2].formatted_address)
        setAddress(data.results[2].formatted_address)
        document.getElementById('pac-input').value = data.results[2].formatted_address;
        loadMapClick(loadMap, loadMaps);
      }
    }
  }

  const apiIsLoaded = (map, maps, center) => {
    setLoadMap(map);
    setLoadMaps(maps);
    loadMapClick(map, maps);
  };

  const loadMapClick = (map, maps) => {
    const circle = new maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.3,
      map,
      center: defaultProps.center,
      radius: radius
    });
  }

  return (
    <div>
      {alert}
      {cabang && cabang === "1" ? (
      <div>
      <SimpleHeader name="Tambah Pusat" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <h3>Tambah Pusat</h3>
                  </CardHeader>
                  <CardBody>
                    <Row md="12">
                      <Col md="6">
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={4}
                          >
                            Nama
                          </Label>
                          <Col sm={7}>
                            <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Nama"
                              placeholder="Masukan Nama"
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={4}
                          >
                            Alamat
                          </Label>
                          <Col sm={7}>
                            <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                              name="Alamat"
                              placeholder="Masukan Alamat"
                              value={address}
                              onChange={(e) => {
                                setAddress(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={4}
                          >
                            Provinsi
                          </Label>
                          <Col sm={7}>
                            <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              type="select"
                              value={province}
                              onChange={(e) => {
                                setProvince(e.target.value);
                                getKota(e.target.value);
                              }}
                            >
                              <option>Pilih Provinsi</option>
                              {
                                provinces.map((a, key) => {
                                  return <option key={key} value={a.id}>{a.name}</option>
                                })
                              }
                            </Input>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={4}
                          >
                            Kota
                          </Label>
                          <Col sm={7}>
                            <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              name="function"
                              type="select"
                              value={city}
                              onChange={(e) => {
                                setCity(e.target.value);
                              }}
                            >
                              <option>Pilih Kota </option>
                              {
                                citys.map((b, key) => {
                                  return <option key={key} value={b.id}>{b.name}</option>
                                })
                              }
                            </Input>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={4}
                          >
                            Kontak
                          </Label>
                          <Col sm={7}>
                            <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              type="number"
                              pattern='[0-9]{0,5}'
                              name="kontak"
                              placeholder="Masukan kontak"
                              value={phone}
                              onChange={(e) => {
                                setPhone(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={4}
                          >
                            Gambar
                          </Label>
                          <Col sm={7}>
                            <label htmlFor="formId" >
                              <input
                                hidden
                                id="formId"
                                name="file"
                                type="file"
                                accept="image/*"
                                onChange={(event) => {
                                  setImage(event.target.files[0]);
                                }}
                              />
                              <SelectFile
                                onclick={() => { }}
                                imageSrc={image != "" && image != undefined ? URL.createObjectURL(image) : null}
                              />
                            </label>
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                  <CardHeader>
                    <h3>lokasi</h3>
                    <div
                      style={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <div style={{ textAlign: "center" }}>
                      </div>
                    </div>
                  </CardHeader>
                  <CardBody>
                    {/* <CardBody> */}

                    {/* {lat} , {long} {lokasi} */}
                    <Row md="16">
                      <Col md="4">
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={2}
                          >
                            latitude
                          </Label>
                          <Col sm={9}>
                            <Input
                              // disabled
                              autoComplete="off"
                              className="form-control-alternative"
                              type="number"
                              pattern='[0-9]{0,5}'
                              name="Phone"
                              placeholder="Masukan Latitude"
                              value={lat}
                              onChange={(e) => {
                                setLat(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={3}
                          >
                            Longitude
                          </Label>
                          <Col sm={9}>
                            <Input
                              // disabled
                              autoComplete="off"
                              className="form-control-alternative"
                              type="number"
                              pattern='[0-9]{0,5}'
                              name="Phone"
                              placeholder="Masukan Longitude"
                              value={long}
                              onChange={(e) => {
                                setLong(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={3}
                          >
                            Radius
                          </Label>
                          <Col sm={9}>
                            <Input
                              // disabled
                              autoComplete="off"
                              className="form-control-alternative"
                              type="number"
                              pattern='[0-9]{0,5}'
                              name="Phone"
                              placeholder="Masukan Radius"
                              value={radius}
                              onChange={(e) => {
                                setRadius(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                      </Col>
                    </Row>
                    <Maps
                      defaultCenter={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                      onClickMap={onClickMap}
                      apiIsLoaded={apiIsLoaded}
                      isSearch={true}
                      long={long}
                      lat={lat}
                      address={selectAddress}
                      setLong={setLong}
                      setLat={setLat}
                    />
                  </CardBody>
                  <CardFooter>
                    <Link className="btn btn-info" to="/admin/warehouse">
                      Kembali
                    </Link>
                    <Button color="danger" onClick={setQuestionAlert}>
                      Simpan
                    </Button>
                  </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
      </Container>
      </div>
      ):(
        <Halaman404 />
      )}
    </div>

  );
};

export default CreateWarehousePusat;