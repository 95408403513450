/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  FormGroup,
  Label,
  Input,
  
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";


export default function DetailJurnalUmum(props)  {
  const token = localStorage.token; 
  const [savedItems, setSavedItems] = useState([]);
  const [rekanan, setRekanan] = useState("");
  const [transaksi, setTransaksi] = useState("")
  const [tanggal, setTanggal] = useState("");
  const [codejurnal,setCodeJurnal] = useState("");
  const allInfo = JSON.parse(localStorage.allinfo);
  const laporanfinance = String(allInfo.privileges.filter((i) => i.privilege_name === "Laporan Finance").map((p) => p.read_access));
  
  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/account-journal/umum/detail/${props.match.params.id}`,
        { headers }
      )
      .then(async response => {
        let stateItem = [];
        setCodeJurnal(response.data.response.journal_code);
        setTanggal(response.data.response.tgl_journal);
        setRekanan(response.data.response.transaction_name)
        setTransaksi(response.data.response.transaction_code)
        await Promise.all(response.data.response.list.map(async (data) => {
            stateItem = [...stateItem, {
                kode_akun: data.account_code,
                akun:data.account_name,
                transaction_code:data.transaction_code,
                debet: data.debet_total,
                kredit: data.kredit_total,
            }];
            // setTotalQty(data.harga * data.qty);
            // setDiskonTotal(data.qty * data.diskon_nominal);
        }));
        setSavedItems(stateItem);
    })
      .catch(function (error) {
        console.log(error);
      });
  };

    const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
      }

  return (
    <>
    {laporanfinance && laporanfinance === "1" ? (
			<div>
    <SimpleHeader name="Detail Jurnal" parentName="Jurnal" />
    <Container className="mt--6" fluid>
        <Row>
        <div className="col">
          <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Detail Jurnal</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Tanggal
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                  className="form-control-alternative"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Masukan Tanggal"
                                  value={tanggal}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                  Supplier/Customer
                              </Label>
                              <Col sm={6}>
                              <Input
                                disabled
                                  className="form-control-alternative"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Masukan Supplier/customer"
                                  value={rekanan}
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode Jurnal
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                  type="text"
                                  name="desc"
                                  rows = "4"
                                  placeholder="Masukan Kode Journal"
                                  value={codejurnal}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode Transaksi
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                  type="text"
                                  name="desc"
                                  rows = "4"
                                  placeholder="Masukan Keterangan"
                                  value={transaksi}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Akun</h3>
                    </CardHeader>
                    <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                {/* <th><b>No</b></th> */}
                                <th><b>Kode Akun</b></th>
                                <th><b>Akun</b></th>
                                <th><b>Transaksi</b></th>
                                <th><b>Debet</b></th>
                                <th><b>Kredit</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      {/* <td>{savedItem}</td> */}
                                      <td>{savedItem.kode_akun}</td>
                                      <td>{savedItem.akun}</td>
                                      <td>{savedItem.transaction_code}</td>
                                      <td><>{formatRupiah(savedItem.debet)}</></td>
                                      <td><>{formatRupiah(savedItem.kredit)}</></td>
                                      
                                    </tr>
                                  )
                                })
                              }
                              {/* <tr>
                                      <td colSpan={2}>TOTAL</td>
                                      <td><>1000000</></td>
                                      <td><>1000000</></td>
                                      
                                    </tr> */}
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter >
                          <Row md="12">
                            <Col md="6">
                            </Col>
                            <Col md="2">
                            </Col>
                            {/* <Col md="4">
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                                size="small"
                              >
                                <b>Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Harga Total"
                                // value={totalPrice1}
                                value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Diskon</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                style={{fontWeight: 'bold'}}
                                disabled
                                type="text"
                                name="barcode"
                                placeholder="Diskon"
                                value={"Rp." + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>PPN</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Ongkir</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                // placeholder="PPN"
                                value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Uang Muka</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Uang Muka"
                                value={"Rp." + dp1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Grand Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                type="text"
                                name="barcode"
                                style={{fontWeight: 'bold'}}
                                placeholder="Grand Total"
                                value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                            </Col> */}
                          </Row>
                        </CardFooter>
                    <CardFooter>
                          <Link className="btn btn-info" to="/admin/laporan-jurnal/jurnal-umum">
                              Kembali
                          </Link>
                    </CardFooter>
              </Card>
          </div>
        </Row>
    </Container>
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}