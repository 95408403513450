/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Form, 
    Badge,
    FormGroup, 
    Input ,
    DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle
} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";
import ModalCetakRiwayatIzinHrd from './ModalCetakRiwayatIzinHrd.js';

const ValidasiRiwayatIzin = () => {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  // const warehouseid = localStorage.warehouse;
  const redirectPrefix1 = `/admin/employee/izin/detail/`;
  const [rowIndex, setRowIndex] = useState(0);
  const [allPenawaranPo, setAllPenawaranPo] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [approve, setApprove] = useState("")
  const [name, setName] = useState("")
  const [warehouse, setWarehouse] = useState("")
  const [warehouses, setWarehouses] = useState([])
  const [openModalCetak, setOpenModalCetak] = useState(false);
  const [dataModalCetak, setDataModalCetak] = useState({
    id: 0,
  });
  const toggleModal = () => setOpenModalCetak(!openModalCetak);

  const displayModalCetak = (id) => {
    setDataModalCetak({
      id: id,
    });
    setOpenModalCetak(true);
  };

  useEffect(() => {
    getWarehouse();
  }, []);


  const getWarehouse = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`,
        { headers }
      )
      .then((data) => {
        setWarehouses(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, approve, name,warehouse);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort,approve, name,warehouse)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, approve, name,warehouse) => {
    getPenawaranPo(page, perPage, sort, approve, name,warehouse);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setApprove(approve);
    setName(name);
    setWarehouse(warehouse)
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, approve, name,warehouse)
    }
  }

  
  useEffect(() => {
    getPenawaranPo(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getPenawaranPo = (page, perPage, currentSort,approve = null, name='',warehouse_id='') => {
    
    let filter = { 
      
      page: page, 
      per_page: perPage,
      
    };
    if (approve !== null) {
        filter = Object.assign(filter, {approve: approve });
    }
    if (name !== '') {
        filter = Object.assign(filter, {name: name });
    }
    if (warehouse_id !== '') {
      filter = Object.assign(filter, {warehouse_id: warehouse_id });
  }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/absensi-form/page`, data, {
        headers,
      })
      .then((data) => {
        setAllPenawaranPo(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        setAllPenawaranPo(error.response.data.response);
  });
}


const setGagalAlert = (id) => {
setAlert(
<SweetAlert
danger
showConfirm
confirmBtnText="Ok"
title={id}
onCancel={hideAlert}
onConfirm={hideAlert}
/>
);
};

const hideAlert = () => {
setAlert(null);
};

  const reset = () => {
    setApprove("");
    setName("");
    setWarehouse("");
    updateDataTable(1, perPage, currentSort, "", "","");
  }

  return (
    <div>
      {alert}
        <Row>
          <div className="col">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3>Histori Izin</h3>
                </div>
              </CardHeader>
              <CardBody>
                <Form>
                    <Row md="9">
                    <Col md="3">
                            <FormGroup>
                            <Input
                                name="account"
                                type="select"
                                value={warehouse}
                                onChange={(e) =>
                                updateDataTable(1,  perPage, currentSort, approve, name, e.target.value)
                                }
                            >
                                <option value="">Pilih Cabang</option>
                                {warehouses.map((dep, key) => {
                                return (
                                    <option key={key} value={dep.id}>
                                    {dep.name}
                                    </option>
                                );
                                })}
                            </Input>
                            </FormGroup>
                        </Col>
                        <Col md="3">
                              <FormGroup>
                                <Input
                                    className="form-control-alternative"
                                    type="select"
                                    placeholder="Status"
                                    value={approve}
                                    onChange={e => updateDataTable(1, perPage, currentSort, e.target.value, name)}
                                >
                                  <option value="">Pilih Status</option>
                                  <option value={5}>Setuju</option>
                                  <option value={4}>Tolak</option>
                                  <option value={3}>Proses</option>

                                </Input>
                            </FormGroup>
                        </Col>
                        <Col md="3">
                            <FormGroup>
                                <Input
                                    className="form-control-alternative"
                                    type="text"
                                    placeholder="Masukan Karyawan"
                                    value={name}
                                    onChange={e => updateDataTable(1, perPage, currentSort, approve, e.target.value)}
                                />
                            </FormGroup>
                        </Col>
                      
                        <Col md="3">
                            <FormGroup>
                                <Button type="button" onClick={reset} color="secondary">
                                    Reset
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                {allPenawaranPo === null ? (
                     <CardHeader>
                          <center><h3>DATA NOT FOUND</h3></center>
                      </CardHeader>
                    ):(
                    <ToolkitProvider
                            rowNumber={rowIndex}
                            data={allPenawaranPo}
                            keyField="id"
                            columns={[
                            {
                                dataField: "no",
                                text: "#",
                                sort: true,
                                page: 1,
                                formatter: (cell, row, index) => {
                                let currentRow = ++index;
                                return currentRow + rowIndex;
                                },
                            },
                            {
                                dataField: "ijin_date",
                                text: "Tanggal Izin",
                                sort: true,
                            },
                            {
                                dataField: "name",
                                text: "Nama",
                                sort: true,
                            },
                            {
                                dataField: "keterangan",
                                text: "Keterangan",
                                sort: true,
                            },
                            {
                              dataField: "approve",
                              text: "Status",
                              sort: true,
                              formatter: (cell, row) => {
                                return row.approve === 3
                                  ? 
                                    <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-danger" />
                                      Proses
                                    </Badge>
                                  : row.approve === 4
                                  ? <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-warning" />
                                      Tolak
                                    </Badge>
                                  : <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-success" />
                                      Setuju
                                    </Badge>;
                              },
                          },
                          {
                            dataField: "",
                            text: "",
                            formatter: (cell, row, index) => {
                              return row.approve === 3
                                ? <UncontrolledDropdown nav>
                                    <DropdownToggle color="danger">
                                        Tindakan
                                      </DropdownToggle>
                                      <DropdownMenu center>
                                            <Link  to={redirectPrefix1 + row.id}
                                              id={"tooltip_" + row.id}>
                                            <DropdownItem>
                                              <i className="fas fa-user-edit" /><span>Detail</span>
                                            </DropdownItem>
                                            </Link>
                                      </DropdownMenu>
                                  </UncontrolledDropdown>
                                : row.approve === 4
                                ?  <UncontrolledDropdown nav>
                                <DropdownToggle color="danger">
                                    Tindakan
                                  </DropdownToggle>
                                  <DropdownMenu center>
                                        <Link  to={redirectPrefix1 + row.id}
                                          id={"tooltip_" + row.id}>
                                        <DropdownItem>
                                          <i className="fas fa-user-edit" /><span>Detail</span>
                                        </DropdownItem>
                                        </Link>
                                  </DropdownMenu>
                              </UncontrolledDropdown>
                                : <UncontrolledDropdown nav>
                                <DropdownToggle color="danger">
                                    Tindakan
                                  </DropdownToggle>
                                  <DropdownMenu center>
                                        <Link  onClick={() => displayModalCetak(row.id)}>
                                            <DropdownItem>
                                              <i className="fas fa-print" /> <span>Cetak Permohonan</span>
                                            </DropdownItem>
                                        </Link>
                                  </DropdownMenu>
                              </UncontrolledDropdown>
                            },
                          },
                            ]}
                        >
                            {(props) => (
                            <div className="py-4 table-responsive">
                                <BootstrapTable
                                remote
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                                hover={true}
                                pagination={paginationFactory({ ...paginationOption })}
                                onTableChange={handleTableChange}
                                />
                            </div>
                            )}
                    </ToolkitProvider>
                    )}
              </CardBody>
            </Card>
          </div>
        </Row>
        {openModalCetak && (
        <ModalCetakRiwayatIzinHrd
          open={openModalCetak}
          data={dataModalCetak}
          toggle={toggleModal}
        />
      )}
    </div>
  );
}

export default ValidasiRiwayatIzin;