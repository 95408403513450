/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardGroup,
} from "reactstrap";
import axios from "axios";

const DetailItemStok = (props) => {
  const token = localStorage.token;
  const [image1,setImage1] = useState("");
  const [image2,setImage2] = useState("");
  const [image3,setImage3] = useState("");

  useEffect(() => {
    getSalesTrackingDetail();
  }, [props.code]);

  const getSalesTrackingDetail = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/item-stok/get/${props.code}`,
        {
          headers,
        }
      )
      .then((data) => {
        setImage1(data.data.response.image_1);
        setImage2(data.data.response.image_2);
        setImage3(data.data.response.image_3);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  return (
    <div>
        <CardBody>
        <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <h3>Gambar</h3>
            </CardHeader>
            <CardBody>
            <Row md="12">
                <Col md="12">
                <CardGroup>
                    <Card>
                          {image1 === "" ?(
														<img
														alt=""
														src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
														width="95%"
														/>
													):(
													<img
														alt=""
														src={image1}
														width="95%"
													/>
													)}
                  </Card>        
                  <Card>
                      {image2 === "" ?(
														<img
														alt=""
														src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
														width="95%"
														/>
													):(
													<img
														alt=""
														src={image2}
														width="95%"
													/>
													)}
                  </Card>
                  <Card>
                    {image3 === "" ?(
														<img
														alt=""
														src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
														width="95%"
														/>
													):(
													<img
														alt=""
														src={image3}
														width="95%"
													/>
													)}
                        </Card>
                        </CardGroup>
                    </Col>
                </Row>
                </CardBody>
            </Card>
        </CardBody>
    </div>
  );
};

export default DetailItemStok;

