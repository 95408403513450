import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  // Input,
  ModalBody,
  Label
} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";
// import * as FileSaver from "file-saver";
// import SweetAlert from "react-bootstrap-sweetalert";
// import styles from '../../../../../assets/scss/Print.scss'
import moment from "moment";

const ModalCetakRfqPusat = ({ open, toggle, data }) => {
  const token = localStorage.token;
  const username = localStorage.username;
  const [savedItems, setSavedItems] = useState([]);
  const [warehouserfq, setWarehouseRfq] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [coderfq, setCodeRfq] = useState("");
  const [waktu, setWaktu] = useState([]);
  const [validator, setValidator] = useState("");
  const [phone, setPhone] = useState("");
  const [download, setDownload] = useState("")
  // const [alert, setAlert] = React.useState(null);
  const [creator, setCreator] = useState("")
  // const [item, setItem] = useState("")

  

  const HitCounter = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/rfq-po/hit-counter-cabang/${data.id}`,
        { headers }
      )
      .then((data) => {  
        console.log("suskes Cuk");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getById = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/rfq-po/cetak/${data.id}`, {
        headers,
      })
      .then((data) => {
        setCodeRfq(data.data.response.rfqpo.rfq_code);
        setCreator(data.data.response.rfqpo.creator)
        setPhone(data.data.response.rfqpo.type)
        setDownload(data.data.response.rfqpo.counter_cabang);
        setWarehouseRfq(data.data.response.rfqpo.warehouse);
        setWaktu(data.data.response.rfqpo.created)
        setValidator(data.data.response.rfqpo.validator1);
        setKeterangan(data.data.response.rfqpo.keterangan);
        // setItem(data.data.response.list.length);

      })
      .catch(function (error) {
        console.log(error);
      });
  },[token, data.id]);

  const getItemDataSaved = useCallback(() => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/rfq-po/cetak/${data.id}`,{ headers }
    
    ).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.list.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                qty: data.qty,
                harga: data.harga,
                satuan: data.satuan,
                diskon_nominal: data.diskon_nominal,
                diskon_persen : data.diskon_persen,
                note : data.note,
                data: {
                    item_name: data.item_name,
                    harga: data.harga
                },
            }];
        }));

        setSavedItems(stateItem);
    })
},[token,data.id])

useEffect(() => {
  getById();
  getItemDataSaved();
}, [getById,getItemDataSaved]);

// const downloadExcel = async ()=> {
//   const J = "RFQ-CABANG"
//   const fileName = J;
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${token}`,
//   };
//   axios
//     .get(`${process.env.REACT_APP_API_BASE_URL}/rfq-po/cetak/${data.id}`,{headers})
//     .then((data) => {
//       setCodeRfq(data.data.response.rfqpo.rfq_code);
//       setCreator(data.data.response.rfqpo.creator)
//       setPhone(data.data.response.rfqpo.type)
//       setDownload(data.data.response.rfqpo.counter_cabang);
//       setWarehouseRfq(data.data.response.rfqpo.warehouse);
//       setWaktu(data.data.response.rfqpo.created)
//       setValidator(data.data.response.rfqpo.validator1);
//       setKeterangan(data.data.response.rfqpo.keterangan);
//       setItem(data.data.response.list.length);

//       var apiPo = data.data.response.list;
//       var tableToExcel = (function() {
//         var uri = 'data:application/vnd.ms-excel;base64,',
//           template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
//           base64 = function(s) {
//             return window.btoa(unescape(encodeURIComponent(s)))
//           },
//           format = function(s, c) {
//             return s.replace(/{(\w+)}/g, function(m, p) {
//               return c[p];
//             })
//           }
//         return function(table, name) {
//           var heading = 'RFQ CABANG' ;
//           var imgsrc1 = 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhAVKx5R3RdjeXQuRdKan2RNLsZn2U4qXYOgU4jqILz6u6MLSzlvzY1b5x9Xiz4sKHhM0UJ1NKKoFVx6ZEI8JqgANlrZ8KwCJ2j9pOmJN-e50-HzVhTFRvEahjCJB51O4oMmJ25V2yQtYOGfxV2b7C2aT9VKBruh0_znTbORz66pu9P47DMB5aP4SuF/s320/Hokky1.png';
//           var po = '';
//           var a ;
//           for(a=0; a < apiPo.length; a++){
//             po += `
//             <tr style="border: 1px solid black">
//                 <td colspan="4">${apiPo[a].item_name}</td>
//                 <td>${apiPo[a].item_code}</td>
//                 <td>${apiPo[a].qty}</td>
//                 <td>${apiPo[a].satuan}</td>
//             </tr>
//             `
//           }
          
//           var table = `
//           <table id="account_table">
//               <tbody>
//               <tr>
//                   <td><img src="https://i.ibb.co/jkFYDqg/150x75.png"/> </td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>Hokky Bangunan</td>
//               </tr>
//               <tr>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>Jl Menganti Karangan No.562</td>
//               </tr>
//               <tr>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>Wiyung surabaya barat - Jawa Timur </td>
//               </tr>
//               <tr>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>Telp: 081 217 85 3300 </td>
//               </tr>
//               <tr>
//                   <td colspan="7"><center><b><h1>RFQ CABANG</h1></b><center></td>
//               </tr>
//               <tr>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//               </tr>
//               <tr>
//                   <td>Tanggal</td>
//                   <td>: ${waktu}</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//               </tr>
//               <tr>
//                   <td>Kode RFQ</td>
//                   <td>: ${coderfq}</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//               <tr>
//               <tr>
//                   <td>Ket</td>
//                   <td rowspan="2">: ${keterangan}</td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td>Phone : ${phone}</td>
//               </tr>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//                   <td></td>
//               </tr>
//                 <tr style="border: 1px solid black">
//                     <th colspan="4">Nama Item</th>
//                     <th>Kode Item</th>
//                     <th>Qty</th>
//                     <th>Satuan</th>
//                   </tr>
//                       ${po}
//               </tbody>
//           </table>
//           `;
//           var ctx = {
//               worksheet: name || 'WorkBook',
//               imgsrc1: imgsrc1,
//               heading: heading,
//               table: table
//           }
//           var blob = new Blob([format(template, ctx)]);
//           return blob;
//           }
//       })()
//       var blobURL = tableToExcel('account_table', 'RFQ Cabang');
//       FileSaver.saveAs(blobURL, fileName+'.xls');
      
//       })
//     .catch(function (error) {
//           setGagalAlert();
//     });
// }

// const setGagalAlert = () => {
//   setAlert(
//     <SweetAlert
//       danger
//       showConfirm
//       confirmBtnText="Ok"
//       title="Gagal Cok"
//       onCancel={hideAlert}
//       onConfirm={hideAlert}
//     />
//   );
// };

// const hideAlert = () => {
//   setAlert(null);
// };

// const formatRupiah = (money) => {
//   return new Intl.NumberFormat('id-ID',
//       { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
//   ).format(money);
// }


  // function print() {
  //   var printContents = document.getElementById("targetContent").innerHTML;
  //   var originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //   document.body.innerHTML = originalContents;
  // }

  function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    // console.log(item)
    window.print();
    window.close();
    document.body.innerHTML = originalContents;
  }

  // function clickItem(e) {
  //   setItem(e.target.value)
  // }
  
  
  return (
    <div>
      {/* {alert} */}
      <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%" , top:"-10%" }}>
      <ModalHeader toggle={toggle} align="center">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {/* <div style={{ textAlign: 'left' }}>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            Set Item
                        </Label>
                        <Input type="number" pattern="[0-9]*" value={item} min="1" max={savedItems.length} onChange={clickItem} style={{width: '70px',marginRight:'50px'}}/>
                    </div> */}
                    <div style={{ textAlign: 'right' }}>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            &nbsp;
                        </Label><br></br>
                        <Button color="info" onClick={() => {print();HitCounter();setTimeout(() => (window.location.reload("admin/permintaan-barang/histori")), 1000)}}>Print</Button>
                        {/* <Button color="success" onClick={()=> (downloadExcel(),HitCounter())}>Excel</Button> */}
                    </div>
                </div>
      </ModalHeader>
      <ModalBody>
        <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          {renderPDF()}
        </CardBody>
      </ModalBody>
      {/* <ModalBody>
        <CardBody 
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          <div className="col">
            <KopSurat warehouseName={warehouserfq} />
          </div>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
         
            <Row md="12">
              <Col md="12">
                <h3>
                  <b>
                    <center>RFQ</center>
                  </b>
                </h3>
              </Col>
            </Row>
            <div className="w-100" style={{ border: "1px solid black" }}></div>
            <br></br>
            <Row md="12">
                <Col md="6">
                <div className="row align-items-center mb-1">
                    <div className="col-3">Kode RFQ</div>
                    <div className="col-1 text-center">:</div>
                    <div className="col-6 ">
                        <b>{coderfq}</b>
                    </div>
                </div>
                <div className="row align-items-center mb-1">
                    <div className="col-3">Tanggal</div>
                    <div className="col-1 text-center">:</div>
                    <div className="col-6 ">
                        <b>{waktu}</b>
                    </div>
                </div>
                <div className="row align-items-center mb-1">
                    <div className="col-3">Keterangan</div>
                    <div className="col-1 text-center">:</div>
                    <div className="col-6 ">
                        <b>{keterangan}</b>
                    </div>
                </div>
                <div className="row align-items-center mb-1">
                    <div className="col-3"></div>
                    <div className="col-1 text-center"></div>
                    <div className="col-6 ">
                    </div>
                </div>
                </Col>
                <Col md="6">
                <div className="row align-items-center mb-1">
                    <div className="col-3">Phone</div>
                    <div className="col-1 text-center">:</div>
                    <div className="col-6 ">
                        <b>{phone}</b>
                    </div>
                </div>
                </Col>
            </Row>
            <br></br>
          <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th>
                            <b>Nama Item</b>
                            </th>
                            <th>
                            <b>Kode Item</b>
                            </th>
                            <th>
                            <b>Qty</b>
                            </th>
                            <th>
                            <b>Satuan</b>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            savedItems.map((savedItem, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{savedItem.data.item_name}</td>
                                        <td>{savedItem.item_code}</td>
                                        <td>{savedItem.qty}</td>
                                        <td>{savedItem.satuan}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                      </Table>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          <center>Terms of Price, delivery & shipping required</center>
          <Table>
            <tbody>
              <tr>
                <td>
                  <Row md="12">
                  <Col md="4">
                      <div className="row align-items-center mb-3">
                        <div className="col-4">Di Buat</div>
                        <div className="col-1 text-center">:</div>
                        <div className="col-5 ">
                          <b>{creator}</b>
                        </div>
                      </div>
                      <div className="row align-items-center mb-3">
                        <div className="col-4">Signature</div>
                        <div className="col-1 text-center">:</div>
                        <div className="col-4 ">
                          <b>---------------------------</b>
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="row align-items-center mb-3">
                        <div className="col-4">Kepala Purchasing</div>
                        <div className="col-1 text-center">:</div>
                        <div className="col-5 ">
                          <b>{validator}</b>
                        </div>
                      </div>
                      <div className="row align-items-center mb-3">
                        <div className="col-4">Signature</div>
                        <div className="col-1 text-center">:</div>
                        <div className="col-4 ">
                          <b>---------------------------</b>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="w-100" style={{ border: "0.5px solid black" }}></div>
                      {download === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Download</i> {download} Kali Oleh {username}</div>
                    </div>
                      )
                      }
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </ModalBody> */}
    </Modal>
    </div>
  );
  function renderPDF(){
    const itemsPerPage = 10; // Maksimal 7 item per halaman
    const totalItems = savedItems.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage); // Hitung total halaman
  
    const sheets = [];
    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      // Hitung indeks awal dan akhir untuk slice item per halaman
      const start = pageIndex * itemsPerPage;
      const end = start + itemsPerPage;
  
      // Ambil item untuk halaman saat ini
      const currentItems = savedItems.slice(start, end);
    // // let sheet = 1;
    // // let length = savedItems.length
    // // if (item != length) {
    // //   sheet = Math.floor(length / item);
    // //   if((length % item) > 0){
    // //     sheet++;
    // //   }
    // // }
    // // const sheets = []  
    // // for (let index = 0; index < sheet; index++) {
    // //   let start = 0
    // //   let end = 0
    // //   if(sheet == 1){
    // //     end = length
    // //   }else{
    // //     if(sheet > 1 && index < (sheet - 1)){
    // //       end = item
    // //     }else{
    // //       end = length % item
    // //     }
    // //   }

    // let sheet = 1;
    // let length = savedItems.length
    // if (item != length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []
    // for (let index = 0; index < sheet; index++) {
    //   let start = (item * index)
    //   let end = length
    //   let sisa = (length % item)
    //   if(sheet == 1){
    //     end = length
    //   }else{
    //     if(index < (sheet - 1) && sheet == 2){
    //       end = item
    //     }else{
    //       if (index == (sheet - 1)) {
    //         end = length - sisa + (index * item)
    //       }else{
    //         if (index == 0) {
    //           end = item
    //         }else{
    //           end = (index+1) * item
    //         }
    //       }
    //     }
    //   } 
    //   let data = savedItems.slice(start,end)
    //   console.log(data.length)
    //   let diskon = 0;
    //   // let pajak = 0;
      let usernamecok = moment().format("YYYY-MM-DD hh:mm:ss") + " " + localStorage.username;
      sheets.push(
        <div className='pagebreak'>
          <div className="col">
            <KopSurat warehouseName={warehouserfq} />
          </div>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          <Row md="12">
              <Col md="12">
                  <h3 className="" style={{ color:"black" }}><b><center>RFQ</center></b></h3>
              </Col>
          </Row>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          
          <Row md="12">
            <Col style={{marginLeft:"2%",width:"50%",flexBasis: "unset"}}>
             
              <div className="row align-items-center ">
                  <div style={{width:"10%"}}  className="text-table">Tanggal</div>
                  <div style={{width:"2%"}}  className="text-center text-table">:</div>
                  <div style={{width:"85%"}}  className="text-table">
                      <b>{waktu}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"10%"}} className="text-table">No RFQ</div>
                  <div style={{width:"2%"}} className="text-center text-table">:</div>
                  <div style={{width:"85%"}} className="text-table">
                      <b>{coderfq}</b>
                  </div>
              </div>
            </Col>
            <Col style={{width:"25%",flexBasis: "unset"}}>
            <div className="row align-items-center ">
                  <div style={{width:"30%"}} className="text-table">Tipe PO</div>
                  <div style={{width:"5%"}} className="text-table">:</div>
                  <div style={{width:"65%"}} className="text-table">
                    {phone === 1 ? 
                    <b>Customer Request</b>
                    :
                    <b>Stok Request</b>
                  }
                  </div>
              </div>
            <div className="row align-items-center ">
                  <div style={{width:"30%"}}  className="text-table">keterangan </div>
                  <div style={{width:"5%"}}  className="text-center text-table">:</div>
                  <div style={{width:"65%"}}  className="text-table">
                      <b>{keterangan}</b>
                  </div>
              </div>
            </Col>
          </Row>
            
              <Table style={{width:"100%"}} responsive>
                <thead>
                <tr>
                    <th className="text-table">
                    <b>No</b>
                    </th>
                    <th className="text-table">
                    <b>Kode</b>
                    </th>
                    <th className="text-table">
                    <b>Nama Barang</b>
                    </th>
                    {/* <th className="text-table">
                    <b>Brand</b>
                    </th> */}
                    <th className="text-table">
                    <b>Qty</b>
                    </th>
                    <th className="text-table">
                    <b>Sat</b>
                    </th>
                    <th className="text-table">
                    <b>Keterangan</b>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    currentItems.map((temp,index, key) => {
                        return (
                            <tr>
                                <td className="text-table">{index+1}</td>
                                <td className="text-table">{temp.item_code}</td>
                                <td className="text-table">{temp.item_name}</td>
                                {/* <td className="text-table">-</td> */}
                                <td className="text-table">{temp.qty}</td>
                                <td className="text-table">{temp.satuan}</td>
                                <td className="text-table">{temp.note}</td>
                            </tr>
                        );
                    })
                }
                </tbody>
              </Table>
              <div ></div>
              <Row md="12">
              </Row>
          <div className="w-100 text-table" style={{ border: "0.5px solid black" }}></div>
          <center className="text-table">Terms of Price, delivery & shipping required</center>
          <Row md="20">
            <Col style={{ marginLeft:"2%",width:"33%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Di Buat</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>{creator}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col>
            <Col style={{ width:"33%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Kepala Purchasing</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>{validator}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col>
          </Row>
          <div className="w-100" style={{ border: "0.5px solid black" }}></div>
                      {download === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Download {download} Kali Oleh {username}</i></div>
                    </div>
                      )
                      }
          <div className="divFooter">{usernamecok}</div>
        </div>
      )
    }
    return (
      <div>
        {sheets}
      </div>
    );
  }
};

export default ModalCetakRfqPusat;
