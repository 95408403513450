/*eslint-disable*/
import React from "react";
import {
  Card,
  CardBody,
  Row,
  Container,
  CardImg,
} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";

export default function LaporanJurnal() {
  const allInfo = JSON.parse(localStorage.allinfo);
  const promoToko = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Promo Toko").map((p) => p.read_access));
	 
  return (
    <>
         {promoToko && promoToko === "1" ? (
      <div>
      <SimpleHeader name="Promo" parentName="Report" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <CardBody>
                    <Row>
                        <div className="card-columns">
                            <Card>
                                <Link to="/admin/promo/promo-global">
                                    <CardImg
                                    alt="..."
                                    src={require("assets/img/theme/promo-global.png").default}
                                    top
                                    />
                                </Link>
                            </Card>
                             <Card>
                                <Link to="/admin/promo/promo-budget">
                                    <CardImg
                                    alt="..."
                                    src={require("assets/img/theme/promo-perorangan.png").default}
                                    top
                                    />
                                </Link>
                            </Card>
                            {/* <Card>
                                <Link to="/admin/promo/promo-produk">
                                    <CardImg
                                    alt="..."
                                    src={require("assets/img/theme/promo-produk.png").default}
                                    top
                                    />
                                </Link>
                            </Card>
                            <Card>
                                <Link to="/admin/promo/promo-bundling">
                                    <CardImg
                                    alt="..."
                                    src={require("assets/img/theme/promo-bundling.png").default}
                                    top
                                    />
                                </Link>
                            </Card> */}
                        </div>
					</Row>
                </CardBody>
          </div>
        </Row>
      </Container>
      </div>
         ):(
          <Halaman404 />
         )}
    </>
  );
}