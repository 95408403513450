/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  FormGroup,
  Label,
  Input, 
  Modal, ModalHeader, ModalBody
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

export default function ValidasiDirekturAdj(props)  {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [keterangan,setKeterangan] = useState("");
  const [statusd, setStatusD] = useState([]);
  const [savedItems, setSavedItems] = useState([]);
  const [coderak, setCodeRak] = useState("")
  const [coderaks, setCodeRaks] = useState([])
  const [usernameBuat, setUsernameBuat] = useState("");
  const allInfo = JSON.parse(localStorage.allinfo);
  const StokOpnames = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Stok Opname").map((p) => p.read_access));
 
  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/opname/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setKeterangan(data.data.response.keterangan);
        setUsernameBuat(data.data.response.username);
        setStatusD(data.data.response.status_d);
        getItemGrup(data.data.response.code_rak)
        getItemDataSaved();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemGrup = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/rak/list/${warehouse}`,
        { headers }
      )
      .then((data) => {
        setCodeRaks(data.data.response);
        setCodeRak(id)
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const getItemDataSaved = () => {
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/opname/item`, {

        opname_id: props.match.params.id

      }).then(async response => {
          let stateItem = [];

          await Promise.all(response.data.response.map(async (data) => {
              stateItem = [...stateItem, {
                  item_id: data.item_id,
                  item_name:data.item_name,
                  kode_item:data.item_code,
                  satuan:data.satuan,
                  qty_fisik: data.qty_fisik,
                  qty_sistem: data.qty_sistem,
                  ket:data.ket,
                  data: {
                    item_name: data.item_name,
                    kode_item:data.item_code,
                    satuan:data.satuan,
                    qty_fisik: data.qty_fisik,
                    qty_sistem: data.qty_sistem,
                    ket:data.ket,
                  },
              }];
          }));
          setSavedItems(stateItem);
      })
  }

  
  function EditData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty_fisik: dataItem.qty_fisik,
                qty_sistem: dataItem.qty_sistem,
                ket:dataItem.ket,
            }]);
    let data = {
      warehouse_id : parseInt(warehouse),
      username : usernameBuat,
      code_rak : coderak,
      keterangan: keterangan,
      direktur : username,
      status_d: parseInt(statusd),
      items : dataItems
    };
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/opname/update/${props.match.params.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        setSuccessAlert(response.data.message)
        setTimeout(() => (history.push("/admin/stock-opname/validasi-direktur")), 1000);
      })
      .catch(function (error) {
          setGagalAlert(error.response.data.message);
        });
  };

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={EditData}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

const hideAlert = () => {
  setAlert(null);
};


  return (
    <>
    {alert}
    {StokOpnames && StokOpnames === "1" ? (
      <div>
    <SimpleHeader name="Validasi Direktur" parentName="Inventori" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
              <Card>
                <CardBody>
                    <CardHeader>
                      <h3>Validasi Direktur</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="9">
                          <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={2}
                          >
                            Kode Rak
                          </Label>
                          <Col sm={4}>
                            <Input
                              disabled
                              autoComplete="off"
                              className="form-control-alternative"
                              name="kategory"
                              type="select"
                              value={coderak}
                              onChange={(e) => {
                                setCodeRak(e.target.value);
                              }}
                            >
                              <option value="">Kode Rak</option>
                              {coderaks.map((categorysss, key) => {
                                return (
                                  <option key={key} value={categorysss.rak_code}>
                                    {categorysss.rak_code}
                                  </option>
                                );
                              })}
                              </Input>
                          </Col>
                        </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={2}
                              >
                                Keterangan
                              </Label>
                              <Col sm={10}>
                                <Input
                                disabled
                                autoComplete="off"
                                  type="textarea"
                                  name="keterangan"
                                  placeholder="Masukan Keterangan"
                                  style={{
                                    fontSize: 14,
                                    paddingTop: 20,
                                    top: "50%",
                                    height: 117,
                                    resize: "none",
                                  }}
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Col
                              for="exampleEmail"
                              sm={2}
                            >
                              <Label className="form-control-label">Validasi</Label>
                            </Col>
                              <Col sm={6}>
                                <div style={{ display: "flex" }}>
                                  <div className="custom-control custom-radio mb-3">
                                    <Input
                                    autoComplete="off"
                                      className="custom-control-input"
                                      id="customRadio11"
                                      name="custom-radio-4"
                                      type="radio"
                                      value={5}
                                      checked={statusd === 5}
                                      onChange={() => setStatusD(5)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio11"
                                    >
                                    </Label>
                                    <b>SETUJU</b>

                                  </div>
                                  <div
                                    className="custom-control custom-radio mb-3"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <Input
                                    autoComplete="off"
                                      className="custom-control-input"
                                      id="customRadio12"
                                      name="custom-radio-4"
                                      type="radio"
                                      value={4}
                                      checked={statusd === 4}
                                      onChange={() => setStatusD(4)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio12"
                                    >
                                    </Label>
                                    <b>TOLAK</b>

                                  </div>
                                </div>
                              </Col>
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                    <CardBody>
                      <Table>
                              <thead>
                                <tr>
                                  <th>Nama Item</th>
                                  <th>Kode Item</th>
                                  <th>Qty Fisik</th>
                                  <th> Qty Sistem</th>
                                  <th>Satuan</th>
                                  <th>Keterangan</th>
                                </tr>
                              </thead>
                              {/* {savedItems === null ? ( */}
                                {/* // <tbody>
                                //    { 
                                //     savedItems.map((savedItem, key) => {
                                //         return (
                                //         <tr key={key}>
                                //             <td colspan="4"> */}
                                                  {/* <center><h3>DATA NOT FOUND</h3></center> */}
                                {/* //             </td>
                                //         </tr>
                                //           )
                                //         })
                                //     }
                                //   </tbody>
                                // ):( */}
                                  <tbody>
                                    { 
                                        savedItems.map((savedItem, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{savedItem.data.item_name}</td>
                                                    <td>{savedItem.data.kode_item}</td>
                                                    <td>{savedItem.qty_fisik}</td>
                                                    <td>{savedItem.qty_sistem}</td>
                                                    <td>{savedItem.satuan}</td>
                                                    <td>{savedItem.ket}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                  </tbody>
                                {/* )} */}
                              
                      </Table>
                    </CardBody>
                </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/stock-opname/validasi-direktur">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={()=>setQuestionAlert()}>
                          Simpan
                      </Button>
                </CardFooter>
              </Card>
          </div>
        </Row>
    </Container>  
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}