/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Container,
  CardImg,
} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function PermintaanBarangPoCabang() {
    const allInfo = JSON.parse(localStorage.allinfo);
    const Master = String(allInfo.privileges.filter((i) => i.privilege_name === "Laporan Master").map((p) => p.read_access));
	const Stock = String(allInfo.privileges.filter((i) => i.privilege_name === "Laporan Stok").map((p) => p.read_access));
	const Beli = String(allInfo.privileges.filter((i) => i.privilege_name === "Laporan Pembelian").map((p) => p.read_access));
	const Jual = String(allInfo.privileges.filter((i) => i.privilege_name === "Laporan Penjualan").map((p) => p.read_access));
	// const Lacak = String(allInfo.privileges.filter((i) => i.privilege_name === "Laporan Pelacakan").map((p) => p.read_access));
	const HRD = String(allInfo.privileges.filter((i) => i.privilege_name === "Laporan HRD").map((p) => p.read_access));
	const Finance = String(allInfo.privileges.filter((i) => i.privilege_name === "Laporan Finance").map((p) => p.read_access));
	// const Ecomm = String(allInfo.privileges.filter((i) => i.privilege_name === "Laporan Ecommerce").map((p) => p.read_access));

  return (
    <>
      <div>
      <SimpleHeader name="Laporan" parentName="Report" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <CardBody>
                    <Row>
                        <div className="card-columns">
                        {Master && Master === "1" && (
                            <Card>
                                <Link to="/admin/laporan/master">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/master.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {Stock && Stock === "1" && (
                            <Card>
                                <Link to="/admin/laporan/inventori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/stok.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {Beli && Beli === "1" && (
                            <Card>
                                <Link to="/admin/laporan/pembelian">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/pembelian.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {Jual && Jual === "1" && (
                            <Card>
                                <Link to="/admin/laporan/penjualan">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/penjualan.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                         
                         {HRD && HRD === "1" && (
                            <Card>
                                <Link to="/admin/laporan/hrd">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/hrd.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                         {/* {cetak && cetak === "1" && ( */}
                         {/* <Card>
                                <Link to="/admin/laporan/sdm">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/karyawan.png").default}
                                        top
                                    />
                                </Link>
                            </Card> */}
                        {/* )} */}
                         {Finance && Finance === "1" && (
                            <Card>
                                <Link to="/admin/laporan/finance">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/finance.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                         {/* {Ecomm && Ecomm === "1" && (
                            <Card>
                                <Link to="/admin/laporan/e-commerce">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/penjualan-digital.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )} */}
                         
                        <Card></Card>
                        </div>
					</Row>
                </CardBody>
          </div>
        </Row>
      </Container>
      </div>
    </>
  );
}