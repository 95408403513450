/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";

export default function CetakInvoiceSO(props) {
  const token = localStorage.token;
  const username = localStorage.username;
//   const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [harga, setHarga] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState("");
  const [pengiriman, setPengiriman] = useState([]);
  const [keterangan, setKeterangan] = useState("");
  const [qty, setQty] = useState(0);
  const [savedItems, setSavedItems] = useState([]);
  const [codeso, setCodeSo] = useState("");
  const [usernamea, setUsernamea] = useState("");
  const [alamat, setAlamat] = useState();
  const [diskonglobalnominal, setDiskonGlobalNominal] = useState();
  const [diskonglobalpersen,setDiskonGlobalPersen] = useState();
  const [ongkir, setOngkir] = useState(0);
  const [pajak, setPajak] = useState("");
  const [payment, setPayment] = useState();
  const [jangkawaktu1,setJangkaWaktu1] = useState(0);
  const [lainnya, setLainnya] = useState(0);
  const [supplier, setSupplier] = useState("");
  const [jangkaWaktu,setJangkaWaktu] = useState([]);
  const [warehouserfq, setWarehouseRfq] = useState("");
  const [coderfq, setCodeRfq] = useState("");
  const [phone, setPhone] = useState("");
  const [listItem,setListItem] = useState([]);
  const [waktu,setWaktu] = useState("");
  const [hargatotal, setHargaTotal] = useState([]);
  const [rowIndex, setRowIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [creator, setCreator] = useState("");
  const [validator1, setValidator1] = useState("");
  const [validator2, setValidator2] = useState("");
  const [sales, setSales] = useState("")

  useEffect(() => {
    getById();
    getItemDataSaved();
  }, []);


  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/invoice-so/cetak/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setCodeRfq(data.data.response.invoiceso.invoice_code);
        setSupplier(data.data.response.invoiceso.customer);
        setAlamat(data.data.response.invoiceso.address);
        setSales(data.data.response.invoiceso.sales);
        setWaktu(data.data.response.invoiceso.created)
        setWarehouseRfq(data.data.response.invoiceso.warehouse);
        setHargaTotal(data.data.response.invoiceso.price_total);
        setKeterangan(data.data.response.invoiceso.keterangan);
        setOngkir(data.data.response.invoiceso.ongkir);
        setPhone(data.data.response.invoiceso.phone);
        // setCodePo(data.data.response.invoiceso.po_code);
        // setLainnya(data.data.response.invoiceso.lainnya);
        setCreator(data.data.response.invoiceso.creator);
        setValidator1(data.data.response.invoiceso.validator1);
        setValidator2(data.data.response.invoiceso.validator2);
        setListItem(data.data.response.list);
        getItemDataSaved();
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = () => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/invoice-so/cetak/${props.match.params.id}`,{ headers }
    
    ).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.list.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                qty: data.qty,
                harga: data.harga,
                satuan: data.satuan,
                diskon_nominal: data.diskon_nominal,
                diskon_persen : data.diskon_persen,
                sub_total : data.sub_total,
                data: {
                    item_name: data.item_name,
                    harga: data.harga
                },
            }];
        }));

        setSavedItems(stateItem);
    })
}

const getCustomer = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/customer/list`,
        { headers }
      )
      .then((data) => {
        setCustomers(data.data.response);
        setCustomer(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


//    function EditData() {
//     setLoading(true);
//     let dataItems = [];
//         savedItems.map((dataItem) => dataItems = [...dataItems, 
//             { 
//                 item_id: dataItem.item_id, 
//                 qty: dataItem.qty, 
//                 harga:dataItem.harga,
//             }]);
//     let data = {
//         warehouse_id : parseInt(warehouse),
//         username : username,
//         code_so: codeso,
//         status_sj: 3,
//         customer_id: parseInt(customer),
//         pengiriman: parseInt(pengiriman),
//         keterangan: keterangan ,
//         items : dataItems
//     };
//     axios
//         .post(
//           `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/update/${props.match.params.id}`,
//           data,
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         )
//         .then(function (response) {
//           history.push("/admin/surat-jalan-so");
//         })
//         .then((json) => {
//           setLoading(false);
//         })
//         .catch(function (error) {
//           console.log(error);
//         });
//   }

const handleSubmit = (e) => {
    e.preventDefault();
    {
    //   EditData();
    }
  };

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }

  return (
    <>
        <Row>
          <div className="col">
            <CardBody>
            <Card>
                <Form onSubmit={handleSubmit}>
                <CardBody>
                    <CardHeader>
                        <Row md="12">
                            <Col md="8">
                                <img
                                    style={{ width: "20%" }}
                                    src={require("assets/img/theme/Hokky1.png").default}
                                />
                            </Col>
                            <Col md="4">
                                Head Office : {warehouserfq}<br></br>
                                Jl Menganti Karangan No.562 <br></br>
                                Surabaya - Jawa Timur<br></br>
                                Phone : 081 217 85 3300<br></br>
                                Email : hokkybangunan.sby@gmail.com
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardHeader>
                        <Row md="12">
                            <Col md="12">
                                <h3><b><center>INVOICE SO </center></b></h3>
                            </Col>
                        </Row>
                    </CardHeader>
                    <Table size="sm" responsive>
                        <tbody>
                            <tr>
                                <td>
                                    <Row md="24">
                                        <Col md="6">
                                        <div className="row align-items-center mb-3">
                                            <div className="col-4">Kode Invoice</div>
                                            <div className="col-1 text-center">:</div>
                                            <div className="col-5 ">
                                                <b>{coderfq}</b>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mb-3">
                                            <div className="col-4">Tanggal</div>
                                            <div className="col-1 text-center">:</div>
                                            <div className="col-4 ">
                                                <b>{waktu}</b>
                                            </div>
                                        </div>
                                        {/* <div className="row align-items-center mb-3">
                                            <div className="col-4">Kode RFQ</div>
                                            <div className="col-1 text-center">:</div>
                                            <div className="col-4 ">
                                                <b>---------------------------</b>
                                            </div>
                                        </div> */}
                                        <div className="row align-items-center mb-3">
                                            <div className="col-4">Keterangan</div>
                                            <div className="col-1 text-center">:</div>
                                            <div className="col-4 ">
                                                <b>{keterangan}</b>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mb-3">
                                            <div className="col-4"></div>
                                            <div className="col-1 text-center"></div>
                                            <div className="col-4 ">
                                            </div>
                                        </div>
                                        {/* <div className="row align-items-center mb-3">
                                            <div className="col-4">Alamat Kirim</div>
                                            <div className="col-1 text-center">:</div>
                                            <div className="col-4 ">
                                                <b>{}</b>
                                            </div>
                                        </div> */}
                                        </Col>
                                        <Col md="6">
                                        <div className="row align-items-center mb-3">
                                            <div className="col-4">Supplier</div>
                                            <div className="col-1 text-center">:</div>
                                            <div className="col-5 ">
                                                <b>{supplier}</b>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mb-3">
                                            <div className="col-4">Alamat</div>
                                            <div className="col-1 text-center">:</div>
                                            <div className="col-7 ">
                                                <b>{alamat}</b>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mb-3">
                                            <div className="col-4">Telephone</div>
                                            <div className="col-1 text-center">:</div>
                                            <div className="col-7 ">
                                                <b>{phone}</b>
                                            </div>
                                        </div>
                                        {/* <div className="row align-items-center mb-3">
                                            <div className="col-4">Email</div>
                                            <div className="col-1 text-center">:</div>
                                            <div className="col-4 ">
                                                <b>{}</b>
                                            </div>
                                        </div> */}
                                        {/* <div className="row align-items-center mb-3">
                                            <div className="col-4">Name Sales</div>
                                            <div className="col-1 text-center">:</div>
                                            <div className="col-4 ">
                                                <b>{usernamea}</b>
                                            </div>
                                        </div> */}
                                        {/* <div className="row align-items-center mb-3">
                                            <div className="col-4">Bank Account</div>
                                            <div className="col-1 text-center">:</div>
                                            <div className="col-4 ">
                                                <b></b>
                                            </div>
                                        </div>
                                        <div className="row align-items-center mb-3">
                                            <div className="col-4">Npwp</div>
                                            <div className="col-1 text-center">:</div>
                                            <div className="col-4 ">
                                                <b></b>
                                            </div>
                                        </div> */}
                                        </Col>
                                    </Row>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <CardBody>
                      <br></br>
                      <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th>
                            <b>Nama Item</b>
                            </th>
                            <th>
                            <b>Kode Item</b>
                            </th>
                            <th>
                            <b>Harga</b>
                            </th>
                            <th>
                            <b>Qty</b>
                            </th>
                            <th>
                            <b>Satuan</b>
                            </th>
                            <th>
                            <b>Disc %</b>
                            </th>
                            <th>
                            <b>Disc N</b>
                            </th>
                            <th>
                            <b>Sub Total</b>
                            </th>
                            {/* <th>
                            <b>Delivery</b>
                            </th> */}
                        </tr>
                        </thead>
                        <tbody>
                        {
                            savedItems.map((savedItem, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{savedItem.data.item_name}</td>
                                        <td>{savedItem.item_code}</td>
                                        <td>{formatRupiah(savedItem.harga)}</td>
                                        <td>{savedItem.qty}</td>
                                        <td>{savedItem.satuan}</td>
                                        <td>{savedItem.diskon_persen}</td>
                                        <td>{savedItem.diskon_nominal}</td>
                                        <td>{formatRupiah(savedItem.sub_total)}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                      </Table>
                      <Table size="sm" responsive>
                                    {/* <tr>
                                        <th colSpan={4}></th>
                                        <th>Jumlah Rp</th>
                                        <th>Rp. 4.900.000</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}></th>
                                        <th>hiscount</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}></th>
                                        <th>hPP</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <th colSpan={2}></th>
                                        <th>PPN</th>
                                        <th></th>
                                    </tr>
                                    <tr>
                                        <td>Terbilang</td>
                                        <td></td>
                                        <td></td>
                                        <td>Empat Juta Sembilan Ratur</td>
                                        <td></td>
                                        <td></td>
                                        <td>Rp. 4.900.000</td>
                                    </tr> */}
                                      <tbody>
                            <tr>
                                <td>
                                <Row md="12">
                                    <Col md="3">
                                    </Col>
                                    <Col md="3">
                                    </Col>
                                    <Col md="3">
                                    </Col>
                                    <Col md="3">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Total</div>
                                        <div className="col-2 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{formatRupiah(hargatotal)}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Diskon</div>
                                        <div className="col-2 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{formatRupiah()}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">PPN</div>
                                        <div className="col-2 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{formatRupiah()}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Ongkir</div>
                                        <div className="col-2 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{formatRupiah(ongkir)}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Grand Total</div>
                                        <div className="col-2 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{formatRupiah(hargatotal)}</b>
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                                </td>
                            </tr>
                            </tbody>
                      </Table>
                    </CardBody>
                    <CardHeader></CardHeader>
                        <center>Terms of Price, delivery & shipping required</center>
                        <CardFooter></CardFooter>
                    <Table size="sm" responsive>
                            <tbody>
                            <tr>
                                <td>
                                <Row md="12">
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Finance</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{creator}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>---------------------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Kepala Finance</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{validator1}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>---------------------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md="4">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Direktur</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{validator2}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-4">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-4 ">
                                            <b>---------------------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
                </Form>
              </Card>
            </CardBody>
              
          </div>
        </Row>
    </>
  );
}