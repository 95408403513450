/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

const CreateTransferRak = () => {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [keterangan,setKeterangan] = useState("");
  const [rak1, setRak1] = useState("");
  const [rak2, setRak2] = useState("");
  const [raks, setRaks] = useState([]);
  const [rakss, setRakss] = useState([]);
  const [savedItems, setSavedItems] = useState([]);
  const [isSearchShoww, setIsSearchShoww] = useState(false);
	const [allItemm, setAllItemm] = useState([]);
	const [queryy, setQueryy] = useState("");
  const headers = { Authorization: `Bearer ${token}` };
  const [qtyTotal, setTotalQty] = useState(0);
  const [qtyError, setQtyError] = useState(null);
  const [keteranganerror, setKeteranganError] = useState(null);
  const allInfo = JSON.parse(localStorage.allinfo);
  const transferRak = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Transfer Eksternal").map((p) => p.read_access));
  
  const validateForm = () => {
    let error = false;
    if (keterangan === "") {
      setKeteranganError("invalid");
      error = true;
    }
    savedItems.map((savedItem, key) => { 
      if (savedItem.qty > savedItem.qtysisa) {
        setQtyError("invalid");
        error = true;
      }
    })
    return error;
};

const handleformvalidasi = (e) => {
  e.preventDefault();
  if (!validateForm()) {
      setQuestionAlert();
  }
}

  useEffect(() => {
    const getData = setTimeout(async() => {
        if(queryy != ''){
            axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/item-stok/list-by-rak`, { item_name: queryy , code_rak:rak1, per_page: 10 ,warehouse_id: parseInt(warehouse)},{ headers })
            .then((res) => {
                setAllItemm(res.data);
                setIsSearchShoww(true);
            })
            .catch(function (error) {
                setAllItemm(null);
            });
        }else{
      setAllItemm(null);
        }
      }, 1000)
      return () => clearTimeout(getData)
  }, [queryy]);
  
  
  const handleEditQty = (index, value) => {
    let updateList = savedItems;
    let aqtyTotal = parseInt(updateList[index].harga) + value;
    
    if(!isNaN(value) && value.length > -1){
      updateList[index] = {...updateList[index], qty: value};
      setSavedItems(updateList);
      setTotalQty(qtyTotal + aqtyTotal);
    }else{return false}
  }
  
  
  // save
  const saveItemOrUpdate = (item) => {
    let oldobj = savedItems.find(o => o.item_id === item.id);
    if(oldobj===undefined){
      setSavedItems([...savedItems,{
        item_id: item.item_id,
        item_name: item.item_name,
        qtysisa : item.max_qty,
        satuan : item.satuan,
        qty: 1,
      }]);
      
    }else{
      let index = savedItems.findIndex(e=>e.item_id===item.id);
      let updateList = savedItems;
      let qtyupdate = parseInt(updateList[index].qty)+parseInt(1);
      updateList[index] = {...updateList[index], qty: qtyupdate};
      setSavedItems(updateList);
    };
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    {
      setQueryy("");
      setIsSearchShoww("");
      return true
    }
  };

  useEffect(() => {
    getRak1();
    getRak2();
  }, []);

  const getRak1 = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/rak/list/${warehouse}`,
        { headers }
      )
      .then((data) => {
        setRaks(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getRak2 = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/rak/list/${warehouse}`,
        { headers }
      )
      .then((data) => {
        setRakss(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
            }]);
    let data = {
      warehouse_id : parseInt(warehouse),
      username : username,
      code_rak1: rak1,
      code_rak2: rak2,
      keterangan: keterangan ,
      items : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/pindah-rak/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/transfer-rak/page")), 1000);
        })
        .catch(function (error) {
            setGagalAlert(error.response.data.message);
        });
        };

        const setSuccessAlert = (id) => {
        setAlert(
          <SweetAlert
            success
            showConfirm
            confirmBtnText="Ok"
            title={id}
            onCancel={hideAlert}
            onConfirm={hideAlert}
          />
        )
        }
  
        const setGagalAlert = (id) => {
        setAlert(
          <SweetAlert
            danger
            showConfirm
            confirmBtnText="Ok"
            title={id}
            onCancel={hideAlert}
            onConfirm={hideAlert}
            />
          );
        };
  
        const setQuestionAlert = () => {
        setAlert(
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Lanjutkan"
            confirmBtnBsStyle="danger"
            title="Apakah Kamu Yakin?"
            onConfirm={CreateData}
            onCancel={hideAlert}
            focusCancelBtn
          />
          );
        };
  
        const hideAlert = () => {
        setAlert(null);
        }


  const deleteItem = (id) => {
    let array = [...savedItems];

    let index = array.findIndex(object => {
        return object.item_id === id;
    });

    if (index !== -1) {
        array.splice(index, 1);
        setSavedItems(array);
    }
}

  return (
    <>
    {alert}
    {transferRak && transferRak === "1" ? (
        <div>
    <SimpleHeader name="Buat Transfer Internal" parentName="Inventori" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
              <CardBody>
                <Form onSubmit={handleformvalidasi}>
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <h3>Buat Transfer Internal</h3>
                  </CardHeader>
                  <CardBody>
                    <Row md="12">
                        <Col md="6">
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Rak Asal
                            </Label>
                            <Col sm={6}>
                              <Select2
                                defaultValue="1"
                                // data-minimum-results-for-search="Infinity"
                                value={rak1}
                                isSearchable
                                onChange={(e) => {
                                  setRak1(e.target.value);
                                }}
                                options={{
                                  placeholder: "Pilih Rak"
                                }}
                                data={raks.map((customer) => {
                                  return (
                                  { id: customer.rak_code, text:customer.rak_code }
                                  );
                                })}
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                        <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Rak Tujuan
                            </Label>
                            <Col sm={6}>
                              <Select2
                                defaultValue="1"
                                value={rak2}
                                onChange={(e) => {
                                  setRak2(e.target.value);
                                }}
                                options={{
                                  placeholder: "Pilih Rak"
                                }}
                                data={rakss.map((customer) => {
                                  return (
                                  { id: customer.rak_code, text: customer.rak_code}
                                  );
                                })}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Keterangan
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                type="textarea"
                                name="keterangan"
                                rows = "4"
                                invalid={keteranganerror === "invalid"}
                                placeholder="Masukan Keterangan"
                                value={keterangan}
                                onChange={(e) => {
                                  setKeterangan(e.target.value);
                                  if (e.target.value !== "") {
                                    setKeteranganError("");
                                  }
                                }}
                              />
                              <FormFeedback>
                                Keterangan tidak boleh kosong
                              </FormFeedback>
                            </Col>
                          </FormGroup>
                        </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                        <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Qty</b></th>
                                <th><b>Satuan</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="qty"
                                          invalid={qtyError === "invalid"}
                                          type="number"
                                          value={savedItem.qty}
                                          onChange={(e) => {
                                              handleEditQty(key, e.target.value);
                                              if (savedItem.qty > savedItem.qtysisa) {
                                                setQtyError("");
                                              }
                                          }}/>
                                            <FormFeedback>
                                                  Max Barang {savedItem.qtysisa} 
                                              </FormFeedback>
                                      </td>
                                      <td>{savedItem.satuan}</td>
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              <tr>
                                <td>
                                  <><Form onSubmit={handleSubmit}>
                                          <Input
                                            autoComplete="off"
                                            placeholder="Masukan Item Manual"
                                            type="search"
                                            value={queryy}
                                            onChange={(e) => setQueryy(e.target.value)}
                                          />
                                        {isSearchShoww && queryy && (
                                        <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                          <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                            <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
                                          </div>
                                          {allItemm?.response ? (
                                            allItemm.response.map((item) => (
                                              <CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {saveItemOrUpdate(item);setQueryy('');setIsSearchShoww(false);}}>
                                                <div>
                                                  <b>Nama item:</b> {item.item_name}
                                                </div>
                                              </CardBody>
                                            ))
                                          ) : (
                                            <div className="text-center mb-3 text-danger">Item "{queryy}" tidak ada bosku!</div>
                                          )}
                                        </Card>
                                      )}
                                  </Form></>
                                  </td>
                                 </tr>
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter>
                              <Link className="btn btn-info" to="/admin/transfer-rak">
                                  Kembali
                              </Link>
                              <Button color="danger" type="submit">
                                  Simpan
                              </Button>
                        </CardFooter>
                </Card>
              </Form>
              </CardBody>
          </div>
        </Row>
    </Container>  
    </div>
    ):(
      <Halaman404 />
    )};
    </>
  );
}

export default CreateTransferRak;