/*eslint-disable*/
import React, { useEffect, useState, useRef } from 'react';
import { 
    Card, 
    Row, 
    Col, 
    CardBody,
    Input,
    Button,
    FormGroup,
    CardTitle,
    CardHeader,
    Container,
    Label,
    Badge 
} from 'reactstrap';
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import GoogleMapReact from 'google-map-react';
import SweetAlert from "react-bootstrap-sweetalert";
import ModalCetakAbsensi from './ModalCetakAbsensi';

const AbsensiHrd = () => {
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  const [latmasuk, setLatMasuk] = useState([]);
  const [latkeluar, setLatKeluar] = useState([]);
  const [allPenawaranPo, setAllPenawaranPo] = useState([]);
  // const [tanggal, setTanggal] = useState(new Date().toISOString().split("T")[0]);
  const [tanggal, setTanggal] = useState("");
  const [name,setName] = useState("")
  const [cabang, setCabang] = useState(warehouse)
  const [cabangs, setCabangs] = useState([]);
  const [cekin, setCekin] = useState("");
  const [cekout, setCekOut] = useState("");
  const [alert, setAlert] = React.useState(null);
  const [openModalCetak, setOpenModalCetak] = useState(false);
  const [dataModalCetak, setDataModalCetak] = useState({
    id: 0,
  });
  const toggleModal = () => setOpenModalCetak(!openModalCetak);

  const displayModalCetak = (id,status_kerja) => {
    setDataModalCetak({
      id: id,
      status_kerja : status_kerja,
    });
    setOpenModalCetak(true);
  };

  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: -7.3106144,
      lng: 112.6841525
      // lat:-6.200000, 
      // lng: 106.816666
    },
    zoom: 8
  });

  // const [absensi, setAbsensi] = useState({
  //   absensiPage: [],
  //   absensiCheckInPage: [],
  //   absensiCheckOutPage: []
  // });

  const onClickMap=()=>{
    return false;
  }

  const apiIsLoaded = async (map, maps, center) => {
    if (map && defaultProps.center.lat > 0) {
      setDefaultProps({
        lat: lat,
        lng: long
      })
      await loadMapClick(map, maps);
    }
  };

  const loadMapClick = async (map, maps) => {
    const circle = new window.google.maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.3,
      map,
      center: defaultProps.center,
      radius: 500
    });
  };
  
  useEffect(() => {
    getCabang();
  }, []);

  const getCabang = () => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`, { headers
      })
      .then(data => {
        setCabangs(data.data.response);
        // setCabang(id);
      })
        .catch(function (error) {
          console.log(error)
        })
    }
  
  const updateDataTable = (page, tanggal, cabang, name, cekin, cekout) => {
    getPenawaranPo(page, tanggal, cabang, name,cekin,cekout);
    getCheckinAbsen(page, tanggal, cabang, name,cekin,cekout);
    getCheckoutAbsen(page, tanggal, cabang, name,cekin,cekout);
    setTanggal(tanggal);
    setCabang(cabang);
    setName(name);
    setCekin(cekin);
    setCekOut(cekout);
  }
  
  useEffect(() => {
    getPenawaranPo();
    getCheckinAbsen();
    getCheckoutAbsen();
  }, []);

  // const EditData = async (e) => {
	// 	setTimeout(getPenawaranPo(), 1000);
	// 	setTimeout(getCheckinAbsen, 1000);
	// 	setTimeout(getCheckoutAbsen, 1000);

	// };

  const EditData = async (e) => {
		setTimeout(handleClick, 1000);
    handleClick()
	};

	const inputRef = useRef();
	const cekPromo = useRef();
	function handleClick() {
		cekPromo.current.onClick();
	}

  // const handlemit = () => {(getPenawaranPo(),getCheckinAbsen(),getCheckoutAbsen())}

  // fungsi dari ambil data
  const getPenawaranPo = (page, search_date = null, warehouse_id = "", name = "",check_in="", check_out=""
  ) => {
    let filter = { page: page, per_page: 500
    };
    if (search_date !== null) {
      filter = Object.assign(filter, { search_date: search_date});
    }
    if (warehouse_id !== "") {
      filter = Object.assign(filter, { warehouse_id: warehouse_id});
    }
    if (name !== "") {
      filter = Object.assign(filter, { name: name });
    }
    if (check_in !== "") {
      filter = Object.assign(filter, { check_in: check_in });
    }
    if (check_out !== "") {
      filter = Object.assign(filter, { check_out: check_out });
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/absensi-karyawan/page`, data, {
        headers,
      })
      .then((data) => {
        // setImage(data.data.response[0].karyawan_image)
        setAllPenawaranPo(data.data.response);
      })
      .catch(function (error) {
        setAllPenawaranPo(error.response.data.response);
        // setGagalAlert(error.response.data.message);
  });
}


  const getCheckinAbsen = ( page, search_date = null, warehouse_id = "", name = "",check_in="", check_out=""
  ) => {
    let filter = { page: page, per_page: 500
    };
    if (search_date !== null) {
      filter = Object.assign(filter, { search_date: search_date });
    }
    if (warehouse_id !== "") {
      filter = Object.assign(filter, {warehouse_id: warehouse_id });
    }
    if (name !== "") {
      filter = Object.assign(filter, {name: name });
    }
    if (check_in !== "") {
      filter = Object.assign(filter, { check_in: check_in });
    }
    if (check_out !== "") {
      filter = Object.assign(filter, { check_out: check_out });
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/absensi-karyawan/check-in/page`, data, {
        headers,
      })
      .then((data) => {
        setLatMasuk(data.data.response);

      })
      .catch(function (error) {
        // setGagalAlert(error.data.data.message);
  });
}

  const getCheckoutAbsen = (page, search_date = null, warehouse_id = "", name = "",check_in="", check_out=""
  ) => {
    let filter = { page: page, per_page: 500
    };
    if (search_date !== null) {
      filter = Object.assign(filter, { search_date: search_date });
    }
    if (warehouse_id !== "") {
      filter = Object.assign(filter, {warehouse_id: warehouse_id });
    }
    if (name !== "") {
      filter = Object.assign(filter, {name: name });
    }
    if (check_in !== "") {
      filter = Object.assign(filter, { check_in: check_in });
    }
    if (check_out !== "") {
      filter = Object.assign(filter, { check_out: check_out });
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/absensi-karyawan/check-out/page`, data, {
        headers,
      })
      .then((data) => {
        setLatKeluar(data.data.response);

      })
      .catch(function (error) {
        // setGagalAlert(error.data.data.message);
  });
}


const setGagalAlert = (id) => {
setAlert(
<SweetAlert
danger
showConfirm
confirmBtnText="Ok"
title={id}
onCancel={hideAlert}
onConfirm={hideAlert}
/>
);
};

const hideAlert = () => {
setAlert(null);
};

  const reset = () => {
    setTanggal(tanggal);
    setCabang(cabang)
    setName("");
    updateDataTable(1, "", "");
  }

  const googleMapsApiKey = `${process.env.REACT_APP_MAP}`
  
  const PointMasuk = () => 
  <div style={{
    padding: '5px 5px',
    display: 'inline-flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
    transform: 'translate(-50%, -50%)'
  }}>
    <img src="https://i.ibb.co/XYf5GGc/masuk.png" width="40px"/>
  </div>;

const PointKeluar = () => 
<div style={{
  padding: '5px 5px',
  display: 'inline-flex',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
  transform: 'translate(-50%, -50%)'
}}>
    <img src="https://i.ibb.co/hCtKVzc/pulang.png" width="40px"/>
</div>;

  return (
    <div>
      {alert}
    <Button hidden color="danger" outline onClick={handleClick}>  Klik Fungsi EditData
    </Button>
    <Button  hidden color="danger" outline ref={cekPromo} onClick={()=>{getPenawaranPo();getCheckinAbsen();getCheckoutAbsen()}}>Klik Cek promo  </Button>
    <SimpleHeader name="Absensi Karyawan " parentName="SO" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Row md="12">
						  <Col md="9">
                <Card>
                  <CardHeader>
                    <div style={{ justifyContent: "space-between" }}
                    >
                      {/* <h3>Maps</h3> */}
                      <div style={{ textAlign: "left" }}>
                          <Row md="12">
                            <Col md="3">
                              <FormGroup>
                                <Label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect3"
                                    >
                                  Tanggal
                                </Label>
                                <Input
                                  autoComplete="off"
                                  type="date"
                                  value={tanggal}
                                  onChange={e => updateDataTable(1, e.target.value, cabang, name, cekin, cekout)}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="3">
                            <FormGroup>
                                <Label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect3"
                                    >
                                  Cabang
                                </Label>
                              <Input
                                autoComplete="off"
                                type="select"
                                placeholder="Masukan Cabang"
                                value={cabang}
                                onChange={e => updateDataTable(1, tanggal, e.target.value, name, cekin, cekout)}
                              >
                                {cabangs.map((warehouse2, key) => {
                                      return (
                                          <option key={key} value={warehouse2.id}>
                                          {warehouse2.name}
                                          </option>
                                      );
                                      })}
                              </Input>
                            </FormGroup>
                            </Col>
                            <Col md="3">
                            <FormGroup>
                                <Label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect3"
                                    >
                                  Nama
                                </Label>
                              <Input
                                autoComplete="off"
                                type="text"
                                placeholder="Masukan Nama"
                                value={name}
                                onChange={e => updateDataTable(1, tanggal, cabang, e.target.value, cekin, cekout )}
                              />
                              </FormGroup>
                            </Col>
                            {/* <Col md="3">
                            <FormGroup>
                                <Label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect3"
                                    >
                                  Check In
                                </Label>
                              <Input
                                autoComplete="off"
                                type="date"
                                placeholder="Masukan Nama"
                                value={cekin}
                                onChange={e => updateDataTable(1, tanggal, cabang, name, e.target.value, cekout )}
                              />
                              </FormGroup>
                            </Col> */}
                          </Row>
                          {/* <Row md="12">
                            <Col md="3">
                            <FormGroup>
                                <Label
                                        className="form-control-label"
                                        htmlFor="exampleFormControlSelect3"
                                    >
                                  Check Out
                                </Label>
                              <Input
                                autoComplete="off"
                                type="date"
                                placeholder="Masukan Nama"
                                value={cekout}
                                onChange={e => updateDataTable(1, tanggal, cabang, name, cekin, e.target.value )}
                              />
                              </FormGroup>
                            </Col>
                            <Col md="2">
                            </Col>
                          </Row> */}
                      </div>
                    </div>
                  </CardHeader>
                  <div style={{ height: '  70vh', width: '100%' }}>
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: googleMapsApiKey }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                        onClickMap={onClickMap}
                        isLoopMaker={true}
                        apiIsLoaded={apiIsLoaded}
                      >
                        {latmasuk.map((latmasuk, key) => {
                            return (
                                <PointMasuk key={key}
                                lng={latmasuk.longitude_dtg}
                                lat={latmasuk.latitude_dtg}
                              />
                            );
                        })}
                        {latkeluar.map((latkeluar, key) => {
                            return (
                                <PointKeluar key={key}
                                lng={latkeluar.longitude_plg}
                                lat={latkeluar.latitude_plg}
                              />
                            );
                        })}
                      </GoogleMapReact>
                      {/* <Maps
                      defaultCenter={defaultProps.center}
                      defaultZoom={defaultProps.zoom}
                      onClickMap={onClickMap}
                      isLoopMaker={true}
                      apiIsLoaded={apiIsLoaded}
                      absensi={absensi}
                    // isSearch={true}
                    // long={long}
                    // lat={lat}
                    // address={selectAddress}
                    // setItem={setItem}
                    // setLong={setLong}
                    // setLat={setLat}
                    /> */}
                    </div>
                </Card>
              </Col>
              <Col md="3">
                <Card>
                  <CardHeader>
                  <h3>Riwayat Absen</h3>
                  </CardHeader>
                  {allPenawaranPo === null ? (
                     <CardHeader>
                          <center><h3>DATA NOT FOUND</h3></center>
                      </CardHeader>
                    ):(
                    <div style={{ height: '  73vh', overflowY: "auto" ,cursor: "pointer" }}>
                            {allPenawaranPo.map((v) => (
                            <Card style={{ marginBottom: "0rem"}} onClick={() => displayModalCetak(v.id, v.status_kerja)}>
                              <CardBody>
                                <Row>
                                  <div className="col">
                                    <CardTitle className="h5 text-uppercase text-muted mb-0 text-black">
                                    {v.check}&nbsp;{v.status_kerja === 1 ? (
                                      <Badge color="success">{v.keterangan}</Badge>
                                  ) : v.status_kerja === 2 ? (
                                      <Badge color="danger">{v.keterangan}</Badge>
                                  ): <Badge color="warning">{v.keterangan}</Badge>}
                                    </CardTitle>
                                    <span className="h5 font-weight-bold mb-0 text-black">
                                    {v.name}
                                    </span>
                                  </div>
                                  <Col className="col-auto">
                                  <a
                                    className="avatar rounded-circle mr-3"
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                  >
                                    {v.karyawan_image === "" ?(
                                          <img
                                            alt=""
                                            src={"https://i.ibb.co/bH9JpRW/profil.png"}
                                          /> 
                                        ):(
                                            <img
                                                alt=""
                                                src={v.karyawan_image}
                                              />
                                        )}
                                  </a>
                                      
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                            ))
                        } 
                    </div>
                    )}
                    {/* <div>
                        {allPenawaranPo.map((absensi) => {
                            <Media className="align-items-center" onClick={() => displayModalCetak(absensi.id)}>
                              <a
                                className="avatar rounded-circle mr-3"
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                {absensi.karyawan_image === "" ?(
                                    <img
                                      alt=""
                                      src={"https://i.ibb.co/bH9JpRW/profil.png"}
                                    />
                                ):(
                                  <img
                                    alt=""
                                    src={absensi.karyawan_image}
                                  />
                                )}
                              </a>
                                <Media>
                                <div style={{ textAlign: "left" }}>
                                  <span className="mb-3 text-sm">
                                  {absensi.name}&nbsp; 

                                  {absensi.status_kerja === 1 ? (
                                      <Badge color="success"> In {absensi.keterangan}</Badge>
                                  ) : absensi.status_kerja === 2 ? (
                                      <Badge color="danger"> Out {absensi.keterangan}</Badge>
                                  ): <Badge color="warning">{absensi.keterangan}</Badge>}
                                  <br></br>{absensi.check} 
                                  </span>
                                </div>
                                </Media>
                            </Media>
                          })
                        }
                    </div> */}
                </Card>
              </Col>
            </Row>
          </div>
        </Row>
        {openModalCetak && (
        <ModalCetakAbsensi
          open={openModalCetak}
          data={dataModalCetak}
          toggle={toggleModal}
        />
        )}
        </Container>
    </div>
  );
}

export default AbsensiHrd;