/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
	Col,
	Button,
    Badge,
	Table,
	Container,
    Modal, ModalHeader, ModalBody

} from "reactstrap";
import { Link} from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment"
import Halaman404 from "views/404";
import CurrencyInput from 'react-currency-input-field';


export default function PromoIndexBudget() {
    const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle1 = () => setModal1(!modal1);
    const [alldata,setAlldata] = useState("")
	const [modal1, setModal1] = useState(false);
  const redirectPrefix = `/admin/promo/promo-budget/edit/`;
	const [page, setPage] = useState(1);
	const [perPage, setPerpage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [currentSort, setCurrentSort] = useState("");
  const [itemId, setItemId] = useState(1);
  const [allItem, setAllItem] = useState([]);
  const [isSearchShow, setIsSearchShow] = useState(false);
  const token = localStorage.token;
  const headers = { Authorization: `Bearer ${token}` };

  const [namapromo,setNamaPromo] = useState("");
  const [keterangan,setKeterangan] = useState("");
  const [uniontype,setUnionType] = useState("");
  const [senin,setSenin] = useState(2);
  const [selasa,setSelasa] = useState(2);
  const [rabu,setRabu] = useState(2);
  const [kamis,setKamis] = useState(2);
  const [jumat,setJumat] = useState(2);
  const [sabtu,setSabtu] = useState(2);
  const [minggu,setMinggu] = useState(2);
  const [januari,setJanuari] = useState(2);
  const [februari,setFebruari] = useState(2);
  const [maret,setMaret] = useState(2);
  const [april,setApril] = useState(2);
  const [mei,setMei] = useState(2);
  const [juni,setJuni] = useState(2);
  const [juli,setJuli] = useState(2);
  const [agustus,setAgustus] = useState(2);
  const [september,setSeptember] = useState(2);
  const [oktober,setOktober] = useState(2);
  const [november,setNovember] = useState(2);
  const [desember,setDesember] = useState(2);
  const [warehouse,setWarehouse] = useState([]);
  const [warehouses,setWarehouses] = useState([]);
  const [pic,setPic]=useState("")
  const [karyawans,setKaryawans] = useState([]);
  const [start,setStart] = useState("");
  const [end,setEnd] = useState("");
  const [status,setStatus] = useState(2);
  const [budgetawal,setBudgetAwal] = useState(0);
  const [kondisi,setKondisi] = useState("")
  const [query, setQuery] = useState(null);
  const [codepromo, setCodePromo] = useState("");
  const [globaltype, setGlobalType] = useState("");
  const [potonganpertransaksi, setPotonganPerTransaksi] = useState(0);
  const allInfo = JSON.parse(localStorage.allinfo);
  const promoToko = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Promo Toko").map((p) => p.read_access));

	useEffect(() => {
		getItems();
        getCabang();
	},[page]);

    const getCabang = () => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`, { headers
        })
        .then(data => {
          setWarehouses(data.data.response);
        })
          .catch(function (error) {
            console.log(error)
          })
      }

    const getKaryawan = (id) => {
      const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
      };
      axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/karyawan/list/${id}`, { headers })
          .then((data) => {
              setKaryawans(data.data.response);
          })
          .catch(function (error) {
              console.log(error);
          });
  };

	//cari item
	useEffect(() => {
		const getData = setTimeout(async() => {
			if(query != ""){
				axios
				.post(`${process.env.REACT_APP_API_BASE_URL}/promo/page`, { promo_name: query , type: 2,  },{ headers })
				.then((res) => {
					setAllItem(res.data);
					setIsSearchShow(true);
				})
				.catch(function (error) {

				});
			}else{
				// getItems();
			}
		  }, 100)
		  return () => clearTimeout(getData)
	}, [query]);

	//get list item berdasarkan warehouse
	const getItems = async () => {
		let filter = {
			page: page,
			per_page: 1,
            type:2
		};
		const data = filter;
		const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/promo/page`, data, { headers });
		if(res.data.response.id !== null){
			getById(res.data.response[0].id);
			setItemId(res.data.response[0].id);
			setTotalPages(res.data.total_page);
		}
	};

	  const getById = (id) => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/promo/get/poin-dua/${id}`,
	        { headers }
	      )
	      .then((data) => {
            setBudgetAwal(data.data.response.budget_awal);
            setPotonganPerTransaksi(data.data.response.potongan_per_transaksi);
            setKondisi(data.data.response.conds);
            setCodePromo(data.data.response.promo_code);
            setNamaPromo(data.data.response.promo_name);
            setKeterangan(data.data.response.keterangan)
            setGlobalType(data.data.response.global_type)
            setUnionType(data.data.response.union_type)
            setSenin(data.data.response.senin)
            setSelasa(data.data.response.selasa)
            setRabu(data.data.response.rabu)
            setKamis(data.data.response.kamis)
            setJumat(data.data.response.jumat)
            setSabtu(data.data.response.sabtu)
            setMinggu(data.data.response.minggu)
            setJanuari(data.data.response.januari)
            setFebruari(data.data.response.februari)
            setMaret(data.data.response.maret)
            setApril(data.data.response.april)
            setMei(data.data.response.mei)
            setJuni(data.data.response.juni)
            setJuli(data.data.response.juli)
            setAgustus(data.data.response.agustus)
            setSeptember(data.data.response.september)
            setOktober(data.data.response.oktober)
            setNovember(data.data.response.november)
            setDesember(data.data.response.desember)
            setStart(moment(data.data.response.start_date, "YYYY/MM/DD").format("YYYY-MM-DD"));
            setEnd(moment(data.data.response.end_date, "YYYY/MM/DD").format("YYYY-MM-DD"));
            setStatus(data.data.response.active_flag)
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

		let paginationOption = {
		page: page,
		alwaysShowAllBtns: true,
		override: true,
		showTotal: true,
		withFirstAndLast: false,
		sizePerPage: perPage,
		onPageChange: (page) => {
			updateDataTable(page, perPage, currentSort);
		},
		sizePerPageRenderer: () => (
			<div className="dataTables_length" id="datatable-basic_length">
				<label
                className="form-control-label"
                >
					Show{" "}
					{
						<select
							name="datatable-basic_length"
							aria-controls="datatable-basic"
							className="form-control form-control-sm"
							onChange={(e) => {
								updateDataTable(page, e.target.value, currentSort);
							}}>
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="25">25</option>
							<option value="50">50</option>
						</select>
					}{" "}
					entries.
				</label>
			</div>
		),
	};

		const updateDataTable = (page, perPage, sort) => {
		setPage(page);
		setPerpage(perPage);
		setRowIndex((page - 1) * perPage);
		setCurrentSort(sort);
	};

		const handleTableChange = (type, { sortField, sortOrder }) => {
		if (type === "sort") {
			let sort = `${sortField} ${sortOrder}`;
			updateDataTable(page, perPage, sort);
		}
	};

		const searchShow = (item) => {
		getById(item.id);
		setIsSearchShow(false);
	};

	const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

	const formatRupiah = (money) => {
		return new Intl.NumberFormat('id-ID',
			{ style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
		).format(money);
	  }

      const getDataLog = (id) => {
		let filter = { 
			transaction : id,
			features : "MASTER10",
			warehouse_id : 0
		}
		const data = filter;
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
			setDataLog(data.data.response);
			setModal1(!modal1);
	
		})
		  .catch(function (error) {
			setGagalAlert(error.response.data.message);
		  })
	  }
	
	  const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
		);
		};
		
		const hideAlert = () => {
		  setAlert(null);
		};

        const badgetAwal = (e) => {
            const value = e === undefined ? 'undefined' : e;
            setBudgetAwal(value || ' ');
            };

            const PromoPerTransaksi = (e) => {
                const value = e === undefined ? 'undefined' : e;
                setPotonganPerTransaksi(value || ' ');
                };


	return (
		<>
        {alert}
            {promoToko && promoToko === "1" ? (
      <div>
				<SimpleHeader name="Promo Perorangan" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
								<Card className="position-sticky boxShadow" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
											<Col md="5 d-lg-block d-none">
												<Button onClick={() => setPage(1)} disabled={page === 1} color="danger" type="button">
													First
												</Button>
												<Button onClick={() => setPage((page) => page - 1)} disabled={page === 1} color="success" type="button">
												<i className="fa fa-angle-left" /> Prev
												</Button>
												<Button onClick={() => setPage((page) => page + 1)} disabled={page >= totalPages} color="success" type="button">
													Next <i className="fa fa-angle-right" />
												</Button>
												<Button onClick={() => setPage(totalPages)} disabled={page == totalPages} color="warning" type="button">
													End
												</Button>
											</Col>
											<Col md="3">
												<FormGroup row>
													<Col sm={10}>
														<Input
															placeholder="Search promo"
															type="search"
															value={query}
															onChange={(e) => setQuery(e.target.value)}
														/>
													</Col>
												</FormGroup>
											</Col>
											<Col md="4 d-lg-block d-none">
												<div style={{ textAlign: "right" }}>
                                                <Button color="warning" onClick={() => (getDataLog(itemId))}>
												<i className="fa fa-info-circle" aria-hidden="true"></i>
												</Button>
                                                    {kondisi > 3 ?(
																""
															):(
                                                                <Link className="btn btn-info" to={redirectPrefix + itemId}
                                                                >
                                                                    <i className="fas fa-user-edit" /> Edit
                                                                </Link>
                                                            )
                                                        }
													<Link className="btn btn-danger" to="/admin/promo/promo-budget/create">
                                                        <i className="fas fa-plus" /> Tambah
													</Link>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
									{isSearchShow && query && (
										<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
											<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
												<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
											</div>
											<span className="text-center mb-3">
												<b>Pencarian berdasarkan:</b> {query}
											</span>
											{allItem?.response ? (
												allItem.response.map((item) => (
													<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" onClick={() => searchShow(item)}style={{minHeight: 'auto'}}>
														<div className=" d-flex align-items-center">
															<div className="ml-3">
																<div>
																	<b>Nama</b> {item.promo_name}
																</div>
															</div>
														</div>
													</CardBody>
												))
											) : (
												<div className="text-center mb-3 text-danger">Item "{query}" tidak ada bosku!</div>
											)}
										</Card>
									)}
								<CardBody>
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h3>Promo</h3>
                          <div style={{ textAlign: 'right' }}>
                            <h1>
                              <Badge color="success">
                                {globaltype}
                              </Badge>
                            </h1>
                          </div>
                      </div>
                  </CardHeader>
                  <Row md="12">
                      <Col md="6">
                          <CardBody>
                              <FormGroup row>
                                  <Label
                                  className="form-control-label"
                                  for="exampleEmail" sm={4}>
                                      Kode Promo
                                  </Label>
                                  <Col sm={7}>
                                      <Input
                                        disabled
                                          autoComplete="off"    
                                          className="form-control-alternative"
                                          type="text"
                                          name="barname"
                                          placeholder="Masukan Kode Promo"
                                          value={codepromo}
                                          onChange={(e) => {
                                            setCodePromo(e.target.value);
                                          }}
                                      />
                                  </Col>
                              </FormGroup>
                              <FormGroup row>
                                  <Label
                                  className="form-control-label"
                                  for="exampleEmail" sm={4}>
                                      Nama Promo
                                  </Label>
                                  <Col sm={7}>
                                      <Input
                                      disabled
                                          autoComplete="off"    
                                          className="form-control-alternative"
                                          type="text"
                                          name="barname"
                                          placeholder="Masukan Nama Promo"
                                          value={namapromo}
                                          onChange={(e) => {
                                              setNamaPromo(e.target.value);
                                          }}
                                      />
                                  </Col>
                              </FormGroup>
                              <FormGroup row>
                                    <Label
                                    className="form-control-label"
                                    for="exampleEmail" sm={4}>
                                        Cabang
                                    </Label>
                                    <Col sm={7}>
                                        <Input
                                        disabled
                                          autoComplete="off"
                                            className="form-control-alternative"
                                            type="select"
                                            value={warehouse}
                                            onChange={(e) => {
                                                setWarehouse(e.target.value);
                                                getKaryawan(e.target.value);
                                            }}
                                        >
                                          <option value=''>Pilih Cabang</option>
                                          {warehouses.map((warehouse2, key) => {
                                              return (
                                                  <option key={key} value={warehouse2.id}>
                                                  {warehouse2.name}
                                                  </option>
                                              );
                                              })}
                                        </Input>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label
                                    className="form-control-label"
                                    for="exampleEmail" sm={4}>
                                        PIC
                                    </Label>
                                    <Col sm={7}>
                                        <Input
                                        disabled
                                          autoComplete="off"
                                            className="form-control-alternative"
                                            type="select"
                                            value={pic}
                                            onChange={(e) => {
                                                setPic(e.target.value);
                                            }}
                                        >
                                          <option value=''>Pilih PIC</option>
                                          {karyawans.map((warehouse2, key) => {
                                              return (
                                                  <option key={key} value={warehouse2.id}>
                                                  {warehouse2.name}
                                                  </option>
                                              );
                                              })}
                                        </Input>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                  <Label
                                  className="form-control-label"
                                  for="exampleEmail" sm={4}>
                                      Keterangan
                                  </Label>
                                  <Col sm={7}>
                                      <Input
                                      disabled
                                          autoComplete="off"
                                          className="form-control-alternative"
                                          type="textarea"
                                          rows ="5"
                                          placeholder="Masukan Keterangan"
                                          value={keterangan}
                                          onChange={(e) => {
                                              setKeterangan(e.target.value);
                                          }}
                                      />
                                  </Col>
                              </FormGroup>
                          </CardBody>
                      </Col>
                      <Col md="6">
                          <CardBody>
                                <FormGroup row>
                                    <Label
                                    className="form-control-label"
                                    for="exampleEmail" sm={4}>
                                        Budget
                                    </Label>
                                    <Col sm={7}>
                                        {/* <Input
                                        disabled
                                          autoComplete="off"
                                            className="form-control-alternative"
                                            type="number"
                                            placeholder="Rp.0"
                                            value={budgetawal}
                                            onChange={(e) => {
                                                setBudgetAwal(e.target.value);
                                            }}
                                        /> */}
                                        <CurrencyInput
                                        disabled
                                            id="validation-example-2-field"
                                            placeholder="Rp.0"
                                            allowDecimals={false}
                                            value={budgetawal}
                                            onValueChange={badgetAwal}
                                            className={`form-control`}
                                            prefix={'Rp'}
                                            step={10}
                                            />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label
                                    className="form-control-label"
                                    for="exampleEmail" sm={4}>
                                        Potongan
                                    </Label>
                                    <Col sm={3}>
                                        {/* <Input
                                            disabled
                                            autoComplete="off"
                                            className="form-control-alternative"
                                            type="number"
                                            placeholder="Rp.0"
                                            value={potonganpertransaksi}
                                            onChange={(e) => {
                                                setPotonganPerTransaksi(e.target.value);
                                            }}
                                        /> */}
                                         <CurrencyInput
                                        disabled
                                            id="validation-example-2-field"
                                            placeholder="Rp.0"
                                            allowDecimals={false}
                                            value={potonganpertransaksi}
                                            onValueChange={PromoPerTransaksi}
                                            className={`form-control`}
                                            prefix={'Rp'}
                                            step={10}
                                            />
                                    </Col>
                                    <Col sm={5}>
                                        <Label
                                        className="form-control-label"
                                        for="exampleEmail" sm={7}>
                                            / Transaksi
                                        </Label>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label
                                    className="form-control-label"
                                    for="exampleEmail" sm={4}>
                                        Tipe Promo
                                    </Label>
                                    <Col sm={7}>
                                        <Input
                                        disabled
                                          autoComplete="off"
                                            className="form-control-alternative"
                                            name="Supplier"
                                            type="text"
                                            placeholder="Masukan Type"
                                            value={uniontype}
                                            onChange={(e) => {
                                                setUnionType(e.target.value);
                                            }} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                  <Label
                                  className="form-control-label"
                                  for="exampleEmail" sm={4}>
                                  Mulai
                                  </Label>
                                  <Col sm={7}>
                                      <Input
                                      disabled
                                          autoComplete="off"
                                          className="form-control-alternative"
                                          type="date"
                                          
                                          placeholder="Masukan Tanggal"
                                          value={start}
                                          onChange={(e) => {
                                              setStart(e.target.value);
                                          }}
                                      />
                                  </Col>
                              </FormGroup>
                              <FormGroup row>
                                  <Label
                                  className="form-control-label"
                                  for="exampleEmail" sm={4}>
                                  Selesai
                                  </Label>
                                  <Col sm={7}>
                                      <Input
                                      disabled
                                          autoComplete="off"
                                          className="form-control-alternative"
                                          type="date"
                                          placeholder="Masukan Tanggal"
                                          value={end}
                                          onChange={(e) => {
                                              setEnd(e.target.value);
                                          }}
                                      />
                                  </Col>
                              </FormGroup>
                              <FormGroup row>
                                  <Col
                                    for="exampleEmail"
                                    sm={4}
                                  >
                                    <Label
                                  className="form-control-label"
                                    >
                                        Status
                                    </Label>
                                  </Col>
                                    <Col sm={7}>
                                            {status === 1 ?  
                                            <h2>
                                            <Badge color="success">
                                                AKTIF
                                            </Badge>
                                            </h2> :
                                            <h2>  
                                            <Badge color="danger">
                                                TIDAK AKTIF
                                            </Badge>
                                            </h2>}
                                    </Col>
                              </FormGroup>
                          </CardBody>
                      </Col>
                  </Row>
              </Card>
              {/* Hari */}
              <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <h3>Promo Hari</h3>
                      </div>
                  </CardHeader>
                  <CardBody>
                  <Table size="sm" responsive>
                          <thead>
                            <tr>
                              <th><center>Senin</center></th>
                              <th><center>Selasa</center></th>
                              <th><center>Rabu</center></th>
                              <th><center>Kamis</center></th>
                              <th><center>Jumat</center></th>
                              <th><center>Sabtu</center></th>
                              <th><center>Minggu</center></th>
                            </tr>
                          </thead>
                          <tbody>
                                <tr>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked={
                                                  senin == 1 ? true : false
                                              }
                                              value={senin == 1 ? "2" : "1"}
                                              onChange={(e) => {
                                                      setSenin(e.target.value);
                                                  }}
                                          />
                                          <Label
                                          className="form-control-label"
                                          check>
                                          </Label>
                                      </FormGroup>
                                  </td>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked = {selasa == 1 ? true : false}
                                              value={selasa == 1 ? "2" : "1"}
                                              onChange={(e) => setSelasa(e.target.value)}
                                          />
                                              <Label
                                              className="form-control-label"
                                              check>
                                              </Label>
                                      </FormGroup>
                                  </td>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked = {rabu == 1 ? true : false}
                                              value={rabu == 1 ? "2" : "1"}
                                              onChange={(e) => setRabu(e.target.value)}
                                          />
                                              <Label
                                              className="form-control-label"
                                              check>
                                              </Label>
                                      </FormGroup>
                                  </td>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked = {kamis == 1 ? true : false}
                                              value={kamis == 1 ? "2" : "1"}
                                              onChange={(e) => setKamis(e.target.value)}
                                          />
                                              <Label
                                              className="form-control-label"
                                              check>
                                              </Label>
                                      </FormGroup>
                                  </td>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked = {jumat == 1 ? true : false}
                                              value={jumat == 1 ? "2" : "1"}
                                              onChange={(e) => setJumat(e.target.value)}
                                          />
                                              <Label
                                              className="form-control-label"
                                              check>
                                              </Label>
                                      </FormGroup>
                                  </td>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked = {sabtu == 1 ? true : false}
                                              value={sabtu == 1 ? "2" : "1"}
                                              onChange={(e) => setSabtu(e.target.value)}
                                          />
                                              <Label
                                              className="form-control-label"
                                              check>
                                              </Label>
                                      </FormGroup>
                                  </td>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked = {minggu == 1 ? true : false}
                                              value={minggu == 1 ? "2" : "1"}
                                              onChange={(e) => setMinggu(e.target.value)}
                                          />
                                              <Label
                                              className="form-control-label"
                                              check>
                                              </Label>
                                      </FormGroup>
                                  </td>
                                </tr>
                          </tbody>
                        </Table>
                  </CardBody>
              </Card>
              {/* Bulan */}
              <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <h3>Promo Bulan</h3>
                      </div>
                  </CardHeader>
                  <CardBody>
                  <Table size="sm" responsive>
                          <thead>
                            <tr>
                              <th><center>Januari</center></th>
                              <th><center>Februari</center></th>
                              <th><center>Maret</center></th>
                              <th><center>April</center></th>
                              <th><center>Mei</center></th>
                              <th><center>Juni</center></th>
                              <th><center>Juli</center></th>
                              <th><center>Agustus</center></th>
                              <th><center>September</center></th>
                              <th><center>Oktober</center></th>
                              <th><center>November</center></th>
                              <th><center>Desember</center></th>
                            </tr>
                          </thead>
                          <tbody>
                                <tr>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked={
                                                  januari == 1 ? true : false
                                              }
                                              value={januari == 1 ? "2" : "1"}
                                              onChange={(e) => {
                                                      setJanuari(e.target.value);
                                                  }}
                                          />
                                          <Label
                                          className="form-control-label"
                                          check>
                                          </Label>
                                      </FormGroup>
                                  </td>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked = {februari == 1 ? true : false}
                                              value={februari == 1 ? "2" : "1"}
                                              onChange={(e) => setFebruari(e.target.value)}
                                          />
                                              <Label
                                              className="form-control-label"
                                              check>
                                              </Label>
                                      </FormGroup>
                                  </td>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked = {maret == 1 ? true : false}
                                              value={maret == 1 ? "2" : "1"}
                                              onChange={(e) => setMaret(e.target.value)}
                                          />
                                              <Label
                                              className="form-control-label"
                                              check>
                                              </Label>
                                      </FormGroup>
                                  </td>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked = {april == 1 ? true : false}
                                              value={april == 1 ? "2" : "1"}
                                              onChange={(e) => setApril(e.target.value)}
                                          />
                                              <Label
                                              className="form-control-label"
                                              check>
                                              </Label>
                                      </FormGroup>
                                  </td>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked = {mei == 1 ? true : false}
                                              value={mei == 1 ? "2" : "1"}
                                              onChange={(e) => setMei(e.target.value)}
                                          />
                                              <Label
                                              className="form-control-label"
                                              check>
                                              </Label>
                                      </FormGroup>
                                  </td>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked = {juni == 1 ? true : false}
                                              value={juni == 1 ? "2" : "1"}
                                              onChange={(e) => setJuni(e.target.value)}
                                          />
                                              <Label
                                              className="form-control-label"
                                              check>
                                              </Label>
                                      </FormGroup>
                                  </td>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked = {juli == 1 ? true : false}
                                              value={juli == 1 ? "2" : "1"}
                                              onChange={(e) => setJuli(e.target.value)}
                                          />
                                              <Label
                                              className="form-control-label"
                                              check>
                                              </Label>
                                      </FormGroup>
                                  </td>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked = {agustus == 1 ? true : false}
                                              value={agustus == 1 ? "2" : "1"}
                                              onChange={(e) => setAgustus(e.target.value)}
                                          />
                                              <Label
                                              className="form-control-label"
                                              check>
                                              </Label>
                                      </FormGroup>
                                  </td>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked = {september == 1 ? true : false}
                                              value={september == 1 ? "2" : "1"}
                                              onChange={(e) => setSeptember(e.target.value)}
                                          />
                                              <Label
                                              className="form-control-label"
                                              check>
                                              </Label>
                                      </FormGroup>
                                  </td>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked = {oktober == 1 ? true : false}
                                              value={oktober == 1 ? "2" : "1"}
                                              onChange={(e) => setOktober(e.target.value)}
                                          />
                                              <Label
                                              className="form-control-label"
                                              check>
                                              </Label>
                                      </FormGroup>
                                  </td>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked = {november == 1 ? true : false}
                                              value={november == 1 ? "2" : "1"}
                                              onChange={(e) => setNovember(e.target.value)}
                                          />
                                              <Label
                                              className="form-control-label"
                                              check>
                                              </Label>
                                      </FormGroup>
                                  </td>
                                  <td align="center">
                                      <FormGroup check>
                                          <Input
                                          disabled
                                              id="checkbox2"
                                              type="checkbox"
                                              checked = {desember == 1 ? true : false}
                                              value={desember == 1 ? "2" : "1"}
                                              onChange={(e) => setDesember(e.target.value)}
                                          />
                                              <Label
                                              className="form-control-label"
                                              check>
                                              </Label>
                                      </FormGroup>
                                  </td>
                                </tr>
                          </tbody>
                        </Table>
                  </CardBody>
              </Card>
              <Card>
										<CardHeader>
											<div style={{ display: "flex", justifyContent: "space-between" }}>
												<h3>Cabang</h3>
											</div>
										</CardHeader>
										<CardBody>
											<ToolkitProvider
												rowNumber={rowIndex}
												data={warehouse}
												keyField="id"
												columns={[
													{
														dataField: "no",
														text: "#",
														sort: true,
														page: 1,
														formatter: (cell, row, index) => {
															let currentRow = ++index;
															return currentRow + rowIndex;
														},  
													},
													{
														dataField: "warehouse_code",
														text: "Kode Cabang",
														sort: true,
													},
                          {
														dataField: "warehouse_name",
														text: "Cabang",
														sort: true,
													},
												]}>
												{(props) => (
													<div className="py-4 table-responsive">
														<BootstrapTable
															remote
															{...props.baseProps}
															bootstrap4={true}
															bordered={false}
															hover={true}
															pagination={paginationFactory({ ...paginationOption })}
															onTableChange={handleTableChange}
														/>
													</div>
												)}
											</ToolkitProvider>
										</CardBody>
									</Card>
								</CardBody>
						</div>
					</Row>
				</Container>
                <Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>
            Kembali
          </Button>
        </ModalBody>
      </Modal> 
			</div>
            ):(
                <Halaman404 />
            )}
		</>
	);
}