/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Label,
    FormGroup,
    Row,
    Input,
    CardHeader,
    CardFooter,
    Col,
    Button,
    Form ,
    FormFeedback,
    Container,
    InputGroup,
    InputGroupText
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import CurrencyInput from 'react-currency-input-field';
import Halaman404 from "views/404";

export default function EditSupplier(props) {
  const token = localStorage.token;
  let history = useHistory();
  const [namePerson, setNamePerson] = useState("");
  const [limitPiutang, setLimitPiutang] = useState("");
  const [limitHutang, setLimitHutang] = useState("");
  const [npwp, setNpwp] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [province, setProvince] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [citys, setCitys] = useState([]);
  const [city, setCity] = useState("");
  const [bankname1,setBankName1] = useState("");
  const [bankname2,setBankName2] = useState("");
  const [bankaccount1,setBankAccount1] = useState("");
  const [bankaccount2,setBankAccount2] = useState("");
  const [banknumber1,setBankNumber1] = useState("");
  const [banknumber2,setBankNumber2] = useState("");
  const [banks1,setBanks1] = useState([])
  const [banks2,setBanks2] = useState([])
  const [bankname1Error,setBankName1Error] = useState(null);
  const [bankname2Error,setBankName2Error] = useState(null);
  const [bankaccount1Error,setBankAccount1Error] = useState(null);
  const [bankaccount2Error,setBankAccount2Error] = useState(null);
  const [banknumber1Error,setBankNumber1Error] = useState(null);
  const [banknumber2Error,setBankNumber2Error] = useState(null);
  const [namePersonError, setNamePersonError] = useState(null);
  const [addressError, setAddressError] = useState(null);
  const [limitPiutangError, setLimitPiutangError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [provinceError, setProvinceError] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [limitHutangError, setLimitHutangError] = useState(null);
  const [npwpError, setNpwpError] = useState(null);
  const [alert, setAlert] = React.useState(null);
  const allInfo = JSON.parse(localStorage.allinfo);
  const supplier = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Supplier").map((p) => p.read_access));
    

  const validateForm = () => {
    let error = false;
    if (namePerson === "") {
      setNamePersonError("invalid");
      error = true;
    }
    if (address === "") {
        setAddressError("invalid");
      error = true;
    }
    if (limitHutang === "") {
      setLimitHutangError("invalid");
      error = true;
    }
    if (province === "") {
        setProvinceError("invalid");
        error = true;
      }
    if (city === "") {
        setCityError("invalid");
        error = true;
      }
    if (limitPiutang === "") {
      setLimitPiutangError("invalid");
      error = true;
    }
    if (phone === "") {
        setPhoneError("invalid");
      error = true;
    }
    if (npwp === "") {
      setNpwpError("invalid");
      error = true;
    }
    if (bankname1 === "") {
        setBankName1Error("invalid");
        error = true;
      }
    if (bankaccount1 === "") {
        setBankAccount1Error("invalid");
        error = true;
      }
      if (banknumber1 === "") {
        setBankNumber1Error("invalid");
        error = true;
      }
    return error;
  };

  const pricelimithutang = (e) => {
    const value = e === undefined ? 'undefined' : e;
    setLimitHutang(value || ' ');
    if (value !== "") {
        setLimitHutangError("");
      }
                                                            
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
        setQuestionAlert();
    }
  }
  
  useEffect(() => {
    getById();
    getBank();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/person/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setNamePerson(data.data.response.person_name);
        setAddress(data.data.response.address);
        setPhone(data.data.response.phone);
        setNpwp(data.data.response.npwp);
        getProvinsi(data.data.response.province);
        setCity(data.data.response.city);
        setLimitHutang(data.data.response.limit_hutang);
        setLimitPiutang(data.data.response.limit_piutang);
        setBankName1(data.data.response.bank_name1);
        setBankName2(data.data.response.bank_name2);
        setBankAccount1(data.data.response.bank_account1);
        setBankAccount2(data.data.response.bank_account2);
        setBankNumber1(data.data.response.bank_number1);
        setBankNumber2(data.data.response.bank_number2);
        
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getBank = () => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/daftar-bank/list`, { headers
    })
    .then(data => {
        setBanks1(data.data.response);
        setBanks2(data.data.response);
        
    })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getProvinsi = (id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/province/list`, { headers
    })
    .then(data => {
      setProvinces(data.data.response_data);
      getKota(id)
      setProvince(id)
    //   setCity(id)
    })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getKota = (id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/city?province_id=${id}`, { headers
    })
    .then(data => {
      setCitys(data.data.response_data);
    })
      .catch(function (error) {
        console.log(error)
      })
  }


  function EditData() {
    let data = {
        person_name: namePerson,
      phone : phone,
      address: address,
      province : province,
      city : city,
      bank_name1: bankname1 ,
      bank_name2: bankname2,
      bank_account1: bankaccount1,
      bank_account2: bankaccount2,
      bank_number1 : banknumber1,
      bank_number2 : banknumber2,
      limit_piutang: parseInt(limitPiutang),
      limit_hutang: parseInt(limitHutang),
      active_flag: 1,
      npwp: npwp,
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/person/update/${props.match.params.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then(function (response) {
        setSuccessAlert(response.data.message);
        setTimeout(() => (history.push("/admin/person")), 2000);
        })
        .catch(function (error) {
          setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

    const setGagalAlert = () => {
    setAlert(
        <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
        />
    );
    };

    const setQuestionAlert = () => {
    setAlert(
        <SweetAlert
        warning
        showCancel
        confirmBtnText="Lanjutkan"
        confirmBtnBsStyle="danger"
        title="Apakah Kamu Yakin?"
        onConfirm={EditData}
        onCancel={hideAlert}
        focusCancelBtn
        />
    );
    };

  const hideAlert = () => {
    setAlert(null);
  }

  return (
    <>
    {alert}
    {supplier && supplier === "1" ? (
      <div>
      <SimpleHeader name="Edit Supplier" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
            <div className="col">
            <Form onSubmit={handleSubmit}>
                                <Card className="bg-secondary shadow">
                                    <CardHeader className="bg-white border-0">
                                        <h3>Supplier</h3>
                                    </CardHeader>
                                    <Row md="12">
                                        <Col md="6">
                                            <CardBody>
                                                <FormGroup row>
                                                    <Label
                                                    className="form-control-label"
                                                     for="exampleEmail" sm={4}>
                                                        Nama
                                                        <span className="text-danger">*</span>
                                                    </Label>
                                                    <Col sm={7}>
                                                        <Input
                                                            autoComplete="off"
                                                            className="form-control-alternative"
                                                            type="text"
                                                            placeholder="Masukan Nama Supplier"
                                                            invalid={namePersonError === "invalid"}
                                                            value={namePerson}
                                                            onChange={(e) => {
                                                                setNamePerson(e.target.value);
                                                                if (e.target.value !== "") {
                                                                    setNamePersonError("");
                                                                  }
                                                            }}
                                                        />
                                                        <FormFeedback>
                                                            Nama tidak boleh kosong
                                                        </FormFeedback>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label
                                                    className="form-control-label"
                                                     for="exampleEmail" sm={4}>
                                                        Alamat
                                                        <span className="text-danger">*</span>
                                                    </Label>
                                                    <Col sm={7}>
                                                        <Input
                                                        autoComplete="off"
                                                            className="form-control-alternative"
                                                            type="textarea"
                                                            rows = "4"
                                                            placeholder="Masukan Alamat"
                                                            invalid={addressError === "invalid"}
                                                            value={address}
                                                            onChange={(e) => {
                                                                setAddress(e.target.value);
                                                                if (e.target.value !== "") {
                                                                    setAddressError("");
                                                                  }
                                                            }}
                                                        />
                                                        <FormFeedback>
                                                            Alamat tidak boleh kosong
                                                        </FormFeedback>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label
                                                    className="form-control-label"

                                                        for="exampleEmail"
                                                        sm={4}
                                                    >
                                                        Provinsi
                                                        <span className="text-danger">*</span>
                                                    </Label>
                                                    <Col sm={7}>
                                                        <Input
                                                        autoComplete="off"
                                                            className="form-control-alternative"
                                                            type="select"
                                                            value={province}
                                                            invalid={provinceError === "invalid"}
                                                            onChange={(e) => {
                                                            setProvince(e.target.value);
                                                            if (e.target.value !== "") {
                                                                setProvinceError("");
                                                              }
                                                            getKota(e.target.value);
                                                            }}
                                                        >
                                                            <option>Pilih Provinsi</option>
                                                            {
                                                            provinces.map((a, key) => {
                                                                return <option key={key} value={a.id}>{a.name}</option>
                                                            })
                                                            }
                                                        </Input>
                                                        <FormFeedback>
                                                            Province tidak boleh kosong
                                                        </FormFeedback>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label
                                                    className="form-control-label"

                                                        for="exampleEmail"
                                                        sm={4}
                                                    >
                                                        Kota
                                                        <span className="text-danger">*</span>
                                                    </Label>
                                                    <Col sm={7}>
                                                        <Input
                                                        autoComplete="off"
                                                            className="form-control-alternative"
                                                            name="function"
                                                            type="select"
                                                            value={city}
                                                            invalid={cityError === "invalid"}
                                                            onChange={(e) => {
                                                            setCity(e.target.value);
                                                            if (e.target.value !== "") {
                                                                setCityError("");
                                                              }
                                                            }}
                                                        >
                                                            <option>Pilih Kota</option>
                                                            {
                                                            citys.map((b, key) => {
                                                                return <option key={key} value={b.id}>{b.name}</option>
                                                            })
                                                            }
                                                        </Input>
                                                        <FormFeedback>
                                                            Kota tidak boleh kosong
                                                        </FormFeedback>
                                                    </Col>
                                                </FormGroup>
                                            </CardBody>
                                        </Col>
                                        <Col md="6">
                                            <CardBody>
                                                <FormGroup row>
                                                    <Label
                                                    className="form-control-label"
                                                     for="exampleEmail" sm={4}>
                                                        Kontak
                                                        <span className="text-danger">*</span>
                                                    </Label>
                                                    <Col sm={7}>
                                                        <Input
                                                        autoComplete="off"
                                                            className="form-control-alternative"
                                                            type="number"
                                                            pattern='[0-9]{0,5}'
                                                            maxLength={12}
                                                            placeholder="Masukan No Phone"
                                                            value={phone}
                                                            invalid={phoneError === "invalid"}
                                                            onChange={(e) => {
                                                                setPhone(e.target.value);
                                                                if (e.target.value !== "") {
                                                                    setPhoneError("");
                                                                  }
                                                            }}
                                                        />
                                                        <FormFeedback>
                                                            Phone tidak boleh kosong
                                                        </FormFeedback>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label
                                                    className="form-control-label"
                                                     for="exampleEmail" sm={4}>
                                                        Npwp
                                                        <span className="text-danger">*</span>
                                                    </Label>
                                                    <Col sm={7}>
                                                        <Input
                                                        autoComplete="off"
                                                            className="form-control-alternative"
                                                            type="number"
                                                            // pattern='[0-9]{0,5}'
                                                            placeholder="Masukan No NPWP"
                                                            value={npwp}
                                                            invalid={npwpError === "invalid"}
                                                            onChange={(e) => {
                                                                setNpwp(e.target.value);
                                                                if (e.target.value !== "") {
                                                                    setNpwpError("");
                                                                  }
                                                            }}
                                                        />
                                                        <FormFeedback>
                                                            Npwp tidak boleh kosong
                                                        </FormFeedback>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label
                                                    className="form-control-label"
                                                      htmlFor="validation-example-2-field" sm={4}>
                                                        Limit Hutang
                                                        <span className="text-danger">*</span>
                                                    </Label>
                                                    <Col sm={7}>
                                                    <InputGroup>
															<InputGroupText>
															Rp
															</InputGroupText>
                                                        <Input
                                                            autoComplete="off"
                                                            className="form-control-alternative"
                                                            type="number"
                                                            pattern='[0-9]{0,5}'
                                                            placeholder="Rp.0"
                                                            value={limitHutang}
                                                            invalid={limitHutangError === "invalid"}
                                                            onChange={(e) => {
                                                                setLimitHutang(e.target.value);
                                                                if (e.target.value !== "") {
                                                                    setLimitHutangError("");
                                                                  }
                                                            }}
                                                        />
                                                        </InputGroup>
                                                        {/* <CurrencyInput
																id="validation-example-2-field"
																// placeholder="Rp0"
																allowDecimals={false}
																value={limitHutang}
																onValueChange={pricelimithutang}
																className={`form-control`}
																prefix={'Rp'}
																step={10}
															/> */}
                                                        <FormFeedback>
                                                            Limit Hutang tidak boleh kosong
                                                        </FormFeedback>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label
                                                    className="form-control-label"
                                                     for="exampleEmail" sm={4}>
                                                        Limit Piutang
                                                        <span className="text-danger">*</span>
                                                    </Label>
                                                    <Col sm={7}>
                                                    <InputGroup>
															<InputGroupText>
															Rp
															</InputGroupText>
                                                        <Input
                                                        autoComplete="off"
                                                            className="form-control-alternative"
                                                            type="number"
                                                            pattern='[0-9]{0,5}'
                                                            placeholder="Rp.0"
                                                            value={limitPiutang}
                                                            invalid={limitPiutangError === "invalid"}
                                                            onChange={(e) => {
                                                                setLimitPiutang(e.target.value);
                                                                if (e.target.value !== "") {
                                                                    setLimitPiutangError("");
                                                                  }
                                                            }}
                                                        />
                                                        </InputGroup>
                                                        <FormFeedback>
                                                            Limit Piutang tidak boleh kosong
                                                        </FormFeedback>
                                                    </Col>
                                                </FormGroup>
                                            </CardBody>
                                        </Col>
                                    </Row>
                                </Card>
                                <Card className="bg-secondary shadow">
                                                <CardHeader className="bg-white border-0">
                                                    <h3>Bank</h3>
                                                </CardHeader>
                                                <Row md="12">
                                                    <Col md="6">
                                                        <CardBody>
                                                            <FormGroup row>
                                                                <Label
                                                                className="form-control-label"
                                                                 for="exampleEmail" sm={4}>
                                                                    Nama Bank
                                                                    <span className="text-danger">*</span>
                                                                </Label>
                                                                <Col sm={7}>
                                                                <Input
                                                                    autoComplete="off"
                                                                    name="Kota"
                                                                    type="select"
                                                                    invalid={bankname1Error === "invalid"}
                                                                    value={bankname1}
                                                                    onChange={(e) => {
                                                                        setBankName1(e.target.value);
                                                                        if (e.target.value !== "") {
                                                                            setBankName1Error("");
                                                                          }
                                                                    }}
                                                                    >
                                                                    <option value="">Pilih Bank</option>
                                                                    {
                                                                        banks1.map((city, key) => {
                                                                        return <option key={key} value={city.bank_name}>{city.bank_name}</option>
                                                                        })
                                                                    }
                                                                </Input>
                                                                <FormFeedback>
                                                                    Nama Bank tidak boleh kosong
                                                                </FormFeedback>
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label
                                                                className="form-control-label"
                                                                 for="exampleEmail" sm={4}>
                                                                    Nama Rekening
                                                                    <span className="text-danger">*</span>
                                                                </Label>
                                                                <Col sm={7}>
                                                                    <Input
                                                                    
                                                                        autoComplete="off"
                                                                        className="form-control-alternative"
                                                                        type="text"
                                                                        placeholder="Masukan Atas Nama Rekening"
                                                                        value={bankaccount1}
                                                                        invalid={bankaccount1Error === "invalid"}
                                                                        onChange={(e) => {
                                                                            setBankAccount1(e.target.value);
                                                                            if (e.target.value !== "") {
                                                                                setBankAccount1Error("");
                                                                              }
                                                                        }}
                                                                    />
                                                                     <FormFeedback>
                                                                        Nama Rekening tidak boleh kosong
                                                                    </FormFeedback>
                                                                </Col>
                                                            </FormGroup>
                                                        </CardBody>
                                                    </Col>
                                                    <Col md="6">
                                                        <CardBody>
                                                            <FormGroup row>
                                                                <Label
                                                                className="form-control-label"
                                                                 for="exampleEmail" sm={4}>
                                                                    Nomor Rekening
                                                                    <span className="text-danger">*</span>
                                                                </Label>
                                                                <Col sm={7}>
                                                                    <Input
                                                                    
                                                                    autoComplete="off"
                                                                        className="form-control-alternative"
                                                                        type="number"
                                                                        pattern='[0-9]{0,5}'
                                                                        placeholder="Masukan Nomor Rekening"
                                                                        value={banknumber1}
                                                                        invalid={banknumber1Error === "invalid"}
                                                                        onChange={(e) => {
                                                                            setBankNumber1(e.target.value);
                                                                            if (e.target.value !== "") {
                                                                                setBankNumber1Error("");
                                                                              }
                                                                        }}
                                                                    />
                                                                    <FormFeedback>
                                                                        Nomor Rekening tidak boleh kosong
                                                                    </FormFeedback>
                                                                </Col>
                                                            </FormGroup>
                                                        </CardBody>
                                                    </Col>
                                                </Row>
                                                <Row md="12">
                                                    <Col md="6">
                                                        <CardBody>
                                                            <FormGroup row>
                                                                <Label
                                                                className="form-control-label"
                                                                 for="exampleEmail" sm={4}>
                                                                    Nama Bank
                                                                </Label>
                                                                <Col sm={7}>
                                                                    <Input
                                                                        autoComplete="off"
                                                                        name="Kota"
                                                                        type="select"
                                                                        value={bankname2}
                                                                        onChange={(e) => {
                                                                            setBankName2(e.target.value);
                                                                        }}
                                                                        >
                                                                        <option value="">Pilih Bank</option>
                                                                        {
                                                                            banks2.map((city, key) => {
                                                                            return <option key={key} value={city.bank_name}>{city.bank_name}</option>
                                                                            })
                                                                        }
                                                                    </Input>
                                                                    <FormFeedback>
                                                                        Nama Bank tidak boleh kosong
                                                                    </FormFeedback>
                                                                </Col>
                                                            </FormGroup>
                                                            <FormGroup row>
                                                                <Label
                                                                className="form-control-label"
                                                                 for="exampleEmail" sm={4}>
                                                                    Nama Rekening
                                                                </Label>
                                                                <Col sm={7}>
                                                                    <Input
                                                                    
                                                                        autoComplete="off"
                                                                        className="form-control-alternative"
                                                                        type="text"
                                                                        placeholder="Masukan Akun Bank"
                                                                        value={bankaccount2}
                                                                        onChange={(e) => {
                                                                            setBankAccount2(e.target.value);
                                                                        }}
                                                                    />
                                                                    <FormFeedback>
                                                                        Nama Rekening tidak boleh kosong
                                                                    </FormFeedback>
                                                                </Col>
                                                            </FormGroup>
                                                        </CardBody>
                                                    </Col>
                                                    <Col md="6">
                                                        <CardBody>
                                                            <FormGroup row>
                                                                <Label
                                                                className="form-control-label"
                                                                 for="exampleEmail" sm={4}>
                                                                    Nomor Rekening
                                                                </Label>
                                                                <Col sm={7}>
                                                                    <Input
                                                                    
                                                                    autoComplete="off"
                                                                        className="form-control-alternative"
                                                                        type="number"
                                                                        pattern='[0-9]{0,5}'
                                                                        placeholder="Masukan Nomor Rekening"
                                                                        value={banknumber2}
                                                                        onChange={(e) => {
                                                                            setBankNumber2(e.target.value);
                                                                        }}
                                                                    />
                                                                    <FormFeedback>
                                                                        Nomor Rekening tidak boleh kosong
                                                                    </FormFeedback>
                                                                </Col>
                                                            </FormGroup>
                                                        </CardBody>
                                                    </Col>
                                                </Row>
                                    <CardFooter>
                                        <Link className="btn btn-info" to="/admin/person">
                                            Kembali
                                        </Link>
                                        <Button color="danger" type="submit">
                                            Simpan
                                        </Button>
                                    </CardFooter>
                                </Card>
                            </Form> 
            </div>
        </Row>
      </Container>
      </div>
    ):(
        <Halaman404 />
    )}
    </>
  );
}
