import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";
// import styles from '../../../../assets/scss/Print.scss'
import moment from "moment";

const ModalCetakInvoiceSo = ({ open, toggle, data }) => {

    const token = localStorage.token;
    // const username = localStorage.username;
    const [usernamekasir, setUsernameKasir] = useState("");
    const [warehouserfq, setWarehouseRfq] = useState("");
    const [listItem,setListItem] = useState([]);
    const [listBank,setListBank] = useState([]);
    const [waktu,setWaktu] = useState("");
    const [modal, setModal] = useState(0);
    const [total, setTotal] = useState(0);
    const [shift, setShift] = useState("")
  // const dataModalCetak1 = ({ data: id,});
    const [item, setItem] = useState("")

  const getById = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/chasier-settlement/cetak/by-shift/${data.id}`, {
        headers,
      })
      .then((data) => {
        setWaktu(data.data.response.updated_at)
        setItem(data.data.response.list_nota.length);
        setListItem(data.data.response.list_nota);
        setTotal(data.data.response.total_kasir)
        setModal(data.data.response.modal_kasir)
        setListBank(data.data.response.list_bank);
        setUsernameKasir(data.data.response.username);
        setShift(data.data.response.shift);
        setWarehouseRfq(data.data.response.warehouse);
      })
      .catch(function (error) {
        console.log(error);
      });
  },[token, data.id]);

  useEffect(() => {
    getById();
  }, [getById]);

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }


  function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    window.close();
    document.body.innerHTML = originalContents;
  }
  
  return (
    <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top:"-15%" }}>
      <ModalHeader toggle={toggle} align="center">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <Button color="info" onClick={() => {print();setTimeout(() => (window.location.reload("/admin/closing-kasir")), 1000)}}>Print</Button>
                        {/* <Button color="success" onClick={()=> (downloadExcel(),HitCounter())}>Excel</Button> */}
                    </div>
                </div>
      </ModalHeader>
      <ModalBody>
      <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          {renderPDF()}
        </CardBody>
        {/* <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          <div className="col">
            <KopSurat 
            warehouseName={warehouserfq}
            // user={username}
             />
          </div>
          <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                        <Row md="12">
                            <Col md="12">
                                <h3><b><center>Closing Kasir</center></b></h3>
                            </Col>
                        </Row>
                        <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                        <br></br>
                        <Row md="12">
                            <Col md="5">
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Tanggal</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-4 ">
                                    <b>{waktu}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Shift</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{shift}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Keterangan</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{keterangan}</b>
                                </div>
                            </div>
                            </Col>
                            <Col md="1">
                            </Col>
                            <Col md="6">
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Kasir</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{usernamekasir}</b>
                                </div>
                            </div>
                            </Col>
                        </Row>
                    <CardBody>
                      <br></br>
                      <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th>
                            <b>Tgl</b>
                            </th>
                            <th>
                            <b>Nota</b>
                            </th>
                            <th>
                            <b>Pembayaran</b>
                            </th>
                            <th>
                            <b>Bank</b>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            listItem.map((list, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{list.transaction_date}</td>
                                        <td>{list.nota_code}</td>
                                        <td>{formatRupiah(list.payment_total)}</td>
                                        <td>{list.list_pm}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                      </Table>
                      <br></br>
                      <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                      <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th>
                            <b>Bank</b>
                            </th>
                            <th>
                            <b>Saldo</b>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            listBank.map((list, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{list.bank_name} {list.account_number} a/n {list.account_name}</td>
                                        <td>{formatRupiah(list.saldo)}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                      </Table>
                    </CardBody>
                    <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                      {download === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Download</i> {download} Kali Oleh {username}</div>
                    </div>
                      )}
        </CardBody> */}
      </ModalBody>
    </Modal>
  );

  function renderPDF(){
    // let sheet = 1;
    // let length = listItem.length
    // if (item != length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []  
    // for (let index = 0; index < sheet; index++) {
    //   let start = 0
    //   let end = 0
    //   if(sheet == 1){
    //     end = length
    //   }else{
    //     if(sheet > 1 && index < (sheet - 1)){
    //       end = item
    //     }else{
    //       end = length % item
    //     }
    //   }

    let sheet = 1;
    let length = listItem.length
    if (item !== length) {
      sheet = Math.floor(length / item);
      if((length % item) > 0){
        sheet++;
      }
    }
    const sheets = []
    for (let index = 0; index < sheet; index++) {
      let start = (item * index)
      let end = length
      let sisa = (length % item)
      if(sheet === 1){
        end = length
      }else{
        if(index < (sheet - 1) && sheet === 2){
          end = item
        }else{
          if (index === (sheet - 1)) {
            end = length - sisa + (index * item)
          }else{
            if (index === 0) {
              end = item
            }else{
              end = (index+1) * item
            }
          }
        }
      } 
      let data = listItem.slice(start,end)
      // console.log(data.length)
      let usernamecok = moment().format("YYYY-MM-DD hh:mm:ss") + " " + localStorage.username;
      sheets.push(
        <div className='pagebreak'>
          <div className="col">
            <KopSurat warehouseName={warehouserfq} />
          </div>
          <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
          <Row md="12">
              <Col md="12">
                  <h3 className=""><b><center>CLOSING KASIR</center></b></h3>
              </Col>
          </Row>
          <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
          
          <Row md="12">
            <Col style={{marginLeft:"2%",width:"70%",flexBasis: "unset"}}>
             
              <div className="row align-items-center ">
                  <div style={{width:"8%"}}  className="text-table">Tanggal</div>
                  <div style={{width:"2%"}}  className="text-center text-table">:</div>
                  <div style={{width:"85%"}}  className="text-table">
                      <b>{waktu}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"8%"}} className="text-table">Shift</div>
                  <div style={{width:"2%"}} className="text-center text-table">:</div>
                  <div style={{width:"85%"}} className="text-table">
                      <b>{shift}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"8%"}}  className="text-table">keterangan </div>
                  <div style={{width:"2%"}}  className="text-center text-table">:</div>
                  <div style={{width:"85%"}}  className="text-table">
                      <b></b>
                  </div>
              </div>
            </Col>
            <Col style={{width:"25%",flexBasis: "unset"}}>
              <div className="row align-items-center ">
                  <div style={{width:"30%"}} className="text-table">Kasir</div>
                  <div style={{width:"5%"}} className="text-table">:</div>
                  <div style={{width:"65%"}} className="text-table">
                      <b>{usernamekasir}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"30%"}} className="text-table">Modal</div>
                  <div style={{width:"5%"}} className="text-table">:</div>
                  <div style={{width:"65%"}} className="text-table">
                      <b>{formatRupiah(modal)}</b>
                  </div>
              </div>
            </Col>
          </Row>
            
              <Table style={{width:"100%"}} responsive>
                <thead>
                <tr>
                    <th className="text-table">
                    <b>No</b>
                    </th>
                    <th className="text-table">
                    <b>Tanggal</b>
                    </th>
                    <th className="text-table">
                    <b>Nota</b>
                    </th>
                    <th className="text-table">
                    <b>Bank</b>
                    </th>
                    <th className="text-table">
                    <b>Bayar</b>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    data.map((temp,index, key) => {
                        return (
                            <tr>
                                <td className="text-table">{index+1}</td>
                                <td className="text-table">{temp.transaction_date}</td>
                                <td className="text-table">{temp.nota_code}</td>
                                <td className="text-table">{temp.list_pm}</td>
                                <td className="text-table">{formatRupiah(temp.payment_total)}</td>
                               
                            </tr>
                        );
                    })
                }
                <tr>
                                <td className="text-table text-right" colSpan={4}>Total</td>
                                <td className="text-table">{formatRupiah(total)}</td>
                </tr>
                </tbody>
              </Table>
              <div ></div>
              <Table style={{width:"100%"}} responsive>
                <thead>
                <tr>
                    <th colSpan={3} className="text-table">
                    <b>Bank</b>
                    </th>
                    <th colSpan={2} className="text-table">
                    <b>Saldo</b>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                            listBank.map((list, key) => {
                                return (
                                    <tr key={key}>
                                        <td  colSpan={3} className="text-table">{list.bank_name} {list.account_number} a/n {list.account_name}</td>
                                        
                                        <td  colSpan={2} className="text-table">{formatRupiah(list.saldo)}</td>
                                        
                                    </tr>
                                )
                            })
                        }
                </tbody>
              </Table>
              <Row md="12">
              </Row>
          <div className="w-100 text-table" style={{ border: "0.5px solid #b3b3b3" }}></div>
          <center className="text-table">Terms of Price, delivery & shipping required</center>
          <Row md="20">
            <Col style={{ marginLeft:"2%",width:"33%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Di Buat</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>{usernamekasir}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col>
            <Col style={{ width:"33%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Kepala Finance</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b></b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col>
          </Row>
          <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
          <div className="divFooter">{usernamecok}</div>
        </div>
      )
    }
    return (
      <div>
        {sheets}
      </div>
    );
  }

};

export default ModalCetakInvoiceSo;
