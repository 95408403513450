/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";

export default function CreateItemRakCok(props) {
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [status, setStatus] = useState("");
  const [coderak, setCodeRak] = useState("")
  const [coderaks, setCodeRaks] = useState([]);
  const [allUom, setAllUom] = useState([])
  const [total, setTotal] = useState(0)
  const [savedItems, setSavedItems] = useState([]);
  const [isSearchShoww, setIsSearchShoww] = useState(false);
  const [allItemm, setAllItemm] = useState([]);
  const [queryy, setQueryy] = useState("");
  const headers = { Authorization: `Bearer ${token}` };
  const [alert, setAlert] = React.useState(null);
	const [qtyTotal, setTotalQty] = useState(0);
  const [partisis, setPartisis] = useState([])

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/rak/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setCodeRak(data.data.response.rak_code)
        getPartisi(data.data.response.rak_code);
        getRakListItem(data.data.response.rak_code)
        setTotal(data.data.response.total_real_location)
        // getItemGrup(data.data.response.rak_code)s
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getRakListItem = async (id) => {
    let filter = { rak_code : id, warehouse_id : warehouse }
    const data = filter;
    const headers = {
      'Authorization': `Bearer ${token}`,
    };
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/rak/list-item`, data, { headers })
    .then((data) => {
      setAllUom(data.data.response);
    })
    .catch(function (error) {
      setAllUom(error.response.data.response);
    });
     
    }
 
  const getPartisi = async (id) => {
    let filter = { rak_code : id, warehouse_id : warehouse }
    const data = filter;
    const headers = {
      'Authorization': `Bearer ${token}`,
    };
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/rak/list-partition`, data, { headers })
    .then((data) => {
      setPartisis(data.data.response);
    })
    .catch(function (error) {
      // setAllUom(error.response.data.response);
      console.log(error);
    });
     
    }

  useEffect(() => {
    const getData = setTimeout(async() => {
        if(queryy != ''){
            axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/items-po`, { item_info: queryy , per_page: 10, warehouse_id: parseInt(warehouse)},{ headers })
            .then((res) => {
                setAllItemm(res.data);
                setIsSearchShoww(true);
            })
            .catch(function (error) {
                setAllItemm(null);
            });
        }else{
            setAllItemm(null);
        }
      }, 500)
      return () => clearTimeout(getData)
  }, [queryy]);
  
  // save
  const saveItemOrUpdate = (item) => {
    let oldobj = savedItems.find(o => o.item_id === item.id);
    if(oldobj===undefined){
      setSavedItems([...savedItems,{
        item_id: item.id,
        item_name: item.item_name,
        item_code: item.item_code,
        barcode : item.barcode,
        satuan : item.satuan,
        partisi : 0,
        minimum_stok : 1,
        maximum_stok : 0,
      }]);
      
    }else{
      let index = savedItems.findIndex(e=>e.item_id===item.id);
      let updateList = savedItems;
      let qtyupdate = parseInt(updateList[index].qty)+parseInt(1);
      updateList[index] = {...updateList[index], qty: qtyupdate};
      setSavedItems(updateList);
    };
  }

  const handlePartisi = (index, value) => {
    let updateList = savedItems;
    let aqtyTotal = parseInt(updateList[index].partisi) + value;
    if(!isNaN(value) && value.length > -1){
      updateList[index] = {...updateList[index], partisi: value};
      setSavedItems(updateList);
      setTotalQty(qtyTotal + aqtyTotal);
    }else{return false}
  }

  const handleMinimum = (index, value) => {
    let updateList = savedItems;
    let aqtyTotal = parseInt(updateList[index].minimum_stok) + value;
    if(!isNaN(value) && value.length > -1){
      updateList[index] = {...updateList[index], minimum_stok: value};
      setSavedItems(updateList);
      setTotalQty(qtyTotal + aqtyTotal);
    }else{return false}
  }

  const handleMaximum = (index, value) => {
    let updateList = savedItems;
    let aqtyTotal = parseInt(updateList[index].harga) + value;
    if(!isNaN(value) && value.length > -1){
      updateList[index] = {...updateList[index], maximum_stok: value};
      setSavedItems(updateList);
      setTotalQty(qtyTotal + aqtyTotal);
    }else{return false}
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    {
      setQueryy("");
      setIsSearchShoww("");
      return true
    }
  };


  function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id,
                maximum_stok : dataItem.maximum_stok,
                minimum_stok: dataItem.minimum_stok,
                real_location : dataItem.partisi
            }]);
    let data = {
        warehouse_id: parseInt(warehouse),
        code_rak : coderak,
        active_flag: 1,
        items : dataItems,
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/item-stok/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/rak")), 1000);
          })
          .catch(function (error) {
            setGagalAlert(error.response.data.message);
          });
    };
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }
  
  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };
  
  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
      warning
      showCancel
      confirmBtnText="Lanjutkan"
      confirmBtnBsStyle="danger"
      title="Apakah Kamu Yakin?"
      onConfirm={CreateData}
      onCancel={hideAlert}
      focusCancelBtn
      />
    );
  };
  
    const hideAlert = () => {
      setAlert(null);
    }

  const deleteItem = (id) => {
    let array = [...savedItems];

    let index = array.findIndex(object => {
        return object.item_id === id;
    });

    if (index !== -1) {
        array.splice(index, 1);
        setSavedItems(array);
    }
}
  return (
    <>
    {alert}
    <SimpleHeader name="Tambah Item Rak" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <h3>Tambah Item Rak</h3>
                </CardHeader>
                <CardBody>
                  <Row md="12">
                      <Col md="6">
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Kode Rak
                          </Label> 
                          <Col sm={7}>
                            <Input
                            disabled
                            autoComplete="off"
                              className="form-control-alternative"
                              name="kategory"
                              type="text"
                              value={coderak}
                              onChange={(e) => {
                                setCodeRak(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                        <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Barcode</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Partisi</b></th>
                                <th><b>Minimal Stok</b></th>
                                <th><b>Maksimal Stok</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td>{savedItem.barcode}</td>
                                      <td>{savedItem.satuan}</td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="qty"
                                          type="select"
                                          value={savedItem.partisi}
                                          onChange={(e) => {
                                            handlePartisi(key, e.target.value);
                                          }}
                                          >
                                             <option value="">Pilih Tipe Rak</option>
                                            {partisis.map((Tipe, key) => {
                                                return (
                                                <option key={key} value={Tipe.partisi}>
                                                    {Tipe.partisi}
                                                </option>
                                                );
                                            })}
                                          </Input>
                                      </td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="Min"
                                          type="number"
                                          value={savedItem.minimum_stok}
                                          onChange={(e) => {
                                              handleMinimum(key, e.target.value);
                                          }}/>
                                      </td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="Max"
                                          type="number"
                                          value={savedItem.maximum_stok}
                                          onChange={(e) => {
                                              handleMaximum(key, e.target.value);
                                          }}/>
                                      </td>
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              <tr>
                                <td>
                                  <><Form onSubmit={handleSubmit}>
                                    {/* {savedItems.length === allUom.length ?  */}
                                      {/* ""
                                      : */}
                                       <Input
                                          autoComplete="off"
                                          placeholder="Masukan Item Manual"
                                          type="search"
                                          value={queryy}
                                          onChange={(e) => setQueryy(e.target.value)}
                                        />
                                      {/* } */}

                                        {isSearchShoww && queryy && (
                                        <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                          <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                            <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
                                          </div>
                                          {allItemm?.response ? (
                                            allItemm.response.map((item) => (
                                                <CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" style={{minHeight: 'auto'}} onClick={() => {saveItemOrUpdate(item);setQueryy('');setIsSearchShoww(false);}}>
                                                <div>
                                                  <b>Nama item:</b> {item.item_name}
                                                </div>
                                              </CardBody>
                                            ))
                                          ) : (
                                            <div className="text-center mb-3 text-danger">Item "{queryy}" tidak ada bosku!</div>
                                          )}
                                        </Card>
                                      )}
                                  </Form></>
                                  </td>
                                 </tr>
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter>
                              <Link className="btn btn-info" to="/admin/rak">
                                  Kembali
                              </Link>
                              <Button color="danger" onClick={setQuestionAlert}>
                                  Simpan
                              </Button>
                        </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row> 
    </Container>  
    </>
  );
}
