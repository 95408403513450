/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Button ,
    Container,
    Badge,
    Form, 
    FormGroup,
    Label, 
    Input,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
    Modal, ModalHeader, ModalBody

} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";

// import * as FileSaver from "file-saver";
// import * as XLSX from "xlsx";

// import {PDFDownloadLink} from '@react-pdf/renderer';
import ModalCetakReturSo from './ModalCetakReturSo';
import Halaman404 from 'views/404';
// import PdfReportSo from './Pdf';
import Select2 from "react-select2-wrapper";

const HistoriSoRetur = () => {
  const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle1 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
  const token = localStorage.token;
  const [tanggal,setTanggal] = useState("")
  const [persons, setPersons] = useState([]);
  const [description, setDescription] = useState("");
  const [uomCode, setUomCode] = useState("")
  const warehouse = parseInt(localStorage.warehouse);
  const redirectPrefix = `/admin/so-retur/histori/detail/`;
  // const redirectPrefix1 = `/admin/so-retur/payment/`;
  const [allPenawaranSo, setAllPenawaranSo] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(1000000);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [status,setStatus] = useState("");
  const [status1,setStatus1] = useState("");
  const [openModalCetak, setOpenModalCetak] = useState(false);
  const [dataModalCetak, setDataModalCetak] = useState({ id: 0,});
  const toggle = () => setOpenModalCetak(!openModalCetak);
  const allInfo = JSON.parse(localStorage.allinfo);
  const ReturSoProyeks = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Retur SO").map((p) => p.read_access));
  const cetak = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Cetak Retur SO").map((p) => p.create_access));
  
  useEffect(() => {
    getPerson();
  }, []);

  const getPerson = () => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/customer/list`, { headers
      })
      .then(data => {
        setPersons(data.data.response);
      })
        .catch(function (error) {
          console.log(error)
        })
    }

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort,tanggal, uomCode,description, status, status1);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort,tanggal, uomCode,description, status, status1)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort,tanggal, uomCode,description, status, status1) => {
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setTanggal(tanggal);
    setUomCode(uomCode);
    setDescription(description);
    setStatus(status)
    setStatus1(status1);
    getPenawaranSo(page, perPage, sort,tanggal, uomCode,description, status, status1);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort,tanggal, uomCode,description, status, status1)
    }
  }

  
  useEffect(() => {
    getPenawaranSo(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getPenawaranSo = async (page, perPage, currentSort,transaction_date=null, retur_code=null, customer_name=null,status_ar2=null,status_d=null) => {
    
    let filter = { 
      page: page, 
      per_page: perPage,
      // status_ar2:5,
      // status:2,
      // status_d:5,
      warehouse_id : parseInt(warehouse)
    };
    if (transaction_date !== '') {
      filter = Object.assign(filter, { transaction_date: transaction_date })
    }if (retur_code !== '') {
      filter = Object.assign(filter, { retur_code: retur_code })
    }if (customer_name !== '') {
      filter = Object.assign(filter, { customer_name: customer_name })
    }if (status_ar2 !== '') {
      filter = Object.assign(filter, { status_ar2: status_ar2 })
    }if (status_d !== '') {
      filter = Object.assign(filter, { status_d: status_d })
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/so-retur/page`, data, {
        headers,
      })
      .then((data) => {
        setAllPenawaranSo(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        setAllPenawaranSo(error.response.data.response);
      });
  }

const displayModalCetak = (id) => {
  setDataModalCetak({
    id: id,
  });
  setOpenModalCetak(true);
};

const getDataLog = (id) => {
  let filter = { 
    transaction : id,
    features : "JUAL10", 
    warehouse_id : warehouse
  }
  const data = filter;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
    }
  axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
  })
  .then(data => {
    setDataLog(data.data.response);
          setModal1(!modal1);

  })
    .catch(function (error) {
    setGagalAlert(error.response.data.message);
    })
  }

  const setGagalAlert = (id) => {
  setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
  );
  };
  
  const hideAlert = () => {
    setAlert(null);
  };

  const reset = () => {
    setTanggal("")
    setUomCode("")
    setDescription("");
    setStatus("")
    setStatus1("")
    updateDataTable(1, perPage, currentSort, "", "");
  }

  return (
    <>
    {alert}
    {ReturSoProyeks && ReturSoProyeks === "1" ? (
      <div>
       <SimpleHeader name="Histori" parentName="PO" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
            <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                        <Link className="btn btn-link" to="/admin/so-retur">
                          <i className="fa fa-arrow-circle-left fa-2x" /> 
                        </Link>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                <Form>
                        <Row md="12">
                        <Col md="3">
                            <FormGroup>
                              <Label
                                      className="form-control-label"
                                      htmlFor="exampleFormControlSelect3"
                                  >
                                Tanggal 
                              </Label>
                              <Input
                              className="form-control-alternative"
                                type="date"
                                placeholder="Masukan Tanggal"
                                value={tanggal}
                                onChange={(e) =>
                                  updateDataTable(1,perPage,currentSort, e.target.value, uomCode , description,status,status1 )
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect3"
                            >Kode Retur</Label>
                              <Input
                              className="form-control-alternative"
                                type="text"
                                placeholder="Masukan Retur"
                                value={uomCode}
                                onChange={e => updateDataTable(1, perPage, currentSort,tanggal, e.target.value, description,status,status1)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                             <FormGroup>
                              <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect3"
                            >Customer</Label>
                              <Select2
                                        className="form-control-alternative"
                                        defaultValue="0"
                                        value={description}
                                        onChange={(e) =>
                                          updateDataTable(1, perPage, currentSort, tanggal, uomCode, e.target.value,status,status1)
                                          }
                                        options={{
                                          placeholder: "Pilih Customer"
                                        }}
                                        data={persons.map((person, key) => {
                                          return (
                                          { id: person.name, text: person.name, key:key}
                                          );
                                        })}
                                      />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                                <FormGroup> 
                              <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >
                                  Status Manager Office
                              </Label>
                              <Input
                                  name="account"
                                  type="select"
                                  value={status}
                                  onChange={(e) =>
                                  updateDataTable(1, perPage, currentSort, tanggal, uomCode, description,e.target.value,status1)
                                  }
                              >
                                  <option value={""}>Pilih Status</option>
                                  <option value={3}>Proses</option>
                                  <option value={4}>Tolak</option>
                                  <option value={5}>Setuju</option>
                              </Input>
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup> 
                              <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >
                                  Status Direktur
                              </Label>
                              <Input
                                  name="account"
                                  type="select"
                                  value={status1}
                                  onChange={(e) =>
                                  updateDataTable(1, perPage, currentSort, tanggal, uomCode, description,status,e.target.value)
                                  }
                              >
                                  <option value={""}>Pilih Status</option>
                                  <option value={3}>Proses</option>
                                  <option value={4}>Tolak</option>
                                  <option value={5}>Setuju</option>
                              </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                          <Col md="3">
                            <Button type="button" onClick={reset} color="secondary">Reset</Button>
                          </Col>
                        </Row>
                      </Form>
                        {allPenawaranSo === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                  dataField: "",
                                  text: "Tanggal",
                                  sort: true,
                                  },
                                  {
                                    dataField: "",
                                    text: "Kode Retur",
                                    sort: true,
                                },
                                {
                                  dataField: "",
                                  text: "Customer",
                                  sort: true,
                              },
                                  {
                                    dataField: "",
                                    text: "Status",
                                    sort: true,
                                },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                        </div>
                        ):(
                        <ToolkitProvider
                              rowNumber={rowIndex}
                              data={allPenawaranSo}
                              keyField="id"
                              columns={[
                              {
                                  dataField: "no",
                                  text: "#",
                                  sort: true,
                                  page: 1,
                                  formatter: (cell, row, index) => {
                                  let currentRow = ++index;
                                  return currentRow + rowIndex;
                                  },
                              },
                              {
                                  dataField: "transaction_date",
                                  text: "Tanggal",
                                  sort: true,
                                },
                                {
                                    dataField: "retur_code",
                                    text: "Kode Retur",
                                    sort: true,
                                    formatter: (cell, row) => {
                                      return row.retur_code === ""
                                        ? <h2>
                                          <Badge color="warning" >
                                            {row.retur_code}
                                          </Badge>
                                          </h2>
                                        : 
                                        <h2>
                                        <Badge color="warning" >
                                            {row.retur_code}
                                          </Badge>
                                          </h2>
                                    },
                                },
                                {
                                  dataField: "customer_name",
                                  text: "Customer",
                                  sort: true,
                              },
                                {
                                  dataField: "status_d",
                                  text: "Status",
                                  sort: true,
                                  formatter: (cell, row) => {
                                    return row.status_d === 3
                                      ? 
                                        <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-danger" />
                                          Proses
                                        </Badge>
                                      : row.status_d === 4
                                      ? <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-warning" />
                                          Tolak
                                        </Badge>
                                      : <Badge color="" className="badge-dot mr-4">
                                          <i className="bg-success" />
                                          Setuju
                                        </Badge>;
                                  },
                              },
                              {
                                dataField: "",
                                text: "",
                                formatter: (cell, row, index) => {
                                  return row.status_d === 3
                                    ? <UncontrolledDropdown nav>
                                        <DropdownToggle color="danger">
                                            Tindakan
                                          </DropdownToggle>
                                          <DropdownMenu center>
                                                <Link  to={redirectPrefix + row.id}
                                                  id={"tooltip_" + row.id}>
                                                <DropdownItem>
                                                  <i className="fas fa-user-edit" /><span>Detail</span>
                                                </DropdownItem>
                                                </Link>
                                          </DropdownMenu>
                                      </UncontrolledDropdown>
                                    : row.status_d === 4
                                    ?  <UncontrolledDropdown nav>
                                    <DropdownToggle color="danger">
                                        Tindakan
                                      </DropdownToggle>
                                      <DropdownMenu center>
                                            <Link  to={redirectPrefix + row.id}
                                              id={"tooltip_" + row.id}>
                                            <DropdownItem>
                                              <i className="fas fa-user-edit" /><span>Detail</span>
                                            </DropdownItem>
                                            </Link>
                                      </DropdownMenu>
                                  </UncontrolledDropdown>
                                    : <UncontrolledDropdown nav>
                                    <DropdownToggle color="danger">
                                        Tindakan
                                      </DropdownToggle>
                                      <DropdownMenu center>
                                      <Link  onClick={() => getDataLog(row.id)}>
                                                <DropdownItem>
                                                <i className="fa fa-info-circle" aria-hidden="true"></i> <span>Info</span>
                                                </DropdownItem>
                                            </Link>
                                            <Link  to={redirectPrefix + row.id}
                                                  id={"tooltip_" + row.id}>
                                                <DropdownItem>
                                                  <i className="fas fa-user-edit" /><span>Detail</span>
                                                </DropdownItem>
                                                </Link>
                                            {/* <Link  to={redirectPrefix1 + row.id}
                                                id={"tooltip_" + row.id}>
                                              <DropdownItem>
                                                <i className="fas fa-book" /><span>Payment</span>
                                              </DropdownItem>
                                            </Link> */}
                                            {cetak && cetak === "1" && (
                                            <DropdownItem
                                              onClick={() => displayModalCetak(row.id)}
                                            >
                                              <i className="fas fa-print" />
                                              <span>Cetak</span>
                                            </DropdownItem>
                                            )}
                                      </DropdownMenu>
                                  </UncontrolledDropdown>
                                },
                              },
                              ]}
                          >
                              {(props) => (
                              <div className="py-4 table-responsive">
                                  <BootstrapTable
                                  remote
                                  {...props.baseProps}
                                  bootstrap4={true}
                                  bordered={false}
                                  hover={true}
                                  pagination={paginationFactory({ ...paginationOption })}
                                  onTableChange={handleTableChange}
                                  />
                              </div>
                            )}
                      </ToolkitProvider>
                        )} 
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>
        </div>
        ):(
          <Halaman404 />
        )}
        {openModalCetak && (
  <ModalCetakReturSo
    open={openModalCetak}
    data={dataModalCetak}
    toggle={toggle}
  />
)}
    </>
  );
}

export default HistoriSoRetur;