/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Card,
  CardBody,
  Row,
  Container,
  CardImg,
} from "reactstrap";
import { Link  } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";
import HeaderTotal from "components/Headers/HeaderTotal";

export default function PermintaanBarangPoCabang() {
    const warehouse = localStorage.warehouse;
    const token = localStorage.token;
    const [totalItem, setTotalItem] = useState(0);
    const [proses, setProses] = useState(0);
    const [tolak, setTolak] = useState(0);
    const [setuju, setSetuju] = useState(0);
    const page = 1;
    const perPage = 10;
    const allInfo = JSON.parse(localStorage.allinfo);
    const penawaranPOs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub PO Pusat").map((p) => p.read_access));
    const penawaranPO = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab PO Pusat").map((p) => p.read_access));
	const validasiPenawaranPO = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi PO Pusat").map((p) => p.read_access));
	const validasiKepalaPurchasingPo = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi PO Admin Pusat").map((p) => p.read_access));
	const validasiDirektur = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi PO Direktur Pusat").map((p) => p.read_access));
	const cetakPO = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Cetak PO Pusat").map((p) => p.read_access));
  
    useEffect(() => {
        gettotal();
        getProses();
        getTolak();
        getSetuju()
      }, []);
    
      // fungsi dari ambil data
      const gettotal = () => {
        let filter = { 
          page: page, 
          per_page: perPage,
        };
        
        const data = filter;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/page`, data, {
            headers,
          })
          .then((data) => {
            setTotalItem(data.data.total_item);
          })
          .catch(function (error) {
          });
    }

    const getProses = () => {
        let filter = { 
          page: page, 
          per_page: perPage,
          approve:3, 
          status_ap:3,
          status_d: 3,
        };
        
        const data = filter;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/page`, data, {
            headers,
          })
          .then((data) => {
            setProses(data.data.total_item);
          })
          .catch(function (error) {
          });
    }

    const getTolak = () => {
        let filter = { 
          page: page, 
          per_page: perPage,
          approve:3, 
          status_ap:3,
          status_d: 4,
        };
        
        const data = filter;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/page`, data, {
            headers,
          })
          .then((data) => {
            setTolak(data.data.total_item);
          })
          .catch(function (error) {
          });
    }

    const getSetuju = () => {
        let filter = { 
          page: page, 
          per_page: perPage,
          approve:5, 
          status_ap:5,
          status_d: 5,
        };
        
        const data = filter;
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/page`, data, {
            headers,
          })
          .then((data) => {
            setSetuju(data.data.total_item);
          })
          .catch(function (error) {
          });
    }
  
    return (
    <>
      {penawaranPOs && penawaranPOs === "1" ? (
			<div>
      <SimpleHeader name="Pembelian Barang Cabang" parentName="Report" />
      <HeaderTotal 
       totalItem = {totalItem}
       proses ={proses}
       tolak={tolak}
       setuju={setuju}

      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <CardBody>
                    <Row>
                        <div className="card-columns">
                        {penawaranPO && penawaranPO === "1" && (
                            <Card>
                                <Link to="/admin/pusat/purchase-order/page">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/pembelian-barang.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {validasiPenawaranPO && validasiPenawaranPO === "1" && (
                            <Card>
                                <Link to="/admin/pusat/purchase-order/validasi-admin-purchasing">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-admin-purchasing.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {validasiKepalaPurchasingPo && validasiKepalaPurchasingPo === "1" && (
                            <Card>
                                <Link to="/admin/pusat/purchase-order/validasi-kepala-purchasing">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-kepala-purchasing.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {validasiDirektur && validasiDirektur === "1" && (
                            <Card>
                                <Link to="/admin/pusat/purchase-order/validasi-direktur">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-direktur.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                            {cetakPO && cetakPO === "1" && (
                            <Card>
                                <Link to="/admin/pusat/purchase-order/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        </div>
					</Row>
                </CardBody>
          </div>
        </Row>
      </Container>
      </div>
      ):(
        <Halaman404 />
      )};
    </>
  );
}