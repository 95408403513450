/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
  Card, 
  Button, 
  Row, 
  Col, 
  CardBody, 
  Badge,
  CardHeader,
  Container,
  Form, 
  FormGroup, 
  Label, 
  Input ,
  DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle,
  Modal, ModalHeader, ModalBody

} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import paginationFactory from "react-bootstrap-table2-paginator";
import ModalCetakClosingKasir from './ModalCetakClosingKasir';
import SweetAlert from "react-bootstrap-sweetalert";


const ClosingKasir = () => {
  const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
  const [tanggal,setTanggal] = useState("")
	const toggle2 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
  const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = parseInt(localStorage.warehouse);
  const [allRak, setAllRak] = useState([]);
  const [rakCode, setRakCode] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const redirectPrefix = `/admin/closing-kasir/detail/`;
	const [openModalCetak, setOpenModalCetak] = useState(false);
  const [dataModalCetak, setDataModalCetak] = useState({ id: 0,});
  const toggle = () => setOpenModalCetak(!openModalCetak);
  
  const displayModalCetak = (id) => {
    setDataModalCetak({
      id: id,
    });
    setOpenModalCetak(true);
  };
  
  
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort,tanggal, rakCode);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort,tanggal, rakCode)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort,tanggal, rakCode) => {
    getRak(page, perPage, sort, tanggal, rakCode);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setTanggal(tanggal)
    setRakCode(rakCode);
    
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort,tanggal, rakCode)
    }
  }

  useEffect(() => {
    getRak(page, perPage, currentSort, "", "");
  }, []);

  const getRak = async (page, perPage, currentSort,transaction_date=null, batch_number = null) => {
    let filter = {
       page: page, 
       per_page: perPage,
       username : username,
       id_warehouse: parseInt(warehouse) 
      }
      if (transaction_date !== null) {
        filter = Object.assign(filter, { transaction_date: transaction_date })
      }
      if (batch_number !== null) {
        filter = Object.assign(filter, { batch_number: batch_number })
      }
    const data = filter;
    const headers = {
      'Authorization': `Bearer ${token}`,
    };
     axios.post(`${process.env.REACT_APP_API_BASE_URL}/chasier-settlement/page`, data, { headers })
    .then((data) => {
      setAllRak(data.data.response);
      setPage(data.data.current_page + 1);
      setPerpage(data.data.per_page);
      setTotalItem(data.data.total_item);
    })
    .catch(function (error) {
      setAllRak(error.response.data.response);
});
}

const getDataLog = (id) => {
  let filter = { 
    transaction : id,
    features : "JUAL4", 
    warehouse_id : warehouse
  }
  const data = filter;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
    }
  axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
  })
  .then(data => {
    setDataLog(data.data.response);
          setModal1(!modal1);

  })
    .catch(function (error) {
    setGagalAlert(error.response.data.message);
    })
  }

  const setGagalAlert = (id) => {
    setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
    );
    };
    
    const hideAlert = () => {
      setAlert(null);
    };

  const reset = () => {
    setTanggal("")
    setRakCode("");
    updateDataTable(1, perPage, currentSort, "", "");
  }

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }

  return (
    <div>
      {alert}
     <SimpleHeader name="Closing Kasir" parentName="Master" />
      <Container className="mt--6" fluid>
       <Row>
          <div className="col">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3>Closing Kasir</h3>
                </div>
              </CardHeader>
                <CardBody>
                      <Form>
                        <Row md="12">
                        <Col md="3">
                            <FormGroup>
                              <Label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect3">Tanggal</Label>
                              <Input
                              className="form-control-alternative"
                                type="date"
                                placeholder="Masukan Kode Closing"
                                value={tanggal}
                                onChange={e => updateDataTable(1, perPage, currentSort, e.target.value, rakCode)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect3">Kode Closing</Label>
                              <Input
                              className="form-control-alternative"
                                type="text"
                                placeholder="Masukan Kode Closing"
                                value={rakCode}
                                onChange={e => updateDataTable(1, perPage, currentSort,tanggal, e.target.value)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button type="button" onClick={reset} color="secondary">Reset</Button>
                          </Col>
                        </Row>
                      </Form>
                      <Row md="12">
                        <Col md="9">
                        {allRak === null ? (
                                <div>
                                  <ToolkitProvider
                                          rowNumber={rowIndex}
                                          data={""}
                                          keyField="id"
                                          columns={[
                                          {
                                              dataField: "no",
                                              text: "#",
                                              sort: true,
                                              page: 1,
                                              formatter: (cell, row, index) => {
                                              let currentRow = ++index;
                                              return currentRow + rowIndex;
                                              },
                                          },
                                          {
                                            dataField: "",
                                            text: "Tanggal",
                                            sort: true,
                                          },  
                                          {
                                            dataField: "",
                                            text: "Kode",
                                            sort: true,
                                          },
                                          {
                                              dataField: "",
                                              text: "Modal",
                                              sort: true,
                                          },
                                        
                                          {
                                              dataField: "",
                                              text: "Omset",
                                              sort: true,
                                          },
                                  
                                          ]}
                                      >
                                          {(props) => (
                                          <div className="py-4 table-responsive">
                                              <BootstrapTable
                                              remote
                                              {...props.baseProps}
                                              bootstrap4={true}
                                              bordered={false}
                                              hover={true}
                                              onTableChange={handleTableChange}
                                              />
                                          </div>
                                          )}
                                  </ToolkitProvider>
                                  <CardHeader>
                                      <center><h3>DATA NOT FOUND</h3></center>
                                  </CardHeader>
                                </div>
                                ):(
                        <ToolkitProvider
                          rowNumber={rowIndex}
                          data={allRak}
                          keyField="id"
                          search={ {
                            defaultSearch: 'search something here'
                          } }
                          columns={[
                            {
                              dataField: "no",
                              text: "#",
                              sort: true,
                              page: 1,
                              formatter: (cell, row, index) => {
                                let currentRow = ++index;
                                return currentRow + rowIndex;
                              }
                            },
                            {
                              dataField: "created_at",
                              text: "Tanggal",
                              sort: true,
                            },  
                            {
                              dataField: "batch_number",
                              text: "Kode",
                              sort: true,
                              formatter: (cell, row) => {
                                return row.batch_number === ""
                                  ? <h2>
                                    <Badge color="warning" >
                                      {row.batch_number}
                                    </Badge>
                                    </h2>
                                  : 
                                  <h2>
                                  <Badge color="warning" >
                                      {row.batch_number}
                                    </Badge>
                                    </h2>
                              },
                            },
                            {
                                dataField: "modal",
                                text: "Modal",
                                sort: true,
                                formatter: (value) => formatRupiah(value)
                            },
                          
                            {
                                dataField: "omzet",
                                text: "Omset",
                                sort: true,
                                formatter: (value) => formatRupiah(value)
                            },
                            {
                              dataField: "", text: "", formatter: (cell, row, index) => {
                              return (
                                  <UncontrolledDropdown nav>
                                    <DropdownToggle className="nav-link pr-0" color="" tag="a">
                                        <Link className="btn btn-danger" >
                                          Tindakan
                                        </Link>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                    <Link  onClick={() => getDataLog(row.id)}>
                                                        <DropdownItem>
                                                        <i className="fa fa-info-circle" aria-hidden="true"></i> <span>Info</span>
                                                        </DropdownItem>
                                                    </Link>
                                          <Link to={redirectPrefix + row.id}
                                            id={"tooltip_" + row.id}>
                                          <DropdownItem>
                                            <i className="fas fa-book" /><span>Detail</span>
                                          </DropdownItem>
                                          </Link>
                                          <DropdownItem
                                            onClick={() => displayModalCetak(row.id)}
                                          >
                                            <i className="fas fa-print" />
                                            <span>Cetak</span>
                                          </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                              )
                              }
                            },
                          ]}
                        >
                          {(props) => (
                            <div className="py-4 table-responsive">
                              <BootstrapTable
                                remote
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                                hover={true}
                                pagination={paginationFactory({ ...paginationOption })}
                                onTableChange={handleTableChange}
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                                )}
                        </Col>
                      </Row>
              </CardBody>
            </Card>
          </div>
       </Row>
       {openModalCetak && (
        <ModalCetakClosingKasir
          open={openModalCetak}
          data={dataModalCetak}
          toggle={toggle}
        />
      )}
     </Container>
     <Modal isOpen={modal1} toggle={toggle2} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle2} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle2}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ClosingKasir;