/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  CardHeader,
  CardFooter,
  Col,
  Button,
  Container,
  Form,
  InputGroup,
  InputGroupText,
  Modal, ModalHeader, ModalBody

} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";

export default function EditItem() {
    const token = localStorage.token;
    const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle1 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
    const [itemId, setItemId] = useState(1);
    const [query, setQuery] = useState(null);
    const [isSearchShow, setIsSearchShow] = useState(false); 
    const headers = { Authorization: `Bearer ${token}` };
    const redirectPrefix = `/admin/person/edit/`;
    const [allItem, setAllItem] = useState([]);
    const [namePerson, setNamePerson] = useState("");
    const [limitPiutang, setLimitPiutang] = useState("");
    const [limitHutang, setLimitHutang] = useState("");
    const [npwp, setNpwp] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [province, setProvince] = useState(0);
    const [provinces, setProvinces] = useState([]);
    const [citys, setCitys] = useState([]);
    const [city, setCity] = useState(0);
    const [bankname1,setBankName1] = useState("");
    const [bankname2,setBankName2] = useState("");
    const [bankaccount1,setBankAccount1] = useState("");
    const [bankaccount2,setBankAccount2] = useState("");
    const [banknumber1,setBankNumber1] = useState("");
    const [banknumber2,setBankNumber2] = useState("");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const allInfo = JSON.parse(localStorage.allinfo);
    const supplier = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Supplier").map((p) => p.read_access));
    const CreateButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Supplier").map((p) => p.create_access));
    const UpdateButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Supplier").map((p) => p.update_access));
  

	const getById = (id) => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/person/${id}`,
            { headers }
          )
          .then((data) => {
            setNamePerson(data.data.response.person_name);
            setAddress(data.data.response.address);
            setPhone(data.data.response.phone);
            setNpwp(data.data.response.npwp);
            getProvinsi(data.data.response.province);
            setCity(data.data.response.city);
            setLimitHutang(data.data.response.limit_hutang);
            setLimitPiutang(data.data.response.limit_piutang);
            setBankName1(data.data.response.bank_name1);
            setBankName2(data.data.response.bank_name2);
            setBankAccount1(data.data.response.bank_account1);
            setBankAccount2(data.data.response.bank_account2);
            setBankNumber1(data.data.response.bank_number1);
            setBankNumber2(data.data.response.bank_number2);
            
          })
          .catch(function (error) {
            console.log(error);
          });
      };
    
      const getProvinsi = (id) => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/province/list`, { headers
        })
        .then(data => {
          setProvinces(data.data.response_data);
          getKota(id)
          setProvince(id)
        })
          .catch(function (error) {
            console.log(error)
          })
      }
    
      const getKota = (id) => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/city?province_id=${id}`, { headers
        })
        .then(data => {
          setCitys(data.data.response_data);
        })
          .catch(function (error) {
            console.log(error)
          })
      }

useEffect(() => {
    getItems();
}, [page]);

//get list item berdasarkan warehouse
const getItems = async () => {
    let filter = {
        page: page,
        per_page: 1,
    };
    const data = filter;
    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/person`, data, { headers });
    if(res.data.response.id !== null){
        getById(res.data.response[0].id);
        setItemId(res.data.response[0].id);
        setTotalPages(res.data.total_page);
    }
};

useEffect(() => {
    const getData = setTimeout(async() => {
        if(query != ''){
            axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/person`, { person_name: query , per_page: 10},{ headers })
            .then((res) => {
                setAllItem(res.data);
                setIsSearchShow(true);
            })
            .catch(function (error) {
                setIsSearchShow(true);
                setAllItem(error.response.data.response);
                // setAlert("Supplier Kosong")
            });
        }
      }, 1000)
      return () => clearTimeout(getData)
}, [query]);

const searchShow = (item) => {
    setItemId(item.id);
    setPage(item.id);
    getById(item.id)
    setIsSearchShow(false);
};

const handleSubmit = async (e) => {
    e.preventDefault();
    {
      setQuery("");
      return true
    }
  };

  const getDataLog = (id) => {
    let filter = { 
        transaction : id,
        features : "MASTER7",
        warehouse_id : 0
    }
    const data = filter;
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
    })
    .then(data => {
        setDataLog(data.data.response);
        setModal1(!modal1);

    })
      .catch(function (error) {
        setGagalAlert(error.response.data.message);
      })
  }

  const setGagalAlert = (id) => {
    setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
    );
    };
    
    const hideAlert = () => {
      setAlert(null);
    };

  return (
    <>
    {alert}
     {supplier && supplier === "1" ? (
      <div>
      <SimpleHeader name="Supplier" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                    <Card className="position-sticky boxShadow" style={{ top: 0, zIndex: "5" }}>
                        <CardBody className="ml-3 pb-0">
                            <Row md="12">
                                <Col md="5" className="d-lg-block d-none">
                                    <Button onClick={() => setPage(1)} disabled={page === 1} color="danger" type="button">
                                        First
                                    </Button>
                                    <Button onClick={() => setPage((page) => page - 1)} disabled={page === 1} color="success" type="button">
                                        <i className="fa fa-angle-left" /> Prev
                                    </Button>
                                    <Button onClick={() => setPage((page) => page + 1)} disabled={page >= totalPages} color="success" type="button">
                                        Next <i className="fa fa-angle-right" />
                                    </Button>
                                    <Button onClick={() => setPage(totalPages)} disabled={page == totalPages} color="warning" type="button">
                                        End
                                    </Button>
                                </Col>
                                <Col md="4">
                                <Form onSubmit={handleSubmit} >
                                    <FormGroup row>
                                        <Col sm={7}>
                                            <Input
                                                placeholder="Search Supplier"
                                                value={query}
                                                type="search"
                                                onChange={(e) => setQuery(e.target.value)}
                                            />
                                        </Col>
                                    </FormGroup>
                                    </Form>
                                </Col>
                                <Col md="3" className="d-lg-block d-none">
                                    <div style={{ textAlign: "right" }}>
                                    {UpdateButton && UpdateButton === "1" && (
                                        <Link className="btn btn-info"
                                            to={redirectPrefix + itemId}
                                        >
                                            <i className="fas fa-user-edit" /> Edit
                                        </Link>
                                    )}
                                        {CreateButton && CreateButton === "1" && (
                                        <Link className="btn btn-danger" to="/admin/person/create">
                                            <i className="fas fa-plus" /> Tambah
                                        </Link>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    {isSearchShow && query && (
									<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
										<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
											<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
										</div>
										<span className="text-center mb-3">
											<b>Pencarian berdasarkan:</b> {query}
										</span>
										{allItem?.response ? (
											allItem.response.map((item) => (
												<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" style={{minHeight: 'auto'}} onClick={() => {searchShow(item); setQuery('');}}>
													<div>
														<b>Nama :</b> {item.person_name}
													</div>
													<div>
														<b>Kode Supplier:</b> {item.person_code ? item.person_code : "(Not available)"}
													</div>
												</CardBody>
											))
										) : (
											<div className="text-center mb-3 text-danger">Supplier "{query}" tidak ada bosku!</div>
										)}
									</Card>
								)}
                <CardBody>
                    <Row>
						<div className="col">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                <div
											style={{ display: "flex", justifyContent: "space-between" }}
										>
											<h3>Supplier</h3>
											<div style={{ textAlign: "rigth" }}>
												<Button color="warning" onClick={() => (getDataLog(itemId))}>
												<i className="fa fa-info-circle" aria-hidden="true"></i>
												</Button>
											</div>
										</div>
                                </CardHeader>
                                <Row md="12">
                                    <Col md="6">
                                        <CardBody>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                for="exampleEmail" sm={4}>
                                                    Nama
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    disabled
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="text"
                                                        placeholder="Masukan Nama Supplier"
                                                        value={namePerson}
                                                        onChange={(e) => {
                                                            setNamePerson(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                for="exampleEmail" sm={4}>
                                                    Alamat
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    disabled
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="textarea"
                                                        rows = "4"
                                                        placeholder="Masukan Alamat"
                                                        value={address}
                                                        onChange={(e) => {
                                                            setAddress(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                   
                                                   className="form-control-label"
                                                   for="exampleEmail"
                                                    sm={4}
                                                >
                                                    Provinsi
                                                    
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    disabled
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="select"
                                                        value={province}
                                                        onChange={(e) => {
                                                        setProvince(e.target.value);
                                                        getKota(e.target.value);
                                                        }}
                                                    >
                                                        <option>Pilih Provinsi</option>
                                                        {
                                                        provinces.map((a, key) => {
                                                            return <option key={key} value={a.id}>{a.name}</option>
                                                        })
                                                        }
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                   
                                                   className="form-control-label"
                                                   for="exampleEmail"
                                                    sm={4}
                                                >
                                                    Kota
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    disabled
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        name="function"
                                                        type="select"
                                                        value={city}
                                                        onChange={(e) => {
                                                        setCity(e.target.value);
                                                        }}
                                                    >
                                                        <option>Pilih Kota</option>
                                                        {
                                                        citys.map((b, key) => {
                                                            return <option key={key} value={b.id}>{b.name}</option>
                                                        })
                                                        }
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                    </Col>
                                    <Col md="6">
                                        <CardBody>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                for="exampleEmail" sm={4}>
                                                    Kontak
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    disabled
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="text"
                                                        
                                                        placeholder="Masukan No Phone"
                                                        value={phone}
                                                        onChange={(e) => {
                                                            setPhone(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                for="exampleEmail" sm={4}>
                                                    Npwp
                                                </Label>
                                                <Col sm={7}>
                                                    <Input
                                                    disabled
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="text"
                                                        
                                                        placeholder="Masukan No NPWP"
                                                        value={npwp}
                                                        onChange={(e) => {
                                                            setNpwp(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                for="exampleEmail" sm={4}>
                                                    Limit Hutang
                                                </Label>
                                                <Col sm={7}>
                                                <InputGroup>
															<InputGroupText>
															Rp
															</InputGroupText>
                                                    <Input
                                                    disabled
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="number"
                                                        placeholder="Rp.0"
                                                        value={limitHutang}
                                                        onChange={(e) => {
                                                            setLimitHutang(e.target.value);
                                                        }}
                                                    />
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup row>
                                                <Label
                                                className="form-control-label"
                                                for="exampleEmail" sm={4}>
                                                    Limit Piutang
                                                </Label>
                                                <Col sm={7}>
                                                <InputGroup>
															<InputGroupText>
															Rp
															</InputGroupText>
                                                    <Input
                                                    disabled
                                                    autoComplete="off"
                                                        className="form-control-alternative"
                                                        type="number"
                                                        placeholder="Rp.0"
                                                        value={limitPiutang}
                                                        onChange={(e) => {
                                                            setLimitPiutang(e.target.value);
                                                        }}
                                                    />
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                            <Card className="bg-secondary shadow">
                                            <CardHeader className="bg-white border-0">
                                                <h3>Bank</h3>
                                            </CardHeader>
                                            <Row md="12">
                                                <Col md="6">
                                                    <CardBody>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                Nama Bank
                                                            </Label>
                                                            <Col sm={7}>
                                                                <Input
                                                                disabled
                                                                autoComplete="off"
                                                                    className="form-control-alternative"
                                                                    type="text"
                                                                    placeholder="Masukan Nama Bank"
                                                                    value={bankname1}
                                                                    onChange={(e) => {
                                                                        setBankName1(e.target.value);
                                                                    }}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                Nama Rekening
                                                            </Label>
                                                            <Col sm={7}>
                                                                <Input
                                                                disabled
                                                                    autoComplete="off"
                                                                    className="form-control-alternative"
                                                                    type="text"
                                                                    placeholder="Masukan Atas Nama Rekening"
                                                                    value={bankaccount1}
                                                                    onChange={(e) => {
                                                                        setBankAccount1(e.target.value);
                                                                    }}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </CardBody>
                                                </Col>
                                                <Col md="6">
                                                    <CardBody>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                Nomor Rekening
                                                            </Label>
                                                            <Col sm={7}>
                                                                <Input
                                                                disabled
                                                                autoComplete="off"
                                                                    className="form-control-alternative"
                                                                    type="text"
                                                                
                                                                    placeholder="Masukan Nomor Rekening"
                                                                    value={banknumber1}
                                                                    onChange={(e) => {
                                                                        setBankNumber1(e.target.value);
                                                                    }}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </CardBody>
                                                </Col>
                                            </Row>
                                            <Row md="12">
                                                <Col md="6">
                                                    <CardBody>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                Nama Bank
                                                            </Label>
                                                            <Col sm={7}>
                                                                <Input
                                                                disabled
                                                                autoComplete="off"
                                                                    className="form-control-alternative"
                                                                    type="text"
                                                                    placeholder="Masukan Nama Bank"
                                                                    value={bankname2}
                                                                    onChange={(e) => {
                                                                        setBankName2(e.target.value);
                                                                    }}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                Nama Rekening
                                                            </Label>
                                                            <Col sm={7}>
                                                                <Input
                                                                disabled
                                                                    autoComplete="off"
                                                                    className="form-control-alternative"
                                                                    type="text"
                                                                    placeholder="Masukan Akun Bank"
                                                                    value={bankaccount2}
                                                                    onChange={(e) => {
                                                                        setBankAccount2(e.target.value);
                                                                    }}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </CardBody>
                                                </Col>
                                                <Col md="6">
                                                    <CardBody>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                Nomor Rekening
                                                            </Label>
                                                            <Col sm={7}>
                                                                <Input
                                                                disabled
                                                                autoComplete="off"
                                                                    className="form-control-alternative"
                                                                    type="text"
                                                                
                                                                    placeholder="Masukan Nomor Rekening"
                                                                    value={banknumber2}
                                                                    onChange={(e) => {
                                                                        setBankNumber2(e.target.value);
                                                                    }}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                    </CardBody>
                                                </Col>
                                            </Row>
                                        <CardFooter>
                                </CardFooter>
                            </Card>
						</div>
					</Row>
                </CardBody>
          </div>
        </Row>
      </Container>
      <Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>  
      </div>
     ):(
        <Halaman404 />
     )}
    </>
  );
}