/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input, 
  Modal, ModalHeader, ModalBody,
  FormFeedback
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
// import Select2 from "react-select2-wrapper";
// import moment from "moment";

const CreateItemProduksi = () => {
  const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [nameItem, setNameItem] = useState("");
  const [description, setDescription] = useState("");
  const [allSatuan, setAllSatuan] = useState([]);
  const [minimumstock, setMinimumStock] = useState("");
  const [satuan, setSatuan] = useState("");
  const [allGrade, setAllGrade] = useState([]);
  const [grade, setGrade] = useState(0);
  const [category, setCategory] = useState(0);
  const [categoryError, setCategoryError] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoryId,setCategoryId] = useState("");
  const [subCategoryId,setSubCategoryId] = useState("");
  const [subCategory, setSubCategory] = useState(0);
  const [subCategoryError, setSubCategoryError] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [nameFunction, setNameFunction] = useState(0);
  const [nameFunctions, setNameFunctions] = useState([]);
  const [nameSubFunction, setNameSubFunction] = useState(0);
  const [nameSubFunctions, setNameSubFunctions] = useState([]);
  const [merek, setMerek] = useState(0);
  const [allMerek, setAllMerek] = useState([]);
  const [submerek, setSubMerek] = useState(0);
  const [allSubMerek, setAllSubMerek] = useState([]);
  const [panjang, setPanjang] = useState("");
  const [lebar, setLebar] = useState("");
  const [tinggi, setTinggi] = useState("");
  const [nameType, setNameType] = useState(2);
  const [itemgrup, setItemGrup] = useState("");
  const [itemgrups, setItemGrups] = useState([]);
  const [berat, setBerat] = useState("");
  const [codecek, setCodeCek] = useState("");
  const [savedItems, setSavedItems] = useState([]);
  const [isSearchShoww, setIsSearchShoww] = useState(false);
  const [allItemm, setAllItemm] = useState([]);
  const [queryy, setQueryy] = useState("");
  const headers = { Authorization: `Bearer ${token}` };
  const [qtyTotal, setTotalQty] = useState(0);
  const [alert, setAlert] = React.useState(null);


  const validateForm = () => {
    let error = false;
    if (category === "") {
      setCategoryError("invalid");
      error = true;
    }
    if (subCategory === "") {
      setSubCategoryError("invalid");
      error = true;
    }
    return error;
  };


  useEffect(() => {
    getSatuan();
    getCategory();
    getSubCategory();
    getFunction();
    getSubFunction();
    getMerek();
    getSubMerek();
    getGrade();
    getItemGrup();
    GetCekCode();
  }, []);

  const handleSubmit2 = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      GetCekCode();
    }
  }

  const GetCekCode = async () => {
    let filter = {
        kategori_id: parseInt(category),
        subkategori_id: parseInt(subCategory) 
    };
    const data = filter;

    const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/items/check-code`, data, { headers });
    setCodeCek(res.data.response);
};

  const getItemGrup = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/item-group/list`,
        { headers }
      )
      .then((data) => {
        setItemGrups(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getGrade = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/item-grade/list`, { headers })
      .then((data) => {
        setAllGrade(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getSatuan = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/uom/list`, { headers })
      .then((data) => {
        setAllSatuan(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  const getCategory = () => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };
    axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/item-kategori/list`, {
            headers,
        })
        .then((data) => {
            setCategories(data.data.response);
            // getSubCategory(id);
        })
        .catch(function (error) {
            console.log(error);
        });
};

const getSubCategory = (id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/sub-kategori/list?kategori_id=${id}`, { headers
    })
    .then(data => {
        setSubCategories(data.data.response);
    })
      .catch(function (error) {
        console.log(error)
      })
  }

const getFunction = (id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/item-function/list?kategori_id=${categoryId}&sub_kategori_id=${id}`, { headers
    })
    .then(data => {
      setNameFunctions(data.data.response);
    })
      .catch(function (error) {
        console.log(error)
      })
  }

const getSubFunction = (id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/sub-function/list?kategori_id=${categoryId}&sub_kategori_id=${subCategoryId}&function_id=${id}`, { headers
    })
    .then(data => {
        setNameSubFunctions(data.data.response);
    })
      .catch(function (error) {
        console.log(error)
      })
  }

const getMerek = () => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };
    axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/merek/list`, { headers })
        .then((data) => {
            setAllMerek(data.data.response);
        })
        .catch(function (error) {
            console.log(error);
        });
};

const getSubMerek = (id) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };
    axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/sub-merek/list?merek_id=${id}`, { headers })
        .then((data) => {
            setAllSubMerek(data.data.response);
        })
        .catch(function (error) {
            console.log(error);
        });
};

  useEffect(() => {
    const getData = setTimeout(async() => {
        if(queryy != ''){
            axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/items-po`, { item_info: queryy , per_page: 10, warehouse_id: parseInt(warehouse)},{ headers })
            .then((res) => {
                setAllItemm(res.data);
                setIsSearchShoww(true);
            })
            .catch(function (error) {
                setAllItemm(null);
            });
        }else{
            setAllItemm(null);
        }
      }, 1000)
      return () => clearTimeout(getData)
  }, [queryy]);
  
  
  const handleEditQty = (index, value) => {
    let updateList = savedItems;
    let aqtyTotal = parseInt(updateList[index].harga) + value;
    
    if(!isNaN(value) && value.length > -1){
      updateList[index] = {...updateList[index], qty: value};
      setSavedItems(updateList);
      // setTotalPrice(totalPrice+plusTotal-minTotal);
      setTotalQty(qtyTotal + aqtyTotal);
      // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
    }else{return false}
  }
  
  
  // save
  const saveItemOrUpdate = (item) => {
    let oldobj = savedItems.find(o => o.item_id === item.id);
    if(oldobj===undefined){
      setSavedItems([...savedItems,{
        item_id: item.id,
        item_name: item.item_name,
        // item_code: item.item_code,
        qty: 1,
        // satuan : item.satuan,
      }]);
      
    }else{
      let index = savedItems.findIndex(e=>e.item_id===item.id);
      let updateList = savedItems;
      let qtyupdate = parseInt(updateList[index].qty)+parseInt(1);
      updateList[index] = {...updateList[index], qty: qtyupdate};
      setSavedItems(updateList);
    };
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    {
      setQueryy("");
      setIsSearchShoww("");
      return true
    }
  };

  const searchShow = (item) => {
		getById(item.id);
		setIsSearchShoww(false);
	};

  function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty,
            }]);
    let data = {
        warehouse_id: parseInt(warehouse),
        username: username,
        item_description: description,
        item_code : codecek,
        uom_id: parseInt(satuan),
        kategori_id: parseInt(category),
        subkategori_id: parseInt(subCategory),
        grade_id: parseInt(grade),
        function_id: parseInt(nameFunction),
        subfunction_id: parseInt(nameSubFunction),
        merek_id: parseInt(merek),
        submerek_id : parseInt(submerek),
        ig_id: parseInt(itemgrup),
        berat : parseInt(berat),
        item_name: nameItem,
        minimum_stok : minimumstock,
        panjang,
        lebar,
        tinggi,
        stock: 0,
        type: nameType,
        part : dataItems,
    };
    
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/production/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/item")), 2000);
          })
          .catch(function (error) {
            setGagalAlert(error.response.data.message);
          });
    }
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }
  
  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };
  
  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={CreateData}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };
  
    const hideAlert = () => {
      setAlert(null);
    }

  const deleteItem = (id) => {
    let array = [...savedItems];

    let index = array.findIndex(object => {
        return object.item_id === id;
    });

    if (index !== -1) {
        // setTotalPrice(totalPrice - array[index].data.price_1 * array[index].qty);
        array.splice(index, 1);
        setSavedItems(array);
    }
}
  return (
    <>
    {alert}
    <SimpleHeader name="Buat Item Produksi" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Buat Item Produksi</h3>
                    </CardHeader>
                    <CardBody>
                  <Row md="12">
                      <Col md="6">
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={4}
                          >
                            Nama Item
                          </Label>
                          <Col sm={7}>
                            <Input
                            autoComplete="off"
                              className="form-control-alternative"
                              type="text"
                             
                              placeholder="Masukan Nama Item"
                              value={nameItem}
                              onChange={(e) => {
                                setNameItem(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={4}
                          >
                            Jenis Barang
                          </Label>
                          <Col sm={7}>
                            <Input
                            autoComplete="off"
                              className="form-control-alternative"
                              name="kategory"
                              type="select"
                              value={itemgrup}
                              onChange={(e) => {
                                setItemGrup(e.target.value);
                              }}
                            >
                              <option value="">Pilih Jenis Barang</option>
                              {itemgrups.map((categorysss, key) => {
                                return (
                                  <option key={key} value={categorysss.id}>
                                    {categorysss.name}
                                  </option>
                                );
                              })}
                              </Input>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={4}
                          >
                            Satuan
                          </Label>
                          <Col sm={7}>
                            <Input
                            autoComplete="off"
                          className="form-control-alternative"
                          name="satuan"
                          type="select"
                          value={satuan}
                          onChange={(e) => {
                            setSatuan(e.target.value);
                          }}
                        >
                          <option value="">Pilih Satuan</option>
                          {allSatuan.map((satuan, key) => {
                            return (
                              <option key={key} value={satuan.id}>
                                {satuan.description}
                              </option>
                            );
                          })}
                        </Input>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={4}
                          >
                            Dimensi <b>(PxLxT)</b>
                          </Label>
                          <Col sm={2}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="number"
                            name="panjang"
                            placeholder="P"
                            value={panjang}
                           onChange={(e) => {
                              setPanjang(e.target.value);
                            }}
                            />
                          </Col>
                          <Col sm={3}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="number"
                            name="lebar"
                            placeholder="L"
                            value={lebar}
                            onChange={(e) => {
                              setLebar(e.target.value);
                             
                            }}
                            />
                          </Col>
                          <Col sm={2}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="number"
                            name="tinggi"
                            placeholder="T"
                            value={tinggi}
                            onChange={(e) => {
                              setTinggi(e.target.value);
                             
                            }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={4}
                          >
                            Berat
                          </Label>
                          <Col sm={4}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="number"
                            name="Berat"
                            placeholder="Masukan Berat"
                            value={berat}
                            onChange={(e) => {
                              setBerat(e.target.value);
                              }}
                            />
                          </Col>
                          <Label
                          for="exampleEmail"
                          sm={4}
                          >
                            <b>Gram</b>
                          </Label>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={4}
                          >
                            Minimum Stock
                          </Label>
                          <Col sm={7}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                            type="number"
                            name="Minimum Stock"
                            placeholder="Masukan Stock"
                            value={minimumstock}
                            onChange={(e) => {
                              setMinimumStock(e.target.value);
                              }}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={4}
                              >
                                Deskripsi
                              </Label>
                              <Col sm={7}>
                                <Input
                                autoComplete="off"
                                  className="form-control-alternative"
                                  type="textarea"
                                  name="descripsi"
                                  rows="8"
                                  placeholder="Masukan Deskripsi"
                                  value={description}
                                  onChange={(e) => {
                                    setDescription(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={3}
                          >
                            Tipe Item
                          </Label>
                          <Col sm={6}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                              type="select"
                             disabled
                              placeholder="Masukan Tipe Item"
                              value={nameType}
                              onChange={(e) => {
                                setNameType(e.target.value);
                              
                              }}
                            >
                              <option value={1}>Material</option>
                              <option value={2}>Produksi</option>
                            </Input>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={3}
                          >
                            Kategori
                          </Label>
                          <Col sm={6}>
                            <Input
                              autoComplete="off"
                              className="form-control-alternative"
                              name="kategory"
                              type="select"
                              value={category}
                              invalid={categoryError === "invalid"}
                              onChange={(e) => {
                                setCategory(e.target.value);
																setCategoryId(e.target.value);
																// getSubCategory(e.target.value);
                                if (e.target.value !== "") {
                                    setCategoryError("");
                                }
                                getSubCategory(e.target.value);
															}}>
                              <option value="">Pilih Kategori</option>
                                {categories.map((category, key) => {
                                  return (
                                    <option key={key} value={category.id}>
                                      {category.name}
                                    </option>
                                  );
                                })}
                              </Input>
                              <FormFeedback>
                                Kategori Tidak Boleh Kosong
                              </FormFeedback>
                          </Col>
                          <Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-kategori/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={3}
                          >
                            Sub Kategori
                          </Label>
                          <Col sm={6}>
                            <Input
                            autoComplete="off"
                                  className="form-control-alternative"
                                  name="subKategory"
                                  type="select"
                                  value={subCategory}
                                  invalid={subCategoryError === "invalid"}
                                  onChange={(e) => {
                                    setSubCategory(e.target.value);
                                    setSubCategoryId(e.target.value);
                                    // getFunction(e.target.value)
                                  if (e.target.value !== "") {
                                      setSubCategoryError("");
                                  }
                                  getFunction(e.target.value);
															  }}>
                                  <option value="">Pilih Sub Kategori</option>
                              {subCategories.map((category, key) => {
                                return (
                                  <option key={key} value={category.id}>
                                    {category.name}
                                  </option>
                                );
                              })}
                            </Input>
                            <FormFeedback>
                              Sub Kategori tidak boleh kosong
                            </FormFeedback>
                          </Col>
                          <Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-sub-kategori/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={3}
                          >
                            Function
                          </Label>
                          <Col sm={6}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                              name="function"
                              type="select"
                              value={nameFunction}
                              onChange={(e) => {
                                setNameFunction(e.target.value);
                                getSubFunction(e.target.value)
                              }}
                            >
                              <option value="">Pilih Function</option>
                              {nameFunctions.map((nameFunction, key) => {
                                return (
                                  <option key={key} value={nameFunction.id}>
                                    {nameFunction.name}
                                  </option>
                                );
                              })}
                            </Input>
                          </Col>
                          <Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-function/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={3}
                          >
                            Sub Function
                          </Label>
                          <Col sm={6}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                              name="subFunction"
                              type="select"
                              value={nameSubFunction}
                              onChange={(e) => {
                                setNameSubFunction(e.target.value);
                              }}
                            >
                              <option value="">Pilih Sub Function</option>
                              {nameSubFunctions.map((nameFunction, key) => {
                                return (
                                  <option key={key} value={nameFunction.id}>
                                    {nameFunction.name}
                                  </option>
                                );
                              })}
                            </Input>
                          </Col>
                          <Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-sub-function/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={3}
                          >
                            Merek
                          </Label>
                          <Col sm={6}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                              name="merek"
                              type="select"
                              value={merek}
                              onChange={(e) => {
                                setMerek(e.target.value);
                                getSubMerek(e.target.value)
                              }}
                            >
                              <option value="">Pilih Merek</option>
                              {allMerek.map((m, key) => {
                                return (
                                  <option key={key} value={m.id}>
                                    {m.name}
                                  </option>
                                );
                              })}
                            </Input>
                          </Col>
                          <Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-merek/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={3}
                          >
                            Sub Merk
                          </Label>
                          <Col sm={6}>
                            <Input
                            autoComplete="off"
                            className="form-control-alternative"
                              name="merek"
                              type="select"
                              value={submerek}
                              onChange={(e) => {
                                setSubMerek(e.target.value);
                              }}
                            >
                              <option value="">Pilih Merek</option>
                              {allSubMerek.map((m, key) => {
                                return (
                                  <option key={key} value={m.id}>
                                    {m.name}
                                  </option>
                                );
                              })}
                            </Input>
                          </Col>
                          <Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-sub-merek/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={3}
                          >
                            Grade
                          </Label>
                          <Col sm={6}>
                          <Input
                          className="form-control-alternative"
                          name="grade"
                          type="select"
                          value={grade}
                          onChange={(e) => {
                            setGrade(e.target.value);
                            }}
                        >
                          <option value="">Pilih Grade</option>
                          {allGrade.map((a, key) => {
                            return (
                              <option key={key} value={a.id}>
                                {a.nilai}
                              </option>
                            );
                          })}
                        </Input>
                          </Col>
                          
                          <Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-grade/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
                        </FormGroup>
                      </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                        <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Qty</b></th>
                                <th><b>Satuan</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="qty"
                                          type="number"
                                          value={savedItem.qty}
                                          onChange={(e) => {
                                              handleEditQty(key, e.target.value);
                                          }}/>
                                      </td>
                                      <td>{savedItem.satuan}</td>
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              <tr>
                                <td>
                                  <><Form onSubmit={handleSubmit}>
                                          <Input
                                            autoComplete="off"
                                            placeholder="Masukan Item Manual"
                                            type="search"
                                            // onKeyDown={searchh}
                                            value={queryy}
                                            onChange={(e) => setQueryy(e.target.value)}
                                          />
                                          {/* <Button type="submit"><i className="fa fa-search" /></Button> */}
                                        
                                        {isSearchShoww && queryy && (
                                        <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                          <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                            <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
                                          </div>
                                          {allItemm?.response ? (
                                            allItemm.response.map((item) => (
                                              <CardBody key={item.id} className="bgSearch"onClick={() => {saveItemOrUpdate(item);setQueryy('');setIsSearchShoww(false);}} style={{ minHeight: "5rem", padding: "1rem" }}>
                                                <div>
                                                  <b>Nama item:</b> {item.item_name}
                                                </div>
                                              </CardBody>
                                            ))
                                          ) : (
                                            <div className="text-center mb-3 text-danger">Item "{queryy}" tidak ada bosku!</div>
                                          )}
                                        </Card>
                                      )}
                                  </Form></>
                                  </td>
                                 </tr>
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter>
                              <Link className="btn btn-info" to="/admin/item">
                                  Kembali
                              </Link>
                              <Button color="danger" onClick={setQuestionAlert}>
                                  Simpan
                              </Button>
                        </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row> 
    </Container>  
    </>
  );
}

export default CreateItemProduksi;