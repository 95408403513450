/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Col,
  Input,
  Container,
  CardHeader,
  Form ,
  CardFooter,
  Button,
  FormFeedback,
  InputGroup,
  InputGroupText
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";


export default function EditCustomer(props) {
  const token = localStorage.token;
  let history = useHistory();
  const [name, setName] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [province, setProvince] = useState("");
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber , setPhoneNumber ] = useState([]);
  const [pricetype, setPriceType] = useState("");
  const [email, setEmail] = useState("")
  const [limithutang,setLimitHutang] = useState(0);
	const [limitpiutang, setLimitPiutang] = useState(0)
	const [bankname1,setBankName1] = useState("");
	const [bankname2,setBankName2] = useState("");
	const [bankaccount1,setBankAccount1] = useState("");
	const [bankaccount2,setBankAccount2] = useState("");
	const [banknumber1,setBankNumber1] = useState("");
	const [banknumber2,setBankNumber2] = useState("");
  const [banks1,setBanks1] = useState([])
  const [banks2,setBanks2] = useState([])
  const [bankname1Error,setBankName1Error] = useState(null);
  const [bankname2Error,setBankName2Error] = useState(null);
  const [bankaccount1Error,setBankAccount1Error] = useState(null);
  const [bankaccount2Error,setBankAccount2Error] = useState(null);
  const [banknumber1Error,setBankNumber1Error] = useState(null);
  const [banknumber2Error,setBankNumber2Error] = useState(null);
  const [namePersonError, setNamePersonError] = useState(null);
  const [addressError, setAddressError] = useState(null);
  const [limitPiutangError, setLimitPiutangError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [provinceError, setProvinceError] = useState(null);
  const [cityError, setCityError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [limitHutangError, setLimitHutangError] = useState(null);
  const [pricetypeError, setPriceTypeError] = useState(null);
  const [alert, setAlert] = React.useState(null);
  const allInfo = JSON.parse(localStorage.allinfo);
  const customer = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Customer").map((p) => p.read_access));
 
  const validateForm = () => {
    let error = false;
    if (name === "") {
      setNamePersonError("invalid");
      error = true;
    }
    if (email === "") {
      setEmailError("invalid");
      error = true;
    }
    if (address === "") {
        setAddressError("invalid");
      error = true;
    }
    if (limithutang === "") {
      setLimitHutangError("invalid");
      error = true;
    }
    if (province === "") {
        setProvinceError("invalid");
        error = true;
      }
    if (city === "") {
        setCityError("invalid");
        error = true;
      }
    if (limitpiutang === "") {
      setLimitPiutangError("invalid");
      error = true;
    }
    if (phoneNumber === "") {
        setPhoneError("invalid");
      error = true;
    }
    if (pricetype === "") {
      setPriceTypeError("invalid");
      error = true;
    }
    return error;
  };

  const pricelimithutang = (e) => {
    const value = e === undefined ? 'undefined' : e;
    setLimitHutang(value || ' ');
    if (value !== "") {
        setLimitHutangError("");
      }
                                                            
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
        setQuestionAlert();
    }
  }


  useEffect(() => {
        getById();
        getBank();
    }, []);

    const getById = () => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/customer/get/${props.match.params.id}`,
	        { headers }
	      )
	      .then((data) => {
            getProvince(data.data.response.province);
            setEmail(data.data.response.customer_email);
            setPriceType(data.data.response.price_type);
            setCity(data.data.response.city);
            setName(data.data.response.name);
            setAddress(data.data.response.address);
            setPhoneNumber(data.data.response.phone);
            setLimitHutang(data.data.response.limit_hutang);
            setLimitPiutang(data.data.response.limit_piutang);
            setBankName1(data.data.response.bank_name1);
            setBankName2(data.data.response.bank_name2);
            setBankAccount1(data.data.response.bank_account1);
            setBankAccount2(data.data.response.bank_account2);
            setBankNumber1(data.data.response.bank_number1);
            setBankNumber2(data.data.response.bank_number2);

	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

    const getBank = () => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/daftar-bank/list`, { headers
      })
      .then(data => {
          setBanks1(data.data.response);
          setBanks2(data.data.response);
          
      })
        .catch(function (error) {
          console.log(error)
        })
    }

  const getProvince = (id) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/province/list`, { headers
    })
    .then(data => {
        setProvinces(data.data.response_data);
        getCity(id);
        setProvince(id);
        
    })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getCity = (id) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		  axios.get(`${process.env.REACT_APP_API_BASE_URL}/city?province_id=${id}`, { headers
		})
		.then(data => {
			setCities(data.data.response_data);
		})
		  .catch(function (error) {
			console.log(error)
		  })
	  }

  

  function CreateData() {
    let data = {
      name: name,
      active_flag: 1,
      province: parseInt(province),
      city: parseInt(city),
      address: address,
      phone: phoneNumber ,
      customer_email : email,
      price_type: parseInt(pricetype),
      bank_name1: bankname1 ,
      bank_name2: bankname2,
      bank_account1: bankaccount1,
      bank_account2: bankaccount2,
      bank_number1 : banknumber1,
      bank_number2 : banknumber2,
      limit_piutang: parseInt(limitpiutang),
      limit_hutang: parseInt(limithutang),
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/customer/update/${props.match.params.id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .then(function (response) {
      setSuccessAlert(response.data.message);
      setTimeout(() => (history.push("/admin/customer")), 1000);
      })
      .catch(function (error) {
        setGagalAlert(error.response.data.message);
      });
}

const setSuccessAlert = (id) => {
  setAlert(
    <SweetAlert
      success
      showConfirm
      confirmBtnText="Ok"
      title={id}
      onCancel={hideAlert}
      onConfirm={hideAlert}
    />
  )
}

const setGagalAlert = (id) => {
setAlert(
<SweetAlert
danger
showConfirm
confirmBtnText="Ok"
title={id}
onCancel={hideAlert}
onConfirm={hideAlert}
/>
);
};

const setQuestionAlert = () => {
setAlert(
<SweetAlert
warning
showCancel
confirmBtnText="Lanjutkan"
confirmBtnBsStyle="danger"
title="Apakah Kamu Yakin?"
onConfirm={CreateData}
onCancel={hideAlert}
focusCancelBtn
/>
);
};

const hideAlert = () => {
  setAlert(null);
}

  return (
    <>
    {alert}
    {customer && customer === "1" ? (
			<div>
      <SimpleHeader name="Edit Customer" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <Form onSubmit={handleSubmit} >
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Buat Customer</h3>
                    </CardHeader>
                    <CardBody>
                    <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Nama
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          autoComplete="off"
                          type="text"
                          name="nama"
                          placeholder="Masukan Nama"
                          value={name}
                          invalid={namePersonError === "invalid"}
                          onChange={(e) => {
                            setName(e.target.value);
                            if (e.target.value !== "") {
                              setNamePersonError("");
                            }
                          }}
                        />
                        <FormFeedback>
                          Name tidak boleh kosong
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Province
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          autoComplete="off"
                          name="Province"
                          type="select"
                          value={province}
                          invalid={provinceError === "invalid"}
                          onChange={(e) => {
                            setProvince(e.target.value);
                            if (e.target.value !== "") {
                              setProvinceError("");
                            }
                            getCity(e.target.value);
                          }}
                        >
                          <option value="">Pilih Province</option>
                          {
                            provinces.map((prov, key) => {
                              return <option key={key} value={prov.id}>{prov.name}</option>
                            })
                          }
                        </Input>
                        <FormFeedback>Province tidak boleh kosong</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlSelect3"
                        >
                          Kota
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          autoComplete="off"
                          name="Kota"
                          type="select"
                          value={city}
                          invalid={cityError === "invalid"}
                          onChange={(e) => {
                            setCity(e.target.value);
                            if (e.target.value !== "") {
                              setCityError("");
                            }
                          }}
                        >
                          <option value="">Pilih Kota</option>
                          {
                            cities.map((city, key) => {
                              return <option key={key} value={city.id}>{city.name}</option>
                            })
                          }
                        </Input>
                        <FormFeedback>Kota tidak boleh kosong</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Alamat
                          <span className="text-danger">*</span>
                          </Label>
                        <Input
                        autoComplete="off"
                          type="text"
                          name="alamat"
                          placeholder="Masukan Alamat"
                          value={address}
                          invalid={addressError === "invalid"}
                          onChange={(e) => {
                            setAddress(e.target.value);
                            if (e.target.value !== "") {
                              setAddressError("");
                            }
                          }}
                        />
                        <FormFeedback>
                          Alamat tidak boleh kosong
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Kontak
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                        autoComplete="off"
                          type="number"
                          pattern='[0-9]{0,5}'
                          maxLength={12}
                          name="phoneNumber"
                          placeholder="Masukan Kontak"
                          value={phoneNumber}
                          invalid={phoneError === "invalid"}
                          onChange={(e) => {
                            setPhoneNumber(e.target.value);
                            if (e.target.value !== "") {
                              setPhoneError("");
                            }
                          }}
                        />
                        <FormFeedback>
                          Nomor Telpon tidak boleh kosong
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Email
                          <span className="text-danger">*</span>
                                              </Label>
                        <Input
                        autoComplete="off"
                          type="text"
                          name="phoneNumber"
                          placeholder="Masukan Email"
                          value={email}
                          invalid={emailError === "invalid"}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            if (e.target.value !== "") {
                              setEmailError("");
                            }
                          }}
                        />
                        <FormFeedback>
                          Email tidak boleh kosong
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Tipe Harga
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          autoComplete="off"
                          type="select"
                          value={pricetype}
                          invalid={pricetypeError === "invalid"}
                          onChange={(e) => {
                            setPriceType(e.target.value)
                            if (e.target.value !== "") {
                              setPriceTypeError("");
                            }
                          }}
                        >
                          <option value="" selected hidden>Pilih Tipe Harga</option>
                          <option value="1">Level 1</option>
                          <option value="2">Level 2</option>
                          <option value="3">Level 3</option>
                          <option value="4">Level 4</option>
                          <option value="5">Level 5</option>
                        </Input>
                        <FormFeedback>
                          Tipe Harga tidak boleh kosong
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Limit Hutang
                          <span className="text-danger">*</span>
                                              </Label>
                        <InputGroup>
                          <InputGroupText>
                          Rp
                          </InputGroupText>
                        <Input
                        autoComplete="off"
                          type="number"
                          placeholder="Rp.0"
                          value={limithutang}
                          invalid={limitHutangError === "invalid"}
                          onChange={(e) => {
                            setLimitHutang(e.target.value);
                            if (e.target.value !== "") {
                              setLimitHutangError("");
                            }
                          }}
                        />
                        </InputGroup>
                        <FormFeedback>
                          Limit Hutang tidak boleh kosong
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Limit Piutang
                          <span className="text-danger">*</span>
                                              </Label>
                                              <InputGroup>
                          <InputGroupText>
                          Rp
                          </InputGroupText>
                        <Input
                        autoComplete="off"
                          type="number"
                          name="phoneNumber"
                          placeholder="Rp.0"
                          value={limitpiutang}
                          invalid={limitPiutangError === "invalid"}
                          onChange={(e) => {
                            setLimitPiutang(e.target.value);
                            if (e.target.value !== "") {
                              setLimitPiutangError("");
                            }
                          }}
                        />
                        </InputGroup>
                        <FormFeedback>
                          Limit Piutang tidak boleh kosong
                        </FormFeedback>
                      </FormGroup>
                    </CardBody>
                  
              </Card>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                    <h3>Bank</h3>
                </CardHeader>
                <Row md="12">
                    <Col md="6">
                        <CardBody>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                for="exampleEmail" sm={4}>
                                    Nama Bank
                                </Label>
                                <Col sm={7}>
                                <Input
                                    autoComplete="off"
                                    name="Kota"
                                    type="select"
                                    value={bankname1}
                                    onChange={(e) => {
                                        setBankName1(e.target.value);
                                    }}
                                    >
                                    <option value="">Pilih Bank</option>
                                    {
                                        banks1.map((city, key) => {
                                        return <option key={key} value={city.bank_name}>{city.bank_name}</option>
                                        })
                                    }
                                </Input>
                                <FormFeedback>
                                    Nama Bank tidak boleh kosong
                                </FormFeedback>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                for="exampleEmail" sm={4}>
                                    Nama Rekening
                                </Label>
                                <Col sm={7}>
                                    <Input
                                    
                                        autoComplete="off"
                                        className="form-control-alternative"
                                        type="text"
                                        placeholder="Masukan Atas Nama Rekening"
                                        value={bankaccount1}
                                        onChange={(e) => {
                                            setBankAccount1(e.target.value);
                                        }}
                                    />
                                      <FormFeedback>
                                        Nama Rekening tidak boleh kosong
                                    </FormFeedback>
                                </Col>
                            </FormGroup>
                        </CardBody>
                    </Col>
                    <Col md="6">
                        <CardBody>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                for="exampleEmail" sm={4}>
                                    Nomor Rekening
                                </Label>
                                <Col sm={7}>
                                    <Input
                                    
                                    autoComplete="off"
                                        className="form-control-alternative"
                                        type="number"
                                        pattern='[0-9]{0,5}'
                                        placeholder="Masukan Nomor Rekening"
                                        value={banknumber1}
                                        onChange={(e) => {
                                            setBankNumber1(e.target.value);
                                        }}
                                    />
                                    <FormFeedback>
                                        Nomor Rekening tidak boleh kosong
                                    </FormFeedback>
                                </Col>
                            </FormGroup>
                        </CardBody>
                    </Col>
                </Row>
                <Row md="12">
                    <Col md="6">
                        <CardBody>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                for="exampleEmail" sm={4}>
                                    Nama Bank
                                </Label>
                                <Col sm={7}>
                                    <Input
                                        autoComplete="off"
                                        name="Kota"
                                        type="select"
                                        value={bankname2}
                                        onChange={(e) => {
                                            setBankName2(e.target.value);
                                        }}
                                        >
                                        <option value="">Pilih Bank</option>
                                        {
                                            banks2.map((city, key) => {
                                            return <option key={key} value={city.bank_name}>{city.bank_name}</option>
                                            })
                                        }
                                    </Input>
                                    <FormFeedback>
                                        Nama Bank tidak boleh kosong
                                    </FormFeedback>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                for="exampleEmail" sm={4}>
                                    Nama Rekening
                                </Label>
                                <Col sm={7}>
                                    <Input
                                    
                                        autoComplete="off"
                                        className="form-control-alternative"
                                        type="text"
                                        placeholder="Masukan Akun Bank"
                                        value={bankaccount2}
                                        onChange={(e) => {
                                            setBankAccount2(e.target.value);
                                        }}
                                    />
                                    <FormFeedback>
                                        Nama Rekening tidak boleh kosong
                                    </FormFeedback>
                                </Col>
                            </FormGroup>
                        </CardBody>
                    </Col>
                    <Col md="6">
                        <CardBody>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                for="exampleEmail" sm={4}>
                                    Nomor Rekening
                                </Label>
                                <Col sm={7}>
                                    <Input
                                    
                                    autoComplete="off"
                                        className="form-control-alternative"
                                        type="number"
                                        pattern='[0-9]{0,5}'
                                        placeholder="Masukan Nomor Rekening"
                                        value={banknumber2}
                                        onChange={(e) => {
                                            setBankNumber2(e.target.value);
                                        }}
                                    />
                                    <FormFeedback>
                                        Nomor Rekening tidak boleh kosong
                                    </FormFeedback>
                                </Col>
                            </FormGroup>
                        </CardBody>
                    </Col>
                </Row>
                <CardFooter>
                    <Link className="btn btn-info" to="/admin/customer">
                        Kembali
                    </Link>
                    <Button color="danger" type="submit">
                        Simpan
                    </Button>
                </CardFooter>
            </Card>
            </Form>
          </div>
        </Row>
      </Container>
      </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}
