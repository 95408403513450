/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col, Badge, CardBody, CardHeader, Container,Form, FormGroup, Label, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";  
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

// import * as FileSaver from "file-saver";
// import * as XLSX from "xlsx";

// import {PDFDownloadLink} from '@react-pdf/renderer';
// import PdfReportSo from './Pdf';

const JurnalUmum = () => {
  const token = localStorage.token;
  const warehouse = parseInt(localStorage.warehouse);
  const redirectPrefix = `/admin/laporan-jurnal/jurnal-umum/detail/`;

  const [rowIndex, setRowIndex] = useState(0);
  const [allPenawaranSo, setAllPenawaranSo] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(1000);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [rekanan, setRekanan] = useState("");
  
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, start, end, rekanan);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, start,end,rekanan) => {
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setStart(start);
    setEnd(end);
    setRekanan(rekanan);
    getPenawaranSo(page, perPage, sort, start,end,rekanan);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort,  start,end,rekanan)
    }
  }

  
  useEffect(() => {
    getPenawaranSo(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getPenawaranSo = async (page, perPage, currentSort, start='',end='',rekanan='') => {
    
    let filter = { 
      page: page, 
      per_page: perPage,
      is_parent:1,
      warehouse_id : parseInt(warehouse)
    };
    if (start !== '') {
      filter = Object.assign(filter, { start_date: start })
    }
    if (end !== '') {
      filter = Object.assign(filter, { end_date: end })
    }
    if (rekanan !== '') {
      filter = Object.assign(filter, { transaction_name: rekanan })
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/account-journal/umum`, data, {
        headers,
      })
      .then((data) => {
        setAllPenawaranSo(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        // setAllPenawaranSo(error.data.response)
        setAllPenawaranSo(error.response.data.response);
      });
  };

  
  return (
    <>
        <SimpleHeader name="Jurnal Umum" parentName="Report" />
        <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3>Jurnal Umum</h3>
                </div>
              </CardHeader>
              <CardBody>
                      <Form>
                        <Row md="12">
                        <Col md="" sm="4">
                            <FormGroup>
                              <Label>Start Date</Label>
                              <Input
                                className="form-control-alternative"
                                name="start"
                                type="date"
                                value={start}
                                onChange={e => updateDataTable(1, perPage, currentSort, e.target.value, end, rekanan)}
                              >
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col md="" sm="4">
                            <FormGroup>
                              <Label>End Date</Label>
                              <Input
                                className="form-control-alternative"
                                name="end"
                                type="date"
                                value={end}
                                onChange={e => updateDataTable(1, perPage, currentSort, start, e.target.value, rekanan)}
                              >
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col md="" sm="6">
                            <FormGroup>
                              <Label>Nama</Label>
                              <Input
                                className="form-control-alternative"
                                name="rekanan"
                                placeholder='masukan customer/supplier'
                                type="text"
                                value={rekanan}
                                onChange={e => updateDataTable(1, perPage, currentSort, start, end, e.target.value)}
                              >
                                
                              </Input>
                            </FormGroup>
                          </Col>
                          {/* <Col md="" sm="6">
                            <FormGroup>
                              <Label htmlFor="exampleFormControlSelect3">Status</Label>
                              <Input
                                className="form-control-alternative"
                                name="Tipe So"
                                type="select"
                                value={status}
                                onChange={e => updateDataTable(1, perPage, currentSort, e.target.value, description,start,end,rekanan)}
                              >
                                <option value="">Pilih Sales Order</option>
                                <option value="1">Cahsier</option>
                                <option value="2">Project</option>
                                <option value="3">E-commerce</option>
                                <option value="4">Canvaser</option>
                              </Input>
                            </FormGroup>
                          </Col> */}
                          <Col md="" sm="4">
                          <Label>&nbsp;</Label>
                          <br></br>
                            <Button type='button' onClick={e => updateDataTable(1, perPage, currentSort, start, end, rekanan)} className="btn btn-info"><i className="fa fa-filter"></i></Button>
                            {/* <ButtonDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(true)}> */}
                            {/* <UncontrolledDropdown nav>
                              <DropdownToggle caret color="primary">
                                Download
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={()=> {downloadExcel(allPenawaranSo)}}>Excel</DropdownItem>
                                <DropdownItem>PDF</DropdownItem>
                                <DropdownItem>
                                  <PDFDownloadLink
                                      document={
                                      <PdfReportSo data={{start, end, rekanan}}/>}
                                      fileName="Report Laporan SO.pdf"
                                      style={{color: '#000'}}>
                                        PDF
                                  </PDFDownloadLink>
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown> */}
                            {/* </ButtonDropdown> */}
                          </Col>
                        </Row>
                      </Form>
                    <CardBody className="bg-white border-0">
                    {allPenawaranSo === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                  dataField: "",
                                  text: "Tanggal Jurnal",
                                  sort: true,
                              },
                              {
                                  dataField: "",
                                  text: "Kode Jurnal",
                                  sort: true,
                              },
                              {
                                  dataField: "",
                                  text: "Supplier/Customer",
                                  sort: true,
                              },
                              {
                                  dataField: "",
                                  text: "Transaksi",
                                  sort: true,
                              },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                        </div>
                        ):(
                          <ToolkitProvider
                          rowNumber={rowIndex}
                          data={allPenawaranSo}
                          keyField="id"
                          columns={[
                          {
                              dataField: "no",
                              text: "#",
                              sort: true,
                              page: 1,
                              formatter: (cell, row, index) => {
                              let currentRow = ++index;
                              return currentRow + rowIndex;
                              },
                          },
                          {
                              dataField: "tgl_journal",
                              text: "Tanggal Jurnal",
                              sort: true,
                          },
                          {
                            dataField: "journal_code",
                            text: "Kode Jurnal",
                            sort: true,
                            formatter: (cell, row) => {
                              return row.journal_code === ""
                                ? <h2>
                                  <Badge color="warning" >
                                    {row.journal_code}
                                  </Badge>
                                  </h2>
                                : 
                                <h2>
                                <Badge color="warning" >
                                    {row.journal_code}
                                  </Badge>
                                  </h2>
                            },
                        },
                          {
                              dataField: "transaction_name",
                              text: "Supplier/Customer",
                              sort: true,
                          },
                          {
                              dataField: "transaction_code",
                              text: "Transaksi",
                              sort: true,
                              formatter: (cell, row) => {
                                return row.state_colour === 0
                                  ? 
                                  <h2>
                                    <Badge color="info" >
                                      {row.transaction_code}
                                    </Badge>
                                    </h2>
                                  : row.state_colour === 1 ?
                                  <h2>
                                  <Badge color="danger" >
                                      {row.transaction_code}
                                    </Badge>
                                    </h2>
                                  : row.state_colour === 2 ?
                                  <h2>
                                  <Badge color="primary" >
                                      {row.transaction_code}
                                    </Badge>
                                    </h2>
                                  : row.state_colour === 3 ?
                                  <h2>
                                  <Badge color="warning" >
                                      {row.transaction_code}
                                    </Badge>
                                    </h2>
                                    :
                                    row.state_colour === 4 ?
                                  <h2>
                                  <Badge color="secondary" >
                                      {row.transaction_code}
                                    </Badge>
                                    </h2>
                                     :
                                     row.state_colour === 5 ?
                                   <h2>
                                   <Badge color="light" >
                                       {row.transaction_code}
                                     </Badge>
                                     </h2>
                                      :
                                      row.state_colour === 6 ?
                                    <h2>
                                    <Badge color="success" >
                                        {row.transaction_code}
                                      </Badge>
                                      </h2>
                                       :
                                  <h2>
                                  <Badge color="dark" >
                                      {row.transaction_code}
                                    </Badge>
                                    </h2>
                              },
                          },
                          {
                            dataField: "", text: "", formatter: (cell, row, index) => {
                            return (
                              <UncontrolledDropdown nav>
                              <DropdownToggle color="danger" >
                                    Tindakan
                              </DropdownToggle>
                              <DropdownMenu>
                                    <Link  to={redirectPrefix + row.journal_code}
                                      id={"tooltip_" + row.id}>
                                    <DropdownItem>
                                      <i className="fas fa-user-edit" /><span>Detail</span>
                                    </DropdownItem>
                                    </Link>
                              </DropdownMenu>
                          </UncontrolledDropdown>
                            )
                            }
                        },
                          ]}
                      >
                          {(props) => (
                          <div className="py-4 table-responsive">
                              <BootstrapTable
                              remote
                              {...props.baseProps}
                              bootstrap4={true}
                              bordered={false}
                              hover={true}
                              pagination={paginationFactory({ ...paginationOption })}
                              onTableChange={handleTableChange}
                              />
                          </div>
                        )}
                  </ToolkitProvider>
                    )}
                   
                    </CardBody>
              </CardBody>
            </Card>
          </div>
        </Row>
        </Container>
    </>
  );
}

export default JurnalUmum;