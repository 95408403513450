/*eslint-disable*/
import React, { useState } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
} from "reactstrap";

import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";


export default function CreateJadwalOpname() {
  const [alert, setAlert] = React.useState(null);
  const warehouse = localStorage.warehouse;
  const username = localStorage.username;
  const token = localStorage.token;
  let history = useHistory();
  const [tanggal, setTanggal] = useState("");
  const [tipe, setTipe] = useState("");
  const allInfo = JSON.parse(localStorage.allinfo);
  const JadwalStokOpnames = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Stok Opname Scheduled").map((p) => p.read_access));
  
  function CreateData() {
    let data = {
      active_flag: 1,
      warehouse_id : parseInt(warehouse),
      username : username,
      type : tipe,
      opname_date : moment(tanggal).format("YYYY-MM-DD 00:00:00"),

    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/opname-scheduled/save`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setTimeout(() => ( history.push("/admin/jadwal-stock-opname/page")), 1000);
      })
      .catch(function (error) {
            setGagalAlert(error.response.data.message);
      });
}

const setSuccessAlert = (id) => {
  setAlert(
    <SweetAlert
      success
      showConfirm
      confirmBtnText="Ok"
      title={id}
      onCancel={hideAlert}
      onConfirm={hideAlert}
    />
  )
}

const setGagalAlert = (id) => {
setAlert(
<SweetAlert
danger
showConfirm
confirmBtnText="Ok"
title={id}
onCancel={hideAlert}
onConfirm={hideAlert}
/>
);
};

const setQuestionAlert = () => {
setAlert(
<SweetAlert
warning
showCancel
confirmBtnText="Lanjutkan"
confirmBtnBsStyle="danger"
title="Apakah Kamu Yakin?"
onConfirm={CreateData}
onCancel={hideAlert}
focusCancelBtn
/>
);
};

const hideAlert = () => {
  setAlert(null);
};

  return (
    <>
    {alert}
    {JadwalStokOpnames && JadwalStokOpnames === "1" ? (
      <div>
      <SimpleHeader name="Buat Jadwal Opname" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Buat Jadwal Opname</h3>
                    </CardHeader>
                    <CardBody>
                    <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Tanggal Opname
                        </Label>
                        <Input
                          autoComplete="off"
                          type="date"
                          name="nama"
                          placeholder="Masukan Nama Tanggal"
                          value={tanggal}
                          onChange={(e) => {
                            setTanggal(e.target.value);
                          }}
                        />
                        
                      </FormGroup>
                      <FormGroup>
                        <Label
                          className="form-control-label"
                          htmlFor="exampleFormControlInput1"
                        >
                          Status
                        </Label>
                        <Input
                          autoComplete="off"
                          type="select"
                          name="nama"
                          placeholder="Masukan Nama Tanggal"
                          value={tanggal}
                          onChange={(e) => {
                            setTanggal(e.target.value);
                          }}
                        >
                          <option>Pilih Status Opname</option>
                          <option value={1}>Opname Tertutup</option>
                          <option value={2}>Opname Terbuka</option>
                          </Input>
                        
                      </FormGroup>
                    </CardBody>
                    <CardFooter>
                      <Link className="btn btn-info" to="/admin/jadwal-stock-opname/page">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={()=>setQuestionAlert()}>
                          Simpan
                      </Button>
                    </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
      </Container>
      </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}
