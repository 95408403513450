/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Row,
  Container,
  CardImg,
} from "reactstrap";
import { Link } from "react-router-dom";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";

export default function PermintaanBarangPoCabang() {
    const allInfo = JSON.parse(localStorage.allinfo);
    const StokOpnames = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Stok Opname").map((p) => p.read_access));
    const StokOpname = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Stok Opname").map((p) => p.read_access));
	const ValidasiStokOpname = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Validasi Stok Opname").map((p) => p.read_access));
	const HistoriStokOpname = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Cetak Stok Opname").map((p) => p.read_access));
 return (
    <>
    {StokOpnames && StokOpnames === "1" ? (
      <div>
      <SimpleHeader name="Stok Opname" parentName="Report" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <CardBody>
                    <Row>
                        <div className="card-columns">
                        {StokOpname && StokOpname === "1" && (
                            <Card>
                                <Link to="/admin/stock-opname/page">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/stok-opname.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        {ValidasiStokOpname && ValidasiStokOpname === "1" && (
                            <Card>
                                <Link to="/admin/stock-opname/validasi-direktur">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/validasi-direktur.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                            {HistoriStokOpname && HistoriStokOpname === "1" && (
                            <Card>
                                <Link to="/admin/stock-opname/histori">
                                    <CardImg
                                        alt="..."
                                        src={require("assets/img/theme/history.png").default}
                                        top
                                    />
                                </Link>
                            </Card>
                        )}
                        </div>
					</Row>
                </CardBody>
          </div>
        </Row>
      </Container>
      </div>
    ):(
        <Halaman404 />
    )}
    </>
  );
}