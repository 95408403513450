/*eslint-disable*/
import React, { useState,useEffect } from "react";
import {
  Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
    Table,
    CardFooter,
    Form,
    Button,
	Col,
	Container,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import CurrencyInput from 'react-currency-input-field';


export default function CreatePromoPerorangan() {
  const token = localStorage.token;
  const username = localStorage.username;
  let history = useHistory();
  const [namapromo, setNamaPromo] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [potonganpertransaksi, setPotonganPerTransaksi] = useState(0);
  const [uniontype, setUnionType] = useState("");
  const [senin, setSenin] = useState(2);
  const [selasa, setSelasa] = useState(2);
  const [rabu, setRabu] = useState(2);
  const [kamis, setKamis] = useState(2);
  const [jumat, setJumat] = useState(2);
  const [sabtu, setSabtu] = useState(2);
  const [minggu, setMinggu] = useState(2);
  const [januari, setJanuari] = useState(2);
  const [februari,setFebruari] = useState(2);
  const [maret,setMaret] = useState(2);
  const [april,setApril] = useState(2);
  const [mei,setMei] = useState(2);
  const [juni,setJuni] = useState(2);
  const [juli,setJuli] = useState(2);
  const [agustus,setAgustus] = useState(2);
  const [september,setSeptember] = useState(2);
  const [oktober,setOktober] = useState(2);
  const [november,setNovember] = useState(2);
  const [desember,setDesember] = useState(2);
  const [warehouse,setWarehouse] = useState("");
  const [warehouses,setWarehouses] = useState([]);
  const [pic,setPic]=useState("")
  const [karyawans, setKaryawans] = useState([]);
  const [start,setStart] = useState("");
  const [end,setEnd] = useState("");
  const [status,setStatus] = useState(2);
  const [budgetawal, setBudgetAwal] = useState(0);
    const [alert, setAlert] = React.useState(null);

    useEffect(() => {
        getCabang();
      }, []);
    
      const getCabang = () => {
          const headers = {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            }
          axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`, { headers
          })
          .then(data => {
            setWarehouses(data.data.response);
          })
            .catch(function (error) {
              console.log(error)
            })
        }

      const getKaryawan = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/karyawan/list/${id}`, { headers })
			.then((data) => {
				setKaryawans(data.data.response);
			})
			.catch(function (error) {
				console.log(error);
			});
	};


  function CreateData() {
    let data = {
        username: username,
        promo_name: namapromo,
        budget_awal : budgetawal,
        pic : pic,
        potongan_per_transaksi: potonganpertransaksi,
        keterangan: keterangan,
        union_type: parseInt(uniontype),
        senin: parseInt(senin),
        selasa:parseInt(selasa),
        rabu:parseInt(rabu),
        kamis:parseInt(kamis),
        jumat:parseInt(jumat),
        sabtu:parseInt(sabtu),
        minggu:parseInt(minggu),
        januari: parseInt(januari),
        februari: parseInt(februari),
        maret: parseInt(maret),
        april: parseInt(april),
        mei:parseInt(mei),
        juni:parseInt(juni),
        juli:parseInt(juli),
        agustus:parseInt(agustus),
        september:parseInt(september),
        oktober:parseInt(oktober),
        november:parseInt(november),
        desember:parseInt(desember),
        start_date:moment(start).format("YYYY-MM-DD 00:00:00"),
        end_date: moment(end).format("YYYY-MM-DD 00:00:00"),
        active_flag:parseInt(status),
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/promo/poin-dua/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
            setSuccessAlert(response.data.message);
            setTimeout(() => (history.push("/admin/promo/promo-budget")), 2000);
            })
            .catch(function (error) {
              setGagalAlert(error.response.data.message);
            });
      };
    
      const setSuccessAlert = (id) => {
        setAlert(
          <SweetAlert
            success
            showConfirm
            confirmBtnText="Ok"
            title={id}
            onCancel={hideAlert}
            onConfirm={hideAlert}
          />
        )
      }
    
    const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
      danger
      showConfirm
      confirmBtnText="Ok"
      title={id}
      onCancel={hideAlert}
      onConfirm={hideAlert}
      />
    );
    };
    
    const setQuestionAlert = () => {
    setAlert(
      <SweetAlert
      warning
      showCancel
      confirmBtnText="Lanjutkan"
      confirmBtnBsStyle="danger"
      title="Apakah Kamu Yakin?"
      onConfirm={CreateData}
      onCancel={hideAlert}
      focusCancelBtn
      />
    );
    };
    
    const hideAlert = () => {
        setAlert(null);
      }

        const allCentanghari = async (e) => {
            e.preventDefault();
            {
              setSenin("1");
              setSelasa("1");
              setRabu("1");
              setKamis("1");
              setJumat("1");
              setSabtu("1");
              setMinggu("1");
              return true
            }
          };
    
          const allCentangBulan = async (e) => {
            e.preventDefault();
            {
              setJanuari("1");
              setFebruari("1");
              setMaret("1");
              setApril("1");
              setMei("1");
              setJuni("1");
              setJuli("1");
              setAgustus("1");
              setSeptember("1");
              setOktober("1");
              setNovember("1");
              setDesember("1");
              return true
            }
          };

          const badgetAwal = (e) => {
            const value = e === undefined ? 'undefined' : e;
            setBudgetAwal(value || ' ');
            };

            const PromoPerTransaksi = (e) => {
                const value = e === undefined ? 'undefined' : e;
                setPotonganPerTransaksi(value || ' ');
                };

  return (
    <>
    {alert}
      <SimpleHeader name="Buat Promo Perorangan" parentName="Master" />
      <Container className="mt--6" fluid>
        <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <h3>Promo</h3>
            </CardHeader>
            <Row md="12">
                <Col md="6">
                    <CardBody>
                        <FormGroup row>
                            <Label
                            className="form-control-label"
                            for="exampleEmail" sm={4}>
                                Nama Promo
                            </Label>
                            <Col sm={7}>
                                <Input
                                    autoComplete="off"    
                                    className="form-control-alternative"
                                    type="text"
                                    name="barname"
                                    placeholder="Masukan Nama Promo"
                                    value={namapromo}
                                    onChange={(e) => {
                                        setNamaPromo(e.target.value);
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                              <Label
                              className="form-control-label"
                              for="exampleEmail" sm={4}>
                                  Cabang
                              </Label>
                              <Col sm={7}>
                                  <Input
                                    autoComplete="off"
                                      className="form-control-alternative"
                                      type="select"
                                      value={warehouse}
                                      onChange={(e) => {
                                          setWarehouse(e.target.value);
                                          getKaryawan(e.target.value);
                                      }}
                                  >
                                    <option value=''>Pilih Cabang</option>
                                    {warehouses.map((warehouse2, key) => {
                                        return (
                                            <option key={key} value={warehouse2.id}>
                                            {warehouse2.name}
                                            </option>
                                        );
                                        })}
                                  </Input>
                              </Col>
                          </FormGroup>
                        <FormGroup row>
                              <Label
                              className="form-control-label"
                              for="exampleEmail" sm={4}>
                                  PIC
                              </Label>
                              <Col sm={7}>
                                  <Input
                                    autoComplete="off"
                                      className="form-control-alternative"
                                      type="select"
                                      value={pic}
                                      onChange={(e) => {
                                          setPic(e.target.value);
                                      }}
                                  >
                                    <option value=''>Pilih PIC</option>
                                    {karyawans.map((warehouse2, key) => {
                                        return (
                                            <option key={key} value={warehouse2.id}>
                                            {warehouse2.name}
                                            </option>
                                        );
                                        })}
                                  </Input>
                              </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                            for="exampleEmail" sm={4}>
                                Keterangan
                            </Label>
                            <Col sm={7}>
                                <Input
                                    autoComplete="off"
                                    className="form-control-alternative"
                                    type="textarea"
                                    rows ="5"
                                    placeholder="Masukan Keterangan"
                                    value={keterangan}
                                    onChange={(e) => {
                                        setKeterangan(e.target.value);
                                    }}
                                />
                            </Col>
                        </FormGroup>
                    </CardBody>
                </Col>
                <Col md="6">
                    <CardBody>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                              for="exampleEmail" sm={4}>
                                  Budget
                              </Label>
                              <Col sm={7}>
                                  {/* <Input
                                    autoComplete="off"
                                      className="form-control-alternative"
                                      type="number"
                                      placeholder="Rp.0"
                                      value={budgetawal}
                                      onChange={(e) => {
                                          setBudgetAwal(e.target.value);
                                      }}
                                  /> */}
                                  <CurrencyInput
                                  id="validation-example-2-field"
                                  placeholder="Rp.0"
                                  allowDecimals={false}
                                  value={budgetawal}
                                  onValueChange={badgetAwal}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>
                          </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                              for="exampleEmail" sm={4}>
                                  Potongan
                              </Label>
                              <Col sm={3}>
                                  <CurrencyInput
                                            id="validation-example-2-field"
                                            placeholder="Rp.0"
                                            allowDecimals={false}
                                            value={potonganpertransaksi}
                                            onValueChange={PromoPerTransaksi}
                                            className={`form-control`}
                                            prefix={'Rp'}
                                            step={10}
                                            />
                              </Col>
                              <Col sm={5}>
                                <Label
                                className="form-control-label"
                                for="exampleEmail" sm={7}>
                                    / Transaksi
                                </Label>
                              </Col>
                          </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                              for="exampleEmail" sm={4}>
                                  Tipe Promo
                              </Label>
                              <Col sm={7}>
                                  <Input
                                    autoComplete="off"
                                      className="form-control-alternative"
                                      name="Supplier"
                                      type="select"
                                      placeholder="Masukan Type"
                                      value={uniontype}
                                      onChange={(e) => {
                                          setUnionType(e.target.value);
                                      }} >
                                    <option value="" disabled selected hidden> Tipe Promo</option>
                                    <option value="1">Gabung</option>
                                    <option value="2">Tidak Gabung</option>
                                  </Input>
                              </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                            for="exampleEmail" sm={4}>
                            Mulai
                            </Label>
                            <Col sm={7}>
                                <Input
                                    autoComplete="off"
                                    className="form-control-alternative"
                                    type="date"
                                    
                                    placeholder="Masukan Tanggal"
                                    value={start}
                                    onChange={(e) => {
                                        setStart(e.target.value);
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label
                            className="form-control-label"
                            for="exampleEmail" sm={4}>
                            Selesai
                            </Label>
                            <Col sm={7}>
                                <Input
                                    autoComplete="off"
                                    className="form-control-alternative"
                                    type="date"
                                    placeholder="Masukan Tanggal"
                                    value={end}
                                    onChange={(e) => {
                                        setEnd(e.target.value);
                                    }}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col
                              for="exampleEmail"
                              sm={4}
                            >
                                <Label
                                className="form-control-label"
                                >
                                    Status
                                </Label>
                            </Col>
                              <Col sm={7}>
                                <div style={{ display: "flex" }}>
                                    <label
                                    className="custom-toggle custom-toggle-primary">
                                        <Input
                                        type="checkbox"
                                        checked={status == 1 ? true : false}
                                        value={status == 1 ? "2" : "1"}
                                        onChange={(e) => {
                                            setStatus(e.target.value );
                                        }}
                                        />
                                        <span
                                        className="custom-toggle-slider rounded-circle"
                                        ></span>
                                    </label>
                                </div>  
                              </Col>
                        </FormGroup>
                    </CardBody>
                </Col>
            </Row>
        </Card>
        {/* Hari */}
        <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3>Promo Hari</h3>
                    <Form onSubmit={allCentanghari}>
                        <div style={{ textAlign: "right" }}>
                            <Button color="primary" className="mb-2" type="submit">Pilih Semua</Button> 
                        </div>
                    </Form>
                </div>
            </CardHeader>
            <CardBody>
            <Table size="sm" responsive>
                    <thead>
                      <tr>
                        <th><center>Senin</center></th>
                        <th><center>Selasa</center></th>
                        <th><center>Rabu</center></th>
                        <th><center>Kamis</center></th>
                        <th><center>Jumat</center></th>
                        <th><center>Sabtu</center></th>
                        <th><center>Minggu</center></th>
                      </tr>
                    </thead>
                    <tbody>
                          <tr>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked={
                                            senin == 1 ? true : false
                                        }
                                        value={senin == 1 ? "2" : "1"}
                                        onChange={(e) => {
                                                setSenin(e.target.value);
                                            }}
                                    />
                                    <Label
                                    className="form-control-label"
                                    check>
                                    </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {selasa == 1 ? true : false}
                                        value={selasa == 1 ? "2" : "1"}
                                        onChange={(e) => setSelasa(e.target.value)}
                                    />
                                        <Label
                                        className="form-control-label"
                                        check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {rabu == 1 ? true : false}
                                        value={rabu == 1 ? "2" : "1"}
                                        onChange={(e) => setRabu(e.target.value)}
                                    />
                                        <Label
                                        className="form-control-label"
                                        check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {kamis == 1 ? true : false}
                                        value={kamis == 1 ? "2" : "1"}
                                        onChange={(e) => setKamis(e.target.value)}
                                    />
                                        <Label
                                        className="form-control-label"
                                        check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {jumat == 1 ? true : false}
                                        value={jumat == 1 ? "2" : "1"}
                                        onChange={(e) => setJumat(e.target.value)}
                                    />
                                        <Label
                                        className="form-control-label"
                                        check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {sabtu == 1 ? true : false}
                                        value={sabtu == 1 ? "2" : "1"}
                                        onChange={(e) => setSabtu(e.target.value)}
                                    />
                                        <Label
                                        className="form-control-label"
                                        check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {minggu == 1 ? true : false}
                                        value={minggu == 1 ? "2" : "1"}
                                        onChange={(e) => setMinggu(e.target.value)}
                                    />
                                        <Label
                                        className="form-control-label"
                                        check>
                                        </Label>
                                </FormGroup>
                            </td>
                          </tr>
                    </tbody>
                  </Table>
            </CardBody>
        </Card>
        {/* Bulan */}
        <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3>Promo Bulan</h3>
                    <Form onSubmit={allCentangBulan}>
                        <div style={{ textAlign: "right" }}>
                            <Button color="primary" className="mb-2" type="submit">Pilih Semua</Button> 
                        </div>
                    </Form>
                </div>
            </CardHeader>
            <CardBody>
            <Table size="sm" responsive>
                    <thead>
                      <tr>
                        <th><center>Januari</center></th>
                        <th><center>Februari</center></th>
                        <th><center>Maret</center></th>
                        <th><center>April</center></th>
                        <th><center>Mei</center></th>
                        <th><center>Juni</center></th>
                        <th><center>Juli</center></th>
                        <th><center>Agustus</center></th>
                        <th><center>September</center></th>
                        <th><center>Oktober</center></th>
                        <th><center>November</center></th>
                        <th><center>Desember</center></th>
                      </tr>
                    </thead>
                    <tbody>
                          <tr>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked={
                                            januari == 1 ? true : false
                                        }
                                        value={januari == 1 ? "2" : "1"}
                                        onChange={(e) => {
                                                setJanuari(e.target.value);
                                            }}
                                    />
                                    <Label
                                    className="form-control-label"
                                    check>
                                    </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {februari == 1 ? true : false}
                                        value={februari == 1 ? "2" : "1"}
                                        onChange={(e) => setFebruari(e.target.value)}
                                    />
                                        <Label
                                        className="form-control-label"
                                        check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {maret == 1 ? true : false}
                                        value={maret == 1 ? "2" : "1"}
                                        onChange={(e) => setMaret(e.target.value)}
                                    />
                                        <Label
                                        className="form-control-label"
                                        check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {april == 1 ? true : false}
                                        value={april == 1 ? "2" : "1"}
                                        onChange={(e) => setApril(e.target.value)}
                                    />
                                        <Label
                                        className="form-control-label"
                                        check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {mei == 1 ? true : false}
                                        value={mei == 1 ? "2" : "1"}
                                        onChange={(e) => setMei(e.target.value)}
                                    />
                                        <Label
                                        className="form-control-label"
                                        check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {juni == 1 ? true : false}
                                        value={juni == 1 ? "2" : "1"}
                                        onChange={(e) => setJuni(e.target.value)}
                                    />
                                        <Label
                                        className="form-control-label"
                                        check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {juli == 1 ? true : false}
                                        value={juli == 1 ? "2" : "1"}
                                        onChange={(e) => setJuli(e.target.value)}
                                    />
                                        <Label
                                        className="form-control-label"
                                        check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {agustus == 1 ? true : false}
                                        value={agustus == 1 ? "2" : "1"}
                                        onChange={(e) => setAgustus(e.target.value)}
                                    />
                                        <Label
                                        className="form-control-label"
                                        check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {september == 1 ? true : false}
                                        value={september == 1 ? "2" : "1"}
                                        onChange={(e) => setSeptember(e.target.value)}
                                    />
                                        <Label
                                        className="form-control-label"
                                        check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {oktober == 1 ? true : false}
                                        value={oktober == 1 ? "2" : "1"}
                                        onChange={(e) => setOktober(e.target.value)}
                                    />
                                        <Label
                                        className="form-control-label"
                                        check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {november == 1 ? true : false}
                                        value={november == 1 ? "2" : "1"}
                                        onChange={(e) => setNovember(e.target.value)}
                                    />
                                        <Label
                                        className="form-control-label"
                                        check>
                                        </Label>
                                </FormGroup>
                            </td>
                            <td align="center">
                                <FormGroup check>
                                    <Input
                                        id="checkbox2"
                                        type="checkbox"
                                        checked = {desember == 1 ? true : false}
                                        value={desember == 1 ? "2" : "1"}
                                        onChange={(e) => setDesember(e.target.value)}
                                    />
                                        <Label
                                        className="form-control-label"
                                        check>
                                        </Label>
                                </FormGroup>
                            </td>
                          </tr>
                    </tbody>
                  </Table>
            </CardBody>
            <CardFooter>
                    <Link className="btn btn-info" to="/admin/promo/promo-budget">
                        Kembali
                    </Link>
                    <Button color="danger" onClick={setQuestionAlert}>
                        Simpan
                    </Button>
                </CardFooter>
        </Card>
      </Container>
    </>
  );
}
