/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card,
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Container,
    Badge,
    Form, 
    FormGroup, 
    Label, 
    Input ,
    DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle,
    Modal, ModalHeader, ModalBody

} from 'reactstrap';

import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ModalCetakSoTerbaru from './ModalCetakSoTerbaru';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from 'views/404';
import Select2 from "react-select2-wrapper";


const HistoriSalesOrder = () => {
  const [alert, setAlert] = useState(null);
  const [persons, setPersons] = useState([]);
  const [tanggal,setTanggal] = useState("")
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle2 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  const redirectPrefix = `/admin/sales-order/histori/detail/`;
  const [allSo, setAllSo] = useState([]);
  const [ordercode, setOrderCode] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("")
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [openModalCetak, setOpenModalCetak] = useState(false);
  const [dataModalCetak, setDataModalCetak] = useState({ id: 0,});
  const toggle = () => setOpenModalCetak(!openModalCetak);
  const allInfo = JSON.parse(localStorage.allinfo);
  const permintaanBarangs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub SO").map((p) => p.read_access));
  const cetak = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Cetak SO").map((p) => p.create_access));
 
  useEffect(() => {
    getPerson();
  }, []);

  const getPerson = () => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/customer/list`, { headers
      })
      .then(data => {
        setPersons(data.data.response);
      })
        .catch(function (error) {
          console.log(error)
        })
    }

  const displayModalCetak = (id) => {
    setDataModalCetak({
      id: id,
    });
    setOpenModalCetak(true);
  };
  
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort,tanggal, status , ordercode,  description);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort,tanggal,status,ordercode,  description) => {
    getSo(page, perPage, sort,tanggal,status, ordercode,  description);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setTanggal(tanggal);
    setStatus(status);
    setOrderCode(ordercode);
    setDescription(description);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort,tanggal,  ordercode,status, description)
    }
  }

  
  useEffect(() => {
    getSo(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getSo = (page, perPage, currentSort,transaction_date =null, status_ph=null,so_code = null,customer_name = null) => {
    
    let filter = { 
        page: page, 
        per_page: perPage, 
        warehouse_id : parseInt(warehouse),
    
    };
    if (transaction_date !== null) {
      filter = Object.assign(filter, { transaction_date: transaction_date })
  }
    if (status_ph !== null) {
      filter = Object.assign(filter, { status_ph: status_ph })
  }
    if (so_code !== null) {
      filter = Object.assign(filter, { so_code: so_code })
    }
    if (customer_name !== null) {
        filter = Object.assign(filter, { customer_name: customer_name })
    }
   
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/sales-order/page`, data, {
        headers,
      })
      .then((data) => {
        setAllSo(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        setAllSo(error.response.data.response);
      });
  }

  const reset = () => {
    setTanggal("")
    setStatus("")
    setOrderCode("");
    setDescription("");
    updateDataTable(1, perPage, currentSort, "", "", "");
  }

  const getDataLog = (id) => {
		let filter = { 
			transaction : id,
			features : "JUAL7", 
			warehouse_id : warehouse
		}
		const data = filter;
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
			setDataLog(data.data.response);
            setModal1(!modal1);

		})
		  .catch(function (error) {
			setGagalAlert(error.response.data.message);
		  })
	  }

	  const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
		);
		};
		
		const hideAlert = () => {
		  setAlert(null);
		};


  return (
    <div>
      {alert}
      {permintaanBarangs && permintaanBarangs === "1" ? (
			<div>
      <SimpleHeader name="Histori" parentName="PO" />
        <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ textAlign: 'left' }}>
                      <Link className="btn btn-link" to="/admin/sales-order">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                      </Link>
                    </div>
                </div>
              </CardHeader>
              <CardBody>
                  <Form>
                  <Row md="12">
                  <Col md="3">
                            <FormGroup>
                              <Label
                                      className="form-control-label"
                                      htmlFor="exampleFormControlSelect3"
                                  >
                                Tanggal 
                              </Label>
                              <Input
                              className="form-control-alternative"
                                type="date"
                                placeholder="Masukan Tanggal"
                                value={tanggal}
                                onChange={(e) =>
                                  updateDataTable(1,perPage,currentSort, e.target.value, status, ordercode , description )
                                }
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                              <FormGroup> 
                            <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect3"
                            >
                                Status
                            </Label>
                            <Input
                                name="account"
                                type="select"
                                value={status}
                                onChange={(e) =>
                                updateDataTable(1, perPage, currentSort,tanggal,e.target.value,ordercode,description)
                                }
                            >
                                <option value={""}>Pilih Status</option>
                                <option value={3}>Proses</option>
                                <option value={4}>Tolak</option>
                                <option value={5}>Setuju</option>
                            </Input>
                              </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect3"
                            >Kode SO</Label>
                              <Input
                              className="form-control-alternative"
                                type="text"
                                placeholder="Masukan Kode SO"
                                value={ordercode}
                                onChange={e => updateDataTable(1, perPage, currentSort,tanggal,status, e.target.value, description)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                             <FormGroup>
                              <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect3"
                            >Customer</Label>
                               <Select2
                                        className="form-control-alternative"
                                        defaultValue="0"
                                        value={description}
                                        onChange={e => updateDataTable(1, perPage, currentSort,tanggal,status,ordercode, e.target.value)}
                                         
                                        options={{
                                          placeholder: "Pilih Customer"
                                        }}
                                        data={persons.map((person,key) => {
                                          return (
                                          { id: person.name, text: person.name, key:key}
                                          );
                                        })}
                                      />
                                      {/* <Select2
                                        className="form-control-alternative"
                                        defaultValue="0"
                                        value={description}
                                        onChange={(e) =>
                                          updateDataTable(1, perPage, currentSort, tanggal,status,ordercode, e.target.value)
                                          }
                                        options={{
                                          placeholder: "Pilih Customer"
                                        }}
                                        data={persons.map((person) => {
                                          return (
                                          { id: person.name, text: person.name}
                                          );
                                        })}
                                      /> */}
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button type="button" onClick={reset} color="secondary">Reset</Button>
                          </Col>
                        </Row>
                      </Form>
                      {allSo === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                  dataField: "",
                                  text: "Tanggal",
                                  sort: true,
                                  },
                                  {
                                    dataField: "",
                                    text: "Kode SO",
                                    sort: true,
                                  },
                                  {
                                      dataField: "",
                                      text: "Customer",
                                      sort: true,
                                  },
                                  {
                                    dataField: "",
                                    text: "Status",
                                    sort: true,
                                },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <h3>DATA NOT FOUND</h3>
                          </CardHeader>
                        </div>
                        ):(
                    <ToolkitProvider
                            rowNumber={rowIndex}
                            data={allSo}
                            keyField="id"
                            columns={[
                            {
                                dataField: "no",
                                text: "#",
                                sort: true,
                                page: 1,
                                formatter: (cell, row, index) => {
                                let currentRow = ++index;
                                return currentRow + rowIndex;
                                },
                            },
                            {
                              dataField: "transaction_date",
                              text: "Tanggal",
                              sort: true,
                            },
                            {
                                dataField: "so_code",
                                text: "Kode SO",
                                sort: true,
                                formatter: (cell, row) => {
                                  return row.so_code === ""
                                    ? <h2>
                                      <Badge color="warning" >
                                        {row.so_code}
                                      </Badge>
                                      </h2>
                                    : 
                                    <h2>
                                    <Badge color="warning" >
                                        {row.so_code}
                                      </Badge>
                                      </h2>
                                },
                            },
                            {
                                dataField: "customer_name",
                                text: "Customer",
                                sort: true,
                            },
                            {
                                dataField: "status_ph",
                                text: "Status",
                                sort: true,
                                formatter: (cell, row) => {
                                  return row.status_ph === 3
                                    ? 
                                      <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-danger" />
                                        Proses
                                      </Badge>
                                    : row.status_ph === 4
                                    ? <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-warning" />
                                        Tolak
                                      </Badge>
                                    : <Badge color="" className="badge-dot mr-4">
                                        <i className="bg-success" />
                                        Setuju
                                      </Badge>;
                                },
                            },
                            {
                              dataField: "",
                              text: "",
                              formatter: (cell, row, index) => {
                                return row.status_ph === 3
                                  ? <UncontrolledDropdown nav>
                                      <DropdownToggle color="danger">
                                          Tindakan
                                        </DropdownToggle>
                                        <DropdownMenu>
                                              <Link  to={redirectPrefix + row.id}
                                                id={"tooltip_" + row.id}>
                                              <DropdownItem>
                                                <i className="fas fa-user-edit" /><span>Detail</span>
                                              </DropdownItem>
                                              </Link>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                  : row.status_ph === 4
                                  ?  <UncontrolledDropdown nav>
                                  <DropdownToggle color="danger">
                                      Tindakan
                                    </DropdownToggle>
                                    <DropdownMenu>
                                          <Link to={redirectPrefix + row.id}
                                            id={"tooltip_" + row.id}>
                                          <DropdownItem>
                                            <i className="fas fa-user-edit" /><span>Detail</span>
                                          </DropdownItem>
                                          </Link>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                  : <UncontrolledDropdown nav>
                                  <DropdownToggle color="danger">
                                      Tindakan
                                    </DropdownToggle>
                                    <DropdownMenu>
                                          <DropdownItem onClick={() => getDataLog(row.id)}>
                                          <i className="fa fa-info-circle" aria-hidden="true"></i><span>Info</span>
                                          </DropdownItem>
                                          <Link to={redirectPrefix + row.id} id={"tooltip_" + row.id}>
                                            <DropdownItem>
                                                <i className="fas fa-user-edit" /><span>Detail</span>
                                            </DropdownItem>
                                          </Link>
                                          {cetak && cetak === "1" && (
                                          <DropdownItem onClick={() => displayModalCetak(row.id)}>
                                            <i className="fas fa-print" /><span>Cetak</span>
                                          </DropdownItem>
                                          )}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                              },
                            },
                            ]}
                        >
                            {(props) => (
                            <div className="py-4 table-responsive">
                                <BootstrapTable
                                remote
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                                hover={true}
                                pagination={paginationFactory({ ...paginationOption })}
                                onTableChange={handleTableChange}
                                />
                            </div>
                            )}
                    </ToolkitProvider>
                        )}
              </CardBody>
            </Card>
          </div>
        </Row>
        {openModalCetak && (
        <ModalCetakSoTerbaru
          open={openModalCetak}
          data={dataModalCetak}
          toggle={toggle}
        />
      )}
      </Container>
      <Modal isOpen={modal1} toggle={toggle2} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle2} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle2}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>
      </div>
      ):(
        <Halaman404 />
      )}
    </div>
  );
}

export default HistoriSalesOrder;
