import React, {useRef} from "react";
import GoogleMapReact from 'google-map-react';
import SearchLocation from "./searchLocation";

const Maps = ({
  defaultCenter,
  defaultZoom,
  onClickMap,
  apiIsLoaded,
  height,
  width,
  long,
  lat,
  address,
  isSearch,
  setItem,
  setLong,
  setLat,
  isLoopMaker,
  absensi
}) => {

  const mapRef = useRef(null);  // Menggunakan useRef untuk menyimpan referensi

  const AnyReactComponent = ({ type }) => (
    <div style={{
      padding: '5px 5px',
      display: 'inline-flex',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '100%',
      transform: 'translate(-50%, -50%)',
      width: 40, height: 40
    }}>
      {
        type === "in" ? 
          <img src="https://i.ibb.co/XYf5GGc/masuk.png" width="40px" alt="Masuk" /> :
        type === "out" ?
          <img src="https://i.ibb.co/XYf5GGc/pulang.png" width="40px" alt="Pulang" /> :
          <img src="https://i.ibb.co/0JhYD5r/cabang.png" width={30} height={30} alt="Cabang" />
      }
    </div>
  );

  return (
    <div>
      {
        isSearch ?
          <SearchLocation
            address={address}
            setItem={setItem}
            setLong={setLong}
            setLat={setLat}
          /> : null
      }
      <div style={{ height: height || '50vh', width: width || '100%' }}>
        <GoogleMapReact
          rref={mapRef}
          bootstrapURLKeys={{ key: process.env.REACT_APP_MAP, libraries: ['places', 'geometry', 'drawing', 'visualization'] }}
          center={defaultCenter}
          zoom={defaultZoom}
          yesIWantToUseGoogleMapApiInternals={true}
          layerTypes={['TransitLayer']}
          onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, defaultCenter)}
          onClick={(e) => onClickMap(e)}
          options={{ scrollwheel: false}}
          draggable={false}
        >
          {
            isLoopMaker ?
              <div>
                {
                  absensi.absensiCheckOutPage.length > 0 &&
                  absensi.absensiCheckOutPage.map((v, i) => (
                    <AnyReactComponent
                      lng={v.longitude_plg}
                      lat={v.latitude_plg}
                      center={{ lat: v.latitude_plg, lng: v.longitude_plg }}
                      radius={500}
                      type="out"
                    />
                  ))
                }
                <div>
                  {
                    absensi.absensiCheckInPage.map((v, i) => (
                      <AnyReactComponent
                        lng={v.longitude_dtg}
                        lat={v.latitude_dtg}
                        center={{ lat: v.latitude_dtg, lng: v.longitude_dtg }}
                        radius={500}
                        type={"in"}
                      />
                    ))
                  }
                </div>
              </div>
              :
              <AnyReactComponent
                lng={long}
                lat={lat}
                center={defaultCenter}
                zoom={defaultZoom}
                radius={500}
              />
          }

        </GoogleMapReact>
      </div>
    </div>
  )
}

export default Maps;