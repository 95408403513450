/*eslint-disable*/
import React, { useEffect, useState } from "react";
  import { Card, Button, Row, Col, CardBody, CardHeader, CardFooter, Table, Container, FormFeedback, Form, FormGroup, Label, Input} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";
import CurrencyInput from 'react-currency-input-field';

const CreatePoRetur = () => {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [persons, setPersons] = useState([]);
  const [person, setPerson] = useState("");
  const [codeinvoice,setCodeInvoice ] = useState("");
  const [payment_method,setPaymentMethod] = useState(0);
  const [keterangan, setKeterangan] = useState("");
  const [allJangkaWaktu, setAllJangkaWaktu] = useState([]);
  const [jangkaWaktu,setJangkaWaktu] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [savedItems, setSavedItems] = useState([]);
  const [ongkir, setOngkir] = useState(0);
  const [allpajak, setAllPajak] = useState([]);
  const [pajak, setPajak] = useState(1);
  const [ppn, setPPN] = useState(0);
  const [totaldiskon, setTotalDiskon] = useState(0);
  const [grandtotal, setGrandTotal] = useState(0);
  const [diskonglobalnominal, setDiskonGlobalNominal] = useState(0);
  const [diskonglobalpersen, setDiskonGlobalPersen] = useState(0);
    const [ongkir1, setOngkir1] = useState(0);
    const [ppnnew, setPPNNEW] = useState(0);
    const [diskonglobal, setDiskonGLobal] = useState(0);
    const [totalPrice1, setTotalPrice1] = useState(0);
    const a = 0;
	const b = 0;
  const c = 0;
  const [dp, setDp] = useState(0);
  const [dp1, setDp1] = useState(0);
    const [diskonglobalnominal1,setDiskonGlobalNominal1] = useState(0)
    const [totaldiskon2, setTotalDiskon2] = useState(0);
    const [totaldiskon3, setTotalDiskon3] = useState(0);
    const [editable, setEditable] = useState(false);
    const [qtyTotal, setTotalQty] = useState(0);
    const [diskontotal, setDiskonTotal] = useState(0);
    const [isSearchShow, setIsSearchShow] = useState(false);
    const headers = { Authorization: `Bearer ${token}`};
    const [allItem, setAllItem] = useState([]);
    const [query, setQuery] = useState("");
    const [qtyError, setQtyError] = useState(null);
    const [banksupplier, setBankSupplier] = useState("")
    const [banksuppliers, setBankSuppliers] = useState([])
    const allInfo = JSON.parse(localStorage.allinfo);
    const returPOs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Retur PO").map((p) => p.read_access));
    
    const validateForm = () => {
        let error = false;
        savedItems.map((savedItem, key) => { 
          if (savedItem.qty > savedItem.qtysisa) {
            setQtyError("invalid");
            error = true;
          }
        })
        return error;
    };

    const handleQtySisa = (e) => {
      e.preventDefault();
      if (!validateForm()) {
          setQuestionAlert()
      }
    }

    const searchShow = (item) => {
      AmbilDataCok(item.invoice_code);
      setIsSearchShow(false);
    };
  
    useEffect(() => {
      const getData = setTimeout(async() => {
        if(query != ""){
          axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/invoice-po/page`, {  
            page: 1,
            per_page: 10,
            status_af:5,
            status_d:5,
            invoice_code: query,
            warehouse_id: parseInt(warehouse),
            active_flag : 2
          },{ headers })
          .then((res) => {
            setAllItem(res.data);
            setIsSearchShow(true);
            
          })
          .catch(function (error) {
            setGagalAlert(error.response.data.message);
          });
        }}, 1000)
        return () => clearTimeout(getData)
    }, [query]);
    
  const AmbilDataCok = (id) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/invoice-po/get-by-code/${id}`,
          { headers }
        )
        .then((data) => {
          getItemDataSaved(data.data.response.invoice_code);
          getPerson(data.data.response.person_id);
          getbankSupplier(data.data.response.person_id);
          setBankSupplier(data.data.response.account_number);
          setCodeInvoice(data.data.response.invoice_code);
          setDp(data.data.response.um_nominal)
          getPajak(data.data.response.pajak_id);
          setOngkir(data.data.response.ongkir);
          setTotalQty(data.data.response.price_real);
          setDiskonTotal(data.data.response.diskon_total);
          // setKeterangan(data.data.response.keterangan);
          setTotalPrice(data.data.response.price_real);
          getJangkaWaktu(data.data.response.jangka_waktu);
          setPaymentMethod(data.data.response.payment_method);
          setDiskonGlobalNominal(data.data.response.diskon_global_nominal);
          setDiskonGlobalPersen(data.data.response.diskon_global_persen);
          
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const getItemDataSaved = (id) => {
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/invoice-po/item-by-code`, {
  
          code_invoice: id
  
      }).then(async response => {
          let stateItem = [];
          await Promise.all(response.data.response.map(async (data) => {
              stateItem = [...stateItem, {
                  item_id: data.item_id,
                  item_name:data.item_name,
                  item_code:data.item_code,
                  satuan : data.satuan,
                  qtysisa: data.max_qty,
                  qty: data.qty,
                  harga: data.harga,
                  diskon_persen: data.diskon_persen,
                  diskon_nominal: data.diskon_nominal,
                  data: {
                    item_name: data.item_name,
                    harga: data.harga,
                    qtysisa: data.max_qty,
                    qty: data.qty,
                    diskon_persen: data.diskon_persen,
                    diskon_nominal: data.diskon_nominal,
                },
            }];
            
        }));
        setSavedItems(stateItem);
    })
  }


  // useEffect(() => {
  //   setDiskonGlobalNominal1(diskonglobalnominal);
  // }, [diskonglobalnominal]);
  
  //   // diskon bayangan
  //   useEffect(() => {
  //   setTotalDiskon(diskonglobalnominal1);
  // }, [diskonglobalnominal1]);
  
  // useEffect(() => {
  //   setTotalDiskon3(diskontotal-a);
  // }, [diskontotal, a]);
  
  //   //diskon tampil
  //   useEffect(() => {
  //   setTotalDiskon2(totaldiskon - a);
  // }, [totaldiskon3, totaldiskon, a]);
  
  // //ongkir tampil
  // useEffect(() => {
  //   setOngkir1(ongkir - b);
  // }, [ongkir, b]);
  
  
  //   useEffect(() => {
  //   setTotalPrice1(qtyTotal);
  // }, [qtyTotal]);
  
  
  //   // diskonglobalnominal dan persen
  //   useEffect(() => {
  //     diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
  //     diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
  //   }, [diskonglobalnominal,diskonglobal, qtyTotal, diskonglobalpersen,diskontotal,diskonglobalpersen]);
  
  //   // hasil nominal dari PPN
  //   useEffect(() => {
  //       setPPNNEW( (qtyTotal - totaldiskon) * (ppn / 100));
  //  }, [qtyTotal,totaldiskon,ppn]);
  
  //   // hasil grandtotal
  // useEffect(() => {
  //   setGrandTotal(( qtyTotal - totaldiskon2) + ppnnew + ongkir1- a );
  // }, [qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);

  // useEffect(() => {
  //   setDiskonGlobalNominal1(diskonglobalnominal);
  // }, [diskonglobalnominal]);

  //   // diskon bayangan
  //   useEffect(() => {
  //   setTotalDiskon(diskonglobalnominal1);
  // }, [diskonglobalnominal1]);

  // useEffect(() => {
  //   setTotalDiskon3(diskontotal-a);
  // }, [diskontotal, a]);

  //   //diskon tampil
  //   useEffect(() => {
  //   setTotalDiskon2(totaldiskon - a);
  // }, [totaldiskon3, totaldiskon, a]);

  // //ongkir tampil
  // useEffect(() => {
  //   setOngkir1(ongkir - b);
  // }, [ongkir, b]);


  //   useEffect(() => {
  //   setTotalPrice1(qtyTotal - ppnnew);
  // }, [qtyTotal, ppnnew]);


  //   // diskonglobalnominal dan persen
  //   useEffect(() => {
  //     diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
  //     diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
  //   }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);

  //   // hasil nominal dari PPN
  //   // hasil nominal dari PPN
  //   useEffect(() => {
  //     setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
  // }, [qtyTotal,totaldiskon,ppn]);
  

  //   // hasil grandtotal
  // useEffect(() => {
  //   setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew + ongkir1- a );
  // }, [totalPrice1,qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);

  useEffect(() => {
    setDiskonGlobalNominal1(diskonglobalnominal);
  }, [diskonglobalnominal]);
  
    // diskon bayangan
    useEffect(() => {
    setTotalDiskon(diskonglobalnominal1);
  }, [diskonglobalnominal1]);
  
  useEffect(() => {
    setTotalDiskon3(diskontotal-a);
  }, [diskontotal, a]);
  
    //diskon tampil
    useEffect(() => {
    setTotalDiskon2((totaldiskon)/((ppn/100)+1));
  }, [totaldiskon3, totaldiskon, ppn , a]);
  
  //ongkir tampil
  useEffect(() => {
    setOngkir1(ongkir - b);
  }, [ongkir, b]);
  
    useEffect(() => {
    setTotalPrice1((qtyTotal)/((ppn/100)+1));
  }, [qtyTotal,ppn]);
  
  useEffect(() => {
    setDp1(dp - c);
  }, [dp, c]);

    // diskonglobalnominal dan persen
    useEffect(() => {
      diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
      diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
    }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);
  
    // hasil nominal dari PPN
    useEffect(() => {
      setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
    }, [qtyTotal,totaldiskon,ppn]);
  
    // hasil grandtotal
  useEffect(() => {
    setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew - dp1 + ongkir1- a );
  }, [totalPrice1,qtyTotal, totaldiskon2, ppnnew,dp1, ongkir1, a]);

	useEffect(() => {
        getById();
	}, [pajak]);

	const getById = () => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/pajak/${pajak}`,
	        { headers }
	      )
	      .then((data) => {;
	        setPPN(data.data.response.persentase);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

    useEffect(() => {
      calculateTotalQty();
    }, [savedItems]);
    
    const calculateTotalQty = () => {
      const newTotalQty = savedItems.reduce((total, item) => {
        const itemTotal = (parseInt(item.harga) - parseInt(item.diskon_nominal)) * parseInt(item.qty);
        return total + itemTotal;
      }, 0);
      setTotalQty(newTotalQty);
    };
    
    
      const handleEditQty = (index, value) => {
        if (!isNaN(value) && value.length > 0) {
          let updateList = [...savedItems];
          updateList[index] = { ...updateList[index], qty: value };
          setSavedItems(updateList);
        } else {
          return false;
        }
      }
    
    const handleEditHarga = (index, value) => {
      if (!isNaN(value) && value.length > 0) {
        let updateList = [...savedItems];
        updateList[index] = { ...updateList[index], harga: value };
        setSavedItems(updateList);
      } else {
        return false;
      }
    }
    
    const handleEditDiskonNominal = (index, value) => {
      if (!isNaN(value) && value.length > 0) {
        let updateList = [...savedItems];
        let persentasediskonnya = (value / parseInt(updateList[index].harga)) * 100;
        updateList[index] = { ...updateList[index], diskon_nominal: value, diskon_persen: persentasediskonnya };
        setSavedItems(updateList);
      } else {
        return false;
      }
    }
    
    const handleEditDiskonPersen = (index, value) => {
      if (!isNaN(value) && value.length > 0) {
        let updateList = [...savedItems];
        let nominaldiskonnya = (parseInt(updateList[index].harga) * value) / 100;
        updateList[index] = { ...updateList[index], diskon_nominal: nominaldiskonnya, diskon_persen: value };
        setSavedItems(updateList);
      } else {
        return false;
      }
    }
    
    const handleEditNote = (index, value) => {
      let updateList = [...savedItems];
      if (value) {
        updateList[index] = { ...updateList[index], note: value };
        setSavedItems(updateList);
      } else {
        return false;
      }
    }

    // const handleEditQty = (index, value) => {
    //   let updateList = savedItems;
    //   let minTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
    //   let plusTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * value ;
    //   let aqtyTotala = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal))* value ;
    //   let aqtyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
    //   let diskonnominal1 = parseInt(updateList[index].diskon_nominal) * value ;
    //   let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
      
    //   if(!isNaN(value)&&value.length>0){
    //     updateList[index] = {...updateList[index], qty: value};
    //     setSavedItems(updateList);
    //     setTotalPrice(totalPrice+plusTotal-minTotal);
    //     setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
    //     // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
    //   }else{return false}
    // }
    
    // const handleEditHarga = (index, value) => {
    //   if(!isNaN(value)&&value.length>0){
    //     let updateList = savedItems;
    //     let minTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
    //     let aqtyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal))* parseInt(updateList[index].qty)  ;
    //     updateList[index] = {...updateList[index], harga: value};
    //     setSavedItems(updateList);
    //     let plusTotal = parseInt(updateList[index].qty) * (value - parseInt(updateList[index].diskon_nominal));
    //     setTotalPrice(totalPrice + plusTotal - minTotal);
    //     let aqtyTotala = parseInt(updateList[index].qty) * (value  - parseInt(updateList[index].diskon_nominal));
    //     setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
    //   }else{return false}
    // }
    
    // const handleEditDiskonNominal = (index, value) => {
    //   if(!isNaN(value)&&value.length>0){
    //     let updateList = savedItems;
    //     let diskonnominal1 = parseInt(updateList[index].qty) * value ;
    //     let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
    //     let persentasediskonnya = value / parseInt(updateList[index].harga) * 100;
    //     let aqtyTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
    //     let minTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
    //     updateList[index] = {...updateList[index], diskon_nominal: value, diskon_persen: persentasediskonnya};
    //     setSavedItems(updateList);
    //     let plusTotal = parseInt(updateList[index].qty) * (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal));
    //     setTotalPrice(totalPrice+plusTotal-minTotal);
    //     let aqtyTotala = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty );
    //     setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
    //     // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
    //   }else{return false}
    // }
    
    // const handleEditDiskonPersen = (index, value) => {
    //   if(!isNaN(value)&&value.length>0){
    //     let updateList = savedItems;
    //     let  nominaldiskonnya = parseInt(updateList[index].harga) * value/100;
    //     let minTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
    //     let aqtyTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
    //     updateList[index] = {...updateList[index], diskon_nominal: nominaldiskonnya, diskon_persen: value};
    //     setSavedItems(updateList);
    //     let plusTotal = (parseInt(updateList[index].qty)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].harga) ;
    //     setTotalPrice(totalPrice+plusTotal-minTotal);
    //     let qatyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty)  ;
    //     setTotalQty(qtyTotal + qatyTotal - aqtyTotal);
    //     let diskonnominal1 = parseInt(updateList[index].qty) * value ;
    //     let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
    //     // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2 )
    //   }else{return false}
    // }


  const getPerson = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/person/list`,
        { headers }
      )
      .then((data) => {
        setPersons(data.data.response);
        setPerson(id)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getbankSupplier = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-person/${id}`, { headers })
      .then((data) => {
        setBankSuppliers(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getJangkaWaktu = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/jwkredit/list`, { headers })
      .then((data) => {
        setAllJangkaWaktu(data.data.response);
        setJangkaWaktu(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  const getPajak = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
      .then((data) => {
        setAllPajak(data.data.response);
        setPajak(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit2 = () => {
    hideAlert();
    setTimeout(() => {CreateData()}, 1000);
  };

   function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga: dataItem.harga,
                diskon_nominal:dataItem.diskon_nominal,
                diskon_persen:dataItem.diskon_persen,
                note : dataItem.note,
            }]);
    let data = {
      warehouse_id : parseInt(warehouse),
      username : username,
      code_invoice: codeinvoice,
      account_number : banksupplier,
      pajak_id : parseInt(pajak),
      diskon_global_nominal : parseFloat(diskonglobalnominal),
      diskon_global_persen : parseInt(diskonglobalpersen),
      payment_method: parseInt(payment_method),
      jangka_waktu: parseInt(jangkaWaktu),
      status_ar: 3,
      um_nominal: parseFloat(dp),
      status_d : 3,
      person_id: parseInt(person),
      ongkir:parseFloat(ongkir),
      keterangan: keterangan ,
      items : dataItems,
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/po-retur/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/po-retur/page")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={handleSubmit2}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };



const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}


  const deleteItem = (id) => {
    let array = [...savedItems];

    let index = array.findIndex(object => {
      return object.item_id === id;
    });

    if (index !== -1) {
      setTotalPrice(totalPrice - (array[index].harga - array[index].diskon_nominal) * array[index].qty)  ;
      setTotalQty(qtyTotal - (array[index].harga - array[index].diskon_nominal) * array[index].qty) ;
      setDiskonTotal(diskontotal - array[index].diskon_nominal * array[index].qty);
      array.splice(index, 1);
      setSavedItems(array);
    }
  }

  const HargaDiskonPersen = (e) => {
    const value = e === undefined ? 'undefined' : e;
    setDiskonGlobalPersen(value || ' ');
    setDiskonGLobal("diskonglobalpersen")
    };
  
    const HargaDiskonNominal = (e) => {
      const value = e === undefined ? 'undefined' : e;
      setDiskonGlobalNominal(value || ' ');
      setDiskonGLobal("diskonglobalnominal")
      };
      
      const HargaOngkir = (e) => {
        const value = e === undefined ? 'undefined' : e;
        setOngkir(value || ' ');
        };


  return (
    <>
    {alert}
    {returPOs && returPOs === "1" ? (
			<div>
    <SimpleHeader name="Buat Retur Pembelian" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <Card className="position-sticky bg-secondary shadow" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
											<Col md="4">
											</Col>
											<Col md="4">
												<FormGroup row>
													<Col sm={10}>
														<Input
															className="form-control-alternative"
															placeholder="Search Kode Faktur"
															type="search"
															value={query}
															onChange={(e) => setQuery(e.target.value)}
														/>
													</Col>
												</FormGroup>
											</Col>
                      <Col md="4">
                      </Col>
										</Row>
									</CardBody>
								</Card>
									{isSearchShow && query && (
										<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
											<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
												<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
											</div>
											<span className="text-center mb-3">
												<b>Pencarian berdasarkan:</b> {query}
											</span>
											{allItem?.response ? (
												allItem.response.map((item) => (
													<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" style={{minHeight: 'auto'}} onClick={() => {searchShow(item); setQuery(''); setIsSearchShow(false);}}>
														 <div className=" d-flex align-items-center">
															<div className="ml-3">
																<div>
																	<b>Kode :</b> {item.invoice_code}
																</div>
															</div>
														</div>
													</CardBody>
												))
											) : (
												<div className="text-center mb-3 text-danger">Item "{query}" tidak ada bosku!</div>
											)}
										</Card>
									)} 
                <Card>
                  <CardBody>
                    <Row md="12">
                        <Col md="6">
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Supplier
                            </Label>
                            <Col sm={6}>
                              <Select2
                                    disabled
                                    className="form-control-alternative"
                                    defaultValue=""
                                    value={person}
                                    onChange={(e) => {
                                      setPerson(e.target.value);
                                    }}
                                    options={{
                                      placeholder: "Pilih Supplier"
                                    }}
                                    data={persons.map((person, key) => {
                                      return (
                                      { id: person.id, text: person.person_name, key:key}
                                      );
                                    })}
                                  />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                Bank
                              </Label>
                              <Col sm={6}>
                                <Input
                                  disabled
                                  className="form-control-alternative"
                                  type="select"
                                  value={banksupplier}
                                  onChange={(e) => {
                                    setBankSupplier(e.target.value);
                                  }}>
                                  <option value="" disabled selected hidden>Pilih Bank</option>
                                    {banksuppliers.map((supplier, key) => {
                                      return (
                                          <option key={key} value={supplier.account_number}>
                                            {supplier.bank_name} {supplier.account_number} a/n {supplier.account_name} 
                                          </option>
                                      );
                                    })}
                                  </Input>
                              </Col>
													  </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                Diskon
                              </Label>
                              <Col sm={3}>
                                <CurrencyInput
                                  disabled
                                  id="validation-example-2-field"
                                  placeholder="0"
                                  allowDecimals={false}
                                  value={diskonglobalpersen}
                                  onValueChange={HargaDiskonPersen}
                                  className={`form-control`}
                                  suffix={'%'}
                                  step={10}
                                />
                              </Col>
                              <Col sm={3}>
                              <CurrencyInput
                                  disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={diskonglobalnominal}
                                  onValueChange={HargaDiskonNominal}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                  PPN
                              </Label>
                              <Col sm={6}>
                                  <Input
                                      disabled
                                      className="form-control-alternative"
                                      type="select"
                                      value={pajak}
                                      onChange={(e) => {
                                          setPajak(e.target.value);
                                  }}>
                              <option value='' disabled selected hidden>Pilih PPN</option>
                                {allpajak.map((warehouse2, key) => {
                                  return (
                                      <option key={key} value={warehouse2.id}>
                                        {warehouse2.keterangan}
                                      </option>
                                  );
                                })}
                              </Input>
                          </Col>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                          >
                            Metode Pembayaran
                          </Label>
                          <Col sm={6}>
                          <Input
                          disabled
                          className="form-control-alternative"
                              name="Tipe Po"
                              type="select"
                              value={payment_method}
                              onChange={(e) => {
                                  setPaymentMethod(e.target.value);
                              }}
                            >
                              <option value={""}>Pilih Metode Pembayaran</option>
                              <option value={1}>Lunas</option>
                              <option value={2}>Tempo</option>
                              <option value={5}>Dp/Kredit</option>
                            </Input>
                          </Col>
                          </FormGroup>
                          {payment_method >  1  ? (
                              <FormGroup row>
                                <Label
                                className="form-control-label"
                                  for="exampleEmail"
                                  sm={3}
                                >
                                  Jangka Waktu
                                </Label>
                                <Col sm={4}>
                                  <Input
                                  disabled
                                    className="form-control-alternative"
                                    type="select"
                                    name="desc"
                                    value={jangkaWaktu}
                                    onChange={(e) => {
                                      setJangkaWaktu(e.target.value);
                                    }}
                                  >
                                  <option value="" disabled selected hidden>Pilih Jangka Waktu</option>
                                  {allJangkaWaktu.map((waktu, key) => {
                                    return (
                                      <option key={key} value={waktu.durasi}>
                                        {waktu.durasi}
                                      </option>
                                    );
                                  })}
                                </Input>
                                </Col> 
                                <Label
                                className="form-control-label" for="exampleEmail" sm={3}>
                                  <b>Hari</b>
                                </Label>
                              </FormGroup>
                            ):(
                              ""
                            )}
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Ongkir
                            </Label>
                            <Col sm={6}>
                            <CurrencyInput
                                    disabled
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={ongkir}
                                    onValueChange={HargaOngkir}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                  />
                            </Col>                             
                          </FormGroup>
                          {/* {payment_method === 5 ? 
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Uang Muka
                              </Label>
                              <Col sm={6}>
                              <CurrencyInput
                              disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={dp}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>                             
                            </FormGroup>
                            : 
                            ""
                            } */}
                        </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Keterangan/Alasan</h3>
                    </CardHeader>
                <CardBody>
                  <Row md="12">
                    <Col md="12">
                      <FormGroup row>
                        <Label
                        className="form-control-label"
                          for="exampleEmail"
                          sm={3}
                        >
                          Keterangan
                        </Label>
                        <Col sm={6}>
                          <Input
                          className="form-control-alternative"
                            type="textarea"
                            name="desc"
                            rows = "4"
                            placeholder="Masukan Keterangan"
                            value={keterangan}
                            onChange={(e) => {
                              setKeterangan(e.target.value);
                            }}
                          />
                        </Col>                             
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                <Form onSubmit={handleQtySisa}>
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                        <CardBody>
                        <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Harga&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th><b>Qty&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Diskon %</b></th>
                                <th><b>Diskon(N)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th><b>Sub Total</b></th>
                                <th><b>Note&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td>
                                      
                                      {editable ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon Persen"
                                            type="number"
                                            value={savedItem.harga}
                                            onChange={(e) => {
                                              handleEditHarga(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{formatRupiah(savedItem.harga)}</>
                                        )}
                                      
                                      </td>
                                      <td>
                                      <Input
                                          className="form-control-alternative"
                                          placeholder="qty"
                                          type="number"
                                          invalid={qtyError === "invalid"}
                                          value={savedItem.qty}
                                          onChange={(e) => {
                                            // setDiskonGLobal("diskonglobalnominal")
                                            setDiskonGLobal("diskonglobalpersen")
                                            handleEditQty(key, e.target.value);
                                            if (savedItem.qty > savedItem.qtysisa) {
                                              setQtyError("");
                                            }
                                          }}/>
                                           <FormFeedback>
                                                Max Barang {savedItem.qtysisa} 
                                            </FormFeedback>
                                      </td>
                                      <td>{savedItem.satuan}</td>
                                      <td>
                                        {editable ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon Persen"
                                            type="number"
                                            value={savedItem.diskon_persen}
                                            onChange={(e) => {
                                              handleEditDiskonPersen(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{savedItem.diskon_persen}</>
                                        )}
                                      </td>
                                      <td>
                                        {editable ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon nominal"
                                            type="number"
                                            value={savedItem.diskon_nominal}
                                            onChange={(e) => {
                                              handleEditDiskonNominal(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{formatRupiah(savedItem.diskon_nominal)}</>
                                        )}
                                      </td>
                                      <td>
                                      {/* {formatRupiah((((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) - ((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) * (ppn/100)))} */}
                                      {formatRupiah(((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) / (ppn/100 + 100/100))}

                                        {/* {formatRupiah((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty)} */}
                                      </td>
                                      <td>
                                      <Input
                                          className="form-control-alternative"
                                          placeholder="Note"
                                          type="text  "
                                          value={savedItem.note}
                                          onChange={(e) => {
                                            handleEditNote(key, e.target.value);
                                          }}/>
                                      </td> 
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter >
                          <Row md="12">
                            <Col md="6">
                            </Col>
                            <Col md="2">
                            </Col>
                            <Col md="4">
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                                size="small"
                              >
                                <b>Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Harga Total"
                                // value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                value={formatRupiah(totalPrice1)}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Diskon</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                style={{fontWeight: 'bold'}}
                                disabled
                                type="text"
                                name="barcode"
                                placeholder="Diskon"
                                value={formatRupiah(totaldiskon2)}
                                
                                // value={"Rp." + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>PPN</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={formatRupiah(ppnnew)}
                                // value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Ongkir</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={formatRupiah(ongkir1)}

                                // value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              {/* <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Uang Muka</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Uang Muka"
                                value={"Rp." + dp1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup> */}
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Grand Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                type="text"
                                name="barcode"
                                style={{fontWeight: 'bold'}}
                                placeholder="Grand Total"
                                value={formatRupiah(grandtotal)}

                                // value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>

                            </Col>
                          </Row>
                        </CardFooter>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/po-retur/page">
                        Kembali
                      </Link>
                      <Button color="danger" type="submit">
                          Simpan
                      </Button>
                </CardFooter>
                </Form>
              </Card>
          </div>
        </Row>
    </Container>  
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}

export default CreatePoRetur;

