/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
	Col,
	Button,
	Container,
	Form,
	InputGroup,
    InputGroupText,
	Modal, ModalHeader, ModalBody

} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";

export default function Userss() {
	const token = localStorage.token;
	const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [username, setUsername] = useState("")
	const [useraccess,setUserAccess] = useState("")
	const [rowIndex, setRowIndex] = useState(0);
	const toggle1 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
	const toggle2 = () => setModal2(!modal2);
	const [modal2, setModal2] = useState(false);
	const [totalPages, setTotalPages] = useState(1);
	const [page, setPage] = useState(1);
    const [kodeuser, setKodeUser] = useState("");
    const [nama,setNama] = useState("");
    const [address, setAddress] = useState("");
	const [provinces, setProvinces] = useState([]);
	const [province, setProvince] = useState("");
	const [cities, setCities] = useState([]);
	const [city, setCity] = useState("");
    const [phone, setPhone] = useState("");
	const [limithutang,setLimitHutang] = useState(0);
	const [limitpiutang, setLimitPiutang] = useState(0)
	const [pricetype, setPriceType] = useState("")
	const [bankname1,setBankName1] = useState("");
	const [bankname2,setBankName2] = useState("");
	const [bankaccount1,setBankAccount1] = useState("");
	const [bankaccount2,setBankAccount2] = useState("");
	const [banknumber1,setBankNumber1] = useState("");
	const [banknumber2,setBankNumber2] = useState("");
    const [itemId, setItemId] = useState(1);
	const [email, setEmail] = useState("")
    const [allItem, setAllItem] = useState([]);
    const [query, setQuery] = useState(null);
    const [isSearchShow, setIsSearchShow] = useState(false);
    const headers = { Authorization: `Bearer ${token}` };
	const redirectPrefix = `/admin/customer/edit/`;
	const allInfo = JSON.parse(localStorage.allinfo);
    const customer = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Customer").map((p) => p.read_access));
    const CreateButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Customer").map((p) => p.create_access));
    const UpdateButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Customer").map((p) => p.update_access));

	useEffect(() => {
		getItems();
	}, [page]);

	//get list item berdasarkan warehouse
	const getItems = async () => {
		let filter = {
			page : page,
			per_page: 1
		};
		const data = filter;
		const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/customer`, data, { headers });
		if(res.data.response.id !== null){
			getById(res.data.response[0].id);
			setItemId(res.data.response[0].id);
			setTotalPages(res.data.total_item);
		}
	};

	useEffect(() => {
		const getData = setTimeout(async() => {
			if(query != ''){
				axios
				.post(`${process.env.REACT_APP_API_BASE_URL}/customer`, { name: query , per_page: 10},{ headers })
				.then((res) => {
					setAllItem(res.data);
					setIsSearchShow(true);	
				})
				.catch(function (error) {
					setIsSearchShow(true);	
					setAllItem(error.response.data.response);

				});
			}
		  }, 1000)
		  return () => clearTimeout(getData)
	}, [query]);

	  const getById = (id) => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/customer/get/${id}`,
	        { headers }
	      )
	      .then((data) => {
			getProvince(data.data.response.province);
			setCity(data.data.response.city);
            setKodeUser(data.data.response.customer_code);
            setNama(data.data.response.name);
			setUserAccess(data.data.response.ecommerce_access)
			setEmail(data.data.response.customer_email)
            setAddress(data.data.response.address);
            setPhone(data.data.response.phone);
			setPriceType(data.data.response.price_type);
			setLimitHutang(data.data.response.limit_hutang);
            setLimitPiutang(data.data.response.limit_piutang);
            setBankName1(data.data.response.bank_name1);
            setBankName2(data.data.response.bank_name2);
            setBankAccount1(data.data.response.bank_account1);
            setBankAccount2(data.data.response.bank_account2);
            setBankNumber1(data.data.response.bank_number1);
            setBankNumber2(data.data.response.bank_number2);

	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };
	
	  const getProvince = (id) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/province/list`, { headers
		})
		.then(data => {
			setProvinces(data.data.response_data)
			getCity(id);
			setProvince(id);
		})
		  .catch(function (error) {
			console.log(error)
		  })
	  }
	
	  const getCity = (id) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		  axios.get(`${process.env.REACT_APP_API_BASE_URL}/city?province_id=${id}`, { headers
		})
		.then(data => {
			setCities(data.data.response_data);
			// setCity(id);
		})
		  .catch(function (error) {
			console.log(error)
		  })
	  }


	const searchShow = (item) => {
		getById(item.id);
		setItemId(item.id);
		setPage(item.id);
		setIsSearchShow(false);
	};

	const getDataLog = (id) => {
		let filter = { 
			transaction : id,
			features : "MASTER8",
			warehouse_id : 0
		}
		const data = filter;
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
			setDataLog(data.data.response);
			setModal1(!modal1);
	
		})
		  .catch(function (error) {
			setGagalAlert(error.response.data.message);
		  })
	  }

	  const setSuccessAlert = (id) => {
        setAlert(
          <SweetAlert
            success
            showConfirm
            confirmBtnText="Ok"
			timeout={2000}
            title={id}
            onCancel={hideAlert}
            onConfirm={hideAlert}
          />
        )
      }
	
	  const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		timeout={2000}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
		);
		};
		
		const hideAlert = () => {
		  setAlert(null);
		};

		function CreateEcommUser() {
			let data = {
			  customer_code: kodeuser,
			  username: username,
			  }
			   axios.post(`${process.env.REACT_APP_API_BASE_URL}/customer/ecommerce-access`, data, {
			  headers: {
				Authorization: `Bearer ${token}`,
			  }
			  })
			  .then(function (data) {
				setModal2(false);
				setUsername("");
				getItems()
				setSuccessAlert(data.data.message);  
			})
			  .catch(function (error) {
				// if(error.response.data.status == 400){
				setModal2(false);
				setUsername("");
				getItems()
				setGagalAlert(error.response.data.message);
				// }  
			});
		  };

	return (
		<>
		{alert}
		 {customer && customer === "1" ? (
			<div>
				<SimpleHeader name="Customer" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
							<Form>
								<Card className="position-sticky boxShadow" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
											<Col md="5" className="d-lg-block d-none">
												<Button onClick={() => setPage(1)} disabled={page === 1} color="danger" type="button">
													First
												</Button>
												<Button onClick={() => setPage((page) => page - 1)} disabled={page === 1} color="success" type="button">
												<i className="fa fa-angle-left" /> Prev
												</Button>
												<Button onClick={() => setPage((page) => page + 1)} disabled={page >= totalPages} color="success" type="button">
													Next <i className="fa fa-angle-right" />
												</Button>
												<Button onClick={() => setPage(totalPages)} disabled={page == totalPages} color="warning" type="button">
													End
												</Button>
											</Col>
											<Col md="3">
												<FormGroup row>
													<Col sm={10}>
														<Input
															placeholder="Search Customer"
															type="search"
															value={query}
															onChange={(e) => setQuery(e.target.value)}
														/>
													</Col>
												</FormGroup>
											</Col>
											<Col md="4" className="d-lg-block d-none">
												<div style={{ textAlign: "right" }}>
												{UpdateButton && UpdateButton === "1" && (
													<Link className="btn btn-info" to={redirectPrefix + itemId}
													>
														<i className="fas fa-user-edit" /> Edit
													</Link>
												)}
													{CreateButton && CreateButton === "1" && (
													<Link className="btn btn-danger" to="/admin/customer/create">
														<i className="fas fa-plus" /> Tambah
													</Link>
													)}
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
								{isSearchShow && query && (
									<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
										<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
											<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
										</div>
										<span className="text-center mb-3">
											<b>Pencarian berdasarkan:</b> {query}
										</span>
										{allItem?.response ? (
											allItem.response.map((item) => (
												<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" style={{minHeight: 'auto'}} onClick={() => {searchShow(item); setQuery('')}}>
													<div>
														<b>Nama :</b> {item.name}
													</div>
													<div>
														<b>Kode Customer:</b> {item.customer_code ? item.customer_code : "(Not available)"}
													</div>
												</CardBody>
											))
										) : (
											<div className="text-center mb-3 text-danger">Customer "{query}" tidak ada bosku!</div>
										)}
									</Card>
								)}
              <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
				  <div
											style={{ display: "flex", justifyContent: "space-between" }}
										>
											<h3>Customer</h3>
											<div style={{ textAlign: "rigth" }}>
												<Button color="warning" onClick={() => (getDataLog(itemId))}>
												<i className="fa fa-info-circle" aria-hidden="true"></i>
												</Button>
											</div>
										</div>
                  </CardHeader>
                  <Row md="12">
					<Col md="6">
                      <CardBody>
							<FormGroup row>
								<Label
								className="form-control-label"
								for="exampleEmail" sm={4}>
									Kode Customer
								</Label>
								<Col sm={7}>
									<Input
										disabled
										className="form-control-alternative"
										type="text"
										name="itemCode"
										placeholder="Kode Customer"
										value={kodeuser}
										onChange={(e) => {
											setKodeUser(e.target.value);
										}}
									/>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label
								className="form-control-label"
								for="exampleEmail" sm={4}>
									Nama
								</Label>
								<Col sm={7}>
									<Input
									disabled
										className="form-control-alternative"
										type="text"
										name="barcode"
										placeholder="Masukan Nama"
										value={nama}
										onChange={(e) => {
											setNama(e.target.value);
										}}
									/>
								</Col>
							</FormGroup>
						  	<FormGroup row>
								<Label
								className="form-control-label"
								for="exampleEmail" sm={4}
							>
								Provinsi
								</Label>
								<Col sm={7}>
								<Input
									disabled
									autoComplete="off"
									name="Province"
									type="select"
									value={province}
									onChange={(e) => {
									setProvince(e.target.value);
									getCity(e.target.value);
									}}
								>
									<option defaultValue="1">Pilih Province</option>
									{
									provinces.map((prov, key) => {
										return <option key={key} value={prov.id}>{prov.name}</option>
									})
									}
								</Input>
								</Col>
							</FormGroup>
							<FormGroup row>
							<Label
								className="form-control-label"
								for="exampleEmail" sm={4}
							>
								Kota
							</Label>
							<Col sm={7}>
							<Input
							disabled
							autoComplete="off"
								name="Kota"
								type="select"
								value={city}
								onChange={(e) => {
								setCity(e.target.value);
								}}
							>
								<option defaultValue="1">Pilih Kota</option>
								{
								cities.map((city, key) => {
									return <option key={key} value={city.id}>{city.name}</option>
								})
								}
							</Input>
							</Col>
							</FormGroup>
							<FormGroup row>
                              <Label
							 className="form-control-label" 
							  for="exampleEmail" sm={4}>
                                  Alamat
                              </Label>
                              <Col sm={7}>
                                  <Input
								  disabled
                                      className="form-control-alternative"
                                      name="Supplier"
                                      type="textarea"
									  rows = "5"
                                      placeholder="Masukan alamat"
                                      value={address}
                                      onChange={(e) => {
                                        setAddress(e.target.value);
                                      }}
                                  />
                              </Col>
                          	</FormGroup>
                      </CardBody>
					</Col>
					<Col md="6">
                      <CardBody>
                      		
                          	<FormGroup row>
                              <Label
							 className="form-control-label" 
							  for="exampleEmail" sm={4}>
                                  Kontak
                              </Label>
                              <Col sm={7}>
                                  <Input
								  disabled
                                      className="form-control-alternative"
                                      type="text"
                                      placeholder="Masukan Kontak"
                                      value={phone}
                                      onChange={(e) => {
                                          setPhone(e.target.value);
                                      }}
                                  />
                              </Col>
                          	</FormGroup> 
							<FormGroup row>
                              <Label
							 className="form-control-label" 
							  for="exampleEmail" sm={4}>
                                  Email
                              </Label>
                              <Col sm={7}>
                                  <Input
								  disabled
                                      className="form-control-alternative"
                                      name="Supplier"
                                      type="text"
									//   rows = "5"
                                      placeholder="Contoh@gmail.com"
                                      value={email}
                                      onChange={(e) => {
                                        setEmail(e.target.value);
                                      }}
                                  />
                              </Col>
                          	</FormGroup>
						  	<FormGroup row>
								<Label
									className="form-control-label"
									sm={4}
								>
									Tipe Harga
									{/* <span className="text-danger">*</span> */}
								</Label>
								<Col sm={7}>
								<Input
								disabled
									autoComplete="off"
									type="select"
									// placeholder="Masukan Nomor Telpon"
									value={pricetype}
									onChange={(e) => {
									setPriceType(e.target.value)
									}}
								>
									<option defaultValue="1" selected hidden>Pilih Tipe Harga</option>
									<option value="1">Level 1</option>
									<option value="2">Level 2</option>
									<option value="3">Level 3</option>
									<option value="4">Level 4</option>
									<option value="5">Level 5</option>
								</Input>
								</Col>
							</FormGroup>
							<FormGroup row>
                              <Label
							 className="form-control-label" 
							  for="exampleEmail" sm={4}>
                                  Limit Hutang
                              </Label>
                              <Col sm={7}>
							  <InputGroup>
								<InputGroupText>
								Rp
								</InputGroupText>
                                  <Input
								  disabled
                                      className="form-control-alternative"
                                      type="text"
                                      placeholder="Rp.0"
                                      value={limithutang}
                                      onChange={(e) => {
                                          setLimitHutang(e.target.value);
                                      }}
                                  />
								  </InputGroup>
                              </Col>
                          	</FormGroup> 
							  <FormGroup row>
                              <Label
								className="form-control-label" 
							  for="exampleEmail" sm={4}>
                                  Limit Piutang
                              </Label>
                              <Col sm={7}>
							  <InputGroup>
								<InputGroupText>
								Rp
								</InputGroupText>
                                  <Input
								  disabled
                                      className="form-control-alternative"
                                      type="text"
                                      placeholder="Rp.0"
                                      value={limitpiutang}
                                      onChange={(e) => {
                                          setLimitPiutang(e.target.value);
                                      }}
                                  />
								</InputGroup>
                              </Col>
                          	</FormGroup>        
							<FormGroup row>
								<Label
								className="form-control-label" 
								for="exampleEmail" sm={4}>
									Akses Ecommerce
								</Label>
								{useraccess !== "" ? 
								<Col sm={7}>
								<Input
								disabled
									className="form-control-alternative"
									type="text"
									placeholder="Username"
									value={useraccess}
									onChange={(e) => {
										setUserAccess(e.target.value);
									}}
								/>
							</Col>
							:
<Col sm={4}>
									<Button color="danger" onClick={() => setModal2(true)} >Aktifkan</Button>
								</Col>
							}
								
								
							</FormGroup>        
                      </CardBody>
					</Col>
				</Row>
                </Card>
				<Card className="bg-secondary shadow">
					<CardHeader className="bg-white border-0">
						<h3>Bank</h3>
					</CardHeader>
					<Row md="12">
						<Col md="6">
							<CardBody>
								<FormGroup row>
									<Label
									className="form-control-label"
									for="exampleEmail" sm={4}>
										Nama Bank
									</Label>
									<Col sm={7}>
										<Input
										disabled
										autoComplete="off"
											className="form-control-alternative"
											type="text"
											placeholder="Masukan Nama Bank"
											value={bankname1}
											onChange={(e) => {
												setBankName1(e.target.value);
											}}
										/>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label
									className="form-control-label"
									for="exampleEmail" sm={4}>
										Nama Rekening
									</Label>
									<Col sm={7}>
										<Input
										disabled
											autoComplete="off"
											className="form-control-alternative"
											type="text"
											placeholder="Masukan Atas Nama Rekening"
											value={bankaccount1}
											onChange={(e) => {
												setBankAccount1(e.target.value);
											}}
										/>
									</Col>
								</FormGroup>
							</CardBody>
						</Col>
						<Col md="6">
							<CardBody>
								<FormGroup row>
									<Label
									className="form-control-label"
									for="exampleEmail" sm={4}>
										Nomor Rekening
									</Label>
									<Col sm={7}>
										<Input
										disabled
										autoComplete="off"
											className="form-control-alternative"
											type="text"
										
											placeholder="Masukan Nomor Rekening"
											value={banknumber1}
											onChange={(e) => {
												setBankNumber1(e.target.value);
											}}
										/>
									</Col>
								</FormGroup>
							</CardBody>
						</Col>
					</Row>
					<Row md="12">
						<Col md="6">
							<CardBody>
								<FormGroup row>
									<Label
									className="form-control-label"
									for="exampleEmail" sm={4}>
										Nama Bank
									</Label>
									<Col sm={7}>
										<Input
										disabled
										autoComplete="off"
											className="form-control-alternative"
											type="text"
											placeholder="Masukan Nama Bank"
											value={bankname2}
											onChange={(e) => {
												setBankName2(e.target.value);
											}}
										/>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label
									className="form-control-label"
									for="exampleEmail" sm={4}>
										Nama Rekening
									</Label>
									<Col sm={7}>
										<Input
										disabled
											autoComplete="off"
											className="form-control-alternative"
											type="text"
											placeholder="Masukan Akun Bank"
											value={bankaccount2}
											onChange={(e) => {
												setBankAccount2(e.target.value);
											}}
										/>
									</Col>
								</FormGroup>
							</CardBody>
						</Col>
						<Col md="6">
							<CardBody>
								<FormGroup row>
									<Label
									className="form-control-label"
									for="exampleEmail" sm={4}>
										Nomor Rekening
									</Label>
									<Col sm={7}>
										<Input
										disabled
										autoComplete="off"
											className="form-control-alternative"
											type="text"
										
											placeholder="Masukan Nomor Rekening"
											value={banknumber2}
											onChange={(e) => {
												setBankNumber2(e.target.value);
											}}
										/>
									</Col>
								</FormGroup>
							</CardBody>
						</Col>
					</Row>
				</Card>
							</Form>
						</div>
					</Row>
				</Container>
				<Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>
            Kembali
          </Button>
        </ModalBody>
      </Modal> 
	  <Modal isOpen={modal2} toggle={toggle2} fade={false} style={{ minWidth: "30%", top: "10%" }}>
				<ModalHeader toggle={toggle2}>Masukan Username</ModalHeader>
				<ModalBody
					cssModule={{
						alignText: "center",
					}}>
						<Row className="justify-content-center mt-4">
								<Col xs={7}>
									<Input
									readOnly
										autoComplete="off"
										placeholder="Nama"
										type="text"
										value={nama}
									/>
								</Col>
						</Row>
						<Row className="justify-content-center mt-4">
								<Col xs={7}>
									<Input
										autoComplete="off"
										placeholder="Masukan Username"
										type="text"
										value={username}
										onChange={(e) => {
											setUsername(e.target.value);
										}}
									/>
								</Col>
							</Row>
							<Row className="justify-content-center mt-4">
								{/* <Col xs={7}> */}
									<Button color="primary" onClick={()=>(CreateEcommUser())}>Simpan</Button>
								{/* </Col> */}
							</Row>
				</ModalBody>
			</Modal>
			</div>
		 ):(
			<Halaman404 />
		 )}
		</>
	);
}
