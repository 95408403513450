/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Card, Row, CardBody, CardHeader, Container } from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider,{ Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";
import filterFactory from 'react-bootstrap-table2-filter';
import DetailAccount from 'views/pages/account/Detail';


const AccountFinance = () => {
  const { SearchBar } = Search;
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  const redirectPrefix = `/admin/account/edit/`;
  const [alert, setAlert] = React.useState(null);
  const [rowIndex, setRowIndex] = useState(0);
  const [allAccount, setAllAccount] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [warehouseid, setWarehouseId] = useState("");
  const [warehouseids, setWarehouseIds] = useState([]);
  const [selectedSalesTracking, setSelectedTracking] = useState(0);

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, warehouseid);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort, warehouseid)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, warehouseid) => {
    getAccount(page, perPage,sort, warehouseid);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setWarehouseId(warehouseid)
  }

  const handleTableChange = (type, { sortField, sortOrder, warehouseid }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, warehouseid)
    }
  }

  useEffect(() => {
    getAccount(page, perPage, currentSort);
    // getAccounts();
    getPusat();
  }, []);

const getPusat = () => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`, { headers
    })
    .then(data => {
      setWarehouseIds(data.data.response);
    })
      .catch(function (error) {
        console.log(error)
      })
  }
  
  const expandRow = {
    parentClassName: 'expanding-foo',
    onlyOneExpanding: true,
    renderer: (row, rowIndex) => (
      setSelectedTracking(row.id),
    <div>
      {/* <p>{ `This Expand row is belong to rowKey ${row.id}` }</p>
      <p>You can render anything here, also you can add additional data on every row object</p>
      <p>expandRow.renderer callback will pass the origin row object to you</p> */}
      <DetailAccount code={selectedSalesTracking} />
    </div>
    
      
      // <Detail code={selectedSalesTracking} />
   
  ),
  // showExpandColumn: true,
  // expandColumnPosition: 'left'
};



  const getAccount = async (page, perPage, currentSort, warehouse_id = null )  => {
    let filter = { 
      page: page, 
      per_page: perPage,
      warehouse_id: parseInt(warehouse)
    }
    // if (warehouse_id !== null) {
    //   filter = Object.assign(filter, { warehouse_id: warehouse_id })
    // }
    const data = filter;
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/account/page`, data, { headers
    })
    .then(data => {
        setAllAccount(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
    })
      .catch(function (error) {
        console.log(error)
      })
  }

  // const delateAddress = (id) => {
  //   axios.post(`${process.env.REACT_APP_API_BASE_URL}/account/delete/${id}`, null, {

  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     }
  //   })
  //     .then(function (response) {
  //       setSuccessAlert();
  //       getAccount();
  //     })
  //     .catch(function (error) {
  //       console.log(error)
  //     })
  // }

  // const setSuccessAlert = () => {
  //   setAlert(
  //     <SweetAlert
  //       success
  //       showConfirm
  //       confirmBtnText="Ok"
  //       title="Account deleted"
  //       onCancel={hideAlert}
  //       onConfirm={hideAlert}
  //     />
  //   )
  // }

  // const setQuestionAlert = (id) => {
  //   setAlert(
  //     <SweetAlert
  //       warning
  //       showCancel
  //       confirmBtnText="Yes"
  //       confirmBtnBsStyle="danger"
  //       title="Are you sure?"
  //       onConfirm={() => delateAddress(id)}
  //       onCancel={hideAlert}
  //       focusCancelBtn
  //     />
  //   )
  // }

  // const hideAlert = () => {
  //   setAlert(null);
  // }

  // const rowEvents = {
  //   onDoubleClick: (e, row, rowIndex) => {
  //     setHide(false);
  //     setSelectedAcquirerId(row.acquirer_id);
  //     setSelectAcquirerName(row.acquirer_name);
  //   }
  // };

  // const onColumnMatch =()=>{({
  //   searchText,
  //   value,
  //   column,
  //   row
  // })}
  
  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }

  return (
    <div>
      {alert}
      <SimpleHeader name="Akun Cabang" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3>List Akun Cabang</h3>
                  <div style={{ textAlign: "right" }}>
                    <Link className="btn btn-danger" to="/admin/account/create">
                      <i className="fas fa-plus" /> Tambah
                    </Link>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <ToolkitProvider
                  rowNumber={rowIndex}
                  data={allAccount}
                  keyField="id"
                  columns={[
                    {
                      dataField: "no",
                      text: "#",
                      sort: true,
                      page: 1,
                      formatter: (cell, row, index) => {
                        let currentRow = ++index;
                        return currentRow + rowIndex;
                      }
                    },
                    {
                      dataField: "account_code",
                      text: "Kode Account",
                      sort: true,
                    },
                    {
                      dataField: "account_name",
                      text: "Nama Account",
                      sort: true,
                    },
                    {
                      dataField: "saldo_total",
                      text: "Debet",
                      sort: true,
                      formatter: (value) => formatRupiah(value)
                    },
                    {
                      dataField: "kredit_total",
                      text: "Kredit",
                      sort: true,
                      formatter: (value) => formatRupiah(value)
                    },
                  ]}
                >
                  {(props) => (
                    <div id="datatable-basic_filter" className="py-4 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1 float-right"
                    >
                      <label>
                        {/* <SearchBar
                          className="form-control-sm"
                          placeholder=""
                          {...props.searchProps}
                        /> */}
                      </label>
                    </div>
                      <BootstrapTable
                        remote
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        hover={true}
                        expandRow={ expandRow }
                        // rowEvents={rowEvents}
                        pagination={paginationFactory({ ...paginationOption })}
                        onTableChange={handleTableChange}
                        filter={ filterFactory() }
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default AccountFinance;
