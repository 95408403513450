/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  FormGroup,
  Label,
  Input
  
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

export default function EditPermintaanBarang(props) {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
    const warehouse = localStorage.warehouse;
    const username = localStorage.username;
    let history = useHistory();
    const [rfq_code,setRfqCode] = useState("");
    const [approve, setApprove] = useState([]);
    const [typereq,setTypeReq] = useState([]);
    const [tanggal, setTanggal] = useState("");
    const [keterangan,setKeterangan] = useState("");
    const [usernamecreaterfq,setUsernameCreateRfq] = useState("");
    const [savedItems, setSavedItems] = useState([]);
    const allInfo = JSON.parse(localStorage.allinfo);
    const permintaanBarangs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Rfq PO").map((p) => p.read_access));
    
    useEffect(() => {
      getById();
    }, []);
  
    const getById = () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/rfq-po/get/${props.match.params.id}`,
          { headers }
        )
        .then((data) => {
          getItemDataSaved();
          setRfqCode(data.data.response.rfq_code);
          setUsernameCreateRfq(data.data.response.username);
          setTypeReq(data.data.response.type);
          setTanggal(data.data.response.created_at);
          setApprove(data.data.response.status_rfq);
          setKeterangan(data.data.response.keterangan);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const getItemDataSaved = () => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/rfq-po/item`, {

            rfq_id: props.match.params.id

        }).then(async response => {
            let stateItem = [];

            await Promise.all(response.data.response.map(async (data) => {
                stateItem = [...stateItem, {
                    item_id: data.item_id,
                    item_name:data.item_name,
                    item_code:data.item_code,
                    qty: data.qty,
                    satuan : data.satuan,
                    note : data.note,
                    data: {
                        item_name: data.item_name,
                        qty: data.qty
                    },
                }];
            }));

            setSavedItems(stateItem);
        })
    }
    
    const handleSubmit1 = () => {
      hideAlert();
      setTimeout(() => {EditData()}, 1000);
  };

    function EditData() {
      let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                satuan: dataItem.satuan,
                note: dataItem.note,
            }]);
        let data = {
        warehouse_id : parseInt(warehouse),
        username : usernamecreaterfq,
        type : parseInt(typereq),
        keterangan: keterangan ,
        status_rfq : parseInt(approve),
        admin_po: username,
        items : dataItems,
        };
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/rfq-po/update/${props.match.params.id}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/permintaan-barang/validasi-kepala-purchasing")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={handleSubmit1}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };

  return (
    <>
    {alert}
    {permintaanBarangs && permintaanBarangs === "1" ? (
			<div>
    <SimpleHeader name="Validasi Kepala Purchasing" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
        <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Validasi Kepala Purchasing</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode RFQ
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="text"
                                  name="desc"
                                  placeholder="Masukan RfqCode"
                                  value={rfq_code}
                                  onChange={(e) => {
                                    setRfqCode(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Tanggal
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="text"
                                  name="desc"
                                  placeholder="Masukan RfqCode"
                                  value={tanggal}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Tipe PO
                            </Label>
                            <Col sm={6}>
                            <Input
                            disabled
                            className="form-control-alternative"
                                name="Tipe Request"
                                type="select"
                                value={typereq}
                                onChange={(e) => {
                                    setTypeReq(e.target.value);
                                }}
                              >
                                <option value="" disabled hidden>Pilih Request</option>
                                <option value={1}>Customer Request</option>
                                <option value={2}>Stok Request</option>
                              </Input>
                            </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Keterangan
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                  type="textarea"
                                  name="desc"
                                  rows = "5"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                            <Col
                              for="exampleEmail"
                              sm={3}
                            >
                              <Label
                              className="form-control-label">
                                Validasi
                              </Label>
                            </Col>
                              <Col sm={6}>
                                <div style={{ display: "flex" }}>
                                  <div className="custom-control custom-radio mb-3">
                                    <Input
                                      className="custom-control-input"
                                      id="customRadio8"
                                      name="custom-radio-3"
                                      type="radio"
                                      value={5}
                                      checked={approve === 5}
                                      onChange={() => setApprove(5)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      for="customRadio8"
                                    >
                                    </Label>
                                    <b>SETUJU</b>

                                  </div>
                                  <div
                                    className="custom-control custom-radio mb-3"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <Input
                                      className="custom-control-input"
                                      id="customRadio9"
                                      name="custom-radio-3"
                                      type="radio"
                                      value={4}
                                      checked={approve === 4}
                                      onChange={() => setApprove(4)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      for="customRadio9"
                                    >
                                    </Label>
                                    <b>TOLAK</b>
                                    

                                  </div>
                                </div>
                              </Col>
                          </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                    <CardBody>
                      <Table size="sm" responsive>
                              <thead>
                                <tr>
                                  <th><b>Nama Item</b></th>
                                  <th><b>Kode Item</b></th>
                                  <th><b>Qty</b></th>
                                  <th><b>Satuan</b></th>
                                  <th><b>Note</b></th>
                                </tr>
                              </thead>
                              <tbody>
                                    {
                                        savedItems.map((savedItem, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{savedItem.item_name}</td>
                                                    <td>{savedItem.item_code}</td>
                                                    <td>{savedItem.qty}</td>
                                                    <td>{savedItem.satuan}</td>
                                                    <td>{savedItem.note}</td>
                                                </tr>
                                            )
                                        })
                                    }
                              </tbody>
                      </Table>
                    </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/permintaan-barang/validasi-kepala-purchasing">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={()=>setQuestionAlert()}>
                          Simpan
                      </Button>
                </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>  
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}
