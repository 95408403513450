/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
    CardFooter,
	Col,
	Button,
	Table,
	Container,
	Form,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment"
import SweetAlert from "react-bootstrap-sweetalert";
import CurrencyInput from 'react-currency-input-field';


export default function EditPromoGlobal(props) {
  const token = localStorage.token;
  const username = localStorage.username;
  let history = useHistory();
  const [namapromo,setNamaPromo] = useState("");
  const [keterangan,setKeterangan] = useState("");
  const [uniontype,setUnionType] = useState("");
  const [senin,setSenin] = useState(2);
  const [selasa,setSelasa] = useState(2);
  const [rabu,setRabu] = useState(2);
  const [kamis,setKamis] = useState(2);
  const [jumat,setJumat] = useState(2);
  const [sabtu,setSabtu] = useState(2);
  const [minggu,setMinggu] = useState(2);
  const [januari,setJanuari] = useState(2);
  const [februari,setFebruari] = useState(2);
  const [maret,setMaret] = useState(2);
  const [april,setApril] = useState(2);
  const [mei,setMei] = useState(2);
  const [juni,setJuni] = useState(2);
  const [juli,setJuli] = useState(2);
  const [agustus,setAgustus] = useState(2);
  const [september,setSeptember] = useState(2);
  const [oktober,setOktober] = useState(2);
  const [november,setNovember] = useState(2);
  const [desember,setDesember] = useState(2);
  const [start,setStart] = useState("");
  const [end,setEnd] = useState("");
  const [status,setStatus] = useState(2);
  const [savedItems1,setSavedItems1] = useState([]);
  const [isSearchShowww,setIsSearchShowww] = useState(false);
  const [allItemmm,setAllItemmm] = useState([]);
  const [queryyy,setQueryyy] = useState("");
  const [qtyTotal1,setTotalQty1] = useState(0);
  const [budgetawal,setBudgetAwal] = useState(0);
  const [promoglobalpersen,setPromoGlobalPersen]=useState(0)
  const [promoglobalnominal,setPromoGlobalNominal]=useState(0)
  const [category,setCategory] = useState("")
    const [subCategory,setSubCategory] = useState("");
    const [nameFunction,setNameFunction] = useState("")
    const [nameSubFunction,setNameSubFunction] = useState("");
    const [merek,setMerek] = useState("");
    const [submerek,setSubMerek] = useState("");
    const [alert,setAlert] = React.useState(null);
    const [codepromo, setCodePromo] = useState("");

    useEffect(() => {
        getById();
      }, []);

	  const getById = () => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/promo/get/${props.match.params.id}`,
	        { headers }
	      )
	      .then((data) => {
            setCategory(data.data.response.kategori);
            setSubCategory(data.data.response.sub_kategori);
            setNameFunction(data.data.response.function);
            setNameSubFunction(data.data.response.sub_function);
            setMerek(data.data.response.merek);
            setSubMerek(data.data.response.sub_merek);
            setBudgetAwal(data.data.response.budget_awal);
            setCodePromo(data.data.response.promo_code);
            setNamaPromo(data.data.response.promo_name);
            setPromoGlobalPersen(data.data.response.promo_global_persen)
            setPromoGlobalNominal(data.data.response.promo_global_nominal)
            setKeterangan(data.data.response.keterangan)
            setUnionType(data.data.response.union_type)
            setSenin(data.data.response.senin)
            setSelasa(data.data.response.selasa)
            setRabu(data.data.response.rabu)
            setKamis(data.data.response.kamis)
            setJumat(data.data.response.jumat)
            setSabtu(data.data.response.sabtu)
            setMinggu(data.data.response.minggu)
            setJanuari(data.data.response.januari)
            setFebruari(data.data.response.februari)
            setMaret(data.data.response.maret)
            setApril(data.data.response.april)
            setMei(data.data.response.mei)
            setJuni(data.data.response.juni)
            setJuli(data.data.response.juli)
            setAgustus(data.data.response.agustus)
            setSeptember(data.data.response.september)
            setOktober(data.data.response.oktober)
            setNovember(data.data.response.november)
            setDesember(data.data.response.desember)
            setStart(moment(data.data.response.start_date, "YYYY/MM/DD").format("YYYY-MM-DD"));
            setEnd(moment(data.data.response.end_date, "YYYY/MM/DD").format("YYYY-MM-DD"));
            setStatus(data.data.response.active_flag)
            getItemDataSaved()
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

      const getItemDataSaved = () => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/promo/cabang`, {
    
            promo_id: props.match.params.id
    
        }).then(async response => {
            let stateItem = [];
            await Promise.all(response.data.response.map(async (data) => {
              stateItem = [...stateItem, {
                editing:false,
                warehouse_id: data.warehouse_id,
                warehouse_code: data.warehouse_code,
                warehouse_name : data.warehouse_name,
                data: {
                    warehouse_id: data.warehouse_id,
                    warehouse_code: data.warehouse_code,
                    warehouse_name : data.warehouse_name,
                },
            }];
        }));
        setSavedItems1(stateItem);
    })
    }

    function CreateData() {
        let dataCabangs = [];
            savedItems1.map((dataCabang) => dataCabangs = [...dataCabangs, 
                { 
                    warehouse_id: dataCabang.warehouse_id,
                    warehouse_code: dataCabang.warehouse_code
    
                }]);
        let data = {
            username: username,
            promo_name: namapromo,
            budget_awal : budgetawal,
            promo_global_persen: parseFloat(promoglobalpersen),
            promo_global_nominal: parseFloat(promoglobalnominal),
            kategori_id: parseInt(category),
            subkategori_id:parseInt(subCategory),
            fungsi_id:parseInt(nameFunction),
            subfungsi_id:parseInt(nameSubFunction),
            merek_id:parseInt(merek),
            submerek_id:parseInt(submerek),
            keterangan: keterangan,
            union_type: parseInt(uniontype),
            senin: parseInt(senin),
            selasa:parseInt(selasa),
            rabu:parseInt(rabu),
            kamis:parseInt(kamis),
            jumat:parseInt(jumat),
            sabtu:parseInt(sabtu),
            minggu:parseInt(minggu),
            januari: parseInt(januari),
            februari: parseInt(februari),
            maret: parseInt(maret),
            april: parseInt(april),
            mei:parseInt(mei),
            juni:parseInt(juni),
            juli:parseInt(juli),
            agustus:parseInt(agustus),
            september:parseInt(september),
            oktober:parseInt(oktober),
            november:parseInt(november),
            desember:parseInt(desember),
            start_date:moment(start).format("YYYY-MM-DD 00:00:00"),
            end_date: moment(end).format("YYYY-MM-DD 00:00:00"),
            active_flag:parseInt(status),
            warehouse : dataCabangs,
        };
        axios
            .post(
              `${process.env.REACT_APP_API_BASE_URL}/promo/update/${props.match.params.id}`,
              data,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(function (response) {
                setSuccessAlert(response.data.message);
                setTimeout(() => (history.push("/admin/promo/promo-global")), 2000);
                })
                .catch(function (error) {
                  setGagalAlert(error.response.data.message);
                });
          };
        
          const setSuccessAlert = (id) => {
            setAlert(
              <SweetAlert
                success
                showConfirm
                confirmBtnText="Ok"
                title={id}
                onCancel={hideAlert}
                onConfirm={hideAlert}
              />
            )
          }
        
        const setGagalAlert = (id) => {
        setAlert(
          <SweetAlert
          danger
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
          />
        );
        };
        
        const setQuestionAlert = () => {
        setAlert(
          <SweetAlert
          warning
          showCancel
          confirmBtnText="Lanjutkan"
          confirmBtnBsStyle="danger"
          title="Apakah Kamu Yakin?"
          onConfirm={CreateData}
          onCancel={hideAlert}
          focusCancelBtn
          />
        );
        };
        
        const hideAlert = () => {
            setAlert(null);
          }

          useEffect(() => {
            const getData = setTimeout(async() => {
                if(queryyy != ''){
                    axios
                    .post(`${process.env.REACT_APP_API_BASE_URL}/warehouse/all`, { warehouse_name: queryyy , per_page: 10,page: 1, level : 3},{ headers: {
                        Authorization: `Bearer ${token}`,
                      },})
                    .then((res) => {
                        setAllItemmm(res.data);
                        setIsSearchShowww(true);
                    })
                    .catch(function (error) {

                    });
                }else{
              setAllItemmm(null);
                }
              }, 500)
              return () => clearTimeout(getData)
          }, [queryyy]);
          
    
          // save
          const saveItemOrUpdate1 = (item) => {
            let oldobj = savedItems1.find(o => o.warehouse_id === item.id);
            if(oldobj===undefined){
              setSavedItems1([...savedItems1,{
                warehouse_id: item.id,
                warehouse_code: item.warehouse_code,
                warehouse_name: item.warehouse_name,
              }]);
            }else{
              let index = savedItems1.findIndex(e => e.warehouse_id === item.id);
              let updateList = savedItems1;
              let qtyupdate = parseInt(updateList[index].qty)+parseInt(1);
              updateList[index] = {...updateList[index], qty: qtyupdate};
              setSavedItems1(updateList);
            };
          }
    
          const handleSubmit1 = async (e) => {
            e.preventDefault();
            {
              setQueryyy("");
              setIsSearchShowww("");
              return true
            }
          };

          const deleteItem1 = (id) => {
            let array = [...savedItems1];
        
            let index = array.findIndex(object => {
              return object.warehouse_id === id;
            });
        
            if (index !== -1) {
              setTotalQty1(qtyTotal1 - array[index].qty);
              array.splice(index, 1);
              setSavedItems1(array);
            }
          }

          const getAllCabangTerbaru = () => {
            axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/toko-aktif`, {headers: {
                Authorization: `Bearer ${token}`,
              },
            }).then(async response => {
                let stateItem = [];
                await Promise.all(response.data.response.map(async (data) => {
                  stateItem = [...stateItem, {
                    editing:false,
                    warehouse_id: data.warehouse_id,
                    warehouse_code: data.warehouse_code,
                    warehouse_name : data.warehouse_name,
                    data: {
                        warehouse_id: data.warehouse_id,
                        warehouse_code: data.warehouse_code,
                        warehouse_name : data.warehouse_name,
                    },
                }];
            }));
            setSavedItems1(stateItem);
        })
        }

          const allCentanghari = async (e) => {
            e.preventDefault();
            {
              setSenin("1");
              setSelasa("1");
              setRabu("1");
              setKamis("1");
              setJumat("1");
              setSabtu("1");
              setMinggu("1");
              return true
            }
          };
    
          const allCentangBulan = async (e) => {
            e.preventDefault();
            {
              setJanuari("1");
              setFebruari("1");
              setMaret("1");
              setApril("1");
              setMei("1");
              setJuni("1");
              setJuli("1");
              setAgustus("1");
              setSeptember("1");
              setOktober("1");
              setNovember("1");
              setDesember("1");
              return true
            }
          };

          const getAllCabang = async (e) => {
            e.preventDefault();
            {
              getAllCabangTerbaru();
              return true
            }
          };

          const HargaPromoNominal = (e) => {
            const value = e === undefined ? 'undefined' : e;
            setPromoGlobalNominal(value || ' ');
            };

            const badgetAwal = (e) => {
              const value = e === undefined ? 'undefined' : e;
              setBudgetAwal(value || ' ');
              };

	const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

	const formatRupiah = (money) => {
		return new Intl.NumberFormat('id-ID',
			{ style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
		).format(money);
	  }

	return (
		<>
        {alert}
			<div>
				<SimpleHeader name="Edit Promo Global" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
								<CardBody>
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <h3>Promo</h3>
                                                <div style={{ textAlign: 'right' }}>
                                                    <h1>
                                                    </h1>
                                                </div>
                                            </div>
                                        </CardHeader>
                                        <Row md="12">
                                            <Col md="6">
                                                <CardBody>
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            Kode Promo
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                                disabled
                                                                autoComplete="off"    
                                                                className="form-control-alternative"
                                                                type="text"
                                                                name="barname"
                                                                placeholder="Masukan Kode Promo"
                                                                value={codepromo}
                                                                onChange={(e) => {
                                                                    setCodePromo(e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            Nama Promo
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                            
                                                                autoComplete="off"    
                                                                className="form-control-alternative"
                                                                type="text"
                                                                name="barname"
                                                                placeholder="Masukan Nama Promo"
                                                                value={namapromo}
                                                                onChange={(e) => {
                                                                    setNamaPromo(e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                        <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                            Keterangan
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                            
                                                                autoComplete="off"
                                                                className="form-control-alternative"
                                                                type="textarea"
                                                                rows ="5"
                                                                placeholder="Masukan Keterangan"
                                                                value={keterangan}
                                                                onChange={(e) => {
                                                                    setKeterangan(e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                Tipe Promo
                                                            </Label>
                                                            <Col sm={7}>
                                                                <Input
                                                                    autoComplete="off"
                                                                    className="form-control-alternative"
                                                                    name="Supplier"
                                                                    type="select"
                                                                    placeholder="Masukan Type"
                                                                    value={uniontype}
                                                                    onChange={(e) => {
                                                                        setUnionType(e.target.value);
                                                                    }} >
                                                                <option value="" selected hidden> Tipe Promo</option>
                                                                <option value="1">Bisa Gabung</option>
                                                                <option value="2">Tidak Gabung</option>
                                                                </Input>
                                                            </Col>
                                                        </FormGroup>
                                                </CardBody>
                                            </Col>
                                            <Col md="6">
                                                <CardBody>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                                Budget
                                                            </Label>
                                                            <Col sm={7}>
                                                                {/* <Input
                                                                
                                                                autoComplete="off"
                                                                    className="form-control-alternative"
                                                                    type="number"
                                                                    placeholder="Rp.0"
                                                                    value={budgetawal}
                                                                    onChange={(e) => {
                                                                        setBudgetAwal(e.target.value);
                                                                    }}
                                                                /> */}
                                                                 <CurrencyInput
                                                                    id="validation-example-2-field"
                                                                    placeholder="Rp.0"
                                                                    allowDecimals={false}
                                                                    value={budgetawal}
                                                                    onValueChange={badgetAwal}
                                                                    className={`form-control`}
                                                                    prefix={'Rp'}
                                                                    step={10}
                                                                    />
                                                            </Col>
                                                        </FormGroup>
                                                        <FormGroup row>
                                                            <Label
                                                            className="form-control-label"
                                                            for="exampleEmail" sm={4}>
                                                            Potongan
                                                            </Label>
                                                            {/* <Col sm={3}>
                                                            <Input
                                                            
                                                            autoComplete="off"
                                                                className="form-control-alternative"
                                                                type="number"
                                                                disabled = {promoglobalnominal > 0}
                                                                placeholder="Promo (%)"
                                                                value={promoglobalpersen}
                                                                onChange={(e) => {
                                                                setPromoGlobalPersen(e.target.value);
                                                                }}
                                                            />
                                                            </Col> */}
                                                            <Col sm={4}>
                                                            {/* <Input
                                                            
                                                                autoComplete="off"
                                                                className="form-control-alternative"
                                                                type="number"
                                                                name="lebar"
                                                                disabled = {promoglobalpersen > 0}
                                                                placeholder="Promo (N)"
                                                                value={promoglobalnominal}
                                                                onChange={(e) => {
                                                                setPromoGlobalNominal(e.target.value);
                                                                }}
                                                            /> */}
                                                            <CurrencyInput
                                                                id="validation-example-2-field"
                                                                placeholder="Rp.0"
                                                                allowDecimals={false}
                                                                value={promoglobalnominal}
                                                                onValueChange={HargaPromoNominal}
                                                                className={`form-control`}
                                                                prefix={'Rp'}
                                                                step={10}
                                                                />
                                                            </Col>
                                                        </FormGroup>
                                                        
                                                        <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                        Mulai
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                            
                                                                autoComplete="off"
                                                                className="form-control-alternative"
                                                                type="date"
                                                                
                                                                placeholder="Masukan Tanggal"
                                                                value={start}
                                                                onChange={(e) => {
                                                                    setStart(e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Label
                                                        className="form-control-label"
                                                        for="exampleEmail" sm={4}>
                                                        Selesai
                                                        </Label>
                                                        <Col sm={7}>
                                                            <Input
                                                            
                                                                autoComplete="off"
                                                                className="form-control-alternative"
                                                                type="date"
                                                                placeholder="Masukan Tanggal"
                                                                value={end}
                                                                onChange={(e) => {
                                                                    setEnd(e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                    <FormGroup row>
                                                        <Col
                                                            className="form-control-label"
                                                            for="exampleEmail"
                                                            sm={4}
                                                        >
                                                            Status
                                                        </Col>
                                                            <Col sm={7}>
                                                            <div style={{ display: "flex" }}>
                                                                <label
                                                                className="custom-toggle custom-toggle-primary">
                                                                    <Input
                                                                    
                                                                    type="checkbox"
                                                                    checked={status == 1 ? true : false}
                                                                    value={status == 1 ? "2" : "1"}
                                                                    onChange={(e) => {
                                                                        setStatus(e.target.value );
                                                                    }}
                                                                    />
                                                                    <span
                                                                    className="custom-toggle-slider rounded-circle"
                                                                    ></span>
                                                                </label>
                                                            </div>  
                                                            </Col>
                                                    </FormGroup>
                                                </CardBody>
                                            </Col>
                                        </Row>
                                    </Card>
                                    {/* Hari */}
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <h3>Promo Hari</h3>
                                                <Form onSubmit={allCentanghari}>
                                                    <div style={{ textAlign: "right" }}>
                                                        <Button color="primary" className="mb-2" type="submit">Pilih Semua</Button> 
                                                    </div>
                                                </Form>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                        <Table size="sm" responsive>
                                                <thead>
                                                    <tr>
                                                    <th><center>Senin</center></th>
                                                    <th><center>Selasa</center></th>
                                                    <th><center>Rabu</center></th>
                                                    <th><center>Kamis</center></th>
                                                    <th><center>Jumat</center></th>
                                                    <th><center>Sabtu</center></th>
                                                    <th><center>Minggu</center></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                        <tr>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked={
                                                                        senin == 1 ? true : false
                                                                    }
                                                                    value={senin == 1 ? "2" : "1"}
                                                                    onChange={(e) => {
                                                                            setSenin(e.target.value);
                                                                        }}
                                                                />
                                                                <Label
                                                                className="form-control-label"
                                                                check>
                                                                </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {selasa == 1 ? true : false}
                                                                    value={selasa == 1 ? "2" : "1"}
                                                                    onChange={(e) => setSelasa(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {rabu == 1 ? true : false}
                                                                    value={rabu == 1 ? "2" : "1"}
                                                                    onChange={(e) => setRabu(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {kamis == 1 ? true : false}
                                                                    value={kamis == 1 ? "2" : "1"}
                                                                    onChange={(e) => setKamis(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {jumat == 1 ? true : false}
                                                                    value={jumat == 1 ? "2" : "1"}
                                                                    onChange={(e) => setJumat(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {sabtu == 1 ? true : false}
                                                                    value={sabtu == 1 ? "2" : "1"}
                                                                    onChange={(e) => setSabtu(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {minggu == 1 ? true : false}
                                                                    value={minggu == 1 ? "2" : "1"}
                                                                    onChange={(e) => setMinggu(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        </tr>
                                                </tbody>
                                                </Table>
                                        </CardBody>
                                    </Card>
                                    {/* Bulan */}
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <h3>Promo Bulan</h3>
                                                <Form onSubmit={allCentangBulan}>
                                                    <div style={{ textAlign: "right" }}>
                                                        <Button color="primary" className="mb-2" type="submit">Pilih Semua</Button> 
                                                    </div>
                                                </Form>
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                        <Table size="sm" responsive>
                                                <thead>
                                                    <tr>
                                                    <th><center>Januari</center></th>
                                                    <th><center>Februari</center></th>
                                                    <th><center>Maret</center></th>
                                                    <th><center>April</center></th>
                                                    <th><center>Mei</center></th>
                                                    <th><center>Juni</center></th>
                                                    <th><center>Juli</center></th>
                                                    <th><center>Agustus</center></th>
                                                    <th><center>September</center></th>
                                                    <th><center>Oktober</center></th>
                                                    <th><center>November</center></th>
                                                    <th><center>Desember</center></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                        <tr>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked={
                                                                        januari == 1 ? true : false
                                                                    }
                                                                    value={januari == 1 ? "2" : "1"}
                                                                    onChange={(e) => {
                                                                            setJanuari(e.target.value);
                                                                        }}
                                                                />
                                                                <Label
                                                                className="form-control-label"
                                                                check>
                                                                </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {februari == 1 ? true : false}
                                                                    value={februari == 1 ? "2" : "1"}
                                                                    onChange={(e) => setFebruari(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {maret == 1 ? true : false}
                                                                    value={maret == 1 ? "2" : "1"}
                                                                    onChange={(e) => setMaret(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {april == 1 ? true : false}
                                                                    value={april == 1 ? "2" : "1"}
                                                                    onChange={(e) => setApril(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {mei == 1 ? true : false}
                                                                    value={mei == 1 ? "2" : "1"}
                                                                    onChange={(e) => setMei(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {juni == 1 ? true : false}
                                                                    value={juni == 1 ? "2" : "1"}
                                                                    onChange={(e) => setJuni(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {juli == 1 ? true : false}
                                                                    value={juli == 1 ? "2" : "1"}
                                                                    onChange={(e) => setJuli(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {agustus == 1 ? true : false}
                                                                    value={agustus == 1 ? "2" : "1"}
                                                                    onChange={(e) => setAgustus(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {september == 1 ? true : false}
                                                                    value={september == 1 ? "2" : "1"}
                                                                    onChange={(e) => setSeptember(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {oktober == 1 ? true : false}
                                                                    value={oktober == 1 ? "2" : "1"}
                                                                    onChange={(e) => setOktober(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {november == 1 ? true : false}
                                                                    value={november == 1 ? "2" : "1"}
                                                                    onChange={(e) => setNovember(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        <td align="center">
                                                            <FormGroup check>
                                                                <Input
                                                                
                                                                    id="checkbox2"
                                                                    type="checkbox"
                                                                    checked = {desember == 1 ? true : false}
                                                                    value={desember == 1 ? "2" : "1"}
                                                                    onChange={(e) => setDesember(e.target.value)}
                                                                />
                                                                    <Label
                                                                    className="form-control-label"
                                                                    check>
                                                                    </Label>
                                                            </FormGroup>
                                                        </td>
                                                        </tr>
                                                </tbody>
                                                </Table>
                                        </CardBody>
                                    </Card>
                                    {/* Cabang */}
                                    <Card className="bg-secondary shadow">
                                        <CardHeader className="bg-white border-0">
                                            <h3>Cabang</h3>
                                            <Form onSubmit={getAllCabang}>
                                                <div style={{ textAlign: "right" }}>
                                                    <Button color="primary" className="mb-2" type="submit">Pilih Semua</Button> 
                                                </div>
                                            </Form>
                                        </CardHeader>
                                            <CardBody>
                                                <Table size="sm" responsive>
                                                    <thead>
                                                        <tr>
                                                            <th><b>Kode Cabang</b></th>
                                                            <th><b>Cabang</b></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                    savedItems1.map((savedItem1, key) => {
                                                        return (
                                                        <tr key={key}>
                                                            <td>{savedItem1.warehouse_code}</td>
                                                            <td>{savedItem1.warehouse_name}</td>
                                                            <td>
                                                            <Button color="danger" onClick={() => deleteItem1(savedItem1.warehouse_id)}><i className="fas fa-trash" /></Button>
                                                            </td>
                                                        </tr>
                                                        )
                                                    })
                                                    }
                                                     <tr>
                                                        <td>
                                                            <><Form onSubmit={handleSubmit1}>
                                                                    <Input
                                                                    autoComplete="off"
                                                                    placeholder="Masukan Cabang"
                                                                    type="search"
                                                                    // onKeyDown={searchh}
                                                                    value={queryyy}
                                                                    onChange={(e) => setQueryyy(e.target.value)}
                                                                    />
                                                                    {/* <Button type="submit"><i className="fa fa-search" /></Button> */}
                                                                
                                                                {isSearchShowww && queryyy && (
                                                                <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                                                    <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                                                    <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShowww(false)}></i>
                                                                    </div>
                                                                    {allItemmm?.response ? (
                                                                    allItemmm.response.map((item) => (
                                                                        <CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {saveItemOrUpdate1(item);setQueryyy('');setIsSearchShowww(false);}}>
                                                                        <div>
                                                                            <b>Cabang:</b> {item.warehouse_name}
                                                                        </div>
                                                                        </CardBody>
                                                                    ))
                                                                    ) : (
                                                                    <div className="text-center mb-3 text-danger">Cabang "{queryyy}" tidak ada bosku!</div>
                                                                    )}
                                                                </Card>
                                                                )}
                                                            </Form></>
                                                            </td>
                                                            </tr>
                                                    </tbody>
                                                </Table>
                                            </CardBody>
                                            <CardFooter>
                                            <Link className="btn btn-info" to="/admin/promo/promo-global">
                                                Kembali
                                            </Link>
                                            <Button color="danger" onClick={setQuestionAlert}>
                                                Simpan
                                            </Button>
                                        </CardFooter>
                                    </Card>
								</CardBody>
						</div>
					</Row>
				</Container>
			</div>
		</>
	);
}