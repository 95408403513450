/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Card, Row, CardBody, CardHeader, Input, Col, Container, Label, FormGroup } from 'reactstrap';
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";
import filterFactory from 'react-bootstrap-table2-filter';
import Halaman404 from 'views/404';


const Account = () => {
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  const [alert, setAlert] = React.useState(null);
  const [rowIndex, setRowIndex] = useState(0);
  const [allAccount, setAllAccount] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [cabang, setCabang] = useState(warehouse)
  const [cabangs, setCabangs] = useState([]);
  const [currentSort, setCurrentSort] = useState("");
  // const [warehouseid, setWarehouseId] = useState(1);
  const allInfo = JSON.parse(localStorage.allinfo);
  const account = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Account").map((p) => p.read_access));
	
  useEffect(() => {
    getCabang();
  }, []);

  const getCabang = () => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`, { headers
      })
      .then(data => {
        setCabangs(data.data.response);
        // setCabang(id);
      })
        .catch(function (error) {
          console.log(error)
        })
    }

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, cabang);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort, cabang)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, cabang) => {
    getAccount(page, perPage,sort, cabang);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    // setWarehouseId(warehouseid)
    setCabang(cabang)
  }

  const handleTableChange = (type, { sortField, sortOrder, cabang }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, cabang)
    }
  }

  useEffect(() => {
    getAccount(page, perPage, currentSort, cabang);
  }, []);

  const getAccount = async (page, perPage, warehouse_id=null)  => {
    let filter = { 
      page: page, 
      per_page: perPage,
      warehouse_id : cabang
      // warehouse_id: parseInt(warehouse)
    }
    if (warehouse_id !== "") {
      filter = Object.assign(filter, { warehouse_id: warehouse_id });
  }
    const data = filter;
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/account/page`, data, { headers
    })
    .then(data => {
        setAllAccount(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
    })
    .catch(function (error) {
      setGagalAlert(error.response.data.message);
});
}

const setGagalAlert = (id) => {
setAlert(
<SweetAlert
danger
showConfirm
confirmBtnText="Ok"
title={id}
onCancel={hideAlert}
onConfirm={hideAlert}
/>
);
};

const hideAlert = () => {
setAlert(null);
}

const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}

  return (
    <div>
      {alert}
      {account && account === "1" ? (
      <div>
      <SimpleHeader name="Akun" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3>List Akun</h3>
                </div>
              </CardHeader>
              <CardBody>
                  <Row md="12">
                    <Col md="3">
                        <FormGroup>
                        <Label
                        className="form-control-label">Cabang</Label>
                        <Input
                            autoComplete="off"
                            type="select"
                            placeholder=""
                            value={cabang}
                            onChange={e => updateDataTable(1, perPage, e.target.value)}
                            // onChange={(e) => {
                            //   setCabang(e.target.value);
                            //   getUser(e.target.value)
                            // }}
                        >
                            <option value={""}>Masukan Cabang</option>
                            {cabangs.map((warehouse2, key) => {
                                return (
                                    <option key={key} value={warehouse2.id}>
                                    {warehouse2.name}
                                    </option>
                                );
                                })}
                        </Input>
                        </FormGroup>
                    </Col>
                  </Row>
                <ToolkitProvider
                  rowNumber={rowIndex}
                  data={allAccount}
                  keyField="id"
                  columns={[
                    {
                      dataField: "no",
                      text: "#",
                      sort: true,
                      page: 1,
                      formatter: (cell, row, index) => {
                        let currentRow = ++index;
                        return currentRow + rowIndex;
                      }
                    },
                    {
                      dataField: "account_code",
                      text: "Kode Account",
                      sort: true,
                    },
                    {
                      dataField: "account_name",
                      text: "Nama Account",
                      sort: true,
                    },
                    {
                      dataField: "saldo_total",
                      text: "Debet",
                      sort: true,
                      formatter: (value) => formatRupiah(value)
                    },
                    {
                      dataField: "kredit_total",
                      text: "Kredit",
                      sort: true,
                      formatter: (value) => formatRupiah(value)
                    },
                  ]}
                >
                  {(props) => (
                    <div id="datatable-basic_filter" className="py-4 table-responsive">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1 float-right"
                    >
                    </div>
                      <BootstrapTable
                        remote
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        hover={true}
                        // expandRow={ expandRow }
                        pagination={paginationFactory({ ...paginationOption })}
                        onTableChange={handleTableChange}
                        filter={ filterFactory() }
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      </div>
      ):(
        <Halaman404 />
      )}
    </div>
  );
}

export default Account;
