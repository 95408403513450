/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Label,
	Form,
	FormGroup,
	Row,
	Input,
	CardHeader,
	CardFooter,
	Table ,
	Col,
	Button,
	Container,
	CardGroup,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Barcodee from "react-barcode";
import QRCode from "qrcode.react";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

export default function EditItem(props) {
	const token = localStorage.token;
	const warehouseId = localStorage.warehouse;
	const username = localStorage.username;
	let history = useHistory();
	const [itemCode, setItemCode] = useState("");
	const [nameItem, setNameItem] = useState("");
	const [description, setDescription] = useState("");
	const [numberPart, setNumberPart] = useState("");
	const [allSatuan, setAllSatuan] = useState([]);
	const [satuan, setSatuan] = useState("");
	const [allGrade, setAllGrade] = useState([]);
	const [grade, setGrade] = useState("");
	const [category, setCategory] = useState("")
	const [categories, setCategories] = useState([]);
	const [subCategory, setSubCategory] = useState("");
	const [subCategories, setSubCategories] = useState([]);
	const [nameFunction, setNameFunction] = useState("")
	const [nameFunctions, setNameFunctions] = useState([]);
	const [nameSubFunction, setNameSubFunction] = useState("");
	const [nameSubFunctions, setNameSubFunctions] = useState([]);
	const [categoryId, setCategoryId] = useState("");
	const [subCategoryId, setSubCategoryId] = useState("");
	const [functionId, setFunctionId] = useState("");
	const [subMerekId, setSubMerekId] = useState("");
	const [merekId, setMerekId] = useState("");
	const [merek, setMerek] = useState("");
	const [allMerek, setAllMerek] = useState([]);
	const [submerek, setSubMerek] = useState("");
	const [allSubMerek, setAllSubMerek] = useState([]);
	const [panjang, setPanjang] = useState("");
	const [lebar, setLebar] = useState("");
	const [tinggi, setTinggi] = useState("");
	const [nameType, setNameType] = useState("");
	const [barcode, setBarcode] = useState("");
	const [status, setStatus] = useState("");
	const [minimumstock, setMinimumStock] = useState("");
	const [itemgrup, setItemGrup] = useState("");
	const [itemgrups, setItemGrups] = useState([]);
	const [berat, setBerat] = useState("");
	const [savedItems, setSavedItems] = useState([]);
	const [qtyTotal, setTotalQty] = useState(0);
	const [isSearchShoww, setIsSearchShoww] = useState(false);
	const [allItemm, setAllItemm] = useState([]);
	const [queryy, setQueryy] = useState("");
	const headers = { Authorization: `Bearer ${token}` };
	const [alert, setAlert] = React.useState(null);
	const allInfo = JSON.parse(localStorage.allinfo);
	const itempusat = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Item Pusat").map((p) => p.read_access));
	

	useEffect(() => {
        getById();
	}, []);

	  const getById = () => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/get-items?id=${props.match.params.id}&wid=${warehouseId}`,
	        { headers }
	      )
	      .then((data) => {
			getItemDataSaved()
			getCategory(data.data.response.kategori_id);
            getSubCategory(data.data.response.kategori_id, data.data.response.subkategori_id);
            getFunction(data.data.response.kategori_id, data.data.response.subkategori_id, data.data.response.function_id);
            getSubFunction(data.data.response.kategori_id, data.data.response.subkategori_id, data.data.response.function_id, data.data.response.subfunction_id);

			getSatuan(data.data.response.uom_id);
	        getItemGrup(data.data.response.ig_id);

			setBarcode(data.data.response.barcode);
	        setBerat(data.data.response.berat);
			setNameType(data.data.response.type);
	        setStatus(data.data.response.active_flag);
	        setNameItem(data.data.response.item_name);
	        setDescription(data.data.response.item_description);
	        setNumberPart(data.data.response.part_number);
	        setPanjang(data.data.response.panjang);
	        setLebar(data.data.response.lebar);
	        setTinggi(data.data.response.tinggi);
	        setMinimumStock(data.data.response.minimum_stok);
	        setItemCode(data.data.response.item_code);
			setNameFunction(data.data.response.function_id)
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

	  const getItemDataSaved = () => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		  };
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-items?id=${props.match.params.id}&wid=${warehouseId}`,{ headers }
		
		).then(async response => {
			let stateItem = [];
			await Promise.all(response.data.response.part.map(async (data) => {
				stateItem = [...stateItem, {
					item_id: data.item_id,
					item_name:data.item_name,
					item_code:data.item_code,
					qty: data.qty,
					harga: data.harga,
					satuan: data.satuan,
					diskon_nominal: data.diskon_nominal,
					diskon_persen : data.diskon_persen,
					sub_total : data.sub_total,
					data: {
						item_name: data.item_name,
						harga: data.harga
					},
				}];
			}));
	
			setSavedItems(stateItem);
		})
	}

	useEffect(() => {
		const getData = setTimeout(async() => {
			if(queryy != ''){
				axios
				.post(`${process.env.REACT_APP_API_BASE_URL}/items-po`, { item_info: queryy , per_page: 10, warehouse_id: parseInt(warehouseId)},{ headers })
				.then((res) => {
					setAllItemm(res.data);
					setIsSearchShoww(true);
				})
				.catch(function (error) {
	  
				});
			}else{
		  setAllItemm(null);
			}
		  }, 1000)
		  return () => clearTimeout(getData)
	  }, [queryy]);
	  
	 

	const getItemGrup = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/item-group/list`, { headers })
			.then((data) => {
				setItemGrups(data.data.response);
				setItemGrup(id);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	// console.log("kategori" + category);
	// console.log("Sub Kategori" + subCategory);
	// console.log("Function" + nameFunction);
	// console.log("Sub Function" + nameSubFunction);
	// console.log("Merek" + merek);


	const getCategory = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/item-kategori/list`, { headers })
			.then((data) => {
				setCategories(data.data.response);
				setCategory(id);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	
	const getSubCategory = (categoryId, id) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		};
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/sub-kategori/list?kategori_id=${categoryId}`, { headers })
			.then((data) => {
				setSubCategories(data.data.response);
				setSubCategory(id);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	
	const getFunction = (categoryId, subCategoryId, id) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		};
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/item-function/list?kategori_id=${categoryId}&sub_kategori_id=${subCategoryId}`, { headers })
			.then((data) => {
				setNameFunctions(data.data.response);
				setNameFunction(id);
			})
			.catch((error) => {
				console.log(error);
			});
	};
	
	const getSubFunction = (categoryId, subCategoryId, functionId, id) => {
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		};
		axios.get(`${process.env.REACT_APP_API_BASE_URL}/sub-function/list?kategori_id=${categoryId}&sub_kategori_id=${subCategoryId}&function_id=${functionId}`, { headers })
			.then((data) => {
				setNameSubFunctions(data.data.response);
				setNameSubFunction(id);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const getMerek = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/merek/list`, { headers })
			.then((data) => {
				setAllMerek(data.data.response);
				setMerek(id);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getSubMerek = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/sub-merek/list?merek_id=${id}`, { headers })
			.then((data) => {
				setAllSubMerek(data.data.response);
				setSubMerek(id);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const getGrade = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/item-grade/list`, { headers })
			.then((data) => {
				setAllGrade(data.data.response);
				setGrade(id);
			})
			.catch(function (error) {
				console.log(error);
			});
	};


	const getSatuan = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/uom/list`, { headers })
			.then((data) => {
				setAllSatuan(data.data.response);
				setSatuan(id);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	// Update sub categories and functions when category changes
	useEffect(() => {
		if (category) {
			getSubCategory(category);
			// Reset sub category and functions when category changes
			setSubCategory('');
			setNameFunction('');
			setNameSubFunction('');
		}
	}, [category]);

	// Update functions when sub category changes
	useEffect(() => {
		if (subCategory) {
			getFunction(category, subCategory);
			// Reset functions when sub category changes
			setNameFunction('');
			setNameSubFunction('');
		}
	}, [subCategory]);

	// Update sub functions when function changes
	useEffect(() => {
		if (nameFunction) {
			getSubFunction(category, subCategory, nameFunction);
			// Reset sub function when function changes
			setNameSubFunction('');
		}
	}, [nameFunction]);

	

	const handleEditQty = (index, value) => {
		let updateList = savedItems;
		let aqtyTotal = parseInt(updateList[index].harga) + value;
		
		if(!isNaN(value) && value.length > -1){
		  updateList[index] = {...updateList[index], qty: value};
		  setSavedItems(updateList);
		  setTotalQty(qtyTotal + aqtyTotal);
		}else{return false}
	  }
	  
	  
	  // save
	  const saveItemOrUpdate = (item) => {
		let oldobj = savedItems.find(o => o.item_id === item.id);
		if(oldobj===undefined){
		  setSavedItems([...savedItems,{
			item_id: item.id,
			item_name: item.item_name,
			item_code : item.item_code,
			qtysisa : item.stock,
			uom_code: item.uom_code,
			satuan : item.satuan,
			qty: 1,
		  }]);
		  
		}else{
		  let index = savedItems.findIndex(e=>e.item_id===item.id);
		  let updateList = savedItems;
		  let qtyupdate = parseInt(updateList[index].qty)+parseInt(1);
		  updateList[index] = {...updateList[index], qty: qtyupdate};
		  setSavedItems(updateList);
		};
	  }
	  
	  const handleSubmit = async (e) => {
		e.preventDefault();
		{
		  setQueryy("");
		  setIsSearchShoww("");
		  return true
		}
	  };

	  const deleteItem = (id) => {
		let array = [...savedItems];
	
		let index = array.findIndex(object => {
			return object.item_id === id;
		});
	
		if (index !== -1) {
			array.splice(index, 1);
			setSavedItems(array);
		}
	}

	function EditData() {
		let dataItems = [];
			savedItems.map((dataItem) => dataItems = [...dataItems, 
				{ 
					item_id: dataItem.item_id, 
					qty: dataItem.qty,
				}]);
        let data = {
            warehouse_id: parseInt(warehouseId),
			username: username,
			barcode : barcode,
			item_description: description,
			part_number: numberPart,
			uom_id: parseInt(satuan),
			kategori_id: parseInt(category),
			subkategori_id: parseInt(subCategory),
			grade_id: parseInt(grade),
			function_id: parseInt(nameFunction),
			subfunction_id: parseInt(nameSubFunction),
			merek_id: parseInt(merek),
			submerek_id: parseInt(submerek),
			active_flag: parseInt(status),
			ig_id: parseInt(itemgrup),
			berat: parseInt(berat),
			item_name: nameItem,
			minimum_stok: minimumstock,
			panjang,
			lebar,
			tinggi,
			stock: 0,
			type: nameType,
			part : dataItems,
        };
        axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/items/update/${props.match.params.id}`, data , {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then(function (response) {
              setSuccessAlert(response.data.message);
              setTimeout(() => (history.push("/admin/item")), 1000);
              })
              .catch(function (error) {
                setGagalAlert(error.response.data.message);
              });
        }

	// const EditData1 = () => {
	// 	const headers = {
	// 		"Content-Type": "multipart/form-data",
	// 		Authorization: `Bearer ${token}`,
	// 	};
	// 	const myjson = JSON.stringify({
	// 		warehouse_id: parseInt(warehouseId),
	// 		username: username,
	// 		barcode : barcode,
	// 		item_description: description,
	// 		part_number: numberPart,
	// 		uom_id: parseInt(satuan),
	// 		kategori_id: parseInt(category),
	// 		subkategori_id: parseInt(subCategory),
	// 		grade_id: parseInt(grade),
	// 		function_id: parseInt(nameFunction),
	// 		subfunction_id: parseInt(nameSubFunction),
	// 		merek_id: parseInt(merek),
	// 		submerek_id: parseInt(submerek),
	// 		active_flag: parseInt(status),
	// 		ig_id: parseInt(itemgrup),
	// 		berat: parseInt(berat),
	// 		item_name: nameItem,
	// 		minimum_stok: minimumstock,
	// 		panjang,
	// 		lebar,
	// 		tinggi,
	// 		stock: 0,
	// 		type: nameType,
	// 	});
	// 	let data = new FormData();
	// 	data.append("body", myjson);
	// 	data.append("image1", image1);
	// 	data.append("image2", image2);
	// 	data.append("image3", image3);
	// 	axios
	// 		.post(`${process.env.REACT_APP_API_BASE_URL}/items/update/${props.match.params.id}`, data, { headers })
	// 		.then(function (response) {
	// 			setSuccessAlert();
	// 			setTimeout(() => (history.push("/admin/item")), 2000);
	// 			})
	// 			.catch(function (error) {
	// 			  setGagalAlert();
	// 			});
	// 	  }
		
		  const setSuccessAlert = (id) => {
			setAlert(
			  <SweetAlert
				success
				showConfirm
				confirmBtnText="Ok"
				title={id}
				onCancel={hideAlert}
				onConfirm={hideAlert}
			  />
			)
		  }
		
		const setGagalAlert = (id) => {
		setAlert(
		  <SweetAlert
		  danger
		  showConfirm
		  confirmBtnText="Ok"
		  title={id}
		  onCancel={hideAlert}
		  onConfirm={hideAlert}
		  />
		);
		};
		
		const setQuestionAlert = () => {
		setAlert(
		  <SweetAlert
		  warning
		  showCancel
		  confirmBtnText="Lanjutkan"
		  confirmBtnBsStyle="danger"
		  title="Apakah Kamu Yakin?"
		  onConfirm={EditData}
		  onCancel={hideAlert}
		  focusCancelBtn
		  />
		);
		};
		
		  const hideAlert = () => {
			setAlert(null);
		  }


	return (
		<>
		{alert}
		{itempusat && itempusat === "1" ? (
			<div>
				<SimpleHeader name="Edit Item" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
							<CardBody>
								<Row md="12">
									<Col md="7">
										<Card className="bg-secondary shadow">
											<CardHeader className="bg-white border-0">
												<h3>EDIT ITEM</h3>
											</CardHeader>
											<CardBody>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Kode Item
													</Label>
													<Col sm={7}>
														<Input
															autoComplete="off"
															disabled
															className="form-control-alternative"
															type="text"
															name="itemCode"
															placeholder="Masukan Kode Item"
															value={itemCode}
															
														/>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Barcode
													</Label>
													<Col sm={7}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															type="text"
															name="barcode"
															placeholder="Masukan Barcode"
															value={barcode}
															
														/>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Nama Item
													</Label>
													<Col sm={7}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															type="text"
															name="nameItem"
															placeholder="Masukan Nama Item"
															value={nameItem}
															onChange={(e) => {
																setNameItem(e.target.value);
															}}
														/>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
													Jenis Barang
													</Label>
													<Col sm={7}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="kategory"
															type="select"
															value={itemgrup}
															onChange={(e) => {
																setItemGrup(e.target.value);
															}}>
															<option value="">Pilih Jenis Barang</option>
															{itemgrups.map((categorysss, key) => {
																return (
																	<option key={key} value={categorysss.id}>
																		{categorysss.name}
																	</option>
																);
															})}
														</Input>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Satuan
													</Label>
													<Col sm={7}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="satuan"
															type="select"
															value={satuan}
															onChange={(e) => {
																setSatuan(e.target.value);
															}}>
															<option value={0}>Pilih Satuan</option>
															{allSatuan.map((satuan, key) => {
																return (
																	<option key={key} value={satuan.id}>
																		{satuan.description}
																	</option>
																);
															})}
														</Input>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Dimensi <b>(PxLxT)</b>
													</Label>
													<Col sm={2}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															type="text"
															name="panjang"
															placeholder="Panjang"
															value={panjang}
															onChange={(e) => {
																setPanjang(e.target.value);
															}}
														/>
													</Col>
													<Col sm={3}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															type="text"
															name="lebar"
															placeholder="Lebar"
															value={lebar}
															onChange={(e) => {
																setLebar(e.target.value);
															}}
														/>
													</Col>
													<Col sm={2}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															type="text"
															name="tinggi"
															placeholder="Tinggi"
															value={tinggi}
															onChange={(e) => {
																setTinggi(e.target.value);
															}}
														/>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Berat
													</Label>
													<Col sm={4}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															type="text"
															name="Berat"
															placeholder="Masukan Berat"
															value={berat}
															onChange={(e) => {
																setBerat(e.target.value);
															}}
														/>
													</Col>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														<b>Gram</b>
													</Label>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Minimum Stock
													</Label>
													<Col sm={7}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															type="number"
															name="stock"
															placeholder="Masukan Stock"
															value={minimumstock}
															onChange={(e) => {
																setMinimumStock(e.target.value);
															}}
														/>
													</Col>
												</FormGroup>
											</CardBody>
										</Card>
									</Col>
									<Col md="5">
										<Card className="bg-secondary shadow">
											<CardHeader className="bg-white border-0">
												<h3>Deskripsi</h3>
											</CardHeader>
											<CardBody>
												<FormGroup row>
													<Input
														autoComplete="off"
														className="form-control-alternative"
														rows="13"
														type="textarea"
														name="deskripsi Item"
														placeholder="Masukan Deskripsi Item"
														value={description}
														onChange={(e) => {
															setDescription(e.target.value);
														}}
													/>
												</FormGroup>
												<FormGroup row>
														<Card>
															<CardBody>
																<CardGroup>
																	<Row md="10" center>
																		<Col md="5">
																			<QRCode className="qrcode"  value={barcode} />
																		</Col>
																		<Col md="5">
																			<Barcodee className="qrcode" value={barcode} />
																		</Col>
																	</Row>
																</CardGroup>
															</CardBody>
														</Card>
													</FormGroup>
											</CardBody>
										</Card>
									</Col>
								</Row>
								<Card className="bg-secondary shadow">
									<CardHeader className="bg-white border-0">
										<h3>KATEGORI</h3>
									</CardHeader>
									<CardBody>
										<Row md="12">
											<Col md="6">
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Tipe Item
													</Label>
													<Col sm={5}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															type="select"
															name="nameItem"
															placeholder="Masukan Tipe Item"
															value={nameType}
															onChange={(e) => {
																setNameType(e.target.value);
															}}>
															<option value={1}>Material</option>
															<option value={2}>Produksi</option>
														</Input>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Kategori
													</Label>
													<Col sm={5}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="kategory"
															type="select"
															value={category}
															onChange={(e) => {
																setCategory(e.target.value);
															}}>
															<option value="">Pilih Kategori</option>
															{categories.map((category, key) => {
																return (
																	<option key={key} value={category.id}>
																		{category.name}
																	</option>
																);
															})}
														</Input>
													</Col>
													<Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-kategori/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Sub Kategori {subCategory}
													</Label>
													<Col sm={5}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="subKategory"
															type="select"
															value={subCategory}
															onChange={(e) => {
																setSubCategory(e.target.value);
															}}>
															<option value="">Pilih Sub Kategori</option>
															{subCategories.map((category, key) => {
																return (
																	<option key={key} value={category.id}>
																		{category.name}
																	</option>
																);
															})}
														</Input>
													</Col>
													<Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-sub-kategori/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Function
													</Label>
													<Col sm={5}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="function"
															type="select"
															value={nameFunction}
															onChange={(e) => {
																setNameFunction(e.target.value);
															}}>
															<option value="">Pilih Function</option>
															{nameFunctions.map((nameFunction, key) => {
																return (
																	<option key={key} value={nameFunction.id}>
																		{nameFunction.name}
																	</option>
																);
															})}
														</Input>
													</Col>
													<Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-function/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Sub Function
													</Label>
													<Col sm={5}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="subFunction"
															type="select"
															value={nameSubFunction}
															onChange={(e) => {
																setNameSubFunction(e.target.value);
															}}>
															<option value="">Pilih Sub Function</option>
															{nameSubFunctions.map((nameFunction, key) => {
																return (
																	<option key={key} value={nameFunction.id}>
																		{nameFunction.name}
																	</option>
																);
															})}
														</Input>
													</Col>
													<Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-sub-function/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
												</FormGroup>
											</Col>
											<Col md="6">
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Merek
													</Label>
													<Col sm={5}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="merek"
															type="select"
															value={merek}
															onChange={(e) => {
																setMerek(e.target.value);
															}}>
															<option value="">Pilih Merek</option>
															{allMerek.map((m, key) => {
																return (
																	<option key={key} value={m.id}>
																		{m.name}
																	</option>
																);
															})}
														</Input>
													</Col>
													<Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-merek/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Sub Merek
													</Label>
													<Col sm={5}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="merek"
															type="select"
															value={submerek}
															onChange={(e) => {
																setSubMerek(e.target.value);

															}}>
															<option value="">Pilih Sub Merek</option>
															{allSubMerek.map((submerek, key) => {
																return (
																	<option key={key} value={submerek.id}>
																		{submerek.name}
																	</option>
																);
															})}
														</Input>
													</Col>
													<Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-sub-merek/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label
													className="form-control-label"
													for="exampleEmail" sm={4}>
														Grade
													</Label>
													<Col sm={5}>
														<Input
														autoComplete="off"
															className="form-control-alternative"
															name="grade"
															type="select"
															value={grade}
															onChange={(e) => {
																setGrade(e.target.value);
															}}>
															<option value="">Pilih Grade</option>
															{allGrade.map((a, key) => {
																return (
																	<option key={key} value={a.id}>
																		{a.nilai}
																	</option>
																);
															})}
														</Input>
													</Col>
													<Col sm={3}>
														<Button
															onClick={() =>
																history.push({
																	pathname: `/admin/item-grade/create`,
																})
															}
															color="secondary"
															type="button">
															<i className="ni ni-fat-add" />
														</Button>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Col sm={4}>
													<Label
													className="form-control-label">
														Status
													</Label>
													</Col>
													<Col sm={7}>
														<div style={{ display: "flex" }}>
															<div className="custom-control custom-radio mb-3">
																<Input
																autoComplete="off"
																	className="custom-control-input"
																	id="customRadio11"
																	name="custom-radio-4"
																	type="checkbox"
																	value={1}
																	checked={status === 1}
																	onChange={() => setStatus(1)}
																/>
																<Label
																className="custom-control-label" htmlFor="customRadio11">
																</Label>
																<b>AKTIF</b>

															</div>
															<div className="custom-control custom-radio mb-3" style={{ marginLeft: "20px" }}>
																<Input
																autoComplete="off"
																	className="custom-control-input"
																	id="customRadio12"
																	name="custom-radio-4"
																	type="radio"
																	value={2}
																	checked={status === 2}
																	onChange={() => setStatus(2)}
																/>
																<Label
																className="custom-control-label" htmlFor="customRadio12">
																</Label>
																<b>TIDAK AKTIF</b>

															</div>
														</div>
													</Col>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
								</Card>
								{nameType >  1  ? (<Card className="bg-secondary shadow">
										<CardHeader className="bg-white border-0">
										<h3>Item</h3>
										</CardHeader>
										<CardBody>
											<Table size="sm" responsive>
												<thead>
												<tr>
													<th><b>Nama Item</b></th>
													<th><b>Kode Item</b></th>
													<th><b>Qty</b></th>
													<th><b>Satuan</b></th>
												</tr>
												</thead>
												<tbody>
												{
													savedItems.map((savedItem, key) => {
													return (
														<tr key={key}>
														<td>{savedItem.item_name}</td>
														<td>{savedItem.item_code}</td>
														<td>
														<Input
																className="form-control-alternative"
																placeholder="Qty"
																type="number"
																value={savedItem.qty}
																onChange={(e) => {
																	handleEditQty(key, e.target.value);
																}}/>
														</td>
														<td>{savedItem.satuan}</td>
														<td>
															<Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
														</td>
														</tr>
													)
													})
												}
												<tr>
													<td>
													<><Form onSubmit={handleSubmit}>
															<Input
																autoComplete="off"
																placeholder="Masukan Item Manual"
																type="search"
																value={queryy}
																onChange={(e) => setQueryy(e.target.value)}
															/>
															
															{isSearchShoww && queryy && (
															<Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
															<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
																<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
															</div>
															{allItemm?.response ? (
																allItemm.response.map((item) => (
																<CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {saveItemOrUpdate(item);setQueryy('');setIsSearchShoww(false);}}>
																	<div>
																	<b>Nama item:</b> {item.item_name}
																	</div>
																</CardBody>
																))
															) : (
																<div className="text-center mb-3 text-danger">Item "{queryy}" tidak ada bosku!</div>
															)}
															</Card>
														)}
													</Form></>
													</td>
													</tr>
												</tbody>
											</Table>
										</CardBody>
								</Card>):( ""
								)}
								<CardFooter>
										<Link className="btn btn-info" to="/admin/item">
											Kembali
										</Link>
										<Button color="danger" onClick={setQuestionAlert}>
											Simpan
										</Button>
									</CardFooter>
							</CardBody>
						</div>
					</Row>
				</Container>
			</div>
		):(
			<Halaman404 />
		)}
		</>
	);
}
