import React, { useCallback, useEffect, useState } from "react";
import {Button,Row,Col,CardBody,Table,Modal,ModalHeader,ModalBody} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";

const ModalCetakQtyFisik = ({ open, toggle, data }) => {
        const token = localStorage.token;
        const username = localStorage.username;
        const [tanggal, setTanggal] = useState("")
        const [picadmin, setPicAdmin] = useState("")
        const [cabang, setCabang] = useState("");
        const [counteradmin, setCounterAdmin] = useState("");
        const [codeopname, setCodeOpname] = useState("");
        const [savedItems, setSavedItems] = useState([]);
        const [coderak, setCodeRak] = useState("")

          const getById = useCallback(() => {
            const headers = {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            };
            axios
              .get(
                `${process.env.REACT_APP_API_BASE_URL}/opname/cetak/${data.id}`,
                { headers }
              )
              .then((data) => {
                setTanggal(data.data.response.opname.created_at);
                setPicAdmin(data.data.response.opname.creator);
                setCodeRak(data.data.response.opname.code_rak);
                setCodeOpname(data.data.response.opname.opname_code);
                setCabang(data.data.response.opname.warehouse);
                setCounterAdmin(data.data.response.opname.counter_admin)
              })
              .catch(function (error) {
                console.log(error);
              });
          },[token, data.id]);

          const getItemDataSaved = useCallback(() => {
            const headers = {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            };
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/opname/item`, {
      
              opname_id: data.id
      
            },{headers}).then(async response => {
                let stateItem = [];
                let stateEditing = [];
                await Promise.all(response.data.response.map(async (data) => {
                    stateItem = [...stateItem, {
                        item_id: data.item_id,
                        item_name:data.item_name,
                        item_code : data.item_code,
                        qtyfisik: data.qty_fisik,
                        qtysistem: data.qty_sistem,
                        ket:data.ket,
                        data: {
                          item_name: data.item_name,
                          item_code : data.item_code,
                          qtyfisik: data.qty_fisik,
                          qtysistem: data.qty_sistem,
                          ket:data.ket,
                        },
                    }];
                    stateEditing = [...stateEditing, {
                        editing: false
                    }];
              }));
              setSavedItems(stateItem);
          })
        },[token, data.id])

        
          useEffect(() => {
            getById();
            getItemDataSaved();
          }, [getById,getItemDataSaved]);

  // function print() {
  //   var printContents = document.getElementById("targetContent").innerHTML;
  //   var originalContents = document.body.innerHTML;
  //   document.body.innerHTML = printContents;
  //   window.print();
  //   document.body.innerHTML = originalContents;
  // }

  function print() {
    const printContents = document.getElementById("targetContent").innerHTML;
    const printWindow = window.open("", "", "width=900,height=600");
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            /* Tambahkan semua gaya yang diperlukan di sini */
            body {
              font-family: Arial, sans-serif;
              font-size: 12px; /* Perkecil ukuran teks */
            }
             .centered-title {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh; /* Set tinggi agar berada di tengah vertikal halaman */
            text-align: center;
            font-weight: bold;
            font-size: 18px; /* Ukuran teks lebih besar untuk kejelasan */
            color: black;
            margin: 0;
          }
            table {
              width: 100%;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 4px;
              font-size: 12px; /* Perkecil ukuran teks */

            }
            .row {
              display: flex;
              align-items: center;
            }
            .col-4, .col-1, .col-7 {
              padding: 0;
            }
            .col-4 {
              flex: 0 0 60%;
              max-width: 60%;
              text-align: left;
            }
            .col-1 {
              flex: 0 0 5%;
              max-width: 5%;
              text-align: center;
            }
            .col-7 {
              flex: 0 0 100%;
              max-width: 100%;
              text-align: left;
            }
            
          </style>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  }

  return (
    <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top: "-15%" }}>
      <ModalHeader toggle={toggle} align="center">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <   div style={{ textAlign: 'left' }}>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <Button color="info" onClick={() => print()}>Print</Button>
                    </div>
                </div>
      </ModalHeader>
      <ModalBody>
        <CardBody className="border" id="targetContent" style={{ width: "100%" }}>
          <div className="col">
            <KopSurat warehouseName={cabang} />
          </div>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          <center style={{color:"black" , fontWeight: "bold",fontSize: "20px", color: "black",margin: 0,}}>Stok Opname Qty Validasi</center>
           
          <div className="w-100" style={{ border: "1px solid black" }}></div>
            <br></br>
            <Row md="24">
                <Col md="8">
                  <div className="row align-items-center mb-1">
                    <div className="col-4" style={{ color: "black", textAlign: "left" }}>Kode Opname</div>
                    <div className="col-1 text-center" style={{ color: "black" }}>:</div>
                    <div className="col-7" style={{ color: "black", textAlign: "left" }}>
                      <b>{codeopname}</b>
                    </div>
                  </div>
                  <div className="row align-items-center mb-1">
                    <div className="col-4" style={{ color: "black", textAlign: "left" }}>Tanggal</div>
                    <div className="col-1 text-center" style={{ color: "black" }}>:</div>
                    <div className="col-7" style={{ color: "black", textAlign: "left" }}>
                      <b>{tanggal}</b>
                    </div>
                  </div>
                {/* </Col>
                <Col md="8"> */}
                  <div className="row align-items-center mb-1">
                    <div className="col-4" style={{ color: "black", textAlign: "left" }}>Kode Rak</div>
                    <div className="col-1 text-center" style={{ color: "black" }}>:</div>
                    <div className="col-7" style={{ color: "black", textAlign: "left" }}>
                      <b>{coderak}</b>
                    </div>
                  </div>
                </Col>
              </Row>
            
              {/* <Row md="24">
                <Col md="8">
                  <div className="row align-items-center mb-1">
                    <div className="col-4" style={{ color: "black", textAlign: "left" }}>Kode Opname</div>
                    <div className="col-1 text-center" style={{ color: "black" }}>:</div>
                    <div className="col-7" style={{ color: "black", textAlign: "left" }}>
                      <b>{codeopname}</b>
                    </div>
                  </div>
                  <div className="row align-items-center mb-1">
                    <div className="col-4" style={{ color: "black", textAlign: "left" }}>Tanggal</div>
                    <div className="col-1 text-center" style={{ color: "black" }}>:</div>
                    <div className="col-7" style={{ color: "black", textAlign: "left" }}>
                      <b>{tanggal}</b>
                    </div>
                  </div>
                </Col>
                <Col md="8">
                  <div className="row align-items-center mb-1">
                    <div className="col-4" style={{ color: "black", textAlign: "left" }}>Kode Rak</div>
                    <div className="col-1 text-center" style={{ color: "black" }}>:</div>
                    <div className="col-7" style={{ color: "black", textAlign: "left" }}>
                      <b>{coderak}</b>
                    </div>
                  </div>
                </Col>
              </Row> */}

          {/* <CardBody> */}
            <br></br>
          <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th style={{color:"black"}}><b>Nama Item</b></th>
                            <th style={{color:"black"}}><b>Kode Item</b></th>
                            <th style={{color:"black"}}><b>Qty Fisik</b></th>
                            <th style={{color:"black"}}><b>Qty Sistem</b></th>
                            <th style={{color:"black"}}><b>Keterangan</b></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            savedItems.map((savedItem, key) => {
                                return (
                                    <tr key={key}>
                                        <td style={{color:"black"}}>{savedItem.data.item_name}</td>
                                        <td style={{color:"black"}}>{savedItem.item_code}</td>
                                        <td style={{color:"black"}}>{savedItem.qtyfisik}</td>
                                        <td style={{color:"black"}}>{savedItem.qtysistem}</td>
                                        <td style={{color:"black"}}>{savedItem.ket}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                      </Table>

            <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
          <center style={{color:"black"}}>Terms of Price, delivery & shipping required</center>
          <Table>
            <tbody>
              <tr>
                <td>
                  <Row md="12">
                    <Col md="4">
                      <div className="row align-items-center mb-3">
                        <div className="col-4" style={{color:"black"}}>PIC Admin</div>
                        <div className="col-1 text-center" style={{color:"black"}}>:</div>
                        <div className="col-5 " style={{color:"black"}}>
                          <b>{picadmin}</b>
                        </div>
                      </div>
                      <div className="row align-items-center mb-3">
                        <div className="col-4" style={{color:"black"}}>Signature</div>
                        <div className="col-1 text-center" style={{color:"black"}}>:</div>
                        <div className="col-4 " style={{color:"black"}}>
                          <b>-----------------------</b>
                        </div>
                      </div>
                    </Col>
                    </Row>
                  <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                      {counteradmin === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Cetak Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Cetak</i> {counteradmin} Kali Oleh {username}</div>
                    </div>
                      )
                      
                      }
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </ModalBody>
    </Modal>
  );
};

export default ModalCetakQtyFisik;
