/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, CardBody, FormFeedback,CardHeader, CardFooter, Table, Container, Form, FormGroup, Label, Input, Modal, ModalHeader, ModalBody} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

const EditSoReturKasirBaru = (props ) => {
  const [alert, setAlert] = React.useState(null);
  const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [tanggal, setTanggal] = useState("");
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState("");
  const [codeinvoice,setCodeInvoice ] = useState("");
  const [payment_method,setPaymentMethod] = useState(0);
  const [keterangan, setKeterangan] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [savedItems, setSavedItems] = useState([]);
  const [ongkir, setOngkir] = useState(0);
  const [coderetur, setCodeRetur] = useState(0);
  const [allpajak, setAllPajak] = useState([]);
  const [pajak, setPajak] = useState(1);
  const [ppn, setPPN] = useState(0);
  const [totaldiskon, setTotalDiskon] = useState(0);
  const [grandtotal, setGrandTotal] = useState(0);
  const [diskonglobalnominal, setDiskonGlobalNominal] = useState(0);
    const [ongkir1, setOngkir1] = useState(0);
    const [ppnnew, setPPNNEW] = useState(0);
    const [totalPrice1, setTotalPrice1] = useState(0);
    const [a, setA] = useState(0);
    const [b, setB] = useState(0);
    const [diskonglobalnominal1,setDiskonGlobalNominal1] = useState(0)
    const [totaldiskon2, setTotalDiskon2] = useState(0);
    const [totaldiskon3, setTotalDiskon3] = useState(0);
    const [editable, setEditable] = useState(false);
    const [qtyTotal, setTotalQty] = useState(0);
    const [diskontotal, setDiskonTotal] = useState(0);
    const [qtyError, setQtyError] = useState(null);
    const allInfo = JSON.parse(localStorage.allinfo);
    const ReturSoKasirs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Retur Cashier").map((p) => p.read_access));
    
    const validateForm = () => {
      let error = false;
      savedItems.map((savedItem, key) => { 
        if (savedItem.qty > savedItem.qtysisa) {
          setQtyError("invalid");
          error = true;
        }
      })
      return error;
  };
  
  const handleQtySisa = (e) => {
    e.preventDefault();
    if (!validateForm()) {
        setQuestionAlert();
    }
  }
  
  useEffect(() => {
    setDiskonGlobalNominal1(diskonglobalnominal);
  }, [diskonglobalnominal]);
  
    // diskon bayangan
    useEffect(() => {
    setTotalDiskon(diskonglobalnominal1);
  }, [diskonglobalnominal1]);
  
  useEffect(() => {
    setTotalDiskon3(diskontotal-a);
  }, [diskontotal, a]);
  
   //diskon tampil
useEffect(() => {
  setTotalDiskon2((totaldiskon)/((ppn/100)+1));
}, [totaldiskon3, totaldiskon, ppn , a]);
  
  //ongkir tampil
  useEffect(() => {
    setOngkir1(ongkir - b);
  }, [ongkir, b]);
  
  
  useEffect(() => {
    setTotalPrice1((qtyTotal)/((ppn/100)+1));
  }, [qtyTotal,ppn]);
  
    // hasil nominal dari PPN
useEffect(() => {
  setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
}, [qtyTotal,totaldiskon,ppn]);
  
    // hasil grandtotal
  useEffect(() => {
    setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew + ongkir1 - a );
  }, [totalPrice1, totaldiskon2, ppnnew, ongkir1, a]);

	useEffect(() => {
        getById3();
	}, [pajak]);

	const getById3 = () => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/pajak/${pajak}`,
	        { headers }
	      )
	      .then((data) => {;
	        setPPN(data.data.response.persentase);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

	const handleEditQty = (index, value) => {
		let updateList = savedItems;
		let minTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty) - parseInt(updateList[index].diskon_nominal);
		let plusTotal = parseInt(updateList[index].harga) * value - parseInt(updateList[index].diskon_nominal);
		let aqtyTotala = parseInt(updateList[index].harga) * value ;
		let aqtyTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty);
		let diskonnominal1 = parseInt(updateList[index].diskon_nominal) * value ;
		let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
		
		if(!isNaN(value)&&value.length>0){
			updateList[index] = {...updateList[index], qty: value};
			setSavedItems(updateList);
			setTotalPrice(totalPrice + plusTotal - minTotal);
			setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
			setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
		}else{return false}
	}

	const handleEditHarga = (index, value) => {
		if(!isNaN(value)&&value.length>0){
			let updateList = savedItems;
			let minTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty) - parseInt(updateList[index].diskon_nominal);
			let aqtyTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty);
			updateList[index] = {...updateList[index], harga: value};
			setSavedItems(updateList);
			let plusTotal = parseInt(updateList[index].qty) * value - parseInt(updateList[index].diskon_nominal);
			setTotalPrice(totalPrice + plusTotal - minTotal);
			let aqtyTotala = parseInt(updateList[index].qty) * value;
			setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
		}else{return false}
	}

	const handleEditDiskonNominal = (index, value) => {
		if(!isNaN(value)&&value.length>0){
			let updateList = savedItems;
			let diskonnominal1 = parseInt(updateList[index].qty) * value ;
			let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
			let persentasediskonnya = value / parseInt(updateList[index].harga) * 100;
			let aqtyTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty);
			let minTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty) - parseInt(updateList[index].diskon_nominal);
			updateList[index] = {...updateList[index], diskon_nominal: value, diskon_persen: persentasediskonnya};
			setSavedItems(updateList);
			let plusTotal = parseInt(updateList[index].qty) * parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal);
			setTotalPrice(totalPrice+plusTotal-minTotal);
			let aqtyTotala = parseInt(updateList[index].harga) * parseInt(updateList[index].qty);
			setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
			setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
		}else{return false}
	}

	const handleEditDiskonPersen = (index, value) => {
		if(!isNaN(value)&&value.length>0){
			let updateList = savedItems;
			let  nominaldiskonnya = parseInt(updateList[index].harga) * value/100;
			let minTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty) - parseInt(updateList[index].diskon_nominal);
			updateList[index] = {...updateList[index], diskon_nominal: nominaldiskonnya, diskon_persen: value};
			setSavedItems(updateList);
			let plusTotal = parseInt(updateList[index].qty) * parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal);
			setTotalPrice(totalPrice+plusTotal-minTotal);
			let qatyTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty);
			setTotalQty(qtyTotal + qatyTotal);
			let diskonnominal1 = parseInt(updateList[index].qty) * value ;
			let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
			setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2 )
		}else{return false}
	}
  
  const getPajak = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
      .then((data) => {
        setAllPajak(data.data.response);
        setPajak(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/cashier-retur/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        getCustomer(data.data.response.customer_id);
        getPajak(data.data.response.pajak_id);
        setTanggal(data.data.response.transaction_date)
        setCodeInvoice(data.data.response.code_nota);
        setOngkir(data.data.response.ongkir);
        setKeterangan(data.data.response.keterangan);
        setTotalPrice(data.data.response.price_real);
        setTotalQty(data.data.response.price_real);
        setCodeRetur(data.data.response.retur_code)
        setDiskonTotal(data.data.response.promo_total);
        setPaymentMethod(data.data.response.payment_method);
        setDiskonGlobalNominal(data.data.response.promo_total);
        getItemDataSaved();
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };

   function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga: dataItem.harga,
                diskon_nominal:dataItem.diskon_nominal,
                diskon_persen:dataItem.diskon_persen,
            }]);
    let data = {
      warehouse_id : parseInt(warehouse),
      username : username,
      code_nota: codeinvoice,
      pajak_id : parseInt(pajak),
      payment_method: parseInt(payment_method),
      status:1,
      status_ar1:3,
      status_ar2:3,
      status_d : 3,
      customer_id: parseInt(customer),
      ongkir:parseFloat(ongkir),
      keterangan: keterangan,
      retur_type: 1,
      items : dataItems,
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/cashier-retur/update/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
				  setTimeout(() => (history.push("/admin/kasir-retur/page")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
    }
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }

  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };

  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={CreateData}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };
  
    const hideAlert = () => {
      setAlert(null);
    };

const getCustomer = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/customer/list`,
        { headers }
      )
      .then((data) => {
        setCustomers(data.data.response);
        setCustomer(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const getItemDataSaved = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/cashier-retur/item`, {

      rcs_id: props.match.params.id

    }).then(async response => {
        let stateItem = [];

        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                satuan : data.satuan,
                qty: data.qty,
                qtysisa: data.max_qty,
                harga: data.harga,
                pembayaran: data.pembayaran,
                diskon_nominal : data.diskon_nominal,
                diskon_persen : data.diskon_persen,
                data: {
                    item_name: data.item_name,
                    harga: data.harga,
                    pembayaran: data.pembayaran,
                    diskon_nominal : data.diskon_nominal,
                    diskon_persen : data.diskon_persen,
                    qty: data.qty,
                    qtysisa: data.max_qty,
                },
            }];
        }));
        setSavedItems(stateItem);
    })
}


const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}

  const handleSubmit = (e) => {
    {
      CreateData();
    }
  };

  const deleteItem = (id) => {
		let array = [...savedItems];

		let index = array.findIndex(object => {
			return object.item_id === id;
		});

		if (index !== -1) {
			setTotalPrice(totalPrice - array[index].harga * array[index].qty - array[index].diskon_nominal);
			setTotalQty(qtyTotal - array[index].harga * array[index].qty);
			setDiskonTotal(diskontotal - array[index].diskon_nominal * array[index].qty);
			array.splice(index, 1);
			setSavedItems(array);
		}
	}

  return (
    <>
    {alert}
    {ReturSoKasirs && ReturSoKasirs === "1" ? (
      <div>
    <SimpleHeader name="Edit Retur Penjualan" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <Card className="bg-secondary shadow">
                <Form onSubmit={handleSubmit}>
                    <CardHeader className="bg-white border-0">
                      <h3>Edit Retur Penjualan</h3>
                    </CardHeader>
                    <CardBody>
                  <Row md="12">
                      <Col md="6">
                      <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Tanggal
                          </Label>
                          <Col sm={7}>
                            <Input
                              className="form-control-alternative"
                              disabled
                              type="text"
                              name="desc"
                              placeholder="Masukan Tanggal"
                              value={tanggal}
                            />
                          </Col>                             
                        </FormGroup>
                      <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Kode Retur
                          </Label>
                          <Col sm={7}>
                            <Input
                              className="form-control-alternative"
                              disabled
                              type="text"
                              name="desc"
                              placeholder="Masukan Ongkir"
                              value={coderetur}
                            />
                          </Col>                             
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Kode Nota
                          </Label>
                          <Col sm={7}>
                            <Input
                              className="form-control-alternative"
                              disabled
                              type="text"
                              name="desc"
                              placeholder="Masukan Kode Nota"
                              value={codeinvoice}
                            />
                          </Col>                             
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Customer
                          </Label>
                          <Col sm={7}>
                          <Input
                          disabled
                          className="form-control-alternative"
                              type="select"
                              value={customer}
                              onChange={(e) => {
                                setCustomer(e.target.value);
                              }}
                            >
                              <option value='' disabled hidden selected>Pilih Customer</option>
                              {customers.map((customer, key) => {
                                  return (
                                    <option key={key} value={customer.id}>
                                      {customer.name}
                                    </option>
                                  );
                                })}
                              </Input>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Label
                            className="form-control-label" for="exampleEmail" sm={4}>
                                PPN
                            </Label>
                            <Col sm={7}>
                                <Input
                                    disabled
                                    className="form-control-alternative"
                                    type="select"
                                    value={pajak}
                                    onChange={(e) => {
                                        setPajak(e.target.value);
                                }}>
                            <option value='' disabled selected hidden>Pilih PPN</option>
                              {allpajak.map((warehouse2, key) => {
                                return (
                                    <option key={key} value={warehouse2.id}>
                                      {warehouse2.keterangan}
                                    </option>
                                );
                              })}
                            </Input>
                        </Col>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                      <FormGroup row>
                        <Label
                        className="form-control-label"
                          for="exampleEmail"
                          sm={4}
                        >
                          Metode Pembayaran
                        </Label>
                        <Col sm={7}>
                        <Input
                        disabled
                        className="form-control-alternative"
                            name="Tipe Po"
                            type="select"
                            value={payment_method}
                            onChange={(e) => {
                                setPaymentMethod(e.target.value);
                            }}
                          >
                            <option value={""}>Pilih Metode Pembayaran</option>
                            <option value={1}>Lunas</option>
                            <option value={3}>Indent DP</option>
                            <option value={4}>Indent Lunas</option>
                          </Input>
                        </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Ongkir
                          </Label>
                          <Col sm={7}>
                            <Input
                              className="form-control-alternative"
                              disabled
                              type="text"
                              name="desc"
                              placeholder="Masukan Ongkir"
                              value={ongkir}
                              onChange={(e) => {
                                setOngkir(e.target.value);
                              }}
                            />
                          </Col>                             
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={4}
                          >
                            Keterangan
                          </Label>
                          <Col sm={7}>
                            <Input
                              className="form-control-alternative"
                              type="textarea"
                              name="desc"
                              rows = "4"
                              placeholder="Masukan Keterangan"
                              value={keterangan}
                              onChange={(e) => {
                                setKeterangan(e.target.value);
                              }}
                            />
                          </Col>                             
                        </FormGroup>
                      </Col>
                  </Row>
                </CardBody>
                </Form>
              </Card>
              <Card className="bg-secondary shadow">
                <Form onSubmit={handleQtySisa}>
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                        <CardBody>
                        <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Harga</b></th>
                                <th><b>Qty&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Diskon %</b></th>
                                <th><b>Diskon (N)</b></th>
                                <th><b>Sub Total</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td>
                                      
                                      {editable ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon Persen"
                                            type="number"
                                            value={savedItem.harga}
                                            onChange={(e) => {
                                              handleEditHarga(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{formatRupiah(savedItem.harga)}</>
                                        )}
                                      
                                      </td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="qty"
                                          type="number"
                                          invalid={qtyError === "invalid"}
                                          value={savedItem.qty}
                                          onChange={(e) => {
                                            handleEditQty(key, e.target.value);
                                            if (savedItem.qty > savedItem.qtysisa) {
                                              setQtyError("");
                                            }
                                          }}
                                        />
                                           <FormFeedback>
                                                Max Barang {savedItem.qtysisa} 
                                            </FormFeedback>
                                      </td>
                                       <td>{savedItem.satuan}</td>
                                      <td>
                                        {editable ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon Persen"
                                            type="number"
                                            value={savedItem.diskon_persen}
                                            onChange={(e) => {
                                              handleEditDiskonPersen(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{savedItem.diskon_persen}</>
                                        )}
                                      </td>
                                      <td>
                                        {editable ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon nominal"
                                            type="number"
                                            value={savedItem.diskon_nominal}
                                            onChange={(e) => {
                                              handleEditDiskonNominal(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{formatRupiah(savedItem.diskon_nominal)}</>
                                        )}
                                      </td>
                                      <td>
																{formatRupiah(((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) / (ppn/100 + 100/100))}

                                        {/* {formatRupiah((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty)} */}
                                      </td>
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter>
                          <Row md="12">
                            <Col md="6">
                            </Col>
                            <Col md="2">
                            </Col>
                            <Col md="4">
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                                size="small"
                              >
                                <b>Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Harga Total"
                                value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Diskon</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                style={{fontWeight: 'bold'}}
                                disabled
                                type="text"
                                name="barcode"
                                placeholder="Diskon"
                                value={"Rp." + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>PPN</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Ongkir</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Grand Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                type="text"
                                name="barcode"
                                style={{fontWeight: 'bold'}}
                                placeholder="Grand Total"
                                value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardFooter>
                        <CardFooter>
                              <Link className="btn btn-info" to="/admin/kasir-retur/page">
                                Kembali
                              </Link>
                              <Button color="danger" type="submit">
                                  Simpan
                              </Button>
                        </CardFooter>
                </Form>
              </Card>
          </div>
        </Row>
    </Container>  
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}

export default EditSoReturKasirBaru;

