/*eslint-disable*/
import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Label,
  FormGroup,
  Row,
  Col,
  Input,
  Container,
  CardHeader,
  CardFooter,
  Button,
  Form,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";


export default function EditDurasiOperasional(props) {
  const token = localStorage.token;
  let history = useHistory();
  const [durasipo,setDurasiPo] = useState("");
  const [alert, setAlert] = React.useState(null);
  const allInfo = JSON.parse(localStorage.allinfo);
  const batasoperasional = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Durasi Operasional").map((p) => p.read_access));
  

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/durasi-operasional/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setDurasiPo(data.data.response.durasi);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function EditData() {
    let data = {
      durasi: parseInt(durasipo),
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/durasi-operasional/update/${props.match.params.id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (response) {
        setSuccessAlert(response.data.message);
        setTimeout(() => (history.push("/admin/durasi-operasional")), 2000);
        })
        .catch(function (error) {
          setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={EditData}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  }

  const handleSubmit = (e) => {
    {
      EditData();
    }
  };
  return (
    <>
    {alert}
    {batasoperasional && batasoperasional === "1" ? (
			<div>
      <SimpleHeader name="Edit Batas Operasional" parentName="Master" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-secondary shadow">
                <Form onSubmit={handleSubmit}>
                  <CardHeader className="bg-white border-0">
                    <h3>Edit Batas Operasional</h3>
                  </CardHeader>
                  <CardBody>
                    <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Durasi
                              </Label>
                              <Col sm={4}>
                                <Input
                                autoComplete="off"
                                  type="number"
                                  name="desc"
                                  placeholder="Masukan Durasi"
                                  value={durasipo}
                                  onChange={(e) => {
                                    setDurasiPo(e.target.value);
                                  }}
                                />
                              </Col>
                              <Col sm={3}>
                                <Label
                                className="form-control-label"
                                    for="exampleEmail"
                                    sm={10}
                                >
                                    <b>Hari</b>
                                </Label>
                              </Col>                             
                            </FormGroup>
                          </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Link className="btn btn-info" to="/admin/durasi-operasional">
                      Kembali
                    </Link>
                    <Button color="danger" onClick={setQuestionAlert}>
                        Simpan
                    </Button>
                  </CardFooter>
                </Form>
            </Card>
          </div>
        </Row>
      </Container>
      </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}
