/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Container,
    ButtonGroup, 
    Form, 
    Badge,
    FormGroup, 
    Label, 
    Input ,
    DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle,
    Modal, ModalHeader, ModalBody

} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ModalCetakReturPo from './ModalCetakReturPo';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from 'views/404';
import Select2 from "react-select2-wrapper";


const HistoriReturPo = () => {
  const [tanggal, setTanggal] = useState("");
  const [person, setPerson] = useState("")
  const [persons, setPersons] = useState([]);
  const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle2 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
  const token = localStorage.token;
  const warehouse = parseInt(localStorage.warehouse);
  const redirectPrefix1 = `/admin/po-retur/histori/detail/`;
  const redirectPrefix2 = `/admin/po-retur/payment-bkm/`;
  const [allPoRetur, setAllPoRetur] = useState([]);
  const [uomCode, setUomCode] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [openModalCetak, setOpenModalCetak] = useState(false);
  const [dataModalCetak, setDataModalCetak] = useState({ id: 0,});
  const toggle = () => setOpenModalCetak(!openModalCetak);
  const allInfo = JSON.parse(localStorage.allinfo);
  const returPOs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Retur PO").map((p) => p.read_access));
  const cetak = String(allInfo.privileges.filter((i) => i.privilege_name === "Tab Cetak Retur PO").map((p) => p.create_access));
    
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, tanggal,  uomCode, person);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort, tanggal,  uomCode, person)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, tanggal,  uomCode, person) => {
    getPoRetur(page, perPage, sort, tanggal,  uomCode, person);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setTanggal(tanggal);
    setUomCode(uomCode);
    setPerson(person);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, tanggal,  uomCode, person)
    }
  }

  
  useEffect(() => {
    getPoRetur(page, perPage, currentSort);
    getPerson()
  }, []);

  const getPerson = () => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/person/list`, { headers
    })
    .then(data => {
      setPersons(data.data.response);
    })
      .catch(function (error) {
        console.log(error)
      })
  }

  // fungsi dari ambil data
  const getPoRetur = (page, perPage, currentSort, transaction_date=null, retur_code=null, person_id=null) => {
    
    let filter = { 
      
      page: page, 
      per_page: perPage,
      // status_ar:5,
      // status_d:5,
      warehouse_id : parseInt(warehouse),
      
    };
    if (transaction_date !== null) {
      filter = Object.assign(filter, { transaction_date: transaction_date });
    }
    if (retur_code !== null) {
      filter = Object.assign(filter, { retur_code: retur_code });
    }
    if (person_id !== null) {
      filter = Object.assign(filter, { person_id: person_id });
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/po-retur/page`, data, {
        headers,
      })
      .then((data) => {
        setAllPoRetur(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        // if(error.response.data.status == 404){
          setAllPoRetur(error.response.data.response);
            // }
      });
}


  const reset = () => {
    setTanggal("")
    setUomCode("");
    setPerson("")
    updateDataTable(1, perPage, currentSort, "", "");
  }

  const displayModalCetak = (id) => {
    setDataModalCetak({
      id: id,
    });
    setOpenModalCetak(true);
  };

  const getDataLog = (id) => {
		let filter = { 
			transaction : id,
			features : "BELI7", 
			warehouse_id : warehouse
		}
		const data = filter;
		const headers = {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
			setDataLog(data.data.response);
            setModal1(!modal1);

		})
		  .catch(function (error) {
			setGagalAlert(error.response.data.message);
		  })
	  }

	  const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
		);
		};
		
		const hideAlert = () => {
		  setAlert(null);
		};

  

  return (
    <div>
      {alert}
      {returPOs && returPOs === "1" ? (
			<div>
      <SimpleHeader name="Histori" parentName="" />
        <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ textAlign: 'left' }}>
                      <Link className="btn btn-link" to="/admin/po-retur">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                      </Link>
                    </div>
                </div>
              </CardHeader>
              <CardBody>
                      <Form>
                        <Row md="12">
                        <Col md="3">
                            <FormGroup>
                              <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect3"
                            >Tanggal</Label>
                              <Input
                              className="form-control-alternative"
                                type="date"
                                placeholder="Masukan Kode BPB"
                                value={tanggal}
                                onChange={e => updateDataTable(1, perPage, currentSort, e.target.value,uomCode, person)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                            <Label
                                className="form-control-label"
                                htmlFor="exampleFormControlSelect3"
                            >Kode Retur</Label>
                              <Input
                                className="form-control-alternative"
                                type="text"
                                placeholder="Masukan Kode Retur"
                                value={uomCode}
                                onChange={e => updateDataTable(1, perPage, currentSort,tanggal, e.target.value, person)}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                      <FormGroup>
                              <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >
                                  Supplier
                              </Label>
                                      <Select2
                                        className="form-control-alternative"
                                        defaultValue="0"
                                        value={person}
                                        onChange={(e) =>
                                          updateDataTable(1, perPage, currentSort, tanggal, uomCode , e.target.value)
                                          }
                                        options={{
                                          placeholder: "Pilih Supplier"
                                        }}
                                        data={persons.map((person, key) => {
                                          return (
                                          { id: person.id, text: person.person_name, key:key}
                                          );
                                        })}
                                      />
                              </FormGroup>
                      </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button type="button" onClick={reset} color="secondary">Reset</Button>
                          </Col>
                        </Row>
                      </Form>
                      {allPoRetur === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                  dataField: "",
                                  text: "Tanggal",
                                  sort: true,
                                },
                                {
                                    dataField: "",
                                    text: "Kode Retur",
                                    sort: true,
                                },
                                {
                                  dataField: "",
                                  text: "Supplier",
                                  sort: true,
                              },
                                {
                                    dataField: "",
                                    text: "Keterangan",
                                    sort: true,
                                },
                                {
                                  dataField: "",
                                  text: "Status",
                                  sort: true,
                                 
                              },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                        </div>
                        ):(
                    <ToolkitProvider
                            rowNumber={rowIndex}
                            data={allPoRetur}
                            keyField="id"
                            columns={[
                            {
                                dataField: "no",
                                text: "#",
                                sort: true,
                                page: 1,
                                formatter: (cell, row, index) => {
                                let currentRow = ++index;
                                return currentRow + rowIndex;
                                },
                            },
                            {
                              dataField: "transaction_date",
                              text: "Tanggal",
                              sort: true,
                            },
                            {
                                dataField: "retur_code",
                                text: "Kode Retur",
                                sort: true,
                                formatter: (cell, row) => {
                                  return row.retur_code === ""
                                    ? <h2>
                                      <Badge color="warning" >
                                        {row.retur_code}
                                      </Badge>
                                      </h2>
                                    : 
                                    <h2>
                                    <Badge color="warning" >
                                        {row.retur_code}
                                      </Badge>
                                      </h2>
                                },
                            },
                            {
                              dataField: "person_name",
                              text: "Supplier",
                              sort: true,
                          },
                            {
                                dataField: "keterangan",
                                text: "Keterangan",
                                sort: true,
                            },
                            {
                              dataField: "status_ar",
                              text: "Status kepala Purchasing",
                              sort: true,
                              formatter: (cell, row) => {
                                return row.status_ar === 3
                                  ? 
                                    <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-danger" />
                                      Proses
                                    </Badge>
                                  : row.status_ar === 4
                                  ? <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-warning" />
                                      Tolak
                                    </Badge>
                                  : <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-success" />
                                      Setuju
                                    </Badge>;
                              },
                          },
                          {
                            dataField: "status_d",
                            text: "Status Office Manager",
                            sort: true,
                            formatter: (cell, row) => {
                              return row.status_d === 3
                                ? 
                                  <Badge color="" className="badge-dot mr-4">
                                    <i className="bg-danger" />
                                    Proses
                                  </Badge>
                                : row.status_d === 4
                                ? <Badge color="" className="badge-dot mr-4">
                                    <i className="bg-warning" />
                                    Tolak
                                  </Badge>
                                : <Badge color="" className="badge-dot mr-4">
                                    <i className="bg-success" />
                                    Setuju
                                  </Badge>;
                            },
                        },
                        {
                          dataField: "",
                          text: "",
                          formatter: (cell, row, index) => {
                            return row.status_d === 3
                              ? <UncontrolledDropdown nav>
                                  <DropdownToggle color="danger">
                                      Tindakan
                                    </DropdownToggle>
                                    <DropdownMenu center>
                                    <Link  to={redirectPrefix1 + row.id}
                                        id={"tooltip_" + row.id}>
                                      <DropdownItem>
                                        <i className="fas fa-user-edit" /><span>Detail</span>
                                      </DropdownItem>
                                      </Link>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                              : row.status_d === 4
                              ?  <UncontrolledDropdown nav>
                              <DropdownToggle color="danger">
                                  Tindakan
                                </DropdownToggle>
                                <DropdownMenu center>
                                <Link  to={redirectPrefix1 + row.id}
                                        id={"tooltip_" + row.id}>
                                      <DropdownItem>
                                        <i className="fas fa-user-edit" /><span>Detail</span>
                                      </DropdownItem>
                                      </Link>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                              : <UncontrolledDropdown nav>
                              <DropdownToggle color="danger">
                                  Tindakan
                                </DropdownToggle>
                                <DropdownMenu center>
                                <Link  onClick={() => getDataLog(row.id)}>
                                                <DropdownItem>
                                                <i className="fa fa-info-circle" aria-hidden="true"></i> <span>Info</span>
                                                </DropdownItem>
                                            </Link>
                                      <Link  to={redirectPrefix1 + row.id}
                                        id={"tooltip_" + row.id}>
                                      <DropdownItem>
                                        <i className="fas fa-user-edit" /><span>Detail</span>
                                      </DropdownItem>
                                      </Link>
                                      {/* <Link  to={redirectPrefix2 + row.id}
                                        id={"tooltip_" + row.id}>
                                      <DropdownItem>
                                        <i className="fas fa-user-edit" /><span>Payment</span>
                                      </DropdownItem>
                                      </Link> */}
                                      {cetak && cetak === "1" && (
                                      <DropdownItem
                                        onClick={() => displayModalCetak(row.id)}
                                      >
                                        <i className="fas fa-print" /><span>Cetak</span>
                                      </DropdownItem>
                                      )}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                          },
                        },
                            ]}
                        >
                            {(props) => (
                            <div className="py-4 table-responsive">
                                <BootstrapTable
                                remote
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                                hover={true}
                                pagination={paginationFactory({ ...paginationOption })}
                                onTableChange={handleTableChange}
                                />
                            </div>
                            )}
                    </ToolkitProvider>
                        )}
              </CardBody>
            </Card>
          </div>
        </Row>
        {openModalCetak && (
        <ModalCetakReturPo
          open={openModalCetak}
          data={dataModalCetak}
          toggle={toggle}
        />
      )}
      </Container>
      <Modal isOpen={modal1} toggle={toggle2} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle2} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle2}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>
      </div>
      ):(
        <Halaman404 />
      )}
    </div>
  );
}

export default HistoriReturPo;