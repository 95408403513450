/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import SweetAlert from "react-bootstrap-sweetalert";


const EditFinanceBbkRetur = (props) => {
    const [alert, setAlert] = React.useState(null);
    const token = localStorage.token;
    const username = localStorage.username;
    const warehouse = localStorage.warehouse;
    let history = useHistory();
    const [persons, setPersons] = useState([]);
    const [person, setPerson] = useState("");
    const [keterangan, setKeterangan] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);
    const [savedItems, setSavedItems] = useState([]);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [isSearchShoww, setIsSearchShoww] = useState(false);
	const [allItemm, setAllItemm] = useState([]);
	const [queryy, setQueryy] = useState("");
    const headers = { Authorization: `Bearer ${token}`};
    const [bank, setBank] = useState("");
    const [banks, setBanks] = useState([]);
	const [qtyTotal, setTotalQty] = useState(0);
    const [account, setAccount] = useState("");
    const [accounts, setAccounts] = useState([]);
    const [codebkm, setCodeBkm] = useState("")

    useEffect(() => {
        getById();
      }, []);
    
      const getById = () => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/bbk/get/${props.match.params.id}`,
            { headers }
          )
          .then((data) => {
            setCodeBkm(data.data.response.bbk_code)
            getPerson(data.data.response.customer_id);
            // setTanggal(moment(data.data.response.created_at, "DD/MM/YYYY").format("YYYY-MM-DD"));
            getbank(data.data.response.payment_method);
            setKeterangan(data.data.response.keterangan1);
            getItemDataSaved();
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      
  const getItemDataSaved = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/bbk/item`, {

        bbk_id: props.match.params.id

    }).then(async response => {
        let stateItem = [];

        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
                warehouse_id: data.warehouse_id,
                code_retur : data.code_retur,
                // tgl_tempo : data.tgl_tempo,
                payment_total : data.payment_total,
                keterangan2 : data.keterangan2,
				potongan: data.potongan,
                bayar : data.bayar,
                data: {
                    warehouse_id: data.warehouse_id,
                    code_retur : data.code_retur,
                    payment_total : data.payment_total,
                    tgl_tempo : data.tgl_tempo,
                    keterangan2 : data.keterangan2,
                    potongan: data.potongan,
                    bayar : data.bayar,
                },
            }];
        }));
        setSavedItems(stateItem);
    })
}

    useEffect(() => {
		getCoa();
	}, []);

    const getCoa = () => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/account/list/${warehouse}`, { headers })
          .then((data) => {
            setAccounts(data.data.response);
          })
          .catch(function (error) {
            console.log(error);
          });
      };

  useEffect(() => {
    const getData = setTimeout(async() => {
        if(queryy != ''){
            axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/so-retur/page`, { code_retur: queryy , per_page: 10,warehouse_id: parseInt(warehouse) , active_flag:1, customer_id:person},{ headers })
            .then((res) => {
                setAllItemm(res.data);
                setIsSearchShoww(true);
            })
            .catch(function (error) {
            });
        }else{
      setAllItemm(null);
        }
      }, 500)
      return () => clearTimeout(getData)
  }, [queryy]);


  const handlePotongan = (index, value) => {
    let updateList = savedItems;
    let aqtyTotal = parseInt(updateList[index].harga) + value;
    if(!isNaN(value) && value.length > -1){
      updateList[index] = {...updateList[index], potongan: value};
      setSavedItems(updateList);
      setTotalQty(qtyTotal + aqtyTotal);
    }else{return false}
  }

  const handleBayar = (index, value) => {
    let updateList = savedItems;
    let aqtyTotal = parseInt(updateList[index].potongan) + value;
    if(!isNaN(value) && value.length > -1){
      updateList[index] = {...updateList[index], bayar: value};
      setSavedItems(updateList);
      // setTotalPrice(totalPrice+plusTotal-minTotal);
      setTotalQty(qtyTotal + aqtyTotal);
    }else{return false}
  }

	// save
	const saveItemOrUpdate = (item) => {
		let oldobj = savedItems.find(o => o.code_retur === item.retur_code);
		if(oldobj===undefined){
			setSavedItems([...savedItems,{
                code_retur: item.retur_code,
                tgl_tempo: item.tgl_tempo,
                payment_total : item.payment_total,
                keterangan2 : item.keterangan,
                potongan: 0,
                bayar : item.payment_total
			}]);
			return setTotalPrice(totalPrice * 1 )
		}else{
			let index = savedItems.findIndex(e=>e.code_retur===item.retur_code);
			let updateList = savedItems;
      let qtyupdate = parseInt(updateList[index].bayar);
			updateList[index] = {...updateList[index], bayar: qtyupdate};
			setSavedItems(updateList);
			return setTotalPrice(parseInt(updateList[index].payment_total) * qtyupdate)
		};
	}
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    {
      setQueryy("");
      setIsSearchShoww("");
      return true
    }
  };
  
    const deleteItem = (id) => {
      let array = [...savedItems];
  
      let index = array.findIndex(object => {
        return object.code_retur === id;
      });
  
      if (index !== -1) {
        array.splice(index, 1);
        setSavedItems(array);
      }
    }


  const getPerson = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/customer/list`,
        { headers }
      )
      .then((data) => {
        setPersons(data.data.response);
        setPerson(id)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getbank = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/bank/get-by-wh/${warehouse}`,
        { headers }
      )
      .then((data) => {
        setBanks(data.data.response);
        setBank(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                warehouse_id: dataItem.warehouse_id,
                code_retur : dataItem.code_retur,
                tgl_tempo : dataItem.tgl_tempo,
                payment_total : dataItem.payment_total,
                keterangan2 : dataItem.keterangan2,
				potongan: dataItem.potongan,
                bayar : dataItem.bayar,
            }]);
    let data = {
      warehouse_id : parseInt(warehouse),
      account_id : parseInt(bank),
      username : username,
      customer_id : parseInt(person),
      payment_method : parseInt(bank),
      keterangan1 : keterangan,
      status_af :3,
      status_d :3,
      // created_at : moment(tanggal).format("YYYY-MM-DD 00:00:00"),
      list : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/bbk/update/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
            setSuccessAlert(response.data.message);
            setTimeout(() => (history.push("/admin/bk-retur/benk/page")), 1000);
          })
          .catch(function (error) {
                setGagalAlert(error.response.data.message);
          });
    }
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }
  
  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };
  
  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={CreateData}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };
  
    const hideAlert = () => {
      setAlert(null);
    };

const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}
  
  return (
    <>
    {alert}
    <SimpleHeader name="Edit BBK Retur" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col"> 
                <CardBody>
                <Card className="bg-secondary shadow">
                          <CardHeader className="bg-white border-0">
                              <h3>Edit BBK Retur</h3>
                          </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Kode
                              </Label>
                              <Col sm={7}>
                                <Input
                                disabled
                                  className="form-control-alternative"
                                  type="text"
                                  name="desc"
                                  placeholder="Masukan Keterangan"
                                  value={codebkm}
                                  onChange={(e) => {
                                    setCodeBkm(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                             <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Customer
                              </Label>
                              <Col sm={7}>
                                <Select2
                                disabled
                                      className="form-control-alternative"
                                      defaultValue="0"
                                      value={person}
                                      onChange={(e) => {
                                        setPerson(e.target.value);
                                      }}
                                      options={{
                                        placeholder: "Pilih Customer"
                                      }}
                                      data={persons.map((person, key) => {
                                        return (
                                        { id: person.id, text: person.name, key:key}
                                        );
                                      })}
                                    />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                    sm={4}
                                >
                                    Bank
                                </Label>
                                <Col sm={7}>
                                <Input
                                autoComplete="off"
                                    name="coa"
                                    type="select"
                                    value={bank}
                                    onChange={(e) => {
                                    setBank(e.target.value);
                                    }}
                                >
                                    <option value="" disabled selected hidden>Pilih Bank</option>
                                    {banks.map((coa, key) => {
                                    return (
                                        <option key={key} value={coa.id}>
                                        {coa.bank_name} {coa.account_number} {coa.account_name}
                                        </option>
                                    );
                                    })}
                                </Input>
                                </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                             <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Keterangan
                              </Label>
                              <Col sm={7}>
                                <Input
                                className="form-control-alternative"
                                
                                  type="textarea"
                                  name="desc"
                                  rows = "4"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                </Card>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Retur</h3>
                    </CardHeader>
                        <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th hidden><b>Cabang</b></th>
                                <th><b>Kode Retur</b></th>
                                <th><b>Tempo</b></th>
                                <th><b>Tagihan</b></th>
                                <th><b>Keterangan</b></th>
                                <th><b>Potongan</b></th>
                                <th><b>Bayar</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td hidden>{savedItem.warehouse_id}</td>
                                      <td>{savedItem.code_retur}</td>
                                      <td>{savedItem.tgl_tempo}</td>
                                      <td>{formatRupiah(savedItem.payment_total)}</td>
                                      <td>{savedItem.keterangan2}</td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="Rp 0"
                                          type="number"
                                          value={savedItem.potongan}
                                          onChange={(e) => {
                                              handlePotongan(key, e.target.value);
                                          }}/>
                                        </td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="Rp 0"
                                          type="number"
                                          value={savedItem.bayar}
                                          onChange={(e) => {
                                              handleBayar(key, e.target.value);
                                          }}/>
                                        </td>
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.code_retur)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              <tr>
                                <td>
                                  <><Form onSubmit={handleSubmit}>
                                          <Input
                                            autoComplete="off"
                                            placeholder="Masukan Kode Retur"
                                            type="search"
                                            // onKeyDown={searchh}
                                            value={queryy}
                                            onChange={(e) => setQueryy(e.target.value)}
                                          />
                                        {isSearchShoww && queryy && (
                                        <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                          <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                            <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
                                          </div>
                                          {allItemm?.response ? (
                                            allItemm.response.map((item) => (
                                              <CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {saveItemOrUpdate(item);setQueryy('');setIsSearchShoww(false);}}>
                                                <div>
                                                  <b>Kode:</b> {item.retur_code}
                                                </div>
                                              </CardBody>
                                            ))
                                          ) : (
                                            <div className="text-center mb-3 text-danger">Akun "{queryy}" tidak ada bosku!</div>
                                          )}
                                        </Card>
                                      )}
                                  </Form></>
                                  </td>
                                 </tr>
                              </tbody>
                          </Table>
                        </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/bk-retur/benk/page">
                        Kembali
                      </Link>
                      <Button color="danger" onClick={()=>setQuestionAlert()}>
                          Simpan
                      </Button>
                </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>
    </>
  );
}

export default EditFinanceBbkRetur;