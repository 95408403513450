import React, { useCallback,useEffect, useState } from "react";
import {Button,Row,Col,CardBody,Table,Modal,ModalHeader,ModalBody,Label} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";
// import * as FileSaver from "file-saver";
// import SweetAlert from "react-bootstrap-sweetalert";
// import styles from '../../../../../assets/scss/Print.scss'
import moment from "moment";
import ExcelJS from 'exceljs';
import { saveAs } from "file-saver";
import SweetAlert from "react-bootstrap-sweetalert";

const ModalCetakPo = ({ open, toggle, data }) => {
    // const [alert, setAlert] = React.useState(null);
    const token = localStorage.token;
    const [alert, setAlert] = React.useState(null);
    const username = localStorage.username;
    const [address,setAddress] = useState("");
    const [counter,setCounter] = useState("");
    const [creator,setCreator] = useState("");
    const [keterangan,setKeterangan] = useState("");
    const [codereceiving,setCodeReceiving] = useState("");
    const [supplier,setSupplier] = useState("");
    const [transactiondate,setTransactionDate] = useState("");
    const [validator1,setValidator1] = useState("");
    const [warehouse,setWarehouse] = useState("");
    const [savedItems, setSavedItems] = useState([]);
    // const [item, setItem] = useState("")
    
    
  

  const handleDownload = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      // Mengambil data dari API
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/receiving-po/cetak-checker/${data.id}`, {headers});
      const responseData = response.data.response;
      const { address } = responseData.receivingpo; // Mengambil address dari receivingpo
      const { supplier } = responseData.receivingpo; // Mengambil address dari receivingpo
      const list = responseData.list; // Mengambil data list dari response
  
      // Membaca file Excel yang diunggah
      const fileResponse = await fetch("https://api-erp.hokkybangunan.com/bpb.xlsx");
      const arrayBuffer = await fileResponse.arrayBuffer();
  
      // Load workbook
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);
  
      const worksheet = workbook.getWorksheet(1); // Mengambil worksheet pertama
  
      // Menulis alamat ke sel I12
      worksheet.getCell('I12').value = address;
      worksheet.getCell('I11').value = supplier;
  
      // Menulis data dari list ke dalam worksheet
      list.forEach((item, index) => {
        const rowNumber = 23 + index; // Mulai dari baris ke-15
        worksheet.getCell(`D${rowNumber}`).value = item.item_code;  // Kolom A untuk item_code
        worksheet.getCell(`B${rowNumber}`).value = item.item_name;  // Kolom B untuk item_name
        worksheet.getCell(`G${rowNumber}`).value = item.qty;        // Kolom C untuk qty
        worksheet.getCell(`H${rowNumber}`).value = item.satuan;     // Kolom D untuk satuan
      });
  
      // Menyimpan workbook ke buffer
      const buffer = await workbook.xlsx.writeBuffer();
  
      // Membuat blob dari buffer dan mengunduh file
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      saveAs(blob, "BPBCOK.xlsx");
  
      // Tampilkan alert sukses
      setAlert(
        <SweetAlert
          success
          title="Berhasil"
          onConfirm={() => setAlert(null)}
        >
          File Excel berhasil diunduh!
        </SweetAlert>
      );
    } catch (error) {
      console.error("Error", error);
      setAlert(
        <SweetAlert
          danger
          title="Error"
          onConfirm={() => setAlert(null)}
        >
          Terjadi kesalahan. Silakan coba lagi.
        </SweetAlert>
      );
    }
  };

  const getById = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/receiving-po/cetak-admin/${data.id}`,
        { headers }
      )
      .then((data) => {  
        setAddress(data.data.response.receivingpo.address)
        setCounter(data.data.response.receivingpo.counter)
        setCreator(data.data.response.receivingpo.creator)
        setKeterangan(data.data.response.receivingpo.keterangan)
        setCodeReceiving(data.data.response.receivingpo.receiving_code)
        setSupplier(data.data.response.receivingpo.supplier)
        setTransactionDate(data.data.response.receivingpo.transaction_date)
        setValidator1(data.data.response.receivingpo.validator1)
        setWarehouse(data.data.response.receivingpo.warehouse)
        // setItem(data.data.response.list.length);

      })
      .catch(function (error) {
        console.log(error);
      });
  },[token, data.id]);

  const HitCounter = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/receiving-po/hit-counter-admin/${data.id}`,
        { headers }
      )
      .then((data) => {  
        console.log("suskes Cuk");
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const getItemDataSaved = useCallback(() => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/receiving-po/cetak-admin/${data.id}`,{ headers }
    
    ).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.list.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                qty: data.qty,
                satuan: data.satuan,
                note : data.note,
                data: {
                    item_id: data.item_id,
                    item_name:data.item_name,
                    item_code:data.item_code,
                    qty: data.qty,
                    satuan: data.satuan,
                },
            }];
        }));

        setSavedItems(stateItem);
    })
},[token, data.id])

useEffect(() => {
  getById();
  getItemDataSaved();
}, [getById,getItemDataSaved]);

// const downloadExcel = async ()=> {
//     const J = "BPB-PO"
//     const fileName = J;
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     };
//     axios
//       .get(`${process.env.REACT_APP_API_BASE_URL}/receiving-po/cetak-admin/${data.id}`,{headers})
//       .then((data) => {
//         setAddress(data.data.response.receivingpo.address)
//         setCounter(data.data.response.receivingpo.counter)
//         setCreator(data.data.response.receivingpo.creator)
//         setKeterangan(data.data.response.receivingpo.keterangan)
//         setCodeReceiving(data.data.response.receivingpo.receiving_code)
//         setSupplier(data.data.response.receivingpo.supplier)
//         setTransactionDate(data.data.response.receivingpo.transaction_date)
//         setValidator1(data.data.response.receivingpo.validator1)
//         setWarehouse(data.data.response.receivingpo.warehouse)
//         setItem(data.data.response.list.length);

//         var apiPo = data.data.response.list;
//         // var tglcetak = data.data.response.download_date;
//         var tableToExcel = (function() {
//           // var uri = 'data:application/vnd.ms-excel;base64,',
//           var template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
//             // base64 = function(s) {
//             //   return window.btoa(unescape(encodeURIComponent(s)))
//             // },
//             format = function(s, c) {
//               return s.replace(/{(\w+)}/g, function(m, p) {
//                 return c[p];
//               })
//             }
//           return function(table, name) {
//             var heading = 'BPB Pembelian' ;
//             var imgsrc1 = 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhAVKx5R3RdjeXQuRdKan2RNLsZn2U4qXYOgU4jqILz6u6MLSzlvzY1b5x9Xiz4sKHhM0UJ1NKKoFVx6ZEI8JqgANlrZ8KwCJ2j9pOmJN-e50-HzVhTFRvEahjCJB51O4oMmJ25V2yQtYOGfxV2b7C2aT9VKBruh0_znTbORz66pu9P47DMB5aP4SuF/s320/Hokky1.png';
//             var po = '';
//             var a ;
//             for(a=0; a < apiPo.length; a++){
//               po += `
//               <tr style="border: 1px solid black">
//                   <td colspan="5">${apiPo[a].item_name}</td>
//                   <td>${apiPo[a].item_code}</td>
//                   <td>${apiPo[a].qty}</td>
//                   <td>${apiPo[a].satuan}</td>
//               </tr>
//               `
//             }
//             var table = `
//             <table id="account_table">
//                 <tbody>
//                 <tr>
//                     <td><img src="https://i.ibb.co/jkFYDqg/150x75.png"/> </td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>${warehouse}</td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Jl Menganti Karangan No.562</td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Wiyung surabaya barat - Jawa Timur </td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Telp: 081 217 85 3300 </td>
//                 </tr>
//                 <tr>
//                     <td colspan="8"><center><b><h1>BPB Pembelian</h1></b><center></td>
//                 </tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                 </tr>
//                 <tr>
//                     <td>Tanggal</td>
//                     <td>: ${transactiondate}</td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Supplier</td>
//                     <td>: ${supplier}</td>
//                 </tr>
//                 <tr>
//                     <td>Kode</td>
//                     <td>: ${codereceiving}</td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td>Alamat</td>
//                     <td>: ${address}</td>
//                 <tr>
//                 <tr>
//                     <td>Ket</td>
//                     <td rowspan="2">: ${keterangan}</td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                 <tr>
//                 <tr>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                     <td></td>
//                 </tr>
//                   <tr style="border: 1px solid black">
//                       <th colspan="5">Nama Item</th>
//                       <th>Kode Item</th>
//                       <th>Qty</th>
//                       <th>Satuan</th>
//                     </tr>
//                         ${po}
//                 </tbody>
//             </table>
//             `;
//             var ctx = {
//                 worksheet: name || 'WorkBook',
//                 imgsrc1: imgsrc1,
//                 heading: heading,
//                 table: table
//             }
//             var blob = new Blob([format(template, ctx)]);
//             return blob;
//             }
//         })()
//         var blobURL = tableToExcel('account_table', 'BPB PEMBELIAN');
//         FileSaver.saveAs(blobURL, fileName+'.xls');
        
//         })
//       .catch(function (error) {
//             // setGagalAlert();
//       });
//   }
  
  // const setGagalAlert = (id) => {
  //   setAlert(
  //     <SweetAlert
  //       danger
  //       showConfirm
  //       confirmBtnText="Ok"
  //       title="Gagal Cok"
  //       onCancel={hideAlert}
  //       onConfirm={hideAlert}
  //     />
  //   );
  // };
  
  // const hideAlert = () => {
  //   setAlert(null);
  // };

  // const formatRupiah = (money) => {
  //   return new Intl.NumberFormat('id-ID',
  //       { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  //   ).format(money);
  // }
    

  function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    // console.log(item)
    window.print();
    window.close();
    document.body.innerHTML = originalContents;
  }

  // function clickItem(e) {
  //   setItem(e.target.value)
  // }
  
  return (
    
    <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top:"-15%" }}>
      <ModalHeader toggle={toggle} align="center">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {/* <div style={{ textAlign: 'left' }}>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            Set Item
                        </Label>
                        <Input type="number" pattern="[0-9]*" value={item} min="1" max={savedItems.length} onChange={clickItem} style={{width: '70px',marginRight:'50px'}}/>
                    </div> */}
                    <div style={{ textAlign: 'right' }}>
                    <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            &nbsp;
                        </Label><br></br>
                        <Button color="info" onClick={() => {print();HitCounter();setTimeout(() => (window.location.reload("/admin/receiving-po/histori")), 1000)}}>Print</Button>
                        <Button color="success" onClick={()=> {handleDownload();HitCounter()}}>Excel</Button>
                        {/* <Button color="success" onClick={()=> (downloadExcel(),HitCounter())}>Excel</Button> */}
                    </div>
                </div>
      </ModalHeader>
      <ModalBody>
      <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          {renderPDF()}
        </CardBody>
        {/* <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          <div className="col">
            <KopSurat warehouseName={warehouse} />
          </div>
          <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                        <Row md="12">
                            <Col md="12">
                                <h3><b><center>RECEIVING PO</center></b></h3>
                            </Col>
                        </Row>
                        <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                        <br></br>
                        <Row md="12">
                            <Col md="5">
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Tanggal</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{transactiondate}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Kode</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{codereceiving}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Keterangan</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-5 ">
                                    <b>{keterangan}</b>
                                </div>
                            </div>
                            </Col>
                            <Col md="1">
                            </Col>
                            <Col md="6">
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Supplier</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{supplier}</b>
                                </div>
                            </div>
                            <div className="row align-items-center mb-1">
                                <div className="col-3">Alamat</div>
                                <div className="col-1 text-center">:</div>
                                <div className="col-7 ">
                                    <b>{address}</b>
                                </div>
                            </div>
                            </Col>
                        </Row>
                    <CardBody>
                      <br></br>
                      <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th>
                            <b>Nama Item</b>
                            </th>
                            <th>
                            <b>Kode Item</b>
                            </th>
                            <th>
                            <b>Qty</b>
                            </th>
                            <th>
                            <b>Satuan</b>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            savedItems.map((savedItem, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{savedItem.data.item_name}</td>
                                        <td>{savedItem.item_code}</td>
                                        <td>{savedItem.qty}</td>
                                        <td>{savedItem.satuan}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                      </Table>
                    </CardBody>
                    <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                        <center>Terms of Price, delivery & shipping required</center>
                        <br></br>
                                <Row md="12">
                                    <Col md="5">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Di Buat</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{creator}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>---------------------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                    <Col md="5">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Kepala Gudang</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>{validator1}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-3">
                                        <div className="col-5">Signature</div>
                                        <div className="col-1 text-center">:</div>
                                        <div className="col-5 ">
                                            <b>---------------------------</b>
                                        </div>
                                    </div>
                                    </Col>
                                </Row>
                                <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                      {counter === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Download {counter} Kali Oleh {username}</i></div>
                    </div>
                      )
                      
                      }
        </CardBody> */}
      </ModalBody>
    </Modal>
  );

  function renderPDF(){
    // let sheet = 1;
    // let length = savedItems.length
    // if (item != length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []  
    // for (let index = 0; index < sheet; index++) {
    //   let start = 0
    //   let end = 0
    //   if(sheet == 1){
    //     end = length
    //   }else{
    //     if(sheet > 1 && index < (sheet - 1)){
    //       end = item
    //     }else{
    //       end = length % item
    //     }
    //   }

    // let sheet = 1;
    // let length = savedItems.length
    // if (item != length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []
    // for (let index = 0; index < sheet; index++) {
    //   let start = (item * index)
    //   let end = length
    //   let sisa = (length % item)
    //   if(sheet == 1){
    //     end = length
    //   }else{
    //     if(index < (sheet - 1) && sheet == 2){
    //       end = item
    //     }else{
    //       if (index == (sheet - 1)) {
    //         end = length - sisa + (index * item)
    //       }else{
    //         if (index == 0) {
    //           end = item
    //         }else{
    //           end = (index+1) * item
    //         }
    //       }
    //     }
    //   } 
    //   let data = savedItems.slice(start,end)
    const itemsPerPage = 10; // Maksimal 7 item per halaman
    const totalItems = savedItems.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage); // Hitung total halaman
  
    const sheets = [];
    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      // Hitung indeks awal dan akhir untuk slice item per halaman
      const start = pageIndex * itemsPerPage;
      const end = start + itemsPerPage;
  
      // Ambil item untuk halaman saat ini
      const currentItems = savedItems.slice(start, end);
      // console.log(data.length)
      // let sub_total = 0;
      // let diskon = 0;
      // let pajak = 0;
      let usernamecok = moment().format("YYYY-MM-DD hh:mm:ss") + " " + localStorage.username;
      sheets.push(
        <div className='pagebreak'>
          {alert}

          <div className="col">
            <KopSurat warehouseName={warehouse} />
          </div>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          <Row md="12">
              <Col md="12">
                  <h3 style={{ color:"black" }} color="black"><b><center>BPB Pembelian</center></b></h3>
              </Col>
          </Row>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          
          <Row md="12">
            <Col style={{marginLeft:"2%",width:"50%",flexBasis: "unset"}}>
             
              <div className="row align-items-center ">
                  <div style={{width:"10%"}}  className="text-table">Tanggal</div>
                  <div style={{width:"2%"}}  className="text-center text-table">:</div>
                  <div style={{width:"85%"}}  className="text-table">
                      <b>{transactiondate}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"10%"}} className="text-table">No BPB</div>
                  <div style={{width:"2%"}} className="text-center text-table">:</div>
                  <div style={{width:"85%"}} className="text-table">
                      <b>{codereceiving}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"10%"}}  className="text-table">keterangan </div>
                  <div style={{width:"2%"}}  className="text-center text-table">:</div>
                  <div style={{width:"85%"}}  className="text-table">
                      <b>{keterangan}</b>
                  </div>
              </div>
            </Col>
            <Col style={{width:"25%",flexBasis: "unset"}}>
              <div className="row align-items-center ">
                  <div style={{width:"30%"}} className="text-table">Supplier</div>
                  <div style={{width:"5%"}} className="text-table">:</div>
                  <div style={{width:"65%"}} className="text-table">
                      <b>{supplier}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"30%"}} className="text-table">Alamat</div>
                  <div style={{width:"5%"}} className="text-table">:</div>
                  <div style={{width:"65%"}} className="text-table">
                      <b>{address}</b>
                  </div>
              </div>
            </Col>
          </Row>
              <Table style={{width:"100%"}} responsive>
                <thead>
                <tr>
                    <th className="text-table">
                    <b>No</b>
                    </th>
                    <th className="text-table">
                    <b>Kode</b>
                    </th>
                    <th className="text-table">
                    <b>Nama Barang</b>
                    </th>
                    <th className="text-table">
                    <b>Qty</b>
                    </th>
                    <th className="text-table">
                    <b>Sat</b>
                    </th>
                    <th className="text-table">
                    <b>Keterangan</b>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    currentItems.map((temp,index, key) => {
                        // let item_name =  temp.data.item_name
                        // sub_total = sub_total + temp.sub_total
                        // diskon = diskon + temp.diskon_nominal
                        return (
                            <tr>
                                <td className="text-table">{index+1}</td>
                                <td className="text-table">{temp.item_code}</td>
                                <td className="text-table">{temp.item_name}</td>
                                <td className="text-table">{temp.qty}</td>
                                <td className="text-table">{temp.satuan}</td>
                                <td className="text-table">{temp.note}</td>
                            </tr>
                        );
                    })
                }
                </tbody>
              </Table>
              <div ></div>
              <Row md="12">
              </Row>
          <div className="w-100 text-table" style={{ border: "0.5px solid black" }}></div>
          <center className="text-table">Terms of Price, delivery & shipping required</center>
          <Row md="20">
            <Col style={{ marginLeft:"2%",width:"33%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Di Buat</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>{creator}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col> 
            <Col style={{ width:"33%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Kepala Gudang</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>{validator1}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col>
          </Row>
          <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                      {counter === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Download {counter} Kali Oleh {username}</i></div>
                    </div>
                      )
                      
                      }
          <div className="divFooter">{usernamecok}</div>
        </div>
      )
    }
    return (
      <div>
        {sheets}
      </div>
    );
  }
};

export default ModalCetakPo;
