/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    Container,
    ButtonGroup, 
    Badge,
    Form, 
    FormGroup, 
    Label, 
    Input 
} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from 'views/404';
import Select2 from "react-select2-wrapper";

const ValidasiKepalaGudangSuratJalanSalesOrder = () => {
  const [alert, setAlert] = React.useState(null);
  const [tanggal,setTanggal] = useState("")
  const [persons, setPersons] = useState([]);
  const token = localStorage.token;
  const warehouse = parseInt(localStorage.warehouse);
  const redirectPrefix = `/admin/validasi-surat-jalan-so/edit/`;
  const [rowIndex, setRowIndex] = useState(0);
  const [allSuratJalanSo, setAllSuratJalanSo] = useState([]);
  const [uomCode, setUomCode] = useState("");
  const [description, setDescription] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const allInfo = JSON.parse(localStorage.allinfo);
  const suratjalanso = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Surat Jalan SO").map((p) => p.read_access));
 
  useEffect(() => {
    getPerson();
  }, []);

  const getPerson = () => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/customer/list`, { headers
      })
      .then(data => {
        setPersons(data.data.response);
      })
        .catch(function (error) {
          console.log(error)
        })
    }

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort,tanggal, uomCode, description);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort,tanggal, uomCode, description) => {
    getSuratJalan(page, perPage, sort,tanggal, uomCode, description);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setTanggal(tanggal)
    setUomCode(uomCode);
    setDescription(description);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort,tanggal,  uomCode, description)
    }
  }

  
  useEffect(() => {
    getSuratJalan(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getSuratJalan = (page, perPage, currentSort,transaction_date=null, sj_code = null, customer_name = null) => {
    
    let filter = { 
      
      page: page, 
      per_page: perPage, 
      status_sj : 3,
      warehouse_id : parseInt(warehouse),
      
    };
    if (transaction_date !== null) {
      filter = Object.assign(filter, { transaction_date: transaction_date })
    }
    if (sj_code !== null) {
      filter = Object.assign(filter, { sj_code: sj_code })
    }
    if (customer_name !== null) {
        filter = Object.assign(filter, { customer_name: customer_name })
    }
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/surat-jalan-so/page`, data, {
        headers,
      })
      .then((data) => {
        setAllSuratJalanSo(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        setAllSuratJalanSo(error.response.data.response);
      });
    }

    const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
    };

    const hideAlert = () => {
    setAlert(null);
    };

  const reset = () => {
    setTanggal("")
    setUomCode("");
    setDescription("");
    updateDataTable(1, perPage, currentSort, "", "");
  }

  return (
    <div>
      {alert}
      {suratjalanso && suratjalanso === "1" ? (
			<div>
      <SimpleHeader name="Validasi Kepala Gudang" parentName="PO" />
        <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ textAlign: 'left' }}>
                      <Link className="btn btn-link" to="/admin/surat-jalan-so">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                      </Link>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
              <Form>
                          <Row md="12">
                          <Col md="3">
                            <FormGroup>
                              <Label
                                      className="form-control-label"
                                      htmlFor="exampleFormControlSelect3"
                                  >
                                Tanggal 
                              </Label>
                              <Input
                              className="form-control-alternative"
                                type="date"
                                placeholder="Masukan Tanggal"
                                value={tanggal}
                                onChange={(e) =>
                                  updateDataTable(1,perPage,currentSort, e.target.value, uomCode , description )
                                }
                              />
                            </FormGroup>
                          </Col>
                              <Col md="3">
                              <FormGroup>
                                <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >Kode SJ</Label>
                                <Input
                                className="form-control-alternative"
                                  type="text"
                                  placeholder="Masukan Kode SJ"
                                  value={uomCode}
                                  onChange={e => updateDataTable(1, perPage, currentSort,tanggal, e.target.value, description)}
                                />
                              </FormGroup>
                            </Col>
                            <Col md="3">
                              <FormGroup>
                                <Label
                                  className="form-control-label"
                                  htmlFor="exampleFormControlSelect3"
                              >Customer</Label>
                                <Select2
                                        className="form-control-alternative"
                                        defaultValue="0"
                                        value={description}
                                          onChange={e => updateDataTable(1, perPage, currentSort,tanggal, uomCode, e.target.value)}
                                        options={{
                                          placeholder: "Pilih Customer"
                                        }}
                                        data={persons.map((person, key) => {
                                          return (
                                          { id: person.name, text: person.name, key:key}
                                          );
                                        })}
                                      />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Button type="button" onClick={reset} color="secondary">Reset</Button>
                            </Col>
                          </Row>
                        </Form>
                      {allSuratJalanSo === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                  dataField: "",
                                  text: "Tanggal",
                                  sort: true,
                                  },
                                  {
                                    dataField: "",
                                    text: "Kode SJ",
                                    sort: true,
                                },
                                {
                                  dataField: "",
                                  text: "Customer",
                                  sort: true,
                              },
                                  {
                                    dataField: "",
                                    text: "Status",
                                    sort: true,
                                },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                        </div>
                        ):(
                    <ToolkitProvider
                            rowNumber={rowIndex}
                            data={allSuratJalanSo}
                            keyField="id"
                            columns={[
                            {
                                dataField: "no",
                                text: "#",
                                sort: true,
                                page: 1,
                                formatter: (cell, row, index) => {
                                let currentRow = ++index;
                                return currentRow + rowIndex;
                                },
                            },
                            {
                              dataField: "created_at",
                              text: "Tanggal",
                              sort: true,
                            },
                            {
                                dataField: "sj_code",
                                text: "Kode SJ",
                                sort: true,
                                formatter: (cell, row) => {
                                  return row.sj_code === ""
                                    ? <h2>
                                      <Badge color="warning" >
                                        {row.sj_code}
                                      </Badge>
                                      </h2>
                                    : 
                                    <h2>
                                    <Badge color="warning" >
                                        {row.sj_code}
                                      </Badge>
                                      </h2>
                                },
                            },
                            {
                              dataField: "customer_name",
                              text: "Customer",
                              sort: true,
                          },
                            {
                              dataField: "status_sj",
                              text: "Status",
                              sort: true,
                              formatter: (cell, row) => {
                                return row.status_sj === 3
                                  ? 
                                    <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-danger" />
                                      Proses
                                    </Badge>
                                  : row.status_sj === 4
                                  ? <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-warning" />
                                      Tolak
                                    </Badge>
                                  : <Badge color="" className="badge-dot mr-4">
                                      <i className="bg-success" />
                                      Setuju
                                    </Badge>;
                              },
                          },
                            {
                                dataField: "", text: "", formatter: (cell, row, index) => {
                                return (
                                    <ButtonGroup>
                                    <Button>
                                        <Link
                                        to={redirectPrefix + row.id}
                                        id={"tooltip_" + row.id}
                                        >
                                        <i className="fa fa-check-circle"  aria-hidden="true"/> Validasi
                                        </Link>
                                    </Button>
                                    </ButtonGroup>
                                )
                                }
                            },
                            ]}
                        >
                            {(props) => (
                            <div className="py-4 table-responsive">
                                <BootstrapTable
                                remote
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                                hover={true}
                                pagination={paginationFactory({ ...paginationOption })}
                                onTableChange={handleTableChange}
                                />
                            </div>
                            )}
                    </ToolkitProvider>
                        )}
              </CardBody>
            </Card>
          </div>
        </Row>
        </Container>
        </div>
      ):(
        <Halaman404 />
      )}
    </div>
  );
}

export default ValidasiKepalaGudangSuratJalanSalesOrder;