/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input
  
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
// import Select2 from "react-select2-wrapper";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

export default function EditPermintaanBarang(props) {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
    const warehouse = localStorage.warehouse;
    const username = localStorage.username;
    let history = useHistory();
    const [rfq_code,setRfqCode] = useState("");
    const [typereq,setTypeReq] = useState([]);
    const [tanggal, setTanggal] = useState("");
    const [keterangan,setKeterangan] = useState("");
    const [savedItems, setSavedItems] = useState([]);
    const [isSearchShoww, setIsSearchShoww] = useState(false);
    const [allItemm, setAllItemm] = useState([]);
    const [queryy, setQueryy] = useState("");
    const headers = { Authorization: `Bearer ${token}` };
    const [qtyTotal, setTotalQty] = useState(0);
    const allInfo = JSON.parse(localStorage.allinfo);
    const permintaanBarangs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Rfq PO").map((p) => p.read_access));
    
    useEffect(() => {
      const getData = setTimeout(async() => {
          if(queryy != ''){
              axios
              .post(`${process.env.REACT_APP_API_BASE_URL}/items-po`, { item_info: queryy , per_page: 10, warehouse_id: parseInt(warehouse)},{ headers })
              .then((res) => {
                  setAllItemm(res.data);
                  setIsSearchShoww(true);
              })
              .catch(function (error) {
                  
              });
          }else{
        setAllItemm(null);
          }
        }, 1000)
        return () => clearTimeout(getData)
    }, [queryy]);
    
    const handleEditQty = (index, value) => {
      let updateList = savedItems;
      let aqtyTotal = parseInt(updateList[index].harga) + value;
      
      if(!isNaN(value) && value.length > -1){
        updateList[index] = {...updateList[index], qty: value};
        setSavedItems(updateList);
        setTotalQty(qtyTotal + aqtyTotal);
      }else{return false}
    }
    
    const handleEditNote = (index, value) => {
      let updateList = savedItems;
      let aqtyTotal = value;
      
      if(value){
        updateList[index] = {...updateList[index], note: value};
        setSavedItems(updateList);
        setTotalQty(qtyTotal + aqtyTotal);
      }else{return false}
    }

    // save
    const saveItemOrUpdate = (item) => {
      let oldobj = savedItems.find(o => o.item_id === item.id);
      if(oldobj===undefined){
        setSavedItems([...savedItems,{
          item_id: item.id,
          item_name: item.item_name,
          item_code: item.item_code,
          satuan: item.satuan,
          qty: 1,
          note : ""
        }]);
        
      }else{
        let index = savedItems.findIndex(e=>e.item_id===item.id);
        let updateList = savedItems;
        let qtyupdate = parseInt(updateList[index].qty)+parseInt(1);
        updateList[index] = {...updateList[index], qty: qtyupdate};
        setSavedItems(updateList);
      };
    }
    
    const handleSubmit = async (e) => {
      e.preventDefault();
      {
        setQueryy("");
        setIsSearchShoww("");
        return true
      }
    };
  
    useEffect(() => {
      getById();
    }, []);
  
    const getById = () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/rfq-po/get/${props.match.params.id}`,
          { headers }
        )
        .then((data) => {
          getItemDataSaved();
          setRfqCode(data.data.response.rfq_code);
          setTanggal(data.data.response.created_at)
          setTypeReq(data.data.response.type);
          setKeterangan(data.data.response.keterangan);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const getItemDataSaved = () => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/rfq-po/item`, {

            rfq_id: props.match.params.id

        }).then(async response => {
            let stateItem = [];

            await Promise.all(response.data.response.map(async (data) => {
                stateItem = [...stateItem, {
                    editing:false,
                    item_id: data.item_id,
                    item_name:data.item_name,
                    item_code : data.item_code,
                    satuan:data.satuan, 
                    qty: data.qty,
                    note : data.note,
                    data: {
                        item_name: data.item_name,
                        qty: data.qty,
                        note : data.note
                    },
                }];
            }));
            setSavedItems(stateItem);
        })
    }

    const handleSubmit1 = () => {
      hideAlert();
      setTimeout(() => {EditData()}, 1000);
  };
    
    function EditData() {
      let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty,
                satuan: dataItem.satuan, 
                note : dataItem.note
            }]);
        let data = {
        warehouse_id : parseInt(warehouse),
        username : username,
        type : parseInt(typereq),
        keterangan: keterangan ,
        status_rfq : 3,
        admin_po: "",
        items : dataItems,
        };
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/rfq-po/update/${props.match.params.id}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/permintaan-barang/page")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={handleSubmit1}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };
 

      const deleteItem = (id) => {
        let array = [...savedItems];
    
        let index = array.findIndex(object => {
            return object.item_id === id;
        });
    
        if (index !== -1) {
            array.splice(index, 1);
            setSavedItems(array);
        }
    }
  
  return (
    <>
    {alert}
    {permintaanBarangs && permintaanBarangs === "1" ? (
			<div>
    <SimpleHeader name="Edit Permintaan Barang" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Edit Permintaan Barang</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode RFQ
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="text"
                                  name="desc"
                                  placeholder="Masukan RfqCode"
                                  value={rfq_code}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Tanggal
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="text"
                                  name="desc"
                                  placeholder="Masukan RfqCode"
                                  value={tanggal}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Tipe PO
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                  name="Tipe Po"
                                  type="select"
                                  value={typereq}
                                  onChange={(e) => {
                                      setTypeReq(e.target.value);
                                  }}
                                >
                                  <option value="" disabled hidden>Pilih Request</option>
                                  <option value={1}>Customer Request</option>
                                  <option value={2}>Stok Request</option>
                                </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Keterangan
                              </Label>
                              <Col sm={6}>
                                <Input
                                autoComplete="off"
                                className="form-control-alternative"
                                  type="textarea"
                                  rows = "4"
                                  name="desc"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                    <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Qty</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Note</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="qty"
                                          type="number"
                                          value={savedItem.qty}
                                          onChange={(e) => {
                                              handleEditQty(key, e.target.value);
                                          }}/>
                                      </td>
                                      <td>{savedItem.satuan}</td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="Note"
                                          type="text"
                                          value={savedItem.note}
                                          onChange={(e) => {
                                            handleEditNote(key, e.target.value);
                                          }}/>
                                      </td>
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              <tr>
                                <td>
                                  <><Form onSubmit={handleSubmit}>
                                          <Input
                                            autoComplete="off"
                                            placeholder="Masukan Item Manual"
                                            type="search"
                                            value={queryy}
                                            onChange={(e) => setQueryy(e.target.value)}
                                          />
                                         
                                        {isSearchShoww && queryy && (
                                        <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                          <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                            <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
                                          </div>
                                          {allItemm?.response ? (
                                            allItemm.response.map((item) => (
                                              <CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {saveItemOrUpdate(item);setQueryy('');setIsSearchShoww(false);}}>
                                                <div>
                                                  <b>Nama item:</b> {item.item_name}
                                                </div>
                                              </CardBody>
                                            ))
                                          ) : (
                                            <div className="text-center mb-3 text-danger">Item "{queryy}" tidak ada bosku!</div>
                                          )}
                                        </Card>
                                      )}
                                  </Form></>
                                  </td>
                                 </tr>
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter>
                              <Link className="btn btn-info" to="/admin/permintaan-barang/page">
                                  Kembali
                              </Link>
                              <Button color="danger" onClick={()=>setQuestionAlert()}>
                                  Simpan
                              </Button>
                        </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>  
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}
