/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  FormGroup,
  Label,
  Input,
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";


const DetailBkmReturFinance = (props) => {
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  const [codebkk, setCodeBkk] = useState("")
  const [persons, setPersons] = useState([]);
  const [person, setPerson] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [savedItems, setSavedItems] = useState([]);
  const [bank, setBank] = useState("");
  const [banks, setBanks] = useState([]);
  
    useEffect(() => {
        getById();
      }, []);
    
      const getById = () => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/bkm/get/${props.match.params.id}`,
            { headers }
          )
          .then((data) => {
            setCodeBkk(data.data.response.bkm_code)
            getPerson(data.data.response.person_id);
            getbank(data.data.response.payment_method);
            setKeterangan(data.data.response.keterangan1);
            getItemDataSaved();
          })
          .catch(function (error) {
            console.log(error);
          });
      };

      
  const getItemDataSaved = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/bkm/item`, {

        bkm_id: props.match.params.id

    }).then(async response => {
        let stateItem = [];

        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
                warehouse_id: data.warehouse_id,
                code_retur : data.code_retur,
                payment_total : data.payment_total,
                keterangan2 : data.keterangan2,
				potongan: data.potongan,
                bayar : data.bayar,
                data: {
                    warehouse_id: data.warehouse_id,
                    code_retur : data.code_retur,
                    payment_total : data.payment_total,
                    keterangan2 : data.keterangan2,
                    potongan: data.potongan,
                    bayar : data.bayar,
                },
            }];
        }));
        setSavedItems(stateItem);
    })
}


  useEffect(() => {
    getPerson();
    getbank();

  }, []);

  const getPerson = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/person/list`,
        { headers }
      )
      .then((data) => {
        setPersons(data.data.response);
        setPerson(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getbank = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/account/list-by-kas/${warehouse}`,
        { headers }
      )
      .then((data) => {
        setBanks(data.data.response);
        setBank(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}
  
  return (
    <>
    <SimpleHeader name="Detail BKM Retur" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col"> 
                <CardBody>
                <Card className="bg-secondary shadow">
                          <CardHeader className="bg-white border-0">
                              <h3>Detail BKM Retur</h3>
                          </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Kode
                              </Label>
                              <Col sm={7}>
                                <Input
                                disabled
                                  className="form-control-alternative"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Masukan Kode"
                                  value={codebkk}
                                  onChange={(e) => {
                                    setCodeBkk(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                             <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Supplier
                              </Label>
                              <Col sm={7}>
                                <Input
                                disabled
                                  name="person"
                                  type="select"
                                  value={person}
                                  onChange={(e) => {
                                    setPerson(e.target.value);
                                  }}
                                >
                                  <option value=''>Pilih Supplier</option>
                                  {persons.map((person, key) => {
                                      return (
                                        <option key={key} value={person.id}>
                                          {person.person_name}
                                        </option>
                                      );
                                    })}
                                </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                className="form-control-label"
                                    sm={4}
                                >
                                    Akun
                                </Label>
                                <Col sm={7}>
                                <Input
                                disabled
                                autoComplete="off"
                                    name="coa"
                                    type="select"
                                    value={bank}
                                    onChange={(e) => {
                                    setBank(e.target.value);
                                    }}
                                >
                                    <option value="" disabled selected hidden>Pilih Akun</option>
                                    {banks.map((coa, key) => {
                                    return (
                                        <option key={key} value={coa.id}>
                                        {coa.account_name}
                                        </option>
                                    );
                                    })}
                                </Input>
                                </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                             <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Keterangan
                              </Label>
                              <Col sm={7}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                  type="textarea"
                                  name="desc"
                                  rows = "4"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                </Card>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Retur</h3>
                    </CardHeader>
                        <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th hidden><b>Cabang</b></th>
                                <th><b>Kode Retur</b></th>
                                <th><b>Tempo</b></th>
                                <th><b>Tagihan</b></th>
                                <th><b>Keterangan</b></th>
                                <th><b>Potongan</b></th>
                                <th><b>Bayar</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td hidden>{savedItem.warehouse_id}</td>
                                      <td>{savedItem.code_retur}</td>
                                      <td>{savedItem.tgl_tempo}</td>
                                      <td><>{formatRupiah(savedItem.payment_total)}</></td>
                                      <td>{savedItem.keterangan2}</td>
                                      <td><>{formatRupiah(savedItem.potongan)}</></td>
                                      <td><>{formatRupiah(savedItem.bayar)}</></td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/bm-retur/kas/histori">
                        Kembali
                      </Link>
                </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>
    </>
  );
}

export default DetailBkmReturFinance;