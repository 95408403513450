/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Modal, 
  ModalHeader, 
  ModalBody,
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";

export default function PenerimaBarang(props) {
  const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState("");
  const [pengiriman, setPengiriman] = useState([]);
  const [keterangan, setKeterangan] = useState("");
  const [savedItems, setSavedItems] = useState([]);
  const [statussj,setStatusSj] = useState([]);
  const [codeso, setCodeSo] = useState("");
  const [codesj, setCodeSj] = useState("");
  const [driver, setDriver] = useState("");
  const [helper, setHelper] = useState("");
  const [nopol, setNopol] = useState("");
  const [achiever, setAchiever] = useState("")
  const [contactachiever, setContactAchiever] = useState("")
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-cashier/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        getCustomer(data.data.response.customer_id);
        getItemDataSaved();
        // setQty(data.data.response.qty_total);
        setStatusSj(data.data.response.status_sj);
        setAchiever(data.data.response.achiever);
        setContactAchiever(data.data.response.achiever_contact);
        setDriver(data.data.response.driver);
        setHelper(data.data.response.helper);
        setNopol(data.data.response.nopol);
        setKeterangan(data.data.response.keterangan);
        setCodeSo(data.data.response.code_nota);
        setPengiriman(data.data.response.pengiriman);
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/surat-jalan-cashier/item`, {

        sjc_id: props.match.params.id

      }).then(async response => {
        console.log(response);
          let stateItem = [];
          // let stateEditing = [];
          await Promise.all(response.data.response.map(async (data) => {
              stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code : data.item_code,
                satuan : data.satuan,
                qty: data.qty,
                data: {
                    item_name: data.item_name,
                    qty: data.qty,
                },
            }]; 
          //     stateEditing = [...stateEditing, {
          //     editing: false
          // }];
      }));
      // setEditingitem(stateEditing);
      setSavedItems(stateItem);
    })
}

const getCustomer = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/customer/list`,
        { headers }
      )
      .then((data) => {
        setCustomers(data.data.response);
        setCustomer(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };


   function EditData() {
    setLoading(true);
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga:dataItem.harga,
                // diskon_nominal: data.diskon_nominal,
                // diskon_persen : data.diskon_persen,
            }]);
    let data = {
        warehouse_id : parseInt(warehouse),
        code_so: codeso,
        code_sj: codesj,
        driver : driver,
        helper : helper,
        nopol : nopol,
        achiever : achiever,
        achiever_contact : contactachiever,
        status_sj: parseInt(statussj),
        admin_gudang: username,
        customer_id: parseInt(customer),
        pengiriman: parseInt(pengiriman),
        keterangan: keterangan ,
        items : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-cashier/update/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          history.push("/admin/surat-jalan-kasir");
        })
        .then((json) => {
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
  }

const handleSubmit = (e) => {
    {
      EditData();
    }
  };

  return (
    <>
    <SimpleHeader name="Penerima Barang" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
        <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                      <h3>Penerima Barang</h3>
                    </CardHeader>
                    <CardBody>
                    <Row md="12">
                          <Col md="6">
                          <Input
                          className="form-control-alternative"
                                  type="hidden"
                                  name="desc"
                                  placeholder="Masukan Keterangan"
                                  value={codeso}
                                  onChange={(e) => {
                                    setCodeSo(e.target.value);
                                  }}
                                />
                          </Col>
                      </Row>
                      <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={3}
                              >
                                Customer
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                              disabled
                                  name="customer"
                                  type="select"
                                  value={customer}
                                  onChange={(e) => {
                                    setCustomer(e.target.value);
                                  }}
                                >
                                  <option value=''>Pilih Customer</option>
                                  {customers.map((customer, key) => {
                                      return (
                                        <option key={key} value={customer.id}>
                                          {customer.name}
                                        </option>
                                      );
                                    })}
                                  </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={3}
                              >
                                Driver
                              </Label>
                              <Col sm={6}>
                                  <Input
                                  disabled
                                    className="form-control-alternative"
                                    placeholder="Masukan Driver"
                                    name="customer"
                                    type="text"
                                    value={driver}
                                    onChange={(e) => {
                                      setDriver(e.target.value);
                                    }}
                                  >
                                </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={3}
                              >
                                Helper
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                    className="form-control-alternative"
                                    placeholder="Masukan Helper"
                                    name="customer"
                                    type="text"
                                    value={helper}
                                    onChange={(e) => {
                                      setHelper(e.target.value);
                                    }}
                                  >
                                </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={3}
                              >
                                Nopol
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                    className="form-control-alternative"
                                    placeholder="Masukan Nopol"
                                    name="customer"
                                    type="text"
                                    value={nopol}
                                    onChange={(e) => {
                                      setNopol(e.target.value);
                                    }}
                                  >
                                </Input>
                              </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={3}
                              >
                                Penerima 
                              </Label>
                              <Col sm={6}>
                                <Input
                                    autoComplete="off"
                                    className="form-control-alternative"
                                    placeholder="Masukan Helper"
                                    name="customer"
                                    type="text"
                                    value={achiever}
                                    onChange={(e) => {
                                      setAchiever(e.target.value);
                                    }}
                                  >
                                </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={3}
                              >
                                Kontak
                              </Label>
                              <Col sm={6}>
                                <Input
                                    autoComplete="off"
                                    pattern="[0-9]{10}"
                                    maxLength="10"
                                    className="form-control-alternative"
                                    placeholder="Masukan Kontak"
                                    name="customer"
                                    type="number"
                                    value={contactachiever}
                                    onChange={(e) => {
                                      setContactAchiever(e.target.value);
                                    }}
                                  >
                                </Input>
                              </Col>
                            </FormGroup>
                          <FormGroup row>
                            <Label
                              for="exampleEmail"
                              sm={3}
                            >
                              Pengiriman
                            </Label>
                            <Col sm={6}>
                            <Input
                            className="form-control-alternative"
                            disabled
                                name="Tipe Request"
                                type="select"
                                value={pengiriman}
                                onChange={(e) => {
                                  setPengiriman(e.target.value);
                                }}
                              >
                                <option value="">Pilih Request</option>
                                <option value={1}>Ambil Sendiri</option>
                                <option value={2}>Delivery</option>
                                <option value={3}>Kurir</option>
                              </Input>
                            </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={3}
                              >
                                Alamat Kirim
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="textarea"
                                  name="Alamat Kirim"
                                  rows = "4"
                                  placeholder="Masukan Alamat Kirim"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label
                              for="exampleEmail"
                              sm={3}
                            >
                              Validasi
                            </Label>
                              <Col sm={7}>
                                <div style={{ display: "flex" }}>
                                  <div className="custom-control custom-radio mb-3">
                                    <Input
                                    disabled
                                      className="custom-control-input"
                                      id="customRadio20"
                                      name="custom-radio-6"
                                      type="radio"
                                      value={5}
                                      checked={statussj === 5}
                                      onChange={() => setStatusSj(5)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio20"
                                    >
                                      Disetujui
                                    </Label>
                                  </div>
                                  <div
                                    className="custom-control custom-radio mb-3"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <Input
                                    disabled
                                      className="custom-control-input"
                                      id="customRadio21"
                                      name="custom-radio-5"
                                      type="radio"
                                      value={4}
                                      checked={statussj === 4}
                                      onChange={() => setStatusSj(4)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio21"
                                    >
                                      Ditolak
                                    </Label>
                                  </div>
                                </div>
                              </Col>
                          </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                    <CardBody>
                    <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th><b>Nama Item</b></th>
                            <th><b>Kode Item</b></th>
                            <th><b>Qty</b></th>
                            <th><b>Satuan</b></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            savedItems.map((savedItem, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{savedItem.item_name}</td>
                                        <td>{savedItem.item_code}</td>
                                        <td>{savedItem.qty}</td>
                                        <td>{savedItem.satuan}</td>
                                        <td hidden>{savedItem.harga}</td>
                                        <td hidden>{savedItem.diskon_nominal}</td>
                                        <td hidden>{savedItem.diskon_persen}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                      </Table>
                    </CardBody>
                <CardFooter>
                      <Button color="danger" onClick={toggle}>
                          Simpan
                      </Button>
                      <Link className="btn btn-info" to="/admin/surat-jalan-kasir/histori">
                        Kembali
                      </Link>
                </CardFooter>
                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader toggle={toggle} align="center"></ModalHeader>
                  <ModalBody align="center">
                  <font size="5"><b>Apakah Anda Sudah Yakin ?</b></font><br></br><br></br><br></br>
                  {!isLoading && (
                    <Button color="primary" onClick={() => handleSubmit()}>
                    Lanjutkan
                    </Button>
                  )}
                  {isLoading && (
                    <Button color="primary" disabled>
                    <i className="fas fa-spinner fa-spin"></i>
                    {""}
                    loading...
                    </Button>
                  )}
                  <Button color="secondary" onClick={toggle}>
                    Cancel
                  </Button>
                  </ModalBody>
                </Modal>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container> 
    </>
  );
}