/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody, 
    CardHeader, 
    ButtonGroup, 
    Form, 
    FormGroup, 
    Container,
    Modal,
    ModalHeader ,
    ModalBody,
    Label, 
    Input 
} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from 'views/404';


const JadwalOpnamePage = () => {
  const token = localStorage.token;
  const warehouse = parseInt(localStorage.warehouse);
  const redirectPrefix = `/admin/jadwal-stock-opname/edit/`;
  const [isLoading, setLoading] = useState(false);
  const [rowIndex, setRowIndex] = useState(0);
  const [allAdjustment, setAllAdjustment] = useState([]);
  const [uomCode, setUomCode] = useState("");
  const [description, setDescription] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState("");
  const [currentSort, setCurrentSort] = useState("");
  const toggle = () => setModal(!modal);
  const [modal, setModal] = useState(false);
  const allInfo = JSON.parse(localStorage.allinfo);
  const JadwalStokOpnames = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Stok Opname Scheduled").map((p) => p.read_access));
  
  const handleSubmit = (e) => {
      CreateData();
  }

  function CreateData() {
    setLoading(true);
    let data = {
      warehouse_id : parseInt(warehouse),
    }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/opname-scheduled/close-opname`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        setTimeout(() => (window.location.reload("/admin/jadwal-stock-opname")), 500);
        // setTimeout(() => (  getJadwalOpname()), 500);
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, uomCode, description);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, uomCode, description) => {
    getJadwalOpname(page, perPage, sort, uomCode, description);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setUomCode(uomCode);
    setDescription(description);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort,  uomCode, description)
    }
  }

  
  useEffect(() => {
    getJadwalOpname(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getJadwalOpname = (page, perPage, currentSort) => {
    
    let filter = { 
      
      page: page, 
      per_page: perPage,
    //   status_d:3,
      active_flag : 1,
      warehouse_id : parseInt(warehouse),
      
    };
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/opname-scheduled/page`, data, {
        headers,
      })
      .then((data) => {
        setAllAdjustment(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        setAllAdjustment(error.response.data.response);
      });
  };

  const reset = () => {
    setUomCode("");
    setDescription("");
    updateDataTable(1, perPage, currentSort, "", "");
  }

  return (
    <div>
      {JadwalStokOpnames && JadwalStokOpnames === "1" ? (
      <div>
      <SimpleHeader name="Jadwal Opname" parentName="PO" />
        <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ textAlign: 'left' }}>
                      <Link className="btn btn-link" to="/admin/jadwal-stock-opname">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                      </Link>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                        {totalItem === 1?(
                             <Button color="danger" onClick={toggle}>
                                Closing Opname
                            </Button>
                        ) : (
                            <Link className="btn btn-info" to="/admin/jadwal-stock-opname/create">
                                Tambah
                            </Link>
                        )}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                      <Form>
                        <Row md="12">
                          <Col md="3">
                            <FormGroup>
                              <Label
                              className="form-control-label"
                              htmlFor="exampleFormControlSelect3">Kode Opname</Label>
                              <Input
                                type="text"
                                placeholder="Masukan Kode Opname"
                                value={uomCode}
                                onChange={e => updateDataTable(1, perPage, currentSort, e.target.value, description)}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button type="button" onClick={reset} color="secondary">Reset</Button>
                          </Col>
                        </Row>
                      </Form>
                      {allAdjustment === null ? (
                     <div>
                      <ToolkitProvider
                            rowNumber={""}
                            data={""}
                            keyField="id"
                            columns={[
                            {
                                dataField: "no",
                                text: "#",
                                sort: true,
                                page: 1,
                                formatter: (cell, row, index) => {
                                let currentRow = ++index;
                                return currentRow + rowIndex;
                                },
                            },
                            {
                                dataField: "",
                                text: "Username",
                                sort: true,
                            },
                            {
                                dataField: "",
                                text: "Tanggal",
                                sort: true,
                            },
                            {
                                dataField: "",
                                text: "Kode",
                                sort: true,
                            },
                           
                            ]}
                        >
                            {(props) => (
                            <div className="py-4 table-responsive">
                                <BootstrapTable
                                remote
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                                hover={true}
                                pagination={paginationFactory({ ...paginationOption })}
                                onTableChange={handleTableChange}
                                />
                            </div>
                            )}
                    </ToolkitProvider>
                    <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                     </div> 
                      )
                     :
                     (
                     <div>
                      <ToolkitProvider
                            rowNumber={rowIndex}
                            data={allAdjustment}
                            keyField="id"
                            columns={[
                            {
                                dataField: "no",
                                text: "#",
                                sort: true,
                                page: 1,
                                formatter: (cell, row, index) => {
                                let currentRow = ++index;
                                return currentRow + rowIndex;
                                },
                            },
                            {
                                dataField: "username",
                                text: "Username",
                                sort: true,
                            },
                            {
                                dataField: "opname_date",
                                text: "Tanggal",
                                sort: true,
                            },
                            {
                                dataField: "opname_code",
                                text: "Kode",
                                sort: true,
                            },
                            {
                                dataField: "", text: "", formatter: (cell, row, index) => {
                                return (
                                    <ButtonGroup>
                                    <Button>
                                        <Link
                                        to={redirectPrefix + row.id}
                                        id={"tooltip_" + row.id}
                                        >
                                        <i className="fas fa-user-edit" /> Edit
                                        </Link>
                                    </Button>
                                    </ButtonGroup>
                                )
                                }
                              },
                            ]}
                        >
                            {(props) => (
                            <div className="py-4 table-responsive">
                                <BootstrapTable
                                remote
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                                hover={true}
                                pagination={paginationFactory({ ...paginationOption })}
                                onTableChange={handleTableChange}
                                />
                            </div>
                            )}
                    </ToolkitProvider>
                     </div>
                     )
                    }
                    {/* <ToolkitProvider
                            rowNumber={rowIndex}
                            data={allAdjustment}
                            keyField="id"
                            columns={[
                            {
                                dataField: "no",
                                text: "#",
                                sort: true,
                                page: 1,
                                formatter: (cell, row, index) => {
                                let currentRow = ++index;
                                return currentRow + rowIndex;
                                },
                            },
                            {
                                dataField: "username",
                                text: "Username",
                                sort: true,
                            },
                            {
                                dataField: "opname_date",
                                text: "Tanggal",
                                sort: true,
                            },
                            {
                                dataField: "opname_code",
                                text: "Kode",
                                sort: true,
                            },
                            {
                                dataField: "", text: "", formatter: (cell, row, index) => {
                                return (
                                    <ButtonGroup>
                                    <Button>
                                        <Link
                                        to={redirectPrefix + row.id}
                                        id={"tooltip_" + row.id}
                                        >
                                        <i className="fas fa-user-edit" /> Edit
                                        </Link>
                                    </Button>
                                    </ButtonGroup>
                                )
                                }
                              },
                            ]}
                        >
                            {(props) => (
                            <div className="py-4 table-responsive">
                                <BootstrapTable
                                remote
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                                hover={true}
                                pagination={paginationFactory({ ...paginationOption })}
                                onTableChange={handleTableChange}
                                />
                            </div>
                            )}
                    </ToolkitProvider> */}
              </CardBody>
            </Card>
          </div>
        </Row>
        </Container>
        <Modal isOpen={modal} toggle={toggle}>
                          <ModalHeader toggle={toggle} align="center"></ModalHeader>
                          <ModalBody align="center">
                          <font size="5"><b>Apakah Anda Sudah Yakin ?</b></font><br></br><br></br><br></br>
                          
                          <Button color="secondary" onClick={toggle}>
                              Cancel
                          </Button>
                          {isLoading && (
                              <Button color="primary" disabled>
                              <i className="fas fa-spinner fa-spin"></i>
                              {""}
                              loading...
                              </Button>
                          )}
                          {!isLoading && (
                              <Button color="primary" onClick={() => handleSubmit()}>
                              Lanjutkan
                              </Button>
                          )}
                          </ModalBody>
                      </Modal>
        </div>
      ):(
        <Halaman404 />
      )}
    </div>
  );
}

export default JadwalOpnamePage;