/*eslint-disable*/
import React, { useEffect, useState } from "react";
import axios from "axios";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import Detail3 from "./Detail3";

const Detail2 = (props) => {
  const token = localStorage.token;
  const [activeTab, setActiveTab] = useState("1");
  const [alert, setAlert] = React.useState(null);
  const [hide, setHide] = useState(true);
  const [rowIndex, setRowIndex] = useState(0);
  const [namaproyek,setNamaProyek] = useState("");
  const [selectedSalesTracking, setSelectedTracking] = useState(0);

  useEffect(() => {
    getSalesTrackingDetail();
  }, [props.code]);

  const getSalesTrackingDetail = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/account/list4/${props.code}`,
        {
          headers,
        }
      )
      .then((data) => {
        setNamaProyek(data.data.response);
        // setAlamatProyek(data.data.response.alamat_proyek);
        // setCodeCanvasing(data.data.response.canvasing_code);
        // setCodeTracking(data.data.response.tracking_code);
        // setPhone(data.data.response.pic_phone);
        // setImage1(data.data.response.foto1);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const expandRow = {
    parentClassName: 'expanding-foo',
    onlyOneExpanding: true,
    renderer: (row, rowIndex) => (
      setSelectedTracking(row.id),
    <div>
      {/* <p>{ `This Expand row is belong to rowKey ${row.id}` }</p>
      <p>You can render anything here, also you can add additional data on every row object</p>
      <p>expandRow.renderer callback will pass the origin row object to you</p> */}
      <Detail3 code={selectedSalesTracking} />
    </div>
    
      
      // <Detail code={selectedSalesTracking} />
   
  ),
  // showExpandColumn: true,
  // expandColumnPosition: 'left'
};

  return (
    <div>
  <ToolkitProvider
                  rowNumber={rowIndex}
                  data={namaproyek}
                  keyField="id"
                  columns={[
                    {
                    //   dataField: "no",
                    //   text: "#",
                    //   sort: true,
                      page: 1,
                      formatter: (cell, row, index) => {
                        let currentRow = ++index;
                        return currentRow + rowIndex;
                      }
                    },
                    
                    {
                      dataField: "account_code",
                    //   text: "Kode Account",
                    //   sort: true,
                    },
                    {
                      dataField: "account_name",
                    //   text: "Nama Account",
                    //   sort: true,
                    },
                  ]}
                >
                  {(props) => (
                    <div>
                      <BootstrapTable
                        remote
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        hover={true}
                        expandRow={ expandRow }
                        // rowEvents={rowEvents}
                        // pagination={paginationFactory({ ...paginationOption })}
                        // onTableChange={handleTableChange}
                      />
                    </div>
                  )}
        </ToolkitProvider>
    </div>
  );
};

export default Detail2;
