import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input
} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";

const ModalCetakAll = ({ open, toggle, data }) => {
    const token = localStorage.token;
    const cabang = data.cabang
    const area = data.area
    const username = localStorage.username
    const [savedItems, setSavedItems] = useState([]);
    const [cabangs, setCabangs] = useState("");
    const [item, setItem] = useState("")

  const getItemDataSaved = useCallback(() => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/rak/cetak-all/${cabang}/${area}`,{ headers }
    
    ).then(async response => {
        setItem(response.data.response.listrak.length);
        setCabangs(response.data.response.warehouse)
        let stateItem = [];
        await Promise.all(response.data.response.listrak.map(async (data) => {
            stateItem = [...stateItem, {
                rak_code: data.rak_code,
                tipe:data.tipe,
                listitem: data.listitem,
                img_area: data.img_area,
                img_sub_area: data.img_sub_area,
                img_sisi: data.img_sisi,
                img_hook: data.img_hook,
                img_total_real_location: data.img_total_real_location,
                data: {
                    rak_code: data.rak_code,
                    tipe:data.tipe,
                    // listitem: data.listitem,
                },
            }];
        }));

        setSavedItems(stateItem);
    })
},[token, cabang, area]);

useEffect(() => {
  getItemDataSaved();
}, [getItemDataSaved]);


  function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    // console.log(item)
    window.print();
    window.close();
    document.body.innerHTML = originalContents;
  }

  function clickItem(e) {
    setItem(e.target.value)
  }
  
  return (
    <div>
      <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top:"-15%" }}>
        <ModalHeader toggle={toggle} align="center">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: 'left' }}>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            Set Item
                        </Label>
                        <Input type="number" pattern="[0-9]*" value={item} min="1" max={savedItems.length} onChange={clickItem} style={{width: '70px',marginRight:'50px'}}/>
                    </div>
                      <div style={{ textAlign: 'right' }}>
                      <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            &nbsp;
                        </Label><br></br>
                          <Button color="info" target="_blank" onClick={() => {print();setTimeout(() => (window.location.reload("/admin/rak")), 1000)}}>Print</Button>
                      </div>
                  </div>
        </ModalHeader>
        <ModalBody>
        <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          {renderPDF()}
        </CardBody>
        </ModalBody>
      </Modal>
    </div>
  );

  function renderPDF(){
    let sheet = 1;
    let length = savedItems.length
    if (item !== length) {
      sheet = Math.floor(length / item);
      if((length % item) > 0){
        sheet++;
      }
    }
    const sheets = []
    for (let index = 0; index < sheet; index++) {
      let start = (item * index)
      let end = length
      let sisa = (length % item)
      if(sheet === 1){
        end = length
      }else{
        if(index < (sheet - 1) && sheet === 2){
          end = item
        }else{
          if (index === (sheet - 1)) {
            end = length - sisa + (index * item)
          }else{
            if (index === 0) {
              end = item
            }else{
              end = (index+1) * item
            }
          }
        }
      } 
      let data = savedItems.slice(start,end)

      sheets.push(
        <div className='pagebreak'>
            <Table style={{width:"100%"}} responsive>
                <tbody>
                {data.map((temp,index, key) => {
                        return (
                            <>
                            {/* {index+1} */}
                            <div className="col">
                                <KopSurat warehouseName={cabangs} />
                            </div>
                            <div className="w-100" style={{ border: "1px solid black" }}></div>
                            <Row md="12">
                                <Col md="12">
                                    <h3 className="" style={{ color:"black" }}><b><center>FORM STOK OPNAME</center></b></h3>
                                </Col>
                            </Row>
                            <div className="w-100" style={{ border: "1px solid black" }}></div>
                            <Row md="12">
                                <Col style={{marginLeft:"2%",width:"70%",flexBasis: "unset"}}>
                                
                                <div className="row align-items-center ">
                                    <div style={{width:"10%"}}  className="text-table">Kode Rak</div>
                                    <div style={{width:"2%"}}  className="text-center text-table">:</div>
                                    <div style={{width:"80%"}}  className="text-table">
                                        <b>{temp.rak_code}</b>
                                    </div>
                                </div>
                                <div className="row align-items-center ">
                                    <div style={{width:"10%"}} className="text-table">Tipe</div>
                                    <div style={{width:"2%"}} className="text-center text-table">:</div>
                                    <div style={{width:"80%"}} className="text-table">
                                        <b>{temp.tipe}</b>
                                    </div>
                                </div>
                                </Col>
                                <Col style={{width:"25%",flexBasis: "unset"}}>
                                {/* <div className="row align-items-center ">
                                    <div style={{width:"30%"}} className="text-table">Supplier</div>
                                    <div style={{width:"5%"}} className="text-table">:</div>
                                    <div style={{width:"65%"}} className="text-table">
                                        <b>{supplier}</b>
                                    </div>
                                </div> */}
                                </Col>
                            </Row>
                            <Table style={{width:"100%"}} responsive>
                                <thead>
                                <tr>
                                    <th className="text-table">
                                    <b>No</b>
                                    </th>
                                    <th className="text-table">
                                    <b>Kode</b>
                                    </th>
                                    <th className="text-table">
                                    <b>Nama</b>
                                    </th>
                                    <th className="text-table">
                                    <b>Qty</b>
                                    </th>
                                    <th className="text-table">
                                    <b>Sat</b>
                                    </th>
                                    <th className="text-table">
                                    <b>Keterangan</b>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                    {temp.listitem.map((listitemcuk,index, key) => {
                                            return (
                                                <tr>
                                                    <td className="text-table">{index+1}</td>
                                                    <td className="text-table">{listitemcuk.item_code}</td>
                                                    <td className="text-table">{listitemcuk.item_name}</td>
                                                    <td className="text-table"></td>
                                                    <td className="text-table">{listitemcuk.satuan}</td>
                                                    <td className="text-table"></td>
                                                </tr>
                                            );
                                        })
                                    }
                                    <tr>
                                    <td className="text-table">&nbsp;</td>
                                    <td className="text-table">&nbsp;</td>
                                    <td className="text-table">&nbsp;</td>
                                    <td className="text-table">&nbsp;</td>
                                    <td className="text-table">&nbsp;</td>
                                    <td className="text-table">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="text-table">&nbsp;</td>
                                        <td className="text-table">&nbsp;</td>
                                        <td className="text-table">&nbsp;</td>
                                        <td className="text-table">&nbsp;</td>
                                        <td className="text-table">&nbsp;</td>
                                        <td className="text-table">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td className="text-table">&nbsp;</td>
                                        <td className="text-table">&nbsp;</td>
                                        <td className="text-table">&nbsp;</td>
                                        <td className="text-table">&nbsp;</td>
                                        <td className="text-table">&nbsp;</td>
                                        <td className="text-table">&nbsp;</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <div ></div>
                            <row md="12"></row>
                            <Row md="20">
                                    <Col style={{ marginLeft:"2%",width:"33%" }}>
                                    <div className="row align-items-center ">
                                        <div className="col-3 text-table">AREA</div>
                                        <div className="col-1 text-center text-table"></div>
                                        <div className="col-8 text-table">
                                          <img
                                              alt="..."
                                              src={temp.img_area}
                                              width="60%"
                                            />
                                        </div>
                                    </div>
                                    <div className="row align-items-center ">
                                        <div className="col-3 text-table">SUB AREA</div>
                                        <div className="col-1 text-center text-table"></div>
                                        <div className="col-8 text-table">
                                            <img
                                              alt="..."
                                              src={temp.img_sub_area}
                                              width="60%"
                                            />
                                        </div>
                                    </div>
                                    </Col> 
                                    <Col style={{ width:"35%" }}>
                                        <div className="row align-items-center ">
                                            <div className="col-3 text-table">SISI</div>
                                            <div className="col-1 text-center text-table"></div>
                                            <div className="col-8 text-table">
                                              <img
                                                alt="..."
                                                src={temp.img_sisi}
                                                width="60%"
                                              />
                                            </div>
                                        </div>
                                        <div className="row align-items-center ">
                                            <div className="col-3 text-table">HOOK</div>
                                            <div className="col-1 text-center text-table"></div>
                                            <div className="col-8 text-table">
                                              <img
                                                alt="..."
                                                src={temp.img_hook}
                                                width="60%"
                                              />
                                            </div>
                                        </div>
                                        <div className="row align-items-center ">
                                            <div className="col-3 text-table">PARTISI</div>
                                            <div className="col-1 text-center text-table"></div>
                                            <div className="col-8 text-table">
                                              {temp.img_total_real_location === null ? 
                                              "Gambar Belum Di Update"
                                              :
                                                <img
                                                  alt="..."
                                                  src={temp.img_total_real_location}
                                                  width="60%"
                                                />
                                              }
                                             
                                            </div>
                                        </div>
                                        </Col>
                                </Row>
                                <Row md="12"></Row>
                                <div className="w-100 text-table" style={{ border: "0.5px solid black" }}></div>
                                <center className="text-table"></center>
                                <Row md="20">
                                    <Col style={{ marginLeft:"2%",width:"33%" }}>
                                    <div className="row align-items-center ">
                                        <div className="col-3 text-table">Di Cetak</div>
                                        <div className="col-1 text-center text-table">:</div>
                                        <div className="col-8 text-table">
                                            <b>{username}</b>
                                        </div>
                                    </div>
                                    <div className="row align-items-center ">
                                        <div className="col-3 text-table">Signature</div>
                                        <div className="col-1 text-center text-table">:</div>
                                        <div className="col-8 text-table">
                                            <b>------------------</b>
                                        </div>
                                    </div>
                                    </Col> 
                                    <Col style={{ width:"35%" }}>
                                        <div className="row align-items-center ">
                                            <div className="col-3 text-table">PIC</div>
                                            <div className="col-1 text-center text-table">:</div>
                                            <div className="col-8 text-table">
                                                <b>------------------</b>
                                            </div>
                                        </div>
                                        <div className="row align-items-center ">
                                            <div className="col-3 text-table">Signature</div>
                                            <div className="col-1 text-center text-table">:</div>
                                            <div className="col-8 text-table">
                                                <b>------------------</b>
                                            </div>
                                        </div>
                                        </Col>
                                </Row>
                            </>
                        );
                    })
                }
                </tbody>
            </Table>
               
                 {/* {datas.map((temp,index, key) => {
                     return (
                        <tr>
                            <td className="text-table">{index+1}</td>
                            <td className="text-table">{temp.item_code}</td>
                            <td className="text-table">{temp.item_name}</td>
                            <td className="text-table"></td>
                            <td className="text-table">{temp.satuan}</td>
                            <td className="text-table"></td>
                        </tr>
                    );

                    })
                    } */}
              
          {/* <div className="divFooter">{username}</div> */}
        </div>
      )
    }
    return (
      <div>
        {sheets}
      </div>
    );
  }

};

export default ModalCetakAll;
