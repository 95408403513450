/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  FormGroup,
  Label,
  Input, 
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";

const DetailOpnameHistori = (props ) => {
const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  const [keterangan,setKeterangan] = useState("");
  const [savedItems, setSavedItems] = useState([]);
  const [coderak, setCodeRak] = useState("")
  const [coderaks, setCodeRaks] = useState([])
  const allInfo = JSON.parse(localStorage.allinfo);
  const StokOpnames = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Stok Opname").map((p) => p.read_access));
 
  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/opname/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setKeterangan(data.data.response.keterangan);
        getItemGrup(data.data.response.code_rak)
        getItemDataSaved();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemGrup = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/rak/list/${warehouse}`,
        { headers }
      )
      .then((data) => {
        setCodeRaks(data.data.response);
        setCodeRak(id)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = () => {
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/opname/item`, {

        opname_id: props.match.params.id

      }).then(async response => {
          let stateItem = [];
          await Promise.all(response.data.response.map(async (data) => {
              stateItem = [...stateItem, {
                  item_id: data.item_id,
                  item_name:data.item_name,
                  kode_item:data.item_code,
                  satuan:data.satuan,
                  qty: data.qty_fisik,
                  ket:data.ket,
                  data: {
                    item_name: data.item_name,
                    satuan:data.satuan,
                    qty: data.qty,
                    kode_item:data.item_code,
                    ket:data.ket,
                  },
              }];
        }));
        setSavedItems(stateItem);
        
    })
  }
 
  return (
    <>
    {StokOpnames && StokOpnames === "1" ? (
      <div>
    <SimpleHeader name="Detail Opname" parentName="Inventori" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <CardBody>
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Detail Opname</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="9">
                          <FormGroup row>
                          <Label
                            for="exampleEmail"
                            sm={2}
                          >
                            Kode Rak
                          </Label>
                          <Col sm={4}>
                            <Input
                            disabled
                            autoComplete="off"
                              className="form-control-alternative"
                              name="kategory"
                              type="select"
                              value={coderak}
                              onChange={(e) => {
                                setCodeRak(e.target.value);
                              }}
                            >
                              <option value="">Kode Rak</option>
                              {coderaks.map((categorysss, key) => {
                                return (
                                  <option key={key} value={categorysss.rak_code}>
                                    {categorysss.rak_code}
                                  </option>
                                );
                              })}
                              </Input>
                          </Col>
                        </FormGroup>
                            <FormGroup row>
                              <Label
                                for="exampleEmail"
                                sm={2}
                              >
                                Keterangan
                              </Label>
                              <Col sm={10}>
                                <Input
                                disabled
                                autoComplete="off"
                                  type="textarea"
                                  name="keterangan"
                                  placeholder="Masukan Keterangan"
                                  style={{
                                    fontSize: 14,
                                    paddingTop: 20,
                                    top: "50%",
                                    height: 117,
                                    resize: "none",
                                  }}
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Card className="bg-secondary shadow">
                    <CardHeader>
                      <h3>Item </h3>
                    </CardHeader>
                    <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Qty</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Keterangan</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.kode_item}</td>
                                      <td>{savedItem.qty}</td>
                                      <td>{savedItem.satuan}</td>
                                      <td>{savedItem.ket}</td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/stock-opname/histori">
                        Kembali
                      </Link>
                </CardFooter>
              </Card>
              </CardBody>
          </div>
        </Row>
    </Container>  
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}

export default DetailOpnameHistori;