/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { 
  Card,
  Row, 
  ButtonGroup,
  CardBody, 
  CardHeader, 
  Button,
  Container,
  Modal, ModalHeader, ModalBody

} from "reactstrap";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";

const DetaiClosingKasir = (props) => {
  const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
  const warehouse = localStorage.warehouse
	const toggle2 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
  const token = localStorage.token;
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [logPrice, setLogPrice] = useState([]);
  const [listkasir, setListKasir] = useState([]);

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort);
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  };

  const updateDataTable = (page, perPage, sort) => {
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
  };

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`;
      updateDataTable(page, perPage, sort);
    }
  };

  useEffect(() => {
    getById();
    // getByIdSo();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/chasier-settlement/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        console.log(data);
        setLogPrice(data.data.response[0].closing);
        setListKasir(data.data.response[0].list);
        
        
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }
  
  const getDataLog = (id) => {
    let filter = { 
      transaction : id,
      features : "JUAL1", 
      warehouse_id : warehouse
    }
    const data = filter;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
      }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
    })
    .then(data => {
      setDataLog(data.data.response);
            setModal1(!modal1);
  
    })
      .catch(function (error) {
      setGagalAlert(error.response.data.message);
      })
    }
  
    const setGagalAlert = (id) => {
      setAlert(
      <SweetAlert
      danger
      showConfirm
      confirmBtnText="Ok"
      title={id}
      onCancel={hideAlert}
      onConfirm={hideAlert}
      />
      );
      };
      
      const hideAlert = () => {
        setAlert(null);
      };

  return (
    <div>
      {alert}
      <SimpleHeader name="Detail Closing Kasir " parentName="Penjualan" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <h3>Detail Closing Kasir</h3>
                </div>
              </CardHeader>
              <CardBody>
                <ToolkitProvider
                  rowNumber={rowIndex}
                  data={logPrice}
                  keyField="id"
                  columns={[
                    {
                      dataField: "no",
                      text: "#",
                      sort: true,
                      page: 1,
                      formatter: (cell, row, index) => {
                        let currentRow = ++index;
                        return currentRow + rowIndex;
                      },
                    },
                    {
                      dataField: "username",
                      text: "username",
                      sort: true,
                    },
                      {
                        dataField: "modal",
                        text: "Modal",
                        sort: true,
                        formatter: (value) => formatRupiah(value)
                    },
                    {
                        dataField: "pecahan_seratus",
                        text: "100",
                        sort: true,
                    },
                    {
                        dataField: "pecahan_dua_ratus",
                        text: "200",
                        sort: true,
                    },
                    {
                        dataField: "pecahan_lima_ratus",
                        text: "500",
                        sort: true,
                    },
                    {
                        dataField: "pecahan_seribu",
                        text: "1.000",
                        sort: true,
                    },
                    {
                        dataField: "pecahan_dua_ribu",
                        text: "2.000",
                        sort: true,
                    },
                    {
                        dataField: "pecahan_lima_ribu",
                        text: "5.000",
                        sort: true,
                    },
                    {
                        dataField: "pecahan_sepuluh_ribu",
                        text: "10.000",
                        sort: true,
                    },
                    {
                        dataField: "pecahan_dua_puluh_ribu",
                        text: "20.000",
                        sort: true,
                    },
                    {
                        dataField: "pecahan_lima_puluh_ribu",
                        text: "50.000",
                        sort: true,
                    },
                    {
                        dataField: "pecahan_tujuh_lima_ribu",
                        text: "75.000",
                        sort: true,
                    },
                    {
                        dataField: "pecahan_seratus_ribu",
                        text: "100.000",
                        sort: true,
                    },
                    {
                        dataField: "omzet",
                        text: "Omset",
                        sort: true,
                        formatter: (value) => formatRupiah(value)
                    },
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        remote
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        hover={true}
                        // pagination={paginationFactory({ ...paginationOption })}
                        // onTableChange={handleTableChange}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </CardBody>
              <CardBody>
                <ToolkitProvider
                  rowNumber={rowIndex}
                  data={listkasir}
                  keyField="id"
                  columns={[
                    {
                      dataField: "no",
                      text: "#",
                      sort: true,
                      page: 1,
                      formatter: (cell, row, index) => {
                        let currentRow = ++index;
                        return currentRow + rowIndex;
                      },
                    },
                    {
                      dataField: "transaction_date",
                      text: "Tanggal",
                      sort: true,
                    },
                    {
                      dataField: "nota_code",
                      text: "Nota",
                      sort: true,
                    },
                    {
                      dataField: "nominal_pajak",
                      text: "PPN",
                      sort: true,
                      formatter: (value) => formatRupiah(value)
                    },
                    {
                      dataField: "payment_total",
                      text: "Total",
                      sort: true,
                      formatter: (value) => formatRupiah(value)
                    },
                    {
                      dataField: "",
                      text: "",
                      formatter: (cell, row, index) => {
                        return (
                          <ButtonGroup>
                            <Button color="warning" onClick={() => (getDataLog(row.id))}>
                                <i className="fa fa-info-circle" aria-hidden="true"></i>
                            </Button>
                          </ButtonGroup>
                        );
                      },
                    },
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        remote
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        hover={true}
                        // pagination={paginationFactory({ ...paginationOption })}
                        // onTableChange={handleTableChange}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card> 
          </div>
        </Row>
      </Container>
      <Modal isOpen={modal1} toggle={toggle2} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle2} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle2}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>
    </div>
  );  
};

export default DetaiClosingKasir;
