/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
	Col,
	Button,
	Container,
	Form,
	Modal, ModalHeader, ModalBody
} from "reactstrap";
import { Link} from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Halaman404 from "views/404";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import SweetAlert from "react-bootstrap-sweetalert";

export default function Userss() {
	const [alert, setAlert] = useState(null);
	const [datalog, setDataLog] = useState([])
	const [rowIndex, setRowIndex] = useState(0);
	const toggle1 = () => setModal1(!modal1);
	const [modal1, setModal1] = useState(false);
	const token = localStorage.token;
	const warehouseId = localStorage.warehouse;
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
    const [itemId, setItemId] = useState(1);
    const [allItem, setAllItem] = useState([]);
    const [query, setQuery] = useState(null);
    const [isSearchShow, setIsSearchShow] = useState(false);
    const headers = { Authorization: `Bearer ${token}` };
	const redirectPrefix = `/admin/bank/edit/`;
    const [accountname,setAccountName] = useState("");
    const [accountnumber,setAccountNumber] = useState("");
    const [bankname,setBankName] = useState("");
	const allInfo = JSON.parse(localStorage.allinfo);
	const bank = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Bank").map((p) => p.read_access));
	const CreateButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Bank").map((p) => p.create_access));
	const UpdateButton = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Bank").map((p) => p.update_access));
	  
	useEffect(() => {
		getItems();
	}, [page]);

	//get list item berdasarkan warehouse
	const getItems = async () => {
		let filter = {
			page: page,
			per_page: 1,
			warehouse_id: parseInt(warehouseId),
		};
		const data = filter;
		const res = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/bank`, data, { headers });
		if(res.data.response.id !== null){
			getById(res.data.response[0].id);
			setItemId(res.data.response[0].id);
			setTotalPages(res.data.total_page);
		}
	};

	//cari item
	useEffect(() => {
		const getData = setTimeout(async() => {
			if(query != ''){
				axios
				.post(`${process.env.REACT_APP_API_BASE_URL}/bank`, { account_name: query , per_page: 10, warehouse_id: parseInt(warehouseId),  },{ headers })
				.then((res) => {
					setAllItem(res.data);
					setIsSearchShow(true);
				})
				.catch(function (error) {

				});
			}
		  }, 1000)
		  return () => clearTimeout(getData)
	}, [query]);

	  const getById = (id) => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/bank/get/${id}`,
	        { headers }
	      )
	      .then((data) => {
			setAccountName(data.data.response.account_name);
            setAccountNumber(data.data.response.account_number);
            setBankName(data.data.response.bank_name);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };
	

	const searchShow = (item) => {
		setItemId(item.id);
		getById(item.id);
		setIsSearchShow(false);
	};

	const getDataLog = (id) => {
		let filter = { 
		  transaction : id,
		  features : "MASTER12",
		  warehouse_id : warehouseId
		}
		const data = filter;
		const headers = {
		  'Content-Type': 'application/json',
		  Authorization: `Bearer ${token}`
		  }
		axios.post(`${process.env.REACT_APP_API_BASE_URL}/riwayat-data/info`, data, { headers
		})
		.then(data => {
		  setDataLog(data.data.response);
		  setModal1(!modal1);
	  
		})
		  .catch(function (error) {
		  setGagalAlert(error.response.data.message);
		  })
		}
	  
		const setGagalAlert = (id) => {
		setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
		);
		};
		
		const hideAlert = () => {
		  setAlert(null);
		};

	return (
		<>
		{alert}
			{bank && bank === "1" ? (
      <div>
				<SimpleHeader name="Bank" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
							<Form>
								<Card className="position-sticky boxShadow" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
											<Col md="5 d-lg-block d-none">
												<Button onClick={() => setPage(1)} disabled={page === 1} color="danger" type="button">
													First
												</Button>
												<Button onClick={() => setPage((page) => page - 1)} disabled={page === 1} color="success" type="button">
												<i className="fa fa-angle-left" /> Prev
												</Button>
												<Button onClick={() => setPage((page) => page + 1)} disabled={page >= totalPages} color="success" type="button">
													Next <i className="fa fa-angle-right" />
												</Button>
												<Button onClick={() => setPage(totalPages)} disabled={page == totalPages} color="warning" type="button">
													End
												</Button>
											</Col>
											<Col md="4">
												<FormGroup row>
													<Col sm={7}>
														<Input
															// className="form-control-alternative"
															placeholder="Search Pemilik rekening"
															type="search"
															value={query}
															onChange={(e) => setQuery(e.target.value)}
														/>
													</Col>
													<Col sm={2}>
													</Col>
												</FormGroup>
											</Col>
											<Col md="3 d-lg-block d-none">
												<div style={{ textAlign: "right" }}>
												{UpdateButton && UpdateButton === "1" && (
													<Link className="btn btn-info"
														to={redirectPrefix + itemId}
													>
														<i className="fas fa-user-edit" /> Edit
													</Link>
												)}
													{CreateButton && CreateButton === "1" && (
													<Link className="btn btn-danger" to="/admin/bank/create">
														<i className="fas fa-plus" /> Tambah
													</Link>
													)}
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>

								{/* Search card */}
								{isSearchShow && query && (
									<Card className="boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
										<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
											<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShow(false)}></i>
										</div>
										<span className="text-center mb-3">
											<b>Pencarian berdasarkan:</b> {query}
										</span>
										{allItem?.response ? (
											allItem.response.map((item) => (
												<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" style={{minHeight: 'auto'}} onClick={() => {searchShow(item); setQuery('')}}>
													<div>
														<b>Nama :</b> {item.account_name}
													</div>
													<div>
														<b>Bank:</b> {item.bank_name ? item.bank_name : "(Not available)"}
													</div>
												</CardBody>
											))
										) : (
											<div className="text-center mb-3 text-danger">Nama "{query}" tidak ada bosku!</div>
										)}
									</Card>
								)}
								<Card className="bg-secondary shadow">
									<CardHeader className="bg-white border-0">
									<div
											style={{ display: "flex", justifyContent: "space-between" }}
										>
											<h3>Bank</h3>
											<div style={{ textAlign: "rigth" }}>
												<Button color="warning" onClick={() => (getDataLog(itemId))}>
												<i className="fa fa-info-circle" aria-hidden="true"></i>
												</Button>
											</div>
										</div>
									</CardHeader>
									<CardBody>
										<Row md="12">
											<Col md="6">
												<FormGroup row>
												<Label
												className="form-control-label"
													for="exampleEmail"
													sm={4}
												>
													Nama Pemilik
												</Label>
												<Col sm={7}>
													<Input
													disabled
													autoComplete="off"
													type="text"
													name="desc"
													placeholder="Masukan Nama Akun"
													value={accountname}
													onChange={(e) => {
														setAccountName(e.target.value);
													}}
													/>
												</Col>
												</FormGroup>
												<FormGroup row>
												<Label
												className="form-control-label"
													for="exampleEmail"
													sm={4}
												>
													Nomor Rekening 
												</Label>
												<Col sm={7}>
													<Input
													disabled
													autoComplete="off"
													type="text"
													name="desc"
													placeholder="Masukan Nomer Akun"
													value={accountnumber}
													onChange={(e) => {
														setAccountNumber(e.target.value);
													}}
													/>
												</Col>                             
												</FormGroup>
											</Col>
											<Col md="6">
												<FormGroup row>
												<Label
												className="form-control-label"
													for="exampleEmail"
													sm={3}
												>
													Bank 
												</Label>
												<Col sm={6}>
													<Input
													disabled
													autoComplete="off"
													type="select"
													name="bank"
													placeholder="Masukan Bank"
													value={bankname}
													onChange={(e) => {
														setBankName(e.target.value);
													}}
													>
														<option value="">Pilih Bank</option>
														<option value="MANDIRI">MANDIRI</option>
														<option value="BCA">BCA</option>
														<option value="BRI">BRI</option>
														<option value="BNI">BNI</option>
														<option value="JATIM">JATIM</option>
														<option value="BTN">BTN</option>
														<option value="DANAMON">DANAMON</option>
														<option value="CIMB">CIMB</option>
														<option value="SINARMAS">SINARMAS</option>
														<option value="MEGA">MEGA</option>
														<option value="BSI">BSI</option>
														<option value="BTPN">BTPN</option>
													</Input>
												</Col>
												</FormGroup>
											</Col>
										</Row>
									</CardBody> 
								</Card>
							</Form>
						</div>
					</Row>
				</Container>
				<Modal isOpen={modal1} toggle={toggle1} style={{ minWidth: "70%", top: "5%"  }}>
        <ModalHeader toggle={toggle1} className="text-center" align="center"></ModalHeader>
        <ModalBody align="center">
          <h2><b>Log Info</b></h2> 
          <CardBody>
          <ToolkitProvider 
              rowNumber={rowIndex}
              data={datalog}
              keyField="id"
              columns={[
                  {
                  dataField: "no",
                  text: "#",
                  sort: true,
                  page: 1,
                  formatter: (cell, row, index) => {
                      let currentRow = ++index;
                      return currentRow + rowIndex;
                  },
                  },
				  {
					dataField: "api_hit",
					text: "Tanggal Update",
					sort: true,
					},
                  {
                  dataField: "username",
                  text: "PIC",
                  sort: true,
                  },
                  {
                  dataField: "lost_data",
                  text: "Data Lama",
                  sort: true,
                  },
                  {
                  dataField: "show_data",
                  text: "Data Baru",
                  sort: true,
                  },
              ]}
              >
              {(props) => (
                  <div className="py-4 table-responsive">
                  <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      hover={true}
                  />
                  </div>
              )}
              </ToolkitProvider>
          </CardBody>
          <Button color="secondary" onClick={toggle1}>
            Kembali
          </Button>
        </ModalBody>
      </Modal> 
			</div>
			):(
				<Halaman404 />
			)}
		</>
	);
}
