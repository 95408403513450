/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Input
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

export default function EditReceivingPo(props){
  const [alert, setAlert] = React.useState(null);
  const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [persons, setPersons] = useState([]);
  const [person, setPerson] = useState("");
  const [tanggal, setTanggal] = useState("");
  const [codereceiving, setCodeReceiving] = useState("");
  const [codepo, setCodePo] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [savedItems, setSavedItems] = useState([]);
  const [editingItem, setEditingitem] = useState([]);
  const [ongkir, setOngkir] = useState([]);
  const [lainnya, setLainnya] = useState([]);
	const [qtyTotal, setTotalQty] = useState(0);
  const [qtyError, setQtyError] = useState(null);
  const allInfo = JSON.parse(localStorage.allinfo);
  const receivingPOs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Receiving PO").map((p) => p.read_access));
  
  const validateForm = () => {
      let error = false;
      savedItems.map((savedItem, key) => { 
        if (savedItem.qty > savedItem.qtysisa) {
          setQtyError("invalid");
          error = true;
        }
      })
      return error;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
        setQuestionAlert()
    }
  }

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/receiving-po/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        getItemDataSaved();
        getPerson(data.data.response.person_id);
        setTanggal(data.data.response.created_at);
        setOngkir(data.data.response.ongkir);
        setLainnya(data.data.response.lainnya);
        setCodeReceiving(data.data.response.receiving_code);
        setCodePo(data.data.response.code_po);
        setTotalQty(data.data.response.price_total);
        setKeterangan(data.data.response.keterangan);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/receiving-po/item`, {

        receiving_id: props.match.params.id

    }).then(async response => {
        let stateItem = [];
        let stateEditing = [];
        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
              item_id: data.item_id,
              item_name:data.item_name,
              item_code:data.item_code,
              satuan:data.satuan,
              qtysisa: data.max_qty,
              qty: data.qty,
              harga:data.harga,
              diskon_nominal: data.diskon_nominal,
              diskon_persen: data.diskon_persen,
              note : data.note,
              data: {
                  item_name: data.item_name,
                  item_code:data.item_code,
                  satuan:data.satuan,
                  qtysisa: data.max_qty,
                  qty: data.qty,
                  note: data.note,
              },
          }]; stateEditing = [...stateEditing, {
            editing: false
        }];
    }));
    setEditingitem(stateEditing);
    setSavedItems(stateItem);
  })
}

useEffect(() => {
  calculateTotalQty();
}, [savedItems]);

const calculateTotalQty = () => {
  const newTotalQty = savedItems.reduce((total, item) => {
    const itemTotal = (parseInt(item.harga) - parseInt(item.diskon_nominal)) * parseInt(item.qty);
    return total + itemTotal;
  }, 0);
  setTotalQty(newTotalQty);
};


  const handleEditQty = (index, value) => {
    if (!isNaN(value) && value.length > 0) {
      let updateList = [...savedItems];
      updateList[index] = { ...updateList[index], qty: value };
      setSavedItems(updateList);
    } else {
      return false;
    }
  }

  const handleEditNote = (index, value) => {
    let updateList = [...savedItems];
    if (value) {
      updateList[index] = { ...updateList[index], note: value };
      setSavedItems(updateList);
    } else {
      return false;
    }
  }
  

const handleEditQty1 = (index, value) => {
    let updateList = savedItems;
    let aqtyTotala = parseInt(updateList[index].harga)* value ;
    let aqtyTotal = parseInt(updateList[index].harga) * parseInt(updateList[index].qty) ;
    if(!isNaN(value)&&value.length>0){
      updateList[index] = {...updateList[index], qty: value};
      setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
      setSavedItems(updateList);
    }else{return false}
  }

  const deleteItem = (id) => {
    let array = [...savedItems];

    let index = array.findIndex(object => {
      return object.item_id === id;
    });

    if (index !== -1) {
      
      array.splice(index, 1);
      setSavedItems(array);
    }
  }


  const getPerson = (id) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/person/list`,
          { headers }
        )
        .then((data) => {
          setPersons(data.data.response);
          setPerson(id);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const handleSubmit2 = () => {
      hideAlert();
      setTimeout(() => {EditData()}, 1000);
    };


    function EditData() {
        let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga:dataItem.harga,
                diskon_nominal: dataItem.diskon_nominal,
                diskon_persen: dataItem.diskon_persen,
                note : dataItem.note
            }]);
    let data = {
      warehouse_id : parseInt(warehouse),
      username : username,
      code_po: codepo,
      ongkir: parseInt(ongkir),
      lainnya: parseInt(lainnya),
      receiving_code: codereceiving,
      person_id: parseInt(person),
      status_receive : 3, 
      keterangan: keterangan ,
      items : dataItems,
          };
        axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/receiving-po/update/${props.match.params.id}`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(function (response) {
            setSuccessAlert(response.data.message);
            setTimeout(() => (history.push("/admin/receiving-po/page")), 1000);
          })
          .catch(function (error) {
                setGagalAlert(error.response.data.message);
          });
    }
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }
  
  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };
  
  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={handleSubmit2}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };
  
    const hideAlert = () => {
      setAlert(null);
    };

      const changePriceStatus = (index, status) => {
        setEditingitem([
            ...editingItem.slice(0, index),
            Object.assign({}, editingItem[index], { editing: status }),
            ...editingItem.slice(index + 1)
        ]);
      }
      
      const changeItemDataTable = async (arg) => {
          setSavedItems([
              ...savedItems.slice(0, arg.index),
              Object.assign({}, savedItems[arg.index], {
                  data: {
                      item_name: arg.itemName,
                      item_code : arg.item_code,
                      satuan : arg.satuan,
                      qty:savedItems[arg.index].qty,
                      harga : savedItems[arg.index].harga,
                      diskon_nominal: savedItems[arg.index].diskon_nominal,
                      diskon_persen : savedItems[arg.index].diskon_persen,
                  }
              }),
              ...savedItems.slice(arg.index + 1)
          ]);
          changePriceStatus(arg.index, false);
      }

      const formatRupiah = (money) => {
        return new Intl.NumberFormat('id-ID',
            { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
        ).format(money);
      }

  return (
    <>
    {alert}
    {receivingPOs && receivingPOs === "1" ? (
      <div>
    <SimpleHeader name="Edit BPB Pembelian" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Edit BPB Pembelian</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Tanggal
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                autoComplete="off"
                                disabled
                                    type="text"
                                    name="desc"
                                    placeholder="Masukan Tanggal"
                                    value={tanggal}
                                />
                              </Col>                             
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode BPB
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                autoComplete="off"
                                disabled
                                    type="text"
                                    name="desc"
                                    placeholder="Masukan Kode Receiving"
                                    value={codereceiving}
                                />
                              </Col>                             
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode PO
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                autoComplete="off"
                                disabled
                                    type="text"
                                    name="desc"
                                    placeholder="Masukan Kode PO"
                                    value={codepo}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Supplier
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                autoComplete="off"
                                  name="person"
                                  type="select"
                                  value={person}
                                >
                                  <option value=''>Pilih Supplier</option>
                                  {persons.map((person, key) => {
                                      return (
                                        <option key={key} value={person.id}>
                                          {person.person_name}
                                        </option>
                                      );
                                    })}
                                </Input>
                              </Col>
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Keterangan
                              </Label>
                              <Col sm={6}>
                                <Input
                                disabled
                                className="form-control-alternative"
                                autoComplete="off"
                                  type="textarea"
                                  rows = "7"
                                  name="desc"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                <Form onSubmit={handleSubmit}>
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                    <CardBody>
                   <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th><b>Nama Item</b></th>
                            <th><b>Kode Item</b></th>
                            <th><b>Qty</b></th>
                            <th><b>Satuan</b></th>
                            <th><b>Note</b></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="qty"
                                          type="number"
                                          invalid={qtyError === "invalid"}
                                          value={savedItem.qty}
                                          onChange={(e) => {
                                              handleEditQty(key, e.target.value);
                                              if (savedItem.qty > savedItem.qtysisa) {
                                                setQtyError("");
                                              }
                                          }}/>
                                           <FormFeedback>
                                              Max Barang {savedItem.qtysisa} 
                                          </FormFeedback>
                                      </td>
                                      <td>{savedItem.satuan}</td>
                                      <td>
                                          <Input
                                          className="form-control-alternative"
                                          placeholder="Note"
                                          type="textarea"
                                          value={savedItem.note}
                                          onChange={(e) => {
                                            handleEditNote(key, e.target.value);
                                          }}/>
                                      </td>
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                        </Table>
                    </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/receiving-po/page">
                        Kembali
                      </Link>
                      <Button color="danger" type="submit">
                          Simpan
                      </Button>
                </CardFooter>
                </Form>
              </Card>
          </div>
        </Row>
    </Container>
    </div>
    ):(
      <Halaman404 />
    )}  
    </>
  );
}
