import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input
} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";
// import styles from '../../../../../assets/scss/Print.scss'
// import moment from "moment";

const ModalCetakRak = ({ open, toggle, data }) => {
    const token = localStorage.token;
    // const username = localStorage.username;
    const [savedItems, setSavedItems] = useState([]);
    const [codepo, setCodePo] = useState("");
    const [warehouserfq, setWarehouseRfq] = useState("");
    const [waktu,setWaktu] = useState("");
    const [validator, setValidator] = useState("");
    const [item, setItem] = useState("")


  

  const getById = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/rak/cetak/${data.id}`,
        { headers }
      )
      .then((data) => {  
        setWaktu(data.data.response.rak.waktu_cetak)
        setCodePo(data.data.response.rak.rak_code);
        setValidator(data.data.response.rak.user_cetak);
        setWarehouseRfq(data.data.response.rak.warehouse);
        setItem(data.data.response.list.length);
       
      })
      .catch(function (error) {
        console.log(error);
      });
  },[token, data.id]);

  const getItemDataSaved = useCallback((id) => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/rak/cetak/${data.id}`,{ headers }
    
    ).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.list.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                qty: data.qty,
                harga: data.harga,
                satuan: data.satuan,
                diskon_nominal: data.diskon_nominal,
                diskon_persen : data.diskon_persen,
                sub_total : data.sub_total,
                data: {
                    item_name: data.item_name,
                    harga: data.harga
                },
            }];
        }));

        setSavedItems(stateItem);
    })
},[token, data.id]);

useEffect(() => {
  getById();
  getItemDataSaved();
}, [getById,getItemDataSaved]);

  function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    // console.log(item)
    window.print();
    window.close();
    document.body.innerHTML = originalContents;
  }

  function clickItem(e) {
    setItem(e.target.value)
  }
  
  return (
    <div>
      <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top:"-15%" }}>
        <ModalHeader toggle={toggle} align="center">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ textAlign: 'left' }}>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            Set Item
                        </Label>
                        <Input type="number" pattern="[0-9]*" value={item} min="1" max={savedItems.length} onChange={clickItem} style={{width: '70px',marginRight:'50px'}}/>
                    </div>
                      <div style={{ textAlign: 'right' }}>
                      <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            &nbsp;
                        </Label><br></br>
                          <Button color="info" target="_blank" onClick={() => {print();setTimeout(() => (window.location.reload("/admin/rak")), 1000)}}>Print</Button>
                      </div>
                  </div>
        </ModalHeader>
        <ModalBody>
        <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          {renderPDF()}
        </CardBody>
        </ModalBody>
      </Modal>
    </div>
  );

  function renderPDF(){
    let sheet = 1;
    let length = savedItems.length
    if (item !== length) {
      sheet = Math.floor(length / item);
      if((length % item) > 0){
        sheet++;
      }
    }
    const sheets = []
    for (let index = 0; index < sheet; index++) {
      let start = (item * index)
      let end = length
      let sisa = (length % item)
      if(sheet === 1){
        end = length
      }else{
        if(index < (sheet - 1) && sheet === 2){
          end = item
        }else{
          if (index === (sheet - 1)) {
            end = length - sisa + (index * item)
          }else{
            if (index === 0) {
              end = item
            }else{
              end = (index+1) * item
            }
          }
        }
      } 
      let data = savedItems.slice(start,end)
      // console.log(data.length)
      let sub_total = 0;
      let diskon = 0;
      // let pajak = 0;
    //   let username = moment().format("YYYY-MM-DD hh:mm:ss") + " " + localStorage.username;
      sheets.push(
        <div className='pagebreak'>
          <div className="col">
            <KopSurat warehouseName={warehouserfq} />
          </div>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          <Row md="12">
              <Col md="12">
                  <h3 className="" style={{ color:"black" }}><b><center>FORM RAK</center></b></h3>
              </Col>
          </Row>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          
          <Row md="12">
            <Col style={{marginLeft:"2%",width:"70%",flexBasis: "unset"}}>
             
              <div className="row align-items-center ">
                  <div style={{width:"8%"}}  className="text-table">Tanggal</div>
                  <div style={{width:"2%"}}  className="text-center text-table">:</div>
                  <div style={{width:"85%"}}  className="text-table">
                      <b>{waktu}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"8%"}} className="text-table">Kode Rak</div>
                  <div style={{width:"2%"}} className="text-center text-table">:</div>
                  <div style={{width:"85%"}} className="text-table">
                      <b>{codepo}</b>
                  </div>
              </div>
            </Col>
            <Col style={{width:"25%",flexBasis: "unset"}}>
              {/* <div className="row align-items-center ">
                  <div style={{width:"30%"}} className="text-table">Supplier</div>
                  <div style={{width:"5%"}} className="text-table">:</div>
                  <div style={{width:"65%"}} className="text-table">
                      <b>{supplier}</b>
                  </div>
              </div> */}
            </Col>
          </Row>
              <Table style={{width:"100%"}} responsive>
                <thead>
                <tr>
                    <th className="text-table">
                    <b>No</b>
                    </th>
                    <th className="text-table">
                    <b>Kode</b>
                    </th>
                    <th className="text-table">
                    <b>Nama</b>
                    </th>
                    <th className="text-table">
                    <b>Qty</b>
                    </th>
                    <th className="text-table">
                    <b>Sat</b>
                    </th>
                    <th className="text-table">
                    <b>Keterangan</b>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    data.map((temp,index, key) => {
                        let item_name =  temp.data.item_name
                        sub_total = sub_total + temp.sub_total
                        diskon = diskon + temp.diskon_nominal
                        return (
                            <tr>
                                <td className="text-table">{index+1}</td>
                                <td className="text-table">{temp.item_code}</td>
                                <td className="text-table">{item_name}</td>
                                <td className="text-table"></td>
                                <td className="text-table">{temp.satuan}</td>
                                <td className="text-table"></td>
                            </tr>
                        );
                    })
                }
                </tbody>
              </Table>
              <div ></div>
              <Row md="12">
              </Row>
          <div className="w-100 text-table" style={{ border: "0.5px solid black" }}></div>
          <center className="text-table">Terms of Price, delivery & shipping required</center>
          <Row md="20">
            <Col style={{ marginLeft:"2%",width:"33%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Di Cetak</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>{validator}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col> 
          </Row>
          {/* <div className="divFooter">{username}</div> */}
        </div>
      )
    }
    return (
      <div>
        {sheets}
      </div>
    );
  }

};

export default ModalCetakRak;
