import React, { useCallback, useEffect, useState } from "react";
import {Button,Row,Col,CardBody,Table,Modal,ModalHeader,ModalBody,} from "reactstrap";
import KopSurat from "views/components/KopSurat";
import axios from "axios";

const ModalCetakSuratJalanCustomer = ({ open, toggle1, data }) => {
    const token = localStorage.token;
    const username = localStorage.username;
    const [savedItems, setSavedItems] = useState([]);
    const [download, setDownload] = useState("")
    // const [alert, setAlert] = React.useState(null);
    const [codepo, setCodePo] = useState("");
    const [keterangan, setKeterangan] = useState("");
    const [supplier, setSupplier] = useState("");
    const [warehouserfq, setWarehouseRfq] = useState("");
    const [waktu,setWaktu] = useState([]);
    const [validator, setValidator] = useState("");
    const [address, setAddress] = useState("")
    const [phone, setPhone] = useState("")
    const [driver, setDriver] = useState("");
    const [helper, setHelper] = useState("");
    const [nopol, setNopol] = useState("");


  const getById = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/surat-jalan-cashier/cetak-customer/${data.id}`,
        { headers }
      )
      .then((data) => {  
        setAddress(data.data.response.sj.address)
        setPhone(data.data.response.sj.phone)
        setSupplier(data.data.response.sj.person);
        setDownload(data.data.response.sj.counter);
        setWaktu(data.data.response.sj.created)
        setWarehouseRfq(data.data.response.sj.warehouse);
        setKeterangan(data.data.response.sj.keterangan);
        setCodePo(data.data.response.sj.sjc_code);
        setValidator(data.data.response.sj.validator1);
        setNopol(data.data.response.sj.nopol);
        setDriver(data.data.response.sj.driver);
        setHelper(data.data.response.sj.helper);
        setSupplier(data.data.response.sj.customer);
        setWaktu(data.data.response.sj.created)
        setWarehouseRfq(data.data.response.sj.warehouse);
        setKeterangan(data.data.response.sj.keterangan);
       
      })
      .catch(function (error) {
        console.log(error);
      });
  },[token, data.id]);

  const getItemDataSaved = useCallback(() => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/surat-jalan-cashier/cetak-customer/${data.id}`,{ headers }
    
    ).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.list.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                qty: data.qty,
                harga: data.harga,
                satuan: data.satuan,
                diskon_nominal: data.diskon_nominal,
                diskon_persen : data.diskon_persen,
                sub_total : data.sub_total,
                data: {
                    item_name: data.item_name,
                    harga: data.harga
                },
            }];
        }));

        setSavedItems(stateItem);
    })
},[token, data.id]);

useEffect(() => {
  getById();
  getItemDataSaved();
}, [getById,getItemDataSaved]);


  function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }
  
  return (
    <div>
      <Modal isOpen={open} toggle={toggle1} style={{ minWidth: "70%", top:"-15%" }}>
        <ModalHeader toggle={toggle1} align="center">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div style={{ textAlign: 'right' }}>
                          <Button color="info" onClick={() => {print();setTimeout(() => (window.location.reload("/admin/surat-jalan-kasir/histori")), 1000)}}>Print</Button>
                      </div>
                  </div>
        </ModalHeader>
        <ModalBody>
          <CardBody
            className="border"
            id="targetContent"
            style={{ width: "100%" }}
          >
            <div className="col">
              <KopSurat 
              warehouseName={warehouserfq}
              // user={username}
              />
            </div>
            <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                          <Row md="12">
                              <Col md="12">
                                  <h3><b><center>SURAT JALAN KASIR</center></b></h3>
                              </Col>
                          </Row>
                          <div className="w-100" style={{ border: "1px solid #b3b3b3" }}></div>
                          <br></br>
                          <Row md="12">
                              <Col md="5">
                              <div className="row align-items-center mb-1">
                                  <div className="col-3">Tanggal</div>
                                  <div className="col-1 text-center">:</div>
                                  <div className="col-4 ">
                                      <b>{waktu}</b>
                                  </div>
                              </div>
                              <div className="row align-items-center mb-1">
                                  <div className="col-3">Kode SJ</div>
                                  <div className="col-1 text-center">:</div>
                                  <div className="col-5 ">
                                      <b>{codepo}</b>
                                  </div>
                              </div>
                              <div className="row align-items-center mb-1">
                                  <div className="col-3">Nopol</div>
                                  <div className="col-1 text-center">:</div>
                                  <div className="col-5 ">
                                      <b>{nopol}</b>
                                  </div>
                              </div>
                              <div className="row align-items-center mb-1">
                                  <div className="col-3">Keterangan</div>
                                  <div className="col-1 text-center">:</div>
                                  <div className="col-7 ">
                                      <b>{keterangan}</b>
                                  </div>
                              </div>
                              </Col>
                              <Col md="1">
                              </Col>
                              <Col md="6">
                              <div className="row align-items-center mb-1">
                                  <div className="col-3">Customer</div>
                                  <div className="col-1 text-center">:</div>
                                  <div className="col-7 ">
                                      <b>{supplier}</b>
                                  </div>
                              </div>
                              <div className="row align-items-center mb-1">
                                  <div className="col-3">Alamat</div>
                                  <div className="col-1 text-center">:</div>
                                  <div className="col-7 ">
                                      <b>{address}</b>
                                  </div>
                              </div>
                              <div className="row align-items-center mb-1">
                                  <div className="col-3">Phone</div>
                                  <div className="col-1 text-center">:</div>
                                  <div className="col-7 ">
                                      <b>{phone}</b>
                                  </div>
                              </div>
                              </Col>
                          </Row>
                      <CardBody>
                        <br></br>
                        <Table size="sm" responsive>
                          <thead>
                          <tr>
                              <th>
                              <b>Nama Item</b>
                              </th>
                              <th>
                              <b>Kode Item</b>
                              </th>
                              <th>
                              <b>Qty</b>
                              </th>
                              <th>
                              <b>Satuan</b>
                              </th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                              savedItems.map((savedItem, key) => {
                                  return (
                                      <tr key={key}>
                                          <td>{savedItem.data.item_name}</td>
                                          <td>{savedItem.item_code}</td>
                                          <td>{savedItem.qty}</td>
                                          <td>{savedItem.satuan}</td>
                                      </tr>
                                  )
                              })
                          }
                          </tbody>
                        </Table>
                      </CardBody>
                      <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                          <center>Terms of Price, delivery & shipping required</center>
                          <br></br>
                                  <Row md="20">
                                      <Col md="4">
                                      <div className="row align-items-center mb-3">
                                          <div className="col-5">Kepala Gudang</div>
                                          <div className="col-1 text-center">:</div>
                                          <div className="col-5 ">
                                              <b>{validator}</b>
                                          </div>
                                      </div>
                                      <div className="row align-items-center mb-3">
                                          <div className="col-5">Signature</div>
                                          <div className="col-1 text-center">:</div>
                                          <div className="col-4 ">
                                              <b>------------</b>
                                          </div>
                                      </div>
                                      </Col>
                                      <Col md="4">
                                      <div className="row align-items-center mb-3">
                                          <div className="col-4">Driver</div>
                                          <div className="col-1 text-center">:</div>
                                          <div className="col-4 ">
                                              <b>{driver}</b>
                                          </div>
                                      </div>
                                      <div className="row align-items-center mb-3">
                                          <div className="col-4">Signature</div>
                                          <div className="col-1 text-center">:</div>
                                          <div className="col-4 ">
                                              <b>------------</b>
                                          </div>
                                      </div>
                                      </Col>
                                      <Col md="4">
                                      <div className="row align-items-center mb-3">
                                          <div className="col-4">Helper</div>
                                          <div className="col-1 text-center">:</div>
                                          <div className="col-4 ">
                                              <b>{helper}</b>
                                          </div>
                                      </div>
                                      <div className="row align-items-center mb-3">
                                          <div className="col-4">Signature</div>
                                          <div className="col-1 text-center">:</div>
                                          <div className="col-4 ">
                                              <b>------------</b>
                                          </div>
                                      </div>
                                      </Col>
                                  </Row>
                                  <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                        {download === 0 ? (
                          <div className="row align-items-center mb-3">
                          <div className="col-4"><i>Download Original oleh {username}</i></div>
                          </div>
                        ):(
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Di Download</i> {download} Kali Oleh {username}</div>
                      </div>
                        )}
          </CardBody>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalCetakSuratJalanCustomer;
