import React, { useCallback,useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  CardBody,
  Label,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  //CardHeader,
  //CardFooter,
  // Input,
} from "reactstrap";
// import * as FileSaver from "file-saver";
import KopSurat from "views/components/KopSurat";
import axios from "axios";
import moment from "moment";

const ModalCetakReturPo = ({ open, toggle, data }) => {
    const token = localStorage.token;
    const username = localStorage.username;
    const [savedItems, setSavedItems] = useState([]);
    const [codepo, setCodePo] = useState("");
    const [keterangan, setKeterangan] = useState("");
    const [supplier, setSupplier] = useState("");
    const [warehouserfq, setWarehouseRfq] = useState("");
    // const [coderfq, setCodeRfq] = useState([]);
    const [ongkir, setOngkir] = useState(0);
    const [waktu,setWaktu] = useState([]);
    const [hargatotal, setHargaTotal] = useState([]);
    const [validator, setValidator] = useState("");
    const [validator1, setValidator1] = useState("");
    const [address, setAddress] = useState("")
    const [phone, setPhone] = useState("")
    const [bank, setBank] = useState("")
    const [rekening, setRekening] = useState("")
    const [pricereal, setPriceReal]= useState("")
    const [diskontotal, setDiskonTotal] = useState("")
    const [pajak, setPajak] = useState("");
    const [ppn, setPPN] = useState("")
    // const [item, setItem] = useState("")
    const [creator, setCreator] = useState("")
    const [download, setDownload] = useState("")

 

  const getById = useCallback(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/po-retur/cetak/${data.id}`,
        { headers }
      )
      .then((data) => {  
        // setItem(data.data.response.list.length);
        setDownload(data.data.response.returpo.counter);
        // setCodeRfq(data.data.response.returpo.code_rfq);
        setCreator(data.data.response.returpo.creator)
        setAddress(data.data.response.returpo.address)
        setPhone(data.data.response.returpo.phone)
        setSupplier(data.data.response.returpo.person);
        setWaktu(data.data.response.returpo.created)
        setWarehouseRfq(data.data.response.returpo.warehouse);
        setDiskonTotal(data.data.response.returpo.diskon_total);
        setPajak(data.data.response.returpo.nominal_pajak);
        setHargaTotal(data.data.response.returpo.payment_total);
        setPriceReal(data.data.response.returpo.price_total);
        setKeterangan(data.data.response.returpo.keterangan);
        setOngkir(data.data.response.returpo.ongkir);
        setCodePo(data.data.response.returpo.retur_code);
        setValidator(data.data.response.returpo.validator1);
        setValidator1(data.data.response.returpo.validator2);
        setBank(data.data.response.returpo.bank_account1);
        setRekening(data.data.response.returpo.bank_number1);
        setPPN(data.data.response.returpo.persen_pajak)
        // getItemDataSaved(data.data.response.returpo.id)
      })
      .catch(function (error) {
        console.log(error);
      });
  },[token,data.id]);

  const getItemDataSaved = useCallback(() => {
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/po-retur/cetak/${data.id}`,{ headers }
    
    ).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.list.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                qty: data.qty,
                harga: data.harga,
                note : data.note,
                satuan: data.satuan,
                diskon_nominal: data.diskon_nominal,
                diskon_persen : data.diskon_persen,
                sub_total : data.sub_total,
                data: {
                    item_name: data.item_name,
                    harga: data.harga
                },
            }];
        }));

        setSavedItems(stateItem);
    })
},[token,data.id])

useEffect(() => {
  getById();
  getItemDataSaved();
}, [getById,getItemDataSaved]);

const HitCounter = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/po-retur/hit-counter/${data.id}`,
        { headers }
      )
      .then((data) => {  
        console.log("suskes Cuk");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const downloadExcel = async ()=> {
  //   const J = "PO-RETUR"
  //   const fileName = J;
  //   const headers = {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${token}`,
  //   };
  //   axios
  //     .get(`${process.env.REACT_APP_API_BASE_URL}/po-retur/cetak/${data.id}`,{headers})
  //     .then((data) => {
  //       setCodeRfq(data.data.response.returpo.code_rfq);
  //       setAddress(data.data.response.returpo.address)
  //       setPhone(data.data.response.returpo.phone)
  //       setSupplier(data.data.response.returpo.person);
  //       setWaktu(data.data.response.returpo.created)
  //       setWarehouseRfq(data.data.response.returpo.warehouse);
  //       setDiskonTotal(data.data.response.returpo.diskon_total);
  //       setPajak(data.data.response.returpo.nominal_pajak);
  //       setHargaTotal(data.data.response.returpo.payment_total);
  //       setPriceReal(data.data.response.returpo.price_real);
  //       setKeterangan(data.data.response.returpo.keterangan);
  //       setOngkir(data.data.response.returpo.ongkir);
  //       setCodePo(data.data.response.returpo.retur_code);
  //       setValidator(data.data.response.returpo.validator1);
  //       setValidator1(data.data.response.returpo.validator2);
  //       setBank(data.data.response.returpo.bank_account1);
  //       setRekening(data.data.response.returpo.bank_number1);
  //       var apiPo = data.data.response.list;
  //       // var tglcetak = data.data.response.download_date;
  //       var tableToExcel = (function() {
  //         var uri = 'data:application/vnd.ms-excel;base64,',
  //           template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><meta http-equiv="content-type" content="text/plain; charset=UTF-8"/></head><body><table>{table}</table></body></html>',
  //           base64 = function(s) {
  //             return window.btoa(unescape(encodeURIComponent(s)))
  //           },
  //           format = function(s, c) {
  //             return s.replace(/{(\w+)}/g, function(m, p) {
  //               return c[p];
  //             })
  //           }
  //         return function(table, name) {
  //           var heading = 'PO Retur' ;
  //           var imgsrc1 = 'https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhAVKx5R3RdjeXQuRdKan2RNLsZn2U4qXYOgU4jqILz6u6MLSzlvzY1b5x9Xiz4sKHhM0UJ1NKKoFVx6ZEI8JqgANlrZ8KwCJ2j9pOmJN-e50-HzVhTFRvEahjCJB51O4oMmJ25V2yQtYOGfxV2b7C2aT9VKBruh0_znTbORz66pu9P47DMB5aP4SuF/s320/Hokky1.png';
  //           var po = '';
  //           var a ;
  //           for(a=0; a < apiPo.length; a++){
  //             po += `
  //             <tr style="border: 1px solid black">
  //                 <td>${apiPo[a].item_name}</td>
  //                 <td>${apiPo[a].item_code}</td>
  //                 <td>${formatRupiah(apiPo[a].harga)}</td>
  //                 <td>${apiPo[a].qty}</td>
  //                 <td>${apiPo[a].satuan}</td>
  //                 <td>${apiPo[a].diskon_persen}</td>
  //                 <td>${formatRupiah(apiPo[a].diskon_nominal)}</td>
  //                 <td>${formatRupiah(apiPo[a].sub_total)}</td>
  //             </tr>
  //             `
  //           }
  //           var table = `
  //           <table id="account_table">
  //               <tbody>
  //               <tr>
  //                   <td><img src="https://i.ibb.co/jkFYDqg/150x75.png"/> </td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td>${warehouserfq}</td>
  //               </tr>
  //               <tr>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td>Jl Menganti Karangan No.562</td>
  //               </tr>
  //               <tr>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td>Wiyung surabaya barat - Jawa Timur </td>
  //               </tr>
  //               <tr>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td>Telp: 081 217 85 3300 </td>
  //               </tr>
  //               <tr>
  //                   <td colspan="8"><center><b><h1>PO RETUR</h1></b><center></td>
  //               </tr>
  //               <tr>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //               </tr>
  //               <tr>
  //                   <td>Tanggal</td>
  //                   <td>: ${waktu}</td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td>Supplier</td>
  //                   <td>: ${supplier}</td>
  //               </tr>
  //               <tr>
  //                   <td>Kode PO</td>
  //                   <td>: ${codepo}</td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td>Alamat</td>
  //                   <td>: ${address}</td>
  //               <tr>
  //               <tr>
  //                   <td>Kode RFQ</td>
  //                   <td>: ${coderfq}</td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td>Phone</td>
  //                   <td>: ${phone}</td>
  //               <tr>
  //               <tr>
  //                   <td>Ket</td>
  //                   <td rowspan="2">: ${keterangan}</td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td>Bank</td>
  //                   <td>: ${bank}</td>
  //               </tr>
  //               <tr>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td>Rekening</td>
  //                   <td>: ${rekening}</td>
  //               </tr>
  //               <tr>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //                   <td></td>
  //               </tr>
  //                 <tr style="border: 1px solid black">
  //                     <th>Nama Item</th>
  //                     <th>Kode Item</th>
  //                     <th>Harga</th>
  //                     <th>Qty</th>
  //                     <th>Satuan</th>
  //                     <th>Disc %</th>
  //                     <th>Disc N</th>
  //                     <th>Sub Total</th>
  //                   </tr>
  //                       ${po}
  //                   <tr style="border: 1px solid black">
  //                     <td colspan="6"></td>
  //                     <td>Total</td>
  //                     <td>${formatRupiah(pricereal)}</td>
  //                   </tr>
  //                   <tr style="border: 1px solid black">
  //                     <td colspan="6"></td>
  //                     <td>Diskon</td>
  //                     <td>${formatRupiah(diskontotal)}</td>
  //                   </tr>
  //                   <tr style="border: 1px solid black">
  //                     <td colspan="6"></td>
  //                     <td>PPN</td>
  //                     <td>${formatRupiah(pajak)}</td>
  //                   </tr>
  //                   <tr style="border: 1px solid black">
  //                     <td colspan="6"></td>
  //                     <td>Ongkir</td>
  //                     <td>${formatRupiah(ongkir)}</td>
  //                   </tr>
  //                   <tr style="border: 1px solid black">
  //                     <td colspan="6"></td>
  //                     <td>Grand Total</td>
  //                     <td>${formatRupiah(hargatotal)}</td>
  //                   </tr>
  //               </tbody>
  //           </table>
  //           `;
  //           var ctx = {
  //               worksheet: name || 'WorkBook',
  //               imgsrc1: imgsrc1,
  //               heading: heading,
  //               table: table
  //           }
  //           var blob = new Blob([format(template, ctx)]);
  //           return blob;
  //           }
  //       })()
  //       var blobURL = tableToExcel('account_table', 'PO Retur');
  //       FileSaver.saveAs(blobURL, fileName+'.xls');
        
  //       })
  //     .catch(function (error) {
  //     });
  // }

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }
    

  function print() {
    var printContents = document.getElementById("targetContent").innerHTML;
    var originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  // function clickItem(e) {
  //   setItem(e.target.value)
  // }
  
  return (
    <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%" , top: "2%"  }}>
      <ModalHeader toggle={toggle} align="center">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    {/* <div style={{ textAlign: 'left' }}>
                        <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            Set Item
                        </Label>
                        <Input type="number" pattern="[0-9]*" value={item} min="1" max={savedItems.length} onChange={clickItem} style={{width: '70px',marginRight:'50px'}}/>
                    </div> */}
                    <div style={{ textAlign: 'right' }}>
                    <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"
                        >
                            &nbsp;
                        </Label><br></br>
                        <Button color="info" onClick={() => {print();HitCounter();setTimeout(() => (window.location.reload("/admin/po-retur/histori")), 1000)}}>Print</Button>
                        {/* <Button color="success" onClick={()=> (downloadExcel(),HitCounter())}>Excel</Button> */}
                    </div>
                </div>
      </ModalHeader>
      <ModalBody>
      <CardBody
          className="border"
          id="targetContent"
          style={{ width: "100%" }}
        >
          {renderPDF()}
        </CardBody>
      </ModalBody>
    </Modal>
  );

  function renderPDF(){
    // let sheet = 1;
    // let length = savedItems.length
    // if (item != length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []  
    // for (let index = 0; index < sheet; index++) {
    //   let start = 0
    //   let end = 0
    //   if(sheet == 1){
    //     end = length
    //   }else{
    //     if(sheet > 1 && index < (sheet - 1)){
    //       end = item
    //     }else{
    //       end = length % item
    //     }
    //   }

    // let sheet = 1;
    // let length = savedItems.length
    // if (item != length) {
    //   sheet = Math.floor(length / item);
    //   if((length % item) > 0){
    //     sheet++;
    //   }
    // }
    // const sheets = []
    // for (let index = 0; index < sheet; index++) {
    //   let start = (item * index)
    //   let end = length
    //   let sisa = (length % item)
    //   if(sheet == 1){
    //     end = length
    //   }else{
    //     if(index < (sheet - 1) && sheet == 2){
    //       end = item
    //     }else{
    //       if (index == (sheet - 1)) {
    //         end = length - sisa + (index * item)
    //       }else{
    //         if (index == 0) {
    //           end = item
    //         }else{
    //           end = (index+1) * item
    //         }
    //       }
    //     }
    //   } 
    //   let data = savedItems.slice(start,end)

    const itemsPerPage = 10; // Maksimal 7 item per halaman
    const totalItems = savedItems.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage); // Hitung total halaman
  
    const sheets = [];
    for (let pageIndex = 0; pageIndex < totalPages; pageIndex++) {
      // Hitung indeks awal dan akhir untuk slice item per halaman
      const start = pageIndex * itemsPerPage;
      const end = start + itemsPerPage;
  
      // Ambil item untuk halaman saat ini
      const currentItems = savedItems.slice(start, end);

      // console.log(data.length)
      // let sub_total = 0;
      // let diskon = 0;
      // let pajak = 0;
      let usernamecok = moment().format("YYYY-MM-DD hh:mm:ss") + " " + localStorage.username;
      sheets.push(
        <div className='pagebreak'>
          <div className="col">
            <KopSurat warehouseName={warehouserfq} />
          </div>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          <Row md="12">
              <Col md="12">
                  <h3 style={{ color: "black" }} ><b><center>RETUR PEMBELIAN</center></b></h3>
              </Col>
          </Row>
          <div className="w-100" style={{ border: "1px solid black" }}></div>
          <Row md="12">
            <Col style={{marginLeft:"2%",width:"50%",flexBasis: "unset"}}>
             
              <div className="row align-items-center ">
                  <div style={{width:"15%"}}  className="text-table">Tanggal</div>
                  <div style={{width:"2%"}}  className="text-center text-table">:</div>
                  <div style={{width:"80%"}}  className="text-table">
                      <b>{waktu}</b>
                  </div>
              </div>
               <div className="row align-items-center ">
                  <div style={{width:"15%"}} className="text-table">Kode Retur</div>
                  <div style={{width:"2%"}} className="text-center text-table">:</div>
                  <div style={{width:"80%"}} className="text-table">
                      <b>{codepo}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"15%"}}  className="text-table">keterangan </div>
                  <div style={{width:"2%"}}  className="text-center text-table">:</div>
                  <div style={{width:"80%"}}  className="text-table">
                      <b>{keterangan}</b>
                  </div>
              </div>
            </Col>
            <Col style={{width:"25%",flexBasis: "unset"}}>
              <div className="row align-items-center ">
                  <div style={{width:"25%"}} className="text-table">Supplier</div>
                  <div style={{width:"5%"}} className="text-table">:</div>
                  <div style={{width:"65%"}} className="text-table">
                      <b>{supplier}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"25%"}} className="text-table">Alamat</div>
                  <div style={{width:"5%"}} className="text-table">:</div>
                  <div style={{width:"65%"}} className="text-table">
                      <b>{address}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"25%"}} className="text-table">Kontak</div>
                  <div style={{width:"5%"}} className="text-table">:</div>
                  <div style={{width:"65%"}} className="text-table">
                      <b>{phone}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div style={{width:"25%"}} className="text-table">Rekening</div>
                  <div style={{width:"5%"}} className="text-table">:</div>
                  <div style={{width:"65%"}} className="text-table">
                      <b>{bank} {rekening}</b>
                  </div>
              </div>
            </Col>
          </Row>
            
              <Table style={{width:"100%"}} responsive>
                <thead>
                <tr>
                    <th className="text-table">
                    <b>No</b>
                    </th>
                    <th className="text-table">
                    <b>Kode</b>
                    </th>
                    <th className="text-table">
                    <b>Nama Barang</b>
                    </th>
                    <th className="text-table">
                    <b>Harga</b>
                    </th>
                    <th className="text-table">
                    <b>Qty</b>
                    </th>
                    <th className="text-table">
                    <b>Sat</b>
                    </th>
                    <th className="text-table">
                    <b>Disc %</b>
                    </th>
                    <th className="text-table">
                    <b>Disc N</b>
                    </th>
                    <th className="text-table">
                    <b>Total</b>
                    </th>
                    <th className="text-table">
                    <b>Note</b>
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    currentItems.map((temp,index, key) => {
                        // sub_total = sub_total + temp.sub_total
                        // diskon = diskon + temp.diskon_nominal
                        return (
                            <tr>
                                <td className="text-table">{index+1}</td>
                                <td className="text-table">{temp.item_code}</td>
                                <td className="text-table">{temp.item_name}</td>
                                <td className="text-table">{formatRupiah(temp.harga / (ppn/100 + 100/100))}</td>
                                <td className="text-table">{temp.qty}</td>
                                <td className="text-table">{temp.satuan}</td> 
                                <td className="text-table">{temp.diskon_persen}</td>
                                <td className="text-table">{temp.diskon_nominal}</td>
                                <td className="text-table">
                                  {/* {formatRupiah(temp.sub_total)} */}
                                  {formatRupiah(((temp.harga - temp.diskon_nominal) * temp.qty) / (ppn/100 + 100/100))}

                                  </td>
                                <td className="text-table">{temp.note}</td> 

                            </tr>
                        );
                    })
                }
                  <tr className="w-100 text-table" style={{ border: "1px solid black" }}></tr>
                  <tr>
                    <td colspan="7" className="border-non text-table"></td>
                    <td colspan="2" className="text-right text-table border-non">Sub Total
                    </td>
                    <td className="text-left text-table border-non">
                          <b>{formatRupiah(pricereal)}</b>
                    </td>
                    <td className="border-non text-table"></td>
                  </tr>
                  <tr>
                    <td colspan="7" className="border-non text-table"></td>
                    <td colspan="2" className="text-right text-table border-non">Diskon
                    </td>
                    <td className="text-left text-table border-non">
                          <b>{formatRupiah(diskontotal)}</b>
                    </td>
                    <td className="border-non text-table"></td>
                  </tr>
                  <tr>
                    <td colspan="7" className="border-non text-table"></td>
                    <td colspan="2" className="text-right text-table border-non">PPN
                    </td>
                    <td className="text-left text-table border-non">
                          <b>{formatRupiah(pajak)}</b>
                    </td>
                    <td className="border-non text-table"></td>
                  </tr>
                  <tr>
                    <td colspan="7" className="border-non text-table"></td>
                    <td colspan="2" className="text-right text-table border-non">Ongkir
                    </td>
                    <td className="text-left text-table border-non">
                          <b>{formatRupiah(ongkir)}</b>
                    </td>
                    <td className="border-non text-table"></td>
                  </tr>
                  <tr>
                    <td colspan="7" className="border-non text-table"></td>
                    <td colspan="2" className="text-right text-table border-non">Grand Total
                    </td>
                    <td className="text-left text-table border-non">
                          <b>{formatRupiah(hargatotal)}</b>
                    </td>
                    <td className="border-non text-table"></td>
                  </tr>
                </tbody>
              </Table>
              <div ></div>
              <Row md="12">
              </Row>
          <div className="w-100 text-table" style={{ border: "0.5px solid #b3b3b3" }}></div>
          <center className="text-table">Terms of Price, delivery & shipping required</center>
          <Row md="20">
            <Col style={{ marginLeft:"2%",width:"25%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Di Buat</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-5 text-table">
                      <b>{creator}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-5 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col>
            <Col style={{ width:"35%" }}>
              <div className="row align-items-center ">
                  <div className="col-4 text-table">Kepala Purchasing</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-7 text-table">
                      <b>{validator}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-4 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-7 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col>
            <Col style={{ width:"35%" }}>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Direktur</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>{validator1}</b>
                  </div>
              </div>
              <div className="row align-items-center ">
                  <div className="col-3 text-table">Signature</div>
                  <div className="col-1 text-center text-table">:</div>
                  <div className="col-8 text-table">
                      <b>------------------</b>
                  </div>
              </div>
            </Col>
          </Row>
          <div className="w-100" style={{ border: "0.5px solid #b3b3b3" }}></div>
                      {download === 0 ? (
                        <div className="row align-items-center mb-3">
                        <div className="col-4"><i>Download Original oleh {username}</i></div>
                        </div>
                      ):(
                      <div className="row align-items-center mb-3">
                      <div className="col-4"><i>Di Download {download} Kali Oleh {username}</i></div>
                    </div>
                      )
                      }
          <div className="divFooter">{usernamecok}</div>
        </div>
      )
    }
    return (
      <div>
        {sheets}
      </div>
    );
  }
};

export default ModalCetakReturPo;
