/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
	Col,
	Container,
	CardGroup,
} from "reactstrap";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Barcodee from "react-barcode";
import QRCode from "qrcode.react";

export default function LogPriceKartuStok(props) {
	const token = localStorage.token;
	const [allOpname, setAllOpname] = useState([]);
	const [rowIndex, setRowIndex] = useState(0);
	const [page, setPage] = useState(1);
	const [perPage, setPerpage] = useState(1);
	const [totalItem, setTotalItem] = useState(0);
	const [currentSort, setCurrentSort] = useState("");
	const [itemCode, setItemCode] = useState("");
	const [nameItem, setNameItem] = useState("");
	const [description, setDescription] = useState("");
	const [itemproduksi, setItemProduksi] = useState([]);
	const [satuan, setSatuan] = useState("");
	const [grade, setGrade] = useState("");
	const [category, setCategory] = useState("")
	const [subCategory, setSubCategory] = useState("");
	const [nameFunction, setNameFunction] = useState("")
	const [nameSubFunction, setNameSubFunction] = useState("");
	const [merek, setMerek] = useState("");
	const [submerek, setSubMerek] = useState("");
	const [panjang, setPanjang] = useState("");
	const [lebar, setLebar] = useState("");
	const [tinggi, setTinggi] = useState("");
	const [stock, setStock] = useState("");
	const [nameType, setNameType] = useState("");
	const [barcode, setBarcode] = useState("");
	const [status, setStatus] = useState("");
	const [minimumstock, setMinimumStock] = useState("");
	const [itemgrup, setItemGrup] = useState("");
	const [berat, setBerat] = useState("");
	const [image1, setImage1] = useState("");
	const [image2, setImage2] = useState("");
	const [image3, setImage3] = useState("");
	

	useEffect(() => {
		getByKartuStok();
		getById()
	}, []);

	  const getById = () => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/get-items-detail?id=${props.match.params.itemid}&wid=${props.match.params.id}`,
	        { headers }
	      )
	      .then((data) => {
			setSatuan(data.data.response.satuan);
			setGrade(data.data.response.grade);
			setCategory(data.data.response.kategori);
			setSubCategory(data.data.response.sub_kategori);
			setNameFunction(data.data.response.function);
			setNameSubFunction(data.data.response.sub_function);
			setMerek(data.data.response.merek);
			setSubMerek(data.data.response.sub_merek);
			setItemGrup(data.data.response.group)
			setBarcode(data.data.response.barcode);
	        setBerat(data.data.response.berat);
	        setStatus(data.data.response.active_flag);
	        setSatuan(data.data.response.uom_code);
	        setMerek(data.data.response.merek_name);
	        setNameItem(data.data.response.item_name);
	        setDescription(data.data.response.item_description);
	        setPanjang(data.data.response.panjang);
	        setLebar(data.data.response.lebar);
	        setImage1(data.data.response.image_1);
	        setImage2(data.data.response.image_2);
	        setImage3(data.data.response.image_3);
	        setTinggi(data.data.response.tinggi);
	        setStock(data.data.response.stock);
	        setMinimumStock(data.data.response.minimum_stok);
	        setItemCode(data.data.response.item_code);
			setNameType(data.data.response.type);
			setItemProduksi(data.data.response.part);
			// getSatuan(data.data.response.uom_id);
	        // getGrade(data.data.response.grade_id);
	        // getPajakBeli(data.data.response.pajak_beli);
	        // getPajakJual(data.data.response.pajak_jual);
	        // getAccount(data.data.response.account_id);
	        // getCategory(data.data.response.kategori_id);
          // getSubCategory(
          // 	data.data.response.kategori_id,
          // 	data.data.response.subkategori_id
          //   );
          // setSubCategory(data.data.response.subkategori_id)
          //   getFunction(
          // 	data.data.response.kategori_id,
          // 	data.data.response.subkategori_id,
          // 	data.data.response.function_id
          //   );
          // setNameFunction(data.data.response.function_id)
          //   getSubFunction(
          // 	data.data.response.kategori_id,
          // 	data.data.response.subkategori_id,
          // 	data.data.response.function_id,
          // 	data.data.response.subfunction_id
          //   );
          // setNameSubFunction(data.data.response.subfunction_id)
          //   getMerek(data.data.response.merek_id);
          //   getSubMerek(
          // 	data.data.response.merek_id,
          // 	data.data.response.submerek_id
          // 	);
          // setSubMerek(data.data.response.submerek_id)
	        // getItemGrup(data.data.response.ig_id);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

    
  
    const getByKartuStok = () => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/log-pd/get?item_id=${props.match.params.itemid}&warehouse_id=${props.match.params.id}`,
          { headers }
        )
        .then((data) => {
          setAllOpname(data.data.response);
          setPage(data.data.current_page + 1);
          setPerpage(data.data.per_page);
          setTotalItem(data.data.total_item);
        })
        .catch(function (error) {
          console.log(error);
        });
    };


		let paginationOption = {
		page: page,
		alwaysShowAllBtns: true,
		override: true,
		showTotal: true,
		withFirstAndLast: false,
		sizePerPage: perPage,
		totalSize: totalItem,
		onPageChange: (page) => {
			updateDataTable(page, perPage, currentSort);
		},
		sizePerPageRenderer: () => (
			<div className="dataTables_length" id="datatable-basic_length">
				<label>
					Show{" "}
					{
						<select
							name="datatable-basic_length"
							aria-controls="datatable-basic"
							className="form-control form-control-sm"
							onChange={(e) => {
								updateDataTable(page, e.target.value, currentSort);
							}}>
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="25">25</option>
							<option value="50">50</option>
						</select>
					}{" "}
					entries.
				</label>
			</div>
		),
	};

		const updateDataTable = (page, perPage, sort) => {
		setPerpage(perPage);
		setRowIndex((page - 1) * perPage);
		setCurrentSort(sort);
	};

		const handleTableChange = (type, { sortField, sortOrder }) => {
		if (type === "sort") {
			let sort = `${sortField} ${sortOrder}`;
			updateDataTable(page, perPage, sort);
		}
	};


	const addCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
	const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

	const formatRupiah = (money) => {
		return new Intl.NumberFormat('id-ID',
			{ style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
		).format(money);
	  }

	return (
		<>
			<div>
				<SimpleHeader name="Kartu Stok" parentName="Master" />
				
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
								<CardBody>
									<Row md="12">
										<Col md="7">
											<Card className="bg-secondary shadow">
												<CardHeader className="bg-white border-0">
													<h3>ITEM</h3>
												</CardHeader>
												<CardBody response>
													<FormGroup row>
														<Label for="exampleEmail" sm={4}>
															Kode Item
														</Label>
														<Col sm={7}>
															<Input
																disabled
																className="form-control-alternative"
																type="text"
																name="itemCode"
																placeholder="Masukan Kode Item"
																value={itemCode}
																onChange={(e) => {
																	setItemCode(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label for="exampleEmail" sm={4}>
															Barcode
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="barcode"
																placeholder="Masukan Barcode"
																value={barcode}
																onChange={(e) => {
																	setBarcode(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label for="exampleEmail" sm={4}>
															Nama Item
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="nameItem"
																placeholder="Masukan Nama Item"
																value={nameItem}
																onChange={(e) => {
																	setNameItem(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label for="exampleEmail" sm={4}>
															Jenis Barang
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																name="kategory"
																type="text"
																value={itemgrup}
																onChange={(e) => {
																	setItemGrup(e.target.value);
																}}>
															</Input>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label for="exampleEmail" sm={4}>
															Satuan
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																name="satuan"
																type="text"
																value={satuan}
																onChange={(e) => {
																	setSatuan(e.target.value);
																}}>
															</Input>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label for="exampleEmail" sm={4}>
															Dimensi
														</Label>
														<Col sm={2}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="panjang"
																placeholder="Panjang"
																value={panjang}
																onChange={(e) => {
																	setPanjang(e.target.value);
																}}
															/>
														</Col>
														<Col sm={3}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="lebar"
																placeholder="Lebar"
																value={lebar}
																onChange={(e) => {
																	setLebar(e.target.value);
																}}
															/>
														</Col>
														<Col sm={2}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="tinggi"
																placeholder="Tinggi"
																value={tinggi}
																onChange={(e) => {
																	setTinggi(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label for="exampleEmail" sm={4}>
															Berat
														</Label>
														<Col sm={4}>
															<Input
															disabled
																className="form-control-alternative"
																type="text"
																name="Berat"
																placeholder="Masukan Berat"
																value={berat}
																onChange={(e) => {
																	setBerat(e.target.value);
																}}
															/>
														</Col>
														<Label for="exampleEmail" sm={4}>
															<b>Gram</b>
														</Label>
													</FormGroup>
													<FormGroup row>
														<Label for="exampleEmail" sm={4}>
															Stock
														</Label>
														<Col sm={7}>
															<Input
																disabled
																className="form-control-alternative"
																type="number"
																name="stock"
																placeholder="Masukan Stock"
																value={stock}
																onChange={(e) => {
																	setStock(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
													<FormGroup row>
														<Label for="exampleEmail" sm={4}>
															Minimum Stock
														</Label>
														<Col sm={7}>
															<Input
															disabled
																className="form-control-alternative"
																type="number"
																name="stock"
																placeholder="Masukan Stock"
																value={minimumstock}
																onChange={(e) => {
																	setMinimumStock(e.target.value);
																}}
															/>
														</Col>
													</FormGroup>
												</CardBody>
											</Card>
										</Col>
										<Col md="5">
											<Card className="bg-secondary shadow">
												<CardHeader className="bg-white border-0">
													<h3>Deskripsi</h3>
												</CardHeader>
												<CardBody>
													<FormGroup row>
														<Input
														disabled
															className="form-control-alternative"
															rows="13"
															type="textarea"
															name="deskripsi Item"
															placeholder="Masukan Deskripsi Item"
															value={description}
															onChange={(e) => {
																setDescription(e.target.value);
															}}
														/>
													</FormGroup>
													<FormGroup row>
														<CardGroup>
															<Row md="10">
                                                                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
																<Col md="5">
																	<QRCode className="qrcode"  value={barcode} />
																</Col>
																<Col md="5">
																	<Barcodee className="qrcode" value={barcode} />
																</Col>
															</Row>
														</CardGroup>
													</FormGroup>
												</CardBody>
											</Card>
										</Col>
									</Row>
									<Card className="bg-secondary shadow">
									<CardHeader className="bg-white border-0">
										<h3>Gambar</h3>
									</CardHeader>
									<CardBody>
										<Row md="12">
											<Col md="12">
												<FormGroup row>
													<Label for="exampleEmail" sm={3}>
														<b>Foto Produk</b><br></br>
														Format gambar .jpg .jpeg .png dan ukuran minimum 300 x 300px.
													</Label>
													<Col sm={2}>
													{image1 === "" ?(
														<img
														alt="..."
														src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
														width="100%"
														/>
													):(
													<img
														alt="HA"
														src={image1}
														width="100%"
													/>
													)}
													</Col>
													<Col sm={2}>
													{image2 === "" ?(
														<img
														alt="..."
														src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
														width="100%"
														/>
													):(
													<img
														alt="HA"
														src={image2}
														width="100%"
													/>
													)}
													</Col>
													<Col sm={2}>
													{image3 === "" ?(
														<img
														alt="..."
														src={"https://i.ibb.co/j5qfxy8/NO-IMAGE-AVAILABLE-2.png"}
														width="100%"
														/>
													):(
													<img
														alt="HA"
														src={image3}
														width="100%"
													/>
													)}
													</Col>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
									</Card>
									<Card className="bg-secondary shadow">
									<CardHeader className="bg-white border-0">
										<h3>KATEGORI</h3>
									</CardHeader>
									<CardBody>
										<Row md="12">
											<Col md="6">
												<FormGroup row>
													<Label for="exampleEmail" sm={4}>
														Tipe Item
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															type="text"
															name="nameItem"
															placeholder="Masukan Tipe Item"
															value={nameType}
															onChange={(e) => {
																setNameType(e.target.value);
															}}>
														</Input>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label for="exampleEmail" sm={4}>
														Kategori
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="kategory"
															type="text"
															value={category}
															onChange={(e) => {
																setCategory(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label for="exampleEmail" sm={4}>
														Sub Kategori
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="subKategory"
															type="text"
															value={subCategory}
															onChange={(e) => {
																setSubCategory(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label for="exampleEmail" sm={4}>
														Function
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="function"
															type="text"
															value={nameFunction}
															onChange={(e) => {
																setNameFunction(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label for="exampleEmail" sm={4}>
														Sub Function
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="subFunction"
															type="text"
															value={nameSubFunction}
															onChange={(e) => {
																setNameSubFunction(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
											</Col>
											<Col md="6">
												<FormGroup row>
													<Label for="exampleEmail" sm={4}>
														Merek
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="merek"
															type="text"
															value={merek}
															onChange={(e) => {
																setMerek(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label for="exampleEmail" sm={4}>
														Sub Merek
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="merek"
															type="text"
															value={submerek}
															onChange={(e) => {
																setSubMerek(e.target.value);

															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label for="exampleEmail" sm={4}>
														Grade
													</Label>
													<Col sm={5}>
														<Input
														disabled
														autoComplete="off"
															className="form-control-alternative"
															name="grade"
															type="text"
															value={grade}
															onChange={(e) => {
																setGrade(e.target.value);
															}}>
														</Input>
													</Col>
													<Col sm={3}>
													</Col>
												</FormGroup>
												<FormGroup row>
													<Label for="exampleEmail" sm={4}>
														Status
													</Label>
													<Col sm={7}>
														<div style={{ display: "flex" }}>
															<div className="custom-control custom-radio mb-3">
																<Input
																disabled
																autoComplete="off"
																	className="custom-control-input"
																	id="customRadio11"
																	name="custom-radio-4"
																	type="checkbox"
																	value={1}
																	checked={status === 1}
																	onChange={() => setStatus(1)}
																/>
																<Label className="custom-control-label" htmlFor="customRadio11">
																	Aktif
																</Label>
															</div>
															<div className="custom-control custom-radio mb-3" style={{ marginLeft: "20px" }}>
																<Input
																disabled
																autoComplete="off"
																	className="custom-control-input"
																	id="customRadio12"
																	name="custom-radio-4"
																	type="radio"
																	value={2}
																	checked={status === 2}
																	onChange={() => setStatus(2)}
																/>
																<Label className="custom-control-label" htmlFor="customRadio12">
																	Tidak Aktif
																</Label>
															</div>
														</div>
													</Col>
												</FormGroup>
											</Col>
										</Row>
									</CardBody>
									</Card>
								<Card>
										<CardHeader>
											<div style={{ display: "flex", justifyContent: "space-between" }}>
												<h3>Item Produksi</h3>
											</div>
										</CardHeader>
										<CardBody>
											<ToolkitProvider
												rowNumber={rowIndex}
												data={itemproduksi}
												keyField="id"
												columns={[
													{
														dataField: "no",
														text: "#",
														sort: true,
														page: 1,
														formatter: (cell, row, index) => {
															let currentRow = ++index;
															return currentRow + rowIndex;
														},
													},
													{
														dataField: "item_name",
														text: "Nama Item",
														sort: true,
													},
													{
														dataField: "province",
														text: "Provinsi",
														sort: true,
													},
													{
														dataField: "phone",
														text: "Telephone",
														sort: true,
													},
													{
														dataField: "stok",
														text: "Stok",
														sort: true,
													},
												]}>
												{(props) => (
													<div className="py-4 table-responsive">
														<BootstrapTable
															remote
															{...props.baseProps}
															bootstrap4={true}
															bordered={false}
															hover={true}
															pagination={paginationFactory({ ...paginationOption })}
															onTableChange={handleTableChange}
														/>
													</div>
												)}
											</ToolkitProvider>
										</CardBody>
									</Card>
									<Card>
										<CardHeader>
											<div style={{ display: "flex", justifyContent: "space-between" }}>
												<h3>Kartu Stok</h3>
											</div>
										</CardHeader>
										<CardBody>
                                            <ToolkitProvider
                                                rowNumber={rowIndex}
                                                data={allOpname}
                                                keyField="id"
                                                columns={[
                                                {
                                                    dataField: "no",
                                                    text: "#",
                                                    sort: true,
                                                    page: 1,
                                                    formatter: (cell, row, index) => {
                                                    let currentRow = ++index;
                                                    return currentRow + rowIndex;
                                                    },
                                                },
                                                {
                                                    dataField: "price_1",
                                                    text: "Harga 1",
                                                    sort: true,
                                                },
                                                {
                                                    dataField: "price_2",
                                                    text: "Harga 2",
                                                    sort: true,
                                                },
                                                {
                                                    dataField: "price_3",
                                                    text: "Harga 3",
                                                    sort: true,
                                                },
                                                {
                                                    dataField: "price_4",
                                                    text: "Harga 4",
                                                    sort: true,
                                                },
                                                {
                                                    dataField: "price_5",
                                                    text: "Harga 5",
                                                    sort: true,
                                                },
                                                {
                                                    dataField: "price_6",
                                                    text: "Harga 6",
                                                    sort: true,
                                                },
                                                ]}
                                            >
                                                {(props) => (
                                                <div className="py-4 table-responsive">
                                                    <BootstrapTable
                                                    remote
                                                    {...props.baseProps}
                                                    bootstrap4={true}
                                                    bordered={false}
                                                    hover={true}
                                                    pagination={paginationFactory({ ...paginationOption })}
                                                    onTableChange={handleTableChange}
                                                    />
                                                </div>
                                                )}
                                            </ToolkitProvider>
										</CardBody>
									</Card>
								</CardBody>
						</div>
					</Row>
				</Container>
			</div>
		</>
	);
}