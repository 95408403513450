/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {Card,Button,Row,Col,CardBody,CardHeader,CardFooter,Table,Container,FormGroup,Label,Input} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

export default function ValidasiReceivingPo(props){
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [persons, setPersons] = useState([]);
  const [person, setPerson] = useState("");
  const [codereceiving, setCodeReceiving] = useState("");
  const [statusreceive, setStatusReceive] = useState([]);
  const [pembuatreceiving, setPembuatReceiving] = useState("");
  const [codepo, setCodePo] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [savedItems, setSavedItems] = useState([]);
  const [ongkir,setOngkir] = useState([]);
  const [lainnya, setLainnya] = useState([]);
  const [usernamepo, setUsernamePO] = useState("");
  const [tanggal, setTanggal] = useState("");
  const allInfo = JSON.parse(localStorage.allinfo);
  const receivingPOs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Receiving PO").map((p) => p.read_access));
  

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/receiving-po/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        getPerson(data.data.response.person_id);
        // setTanggal(moment(data.data.response.created_at, "DD/MM/YYYY").format("YYYY-MM-DD"));
        setTanggal(data.data.response.created_at);
        getItemDataSaved();
        setCodeReceiving(data.data.response.receiving_code);
        setLainnya(data.data.response.lainnya);
        setOngkir(data.data.response.ongkir);
        setUsernamePO(data.data.response.username);
        setCodePo(data.data.response.code_po);
        setStatusReceive(data.data.response.status_receive);
        setKeterangan(data.data.response.keterangan);
        setPembuatReceiving(data.data.response.username);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/receiving-po/item`, {

        receiving_id: props.match.params.id

    }).then(async response => {
      console.log(response);
        let stateItem = [];

        await Promise.all(response.data.response.map(async (data) => {
          console.log(data);
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code : data.item_code,
                satuan : data.satuan,
                qty: data.qty,
                harga : data.harga,
                diskon_nominal : data.diskon_nominal,
                diskon_persen : data.diskon_persen,
                note : data.note,
                data: {
                    item_name: data.item_name,
                    qty: data.qty,
                    harga : data.harga,
                    diskon_nominal : data.diskon_nominal,
                    diskon_persen : data.diskon_persen,
                },
            }];
        }));

        setSavedItems(stateItem);
    })
}


  const getPerson = (id) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/person/list`,
          { headers }
        )
        .then((data) => {
          setPersons(data.data.response);
          setPerson(id);
        })
        .catch(function (error) {
          console.log(error);
        });
    };

    const handleSubmit2 = () => {
      hideAlert();
      setTimeout(() => {EditData()}, 1000);
    };

    function EditData() {
        let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty,
                harga: dataItem.harga,
                diskon_nominal : dataItem.diskon_nominal,
                diskon_persen: dataItem.diskon_persen,
                note : dataItem.note
            }]);
    let data = {
      warehouse_id : parseInt(warehouse),
      username : pembuatreceiving,
      code_po: codepo,
      receiving_code: codereceiving,
      ongkir: parseInt(ongkir),
      lainnya:parseInt(lainnya),
      username: usernamepo,
      person_id: parseInt(person),
      status_receive : parseInt(statusreceive),
      admin_gudang : username,
      keterangan: keterangan ,
      items : dataItems,
      // created_at : moment(tanggal).format("YYYY-MM-DD 00:00:00"),
          };
        axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/receiving-po/update/${props.match.params.id}`,
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(function (response) {
            setSuccessAlert(response.data.message);
            setTimeout(() => (history.push("/admin/receiving-po/validasi-kepala-gudang")), 1000);
          })
          .catch(function (error) {
                setGagalAlert(error.response.data.message);
          });
    }
  
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }
  
  const setGagalAlert = (id) => {
  setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
  );
  };
  
  const setQuestionAlert = () => {
  setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={handleSubmit2}
    onCancel={hideAlert}
    focusCancelBtn
    />
  );
  };
  
    const hideAlert = () => {
      setAlert(null);
    };

   
      const handleSubmit = (e) => {
        {
        EditData();
        }
      };

  return (
    <>
    {alert}
    {receivingPOs && receivingPOs === "1" ? (
      <div>
    <SimpleHeader name="Validasi Kepala Gudang" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Validasi Kepala Gudang</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Tanggal
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                autoComplete="off"
                                disabled
                                    type="text"
                                    name="desc"
                                    placeholder="Masukan Tanggal"
                                    value={tanggal}
                                />
                              </Col>                             
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode BPB
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                autoComplete="off"
                                disabled
                                    type="text"
                                    name="desc"
                                    placeholder="Masukan Kode Receiving"
                                    value={codereceiving}
                                />
                              </Col>                             
                            </FormGroup>
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode PO
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                autoComplete="off"
                                disabled
                                    type="text"
                                    name="desc"
                                    placeholder="Masukan Kode PO"
                                    value={codepo}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Supplier
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  name="person"
                                  type="select"
                                  value={person}
                                >
                                  <option value=''>Pilih Supplier</option>
                                  {persons.map((person, key) => {
                                      return (
                                        <option key={key} value={person.id}>
                                          {person.person_name}
                                        </option>
                                      );
                                    })}
                                </Input>
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Keterangan
                              </Label>
                              <Col sm={6}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="textarea"
                                  rows = "5"
                                  name="desc"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                            <Col
                              for="exampleEmail"
                              sm={3}
                            >
                              <Label
                              className="form-control-label">
                                Validasi
                              </Label>
                              
                            </Col>
                              <Col sm={6}>
                                <div style={{ display: "flex" }}>
                                  <div className="custom-control custom-radio mb-3">
                                    <Input
                                      className="custom-control-input"
                                      id="customRadio10"
                                      name="custom-radio-4"
                                      type="radio"
                                      value={5}
                                      checked={statusreceive === 5}
                                      onChange={() => setStatusReceive(5)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio10"
                                    >
                                    </Label>
                                      <b>SETUJU</b>
                                  </div>
                                  <div
                                    className="custom-control custom-radio mb-3"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    <Input
                                      className="custom-control-input"
                                      id="customRadio11"
                                      name="custom-radio-4"
                                      type="radio"
                                      value={4}
                                      checked={statusreceive === 4}
                                      onChange={() => setStatusReceive(4)}
                                    />
                                    <Label
                                      className="custom-control-label"
                                      htmlFor="customRadio11"
                                    >
                                    </Label>
                                      <b>TOLAK</b>
                                  </div>
                                </div>
                              </Col>
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                    <CardBody >
                      <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th><b>Nama Item</b></th>
                            <th><b>Kode Item</b></th>
                            <th><b>Qty</b></th>
                            <th><b>Satuan</b></th>
                            <th><b>Note</b></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            savedItems.map((savedItem, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{savedItem.data.item_name}</td>
                                        <td>{savedItem.item_code}</td>
                                        <td>{savedItem.qty}</td>
                                        <td>{savedItem.satuan}</td>
                                        <td>{savedItem.note}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                      </Table>
                    </CardBody>
                    <CardFooter>
                          <Link className="btn btn-info" to="/admin/receiving-po/validasi-kepala-gudang">
                            Kembali
                          </Link>
                          <Button color="danger" onClick={()=>setQuestionAlert()}>
                              Simpan
                          </Button>
                    </CardFooter>
              </Card>
          </div>
        </Row>
    </Container>  
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}
