/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  
} from "reactstrap";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import CurrencyInput from 'react-currency-input-field';

const CreateBiaya = () => {
  const [alert, setAlert] = React.useState(null);
  const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  const [keterangan, setKeterangan] = useState("");
  const [savedItems, setSavedItems] = useState([]);
  const [isSearchShoww, setIsSearchShoww] = useState(false);
	const [allItemm, setAllItemm] = useState([]);
	const [queryy, setQueryy] = useState("");
  const headers = { Authorization: `Bearer ${token}`};
    const [account, setAccount] = useState("");
    const [accounts, setAccounts] = useState([]);
    const [tgl, setTgl] = useState("");
    const [tglnota, setTglnota] = useState("");
    const [debet, setDebet] = useState(0);
    const [kredit, setKredit] = useState(0);
    const [saldo, setSaldo] = useState(0);
    const [pic, setPic] = useState("");
    const [piccek, setPicCek] = useState("")

    useEffect(() => {
		getCoa();
	}, []);

    const getCoa = () => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .get(`${process.env.REACT_APP_API_BASE_URL}/account/list/${warehouse}`, { headers })
          .then((data) => {
            setAccounts(data.data.response);
          })
          .catch(function (error) {
            console.log(error);
          });
      };

  useEffect(() => {
    const getData = setTimeout(async() => {
        if(queryy != ''){
            axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/account/manual`, { account_name: queryy , per_page: 10, warehouse_id: parseInt(warehouse)},{ headers })
            .then((res) => {
                setAllItemm(res.data);
                setIsSearchShoww(true);
            })
            .catch(function (error) {
            });
        }else{
    setAllItemm(null);
        }
      }, 1000)
      return () => clearTimeout(getData)
}, [queryy]);

	// save
	const saveItemOrUpdate = (item) => {
		let oldobj = savedItems.find(o => o.id === item.id);
		if(oldobj===undefined){
			setSavedItems([...savedItems,{
				        id: item.id,
                warehouse_id : item.warehouse_id,
				        account_name: item.account_name,
                pic : item.pic,
                note : item.note,
				        account_code: item.account_code,
                debet_total : item.debet_total,
                kredit_total : item.kredit_total,
                saldo_total: item.saldo_total,
			}]);
			return setKredit(kredit + item.debet_total)
		}else{
			let index = savedItems.findIndex(e=>e.id===item.id);
			let updateList = savedItems;
      let minTotal = parseInt(savedItems[index].debet_total)
			let qtyupdate = parseInt(updateList[index].debet_total);
			updateList[index] = {...updateList[index], debet_total: qtyupdate};
			setSavedItems(updateList);
      let fixdebet = kredit - minTotal;
			return setKredit(fixdebet + parseInt(updateList[index].debet_total))
		};
	}

  const handleEditQty = (index, value) => {
    let updateList = savedItems;
    let aqtyTotal111 = parseInt(updateList[index].debet_total * 1);
    let aqtyTotal11 = 1 * value;
    if(!isNaN(value)&&value.length>0){   
      updateList[index]={...updateList[index], debet_total: value};
      setSavedItems(updateList);
      setKredit(kredit +  aqtyTotal11 -aqtyTotal111);
    }else{return false}
  }

  const handlePic1 = (index, value) => {
    let updateList = savedItems;
    let aqtyTotal111 = parseInt(updateList[index].kredit_total * 1);
    if(!isNaN(value)&&value.length>0){   
      updateList[index]={...updateList[index], pic: value};
      setSavedItems(updateList);
      setPicCek(kredit + aqtyTotal111);
    }else{return false}
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    {
      setQueryy("");
      setIsSearchShoww("");
      return true
    }
  };
  
    const deleteItem = (id) => {
      let array = [...savedItems];
  
      let index = array.findIndex(object => {
        return object.item_id === id;
      });
  
      if (index !== -1) {
        setKredit(kredit - array[index].debet_total);
        array.splice(index, 1);
        setSavedItems(array);
      }
    }


  function CreateData() {
    let dataItems = [];
      savedItems.map((dataItem) => dataItems = [...dataItems, 
          { 
              id: dataItem.id,
              account_id : dataItem.id,
              warehouse_id : dataItem.warehouse_id,
              account_name: dataItem.account_name,
              pic : dataItem.pic,
              note : dataItem.note,
              account_code: dataItem.account_code,
              debet_total : dataItem.debet_total,
              kredit_total : dataItem.kredit_total,
              saldo_total: dataItem.saldo_total,
          }]);
    let data = {
      username : username,
      warehouse_id : parseInt(warehouse),
      account_id : parseInt(account),
      tgl_transaksi: tgl,
      tgl_nota : tglnota,
      pic : pic,
      note : keterangan,
      debet_total : parseInt(debet),
      kredit_total : parseInt(kredit),
      saldo_total : parseInt(saldo),
      keterangan: keterangan,
      keluaran : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/account-journal/keluaran-lain/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          // setTimeout(() => (window.location.reload("/admin/biaya")), 2000);
          setKeterangan("")
          setSavedItems([])
          setAccount("");
          setAccounts([]);
          setTgl("");
          setTglnota("");
          setDebet(0);
          setKredit(0);
          setSaldo(0);
          setPic("");
          })
          .catch(function (error) {
            setGagalAlert(error.response.data.message);
          });
    }
    
    const setSuccessAlert = (id) => {
      setAlert(
        <SweetAlert
          success
          showConfirm
          confirmBtnText="Ok"
          title={id}
          onCancel={hideAlert}
          onConfirm={hideAlert}
        />
      )
    }
    
    const setGagalAlert = (id) => {
    setAlert(
    <SweetAlert
    danger
    showConfirm
    confirmBtnText="Ok"
    title={id}
    onCancel={hideAlert}
    onConfirm={hideAlert}
    />
    ); y 
    };
    
    const setQuestionAlert = () => {
    setAlert(
    <SweetAlert
    warning
    showCancel
    confirmBtnText="Lanjutkan"
    confirmBtnBsStyle="danger"
    title="Apakah Kamu Yakin?"
    onConfirm={CreateData}
    onCancel={hideAlert}
    focusCancelBtn
    />
    );
    };
    
    const hideAlert = () => {
      setAlert(null);
    }



const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}

const priceKredit = (e) => {
  const value = e === undefined ? 'undefined' : e;
  setKredit(value || ' ');
                              
  };
  
  return (
    <>
    {alert}
    <SimpleHeader name="Buat Biaya Lain Lain" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col"> 
                <CardBody>
                <Card className="bg-secondary shadow">
                          <CardHeader className="bg-white border-0">
                              <h3>Rekening Asal</h3>
                          </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"  sm={4}>
                                Tanggal Transaksi
                            </Label>
                            <Col sm={7}>
                            <Input
                            autoComplete="off"
                                id="example-date-input"
                                type="date"
                                value={tgl}
                                onChange={(e) => {
                                setTgl(e.target.value);
                                }}
                            />
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                            htmlFor="exampleFormControlSelect3"  sm={4}>
                                Tanggal Nota
                            </Label>
                            <Col sm={7}>
                            <Input
                            autoComplete="off"
                                id="example-date-input"
                                type="date"
                                value={tglnota}
                                onChange={(e) => {
                                setTglnota(e.target.value);
                                }}
                            />
                            </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
className="form-control-label"

                                for="exampleEmail"
                                sm={4}
                              >
                                PIC
                              </Label>
                              <Col sm={7}>
                                <Input
                                className="form-control-alternative"
                                autoComplete="off"
                                  type="text"
                                  name="desc"
                                  placeholder="Masukan Pic"
                                  value={pic}
                                  onChange={(e) => {
                                    setPic(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                           
                            <FormGroup row>
                                <Label
className="form-control-label"

                                    sm={4}
                                >
                                    Akun
                                </Label>
                                <Col sm={7}>
                                <Input
                                autoComplete="off"
                                    name="coa"
                                    type="select"
                                    value={account}
                                    onChange={(e) => {
                                    setAccount(e.target.value);
                                    }}
                                >
                                    <option value="" disabled selected hidden>Pilih Akun</option>
                                    {accounts.map((coa, key) => {
                                    return (
                                        <option key={key} value={coa.id}>
                                         {coa.account_code} {coa.account_name}
                                        </option>
                                    );
                                    })}
                                </Input>
                                </Col>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                              <Label
className="form-control-label"

                                for="exampleEmail"
                                sm={4}
                              >
                                Keterangan
                              </Label>
                              <Col sm={7}>
                                <Input
                                className="form-control-alternative"
                                
                                  type="textarea"
                                  name="desc"
                                  rows = "4"
                                  placeholder="Masukan Keterangan"
                                  value={keterangan}
                                  onChange={(e) => {
                                    setKeterangan(e.target.value);
                                  }}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                              <Label
className="form-control-label"

                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Total Kredit</b>
                              </Label>
                              <Col sm={7}>
                                <Input
                                  disabled
                                  className="form-control-alternative"
                                  type="text"
                                  placeholder="Masukan Total Debet"
                                  value={"Rp." + kredit.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                  onChange={(e) => {
                                    setKredit(e.target.value);
                                  }}
                                  style={{fontWeight: 'bold'}}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
                </Card>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Pengeluaran</h3>
                    </CardHeader>
                        <CardBody>
                          <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Akun</b></th>
                                <th><b>Kode Akun</b></th>
                                <th><b>Debet</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.account_name}</td>
                                      <td>{savedItem.account_code}</td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="qty"
                                          type="number"
                                          value={savedItem.debet_total}
                                          onChange={(e) => {
                                              handleEditQty(key, e.target.value);
                                          }}/>
                                        </td>
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              <tr>
                                <td>
                                  <><Form onSubmit={handleSubmit}>
                                          <Input
                                            autoComplete="off"
                                            placeholder="Masukan Account"
                                            type="search"
                                            // onKeyDown={searchh}
                                            value={queryy}
                                            onChange={(e) => setQueryy(e.target.value)}
                                          />
                                        {isSearchShoww && queryy && (
                                        <Card className="position-sticky boxShadow" style={{ maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "relative" }}>
                                          <div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "1rem" }}>
                                            <i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
                                          </div>
                                          {allItemm?.response ? (
                                            allItemm.response.map((item) => (
                                              <CardBody key={item.id} style={{ minHeight: "5rem", padding: "1rem" }} className="bgSearch" onClick={() => {saveItemOrUpdate(item);setQueryy('');setIsSearchShoww(false);}}>
                                                <div>
                                                  <b>Nama Akun:</b> {item.account_name}
                                                </div>
                                              </CardBody>
                                            ))
                                          ) : (
                                            <div className="text-center mb-3 text-danger">Akun "{queryy}" tidak ada bosku!</div>
                                          )}
                                        </Card>
                                      )}
                                  </Form></>
                                  </td>
                                 </tr>
                              </tbody>
                          </Table>
                        </CardBody>
                <CardFooter>
                      {/* <Link className="btn btn-info" to="/admin/biaya">
                          Kembali
                      </Link> */}
                      <Button color="danger" onClick={()=>setQuestionAlert()}>
                          Simpan
                      </Button>
                </CardFooter>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>
    </>
  );
}

export default CreateBiaya;