/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { 
    Card, 
    Button, 
    Row, 
    Col, 
    CardBody,
    Container, 
    CardHeader,
    ButtonGroup,
    Form, 
    FormGroup, 
    Label, 
    Input ,
    DropdownItem,DropdownMenu, UncontrolledDropdown, DropdownToggle
} from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SimpleHeader from "components/Headers/SimpleHeader.js";

const Pembulatan = () => {
  const token = localStorage.token;
  const warehouse = parseInt(localStorage.warehouse);
  const redirectPrefix = `/admin/pembulatan/edit/`;
//   const redirectPrefix1 = `/admin/invoice-po/detail/`;
  const [rowIndex, setRowIndex] = useState(0);
  const [allInvoicePo, setAllInvoicePo] = useState([]);;
  const [uomCode, setUomCode] = useState("");
  const [description, setDescription] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [persons, setPersons] = useState([]);

  useEffect(() => {
    getPerson();
  }, []);

  const getPerson = () => {
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/person/list`, { headers
      })
      .then(data => {
        setPersons(data.data.response);
      })
        .catch(function (error) {
          console.log(error)
        })
    }
  
  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, uomCode, description);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, uomCode, description) => {
    getInvoicePo(page, perPage, sort, uomCode, description);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setUomCode(uomCode);
    setDescription(description);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort,  uomCode, description)
    }
  }

  
  useEffect(() => {
    getInvoicePo(page, perPage, currentSort);
  }, []);

  // fungsi dari ambil data
  const getInvoicePo = (page, perPage, currentSort,invoice_code = null, person_id = null) => {
    
    let filter = { 
      page: page, 
      per_page: perPage,
      warehouse_id: parseInt(warehouse),
      
    };
    const data = filter;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/pembulatan/page`, data, {
        headers,
      })
      .then((data) => {
        setAllInvoicePo(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const reset = () => {
    setUomCode("");
    setDescription("");
    updateDataTable(1, perPage, currentSort, "", "");
  }

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }

  return (
    <div>
        <SimpleHeader name="Pembulatan" parentName="Pembulatan" />
		<Container className="mt--6" fluid>
            <Row>
            <div className="col">
            <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3>Pembulatan</h3>
                    {/* <div style={{ textAlign: 'right' }}>
                        <Link className="btn btn-info" to="/admin/invoice-po/create">
                        <i className="fas fa-plus" /> Tambah
                        </Link>
                    </div> */}
                    </div>
                </CardHeader>
                <CardBody>
                        {/* <Form>
                            <Row md="12">
                            <Col md="3">
                                <FormGroup>
                                <Label  className="form-control-label" htmlFor="exampleFormControlSelect3">Kode Invoice</Label>
                                <Input
                                className="form-control-alternative"
                                    type="text"
                                    placeholder="Masukan Kode Invoice"
                                    value={uomCode}
                                    onChange={e => updateDataTable(1, perPage, currentSort, e.target.value, description)}
                                />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup>
                                <Label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlSelect3"
                                >
                                    Supplier
                                </Label>
                                <Input
                                    name="account"
                                    type="select"
                                    value={description}
                                    onChange={(e) =>
                                    updateDataTable(1, perPage, currentSort, uomCode, e.target.value,)
                                    }
                                >
                                    <option value="">Pilih Supplier</option>
                                    {persons.map((dep, key) => {
                                    return (
                                        <option key={key} value={dep.id}>
                                        {dep.person_name}
                                        </option>
                                    );
                                    })}
                                </Input>
                                </FormGroup>
                            </Col>
                            </Row>
                            <Row>
                            <Col>
                                <Button type="button" onClick={reset} color="secondary">Reset</Button>
                            </Col>
                            </Row>
                        </Form> */}
                        <ToolkitProvider
                                rowNumber={rowIndex}
                                data={allInvoicePo}
                                keyField="id"
                                columns={[
                                {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                // {
                                //     dataField: "warehouse_id",
                                //     text: "Cabang",
                                //     sort: true,
                                // },
                                // {
                                //     dataField: "tipe_pembulatan",
                                //     text: "Tipe Pembulatan",
                                //     sort: true,
                                // },
                                {
                                    dataField: "keterangan",
                                    text: "Keterangan",
                                    sort: true,
                                },
                                // {
                                //     dataField: "code_po",
                                //     text: "Kode PO",
                                //     sort: true,
                                // },
                                // {
                                //     dataField: "person_id",
                                //     text: "Supplier",
                                //     sort: true,
                                // },
                                // {
                                //     dataField: "qty_total",
                                //     text: "QTY",
                                //     sort: true,
                                // },
                                // {
                                //     dataField: "payment_total",
                                //     text: "Harga Total",
                                //     sort: true,
                                //     formatter: (value) => formatRupiah(value)
                                // },
                                // {
                                //     dataField: "pembayaran_total",
                                //     text: "Pembayaran Total",
                                //     sort: true,
                                //     formatter: (value) => formatRupiah(value)
                                // },
                                // {
                                //     dataField: "status_af",
                                //     text: "Status",
                                //     sort: true,
                                //     formatter: (cell, row) => {
                                //     return row.status_af === 3
                                //         ? 'proses'
                                //         : row.status_af === 4
                                //         ? 'Tidak Setuju'
                                //         : 'Setuju';
                                //     },
                                // },
                                // {
                                //     dataField: "status_d",
                                //     text: "Status Pimpinan",
                                //     sort: true,
                                //     formatter: (cell, row) => {
                                //       return row.status_d === 3
                                //         ? 'proses'
                                //         : row.status_d === 4
                                //         ? 'Tidak Setuju'
                                //         : 'Setuju';
                                //     },
                                // },
                                {
                                    dataField: "", text: "", formatter: (cell, row, index) => {
                                    return (
                                    <UncontrolledDropdown nav>
                                    <DropdownToggle className="nav-link pr-0" color="" tag="a">
                                        <Link className="btn btn-danger">
                                            Tindakan
                                        </Link>
                                    </DropdownToggle>
                                    <DropdownMenu center>
                                            {/* <Link to={redirectPrefix1 + row.id}
                                            id={"tooltip_" + row.id}>
                                            <DropdownItem>
                                            <i className="fas fa-print" /><span>Pembayaran</span>
                                            </DropdownItem>
                                            </Link> */}
                                            {/* {updateButton && updateButton === "YES" && ( */}
                                            {/* <Link to={redirectPrefix1 + row.id}
                                            id={"tooltip_" + row.id}>
                                            <DropdownItem>
                                            <i className="fas fa-book" /><span>Detail</span>
                                            </DropdownItem>
                                            </Link> */}
                                            {/* )} */}
                                            {/* {updateButton && updateButton === "YES" && ( */}
                                            <Link  to={redirectPrefix + row.id}
                                            id={"tooltip_" + row.id}>
                                            <DropdownItem>
                                            <i className="fas fa-user-edit" /><span>Edit</span>
                                            </DropdownItem>
                                            </Link>
                                            {/* )} */}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                    )
                                    }
                                },
                                ]}
                            >
                                {(props) => (
                                <div className="py-4 table-responsive">
                                    <BootstrapTable
                                    remote
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    bordered={false}
                                    hover={true}
                                    pagination={paginationFactory({ ...paginationOption })}
                                    onTableChange={handleTableChange}
                                    />
                                </div>
                                )}
                        </ToolkitProvider>
                </CardBody>
                </Card>
            </div>
            </Row>
        </Container>
    </div>
  );
}

export default Pembulatan;
