/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Input,

} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select2 from "react-select2-wrapper";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";
import CurrencyInput from 'react-currency-input-field';

export default function EditPoRetur(props)  {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [persons, setPersons] = useState([]);
  const [person, setPerson] = useState("");
  const [codeinvoice,setCodeInvoice ] = useState("");
  const [payment_method,setPaymentMethod] = useState(0);
  const [type,setType] =useState("");
  const [returtipe,setReturTipe] = useState("");
  const [coderetur,setCodeRetur] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [allJangkaWaktu, setAllJangkaWaktu] = useState([]);
  const [jangkaWaktu,setJangkaWaktu] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [savedItems, setSavedItems] = useState([]);
  const [ongkir, setOngkir] = useState(0);
  const [allpajak, setAllPajak] = useState([]);
  const [pajak, setPajak] = useState(1);
  const [diskonglobalnominal, setDiskonGlobalNominal] = useState(0);
  const [diskonglobalpersen, setDiskonGlobalPersen] = useState(0);
  const [editable, setEditable] = useState(false);
  const [qtyTotal, setTotalQty] = useState(0);
  const [diskontotal, setDiskonTotal] = useState(0);
  const [totalPrice1, setTotalPrice1] = useState(0);
  const a = 0;
	const b = 0;
  const c = 0;
  const [dp, setDp] = useState(0);
  const [dp1, setDp1] = useState(0);
    const [diskonglobalnominal1,setDiskonGlobalNominal1] = useState(0)
  const [totaldiskon, setTotalDiskon] = useState(0);
    const [totaldiskon2, setTotalDiskon2] = useState(0);
    const [totaldiskon3, setTotalDiskon3] = useState(0);
  const [ppn, setPPN] = useState(0);
  const [grandtotal, setGrandTotal] = useState(0);
  const [ongkir1, setOngkir1] = useState(0);
  const [ppnnew, setPPNNEW] = useState(0);
  const [diskonglobal, setDiskonGLobal] = useState(0);
  const [qtyError, setQtyError] = useState(null);
  const [banksupplier, setBankSupplier] = useState("")
  const [banksuppliers, setBankSuppliers] = useState([])
  const allInfo = JSON.parse(localStorage.allinfo);
  const returPOs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Retur PO").map((p) => p.read_access));
  
  const validateForm = () => {
      let error = false;
      savedItems.map((savedItem, key) => { 
        if (savedItem.qty > savedItem.qtysisa) {
          setQtyError("invalid");
          error = true;
        }
      })
      return error;
  };

  const handleQtySisa = (e) => {
    e.preventDefault();
    if (!validateForm()) {
        setQuestionAlert()
    }
  }

  // useEffect(() => {
  //   setDiskonGlobalNominal1(diskonglobalnominal);
  // }, [diskonglobalnominal]);
  
  //   // diskon bayangan
  //   useEffect(() => {
  //   setTotalDiskon(diskonglobalnominal1);
  // }, [diskonglobalnominal1]);
  
  // useEffect(() => {
  //   setTotalDiskon3(diskontotal-a);
  // }, [diskontotal, a]);
  
  //   //diskon tampil
  //   useEffect(() => {
  //   setTotalDiskon2(totaldiskon - a);
  // }, [totaldiskon3, totaldiskon, a]);
  
  // //ongkir tampil
  // useEffect(() => {
  //   setOngkir1(ongkir - b);
  // }, [ongkir, b]);
  
  
  //   useEffect(() => {
  //   setTotalPrice1(qtyTotal);
  // }, [qtyTotal]);
  
  
  //   // diskonglobalnominal dan persen
  //   useEffect(() => {
  //     diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
  //     diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
  //   }, [diskonglobalnominal, qtyTotal, diskonglobalpersen,diskontotal,diskonglobalpersen]);
  
  //   // hasil nominal dari PPN
  //   useEffect(() => {
  //       setPPNNEW( (qtyTotal - totaldiskon) * (ppn / 100));
  //  }, [qtyTotal,totaldiskon,ppn]);
  
  //   // hasil grandtotal
  // useEffect(() => {
  //   setGrandTotal(( qtyTotal - totaldiskon2) + ppnnew + ongkir1- a );
  // }, [qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);

//   useEffect(() => {
//     setDiskonGlobalNominal1(diskonglobalnominal);
//   }, [diskonglobalnominal]);

//     // diskon bayangan
//     useEffect(() => {
//     setTotalDiskon(diskonglobalnominal1);
//   }, [diskonglobalnominal1]);

//   useEffect(() => {
//     setTotalDiskon3(diskontotal-a);
//   }, [diskontotal, a]);

//     //diskon tampil
//     useEffect(() => {
//     setTotalDiskon2(totaldiskon - a);
//   }, [totaldiskon3, totaldiskon, a]);

//   //ongkir tampil
//   useEffect(() => {
//     setOngkir1(ongkir - b);
//   }, [ongkir, b]);


//     useEffect(() => {
//     setTotalPrice1(qtyTotal - ppnnew);
//   }, [qtyTotal, ppnnew]);


//     // diskonglobalnominal dan persen
//     useEffect(() => {
//       diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
//       diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
//     }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);

//  // hasil nominal dari PPN
//  useEffect(() => {
//   setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
// }, [qtyTotal,totaldiskon,ppn]);


//     // hasil grandtotal
//   useEffect(() => {
//     setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew + ongkir1- a );
//   }, [totalPrice1,qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);

useEffect(() => {
  setDiskonGlobalNominal1(diskonglobalnominal);
}, [diskonglobalnominal]);

  // diskon bayangan
  useEffect(() => {
  setTotalDiskon(diskonglobalnominal1);
}, [diskonglobalnominal1]);

useEffect(() => {
  setTotalDiskon3(diskontotal-a);
}, [diskontotal, a]);

  //diskon tampil
  useEffect(() => {
  setTotalDiskon2((totaldiskon)/((ppn/100)+1));
}, [totaldiskon3, totaldiskon, ppn , a]);

//ongkir tampil
useEffect(() => {
  setOngkir1(ongkir - b);
}, [ongkir, b]);

  useEffect(() => {
  setTotalPrice1((qtyTotal)/((ppn/100)+1));
}, [qtyTotal,ppn]);

useEffect(() => {
  setDp1(dp - c);
}, [dp, c]);

  // diskonglobalnominal dan persen
  useEffect(() => {
    diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
    diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
  }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);

  // hasil nominal dari PPN
  useEffect(() => {
    setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
  }, [qtyTotal,totaldiskon,ppn]);

  // hasil grandtotal
useEffect(() => {
  setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew - dp1 + ongkir1- a );
}, [totalPrice1,qtyTotal, totaldiskon2, ppnnew,dp1, ongkir1, a]);

useEffect(() => {
  calculateTotalQty();
}, [savedItems]);

const calculateTotalQty = () => {
  const newTotalQty = savedItems.reduce((total, item) => {
    const itemTotal = (parseInt(item.harga) - parseInt(item.diskon_nominal)) * parseInt(item.qty);
    return total + itemTotal;
  }, 0);
  setTotalQty(newTotalQty);
};


  const handleEditQty = (index, value) => {
    if (!isNaN(value) && value.length > 0) {
      let updateList = [...savedItems];
      updateList[index] = { ...updateList[index], qty: value };
      setSavedItems(updateList);
    } else {
      return false;
    }
  }

const handleEditHarga = (index, value) => {
  if (!isNaN(value) && value.length > 0) {
    let updateList = [...savedItems];
    updateList[index] = { ...updateList[index], harga: value };
    setSavedItems(updateList);
  } else {
    return false;
  }
}

const handleEditDiskonNominal = (index, value) => {
  if (!isNaN(value) && value.length > 0) {
    let updateList = [...savedItems];
    let persentasediskonnya = (value / parseInt(updateList[index].harga)) * 100;
    updateList[index] = { ...updateList[index], diskon_nominal: value, diskon_persen: persentasediskonnya };
    setSavedItems(updateList);
  } else {
    return false;
  }
}

const handleEditDiskonPersen = (index, value) => {
  if (!isNaN(value) && value.length > 0) {
    let updateList = [...savedItems];
    let nominaldiskonnya = (parseInt(updateList[index].harga) * value) / 100;
    updateList[index] = { ...updateList[index], diskon_nominal: nominaldiskonnya, diskon_persen: value };
    setSavedItems(updateList);
  } else {
    return false;
  }
}

const handleEditNote = (index, value) => {
  let updateList = [...savedItems];
  if (value) {
    updateList[index] = { ...updateList[index], note: value };
    setSavedItems(updateList);
  } else {
    return false;
  }
}

	// const handleEditQty = (index, value) => {
  //   let updateList = savedItems;
  //   let minTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
  //   let plusTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * value ;
  //   let aqtyTotala = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal))* value ;
  //   let aqtyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
  //   let diskonnominal1 = parseInt(updateList[index].diskon_nominal) * value ;
  //   let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
    
  //   if(!isNaN(value)&&value.length>0){
  //     updateList[index] = {...updateList[index], qty: value};
  //     setSavedItems(updateList);
  //     setTotalPrice(totalPrice+plusTotal-minTotal);
  //     setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
  //     // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
  //   }else{return false}
  // }
  
  // const handleEditHarga = (index, value) => {
  //   if(!isNaN(value)&&value.length>0){
  //     let updateList = savedItems;
  //     let minTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
  //     let aqtyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal))* parseInt(updateList[index].qty)  ;
  //     updateList[index] = {...updateList[index], harga: value};
  //     setSavedItems(updateList);
  //     let plusTotal = parseInt(updateList[index].qty) * (value - parseInt(updateList[index].diskon_nominal));
  //     setTotalPrice(totalPrice + plusTotal - minTotal);
  //     let aqtyTotala = parseInt(updateList[index].qty) * (value  - parseInt(updateList[index].diskon_nominal));
  //     setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
  //   }else{return false}
  // }
  
  // const handleEditDiskonNominal = (index, value) => {
  //   if(!isNaN(value)&&value.length>0){
  //     let updateList = savedItems;
  //     let diskonnominal1 = parseInt(updateList[index].qty) * value ;
  //     let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
  //     let persentasediskonnya = value / parseInt(updateList[index].harga) * 100;
  //     let aqtyTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
  //     let minTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
  //     updateList[index] = {...updateList[index], diskon_nominal: value, diskon_persen: persentasediskonnya};
  //     setSavedItems(updateList);
  //     let plusTotal = parseInt(updateList[index].qty) * (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal));
  //     setTotalPrice(totalPrice+plusTotal-minTotal);
  //     let aqtyTotala = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty );
  //     setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
  //     // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
  //   }else{return false}
  // }
  
  // const handleEditDiskonPersen = (index, value) => {
  //   if(!isNaN(value)&&value.length>0){
  //     let updateList = savedItems;
  //     let  nominaldiskonnya = parseInt(updateList[index].harga) * value/100;
  //     let minTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
  //     let aqtyTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
  //     updateList[index] = {...updateList[index], diskon_nominal: nominaldiskonnya, diskon_persen: value};
  //     setSavedItems(updateList);
  //     let plusTotal = (parseInt(updateList[index].qty)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].harga) ;
  //     setTotalPrice(totalPrice+plusTotal-minTotal);
  //     let qatyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty)  ;
  //     setTotalQty(qtyTotal + qatyTotal - aqtyTotal);
  //     let diskonnominal1 = parseInt(updateList[index].qty) * value ;
  //     let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
  //     // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2 )
  //   }else{return false}
  // }

  useEffect(() => {
    getByPajak();
}, [pajak]);

const getByPajak = () => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(
      `${process.env.REACT_APP_API_BASE_URL}/pajak/${pajak}`,
      { headers }
    )
    .then((data) => {;
      setPPN(data.data.response.persentase);
    })
    .catch(function (error) {
      console.log(error);
    });
};


  const getPerson = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/person/list`,
        { headers }
      )
      .then((data) => {
        setPersons(data.data.response);
        setPerson(id)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getbankSupplier = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-person/${id}`, { headers })
      .then((data) => {
        setBankSuppliers(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getJangkaWaktu = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/jwkredit/list`, { headers })
      .then((data) => {
        setAllJangkaWaktu(data.data.response);
        setJangkaWaktu(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  const getPajak = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
      .then((data) => {
        setAllPajak(data.data.response);
        setPajak(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/po-retur/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        getPerson(data.data.response.person_id);
        getbankSupplier(data.data.response.person_id);
        setBankSupplier(data.data.response.account_number);
        setCodeRetur(data.data.response.retur_code);
        setCodeInvoice(data.data.response.invoice_code);
        getPajak(data.data.response.pajak_id);
        setType(data.data.response.type);
        setReturTipe(data.data.response.retur_type)
          setDp(data.data.response.um_nominal)
          setOngkir(data.data.response.ongkir);
        setTotalQty(data.data.response.price_real);
        setDiskonTotal(data.data.response.diskon_total);
        setKeterangan(data.data.response.keterangan);
        setTotalPrice(data.data.response.price_real);
        getJangkaWaktu(data.data.response.jangka_waktu);
        setPaymentMethod(data.data.response.payment_method);
        setDiskonGlobalNominal(data.data.response.diskon_global_nominal);
        setDiskonGlobalPersen(data.data.response.diskon_global_persen);
        getItemDataSaved();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/po-retur/item`, {

        rpo_id: props.match.params.id

    }).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code:data.item_code,
                satuan : data.satuan,
                qtysisa: data.max_qty,
                qty: data.qty,
                harga: data.harga,
                diskon_nominal: data.diskon_nominal,
                diskon_persen: data.diskon_persen,
                note : data.note,
                data: {
                    item_id: data.item_id,
                    item_name: data.item_name,
                    item_code:data.item_code,
                    qtysisa: data.max_qty,
                    qty: data.qty,
                    harga: data.harga,
                    diskon_nominal: data.diskon_nominal,
                    diskon_persen: data.diskon_persen,
                    note : data.note
                },
            }];
      }));
        setSavedItems(stateItem);
    })
}

const handleSubmit2 = () => {
  hideAlert();
  setTimeout(() => {EditData()}, 1000);
};
  
  function EditData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga: dataItem.harga,
                diskon_nominal:dataItem.diskon_nominal,
                diskon_persen:dataItem.diskon_persen,
                note : dataItem.note,
            }]);
    let data = {
      warehouse_id : parseInt(warehouse),
      username : username,
      code_invoice: codeinvoice,
      pajak_id : parseInt(pajak),
      diskon_global_nominal : parseFloat(diskonglobalnominal),
      diskon_global_persen : parseInt(diskonglobalpersen),
      payment_method: parseInt(payment_method),
      jangka_waktu: parseInt(jangkaWaktu),
      status_d : 3,
      status_ar: 3,
      person_id: parseInt(person),
      ongkir:parseFloat(ongkir),
      keterangan: keterangan ,
      type: parseInt(type),
      retur_type: parseInt(returtipe),
      items : dataItems,
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/po-retur/update/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/po-retur/page")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={handleSubmit2}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };

const formatRupiah = (money) => {
  return new Intl.NumberFormat('id-ID',
      { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
  ).format(money);
}
  
  const handleSubmit = (e) => {
    {
      EditData();
    }
  };

  const deleteItem = (id) => {
    let array = [...savedItems];

    let index = array.findIndex(object => {
      return object.item_id === id;
    });

    if (index !== -1) {
      setTotalPrice(totalPrice - (array[index].harga - array[index].diskon_nominal) * array[index].qty)  ;
      setTotalQty(qtyTotal - (array[index].harga - array[index].diskon_nominal) * array[index].qty) ;
      setDiskonTotal(diskontotal - array[index].diskon_nominal * array[index].qty);
      array.splice(index, 1);
      setSavedItems(array);
    }
  }

  const HargaDiskonPersen = (e) => {
    const value = e === undefined ? 'undefined' : e;
    setDiskonGlobalPersen(value || ' ');
    setDiskonGLobal("diskonglobalpersen")
    };
  
    const HargaDiskonNominal = (e) => {
      const value = e === undefined ? 'undefined' : e;
      setDiskonGlobalNominal(value || ' ');
      setDiskonGLobal("diskonglobalnominal")
      };
      
      const HargaOngkir = (e) => {
        const value = e === undefined ? 'undefined' : e;
        setOngkir(value || ' ');
        };


  return (
    <>
    {alert}
    {returPOs && returPOs === "1" ? (
			<div>
    <SimpleHeader name="Edit Retur PO" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
          <Card className="bg-secondary shadow">
                <Form onSubmit={handleSubmit}>
                    <CardHeader className="bg-white border-0">
                      <h3>Edit Retur PO</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Kode Retur
                              </Label>
                              <Col sm={6}>
                                <Input
                                  disabled
                                  className="form-control-alternative"
                                  autoComplete="off"
                                  type="text"
                                  placeholder="Masukan Kode Invoice"
                                  value={coderetur}
                                  onChange={(e) => {
                                    setCodeRetur(e.target.value);
                                  }}
                                />
                              </Col>
                            </FormGroup>
                         
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Supplier
                              </Label>
                              <Col sm={6}>
                                <Select2
                                disabled
                                      className="form-control-alternative"
                                      defaultValue="1"
                                      value={person}
                                      onChange={(e) => {
                                        setPerson(e.target.value);
                                      }}
                                      options={{
                                        placeholder: "Pilih Customer"
                                      }}
                                      data={persons.map((person, key) => {
                                        return (
                                        { id: person.id, text: person.person_name, key:key}
                                        );
                                      })}
                                    />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                Bank
                              </Label>
                              <Col sm={6}>
                                <Input
                                  disabled
                                  className="form-control-alternative"
                                  type="select"
                                  value={banksupplier}
                                  onChange={(e) => {
                                    setBankSupplier(e.target.value);
                                  }}>
                                  <option value="" disabled selected hidden>Pilih Bank</option>
                                    {banksuppliers.map((supplier, key) => {
                                      return (
                                          <option key={key} value={supplier.account_number}>
                                            {supplier.bank_name} {supplier.account_number} a/n {supplier.account_name} 
                                          </option>
                                      );
                                    })}
                                  </Input>
                              </Col>
													  </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={3}>
                                Diskon
                              </Label>
                              <Col sm={3}>
                                <CurrencyInput
                                  disabled
                                  id="validation-example-2-field"
                                  placeholder="0"
                                  allowDecimals={false}
                                  value={diskonglobalpersen}
                                  onValueChange={HargaDiskonPersen}
                                  className={`form-control`}
                                  suffix={'%'}
                                  step={10}
                                />
                              </Col>
                              <Col sm={3}>
                              <CurrencyInput
                                  disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={diskonglobalnominal}
                                  onValueChange={HargaDiskonNominal}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label
                                className="form-control-label" for="exampleEmail" sm={3}>
                                    PPN
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        disabled
                                        className="form-control-alternative"
                                        type="select"
                                        value={pajak}
                                        onChange={(e) => {
                                            setPajak(e.target.value);
                                    }}>
                                <option value='' disabled selected hidden>Pilih PPN</option>
                                  {allpajak.map((warehouse2, key) => {
                                    return (
                                        <option key={key} value={warehouse2.id}>
                                          {warehouse2.keterangan}
                                        </option>
                                    );
                                  })}
                                </Input>
														</Col>
													  </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={3}
                            >
                              Metode Pembayaran
                            </Label>
                            <Col sm={6}>
                            <Input
                            disabled
                            className="form-control-alternative"
                                name="Tipe Po"
                                type="select"
                                value={payment_method}
                                onChange={(e) => {
                                    setPaymentMethod(e.target.value);
                                }}
                              >
                                <option value={""}>Pilih Metode Pembayaran</option>
                                <option value={1}>Lunas</option>
                                <option value={2}>Tempo</option>
                                <option value={5}>Dp/Kredit</option>
                              </Input>
                            </Col>
                            </FormGroup>
                            {payment_method >  1  ? (
                              <FormGroup row>
                                <Label
                                className="form-control-label"
                                  for="exampleEmail"
                                  sm={3}
                                >
                                  Jangka Waktu
                                </Label>
                                <Col sm={4}>
                                  <Input
                                  disabled
                                    className="form-control-alternative"
                                    type="select"
                                    name="desc"
                                    value={jangkaWaktu}
                                    onChange={(e) => {
                                      setJangkaWaktu(e.target.value);
                                    }}
                                  >
                                  <option value="" disabled selected hidden>Pilih Jangka Waktu</option>
                                  {allJangkaWaktu.map((waktu, key) => {
                                    return (
                                      <option key={key} value={waktu.durasi}>
                                        {waktu.durasi}
                                      </option>
                                    );
                                  })}
                                </Input>
                                </Col> 
                                <Label
                                className="form-control-label" for="exampleEmail" sm={3}>
                                  <b>Hari</b>
                                </Label>
                              </FormGroup>
                            ):(
                              ""
                            )}
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Ongkir
                              </Label>
                              <Col sm={6}>
                              <CurrencyInput
                                    disabled
                                    id="validation-example-2-field"
                                    placeholder="Rp0"
                                    allowDecimals={false}
                                    value={ongkir}
                                    onValueChange={HargaOngkir}
                                    className={`form-control`}
                                    prefix={'Rp'}
                                    step={10}
                                  />
                              </Col>                             
                            </FormGroup>
                            {/* {payment_method === 5 ? 
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={3}
                              >
                                Uang Muka
                              </Label>
                              <Col sm={6}>
                              <CurrencyInput
                              disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={dp}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>                             
                            </FormGroup>
                            : 
                            ""
                            } */}
                          </Col>
                      </Row>
                    </CardBody>
                </Form>
              </Card>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Keterangan/Alasan</h3>
                    </CardHeader>
                <CardBody>
                  <Row md="12">
                    <Col md="12">
                      <FormGroup row>
                        <Label
                        className="form-control-label"
                          for="exampleEmail"
                          sm={3}
                        >
                          Keterangan
                        </Label>
                        <Col sm={6}>
                          <Input
                          className="form-control-alternative"
                            type="textarea"
                            name="desc"
                            rows = "4"
                            placeholder="Masukan Keterangan"
                            value={keterangan}
                            onChange={(e) => {
                              setKeterangan(e.target.value);
                            }}
                          />
                        </Col>                             
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                <Form onSubmit={handleQtySisa}>
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                        <CardBody>
                        <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Harga&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th><b>Qty&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Diskon %</b></th>
                                <th><b>Diskon(N)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th><b>Sub Total</b></th>
                                <th><b>Note&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td>
                                      
                                      {editable ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon Persen"
                                            type="number"
                                            value={savedItem.harga}
                                            onChange={(e) => {
                                              handleEditHarga(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{formatRupiah(savedItem.harga)}</>
                                        )}
                                      </td>
                                      <td>
                                        <Input
                                          className="form-control-alternative"
                                          placeholder="qty"
                                          type="number"
                                          invalid={qtyError === "invalid"}
                                          value={savedItem.qty}
                                          onChange={(e) => {
                                            handleEditQty(key, e.target.value);
                                            if (savedItem.qty > savedItem.qtysisa) {
                                              setQtyError("");
                                            }
                                            setDiskonGLobal("diskonglobalpersen")
                                          }}/>
                                           <FormFeedback>
                                                Max Barang {savedItem.qtysisa} 
                                            </FormFeedback>
                                      </td>
                                      <td>{savedItem.satuan}</td>
                                      <td>
                                        {editable ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon Persen"
                                            type="number"
                                            value={savedItem.diskon_persen}
                                            onChange={(e) => {
                                              handleEditDiskonPersen(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{savedItem.diskon_persen}</>
                                        )}
                                      </td>
                                      <td>
                                        {editable ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon nominal"
                                            type="number"
                                            value={savedItem.diskon_nominal}
                                            onChange={(e) => {
                                              handleEditDiskonNominal(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{formatRupiah(savedItem.diskon_nominal)}</>
                                        )}
                                      </td>
                                      <td>
                                      {/* {formatRupiah((((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) - ((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) * (ppn/100)))} */}
                                      {formatRupiah(((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) / (ppn/100 + 100/100))}

                                        {/* {formatRupiah((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty)} */}
                                      </td>
                                      <td>
                                      <Input
                                          className="form-control-alternative"
                                          placeholder="Note"
                                          type="text  "
                                          value={savedItem.note}
                                          onChange={(e) => {
                                            handleEditNote(key, e.target.value);
                                          }}/>
                                      </td> 
                                      <td>
                                        <Button color="danger" onClick={() => deleteItem(savedItem.item_id)}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter >
                          <Row md="12">
                            <Col md="6">
                            </Col>
                            <Col md="2">
                            </Col>
                            <Col md="4">
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                                size="small"
                              >
                                <b>Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Harga Total"
                                // value={totalPrice1}
                                value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                className="form-control-label"
                                  for="exampleEmail"
                                  sm={4}
                                >
                                <b>Diskon</b>
                              </Label>
                              <Col sm={6}>
                                <Input
                                  className="form-control-alternative"
                                  style={{fontWeight: 'bold'}}
                                  disabled
                                  type="text"
                                  name="barcode"
                                  placeholder="Diskon"
                                  value={"Rp." + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                  className="form-control-label"
                                  for="exampleEmail"
                                  sm={4}
                                >
                                  <b>PPN</b>
                                </Label>
                                <Col sm={6}>
                                  <Input
                                    className="form-control-alternative"
                                    disabled
                                    style={{fontWeight: 'bold'}}
                                    type="text"
                                    name="barcode"
                                    placeholder="PPN"
                                    value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                  />
                                </Col>
                              </FormGroup>
                              <FormGroup row>
                                <Label
                                className="form-control-label"
                                  for="exampleEmail"
                                  sm={4}
                                >
                                  <b>Ongkir</b>
                                </Label>
                                <Col sm={6}>
                                <Input
                                  className="form-control-alternative"
                                  disabled
                                  style={{fontWeight: 'bold'}}
                                  type="text"
                                  name="barcode"
                                  placeholder="PPN"
                                  value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                  />
                                </Col>
                              </FormGroup>
                              {/* <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Uang Muka</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Uang Muka"
                                value={"Rp." + dp1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup> */}
                              <FormGroup row>
                                <Label
                                className="form-control-label"
                                  for="exampleEmail"
                                  sm={4}
                                >
                                  <b>Grand Total</b>
                                </Label>
                                <Col sm={6}>
                                  <Input
                                  className="form-control-alternative"
                                    disabled
                                    type="text"
                                    name="barcode"
                                    style={{fontWeight: 'bold'}}
                                    placeholder="Grand Total"
                                    value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                  />
                                </Col>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardFooter>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/po-retur/page">
                        Kembali
                      </Link>
                      <Button color="danger" type="submit">
                          Simpan
                      </Button>
                </CardFooter>
                </Form>
              </Card>
          </div>
        </Row>
    </Container>  
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}