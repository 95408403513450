/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Card, Row, CardBody, CardHeader, Container } from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const Rekonsiliasi = () => {
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;
  const [alert, setAlert] = React.useState(null);
  const [rowIndex, setRowIndex] = useState(0);

  const [allAccount, setAllAccount] = useState([]);

  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [warehouseid, setWarehouseId] = useState("");
  const [warehouseids, setWarehouseIds] = useState([]);

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort, warehouseid);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort, warehouseid)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, warehouseid) => {
    getAccount(page, perPage,sort, warehouseid);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setWarehouseId(warehouseid)
  }

  const handleTableChange = (type, { sortField, sortOrder, warehouseid }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort, warehouseid)
    }
  }

  useEffect(() => {
    getAccount(page, perPage, currentSort);
    getPusat();
  }, []);

const getPusat = () => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/warehouse/list/all`, { headers
    })
    .then(data => {
      setWarehouseIds(data.data.response);
    })
      .catch(function (error) {
        console.log(error)
      })
  }

  const getAccount = async (page, perPage, currentSort, warehouse_id = null )  => {
    let filter = { 
      page: page, 
      per_page: perPage,
      warehouse_id : warehouse
    }
    // if (warehouse_id !== null) {
    //   filter = Object.assign(filter, { warehouse_id: warehouse_id })
    // }
    const data = filter;
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/rekonsiliasi-bank/page`, data, { headers
    })
    .then(data => {
        setAllAccount(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
    })
      .catch(function (error) {
        setAllAccount(error.response.data.response);
      })
  }

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }
  

  return (
    <div>
      <SimpleHeader name="Rekonsiliasi Bank" parentName="Finance" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3></h3>
                  <div style={{ textAlign: "right" }}>
                    <Link className="btn btn-info" to="/admin/rekonsiliasi/create">
                      <i className="fas fa-plus" /> Tambah
                    </Link>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
              {allAccount === null ? (
                        <div>
                          <ToolkitProvider
                                  rowNumber={rowIndex}
                                  data={""}
                                  keyField="id"
                                  columns={[
                                  {
                                    dataField: "no",
                                    text: "#",
                                    sort: true,
                                    page: 1,
                                    formatter: (cell, row, index) => {
                                    let currentRow = ++index;
                                    return currentRow + rowIndex;
                                    },
                                },
                                {
                                  dataField: "",
                                  text: "Kode Rekonsiliasi",
                                  sort: true,
                                },
                                {
                                  dataField: "",
                                  text: "Kode Transaksi",
                                  sort: true,
                                },
                                {
                                  dataField: "",
                                  text: "Keterangan",
                                  sort: true,
                                },
                                {
                                  dataField: "",
                                  text: "Debet",
                                  sort: true,
                                },
                                {
                                  dataField: "",
                                  text: "Kredit",
                                  sort: true,
                                },  
                                {
                                  dataField: "",
                                  text: "Saldo",
                                  sort: true,
                                },
                                  ]}
                              >
                                  {(props) => (
                                  <div className="py-4 table-responsive">
                                      <BootstrapTable
                                      remote
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      bordered={false}
                                      hover={true}
                                      onTableChange={handleTableChange}
                                      />
                                  </div>
                                  )}
                          </ToolkitProvider>
                          <CardHeader>
                              <center><h3>DATA NOT FOUND</h3></center>
                          </CardHeader>
                        </div>
                        ):(
                <ToolkitProvider
                  rowNumber={rowIndex}
                  data={allAccount}
                  keyField="id"
                  columns={[
                    {
                      dataField: "no",
                      text: "#",
                      sort: true,
                      page: 1,
                      formatter: (cell, row, index) => {
                        let currentRow = ++index;
                        return currentRow + rowIndex;
                      }
                    },
                    {
                      dataField: "rekonsiliasi_code",
                      text: "Kode Rekonsiliasi",
                      sort: true,
                    },
                    {
                      dataField: "code_transaction",
                      text: "Kode Transaksi",
                      sort: true,
                    },
                    {
                      dataField: "note",
                      text: "Keterangan",
                      sort: true,
                    },
                    {
                      dataField: "debet_total",
                      text: "Debet",
                      sort: true,
                      formatter: (value) => formatRupiah(value)
                    },
                    {
                      dataField: "kredit_total",
                      text: "Kredit",
                      sort: true,
                      formatter: (value) => formatRupiah(value)
                    },  
                    {
                      dataField: "saldo_total",
                      text: "Saldo",
                      sort: true,
                      formatter: (value) => formatRupiah(value)
                    },
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        remote
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        hover={true}
                        pagination={paginationFactory({ ...paginationOption })}
                        onTableChange={handleTableChange}
                      />
                    </div>
                  )}
                </ToolkitProvider>
                        )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
}

export default Rekonsiliasi;
