/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Input,

} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";
import CurrencyInput from 'react-currency-input-field';

export default function EditSoReturPenjualan(props) {
    const [alert, setAlert] = React.useState(null);
const [tanggal,setTanggal] = useState("")
const [faktur, setFaktur] = useState("")
const [retur, setRetur] = useState("")
const token = localStorage.token;
    const username = localStorage.username;
    const warehouse = localStorage.warehouse;
    let history = useHistory();
    const [customers, setCustomers] = useState([]);
    const [customer, setCustomer] = useState("");
    const [pengiriman, setPengiriman] = useState([]);
    const [keterangan, setKeterangan] = useState("");
    const [savedItems, setSavedItems] = useState([]);
    const [codeso, setCodeSo] = useState("");
    const [pay1,setPay1] =useState(0);
    const [payment_method,setPaymentMethod] = useState(0);
    const [keteranganbayar,setKeteranganBayar] = useState("");
    const [ppn, setPPN] = useState(0);
    const [totaldiskon, setTotalDiskon] = useState(0);
    const [grandtotal, setGrandTotal] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [diskonglobalnominal, setDiskonGlobalNominal] = useState(0);
    const [diskonglobalpersen,setDiskonGlobalPersen] = useState(0);
    const [ongkir, setOngkir] = useState(0);
    const [pajak, setPajak] = useState("");
    const [lainnya, setLainnya] = useState(0);
    const [status,setStatus] = useState("");
    const [coderfq, setCodeRfq] = useState("");
    const [allpajak, setAllPajak] = useState([]);
    const [allJangkaWaktu, setAllJangkaWaktu] = useState([]);
    const [jangkaWaktu,setJangkaWaktu] = useState(0);
    const [ongkir1, setOngkir1] = useState(0);
    const [ppnnew, setPPNNEW] = useState(0);
    const [diskonglobal, setDiskonGLobal] = useState(0);
    const [totalPrice1, setTotalPrice1] = useState(0);
    const [a, setA] = useState(0);
    const [b, setB] = useState(0);
    const [diskonglobalnominal1,setDiskonGlobalNominal1] = useState(0)
    const [totaldiskon2, setTotalDiskon2] = useState(0);
    const [totaldiskon3, setTotalDiskon3] = useState(0);
    const [qtyTotal, setTotalQty] = useState(0);
    const [diskontotal, setDiskonTotal] = useState(0);
    const [editable, setEditable] = useState(false);
    const [qtyError, setQtyError] = useState(null);
    const [bankcustomer, setBankCustomer] = useState("")
    const [bankcustomers, setBankCustomers] = useState([])
    const [customername, setCustomerName] = useState("")
    const allInfo = JSON.parse(localStorage.allinfo);
    const ReturSoProyeks = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Retur SO").map((p) => p.read_access));
    
    const validateForm = () => {
        let error = false;
        savedItems.map((savedItem, key) => { 
          if (savedItem.qty > savedItem.qtysisa) {
            setQtyError("invalid");
            error = true;
          }
        })
        return error;
    };

    const handleQtySisa = (e) => {
      e.preventDefault();
      if (!validateForm()) {
          setQuestionAlert()
      }
    }

    // useEffect(() => {
    //     setDiskonGlobalNominal1(diskonglobalnominal);
    //   }, [diskonglobalnominal]);
      
    //     // diskon bayangan
    //     useEffect(() => {
    //     setTotalDiskon(diskonglobalnominal1);
    //   }, [diskonglobalnominal1]);
      
    //   useEffect(() => {
    //     setTotalDiskon3(diskontotal-a);
    //   }, [diskontotal, a]);
      
    //     //diskon tampil
    //     useEffect(() => {
    //     setTotalDiskon2(totaldiskon - a);
    //   }, [totaldiskon3, totaldiskon, a]);
      
    //   //ongkir tampil
    //   useEffect(() => {
    //     setOngkir1(ongkir - b);
    //   }, [ongkir, b]);
      
      
    //     useEffect(() => {
    //     setTotalPrice1(qtyTotal);
    //   }, [qtyTotal]);
      
      
    //     // diskonglobalnominal dan persen
    //     useEffect(() => {
    //       diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
    //       diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
    //     }, [diskonglobalnominal, qtyTotal, diskonglobalpersen,diskontotal,diskonglobalpersen]);
      
    //     // hasil nominal dari PPN
    //     useEffect(() => {
    //         setPPNNEW( (qtyTotal - totaldiskon) * (ppn / 100));
    //    }, [qtyTotal,totaldiskon,ppn]);
      
    //     // hasil grandtotal
    //   useEffect(() => {
    //     setGrandTotal(( qtyTotal - totaldiskon2) + ppnnew + ongkir1- a );
    //   }, [qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);

    // useEffect(() => {
    //   setDiskonGlobalNominal1(diskonglobalnominal);
    // }, [diskonglobalnominal]);
  
    //   // diskon bayangan
    //   useEffect(() => {
    //   setTotalDiskon(diskonglobalnominal1);
    // }, [diskonglobalnominal1]);
  
    // useEffect(() => {
    //   setTotalDiskon3(diskontotal-a);
    // }, [diskontotal, a]);
  
    //   //diskon tampil
    //   useEffect(() => {
    //   setTotalDiskon2(totaldiskon - a);
    // }, [totaldiskon3, totaldiskon, a]);
  
    // //ongkir tampil
    // useEffect(() => {
    //   setOngkir1(ongkir - b);
    // }, [ongkir, b]);
  
  
    //   useEffect(() => {
    //   setTotalPrice1(qtyTotal - ppnnew);
    // }, [qtyTotal, ppnnew]);
  
  
    //   // diskonglobalnominal dan persen
    //   useEffect(() => {
    //     diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
    //     diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
    //   }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);
  
    // // hasil nominal dari PPN
    // useEffect(() => {
    //   setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
    // }, [qtyTotal,totaldiskon,ppn]);
  
    //   // hasil grandtotal
    // useEffect(() => {
    //   setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew + ongkir1- a );
    // }, [totalPrice1,qtyTotal, totaldiskon2,ppnnew, ongkir1, a]);

    useEffect(() => { 
      setDiskonGlobalNominal1(diskonglobalnominal);
    }, [diskonglobalnominal]);
    
      // diskon bayangan
      useEffect(() => {
      setTotalDiskon(diskonglobalnominal1);
    }, [diskonglobalnominal1]);
    
    useEffect(() => {
      setTotalDiskon3(diskontotal-a);
    }, [diskontotal, a]);
    
      //diskon tampil
      useEffect(() => {
      setTotalDiskon2((totaldiskon)/((ppn/100)+1));
    }, [totaldiskon3, totaldiskon, ppn , a]);
    
    //ongkir tampil
    useEffect(() => {
      setOngkir1(ongkir - b);
    }, [ongkir, b]);
    
      useEffect(() => {
      setTotalPrice1((qtyTotal)/((ppn/100)+1));
    }, [qtyTotal, ppnnew,ppn]);
    
    
      // diskonglobalnominal dan persen
      useEffect(() => {
        diskonglobalnominal && diskonglobal === "diskonglobalnominal" && setDiskonGlobalPersen((diskonglobalnominal / qtyTotal ) * 100);
        diskonglobalpersen && diskonglobal === "diskonglobalpersen"  && setDiskonGlobalNominal((qtyTotal) * (diskonglobalpersen/100));
      }, [diskonglobalnominal, qtyTotal, diskonglobal, diskonglobalpersen,diskontotal,diskonglobalpersen]);
    
      // hasil nominal dari PPN
      useEffect(() => {
        setPPNNEW( (qtyTotal - totaldiskon) - ((qtyTotal - totaldiskon) / ((ppn / 100) + (100 / 100))));
      }, [qtyTotal,totaldiskon,ppn]);
    
      // hasil grandtotal
    useEffect(() => {
      setGrandTotal(( totalPrice1 - totaldiskon2) + ppnnew + ongkir1- a );
    }, [totalPrice1,qtyTotal, totaldiskon2, ppnnew, ongkir1, a]);

      useEffect(() => {
        getByPajak();
	}, [pajak]);

	const getByPajak = () => {
	    const headers = {
	      "Content-Type": "application/json",
	      Authorization: `Bearer ${token}`,
	    };
	    axios
	      .get(
	        `${process.env.REACT_APP_API_BASE_URL}/pajak/${pajak}`,
	        { headers }
	      )
	      .then((data) => {;
	        setPPN(data.data.response.persentase);
	      })
	      .catch(function (error) {
	        console.log(error);
	      });
	  };

    const handleEditQty = (index, value) => {
        let updateList = savedItems;
        let minTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
        let plusTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * value ;
        let aqtyTotala = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal))* value ;
        let aqtyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
        let diskonnominal1 = parseInt(updateList[index].diskon_nominal) * value ;
        let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
        
        if(!isNaN(value)&&value.length>0){
          updateList[index] = {...updateList[index], qty: value};
          setSavedItems(updateList);
          setTotalPrice(totalPrice+plusTotal-minTotal);
          setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
          // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
        }else{return false}
      }
      
      const handleEditHarga = (index, value) => {
        if(!isNaN(value)&&value.length>0){
          let updateList = savedItems;
          let minTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
          let aqtyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal))* parseInt(updateList[index].qty)  ;
          updateList[index] = {...updateList[index], harga: value};
          setSavedItems(updateList);
          let plusTotal = parseInt(updateList[index].qty) * (value - parseInt(updateList[index].diskon_nominal));
          setTotalPrice(totalPrice + plusTotal - minTotal);
          let aqtyTotala = parseInt(updateList[index].qty) * (value  - parseInt(updateList[index].diskon_nominal));
          setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
        }else{return false}
      }
      
      const handleEditDiskonNominal = (index, value) => {
        if(!isNaN(value)&&value.length>0){
          let updateList = savedItems;
          let diskonnominal1 = parseInt(updateList[index].qty) * value ;
          let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
          let persentasediskonnya = value / parseInt(updateList[index].harga) * 100;
          let aqtyTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
          let minTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
          updateList[index] = {...updateList[index], diskon_nominal: value, diskon_persen: persentasediskonnya};
          setSavedItems(updateList);
          let plusTotal = parseInt(updateList[index].qty) * (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal));
          setTotalPrice(totalPrice+plusTotal-minTotal);
          let aqtyTotala = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty );
          setTotalQty(qtyTotal + aqtyTotala - aqtyTotal);
          // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2)
        }else{return false}
      }
      
      const handleEditDiskonPersen = (index, value) => {
        if(!isNaN(value)&&value.length>0){
          let updateList = savedItems;
          let  nominaldiskonnya = parseInt(updateList[index].harga) * value/100;
          let minTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
          let aqtyTotal = (parseInt(updateList[index].harga)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty) ;
          updateList[index] = {...updateList[index], diskon_nominal: nominaldiskonnya, diskon_persen: value};
          setSavedItems(updateList);
          let plusTotal = (parseInt(updateList[index].qty)- parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].harga) ;
          setTotalPrice(totalPrice+plusTotal-minTotal);
          let qatyTotal = (parseInt(updateList[index].harga) - parseInt(updateList[index].diskon_nominal)) * parseInt(updateList[index].qty)  ;
          setTotalQty(qtyTotal + qatyTotal - aqtyTotal);
          let diskonnominal1 = parseInt(updateList[index].qty) * value ;
          let diskonnominal2 = parseInt(updateList[index].qty) * parseInt(updateList[index].diskon_nominal);
          // setDiskonTotal(diskontotal + diskonnominal1 - diskonnominal2 )
        }else{return false}
      }

  useEffect(() => {
    getById();
  }, []);

  const getById = () => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/so-retur/get/${props.match.params.id}`,
        { headers }
      )
      .then((data) => {
        setCustomer(data.data.response.customer_id);
        setCustomerName(data.data.response.customer_name);
        getbankCustomer(data.data.response.customer_id);
        setBankCustomer(data.data.response.account_number);
        getCustomer(data.data.response.customer_id);
        getPajak(data.data.response.pajak_id);
        setCodeSo(data.data.response.code_transaction);
        setCodeRfq(data.data.response.code_sj);
        setFaktur(data.data.response.code_transaction)
        setRetur(data.data.response.retur_code)
        setTanggal(data.data.response.transaction_date)
        setOngkir(data.data.response.ongkir);
        setLainnya(data.data.response.lainnya);
        setPengiriman(data.data.response.pengiriman);
        setPay1(data.data.response.pay_1);
        setStatus(data.data.response.status);
        setKeteranganBayar(data.data.response.keterangan1);
        setKeterangan(data.data.response.keterangan);
        setTotalPrice(data.data.response.price_real);
        setTotalQty(data.data.response.price_real);
        setDiskonTotal(data.data.response.diskon_total);
        getJangkaWaktu(data.data.response.jangka_waktu);
        setPaymentMethod(data.data.response.payment_method);
        setDiskonGlobalNominal(data.data.response.diskon_global_nominal);
        setDiskonGlobalPersen(data.data.response.diskon_global_persen);
        getItemDataSaved();
       
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getItemDataSaved = () => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/so-retur/item`, {

        rso_id: props.match.params.id

    }).then(async response => {
        let stateItem = [];
        await Promise.all(response.data.response.map(async (data) => {
            stateItem = [...stateItem, {
                item_id: data.item_id,
                item_name:data.item_name,
                item_code : data.item_code,
                satuan :data.satuan,
                qty: data.qty,
                qtysisa: data.max_qty,
                harga: data.harga,
                pembayaran: data.pembayaran,
                diskon_nominal : data.diskon_nominal,
                diskon_persen : data.diskon_persen,
                data: {
                    item_name: data.item_name,
                    harga: data.harga,
                    item_code : data.item_code,
                    pembayaran: data.pembayaran,
                    qty: data.qty,
                    qtysisa: data.max_qty,
                    harga: data.harga,
                    pembayaran: data.pembayaran,
                    diskon_nominal : data.diskon_nominal,
                    diskon_persen : data.diskon_persen,
                },
            }];
        }));
        setSavedItems(stateItem);
    })
}

  const getPajak = (id) => {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `bearer ${token}`,
		};
		axios
			.get(`${process.env.REACT_APP_API_BASE_URL}/pajak/list`, { headers })
			.then((data) => {
				setAllPajak(data.data.response);
				setPajak(id);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

  const getJangkaWaktu = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/jwkredit/list`, { headers })
      .then((data) => {
        setAllJangkaWaktu(data.data.response);
        setJangkaWaktu(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  

const getCustomer = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/customer/list`,
        { headers }
      )
      .then((data) => {
        setCustomers(data.data.response);
        setCustomer(id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getbankCustomer = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/bank/get-by-customer/${id}`, { headers })
      .then((data) => {
        setBankCustomers(data.data.response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteItem = (id) => {
    let array = [...savedItems];

    let index = array.findIndex(object => {
      return object.item_id === id;
    });

    if (index !== -1) {
      setTotalPrice(totalPrice - (array[index].harga - array[index].diskon_nominal) * array[index].qty)  ;
      setTotalQty(qtyTotal - (array[index].harga - array[index].diskon_nominal) * array[index].qty) ;
      setDiskonTotal(diskontotal - array[index].diskon_nominal * array[index].qty);
      array.splice(index, 1);
      setSavedItems(array);
    }
  }

  const handleSubmit2 = () => {
    hideAlert();
    setTimeout(() => {EditData()}, 1000);
  };

   function EditData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga:dataItem.harga,
                pembayaran:dataItem.pembayaran,
                diskon_nominal : dataItem.diskon_nominal,
                diskon_persen : dataItem.diskon_persen,
            }]);
    let data = {
        username : username,
        warehouse_id : parseInt(warehouse),
        code_transaction: codeso,
        pajak_id : parseInt(pajak),
        status_ar1:5,
        status_ar2: 3,
        status_d: 3,
        status: parseInt(status),
        customer_id: parseInt(customer),
        pengiriman: parseInt(pengiriman),
        ongkir : parseFloat(ongkir),
        account_number : bankcustomer,
        lainnya: parseFloat(lainnya),
        pay_1 : parseFloat(pay1),
        diskon_global_nominal : parseFloat(diskonglobalnominal),
        diskon_global_persen : parseInt(diskonglobalpersen),
        payment_method : parseInt(payment_method),
        keterangan1 : keteranganbayar,
        keterangan: keterangan,
        items : dataItems
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/so-retur/update/${props.match.params.id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
            setSuccessAlert(response.data.message);
            setTimeout(() => (history.push("/admin/so-retur/page")), 1000);
          })
          .catch(function (error) {
                setGagalAlert(error.response.data.message);
          });
      }
    
      const setSuccessAlert = (id) => {
        setAlert(
          <SweetAlert
            success
            showConfirm
            confirmBtnText="Ok"
            title={id}
            onCancel={hideAlert}
            onConfirm={hideAlert}
          />
        )
      }
  
    const setGagalAlert = (id) => {
    setAlert(
      <SweetAlert
      danger
      showConfirm
      confirmBtnText="Ok"
      title={id}
      onCancel={hideAlert}
      onConfirm={hideAlert}
      />
    );
    };
  
    const setQuestionAlert = () => {
    setAlert(
      <SweetAlert
      warning
      showCancel
      confirmBtnText="Lanjutkan"
      confirmBtnBsStyle="danger"
      title="Apakah Kamu Yakin?"
      onConfirm={handleSubmit2}
      onCancel={hideAlert}
      focusCancelBtn
      />
    );
    };
    
      const hideAlert = () => {
        setAlert(null);
      };

  const handleSubmit = (e) => {
      {
        EditData();
      }
    };

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID',
        { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }
    ).format(money);
  }

  const HargaDiskonPersen = (e) => {
    const value = e === undefined ? 'undefined' : e;
    setDiskonGlobalPersen(value || ' ');
    setDiskonGLobal("diskonglobalpersen")
    };
  
    const HargaDiskonNominal = (e) => {
      const value = e === undefined ? 'undefined' : e;
      setDiskonGlobalNominal(value || ' ');
      setDiskonGLobal("diskonglobalnominal")
      };
    
      const HargaOngkir = (e) => {
        const value = e === undefined ? 'undefined' : e;
        setOngkir(value || ' ');
        };


  return (
    <>
    {alert}
    {ReturSoProyeks && ReturSoProyeks === "1" ? (
      <div>
    <SimpleHeader name="Edit Retur Penjualan" parentName="SO" />
    <Container className="mt--6" fluid>
        <Row>
        <div className="col">
            <CardBody>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                      <h3>Edit Retur Penjualan</h3>
                    </CardHeader>
                    <CardBody>
                      <Row md="12">
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Tanggal
                              </Label>
                              <Col sm={7}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="text"
                                  placeholder="Masukan Tanggal"
                                  value={tanggal}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Kode Retur
                              </Label>
                              <Col sm={7}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="text"
                                  placeholder="Masukan Kode Retur"
                                  value={retur}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Kode Faktur
                              </Label>
                              <Col sm={7}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="text"
                                  placeholder="Masukan Kode Faktur"
                                  value={faktur}
                                />
                              </Col>                             
                            </FormGroup>
                         
                            <FormGroup row>
                              <Label
                              className="form-control-label" for="exampleEmail" sm={4}>
                                Diskon
                              </Label>
                              <Col sm={3}>
                                <CurrencyInput
                                  disabled
                                  id="validation-example-2-field"
                                  placeholder="0"
                                  allowDecimals={false}
                                  value={diskonglobalpersen}
                                  onValueChange={HargaDiskonPersen}
                                  className={`form-control`}
                                  suffix={'%'}
                                  step={10}
                                />
                              </Col>
                              <Col sm={4}>
                              <CurrencyInput
                                  disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={diskonglobalnominal}
                                  onValueChange={HargaDiskonNominal}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>
                            </FormGroup>
                            <FormGroup row>
														<Label
                            className="form-control-label" for="exampleEmail" sm={4}>
															PPN
														</Label>
														<Col sm={7}>
															<Input
                              disabled
                                className="form-control-alternative"
																type="select"
																value={pajak}
                                >
                                <option value=''>Pilih PPN</option>
                                  {allpajak.map((warehouse2, key) => {
                                    return (
                                        <option key={key} value={warehouse2.id}>
                                          {warehouse2.keterangan}
                                        </option>
                                    );
                                  })}
                                </Input>
														</Col>
													  </FormGroup>
                          </Col>
                          <Col md="6">
                          <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Customer
                              </Label>
                              <Col sm={7}>
                                <Input
                                className="form-control-alternative"
                                disabled
                                  type="text"
                                  placeholder="Masukan Customer"
                                  value={customername}
                                />
                              </Col>                             
                            </FormGroup>
                            <FormGroup row>
														<Label
                            className="form-control-label" for="exampleEmail" sm={4}>
															Bank
														</Label>
														<Col sm={7}>
															<Input
                              disabled
                                className="form-control-alternative"
																type="select"
																value={bankcustomer}
                                >
                                <option value="" disabled selected hidden>Pilih Bank</option>
                                  {bankcustomers.map((supplier, key) => {
                                    return (
                                        <option key={key} value={supplier.account_number}>
                                          {supplier.bank_name} {supplier.account_number} a/n {supplier.account_name} 
                                        </option>
                                    );
                                  })}
                                </Input>
														</Col>
													  </FormGroup>
                            <FormGroup row>
                            <Label
                            className="form-control-label"
                              for="exampleEmail"
                              sm={4}
                            >
                              Metode Pembayaran
                            </Label>
                            <Col sm={7}>
                            <Input
                            disabled
                            className="form-control-alternative"
                                name="Tipe Po"
                                type="select"
                                value={payment_method}
                              >
                                <option value={""}>Pilih Metode Pembayaran</option>
                                <option value={1}>Lunas</option>
                                <option value={2}>Tempo</option>
                                <option value={5}>Dp/Kredit</option>
                                {/* <option value={4}>Dp Lunas</option> */}
                              </Input>
                            </Col>
                            </FormGroup>
                            {payment_method >  1  ? (
                              <FormGroup row>
                                <Label
                                className="form-control-label"
                                  for="exampleEmail"
                                  sm={4}
                                >
                                  Jangka Waktu
                                </Label>
                                <Col sm={4}>
                                  <Input
                                    className="form-control-alternative"
                                    type="select"
                                    name="desc"
                                    value={jangkaWaktu}
                                  >
                                  <option value="">Pilih</option>
                                  {allJangkaWaktu.map((waktu, key) => {
                                    return (
                                      <option key={key} value={waktu.durasi}>
                                        {waktu.durasi}
                                      </option>
                                    );
                                  })}
                                </Input>
                                </Col> 
                                <Label
                                className="form-control-label" for="exampleEmail" sm={3}>
                                  <b>Hari</b>
                                </Label>
                              </FormGroup>
                            ):(
                              ""
                            )}
                            <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                Ongkir
                              </Label>
                              <Col sm={7}>
                              <CurrencyInput
                              disabled
                                  id="validation-example-2-field"
                                  placeholder="Rp0"
                                  allowDecimals={false}
                                  value={ongkir}
                                  className={`form-control`}
                                  prefix={'Rp'}
                                  step={10}
                                />
                              </Col>                             
                            </FormGroup>
                          </Col>
                      </Row>
                    </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <h3>Keterangan/Alasan</h3>
                    </CardHeader>
                <CardBody>
                  <Row md="12">
                    <Col md="12">
                      <FormGroup row>
                        <Label
                        className="form-control-label"
                          for="exampleEmail"
                          sm={3}
                        >
                          Keterangan
                        </Label>
                        <Col sm={6}>
                          <Input
                          className="form-control-alternative"
                            type="textarea"
                            name="desc"
                            rows = "4"
                            placeholder="Masukan Keterangan"
                            value={keterangan}
                            onChange={(e) => {
                              setKeterangan(e.target.value);
                            }}
                          />
                        </Col>                             
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
             <Card className="bg-secondary shadow">
                <Form onSubmit={handleQtySisa}>
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                        <CardBody>
                        <Table size="sm" responsive>
                            <thead>
                              <tr>
                                <th><b>Nama Item</b></th>
                                <th><b>Kode Item</b></th>
                                <th><b>Harga</b></th>
                                <th><b>Qty&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b></th>
                                <th><b>Satuan</b></th>
                                <th><b>Diskon %</b></th>
                                <th><b>Diskon (N)</b></th>
                                <th><b>Sub Total</b></th>
                              </tr>
                            </thead>
                              <tbody>
                              {
                                savedItems.map((savedItem, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{savedItem.item_name}</td>
                                      <td>{savedItem.item_code}</td>
                                      <td>
                                      
                                      {editable ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon Persen"
                                            type="number"
                                            value={savedItem.harga}
                                            onChange={(e) => {
                                              handleEditHarga(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{formatRupiah(savedItem.harga)}</>
                                        )}
                                      
                                      </td>
                                      <td>
                                      <Input
                                          className="form-control-alternative"
                                          placeholder="qty"
                                          type="number"
                                          invalid={qtyError === "invalid"}
                                          value={savedItem.qty}
                                          onChange={(e) => {
                                            handleEditQty(key, e.target.value);
                                            setDiskonGLobal("diskonglobalpersen")
                                            if (savedItem.qty > savedItem.qtysisa) {
                                              setQtyError("");
                                            }
                                          }}/>
                                           <FormFeedback>
                                                Max Barang {savedItem.qtysisa} 
                                            </FormFeedback>
                                      </td>
                                      <td>{savedItem.satuan}</td>
                                      <td>
                                        {editable ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon Persen"
                                            type="number"
                                            value={savedItem.diskon_persen}
                                            onChange={(e) => {
                                              handleEditDiskonPersen(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{savedItem.diskon_persen}</>
                                        )}
                                      </td>
                                      <td>
                                        {editable ? (
                                          <Input
                                          className="form-control-alternative"
                                            placeholder="Diskon nominal"
                                            type="number"
                                            value={savedItem.diskon_nominal}
                                            onChange={(e) => {
                                              handleEditDiskonNominal(key, e.target.value);
                                            }}
                                          />
                                        ) : (
                                            <>{formatRupiah(savedItem.diskon_nominal)}</>
                                        )}
                                      </td>
                                      <td>
                                      {/* {formatRupiah((((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) - ((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) * (ppn/100)))} */}
                                      {formatRupiah(((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty) / (ppn/100 + 100/100))}

                                        {/* {formatRupiah((savedItem.harga - savedItem.diskon_nominal) * savedItem.qty)} */}
                                      </td>
                                      <td>
                                        <Button color="danger" onClick={() => (deleteItem(savedItem.item_id))}><i className="fas fa-trash" /></Button>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                              </tbody>
                          </Table>
                        </CardBody>
                        <CardFooter>
                          <Row md="12">
                            <Col md="6">
                            </Col>
                            <Col md="2">
                            </Col>
                            <Col md="4">
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                                size="small"
                              >
                                <b>Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="Harga Total"
                                value={"Rp." + totalPrice1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Diskon</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                style={{fontWeight: 'bold'}}
                                disabled
                                type="text"
                                name="barcode"
                                placeholder="Diskon"
                                value={"Rp." + totaldiskon2.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>PPN</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ppnnew.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Ongkir</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                                className="form-control-alternative"
                                disabled
                                style={{fontWeight: 'bold'}}
                                type="text"
                                name="barcode"
                                placeholder="PPN"
                                value={"Rp." + ongkir1.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                              <FormGroup row>
                              <Label
                              className="form-control-label"
                                for="exampleEmail"
                                sm={4}
                              >
                                <b>Grand Total</b>
                              </Label>
                              <Col sm={6}>
                              <Input
                              className="form-control-alternative"
                                disabled
                                type="text"
                                name="barcode"
                                style={{fontWeight: 'bold'}}
                                placeholder="Grand Total"
                                value={"Rp." + grandtotal.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,") + ",-"}
                                />
                              </Col>
                              </FormGroup>
                            </Col>
                          </Row>
                        </CardFooter>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/so-retur/page">
                        Kembali
                      </Link>
                      <Button color="danger" type="submit">
                          Simpan
                      </Button>
                </CardFooter>
                </Form>
              </Card>
            </CardBody>
          </div>
        </Row>
    </Container>
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}