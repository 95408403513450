/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  
  Card,
  Button,
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
  Table,
  Container,
  // InputGroupText,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem 
  
} from "reactstrap";
import { Link,useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Halaman404 from "views/404";

const CreateReceivingPo = () => {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
  const username = localStorage.username;
  const warehouse = localStorage.warehouse;
  let history = useHistory();
  const [persons, setPersons] = useState([]);
  const [person, setPerson] = useState("");
  const [codepo, setCodePo] = useState("");
  const [personname, setPersonName] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [savedItems, setSavedItems] = useState([]);
  const [ongkir, setOngkir] = useState([]);
  const [lainnya, setLainnya] = useState([]);
  const [pocode, setPoCode] = useState("");
  const [isSearchShoww, setIsSearchShoww] = useState(false);
  const [isSearchShowws, setIsSearchShowws] = useState(false);
  const [editingItem, setEditingitem] = useState([{ editing: false}]);
  const headers = { Authorization: `Bearer ${token}`};
  const [allItem, setAllItem] = useState([]);
	const [query, setQuery] = useState("");
	const [querys, setQuerys] = useState("");
  const [qtyError, setQtyError] = useState(null);
  const allInfo = JSON.parse(localStorage.allinfo);
  const receivingPOs = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Receiving PO").map((p) => p.read_access));
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterType, setFilterType]=useState("supplier")

  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const handleFilterTypeChange = (type) => {
    setFilterType(type);
  };

  const validateForm = () => {
      let error = false;
      savedItems.map((savedItem, key) => { 
        if (savedItem.qty > savedItem.qtysisa) {
          setQtyError("invalid");
          error = true;
        }
      })
      return error;
  };

  useEffect(() => {
		const getData = setTimeout(async() => {
			if(query != ""){
				axios
				.post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/page`, {page: 1,per_page: 10,approve:5, status_ap:5,status_d: 5,person_name: query,warehouse_id: parseInt(warehouse),active_flag:1},{ headers })
				.then((res) => {
					setAllItem(res.data);
					setIsSearchShoww(true);
					
				})
				.catch(function (error) {
					setAllItem(error.response.data.response);
					setIsSearchShoww(true);

              // setGagalAlert(error.response.data.message);
        });
			}}, 100)
		  return () => clearTimeout(getData)
	}, [query]);

  useEffect(() => {
		const getData = setTimeout(async() => {
			if(querys != ""){
				axios
				.post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/page`, {page: 1,per_page: 10,approve:5, status_ap:5,status_d: 5,po_code : querys, warehouse_id: parseInt(warehouse),active_flag:1},{ headers })
				.then((res) => {
					setAllItem(res.data);
					setIsSearchShowws(true);
					
				})
				.catch(function (error) {
					setAllItem(error.response.data.response);
					setIsSearchShowws(true);

              // setGagalAlert(error.response.data.message);
        });
			}}, 1000)
		  return () => clearTimeout(getData)
	}, [querys]);

  const searchShow = (item) => {
    AmbilDataCok(item.id);
    setIsSearchShoww(false);
    setIsSearchShowws(false);
  };

  const AmbilDataCok = (id) => {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      };
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/purchase-order/get/${id}`,
          { headers }
        )
        .then((data) => {
          getPerson(data.data.response.person_id);
          setCodePo(data.data.response.po_code)
          setPersonName(data.data.response.person_name);
          setPoCode(data.data.response.po_code);
          setLainnya(data.data.response.lainnya);
          setOngkir(data.data.response.ongkir);
          setKeterangan(data.data.response.keterangan);
          getItemDataSaved(data.data.response.po_code);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
  
  const getItemDataSaved = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
      axios.post(`${process.env.REACT_APP_API_BASE_URL}/purchase-order/item-by-code`, {
          po_code: id},{ headers }).then(async response => {
          let stateItem = [];
          let stateEditing = [];
          await Promise.all(response.data.response.map(async (data) => {
              stateItem = [...stateItem, {
                  editing:false,
                  qtysisa : data.max_qty,
                  item_id: data.item_id,
                  item_name:data.item_name,
                  item_code : data.item_code,
                  qty: data.qty,
                  satuan : data.satuan,
                  harga: data.harga,
                  diskon_nominal: data.diskon_nominal,
                  diskon_persen: data.diskon_persen,
                  note : data.note,
                  data: { 
                    qtysisa : data.max_qty,
                    item_id: data.item_id,
                    item_name:data.item_name,
                    item_code : data.item_code,
                    qty: data.qty,
                    harga: data.harga,
                    satuan : data.satuan,
                    diskon_nominal: data.diskon_nominal,
                    diskon_persen: data.diskon_persen,
                    note : data.note
                  },
              }]; 
                stateEditing = [...stateEditing, {
                  // editing: false,
                  qtysisa : data.qty,
                  item_id: data.item_id,
                  item_name:data.item_name,
                  item_code : data.item_code,
                  qty: data.qty,
                  satuan : data.satuan,
                  harga: data.harga,
                  diskon_nominal: data.diskon_nominal,
                  diskon_persen: data.diskon_persen,
                  note : data.note,
                  data: {
                    qtysisa : data.qty,
                    item_id: data.item_id,
                    item_name:data.item_name,
                    item_code : data.item_code,
                    satuan : data.satuan,
                    qty: data.qty,
                    harga: data.harga,
                    diskon_nominal: data.diskon_nominal,
                    diskon_persen: data.diskon_persen,
                  },
                // editing: false
              }];
        }));
        setEditingitem(stateItem);
        setSavedItems(stateItem);
      })
  }

  const changePriceStatus = (index, status) => {
    setEditingitem([
        ...editingItem.slice(0, index),
        Object.assign({}, editingItem[index], { editing: status }),
        ...editingItem.slice(index + 1)
    ]);
  }
  
  const changeItemDataTable = async (arg) => {
      setSavedItems([
          ...savedItems.slice(0, arg.index),
          Object.assign({}, savedItems[arg.index], {
              data: {
                  item_name: arg.itemName,
                  item_code: arg.item_code,
                  satuan: arg.satuan,
                  qty:savedItems[arg.index].qty,
                  harga:savedItems[arg.index].harga,
                  diskon_nominal:savedItems[arg.index].diskon_nominal,
                  diskon_persen:savedItems[arg.index].diskon_persen
              }
          }),
          ...savedItems.slice(arg.index + 1)
      ]);
  
      changePriceStatus(arg.index, false);
  }
  

  useEffect(() => {
    getPerson();
  }, []);

  const getPerson = (id) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/person/list`,
        { headers }
      )
      .then((data) => {
        setPersons(data.data.response);
        setPerson(id)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit2 = () => {
    hideAlert();
    setTimeout(() => {CreateData()}, 1000);
  };

  function CreateData() {
    let dataItems = [];
        savedItems.map((dataItem) => dataItems = [...dataItems, 
            { 
                item_id: dataItem.item_id, 
                qty: dataItem.qty, 
                harga: dataItem.harga,
                diskon_persen : dataItem.diskon_persen,
                diskon_nominal : dataItem.diskon_nominal,
                note : dataItem.note,
            }]);
    let data = {
      warehouse_id : parseInt(warehouse),
      username : username,
      code_po: pocode,
      ongkir: parseInt(ongkir),
      lainnya: parseInt(lainnya),
      person_id: parseInt(person),
      status_receive : 3, 
      keterangan: keterangan ,
      items : dataItems,
    };
    axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/receiving-po/save`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(function (response) {
          setSuccessAlert(response.data.message);
          setTimeout(() => (history.push("/admin/receiving-po/page")), 1000);
        })
        .catch(function (error) {
              setGagalAlert(error.response.data.message);
        });
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        success
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

const setGagalAlert = (id) => {
setAlert(
  <SweetAlert
  danger
  showConfirm
  confirmBtnText="Ok"
  title={id}
  onCancel={hideAlert}
  onConfirm={hideAlert}
  />
);
};

const setQuestionAlert = () => {
setAlert(
  <SweetAlert
  warning
  showCancel
  confirmBtnText="Lanjutkan"
  confirmBtnBsStyle="danger"
  title="Apakah Kamu Yakin?"
  onConfirm={handleSubmit2}
  onCancel={hideAlert}
  focusCancelBtn
  />
);
};

  const hideAlert = () => {
    setAlert(null);
  };

const handleSubmit = (e) => {
  e.preventDefault();
  if (!validateForm()) {
      setQuestionAlert()
  }
}

  return (
    <>
    {alert}
    {receivingPOs && receivingPOs === "1" ? (
      <div>
    <SimpleHeader name="Buat BPB Pembelian" parentName="PO" />
    <Container className="mt--6" fluid>
        <Row>
          <div className="col">
                <Card className="position-sticky boxShadow bg-secondary" style={{ top: 0, zIndex: "5" }}>
									<CardBody className="ml-3 pb-0">
										<Row md="12">
											<Col md="4">
											</Col>
                      <Col md="4">
                      <FormGroup>
                      <InputGroup>
                        <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                          <DropdownToggle caret color="primary">
                            {filterType === 'supplier' ? 'Nama Supplier' : 'Kode PO'}
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={() => {handleFilterTypeChange('supplier');setIsSearchShowws(false)}}>
                              Nama Supplier
                            </DropdownItem>
                            <DropdownItem onClick={() => {handleFilterTypeChange('po');setIsSearchShoww(false)}}>
                              Kode PO
                            </DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      {filterType === "supplier" ? 
                        <Input
                            placeholder="Search Supplier"
                            type="search"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                          />
                          : 
                          <Input
                            className="form-control"
                            placeholder={filterType === "supplier" ? "Search Supplier" : "Search Kode PO"}
                            type="search"
                            value={querys}
                            onChange={(e) => setQuerys(e.target.value)}
                          />
                          }
                        </InputGroup>
                      </FormGroup>
                      </Col>
                      <Col md="4">
                      </Col>
										</Row>
									</CardBody>
								</Card>
									{isSearchShoww && query && (
										<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
											<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
												<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShoww(false)}></i>
											</div>
											<span className="text-center mb-3">
												<b>Pencarian berdasarkan:</b> {query}
											</span>
											{allItem?.response ? (
												allItem.response.map((item) => (
													<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" style={{minHeight: 'auto'}} onClick={() => {searchShow(item); setQuery(''); setIsSearchShoww(false);}}>
													  <div className=" d-flex align-items-center">
                            <div className="ml-3">
																<div>
																	<b>Supplier :</b> {item.person_name}
																</div>
															</div>
															<div className="ml-3">
																<div>
																	<b>Kode PO :</b> {item.po_code}
																</div>
															</div>
														</div>
													</CardBody>
												))
											) : (
												<div className="text-center mb-3 text-danger">Supplier "{query}" tidak ada bosku!</div>
											)}
										</Card>
									)}
                  {isSearchShowws && querys && (
										<Card className="boxShadow" style={{ top: 100, zIndex: "3", maxHeight: "15.5rem", overflowY: "auto", paddingTop: "1rem", position: "sticky" }}>
											<div style={{ position: "absolute", top: "2.5px", right: "1rem", cursor: "pointer", fontSize: "2rem" }}>
												<i className="fas fa-window-close text-danger" onClick={() => setIsSearchShowws(false)}></i>
											</div>
											<span className="text-center mb-3">
												<b>Pencarian berdasarkan:</b> {querys}
											</span>
											{allItem === null ? (
												<div className="text-center mb-3 text-danger">PO "{querys}" tidak ada bosku!</div>
											) : (
                        allItem.response.map((item) => (
													<CardBody key={item.id} className="bgSearch border-bottom p-2 mb-2" style={{minHeight: 'auto'}} onClick={() => {searchShow(item); setQuerys(''); setIsSearchShowws(false);}}>
													  <div className=" d-flex align-items-center">
                            <div className="ml-3">
																<div>
																	<b>Supplier :</b> {item.person_name}
																</div>
															</div>
															<div className="ml-3">
																<div>
																	<b>Kode PO :</b> {item.po_code}
																</div>
															</div>
														</div>
													</CardBody>
												))
											)}
										</Card>
									)}
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Row md="12">
                      <Col md="6">
                      <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                          >
                            Kode PO
                          </Label>
                          <Col sm={6}>
                            <Input
                            disabled
                            className="form-control-alternative"
                                autoComplete="off"
                                placeholder="Masukan Kode PO"
                              name="person"
                              type="text"
                              value={codepo}
                            >
                            </Input>
                          </Col>
                        </FormGroup>
                        <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                          >
                            Supplier
                          </Label>
                          <Col sm={6}>
                            <Input
                            disabled
                            placeholder="Masukan Supplier"
                            className="form-control-alternative"
                                autoComplete="off"
                              name="person"
                              type="text"
                              value={personname}
                            >
                              <option value=''>Pilih Supplier</option>
                              {persons.map((person, key) => {
                                  return (
                                    <option key={key} value={person.id}>
                                      {person.person_name}
                                    </option>
                                  );
                                })}
                            </Input>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                      <FormGroup row>
                          <Label
                          className="form-control-label"
                            for="exampleEmail"
                            sm={3}
                          >
                            Keterangan
                          </Label>
                          <Col sm={6}>
                            <Input
                            disabled
                            className="form-control-alternative"
                                autoComplete="off"
                              type="textarea"
                              rows = "6"
                              name="desc"
                              placeholder="Masukan Keterangan"
                              value={keterangan}
                              onChange={(e) => {
                                setKeterangan(e.target.value);
                              }}
                            />
                          </Col>                             
                        </FormGroup>
                      </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="bg-secondary shadow">
                <Form onSubmit={handleSubmit}>
                    <CardHeader className="bg-white border-0">
                      <h3>Item</h3>
                    </CardHeader>
                    <CardBody>
                        <Table size="sm" responsive>
                        <thead>
                        <tr>
                            <th><b>Nama Item</b></th>
                            <th><b>Kode Item</b></th>
                            <th><b>Qty</b></th>
                            <th><b>Satuan</b></th>
                            <th><b>Note</b></th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            savedItems.map((savedItem, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{savedItem.data.item_name}</td>
                                        <td>{savedItem.data.item_code}</td>
                                        <td>{savedItem.data.qty}</td>
                                        <td>{savedItem.data.satuan}</td>
                                        <td>{savedItem.data.note}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                        </Table>
                    </CardBody>
                <CardFooter>
                      <Link className="btn btn-info" to="/admin/receiving-po/page">
                        Kembali
                      </Link>
                      <Button color="danger" type="submit">
                          Simpan
                      </Button>
                </CardFooter>
                </Form>
              </Card>
          </div>
        </Row>
    </Container>  
    </div>
    ):(
      <Halaman404 />
    )}
    </>
  );
}

export default CreateReceivingPo;

