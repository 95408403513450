import React, {useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Form,
  Input,
  Label,
  Spinner
} from "reactstrap";
import axios from "axios";
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";

const ModalCekHarga = ({ open, toggle }) => {
  const token = localStorage.token;
  const warehouse = localStorage.warehouse;

  const [alert, setAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nameStock, setNameStock] = useState("");
  const [codeStock, setCodeStock] = useState("");
  const [barcode, setBarcode] = useState("");
  const [allItemStock, setAllItemStock] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const [rowIndex, setRowIndex] = useState(0);

  const paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label className="form-control-label">
          Show{" "}
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={(e) => updateDataTable(page, e.target.value, currentSort)}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>{" "}
          entries.
        </label>
      </div>
    ),
  };

  const getItemOwnStock =  (page = 1, perPage = 10, item_name = "", item_code = "", barcode = "") => {
    setLoading(true); // Set loading to true before making the request
    try {
      const filter = {
        page,
        per_page: perPage,
        warehouse_id: parseInt(warehouse),
        item_name,
        item_code,
        barcode,
      };

      const { data } = axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/items-additional-cashier`,
        filter,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setAllItemStock(data.response);
      setPage(data.current_page + 1);
      setPerpage(data.per_page);
      setTotalItem(data.total_item);
    } catch (error) {
      setGagalAlert(error.response?.data?.message || "Something went wrong!");
    } finally {
      setLoading(false); // Set loading to false after the request is finished
    }
  };

  useEffect(() => {
    if (open) {
      getItemOwnStock();
    }
  }, [open]);

  const updateDataTable = (page, perPage, sort, nameStock = "", codeStock = "", barcode = "") => {
    getItemOwnStock(page, perPage, nameStock, codeStock, barcode);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
  };

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      const sort = `${sortField} ${sortOrder}`;
      updateDataTable(page, perPage, sort, nameStock, codeStock, barcode);
    }
  };

  const setGagalAlert = (message) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={message}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const reset = () => {
    setNameStock("");
    setCodeStock("");
    setBarcode("");
    updateDataTable(1, perPage, currentSort, "", "", "");
  };

  const formatRupiah = (money) => {
    return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(money);
  };

  return (
    <>
      {alert}
      <Modal isOpen={open} toggle={toggle} style={{ minWidth: "70%", top: "-10%" }}>
        <ModalHeader toggle={toggle} align="center">
          <b>Cek Harga</b>
        </ModalHeader>
        <ModalBody align="center">
          <CardBody>
            <Form>
              <Row md="12">
                <Col md="3">
                  <FormGroup>
                    <Label className="form-control-label">Nama Item</Label>
                    <Input
                      type="text"
                      placeholder="Masukan Nama Item"
                      value={nameStock}
                      onChange={e => updateDataTable(1, perPage, currentSort, e.target.value, codeStock, barcode)}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label className="form-control-label">Kode Item</Label>
                    <Input
                      type="text"
                      placeholder="Masukan Kode Item"
                      value={codeStock}
                      onChange={e => updateDataTable(1, perPage, currentSort, nameStock, e.target.value, barcode)}
                    />
                  </FormGroup>
                </Col>
                <Col md="3">
                  <FormGroup>
                    <Label className="form-control-label">Barcode</Label>
                    <Input
                      type="text"
                      placeholder="Masukan Barcode"
                      value={barcode}
                      onChange={e => updateDataTable(1, perPage, currentSort, nameStock, codeStock, e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label className="form-control-label">&nbsp;</Label>
                    <br />
                    <Button type="button" onClick={reset} color="secondary">
                      Reset
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            {loading ? (
              <div className="text-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <ToolkitProvider
                rowNumber={rowIndex}
                data={allItemStock}
                keyField="id"
                columns={[
                  {
                    dataField: "no",
                    text: "#",
                    sort: true,
                    formatter: (cell, row, index) => {
                      return rowIndex + index + 1;
                    },
                  },
                  {
                    dataField: "item_code",
                    text: "Kode Item",
                    sort: true,
                  },
                  {
                    dataField: "barcode",
                    text: "Barcode",
                    sort: true,
                  },
                  {
                    dataField: "item_name",
                    text: "Nama Item",
                    sort: true,
                  },
                  {
                    dataField: "price",
                    text: "Harga",
                    sort: true,
                    formatter: formatRupiah,
                  },
                  {
                    dataField: "stock",
                    text: "Stok",
                    sort: true,
                  },
                ]}
              >
                {props => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      remote
                      {...props.baseProps}
                      bootstrap4
                      bordered={false}
                      hover
                      pagination={paginationFactory(paginationOption)}
                      onTableChange={handleTableChange}
                    />
                  </div>
                )}
              </ToolkitProvider>
            )}
          </CardBody>
          <Button color="secondary" onClick={toggle}>
            Kembali
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ModalCekHarga;
